import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenClaims } from '@azure/msal-common';
import {
  RoleClient,
  RoleIncidentRequest,
  RoleViewModel,
  UserAdministrationClient,
  UserClient,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { UserService } from '@core/services/oauth-service/user.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RegexpEmail } from '@shared/utils/regex';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss'],
})
export class UserPermissionsComponent implements OnInit {
  headline = this.translateService.instant(
    'menu.ticket.component.request_user_permissions'
  );

  cardUser: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant('user.request.permissions.title'),
      },
    },
    footer: {
      button1: {
        label: this.translateService.instant(
          'user.request.permissions.label__submit'
        ),
        onClick: () => this.validateForm(),
        disabled: false,
      },
      button2: {
        label: this.translateService.instant(
          'user.request.permissions.label__reset'
        ),
        onClick: () => this.reset(),
        disabled: false,
      },
    },
  };

  form = new FormGroup({});

  formModel: any = {};

  formFields: Array<FormlyFieldConfig>;

  loading = false;

  loadingMessage = '';

  isRequestForOwnUser = true;

  currentUserTokenClaims: TokenClaims & {
    [key: string]: unknown;
  };

  private rolesSubject = new BehaviorSubject<RoleViewModel[]>([]);
  roles$ = this.rolesSubject.asObservable();

  isTelekomUser = false;

  currentRoles: RoleViewModel[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private roleClient: RoleClient,
    private router: Router,
    private translateService: TranslateService,
    private userClient: UserClient,
    private userService: UserService,
    private userAdministrationClient: UserAdministrationClient,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.roleClient.get().subscribe({
      next: roles => {
        this.rolesSubject.next(roles);
      },
    });

    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);

    this.currentUserTokenClaims = this.userService.getCurrentUserTokenClaims();

    this.isTelekomUser =
      this.currentUserTokenClaims.ciamId &&
      this.currentUserTokenClaims.ciamId !== '';

    this.userClient.getOwnUser().subscribe({
      next: res => {
        this.currentRoles = res.roles;

        if (!this.isTelekomUser) {
          this.formFields = this.getFormFields(
            this.rolesSubject
              .getValue()
              .filter(role => role.name.startsWith('DL_'))
              .sort((a, b) => a.description.localeCompare(b.description))
          );
        } else if (this.isTelekomUser && this.isRequestForOwnUser) {
          this.formFields = this.getFormFields(
            this.rolesSubject
              .getValue()
              .filter(
                role =>
                  role.name.startsWith('REM_') || role.name.startsWith('DTAG_')
              )
              .sort((a, b) => a.description.localeCompare(b.description))
          );
        }
        this.loading = false;
      },
      error: err => {
        this.loading = false;
      },
    });
  }

  validateForm() {
    if (this.form.valid) {
      this.requestRoles();
    } else {
      this.form.markAllAsTouched();
    }
  }

  reset() {
    this.router.navigateByUrl('dashboard');
  }

  onToggleChange() {
    this.formFields[0].form.get('userData.roles').setValue([]);
    this.form.markAsUntouched();
    if (this.isRequestForOwnUser) {
      this.formFields[0].form.get('userData.email').disable();
      this.formFields[0].form
        .get('userData.email')
        .setValue(this.currentUserTokenClaims.email);

      if (this.isTelekomUser) {
        this.formFields = this.getFormFields(
          this.rolesSubject
            .getValue()
            .filter(
              role =>
                role.name.startsWith('REM_') || role.name.startsWith('DTAG_')
            )
            .sort((a, b) => a.description.localeCompare(b.description))
        );
      } else {
        this.formFields = this.getFormFields(
          this.rolesSubject
            .getValue()
            .filter(role => role.name.startsWith('DL_'))
            .sort((a, b) => a.description.localeCompare(b.description))
        );
      }
    } else {
      this.formFields[0].form.get('userData.email').enable();
      this.formFields[0].form.get('userData.email').setValue(null);
      this.formFields[0].form.get('userData.roles').setValue(null);

      if (this.isTelekomUser) {
        this.formFields = this.getFormFields(
          this.rolesSubject
            .getValue()
            .sort((a, b) => a.description.localeCompare(b.description)),
          []
        );
      } else {
        this.formFields = this.getFormFields(
          this.rolesSubject
            .getValue()
            .filter(role => role.name.startsWith('DL_'))
            .sort((a, b) => a.description.localeCompare(b.description)),
          []
        );
      }
    }
  }

  getFormFields(roles: RoleViewModel[], currentRoles?: RoleViewModel[]) {
    this.currentRoles = this.currentRoles.filter(cr =>
      roles.find(role => role.id === cr.id)
    );
    return [
      {
        type: 'flex-layout',
        key: 'userData',
        fieldGroup: [
          {
            key: 'isRequestForOwnUser',
            type: 'select',
            defaultValue: true,
            props: {
              options: [
                {
                  id: 1,
                  label: this.translateService.instant(
                    'user.request.permissions.own_user'
                  ),
                  value: true,
                },
                {
                  id: 2,
                  label: this.translateService.instant(
                    'user.request.permissions.external_user'
                  ),
                  value: false,
                },
              ],
            },
            hooks: {
              onInit: field => {
                field.formControl.valueChanges.subscribe(value => {
                  this.isRequestForOwnUser = value;
                  this.onToggleChange();
                });
              },
            },
          },
          {
            key: 'email',
            type: 'input',
            defaultValue: this.isRequestForOwnUser
              ? this.currentUserTokenClaims.email
              : '',
            props: {
              label: this.translateService.instant(
                'user.request.permissions.label__email'
              ),
              required: true,
              disabled: this.isRequestForOwnUser,
              pattern: RegexpEmail,
            },
            validation: {
              messages: {
                pattern: (_err, _field: FormlyFieldConfig) =>
                  this.translateService.instant('formErrors.email.pattern'),
              },
            },
          },
          {
            key: 'roles',
            type: 'autocomplete-chips-for-roles',
            defaultValue: currentRoles ?? this.currentRoles,
            props: {
              label: this.translateService.instant(
                'user.request.permissions.label__roles'
              ),
              required: true,
              options: roles,
              currentRoles: currentRoles ?? this.currentRoles,
              disablePreselectedRoles:
                this.isRequestForOwnUser === true ? true : false,
            },
            validation: {
              messages: {
                required: this.translateService.instant('formErrors.required', {
                  label: this.translateService.instant(
                    'user.request.permissions.label__roles'
                  ),
                }),
              },
            },
          },
        ],
      },
    ];
  }

  requestRoles() {
    this.loadingMessage = this.translateService.instant('form.loading_message');
    this.loading = true;
    let request: RoleIncidentRequest;
    const value = this.form.getRawValue() as any;

    const newRoles = value.userData.roles
      .filter(role => !this.currentRoles.find(cr => cr.id === role.id))
      .map((x: RoleViewModel) => x.id);

    if (newRoles?.length > 0) {
      if (newRoles)
        request = {
          email: value.userData.email,
          roles: newRoles,
        };
      this.userAdministrationClient.requestRoles(request).subscribe({
        next: () => {
          this.loading = false;
          this.toastr.success(
            this.translateService.instant(
              'user.request.permissions.successMessage'
            )
          );
          this.router.navigateByUrl('/dashboard');
        },
        error: () => {
          this.loading = false;
          this.toastr.error(
            this.translateService.instant(
              'user.request.permissions.errorMessage'
            ),
            this.translateService.instant('alert.error')
          );
        },
      });
    } else {
      this.loading = false;
      this.toastr.error(
        this.translateService.instant(
          'user.request.permissions.no_permissions_selected'
        )
      );
    }
  }
}
