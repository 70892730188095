import { Component } from '@angular/core';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { TranslateService } from '@ngx-translate/core';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';

@Component({
  selector: 'app-feedback-telekom',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackTelekomComponent {
  headline = this.translateService.instant('menu.feedback.feedback_action');
  cardSupport: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant('feedback.headline'),
      },
    },
  };

  constructor(
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);
  }

  public get mailAddress(): string {
    return this.translateService.instant('feedback.mailAddress');
  }
}
