import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-telekom-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public appName: string = '';
  public appNameLink: string = '/landing/home';

  public mainNavContent = [
    {
      name: 'Home',
      id: '/landing/home',
      href: '/landing/home',
      childroutes: ['/home', '/who', '/contact'],
      onClick: event => this.routeTo(event, '/landing/home'),
    },
    {
      name: 'Über uns',
      id: '/landing/who',
      href: '/landing/who',
      onClick: event => this.routeTo(event, '/landing/who'),
    },
    {
      name: 'Vermietung',
      id: '/landing/rental',
      href: '/landing/rental',
      onClick: event => this.routeTo(event, '/landing/rental'),
    },
    {
      name: 'Verkauf',
      id: '/landing/sell',
      href: '/landing/sell',
      onClick: event => this.routeTo(event, '/landing/sell'),
    },
    {
      name: 'Kontakt',
      id: '/landing/contact',
      href: '/landing/contact',
      onClick: event => this.routeTo(event, '/landing/contact'),
    },
  ];

  public currentRoute: string | undefined;
  private routeSub: Subscription | null = null;

  constructor(
    public router: Router,
    public cd: ChangeDetectorRef,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService
  ) {
    this.routeSub = this.router.events.subscribe({
      next: () => {
        const parentRoute = this.getParentRoute(this.router.url);
        if (parentRoute) {
          this.currentRoute = parentRoute;
        } else {
          this.currentRoute = this.router.url;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  ngOnInit(): void {
    const parentRoute = this.getParentRoute(this.router.url);
    if (parentRoute) {
      this.currentRoute = parentRoute;
    } else {
      this.currentRoute = this.router.url;
    }
  }

  routeTo(event:any, route: string) {
    event.preventDefault();
    this.router.navigateByUrl(route);
  }

  /**
   * Used to highlight the current route in navigation if the route is a subroute.
   * @param route the current route
   * @returns the 'id' of the parent route
   */
  getParentRoute(route: string): string | null {
    for (const rootElement of this.mainNavContent) {
      if (rootElement.childroutes?.includes(route)) {
        return rootElement.id;
      }
    }
    return null;
  }

  public OnB2CLoginClick() {
    if (this.msalGuardConfig.authRequest) {
      this.msalAuthService.loginRedirect();
    } else {
      this.msalAuthService.loginPopup();
    }
  }
}
