import { inject, Injectable } from '@angular/core';
import {
  BaseSelectViewModel,
  BuildingRoomViewModel,
  UpdateRoomRequest,
  CreateArchObjUtilHistoryRequest,
  LocationClient,
  UpdateArchObjUtilHistoryRequest,
  FrozenZoneViewModel,
} from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditRoomService {
  private locationClient = inject(LocationClient);

  getBuildingMaintenanceRoomInformation(
    spaceId: string | undefined
  ): Observable<BuildingRoomViewModel> {
    return this.locationClient.getBuildingMaintenanceRoomInformation(spaceId);
  }

  addArchObjUtilHistory(
    data: CreateArchObjUtilHistoryRequest
  ): Observable<void> {
    return this.locationClient.addArchObjUtilHistory(data);
  }

  updateArchObjUtilHistory(
    data: UpdateArchObjUtilHistoryRequest
  ): Observable<void> {
    return this.locationClient.updateArchObjUtilHistory(data);
  }

  deleteArchObjUtilHistory(archObjUtilHistoryId: string): Observable<void> {
    return this.locationClient.deleteArchObjUtilHistory(archObjUtilHistoryId);
  }

  updateRoom(request: UpdateRoomRequest) {
    return this.locationClient.updateRoom(request);
  }

  getChangeReasons(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getChangeReasons();
  }

  getWmvClusters(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getWmvClusters();
  }

  getWmVClusterInfos(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getWmvClusterInfos();
  }

  getWmvProducts(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getWmvProducts();
  }

  getCalculationTypes(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getCalculationTypes();
  }

  getAllocationAreas(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getAllocationAreas();
  }

  getCentralInfos(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getCentralInfos();
  }

  getOfficeAreaTypes(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getOfficeAreaTypes();
  }

  checkFrozenZone(): Observable<FrozenZoneViewModel> {
    return this.locationClient.checkFrozenZone();
  }
}
