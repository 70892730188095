<su-headline [hidden]="filledForm" h="h1" skin="primary">{{
  title
}}</su-headline>

<div [style]="descriptionContainerStyle">
  <div
    *ngIf="!filledForm"
    class="sub-headline contactDescription"
    [style]="descriptionStyle"
    [innerHtml]="description | safeHtml"></div>
</div>

<su-card [card]="card" [hidden]="filledForm">
  <div card-content>
    <form (ngSubmit)="validateForm()" [formGroup]="form">
      <div [hidden]="filledForm">
        <formly-form
          [fields]="formFields"
          [form]="form"
          [model]="formModel"></formly-form>
      </div>
    </form>
  </div>
</su-card>

<su-headline *ngIf="filledForm" h="h1" skin="primary">{{
  'ticket.create.furniture_order.summary' | translate
}}</su-headline>
<div class="description" *ngIf="filledForm">
  <p
    [innerText]="'ticket.create.furniture_order.p__info_summary' | translate"
    class="confirm-value"></p>
</div>

<su-card [card]="cardConfirm" *ngIf="filledForm">
  <div card-content data-debug="form-confirm">
    <ng-container *ngIf="formFields[0].fieldGroup[0]?.form.value as formValue">
      <div style="display: flex; flex-direction: column; gap: 20px">
        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.delivery_address' | translate
              }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formValue.searchResult">
              {{ formValue.searchResult.id }},
              {{ formValue.searchResult.streetName }},
              {{ formValue.searchResult.postalCode }}
              {{ formValue.searchResult.city }}
            </span>
            <span class="confirm-value" *ngIf="formValue.deliveryAddress">
              {{ formValue.deliveryAddress.firstName }}
              {{ formValue.deliveryAddress.lastName }},
              {{ formValue.deliveryAddress.street }},
              {{ formValue.deliveryAddress.zipCode }}
              {{ formValue.deliveryAddress.city }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.searchResult && !formValue.deliveryAddress">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.desired_date.title' | translate
              }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formValue.desiredDate">
              {{ formValue.desiredDate | date: 'dd.MM.yyyy' }}
            </span>
            <span class="confirm-value" *ngIf="!formValue.desiredDate">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.description_field.title'
                  | translate
              }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formValue.description">
              {{ formValue.description }}
            </span>
            <span class="confirm-value" *ngIf="!formValue.description">
              --
            </span>
          </div>
        </div>

        <div *ngIf="formValue.attachments.length > 0">
          <su-headline skin="secondary" h="h2">
            {{ 'ticket.create.confirm.headline__attachment' | translate }}
          </su-headline>
          <div *ngFor="let file of formValue.attachments">
            <div class="confirm-label">
              <span>{{
                'ticket.create.confirm.label__attachmentType' | translate
              }}</span
              >:
            </div>
            <span class="confirm-value">{{
              'ticket.create.incidentClass.options.other.label' | translate
            }}</span>
            <br /><br />
            <div class="confirm-label">
              <span>{{
                'ticket.create.confirm.label__attachment' | translate
              }}</span
              >:
            </div>
            <span class="confirm-value">{{ file.attachment?.name }}</span>
            <span
              *ngIf="
                file !== formValue.attachments[formValue.attachments.length - 1]
              ">
              <br /><br />
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'ticket.create.furniture_order.flooring.title' | translate }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formValue.furnitureOptions.roll">
              {{
                formValue.furnitureOptions.roll === 'hard'
                  ? ('ticket.create.furniture_order.flooring.options.hard'
                    | translate)
                  : formValue.furnitureOptions.roll === 'soft'
                    ? ('ticket.create.furniture_order.flooring.options.soft'
                      | translate)
                    : '--'
              }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.furnitureOptions.roll">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.statement_BAD.title' | translate
              }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formValue.furnitureOptions.statement">
              {{
                'ticket.create.furniture_order.statement_BAD.options.statement_added'
                  | translate
              }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.furnitureOptions.statement">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.electric_desk.title' | translate
              }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formValue.furnitureOptions.electricDesk">
              {{
                'ticket.create.furniture_order.electric_desk.options.statement_added'
                  | translate
              }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.furnitureOptions.electricDesk">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.special_order.title' | translate
              }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formValue.furnitureOptions.specialOrder">
              {{
                'ticket.create.furniture_order.special_order.options.statement_added'
                  | translate
              }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.furnitureOptions.specialOrder">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.severe_disability.title'
                  | translate
              }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formValue.furnitureOptions.severeDisability">
              {{
                'ticket.create.furniture_order.severe_disability.options.consultation_rejection'
                  | translate
              }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.furnitureOptions.severeDisability">
              --
            </span>
          </div>
        </div>

        <div *ngIf="formValue.telekomCheck === false">
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.additional_agreement.title'
                  | translate
              }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formValue.furnitureOptions.additionalAgreement">
              {{
                'ticket.create.furniture_order.additional_agreement.options.agreement_added'
                  | translate
              }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.furnitureOptions.additionalAgreement">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'ticket.create.furniture_order.contact_person' | translate }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formValue.editor.searchResult.id">
              {{ formValue.editor.searchResult.fullName }},
              {{ formValue.editor.searchResult.phone }},
              {{ formValue.editor.searchResult.email }},
              {{ formValue.editor.searchResult.company }},
              {{ formValue.editor.searchResult.orgUnit }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formValue.editor.searchResult.id">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.furniture_order.psp_element.title' | translate
              }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formValue.pspElement">
              {{ formValue.pspElement }}
            </span>
            <span class="confirm-value" *ngIf="!formValue.pspElement">
              --
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <form (ngSubmit)="submit()">
      <div class="confirm-buttons">
        <su-button
          [skin]="'primary'"
          [icon]="'arrow-left'"
          [label]="'ticket.create.confirm.button__back_to_edit' | translate"
          (onClick)="pressBackToEdit()">
        </su-button>
        <su-button
          id="buttonSendInConfirmForm"
          type="submit"
          [skin]="'accent'"
          [icon]="'paper-plane'"
          [label]="'ticket.create.confirm.button__send' | translate"
          [disabled]="isSubmitted">
        </su-button>
      </div>
    </form>
  </div>
</su-card>

<app-spinner-overlay
  [show]="loading"
  [label]="loadingMessage"></app-spinner-overlay>
