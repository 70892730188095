import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MtxGrid, MtxGridColumn } from '@ng-matero/extensions/grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceRoomEditModalComponent } from './maintenance-room-edit-modal/maintenance-room-edit-modal/maintenance-room-edit-modal.component';
import { MaintenanceRoomAddNewModalComponent } from './maintenance-room-add-new-modal/maintenance-room-add-new-modal/maintenance-room-add-new-modal.component';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { DataMaintenance } from '@shared/constants/datamaintenance.constants';
import { BaseSelectViewModel } from '@core/api';
import { BehaviorSubject } from 'rxjs';
import { EditRoomService } from '@core/services/edit-room/edit-room.service';
import { ToastrService } from 'ngx-toastr';
import { OverrideModule } from '@override/override.module';

@Component({
  selector: 'app-maintenance-room',
  standalone: true,
  imports: [
    CommonModule,
    SmartusComponentsModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MtxGrid,
    FormsModule,
    MatButtonModule,
    FormlyModule,
    OverrideModule,
  ],
  templateUrl: './maintenance-room.component.html',
  styleUrl: './maintenance-room.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceRoomComponent {
  @ViewChild('deleteButtonTemplate', { static: true })
  deleteButtonTemplate: TemplateRef<any>;
  @ViewChild('editButtonTemplate', { static: true })
  editButtonTemplate: TemplateRef<any>;
  @Input() typeOfUseDetailedId: any;
  @Input() typeOfUseDetailAreaData$: BehaviorSubject<any>;
  @Input() spaceId: any;
  @Input() changeReasons: BaseSelectViewModel[];

  private translateService = inject(TranslateService);
  private dialog = inject(MatDialog);
  private cd = inject(ChangeDetectorRef);
  private editRoomService = inject(EditRoomService);
  private toastr = inject(ToastrService);

  columns: MtxGridColumn[] = [];
  selectedItem: any;
  isModalOpen = false;
  isBuildingUsageEditable = true;
  processedData: any[] = [];
  DataMaintenance = DataMaintenance;
  isLoading: boolean = false;

  formFields: FormlyFieldConfig[];
  form: FormGroup;
  cardFields: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  ngOnInit(): void {
    this.initializeColumns();

    this.typeOfUseDetailAreaData$.subscribe({
      next: () => this.parseData(),
    });

    this.formFields = this.getFormFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.typeOfUseDetailAreaData &&
      !changes.typeOfUseDetailAreaData.isFirstChange()
    ) {
      this.parseData();
      this.cd.markForCheck();
    }
  }

  parseData(): void {
    const data = this.typeOfUseDetailAreaData$.value;
    if (data) {
      this.processedData = (data.archObjUtilizationHistories || []).map(
        (history: any) => ({
          ...history,
          validFrom: this.formatDate(history.validFrom) || '--',
          validUntil: this.formatDate(history.validUntil) || '--',
          typeOfUseDetailedAreaId: history.detailAreaType
            ? `${history.usageTypeId} / ${history.detailAreaType}`
            : history.usageTypeId || '--',
          createdBy: history.createdBy || '--',
          changedBy: history.changedBy || '--',
          createDate: this.formatDate(history.createDate) || '--',
          changeDate: this.formatDate(history.changeDate) || '--',
          changeReason: history.changeReason || '--',
          status: history.status || '--',
        })
      );
    } else {
      this.processedData = [];
    }
  }

  formatDate(dateString: string) {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  initializeColumns(): void {
    this.columns = [
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.validFrom'
        ),
        field: 'validFrom',
        sortable: true,
        resizable: true,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.validUntil'
        ),
        field: 'validUntil',
        sortable: true,
        class: 'truncated',
        resizable: true,
        width: '200dp',
      },
      {
        header: this.translateService.instant(
          'edit_room.labels.typeOfUseDetailedAreaId'
        ),
        field: 'typeOfUseDetailedAreaId',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.createdBy'
        ),
        field: 'createdBy',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.changedBy'
        ),
        field: 'changedBy',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.createdOn'
        ),
        field: 'createDate',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.changedOn'
        ),
        field: 'changeDate',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.changeReason'
        ),
        field: 'changeReason',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.status'
        ),
        field: 'status',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: '',
        field: 'editIcon',
        width: '80px',
        resizable: true,
        cellTemplate: this.editButtonTemplate,
      },
      {
        header: '',
        field: 'iconRemove',
        width: '80px',
        resizable: true,
        cellTemplate: this.deleteButtonTemplate,
      },
    ];
  }

  getTrueKey(obj: { [key: string]: boolean }): string {
    if (obj) {
      for (let key in obj) {
        if (obj[key]) {
          return key;
        }
      }
    }
    return '--';
  }

  getFormFields(): FormlyFieldConfig[] {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'validFrom',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.validFrom'
              ),
              readonly: true,
            },
          },
          {
            key: 'validUntil',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.validUntil'
              ),
              readonly: true,
            },
          },
          {
            key: 'typeOfUseOrDetailedArea',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'edit_room.labels.typeOfUseOrDetailedArea'
              ),
              readonly: true,
            },
          },
          {
            key: 'createdBy',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.createdBy'
              ),
              readonly: true,
            },
          },
          {
            key: 'changedBy',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.changedBy'
              ),
              readonly: true,
            },
          },
          {
            key: 'createdDate',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.createdOn'
              ),
              readonly: true,
            },
          },
          {
            key: 'changedDate',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.changedOn'
              ),
              readonly: true,
            },
          },
        ],
      },
    ];
  }

  onRowClick(event: any) {
    this.selectedItem = event.rowData;
    this.isModalOpen = true;
  }

  openEditModal(item: any): void {
    const dialogRef = this.dialog.open(MaintenanceRoomEditModalComponent, {
      minWidth: '40vw',
      minHeight: '60vh',
      data: {
        ...item,
        typeOfUseDetailedId: this.typeOfUseDetailedId,
        changeReasons: this.changeReasons,
        spaceId: this.spaceId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshBuildingData();
        this.cd.detectChanges();
      }
    });
  }

  openAddModal(item: any): void {
    const dialogRef = this.dialog.open(MaintenanceRoomAddNewModalComponent, {
      minWidth: '40vw',
      minHeight: '35vh',
      data: {
        item: item,
        changeReasons: this.changeReasons,
        spaceId: this.spaceId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshBuildingData();
        this.cd.markForCheck();
      }
    });
  }

  refreshBuildingData(): void {
    this.editRoomService
      .getBuildingMaintenanceRoomInformation(this.spaceId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.typeOfUseDetailAreaData$.next(data);
            this.cd.markForCheck();
          }
        },
      });
  }

  editRow(event: Event, rowData: any): void {
    event.stopPropagation();
    this.openEditModal(rowData);
  }

  deleteRow(event: Event, rowData: any) {
    event.stopPropagation();
    this.isLoading = true;

    this.editRoomService.deleteArchObjUtilHistory(rowData.id).subscribe({
      next: () => {
        this.isLoading = false;
        this.cd.detectChanges();
        this.toastr.success(this.translate('edit_room.toastr.success_delete'));
        this.refreshBuildingData();
      },
      error: () => {
        this.isLoading = false;
        this.cd.detectChanges();
        this.toastr.error(this.translate('edit_room.toastr.error_delete'));
      },
    });
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
