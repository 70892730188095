<su-headline h="h2" skin="primary">{{ title }}</su-headline>

<su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>

<su-slot-tab-content *ngIf="activeTab === tabs[0].id">
  <su-card [card]="cardFields">
    <div card-content>
      <formly-form [form]="form" [fields]="formFields" [model]="formModel"></formly-form>
      <app-detail-fields-base *ngIf="task" [data]="task" [detailFields]="detailFields"></app-detail-fields-base>
    </div>
  </su-card>
</su-slot-tab-content>

<su-slot-tab-content *ngIf="activeTab === tabs[1].id">
  <su-card [card]="cardContact">
    <div card-content>
      <app-task-contact *ngIf="task" [dataContactAddress]="dataContactAddress"></app-task-contact>
    </div>
  </su-card>
</su-slot-tab-content>
<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>