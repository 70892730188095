import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  SilentRequest,
} from '@azure/msal-browser';
import { protectedResources } from '@core/auth-config';
import { UserService } from '@core/services/oauth-service/user.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallbackComponent implements OnInit, OnDestroy {
  private readonly destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalAuthService: MsalService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying$)
      )
      .subscribe({
        next: () => {
          console.log(
            '[CallbackComponent] InteractionStatus.None => navigating to /'
          );
          this.checkAndSetActiveAccount();
          this.navigateToPreviousPage();
        },
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe({
        next: () => {
          const accessTokenRequest: SilentRequest = {
            scopes: [...protectedResources.getIncident.scopes],
            account: this.msalAuthService.instance.getAllAccounts()['0'],
          };

          this.msalAuthService
            .acquireTokenSilent(accessTokenRequest)
            .toPromise()
            .then((value: AuthenticationResult) => {
              console.log(
                '[CallbackComponent] got AuthenticationResult via SilentRefresh:',
                value
              );
              this.checkAndSetActiveAccount();
              this.navigateToPreviousPage();
            })
            .catch(reason => {
              console.log(
                '[CallbackComponent] Acquire Token silent error: ',
                reason
              );
            });

          this.navigateHome();
        },
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalAuthService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalAuthService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
      this.userService.setLoginDisplay();
    }
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  navigateHome() {
    this.router.navigate(['/dashboard']);
  }

  navigateToPreviousPage() {
    //Redirect to previuous url after b2c login
    const currentPath = localStorage.getItem('previousUrl');
    if (
      location.pathname.includes('/authentication/callback') &&
      !currentPath.includes('/authentication/callback')
    ) {
      console.log('[Telekom routing]');
      this.router.navigateByUrl(currentPath);
    }
  }
}
