import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  @ViewChild('createTicketForm') createTicketFormRef?: ElementRef;

  constructor(private router: Router) {}

  scrollToForm(): void {
    this.createTicketFormRef?.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  navigateTo(route: string): void {
    this.router.navigateByUrl(route);
  }
}
