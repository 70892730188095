import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { map, mapTo, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private mobileView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  mobileViewObs$ = this.mobileView$.asObservable();
  isMobile = false;
  size!: { width: number; height: number };

  isMobile$ = fromEvent(window, 'resize').pipe(
    startWith(window),
    mapTo(window),
    map((window: Window) => {
      if (window.innerWidth < 768) {
        return true;
      }
      return false;
    })
  );
}
