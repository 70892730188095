import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@core/environments/environment';
import { RoutePath } from '@core/routing/routing.types';
import { MaintenanceService } from '@core/services/menu-service/maintenance.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit {
  maintenanceMessage = '';
  maintenanceDateMessage: string;
  isInMaintenance$ = inject(MaintenanceService).get();
  constructor(
    private router: Router,
    private translateService: TranslateService,
  ) {

    this.isInMaintenance$.subscribe(res => {
      this.maintenanceDateMessage = res; 
      this.maintenanceMessage = this.translateService.instant(
        'maintenance.message',
        {
          value: this.maintenanceDateMessage,
        }
      );
    });
  }

  ngOnInit() {
    if (environment.isInMaintenance === false) {
      if (window.location.pathname === '/landing/maintenance') {
        this.router.navigateByUrl(RoutePath.NONE);
      }
    }
  }
}
