import { NgModule } from '@angular/core';
import { TicketCreateBaseComponent } from '@features/tickets/components/ticket-create/ticket-create.base';
import { TicketGetStatusBaseComponent } from '@features/tickets/components/ticket-get-status/base/ticket-get-status';
import { TicketGetStatusDefaultComponent } from '@features/tickets/components/ticket-get-status/default/ticket-get-status-default';
import { TicketListComponent } from '@features/tickets/components/ticket-list/ticket-list.component';
import { TicketStatusItemBaseComponent } from '@features/tickets/components/ticket-status-item/base/ticket-status-item';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { SafeHtmlPipe } from '@shared/pipes';
import { SharedModule } from '@shared/shared.module';
import { TicketListHelpdeskComponent } from './components/ticket-list-helpdesk/ticket-list-helpdesk.component';
import { TicketSummaryComponent } from './components/ticket-summary/ticket-summary.component';

@NgModule({
  declarations: [
    TicketListComponent,
    TicketCreateBaseComponent,
    TicketGetStatusBaseComponent,
    TicketGetStatusDefaultComponent,
    TicketStatusItemBaseComponent,
    TicketListHelpdeskComponent,
    TicketSummaryComponent,
  ],
  exports: [TicketGetStatusDefaultComponent],
  imports: [TranslateModule, SharedModule, OverrideModule, SafeHtmlPipe],
})
export class TicketsModule {}
