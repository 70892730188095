<su-headline h="h1" skin="primary">{{ headline }}</su-headline>

<su-button
  skin="primary"
  (click)="openRentalListingEditDialog()"
  [label]="
    'website_edit.rental.RentalListingMarketing.label__create' | translate
  ">
</su-button>
<div class="parent-element">
  <form [formGroup]="searchform" class="search-container" id="searchForm">
    <mat-form-field class="formField">
      <mat-label>{{
        'website_edit.rental.label__search' | translate
      }}</mat-label>
      <input
        matInput
        [formControl]="searchform.controls['searchTerm']"
        (keyup)="applySearch()"
        [matTooltip]="'website_edit.rental.tooltip.search_help' | translate"
        matTooltipPosition="right" />
    </mat-form-field>
  </form>
</div>

<div>
  <ng-container>
    <mtx-grid
      id="rentalListings"
      [columns]="columnsRentalListings"
      [data]="filteredRentalListingObjects"
      [loading]="isLoadingObjects"
      [columnResizable]="false"
      [rowStriped]="true"
      [showPaginator]="true"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [pageSize]="10"
      (rowClick)="onSelectRentalListing($event.rowData.id)">
    </mtx-grid>
  </ng-container>
</div>
<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
