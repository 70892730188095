import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

@Component({
  selector: 'app-edit-access-orderer',
  templateUrl: './edit-access-orderer.component.html',
  styleUrls: [],
})

export class EditAccessOrdererComponent implements OnInit {
  form = new FormGroup({});

  formModel: any = {};
  
  formFields: FormlyFieldConfig[] = [
      {
        key: 'employeeInformation',
        type: 'input',
        wrappers: ['readOnly'],
        props: {
          label: this.translateService.instant('access_orderer.dialog.access_orderer'),
          readOnly: true,
          valueFormatter: () => {
              return `${this.data.row.employeeName}, ${this.data.row.employeePhoneNumber}, ${this.data.row.employeeEmail}`;
            }
        },
      },
      {
        key: 'costCenterName',
        type: 'input',
        wrappers: ['readOnly'],
        props: {
          label: this.translateService.instant('access_orderer.dialog.costcenter'),
          readOnly: true,
        },
      },
      {
          key: 'accessOrdererEnddate',
          type: 'input',
          wrappers: ['readOnly'],
          props: {
              label: this.translateService.instant('access_orderer.dialog.validUntil'),
              readOnly: true,
              valueFormatter: (endDate: Date) => {
                  const now = new Date();
                  if (!this.data.endAccessOrderer) {
                      return `${now.getDate()}. ${now.toLocaleDateString('de-DE', { month: 'long' })} ${now.getFullYear() + 1}`;
                  } else if (!endDate) {
                      return '--';
                  }
                  let r = moment(endDate)
                      .locale(this.translateService.instant('locale.locale'))
                      .format(
                      this.translateService.instant('locale.moment__date_format')
                      );
                  return r;
              },
            },
        },
  ]
  
  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<EditAccessOrdererComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.formModel = this.data.row;
  }
}
