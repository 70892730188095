import { SmartusComponentsModule } from '@affinis/smartus-components';
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-link-edit-tab',
  standalone: true,
  imports: [SmartusComponentsModule, NgIf, FormlyModule],
  template: `
    <div class="info-card_field" data-debug="formly-read-only-wrapper">
      <div class="label">{{ props.label }}:</div>
      <a (click)="this.props.onClick()" style="cursor: pointer;">
        <div class="valueOf">{{ props.value }}</div>
      </a>
    </div>
  `,
})
export class FormlyLinkEditTabComponent extends FieldType {}
