import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { SettingsService } from '@core/services/settings-service/settings.service';
import { MatDatetimepickerType } from '@mat-datetimepicker/core/datetimepicker/datetimepicker-type';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment';

@Component({
  selector: 'app-formly-field-datpicker',
  template: `<mat-form-field data-debug="formly-datepicket">
    <mat-label [innerHtml]="field.props?.label"></mat-label>
    <mat-datetimepicker-toggle
      [for]="thePicker"
      matSuffix></mat-datetimepicker-toggle>

    <mat-datetimepicker
      #thePicker
      [type]="gettype()"
      [openOnFocus]="true"
      [startView]="field.props?.startView"
      [startAt]="field.props?.startAt"
      [lang]="lang">
    </mat-datetimepicker>
    <mat-error *ngIf="formControl.errors?.matDatepickerMin">
      <span [innerText]="field.props?.errorMessageMin | translate"></span>
    </mat-error>
    <mat-error *ngIf="formControl.errors?.matDatepickerMax">
      <span [innerText]="field.props?.errorMessageMax | translate"></span>
    </mat-error>
    <mat-error *ngIf="formControl.errors?.matDatepickerParse">
      <span
        [innerText]="
          'formErrors.datepicker.matDatepickerParse' | translate
        "></span>
    </mat-error>
    <mat-error>
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
    <input
      [title]="to.label"
      matInput
      [matDatetimepicker]="thePicker"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [matDatepickerFilter]="matDatepickerFilter"
      [min]="field.props?.minDate"
      [max]="field.props?.maxDate"
      (ngModelChange)="emitChange($event)"
      (click)="thePicker.open()"
      [required]="this.templateOptions?.required === true"
      readonly
  /></mat-form-field>`,
})
export class FormlyDatePickerComponent extends FieldType implements OnInit {
  // @ts-ignore
  formControl: FormControl;

  override field: FormlyFieldConfig;

  templateOptions: any;

  lang: string;

  matDatepickerFilter: (any) => any;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private settingService: SettingsService
  ) {
    super();
    this.lang = this.settingService.language.substring(0, 2);
    //this.dateAdapter.setLocale(this.settingService.language.substring(0, 2));
  }

  ngOnInit() {
    this.templateOptions = this.field.props;

    if (this.templateOptions?.dateFilter) {
      this.matDatepickerFilter = this.templateOptions?.dateFilter;
    } else if (this.templateOptions?.allowedDates) {
      this.matDatepickerFilter = (d: moment.Moment) => {
        if (d) {
          const day = d.format('DD.MM.YYYY');
          return this.templateOptions?.allowedDates.includes(day);
        }
        return true;
      };
    }
  }

  gettype() {
    return <MatDatetimepickerType>this.to.datetype || 'date';
  }

  emitChange(ev) {
    this.field.props?.change?.call(this.field, ev);
  }
}
