import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import {
  RentalListingClient,
  RentalListingDetailViewModel,
  RentalListingOverviewViewModel,
} from '@core/api';
import { RentalSearchService } from '../rental-search.service';
import { RentalSearchComponent } from '../rental-search.component';

/**
 * Returns a MatPaginatorIntl instance with German translations.
 * @returns {MatPaginatorIntl} The MatPaginatorIntl instance with custom range label.
 */
export function getGermanPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  /**
   * Custom range label for the paginator in German.
   * @param {number} page - The current page number.
   * @param {number} pageSize - The number of items per page.
   * @param {number} length - The total number of items.
   * @returns {string} The range label in German.
   */
  paginatorIntl.getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 von ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} – ${endIndex} von ${length}`;
  };

  return paginatorIntl;
}

@Component({
  selector: 'app-rental-item-details',
  templateUrl: './rental-item-details.component.html',
  styleUrls: ['./rental-item-details.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
  ],
})
export class RentalItemDetailsComponent implements OnInit, OnChanges {
  @Input() location: RentalListingDetailViewModel & { city?: string };
  @Output() goBack = new EventEmitter<void>();
  @ViewChild('rentalItemDetails', { static: true })
  rentalItemDetails: ElementRef;

  selectedFile: string | null = null;
  similarLocations: RentalListingOverviewViewModel[] = [];
  paginatedLocations: RentalListingOverviewViewModel[] = [];
  pageSize = 4;
  galleryId: string;
  items: GalleryItem[];
  isDesktopView: boolean = true;
  circumference: number = 10;
  isLoading: boolean = false;

  constructor(
    public gallery: Gallery,
    private rentalListingClient: RentalListingClient,
    private rentalSearchService: RentalSearchService,
    private rentalSearchComponent: RentalSearchComponent
  ) {}

  ngOnInit() {
    this.initializeGalleryId();
    this.updateViewMode();
    this.loadRentalListings();
    this.initializeItems();
    this.loadGalleryItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location && changes.location.currentValue) {
      this.initializeGalleryId();
      this.updateViewMode();
      this.loadRentalListings();
      this.initializeItems();
      this.loadGalleryItems();
    }
  }

  /**
   * Initializes the gallery ID based on the location ID.
   */
  private initializeGalleryId(): void {
    this.galleryId = `rentalItemLightbox${this.location.id}`;
  }

  /**
   * Initializes the gallery items based on the location files.
   */
  private initializeItems(): void {
    const imageFileExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'tif',
      'webp',
      'tiff',
    ];
    this.items = this.location.files
      .filter(file => {
        const fileExtension = file.fileName.split('.').pop().toLowerCase();
        return imageFileExtensions.includes(fileExtension);
      })
      .map(file => new ImageItem({ src: file.uri, thumb: file.uri }));
  }

  /**
   * Loads the gallery items into the gallery reference.
   */
  private loadGalleryItems(): void {
    const galleryRef = this.gallery.ref(this.galleryId);
    galleryRef.setConfig({
      imageSize: 'contain',
      thumb: false,
      dots: true,
      counter: false,
    });
    galleryRef.load(this.items);
  }

  /**
   * Paginates the similar locations based on the page index and page size.
   * @param pageIndex The index of the current page.
   * @param pageSize The number of items per page.
   */
  paginateLocations(pageIndex: number, pageSize: number) {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    this.paginatedLocations = this.similarLocations.slice(startIndex, endIndex);
  }

  /**
   * Handles the page change event from the paginator.
   * @param event The page event containing the page index and page size.
   */
  onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex;
    const pageSize = event.pageSize;
    this.paginateLocations(pageIndex, pageSize);
  }

  /**
   * Loads the rental listings based on the current coordinates and slider value.
   */
  loadRentalListings(): void {
    this.isLoading = true;
    this.loadCityForLocation();
    this.rentalListingClient
      .getAllRentalListingObjects(
        this.location.latitude,
        this.location.longitude,
        this.circumference
      )
      .subscribe({
        next: data => {
          this.similarLocations = data.filter(
            item => item.id !== this.location.id
          );
          this.paginateLocations(0, this.pageSize);
          this.isLoading = false;
        },
        error: error => {
          console.error('Error loading rental listings:', error);
          this.isLoading = false;
        },
      });
  }

  onItemSelect(item: RentalListingOverviewViewModel) {
    this.rentalSearchComponent.onItemSelect(item);
  }

  /**
   * Host listener for the window resize event.
   * Updates the view mode based on the window width.
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateViewMode();
  }

  /**
   * Updates the view mode and page size based on the window width.
   */
  private updateViewMode() {
    this.isDesktopView = window.innerWidth > 768;
    this.pageSize = this.isDesktopView ? 4 : 2;
  }

  /**
   * Loads the city name for the current location based on its coordinates.
   */
  private loadCityForLocation(): void {
    this.rentalSearchService
      .getCityFromCoordinates(this.location.latitude, this.location.longitude)
      .then(city => {
        this.location.city = city;
      })
      .catch(error => {
        console.error('Error retrieving city:', error);
      });
  }
}
