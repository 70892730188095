import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { AfterViewInit, Component, Injector, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CreateServiceRequestRequest,
  EmploymentClient,
  FileResponse,
  IncidentClient,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { FileService } from '@core/services/file-service/file.service';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { TicketCreateBaseComponent } from '@features/tickets/components/ticket-create/ticket-create.base';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { additionalInformation } from '../field-configs/order-access/additional-information.field-config';
import { closureLocation } from '../field-configs/order-access/closure-location.field-config';
import { ordererContact } from '../field-configs/order-access/orderer-contact.field-config';
import { shippingAddress } from '../field-configs/order-access/shipping-address.field-config';
import { emailcc } from '../field-configs/ticket-create-email-cc.field-config';
import { fileAttachments } from '../field-configs/ticket-create-file-attachments.field-configs';

@Component({
  selector: 'app-order-access',
  templateUrl: './order-access.component.html',
  styleUrls: ['./order-access.component.scss'],
})
export class TicketCreateOrderAccessTelekomComponent
  extends TicketCreateBaseComponent
  implements AfterViewInit
{
  totalKeys: number;

  @Input() override navigation = true;

  @Input() override id;

  override loading = false;

  override form = new FormGroup({});

  override formModel = {};

  override formFields: FormlyFieldConfig[] = [];

  override filledForm: FormlyFieldConfig[] | null;

  override showExtraConfirmFormFields: boolean;

  override title: string;

  override showTicketCreateData: boolean;

  override patchValue: Observable<any>;

  override options: FormlyFormOptions = {
    formState: {
      mainModel: this.formModel,
    },
  };

  cardConfirm: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  cardPdf: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  private isUpdatingTotalKeysInternally = false;

  spinnerLabel = this.translateService.instant('form.loading_message');

  public currentUser$ = new BehaviorSubject<any>(null);

  constructor(
    injector: Injector,
    private breadcrumbService: BreadcrumbService,
    private employmentClient: EmploymentClient,
    private locationFormlyFieldService: LocationFormlyFieldService,
    private incidentClient: IncidentClient,
    private fileService: FileService
  ) {
    super(injector);

    this.title = this.translateService.instant(
      'ticket.create.order_access.title'
    );
    this.breadcrumbService.setBreadcrumbs([{ label: this.title }]);

    this.successTitle = this.translateService.instant(
      'ticket.create.order_access.successTitle'
    );
    this.successMessage = this.translateService.instant(
      'ticket.create.order_access.successMessage'
    );

    this.spinnerLabel = '';
    this.loading = true;
    this.currentUser$.subscribe({
      next: user => {
        if (user) {
          console.log(user.entites[0]);
          this.formFields = [
            {
              wrappers: [],
              fieldGroup: [
                {
                  type: 'chapter',
                  props: {
                    chapterTitle: this.translateService.instant(
                      'ticket.create.headline__order_information'
                    ),
                  },
                },
                {
                  type: 'input',
                  key: 'shortTitle',
                  props: {
                    label: this.translateService.instant(
                      'ticket.create.label__short_title'
                    ),
                    visibleInConfirm: true,
                    required: true,
                    maxLength: 40,
                  },
                },
              ],
            },
            ...ordererContact(this.translateService, user.entites[0]),
            ...closureLocation(
              this.translateService,
              this.locationFormlyFieldService,
              null
            ),
            ...shippingAddress(this.translateService),
            ...fileAttachments(this.translateService, true),
            ...additionalInformation(this.translateService),
            ...emailcc(this.translateService, this.onEmailChange.bind(this)),
          ];

          this.card = {
            header: {
              title: {
                label: '',
              },
            },
            footer: {
              button1: {
                label: this.translateService.instant('forward'),
                disabled: false,
                onClick: () => {
                  this.fileService.setFileAttachmentType(
                    this.form.value,
                    'SO7'
                  );
                  this.validateForm();
                },
              },
            },
          };

          this.loading = false;
          this.spinnerLabel = this.translateService.instant(
            'form.loading_message'
          );
        }
      },
    });

    const currentUserEmail = this.msalService.instance.getActiveAccount()
      ?.idTokenClaims?.email as string;

    this.employmentClient.searchEmployee(currentUserEmail).subscribe({
      next: user => {
        this.currentUser$.next(user);
      },
    });
  }

  ngAfterViewInit() {
    this.totalKeys = this.getTotalKeyQuantity() || 1;
    this.form?.valueChanges.subscribe(() => {
      if (!this.isUpdatingTotalKeysInternally) {
        this.updateTotalKeys();
      }
    });
  }

  protected override prepareSubmit = data => {
    const d = data;
    const keyServiceRequest: CreateServiceRequestRequest = {};
    keyServiceRequest.incidentTitle = d.shortTitle;
    if (this.currentUser$?.getValue()?.entites[0]?.id) {
      keyServiceRequest.serviceRequestCreatorId =
        this.currentUser$.getValue().entites[0].id;
    } else {
      keyServiceRequest.serviceRequestCreatorId = '';
    }
    keyServiceRequest.incidentLocationId = d.searchLocation.searchResult.id;
    keyServiceRequest.deliveryAddressFirstName =
      d.shippingAddress.contactInformation.firstname;
    keyServiceRequest.deliveryAddressLastName =
      d.shippingAddress.contactInformation.lastname;
    keyServiceRequest.deliveryAddressEmail =
      d.shippingAddress.contactInformation.email;
    keyServiceRequest.deliveryAddressStreet = d.shippingAddress.address.street;
    keyServiceRequest.deliveryAddressPostal = d.shippingAddress.address.zipcode;
    keyServiceRequest.deliveryAddressState = d.shippingAddress.address.city;
    keyServiceRequest.deliveryAddressCompany =
      d.shippingAddress.address.corporation;
    keyServiceRequest.deliveryAddressOrgunit =
      d.shippingAddress.address.operation;
    keyServiceRequest.additionalInfo =
      d.additionalInformation.additionalInformations;
    keyServiceRequest.mailAddresses = d.ccemail;
    keyServiceRequest.keys = [
      {
        manufacturer: d.producer,
        number: d.keyNumber,
        amount: d.keyQuantity,
        roomNumber: d.assetNumber.assetNumber,
      },
    ];
    if (d.additionalKey) {
      d.additionalKey.forEach(key => {
        keyServiceRequest.keys.push({
          manufacturer: key.producer,
          number: key.keyNumber,
          amount: key.keyQuantity,
          roomNumber: key.assetNumber.assetNumber,
        });
      });
    }

    return { serviceRequest: keyServiceRequest };
  };

  public override clientEndpoint = data => {
    return this.incidentClient.createKeyServiceRequest(data);
  };

  private updateTotalKeys() {
    this.totalKeys = this.getTotalKeyQuantity();
    this.isUpdatingTotalKeysInternally = true;
    this.formFields[0]?.form
      ?.get('additionalInformation.totalKeys')
      ?.setValue(this.totalKeys);
    this.isUpdatingTotalKeysInternally = false;
  }

  getTotalKeyQuantity(): number {
    let totalKeys = this.formFields[0]?.form?.value?.keyQuantity;
    this.formFields[0]?.form?.value?.additionalKey.forEach(additionalKey => {
      totalKeys += additionalKey.keyQuantity ?? 0;
    });
    return totalKeys;
  }

  pressBackToEdit() {
    this.filledForm = null;
  }

  showChapter(label: string): boolean {
    if (
      label ===
      this.translateService.instant('ticket.create.headline__attachment')
    ) {
      let showAttachment = false;
      if (this.formFields[0].form.value.attachments.length > 0) {
        showAttachment = true;
      }
      return showAttachment;
    }
    return true;
  }

  getAttachmentTypeLabel(value: string) {
    const types = [
      { label: 'Flurstückskarte', value: 'AUG' },
      { label: 'Grundbuchauszug', value: 'AUG' },
      { label: 'Pläne', value: 'PL7' },
      { label: 'Schriftverkehr', value: 'SVK' },
      { label: 'Sonstiges', value: 'SO7' },
    ];
    const foundType = types.find(type => type.value === value);

    if (foundType) {
      return foundType.label;
    } else {
      return null;
    }
  }

  public onEmailChange(emails: string) {
    this.form.get('ccemail')?.setValue(emails as never);
  }

  public override getStatusPath() {
    return '/dashboard';
  }

  downloadPdf() {
    this.fileService
      .downloadFile(this.blobfilename, 'application/pdf')
      .subscribe({
        next: (file: FileResponse) => {
          this.fileService.saveFile(file, file.fileName);
        },
      });
  }
}
