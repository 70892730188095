<div class="card">
  <su-card [card]="cardSearch">
    <div card-content class="form">
      <formly-form [form]="form" [fields]="formFields" [model]="formModel">
      </formly-form>

      <div
        *ngIf="
          form.get('documents')?.touched && form.get('documents')?.invalid
        ">
        <div
          *ngIf="form.get('documents')?.errors?.noPrimary"
          class="error-message">
          {{
            'website_edit.rental.RentalListingMarketing.error__noPrimaryImage'
              | translate
          }}
        </div>
        <div
          *ngIf="form.get('documents')?.errors?.multiplePrimary"
          class="error-message">
          {{
            'website_edit.rental.RentalListingMarketing.error__multiplePrimaryImages'
              | translate
          }}
        </div>
      </div>
    </div>
  </su-card>
</div>
<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
