import { Injectable } from '@angular/core';

export enum LogLevel {
  None = 0,
  Info = 1,
  Warn = 2,
  Debug = 4,
  Error = 8,
  All = LogLevel.Info || LogLevel.Warn || LogLevel.Debug || LogLevel.Error,
}

export enum LogType {
  Info = 'info',
  Warn = 'warn',
  Debug = 'debug',
  Error = 'error',
}

@Injectable()
export abstract class LoggerService {
  private logLevel: number = LogLevel.All;

  public get Level(): number {
    return this.logLevel;
  }

  public set Level(level: number) {
    this.logLevel = level;
  }

  public hasLevel(level: LogLevel): boolean {
    return (this.logLevel && level) !== 0;
  }

  abstract info(message: string, ...vargs: any[]): void;

  abstract warn(message: string, ...vargs: any[]): void;

  abstract debug(message: string, ...vargs: any[]): void;

  abstract error(message: string, ...vargs: any[]): void;
}
