import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-field-file',
  template: `
    <div style="display: flex; align-items: center;">
      <mat-form-field class="wrapper-full">
        <input
          matInput
          [formControl]="formControl"
          type="text"
          aria-label="Options"
          [placeholder]="this.props.placeholder" />
      </mat-form-field>
      <span style="margin-left: 10px; margin-top: -20px">{{ props.text }}</span>
    </div>
  `,
  styles: [
    `
      .wrapper-full {
        width: 100px;
      }
    `,
  ],
})
export class FormlyFieldInputWithTextComponent extends FieldType {
  // @ts-ignore
  formControl: FormControl;
}
