import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-formly-checkbox',
  standalone: true,
  imports: [MatFormFieldModule, MatCheckboxModule, ReactiveFormsModule],
  template: `
    <div
      class="info-card_field checkbox-container"
      data-debug="formly-read-only-wrapper">
      <div class="label">{{ props.label }}:</div>
      <mat-checkbox
        [checked]="
          formControl.value !== null ? formControl.value : props?.value ?? false
        "
        (change)="onCheckboxChange($event)">
      </mat-checkbox>
    </div>
  `,
  styles: [
    `
      .checkbox-container {
        display: flex;
        align-items: center;
        height: 20px;
      }
      .checkbox-container mat-checkbox {
        margin: 0; /* Adjust as needed */
      }
    `,
  ],
})
export class FormlyCheckboxComponent extends FieldType implements OnInit {
  ngOnInit() {
    if (this.formControl.value === null) {
      this.formControl.setValue(this.props?.value || false);
    }
  }
  onCheckboxChange(event: MatCheckboxChange) {
    this.formControl.setValue(event.checked);
  }
}
