import { Component, Input, ViewEncapsulation } from '@angular/core';

import { LocationMapService } from './location-map.service';
import * as atlas from 'azure-maps-control';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationMapComponent {
  @Input()
  public set addressString(value: string) {
    if (value) {
      this.setCoordinates(value);
    }
  }

  public map: atlas.Map;
  public currentLocationCoordinates?: [number, number] = [0, 0];

  constructor(private mapsService: LocationMapService) {}

  private async setCoordinates(addressString: string) {
    this.currentLocationCoordinates =
      await this.mapsService.getCoordinatesFromAddress(addressString);

    console.log(
      ' [LocationMapComponent] currentLocationCoordinates',
      this.currentLocationCoordinates
    );

    this.map = new atlas.Map('azure-map', {
      ...this.mapsService.DefaultOptions,
      disableTelemetry: true,
      center: this.currentLocationCoordinates,
      style: 'satellite',
    });

    this.map.events.add('ready', async () => {
      const dataSource = new atlas.source.DataSource();
      this.map.sources.add(dataSource);

      const point = new atlas.Shape(
        new atlas.data.Point(
          new atlas.data.Position(
            this.currentLocationCoordinates![0],
            this.currentLocationCoordinates![1]
          )
        )
      );

      dataSource.add([point]);
      this.map.layers.add(new atlas.layer.SymbolLayer(dataSource));
    });
  }
}
