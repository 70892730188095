<div *ngIf="items.length > 0; else noItemsFound">
  <div class="card-container">
    <scale-card
      class="card-style"
      *ngFor="let item of paginatedItems"
      (click)="selectItem(item)">
      <div class="card-content">
        <div class="card-image-container">
          <img
            class="card-image"
            [src]="item.imageUrl"
            onerror="this.onerror=null;this.src='../../../assets/icons/scan_delete.svg'"
            alt="{{ item.title }}" />
          <div class="card-text-overlay">
            <h4>{{ item.title }}</h4>
          </div>
        </div>
      </div>
    </scale-card>
  </div>
  <mat-paginator
    *ngIf="showPaginator"
    [length]="totalSize"
    [pageSize]="pageSize"
    hidePageSize
    (page)="onPageChange($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>
<ng-template #noItemsFound>
  <div class="no-items-found">
    <p>{{ 'rem.rental.label__noItems' | translate }}</p>
  </div></ng-template
>
