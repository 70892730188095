import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

import { EmploymentSearchResultDto } from '@core/api';
import { EmployeeSearchFormlyFieldService } from '@core/services/employee-search-formly-field-service/employee-search-formly-field.service';
import { checkIfEmailIdentical } from '@shared/utils/email-validator';
import { RegexpEmail } from '@shared/utils/regex';
import { FormModes } from '../ticket-create.types';
import {
  newSalutation,
  phoneConstraints,
  phoneValidationMessages,
} from './ticket-created-shared.field-configs';

export function searchContact(
  translateService: TranslateService,
  employeeFormlyFieldService: EmployeeSearchFormlyFieldService,
  mode: FormModes,
  currentUserEmail: string,
  currentUser?: EmploymentSearchResultDto
): Array<FormlyFieldConfig> {
  if (mode === 'helpdesk') {
    return [
      {
        wrappers: [],
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: translateService.instant(
                'ticket.create.headline__search_creator'
              ),
            },
          },
          {
            key: 'searchContact',
            fieldGroup: [
              ...employeeFormlyFieldService.autocompleteSearch(
                'ticket.create.placeholder__search_contact',
                null,
                true,
                true,
                currentUserEmail,
                null,
                null,
                currentUser
              ),
            ],
          },
        ],
      },
    ];
  }

  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__search_creator'
            ),
            tooltip: translateService.instant(
              'ticket.create.tooltip__search_contact'
            ),
          },
        },
        {
          key: 'searchContact',
          fieldGroup: [
            ...employeeFormlyFieldService.autocompleteSearch(
              'ticket.create.placeholder__search_contact',
              null
            ),
          ],
        },
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__contact'
            ),
            chapterText: translateService.instant(
              'ticket.create.p__info_contact'
            ),
          },
        },
        {
          key: 'requestingContact',
          fieldGroup: [
            ...newSalutation(translateService),
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'firstname',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__firstName'
                    ),
                    visibleInConfirm: true,
                    required: true,
                    maxLength: 50,
                  },
                },
                {
                  type: 'input',
                  key: 'lastname',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__lastName'
                    ),
                    visibleInConfirm: true,
                    maxLength: 50,
                    required: true,
                  },
                },
              ],
            },
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'phonenumber',
                  defaultValue: '0049',
                  wrappers: ['tooltip', 'form-field'],
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__phone'
                    ),
                    visibleInConfirm: true,
                    required: true,
                    tooltip: translateService.instant(
                      'ticket.create.tooltip__phone'
                    ),
                    ...phoneConstraints,
                  },
                  validation: phoneValidationMessages(translateService),
                },
              ],
            },
            {
              type: 'flex-layout',

              validators: {
                fieldMatch: {
                  expression: checkIfEmailIdentical,
                  message: translateService.instant(
                    'formErrors.email.not_identical'
                  ),
                  errorPath: 'confirmEmail',
                },
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'email',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__email'
                    ),
                    visibleInConfirm: true,
                    required: true,
                    pattern: RegexpEmail,
                    maxLength: 100,
                  },
                  validation: {
                    messages: {
                      pattern: () =>
                        translateService.instant('formErrors.email.pattern'),
                    },
                  },
                },
                {
                  type: 'input',
                  key: 'confirmEmail',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__confirm_email'
                    ),
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
