import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <h1 mat-dialog-title>
      {{
        'website_edit.rental.RentalListingMarketing.confirmationDialog.title'
          | translate
      }}
    </h1>
    <div mat-dialog-content>
      <p>
        {{
          'website_edit.rental.RentalListingMarketing.confirmationDialog.message'
            | translate
        }}
      </p>
    </div>
    <div mat-dialog-actions class="button-container">
      <su-button
        skin="secondary"
        [label]="
          'website_edit.rental.RentalListingMarketing.confirmationDialog.cancel'
            | translate
        "
        (click)="onCancel()">
      </su-button>
      <su-button
        skin="primary"
        [label]="
          'website_edit.rental.RentalListingMarketing.confirmationDialog.confirm'
            | translate
        "
        (click)="onConfirm()">
      </su-button>
    </div>
  `,
  styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private translateService: TranslateService
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
