import { BreadcrumbSlotItem } from '@affinis/smartus-components/lib/breadcrumbs/breadcrumbs.types';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RoutePath } from '@core/routing/routing.types';
import { AppInsightsService } from '@core/services/app-insights.service';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { MandantConfigService } from '@core/services/mandant-config-service/mandant-config.service';
import { MenuService } from '@core/services/menu-service/menu.service';
import { UserService } from '@core/services/oauth-service/user.service';
import { StartupService } from '@core/services/startup-service/startup.service';
import { Observable, filter, of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // viewport width detection
  isTablet = false;
  isMobile = false;

  title = 'Affinis_SmartPortal_Frontend-v1';

  // side-nav config
  isSideNavOpen = false;
  hasSideNav = true;
  brandingLogoXl = '';
  brandingLogoXs = '';
  brandingTitle = '';
  activeNavItem = '';
  navItemsTree = [];
  metaNavItemsTree = [];

  // top-bar config
  breadcrumbs: Array<BreadcrumbSlotItem> = [];
  topBarBtns = [
    {
      id: 'Feedback',
      label: 'Feedback',
      onClick: () => {
        this.router.navigate(['/feedback']);
      },
      disabled: false,
      icon: 'thumbs-up',
    },
  ];

  accountBtns = [
    {
      id: 'Profil',
      label: 'Profil',
      onClick: () => {
        this.router.navigate(['/profile']);
      },
      disabled: false,
      icon: 'user',
    },
    {
      id: 'Reset_PW',
      label: 'Passwort zurücksetzen',
      onClick: () => {
        this.userService.OnResetPasswordClick();
      },
      disabled: false,
      icon: 'unlock',
    },
    {
      id: 'Logout',
      label: 'Ausloggen',
      onClick: () => {
        this.userService.OnLogoutClick();
      },
      disabled: false,
      icon: 'right-from-bracket',
    },
  ];

  //Account buttons for GSUS employees
  accountBtnsGsus = [
    {
      id: 'Logout',
      label: 'Ausloggen',
      onClick: () => {
        this.userService.OnLogoutClick();
      },
      disabled: false,
      icon: 'right-from-bracket',
    },
  ];

  public isLandingModule: Observable<boolean> = of(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private appInsightsService: AppInsightsService,
    private startupService: StartupService,

    private breadcrumbsService: BreadcrumbService,
    private http: HttpClient,
    private mandantConfigService: MandantConfigService,
    private menuService: MenuService,
    private router: Router,
    public userService: UserService
  ) {
    const config = this.mandantConfigService.mandant;
    this.brandingLogoXl = config.logo;
    this.brandingLogoXs = config.logo;
    this.getScreenSize();
    this.menuService.menuChange$.subscribe({
      next: menu => {
        this.router.events
          .pipe(filter(e => e instanceof NavigationEnd))
          .subscribe({
            next: () => {
              this.activeNavItem = null;
              if ('side-nav' in menu === false) {
                return;
              }
              menu['side-nav'].forEach(sidenavItem => {
                sidenavItem.items.forEach(subItem => {
                  if (
                    window.location.origin + subItem.link ===
                    window.location.href
                  ) {
                    this.activeNavItem = subItem.id;
                  }
                });
              });
            },
          });

        if (menu === null || menu === undefined) {
          const metaNav = [];
          menu['meta-nav'].forEach(item => {
            metaNav.push({ label: item.label, url: `.${item.link}` });
          });
          this.metaNavItemsTree = metaNav;
        }

        if (menu['meta-nav'] && menu['meta-nav'].length > 0) {
          const metaNav = [];
          menu['meta-nav'].forEach(item => {
            metaNav.push({ label: item.label, url: `${item.link}` });
          });
          this.metaNavItemsTree = metaNav;
        }

        if (menu['side-nav'] && menu['side-nav'].length > 0) {
          const sideNav = [];
          menu['side-nav'].forEach(item => {
            const subItems = [];
            if (item.items && item.items.length > 0) {
              item.items.forEach(subItem => {
                subItems.push({
                  id: subItem.id,
                  label: subItem.label,
                  icon:
                    subItem.icon && subItem.icon !== '' ? subItem.icon : 'box',
                  onClick: () => {
                    this.router.navigateByUrl(subItem.link);
                  },
                });
              });
            }
            sideNav.push({
              label: item.label,
              items: subItems,
            });
          });
          this.navItemsTree = sideNav;
        }
      },
    });

    this.router.events.subscribe({
      next: () => {
        const routePath =
          this.activatedRoute?.snapshot.firstChild?.routeConfig?.path;
        this.isLandingModule = of(
          routePath === RoutePath.LANDING ||
            routePath === RoutePath.PRIVACY_REM ||
            routePath === RoutePath.PRIVACY_GENERAL
        );
      },
    });

    // Activate the current page navigation in side-nav
    // this.router.events
    //   .pipe(filter(event => event instanceof RoutesRecognized),
    //     map( (event: RoutesRecognized) => {
    //       return event.state.root.firstChild.data;
    //     }))
    //   .subscribe({
    //     next: data => {
    //       this.activeNavItem = null;
    //
    //       const  targetPath = ('parentNav' in  data) ?
    //           '/' + data.parentNav
    //         : window.location.pathname
    //
    //       console.log('>>>>>>>>>>>>> GO', targetPath)
    //
    //       menu['side-nav'].forEach( sidenavItem =>{
    //         sidenavItem.items.forEach( subItem =>{
    //           if(subItem.link ===  targetPath ){
    //             this.activeNavItem = subItem.id
    //           }
    //         } )
    //       })
    //     },
    //   });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    if (window.innerWidth <= 1023) {
      this.isTablet = true;
      this.isSideNavOpen = false;
    } else if (window.innerWidth <= 767) {
      this.isMobile = true;
      this.isSideNavOpen = false;
    } else {
      this.isTablet = false;
      this.isMobile = false;
    }
  }

  ngOnInit(): void {
    this.breadcrumbsService.bcChange$.subscribe({
      next: data => {
        this.breadcrumbs = data;
      },
    });
  }

  // side-nav functions
  handleSideNavToggle() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }

  onSideNavItemClick(value: string) {
    this.activeNavItem = value;
    if (this.isTablet) {
      this.isSideNavOpen = false;
    }
  }

  // breadcrumbs functions todo
  handleBackBtnClick() {
    history.back();
  }

  handleHomeBtnClick() {
    this.router.navigate(['/']);
  }
  login() {
    this.userService.login();
  }
}
