<su-headline h="h1" skin="primary">{{
  translateKeyTitle | translate
}}</su-headline>
<div class="description">
  <p
    [innerText]="translateKeyDescription | translate"
    class="confirm-value"></p>
</div>

<su-card [card]="card">
  <div card-content data-debug="form-confirm">
    <ng-container *ngIf="showTicketCreateData; else elseShowTicketCreateData">
      <ng-container *ngFor="let formItem of filledForm; let i = index">
        <div  *ngIf="(formItem.type == 'input' || formItem.type == 'textarea' || formItem.type == 'select')
                      && formItem.data.key != 'confirmEmail'
                      && formItem.data.key != 'attachmentType'">
          <div>
            <div class="confirm-label">
              <span>{{ 'ticket.create.confirm.label__' + formItem.data.key | translate }}</span
                >:
            </div>
            <span class="confirm-value" *ngIf="formItem.value">{{ formItem.value }}</span>
            <span class="confirm-value" *ngIf="!formItem.value">--</span>
          </div>
          <br *ngIf="i !== filledForm.length - 1"/>
        </div>
        <div *ngIf="formItem.type == 'chapter' && formItem.visibleInConfirm">
          <su-headline skin="secondary" h="h2">
            <span *ngIf="formItem.data.props.chapterTitle == 'Anhang hinzufügen' && showChapter(formItem.label)">
              {{
                'ticket.create.confirm.headline__attachment' | translate
              }}
            </span>
            <span *ngIf="formItem.data.props.chapterTitle == 'Ort der Leistung'">
              {{
                'ticket.create.confirm.headline__objectAdress' | translate
              }}
            </span>
        </su-headline>
        </div>
        <div *ngIf="formItem.type === 'preview-autocomplete' && formItem.visibleInConfirm && formItem.label === ('location.autocomplete.label__placeholder' | translate)">
          <div class="confirm-label">
            <span
              [innerText]="
                'ticket.create.confirm.label__placeholder' | translate
              "></span
            >:
          </div>
          <span class="confirm-value" *ngIf="formItem.value?.id || formItem.value?.city || formItem.value?.streetName">
            {{ formItem.value?.id }}<span *ngIf="formItem.value?.id">,</span>
            {{ formItem.value?.city }}<span *ngIf="formItem.value?.city">,</span>
            {{ formItem.value?.streetName }}
          </span>
          <span class="confirm-value" *ngIf="!(formItem.value?.id || formItem.value?.city || formItem.value?.streetName)">--</span>
          <br />
          <br />
        </div>
        <div *ngIf="formItem.type === 'preview-autocomplete' && formItem.visibleInConfirm && (formItem.label === ('ticket.create.placeholder__search_contact' | translate) || formItem.label === ('ticket.create.placeholder__search_processor' | translate))">
          <div class="confirm-label">
            <span *ngIf="formItem.label === ('ticket.create.placeholder__search_contact' | translate)">{{'ticket.create.headline__search_creator' | translate}}</span>
            <span *ngIf="formItem.label === ('ticket.create.placeholder__search_processor' | translate)">{{'ticket.create.headline__search_processor' | translate}}</span>:
          </div>
          <span class="confirm-value"  *ngIf="formItem.value?.id">
            {{formItem.value?.fullName}}, {{formItem.value?.email}}
          </span>
          <span class="confirm-value" *ngIf="!(formItem.value?.id)">--</span>
          <br />
          <br />
        </div>
        <div *ngIf="formItem.type == 'select' && formItem.data.key == 'attachmentType'">
          <div class="confirm-label">
            <span>{{ 'ticket.create.confirm.label__' + formItem.data.key | translate }}</span
              >:
          </div>
          <span class="confirm-value">{{ formItem.value }}</span>
          <br /><br />
        </div>
        <div *ngIf="formItem.type == 'file'">
          <div class="confirm-label">
            <span>{{ 'ticket.create.confirm.label__' + formItem.data.key | translate }}</span
              >:
          </div>
          <span class="confirm-value">{{ formItem.value }}</span>
          <br /><br />
        </div>
      </ng-container>
    </ng-container>

    <ng-template #elseShowTicketCreateData>
      <div *ngFor="let field of filledForm">
        <ng-container *ngIf="field.visibleInConfirm">
          <div [ngSwitch]="field.type" class="confirm-row">
            <ng-container *ngSwitchCase="'textarea'">
              <div>
                <strong>
                  <p [innerText]="field.label + ':'"></p>
                </strong>
                <div *ngIf="field.value" [innerText]="field.value" style="word-wrap: break-word;"></div>
                <div *ngIf="!field.value" [innerText]="'--'"></div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'formly-template'">
              <div [innerHtml]="field.data.template"></div>
            </ng-container>

            <ng-container *ngSwitchCase="'chapter'">
              <!--TODO-->
              <ng-container *ngIf="showChapter(field.label)">
                <su-headline skin="secondary" h="h2">
                  <span *ngIf="field.label != 'Anhang hinzufügen'">
                    {{ field.label }}
                  </span>
                  <span *ngIf="field.label == 'Anhang hinzufügen'">
                    {{
                      'ticket.create.confirm.headline__attachment' | translate
                    }}
                  </span>
                </su-headline>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'matdatetimepicker'">
              <div fxFlex class="confirm-label">
                <span [innerText]="field.label"></span>:
              </div>
              <div fxFlex class="confirm-value">
                <span
                  *ngIf="!field?.data?.templateOptions?.dateTimeFormatConfirm">
                  {{ field.value }}
                </span>
                <span
                  *ngIf="field?.data?.templateOptions?.dateTimeFormatConfirm">
                  {{
                    field.value
                      | date : field?.data?.templateOptions?.dateTimeFormat
                  }}
                </span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'select'">
              <div fxFlex class="confirm-label">
                <span [innerText]="field.label"></span>:
              </div>
              <div *ngIf="field.value" fxFlex [innerText]="field.value" class="confirm-value"></div>
              <div *ngIf="!field.value" fxFlex [innerText]="'--'" class="confirm-value"></div>
            </ng-container>

            <ng-container *ngSwitchCase="'tree-select'">
              <div fxFlex class="confirm-label">
                <span [innerText]="field.label"></span>:
              </div>
              <div *ngIf="field.value" fxFlex [innerText]="field.value" class="confirm-value"></div>
              <div *ngIf="!field.value" fxFlex [innerText]="'--'" class="confirm-value"></div>
            </ng-container>

            <ng-container *ngSwitchCase="'pasm-autocomplete'">
              <div fxFlex class="confirm-label">
                <span [innerText]="field.label"></span>:
              </div>
              <div fxFlex class="confirm-value">
                <span *ngIf="field.value.siteName"
                  >{{ field.value.siteName }};</span
                >
                <span *ngIf="field.value.siteAdditionalNumber"
                  >{{ field.value.siteAdditionalNumber }};</span
                >
                <span *ngIf="field.value.vpsz">{{ field.value.vpsz }};</span>
                <span *ngIf="field.value.address.streetName">
                  {{ field.value.address.streetName }},
                  {{ field.value.address.postalCode }}
                  {{ field.value.address.city }}
                </span>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div fxFlex class="confirm-label">
                <span [innerText]="field.label"></span>:
              </div>
              <div class="confirm-value" fxFlex [innerText]="field.value"></div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-template>
    <hr *ngIf="showExtraConfirmFormFields"/>

    <form (ngSubmit)="validateConfirmedForm()" [formGroup]="extraConfirmForm">
      <div *ngIf="showExtraConfirmFormFields">
        <formly-form
          [form]="extraConfirmForm"
          [fields]="extraConfirmFormFields"
          [model]="extraConfirmFormModel">
        </formly-form>
      </div>

      <div class="confirm-buttons">
        <su-button
          [skin]="'primary'"
          [icon]="'arrow-left'"
          [label]="'ticket.create.confirm.button__back_to_edit' | translate"
          (onClick)="pressBackToEdit()">
        </su-button>
        <su-button
          id="buttonSendInConfirmForm"
          type="submit"
          [skin]="'accent'"
          [icon]="'paper-plane'"
          [label]="'ticket.create.confirm.button__send' | translate"
          [disabled]="isSubmitted">
        </su-button>
      </div>
    </form>
  </div>
</su-card>
