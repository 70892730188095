import { Injectable } from '@angular/core';

import { UserClient } from '@core/api';
import { IAddButtonConfig } from '@shared/components/table-base/table-base.types'; //AddButtonType
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  config = <IAddButtonConfig>{
    label: 'crud.add',
  };

  constructor(private userClient: UserClient) {}

  // TODO
  // initAddButton$(type: AddButtonType): Observable<IAddButtonConfig> {
  //   switch (type) {
  //     case AddButtonType.ENABLED:
  //       return of(<IAddButtonConfig>{
  //         label: this.config.label,
  //         disabled: false,
  //       });
  //     case AddButtonType.RENTAL_CONTRACT:
  //       return this.isAddDisabledRentalContract().pipe(
  //         switchMap((disabled: boolean) => {
  //           return of(<IAddButtonConfig>{
  //             label: this.config.label,
  //             disabled,
  //           });
  //         })
  //       );
  //     default:
  //       throw Error('initAddButton not implemented');
  //   }
  // }

  private isAddDisabledRentalContract(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.userClient.getRentalContractForUser().subscribe({
        next: value => {
          observer.next(value == null);
        },
        error: () => {
          observer.next(true);
        },
      });
    });
  }
}
