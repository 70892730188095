<div class="contactHeadline">
  <su-headline [hidden]="filledForm" h="h1" skin="primary"
    >{{title}}</su-headline
  >
</div>

<div [style]="descriptionContainerStyle">
  <div
  *ngIf="!filledForm"
  class="sub-headline contactDescription"
  [style]="descriptionStyle"
  [innerHtml]="description | safeHtml"
  ></div>
</div>

<div [style]="subDescriptionContainerStyle">
  <div
  *ngIf="!filledForm"
  class="sub-headline contactDescription"
  [style]="subDescriptionStyle"
  [innerHtml]="subDescription | safeHtml"
  ></div>
</div>

<form (ngSubmit)="validateForm()" [formGroup]="form">
  <div [hidden]="filledForm">
    <su-card [card]="card">
      <div card-content>
        <formly-form
          [fields]="formFields"
          [form]="form"
          [model]="formModel"
          [options]="options"></formly-form>
          <div class="confirm-information" *ngIf="dsgvoUrl && dsgvoUrl !== ''">
            <a [href]="dsgvoUrl" target="_blank">
              <mat-icon>description</mat-icon>
              <span
                [innerText]="
                  'ticket.create.confirm.label__dsgvo_link' | translate
                "></span>
            </a>
          </div>
      </div>
    </su-card>
  </div>
</form>

<div *ngIf="filledForm">
  <app-form-confirm
    [filledFields]="filledForm"
    [extraConfirmFormFields]="showTicketCreateData ? !extraConfirmFormFields : extraConfirmFormFields"
    [backButton]="!navigation"
    [showTicketCreateData]="showTicketCreateData"
    [showExtraConfirmFormFields]="showExtraConfirmFormFields"
    (confirmed)="submit()"
    (backToEdit)="discardSumUpForm()"></app-form-confirm>
</div>

<app-spinner-overlay [show]="loading" [label]="loadingMessage"></app-spinner-overlay>
