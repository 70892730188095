import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormlyPreviewAutocompleteColumnDefinition,
  FormlyPreviewAutocompleteProps,
} from '@core/services/formly-service/formly-service.types';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-form-preview-autocomplete-type',
  templateUrl: './formly-preview-autocomplete-type.component.html',
  styleUrls: ['./formly-preview-autocomplete-type.component.style.scss'],
})
export class FormlyPreviewAutocompleteTypeComponent
  extends FieldType<FieldTypeConfig<FormlyPreviewAutocompleteProps>>
  implements AfterViewInit, OnDestroy, OnInit
{
  readonly typeAheadProperty = 'typeAheadValue';
  private defaultListObsv: Observable<unknown[]>;
  private initialSearch = false;
  private idProperty: string;
  private typeAheadValue: string;

  columnDefinitions: FormlyPreviewAutocompleteColumnDefinition[];
  filteredList$: Observable<unknown[]>;
  isLoading = false;
  searchTypeAhead$ = new Subject<string>();
  selectedResult: unknown;
  translatePath = '';

  readOnly = false;

  constructor(
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.defaultListObsv = this.props?.defaultList ?? of([] as unknown[]);
    this.idProperty = this.props?.bindValue ?? 'id';

    if (this.props.currentUser) {
      this.readOnly = true;
      this.selectedResult = this.props.currentUser;
      this.value = this.selectedResult;
    }

    this.filteredList$ = this.searchTypeAhead$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.setLoading(true)),
      switchMap((value: string) => {
        this.typeAheadValue = value?.trim();
        return (
          this.typeAheadValue && this.typeAheadValue !== ''
            ? (this.props?.onSearch(this.typeAheadValue, false) as Observable<
                unknown[]
              >)
            : this.defaultListObsv
        ).pipe(
          catchError(() => {
            this.toastr.error(
              this.translateService.instant(
                'crud.details.error.failed_to_load_msg'
              )
            );
            this.toastr.error(this.translateService.instant('alert.error'));
            return of([]);
          }),
          tap((result: unknown[]) => {
            this.setLoading(false);
            if (result.length === 1 && this.initialSearch) {
              this.selectedResult = result[0];
              this.value = result[0];
              this.setValue(this.selectedResult, this.initialSearch);
            } else if (
              this.initialSearch &&
              this.value[this.typeAheadProperty] === this.typeAheadValue
            ) {
              this.selectedResult = result.find(
                x => x[this.idProperty] === this.value[this.idProperty]
              );
              this.setValue(this.selectedResult, this.initialSearch);
            }
            this.initialSearch = false;
          })
        );
      })
    );
  }

  ngAfterViewInit(): void {
    this.translatePath = this.props?.translatePath ?? '';
    this.columnDefinitions = this.props?.columnDefinitions ?? [];

    if (this.props.defaultList) {
      this.searchTypeAhead$.next('');
    }
    if (this.props?.defaultValue || this.value) {
      this.initialSearch = true;
      this.formControl.setValue(this.value ?? this.props?.defaultValue);
      this.searchTypeAhead$.next(
        this.value[this.typeAheadProperty] ??
          this.value[this.idProperty] ??
          this.props?.defaultValue[this.idProperty]
      );
    }
    this.cd.detectChanges();
  }

  setValue(value: unknown, initialSearch = false): void {
    if (!value) {
      return;
    }

    this.selectedResult = value;
    this.value = {
      ...this.value,
      [this.typeAheadProperty]: this.typeAheadValue,
    };

    if (this.props?.onSelect) {
      this.props?.onSelect(value[this.idProperty], initialSearch, value);
    }
  }

  refreshComponent: boolean = false;

  toggleComponent() {
    this.refreshComponent = !this.refreshComponent;
  }

  deleteSelected() {
    this.value = undefined;
    this.selectedResult = null;
    this.searchTypeAhead$.next('');

    if (this.props?.onDelete) {
      this.props?.onDelete();
    }
    this.toggleComponent();
  }

  getOptionLabelFormatted(
    option: unknown,
    column: FormlyPreviewAutocompleteColumnDefinition
  ) {
    let label = option?.[column.fieldName];
    if (label) {
      return label;
    }
    label = column.formatter ? column.formatter(option) : '';
    return label;
  }

  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.cd.detectChanges();
  }
}
