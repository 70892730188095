import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { FieldType, FormlyModule } from '@ngx-formly/core';

export interface IOption {
  value: string;
  label: string;
  checked?: boolean;
}
@Component({
  selector: 'app-formly-checkbox-multiple-options',
  standalone: true,
  imports: [
    SmartusComponentsModule,
    NgIf,
    FormlyModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
  ],
  template: `
    <div class="container">
      <label>{{ props.label || '' }}</label>
      <div>
        <mat-checkbox
          *ngFor="let option of optionList"
          [(ngModel)]="option.checked"
          (change)="onCheckboxChange(option)">
          {{ option.label }}
        </mat-checkbox>
      </div>
    </div>
  `,
  styles: `
    .container {
      display: flex;
      align-items: center;
    }
  `,
})
export class FormlyCheckboxMultipleOptionsComponent
  extends FieldType
  implements OnInit
{
  optionList: IOption[];

  ngOnInit() {
    this.optionList = this.field.props.options as IOption[];
  }

  onCheckboxChange(selectedOption: IOption) {
    this.optionList.forEach(option => {
      option.checked = option === selectedOption;
    });
    this.formControl.setValue(selectedOption.value);
  }
}
