<h2 [innerHtml]="config.title | translate"></h2>
<form (ngSubmit)="validateForm()" [formGroup]="form" data-debug="form-base">
  <div [hidden]="filledForm">
    <p [innerHtml]="config.description | translate"></p>

    <formly-form
      [form]="form"
      [fields]="formFields"
      [model]="formModel"
      [options]="options"></formly-form>

    <div>
      <su-button
        label="'forward'|translate "
        type="submit"
        skin="primary"
        [disabled]="!form.valid">
      </su-button>
    </div>
  </div>
</form>
<div *ngIf="filledForm">
  <app-form-confirm
    [filledFields]="filledForm"
    [extraConfirmFormFields]="extraConfirmFormFields"
    [translateKeyTitle]="config.confirm.title"
    [translateKeyDescription]="config.confirm.description"
    (confirmed)="submit()"
    (backToEdit)="discardSumUpForm()"></app-form-confirm>
</div>

<pre *ngIf="isDevMode">
    {{form.value | json}}
  </pre
>

<app-spinner-overlay [show]="loading"></app-spinner-overlay>
