import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeModule,
} from '@angular/material/tree';
import {
  ArchObjHierarchyViewModel,
  ArchObjTreeViewModel,
  CostCenterClient,
  LocationClient,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { OverrideModule } from '@mandants/telekom/override/override.module';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  GANTT_GLOBAL_CONFIG,
  GanttDate,
  GanttGroup,
  GanttItem,
  GanttViewOptions,
  GanttViewType,
  NgxGanttComponent,
  NgxGanttModule,
} from '@worktile/gantt';
import { EditCostcenterusageComponent } from './edit-costcenterusage/edit-costcenterusage.component';
import { AppGanttFlatComponent } from './flat/flat.component';
import { Q } from '@angular/cdk/keycodes';

interface IGanttItem extends GanttItem {
  roomTitle?: string;
  workspaceTitle?: string;
  costCenterId?: string;
  areaString?: string;
}

const TREE_DATA: ArchObjTreeViewModel[] = [];

/** Flat node with expandable and level information */
interface FlatNode {
  expandable?: boolean;
  name?: string;
  level?: number;
}

@Component({
  selector: 'app-costcenter-usage',
  standalone: true,
  imports: [
    CommonModule,
    SmartusComponentsModule,
    TranslateModule,
    OverrideModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgxGanttModule,
    MatDialogModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    AppGanttFlatComponent,
  ],
  providers: [
    {
      provide: GANTT_GLOBAL_CONFIG,
      useValue: {
        dateFormat: {
          hour: 'HH:mm',
          day: 'dd. MMM yyyy',
          week: 'w',
          month: 'LLL',
          quarter: 'QQQ',
          year: 'yyyy',
          yearMonth: 'LLL yyyy',
          yearQuarter: `QQQ yyyy`,
        },
      },
    },
  ],
  templateUrl: './costcenterusage.component.html',
  styleUrls: ['./costcenterusage.component.scss'],
})
export class CostcenterusageComponent implements OnInit {
  headline = this.translateService.instant(
    'menu.data_maintenance.component.costcenter_usage'
  );

  form = new FormGroup({});

  formFields: Array<FormlyFieldConfig>;

  formModel = {};

  isLoading = false;

  selectedLocation: ArchObjHierarchyViewModel;

  private transformer = (node: ArchObjTreeViewModel, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.displayName,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  cardArchitecture: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'costcenter.costcenter_usage.architecture'
        ),
      },
    },
  };

  cardView: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'costcenter.costcenter_usage.view'
        ),
      },
    },
  };

  items: IGanttItem[] = [];

  currentYear = new Date().getFullYear();

  viewOptions: GanttViewOptions = {
    start: new GanttDate(new Date(this.currentYear, 0, 1)),
    end: new GanttDate(new Date(this.currentYear, 11, 31)),
    min: new GanttDate(new Date(this.currentYear, 0, 1)),
    max: new GanttDate(new Date(this.currentYear, +1, 11, 31)),
    cellWidth: 80,
  };

  options = {
    viewType: GanttViewType.quarter,
    draggable: false,
    styles: {
      lineHeight: 50,
      barHeight: 20,
    },
  };

  views = [
    {
      name: this.translateService.instant('costcenter.costcenter_usage.week'),
      value: GanttViewType.week,
    },
    {
      name: this.translateService.instant('costcenter.costcenter_usage.month'),
      value: GanttViewType.month,
    },
    {
      name: this.translateService.instant(
        'costcenter.costcenter_usage.quarter'
      ),
      value: GanttViewType.quarter,
    },
  ];

  selectedViewType: GanttViewType = GanttViewType.month;

  tooltipText = '';

  tooltipStyle = {};

  hideTooltipTimeout;

  isCustomStyleActive = false;

  buttonMaxWidthTitle = this.translateService.instant(
    'costcenter.costcenter_usage.max_width'
  );

  @ViewChild('gantt') ganttComponent: NgxGanttComponent;

  selectedItems: string[] = [];

  groups: GanttGroup[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private fieldService: LocationFormlyFieldService,
    private locationClient: LocationClient,
    private dialog: MatDialog,
    private costcenterClient: CostCenterClient,
    private cd: ChangeDetectorRef
  ) {
    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.headline,
      },
    ]);
  }

  ngOnInit(): void {
    this.fieldService.setSearchContext(true);
    this.formFields = [
      ...this.fieldService.autocompleteSearch(
        false,
        null,
        true,
        id => this.onSelect(id),
        null,
        () => this.onDelete()
      ),
    ];
    this.getGanttData([]);
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  public onSelect(archObjId: string): void {
    this.selectedItems = [];
    this.isLoading = true;
    this.locationClient.getArchobjById(archObjId).subscribe({
      next: (response: ArchObjHierarchyViewModel) => {
        this.selectedLocation = response;
        this.getTreeData(this.selectedLocation.archObjId);
      },
      error: () => (this.isLoading = false),
    });
  }

  onDelete(): () => void {
    this.selectedLocation = null;
    this.selectedItems = null;
    this.dataSource.data = [];

    return null;
  }

  getTreeData(id: string) {
    this.isLoading = true;
    this.locationClient.getArchObjTree(id).subscribe({
      next: (res: ArchObjTreeViewModel) => {
        TREE_DATA.length = 0;
        TREE_DATA.push(res);
        this.dataSource.data = TREE_DATA;
        this.isLoading = false;
      },
      error: err => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  findObjectByName(data, targetName, isChild?: boolean) {
    let obj;
    if (!isChild) {
      obj = data[0];
    } else {
      obj = data;
    }
    if (obj.displayName === targetName) {
      return obj;
    }

    if (obj.children && obj.children.length > 0) {
      for (const child of obj.children) {
        const result = this.findObjectByName(child, targetName, true);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  async getGanttData(items: string[]) {
    await this.costcenterClient.getCostCenterUsageTable(items).subscribe({
      next: res => {
        this.groups = res.groups as GanttGroup[];
        this.items = res.items as IGanttItem[];
        this.options.viewType = GanttViewType.month;
        this.isLoading = false;
      },
    });
  }

  barClick(e) {
    let editDialogRef = null;
    this.costcenterClient.getCostCenterUsageDetails(e.item.id).subscribe({
      next: res => {
        editDialogRef = this.dialog.open(EditCostcenterusageComponent, {
          minWidth: '50vw',
          minHeight: '60vh',
          panelClass: 'smartportal-dialog',
          data: {
            title: this.translateService.instant(
              'costcenter.costcenter_usage.edit_dialog.headline'
            ),
            room: res.title,
            costcenter: res.costCenterId ?? null,
            start: res.validFromDate,
            end: res.validUntilDate,
            areaInSquareMeters: res.areaInSquareMeters,
            note: res.remarks ?? null,
            recentChange: res.lastChangeInfo ?? null,
            changeReason: res.changeReasonId,
            isRoom: res.isRoom,
          },
        });
      },
    });

    editDialogRef?.afterClosed().subscribe({
      next: res => {
        if (res) {
          // TODO add logic here
          console.log(res);
        }
      },
    });
  }

  getTooltip(item) {
    return `
      ${this.translateService.instant('costcenter.costcenter_usage.room')}
      ${item.roomTitle}
      ${
        item.workspaceTitle
          ? `
            ${this.translateService.instant('costcenter.costcenter_usage.working_space')}
            ${item.workspaceTitle}\n`
          : ''
      }
      ${this.translateService.instant('costcenter.autocomplete.costCenter')}
      ${item.costCenterId ?? '--'}\n
      ${this.translateService.instant('costcenter.costcenter_usage.share')}
      ${item.areaString ?? '--'} \n
      ${this.translateService.instant('costcenter.costcenter_usage.expiration')}
      ${this.formatUnixTimestamp(item.start)} - ${this.formatUnixTimestamp(item.end)} \n
      ${this.translateService.instant('costcenter.costcenter_usage.option')}
      ${item.option ?? '--'}`;
  }

  formatUnixTimestamp(unixTimestamp: number): string {
    const date = new Date(unixTimestamp * 1000);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  toggleStyle() {
    this.isCustomStyleActive = !this.isCustomStyleActive;
    if (this.isCustomStyleActive === true) {
      this.buttonMaxWidthTitle = this.translateService.instant(
        'costcenter.costcenter_usage.reset'
      );
    } else {
      this.buttonMaxWidthTitle = this.translateService.instant(
        'costcenter.costcenter_usage.max_width'
      );
    }
  }

  selectView(type: GanttViewType) {
    this.selectedViewType = type;
    this.options.viewType = type;
  }

  scrollToToday() {
    this.ganttComponent.scrollToToday();
  }

  onCheckboxClick(e: MatCheckboxChange, item) {
    const selectedObj = this.findObjectByName(TREE_DATA, item.name);

    if (selectedObj.childrenCount > 0) {
      selectedObj.children.forEach(child => {
        if (e.checked === true) {
          this.selectedItems.push(child.id);
          child.checked = true;

          this.cd.detectChanges();
        } else if (e.checked === false) {
          this.selectedItems = this.selectedItems.filter(
            selectedItem => selectedItem !== child.id
          );
          child.checked = false;
          this.cd.detectChanges();
        }
      });
    } else {
      if (e.checked === true) {
        this.selectedItems.push(selectedObj.id);
      } else if (e.checked === false) {
        this.selectedItems = this.selectedItems.filter(
          selectedItem => selectedItem !== item.name
        );
      }
    }

    this.isLoading = true;
    this.getGanttData(this.selectedItems);
  }

  GetCheckedState(node) {
    const checkbox = this.selectedItems.find(s => s === node.name);
    return checkbox ?? node.checked;
  }
}
