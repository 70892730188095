<div class="rental-page-vid">
  <div class="video-container">
    <video
      preload="auto"
      [autoplay]="true"
      [muted]="true"
      controls
      disablePictureInPicture
      webkit-playsinline
      playsinline
      [src]="videoUrl"
      mime-type="video/mp4"
      controlsList="nodownload"
      oncontextmenu="return false;"></video>
  </div>
</div>
<div class="rental-border">
  <app-rental-search class="margin-home"></app-rental-search>
</div>
