<div mat-dialog-title data-debug="search-help-dialog">
  <su-headline h="h3" skin="primary">{{
    'ticket.create.label__add_mails' | translate
  }}</su-headline>
</div>

<div mat-dialog-content>
  <form class="margin-top">
    <mat-form-field class="mat-form-field-full-width">
      <mat-label>{{ 'ticket.create.label__name_email' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (change)="emailExists = false; isEmailInOptions()"
        (ngModelChange)="isEmailInOptions()" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <p *ngIf="this.emailExists" id="warningEmailExists">
      {{ 'ticket.create.p__info_email_exists' | translate }}
    </p>
  </form>
  <mat-list>
    <mat-list-item *ngFor="let selected of selectedOptions" class="list-item">
      <div class="list-label">{{ selected }}</div>
      <su-button
        icon="trash"
        skin="secondary"
        (onClick)="deleteSelectedOption(selected)">
      </su-button>
    </mat-list-item>
  </mat-list>
</div>

<div mat-dialog-actions>
  <su-button
    skin="secondary"
    [label]="'shared.cancel' | translate"
    mat-dialog-close>
  </su-button>

  <su-button
    skin="secondary"
    [label]="'shared.add' | translate"
    cdkFocusInitial
    (onClick)="addOption()"
    [disabled]="!this.emailInOptions || this.emailExists"></su-button>

  <su-button
    skin="primary"
    [label]="'shared.save' | translate"
    (onClick)="saveSelectedOptions(selectedOptions)"></su-button>
</div>
