import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-text',
  templateUrl: './quick-text.component.html',
  styleUrls: ['./quick-text.component.scss'],
})
export class QuickTextComponent implements OnInit {
  @Input() title = 'UNDEFINED';

  @Input() imgUrl = '';

  @Input() backgroundColor = 'none';

  @Input() fontColor = 'inherit';

  @Input() buttonLink: string | undefined = '';

  @Input() buttonVariant: string | undefined = '';

  @Input() index = 0;

  @Input() showButton = true;

  @Input() hasLargeHeader = false;

  @Input() hasBackground = false;

  @Input() buttonText: string | undefined = 'Mehr erfahren';

  ngOnInit(): void {
    this.showButton = !(this.buttonLink == null || this.buttonLink === '');
  }

  isReversed(index: number) {
    return index % 2 !== 0;
  }
}
