/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import {
  RentalListingClient,
  CreateOrUpdateRentalListingRequest,
  ArchObjHierarchyViewModel,
  LocationClient,
  RentalListingDto,
  RentalListingDocument,
} from '@core/api';
import { WebsiteEditCoordinatesService } from '../../website-edit-coordinates.service';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../rental-listing-edit-dialog/confirmation-dialog.component';

/**
 * Validator to ensure there is exactly one primary image.
 * @param control The form control containing the documents.
 * @returns Validation errors if there are no or multiple primary images.
 */
export const primaryImageValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const documents = control.value || [];

  if (documents.length === 0) {
    return null;
  }
  const primaryCount = documents.filter((doc: any) => doc.isPrimary).length;

  if (primaryCount === 0) {
    return { noPrimary: true };
  }

  if (primaryCount > 1) {
    return { multiplePrimary: true };
  }

  return null;
};

@Component({
  selector: 'app-rental-listing-edit',
  templateUrl: './rental-listing-edit.component.html',
  styleUrls: ['./rental-listing-edit.component.scss'],
})
export class RentalListingEditComponent implements OnInit {
  form = new FormGroup({});
  formFields: FormlyFieldConfig[];
  formModel: any = {};
  isLoading = false;
  maxFileSizeInMB = 10;
  rentalListingId: number | null = null;

  cardSearch: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'website_edit.rental.RentalListingMarketing.label__create'
        ),
      },
    },

    footer: {
      button1: {
        label: this.translateService.instant(
          'website_edit.rental.RentalListingMarketing.label__submit'
        ),
        onClick: () => this.submitForm(),
        disabled: false,
      },
      button2: {
        label: this.translateService.instant(
          'website_edit.rental.RentalListingMarketing.label__abort'
        ),
        onClick: () => this.closeDialog(),
        disabled: false,
      },
      ...(this.rentalListingId && {
        button3: {
          label: this.translateService.instant(
            'website_edit.rental.RentalListingMarketing.label__delete'
          ),
          onClick: () => this.deleteRentalListing(),
          disabled: false,
        },
      }),
    },
  };
  constructor(
    private translateService: TranslateService,
    private fieldService: LocationFormlyFieldService,
    private rentalListingClient: RentalListingClient,
    private toastr: ToastrService,
    private coordinatesService: WebsiteEditCoordinatesService,
    private locationClient: LocationClient,
    private dialogRef: MatDialogRef<RentalListingEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RentalListingDto,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    if (this.data) {
      this.rentalListingId = this.data.id;
      this.formModel = {
        searchResult: {
          archObjId: this.data.archObjId,
          streetName: this.data.address?.streetName
            ? this.data.address.streetName
            : '',
          postalCode: this.data.address.postalCode,
          city: this.data.address?.city,
        },
        title: this.data.title,
        description: this.data.description,
        link: this.data.link,
        linkDisplayName: this.data.linkDisplayName,
        isPublished: this.data.isPublished,
        documents: this.data.documents.map(doc => ({
          file: {
            id: doc.id,
            name: doc.fileName,
            size: 0,
            type: 'image/jpeg',
            url: doc.fileUri,
          },
          isPrimary: doc.id === this.data.primaryDocumentId,
        })),
      };
      this.form.patchValue(this.formModel);
    }
    this.updateCardConfig();
    this.locationClient.getArchobjOfCurrentUser().subscribe({
      next: (response: ArchObjHierarchyViewModel) => {
        this.isLoading = false;
        this.initiateForm(response);
      },
      error: (error: Error) => {
        this.handleError(error);
      },
      complete: () => (this.isLoading = false),
    });
  }

  /**
   * Updates the card configuration.
   */
  private updateCardConfig() {
    this.cardSearch = {
      ...this.cardSearch,
      footer: {
        ...this.cardSearch.footer,
        button1: {
          label: this.translateService.instant(
            this.rentalListingId
              ? 'website_edit.rental.RentalListingMarketing.label__update'
              : 'website_edit.rental.RentalListingMarketing.label__submit'
          ),
          onClick: () => this.submitForm(),
          disabled: false,
        },
        button2: {
          label: this.translateService.instant(
            'website_edit.rental.RentalListingMarketing.label__abort'
          ),
          onClick: () => this.closeDialog(),
          disabled: false,
        },
        ...(this.rentalListingId && {
          button3: {
            label: this.translateService.instant(
              'website_edit.rental.RentalListingMarketing.label__delete'
            ),
            onClick: () => this.deleteRentalListing(),
            disabled: false,
          },
        }),
      },
    };
  }

  /**
   * Deletes the rental listing.
   */
  private deleteRentalListing(): void {
    if (!this.rentalListingId) return;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.rentalListingClient
          .deleteRentalListing(this.rentalListingId)
          .subscribe({
            next: () => {
              const successMessage = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.successDelete'
              );
              const successTitle = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.successTitle'
              );
              this.isLoading = false;
              this.toastr.success(successMessage, successTitle);
              this.dialogRef.close({ deleted: true });
            },
            error: error => {
              console.error('Fehler beim Löschen der Anfrage:', error);
              const errorMessage = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.errorDelete'
              );
              const errorTitle = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.errorTitle'
              );
              this.isLoading = false;
              this.toastr.error(errorMessage, errorTitle);
            },
          });
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close({ cancelled: true });
  }

  async submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();

      return;
    }

    try {
      this.isLoading = true;
      const request = await this.createRentalListingRequest(this.form.value);

      if (this.rentalListingId) {
        this.rentalListingClient
          .updateRentalListing(this.rentalListingId, request)
          .subscribe({
            next: () => {
              const successMessage = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.successUpdate'
              );
              const successTitle = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.successTitle'
              );
              this.isLoading = false;
              this.toastr.success(successMessage, successTitle);
              this.dialogRef.close({ cancelled: false });
            },
            error: error => {
              console.error('Fehler beim Aktualisieren der Anfrage:', error);
              const errorMessage = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.errorUpdate'
              );
              const errorTitle = this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.errorTitle'
              );
              this.isLoading = false;
              this.toastr.error(errorMessage, errorTitle);
            },
          });
      } else {
        this.isLoading = true;
        this.rentalListingClient.createRentalListing(request).subscribe({
          next: () => {
            const successMessage = this.translateService.instant(
              'website_edit.rental.RentalListingMarketing.successCreate'
            );
            const successTitle = this.translateService.instant(
              'website_edit.rental.RentalListingMarketing.successTitle'
            );
            this.isLoading = false;
            this.toastr.success(successMessage, successTitle);
            this.dialogRef.close({ cancelled: false });
          },
          error: error => {
            console.error('Fehler beim Erstellen der Anfrage:', error);
            const errorMessage = this.translateService.instant(
              'website_edit.rental.RentalListingMarketing.errorCreate'
            );
            const errorTitle = this.translateService.instant(
              'website_edit.rental.RentalListingMarketing.errorTitle'
            );
            this.isLoading = false;
            this.toastr.error(errorMessage, errorTitle);
          },
        });
      }
    } catch (error) {
      console.error('Fehler beim Erstellen der Anfrage:', error);
      const errorMessage = this.translateService.instant(
        'website_edit.rental.RentalListingMarketing.error'
      );
      const errorTitle = this.translateService.instant(
        'website_edit.rental.RentalListingMarketing.errorTitle'
      );
      this.isLoading = false;
      this.toastr.error(errorMessage, errorTitle);
    }
  }

  /**
   * Creates a rental listing request from the form model.
   * @param formModel The form model.
   * @returns The rental listing request.
   */
  private async createRentalListingRequest(
    formModel: any
  ): Promise<CreateOrUpdateRentalListingRequest> {
    const documents = await this.processDocuments(formModel.documents);
    const address = this.formatAddress(formModel.searchResult);
    const coordinates =
      await this.coordinatesService.getCoordinatesFromArchObjAddress(address);

    return {
      archObjId: formModel.searchResult.id || '',
      title: formModel.title || '',
      description: formModel.description || '',
      link: formModel.link || null,
      linkDisplayName: formModel.linkDisplayName || '',
      isPublished: formModel.isPublished || false,
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      documents,
    };
  }

  /**
   * Processes the documents for the rental listing.
   * @param documents The documents to process.
   * @returns The processed documents.
   */
  private async processDocuments(
    documents: any[]
  ): Promise<RentalListingDocument[]> {
    if (!documents) return [];

    const processedDocuments = await Promise.all(
      documents.map(async doc => {
        if (doc.file && !doc.file.id) {
          const base64Content = await this.readDocument(doc);
          return {
            id: doc.file?.id || null,
            content: base64Content,
            fileName: doc.file.name,
            isPrimary: doc.isPrimary || false,
          } as RentalListingDocument;
        } else {
          return {
            id: doc.file.id,
            content: null,
            fileName: null,
            isPrimary: doc.isPrimary || false,
          } as RentalListingDocument;
        }
      })
    );

    return processedDocuments.filter(
      doc => doc !== null
    ) as RentalListingDocument[];
  }

  /**
   * Reads the document and returns its base64 content.
   * @param doc The document to read.
   * @returns The base64 content of the document.
   */
  private readDocument(doc: any): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      if (!doc || !doc.file) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64Content = (reader.result as string).split(',')[1];
        resolve(base64Content);
      };

      reader.onerror = error => {
        console.error('Fehler beim Lesen der Datei:', error);
        reject(error);
      };

      reader.readAsDataURL(doc.file);
    });
  }

  /**
   * Formats the address from the search result.
   * @param searchResult The search result containing the address.
   * @returns The formatted address.
   */
  private formatAddress(searchResult: any): string {
    if (!searchResult) return '';

    const { streetName, houseNumber, postalCode, city } = searchResult;

    const addressParts = [
      streetName,
      houseNumber ? ` ${houseNumber}` : '',
      (streetName || houseNumber) && (postalCode || city) ? ', ' : '',
      postalCode,
      postalCode && city ? ' ' : '',
      city,
    ];

    return addressParts.join('').trim().replace(/,\s*$/, '');
  }

  /**
   * Handles errors by logging them and setting the loading state to false.
   * @param error The error to handle.
   */
  private handleError(error: Error) {
    this.isLoading = false;
    console.error('[WebsiteEditComponent] handleError:', error);
  }

  /**
   * Initializes the form fields.
   * @param response The response containing the hierarchy view model.
   */
  initiateForm(response: ArchObjHierarchyViewModel): void {
    this.formFields = [
      {
        type: 'chapter',
        props: {
          chapterTitle: this.translateService.instant(
            'location.myLocation.label__search_location'
          ),
        },
      },
      ...this.fieldService.autocompleteSearch(false, response, true, null),
      {
        type: 'chapter',
        props: {
          chapterTitle: 'Inseratinformationen',
        },
      },
      {
        key: 'title',
        type: 'input',
        props: {
          label: this.translateService.instant(
            'website_edit.rental.table_header.label__title'
          ),
          required: true,
          maxLength: 100,
        },
      },
      {
        key: 'description',
        type: 'textarea',
        props: {
          label: this.translateService.instant(
            'website_edit.rental.table_header.label__description'
          ),
          maxLength: 1000,
          rows: 5,
        },
      },
      {
        key: 'link',
        type: 'input',
        props: {
          label: this.translateService.instant(
            'website_edit.rental.table_header.label__link'
          ),
          maxLength: 100,
          type: 'url',
        },
      },
      {
        key: 'linkDisplayName',
        type: 'input',
        props: {
          label: this.translateService.instant(
            'website_edit.rental.table_header.label__linkDisplayName'
          ),
          maxLength: 100,
        },
      },
      {
        type: 'chapter',
      },
      {
        type: 'checkbox',
        wrappers: ['no-border'],
        key: 'isPublished',
        defaultValue: false,
        props: {
          label: this.translateService.instant(
            'website_edit.rental.table_header.label__isPublished'
          ),
        },
      },
      {
        type: 'chapter',
        props: {
          chapterTitle: this.translateService.instant(
            'website_edit.rental.table_header.label__documents'
          ),
        },
      },
      {
        key: 'documents',
        type: 'repeat',
        props: {
          addText: this.translateService.instant(
            'ticket.create.button__attach_new_file'
          ),
        },
        validators: {
          validation: [primaryImageValidator],
        },
        validation: {
          messages: {
            noPrimary: () =>
              this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.error__noPrimaryImage'
              ),
            multiplePrimary: () =>
              this.translateService.instant(
                'website_edit.rental.RentalListingMarketing.error__multiplePrimaryImages'
              ),
          },
        },
        fieldArray: {
          type: 'flex-layout',
          fieldGroup: [
            {
              type: 'file-pictures',
              key: 'file',
              props: {
                label: this.translateService.instant(
                  'ticket.create.label__attachment'
                ),
                visibleInConfirm: true,
                accept: '.jpg, jpeg, .png, .webp, .tif, .tiff',
                maxFileSizeInMB: this.maxFileSizeInMB,
              },
            },
            {
              key: 'isPrimary',
              wrappers: ['no-border'],
              type: 'checkbox',
              defaultValue: false,
              props: {
                label: this.translateService.instant(
                  'website_edit.rental.table_header.label__mainDisplayImage'
                ),
                required: true,
              },
            },
          ],
        },
      },
    ];
  }
}
