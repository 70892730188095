import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RegexpMultipleEmail } from '@shared/utils/regex';

export function emailcc(
  translateService: TranslateService,
  onEmailChange: (emails: any) => void
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.label__notification'
            ),
            chapterText: translateService.instant(
              'ticket.create.p__info_notification'
            ),
          },
        },
        {
          key: 'ccemail',
          type: 'input',
          props: {
            label: translateService.instant('ticket.create.label__emailcc'),
            placeholder: 'E-Mail',
            pattern: RegexpMultipleEmail,
          },
          validation: {
            messages: {
              pattern: () =>
                translateService.instant('formErrors.email.pattern'),
            },
          },
        },
        {
          key: 'emailccButton',
          type: 'button-cc-email-dialog',
          props: {
            label: translateService.instant(
              'ticket.create.label__emailcc_add_mails'
            ),
            change: emails => onEmailChange(emails),
          },
        },
      ],
    },
  ];
}
