import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateLangService } from '@core/services/translate-service/translate-lang.service';
import { AccessOrdererHistoryComponent } from '@mandants/telekom/features/access-orderer/access-orderer-history/access-orderer-history.component';
import { AccessOrdererComponent } from '@mandants/telekom/features/access-orderer/access-orderer.component';
import { EditAccessOrdererComponent } from '@mandants/telekom/features/access-orderer/edit-access-orderer/edit-access-orderer.component';
import { AdministrationComponent } from '@mandants/telekom/features/administration/administration.component';
import { UpdateUserComponent } from '@mandants/telekom/features/administration/update-user/update-user.component';
import { FeedbackTelekomComponent } from '@mandants/telekom/features/feedback/feedback.component';
import { ImprintTelekomComponent } from '@mandants/telekom/features/imprint/imprint.component';
import { LocationModule } from '@mandants/telekom/features/location/location.module';
import { PrivacyTelekomComponent } from '@mandants/telekom/features/privacy/privacy.component';
import { TelekomRemModule } from '@mandants/telekom/features/rem/telekom-rem.module';
import { ProspectiveTenantsTelekomComponent } from '@mandants/telekom/features/tasks/prospective-tenants/prospective-tenants.component';
import { TaskDetailsTelekomComponent } from '@mandants/telekom/features/tasks/task-details/task-details.component';
import { TaskListTelekomComponent } from '@mandants/telekom/features/tasks/task-list/task-list.component';
import { DenialDialogComponent } from '@mandants/telekom/features/tickets/denial-dialog/denial-dialog.component';
import { TicketCreateOrderAccessTelekomComponent } from '@mandants/telekom/features/tickets/ticket-create/order-access/order-access.component';
import { TicketListTelekomComponent } from '@mandants/telekom/features/tickets/ticket-list/ticket-list.component';
import { UserCreateComponent } from '@mandants/telekom/features/user/create/user-create.component';
import { UserPermissionsComponent } from '@mandants/telekom/features/user/permissions/user-permissions.component';
import { WebsiteEditModule } from '@mandants/telekom/features/website-edit/website-edit.module';
import { OverrideModule } from '@mandants/telekom/override/override.module';
import { TelekomSharedModule } from '@mandants/telekom/telekom-shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '@shared/pipes';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { SharedModule } from '@shared/shared.module';
import { ApprovalDialogComponent } from './features/tickets/approval-dialog/approval-dialog.component';

@NgModule({
  declarations: [
    TicketListTelekomComponent,
    TaskListTelekomComponent,
    TaskDetailsTelekomComponent,
    FeedbackTelekomComponent,
    ImprintTelekomComponent,
    PrivacyTelekomComponent,
    TicketCreateOrderAccessTelekomComponent,
    ProspectiveTenantsTelekomComponent,
    AccessOrdererComponent,
    EditAccessOrdererComponent,
    AccessOrdererHistoryComponent,
    AdministrationComponent,
    UpdateUserComponent,
    UserPermissionsComponent,
    UserCreateComponent,
    DenialDialogComponent,
    ApprovalDialogComponent,
  ],
  imports: [
    TelekomSharedModule,
    SharedModule,
    LocationModule,
    TelekomRemModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLangService,
        deps: [HttpClient],
      },
    }),
    OverrideModule,
    FilterPipe,
    WebsiteEditModule,
    SafeHtmlPipe,
    MatDialogModule,
  ],
  exports: [],
  providers: [],
})
export class TelekomModule {}
