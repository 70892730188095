import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  BaseSelectViewModel,
  DtUsageCategoryViewModel,
  DtUsageType,
  UpdateArchObjUtilHistoryRequest,
} from '@core/api';
import { EditRoomService } from '@core/services/edit-room/edit-room.service';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-maintenance-room-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SmartusComponentsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule,
    FormlyModule,
    OverrideModule,
  ],
  templateUrl: './maintenance-room-edit-modal.component.html',
  styleUrl: './maintenance-room-edit-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceRoomEditModalComponent {
  private translateService = inject(TranslateService);
  private editRoomService = inject(EditRoomService);
  private toastr = inject(ToastrService);
  private cd = inject(ChangeDetectorRef);

  form: FormGroup;
  formFields: FormlyFieldConfig[];
  formModel: {
    validFrom: string;
    validUntil: string;
    nestedDropdowns: string;
    changeReason: string;
    createdOn: string;
    changedOn: string;
  } = {
    validFrom: '',
    validUntil: '',
    nestedDropdowns: '',
    changeReason: '',
    createdOn: '',
    changedOn: '',
  };
  typeOfUseDetailedId: any[] = [];
  initialChangeReason: string;
  initialTypeOfUseDetailed: string;
  changeReasons: BaseSelectViewModel[];
  archObjUtilHistoryId: string;
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MaintenanceRoomEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.initialTypeOfUseDetailed = data.typeOfUseDetailedAreaId;
    this.archObjUtilHistoryId = data.id;
    this.form = this.fb.group({
      validFrom: [
        { value: data.validFrom, disabled: true },
        Validators.required,
      ],
      validUntil: [
        { value: data.validFrom, disabled: true },
        Validators.required,
      ],
      nestedDropdowns: [this.initialTypeOfUseDetailed, Validators.required],
      changeReason: ['', Validators.required],
      createdOn: [{ value: data.createDate, disabled: true }],
      changedOn: [{ value: data.changeDate, disabled: true }],
    });
  }

  ngOnInit(): void {
    this.changeReasons = this.data.changeReasons;
    this.initialChangeReason = this.data.changeReason;
    this.typeOfUseDetailedId = Object.entries(
      this.data.typeOfUseDetailedId
    ).map(([key, value]: [string, DtUsageCategoryViewModel]) => {
      const dtUsageTypes = {
        categoryName: value.name,
        dtUsageTypes: value.usageTypes.map((usage: DtUsageType) => ({
          usageTypeId: usage.usageTypeId,
          dtDetailAreaTypes: usage.dtUsageDetailArea || [],
        })),
      };

      return dtUsageTypes;
    });

    this.initialTypeOfUseDetailed = this.data.typeOfUseDetailedAreaId;
    this.initializeFormData();
    this.initializeBuildingUsageOptions();
  }

  initializeFormData(): void {
    this.formModel = {
      validFrom: this.data.validFrom || '',
      validUntil: this.data.validUntil || '',
      nestedDropdowns: this.data.typeOfUseDetailedAreaId || '',
      changeReason: '',
      createdOn: this.data.createDate || '',
      changedOn: this.data.changeDate || '',
    };

    this.formFields = this.getFormFields();
    this.form = this.fb.group({
      validFrom: [this.formModel.validFrom],
      validUntil: [this.formModel.validUntil],
      nestedDropdowns: [this.formModel.nestedDropdowns],
      changeReason: ['', Validators.required],
      createdOn: [this.formModel.createdOn],
      changedOn: [this.formModel.changedOn],
    });
  }

  getFormFields(): FormlyFieldConfig[] {
    return [
      {
        type: 'formly-group',
        fieldGroup: [
          {
            key: 'validFrom',
            className: 'margin-top-edit-room-modal',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.validFrom'
              ),
              disabled: true,
            },
          },
          {
            key: 'validUntil',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.validUntil'
              ),
              disabled: true,
            },
          },
          {
            key: 'nestedDropdowns',
            type: 'nested-select-room-dropdowns',
            props: {
              label: this.translate('buildingMaintenance.table.buildingUsage'),
              datatypeOfUseDetailedId: this.typeOfUseDetailedId,
              required: true,
            },
          },
          {
            key: 'changeReason',
            type: 'select',
            props: {
              label: this.translate('buildingMaintenance.table.changeReason'),
              options: this.changeReasons.map(
                (reason: BaseSelectViewModel) => ({
                  value: reason.name,
                  label: reason.id,
                })
              ),
              required: true,
            },
          },
          {
            key: 'createdOn',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.createdOn'
              ),
              disabled: true,
            },
          },
          {
            key: 'changedOn',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.changedOn'
              ),
              disabled: true,
            },
          },
        ],
      },
    ];
  }

  initializeBuildingUsageOptions(): void {
    this.form.get('buildingUsage')?.setValue(this.initialTypeOfUseDetailed);
  }

  isSaveDisabled(): boolean {
    const buildingUsageControl = this.form.get('nestedDropdowns');
    const changeReasonControl = this.form.get('changeReason');
    const changeReasonValue = changeReasonControl?.value;
    const buildingUsageValue = buildingUsageControl?.value;

    if (!buildingUsageValue || !changeReasonValue || this.form.invalid) {
      return true;
    }

    const levelSelected = buildingUsageValue?.levelControl || null;
    const usageTypeSelected = buildingUsageValue?.usageTypeControl || null;
    const detailAreaSelected = buildingUsageValue?.detailAreaControl || null;

    const hasUsageTypeSecondDropdown =
      !!this.typeOfUseDetailedId.length &&
      !!this.typeOfUseDetailedId[0].dtUsageTypes.length;

    const hasDetailAreaThirdDropdown =
      hasUsageTypeSecondDropdown &&
      !!this.typeOfUseDetailedId[0].dtUsageTypes.find(
        type => type.dtDetailAreaTypes.length > 0
      );

    if (!hasUsageTypeSecondDropdown) {
      return !(levelSelected && changeReasonControl);
    }

    if (hasUsageTypeSecondDropdown && !hasDetailAreaThirdDropdown) {
      return !(levelSelected && usageTypeSelected && changeReasonControl);
    }

    if (hasDetailAreaThirdDropdown) {
      if (
        usageTypeSelected &&
        usageTypeSelected.dtDetailAreaTypes.length === 0
      ) {
        return !(levelSelected && usageTypeSelected && changeReasonControl);
      } else {
        return !(
          levelSelected &&
          usageTypeSelected &&
          detailAreaSelected &&
          changeReasonControl
        );
      }
    }

    return true;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const formValue = this.form.value;

      const nestedDropdowns = formValue.nestedDropdowns || {};
      const usageTypeControl = nestedDropdowns.usageTypeControl || {};
      const detailAreaControl = nestedDropdowns.detailAreaControl || {};

      const requestData: UpdateArchObjUtilHistoryRequest = {
        archObjUtilHistoryId: this.archObjUtilHistoryId,
        usageTypeId: usageTypeControl.usageTypeId,
        detailAreaType: detailAreaControl.detailAreaTypeId,
        changeReason: formValue.changeReason,
      };

      this.editRoomService.updateArchObjUtilHistory(requestData).subscribe({
        next: () => {
          this.isLoading = false;
          this.toastr.success(this.translate('edit_room.toastr.success_edit'));
          this.dialogRef.close(requestData);
        },
        error: () => {
          this.isLoading = false;
          this.cd.detectChanges();
          this.toastr.error(this.translate('edit_room.toastr.error_edit'));
          this.dialogRef.close(requestData);
        },
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
