<scale-telekom-header-data-back-compat
  [appName]="appName"
  [appNameLink]="appNameLink"
  [activeRouteId]="currentRoute"
  [mainNavigation]="mainNavContent"
  [iconNavigation]="[]"
  [userNavigation]="[]"
  [addonNavigation]="[]"
  slot="header"
  logo-href="/landing/home">
</scale-telekom-header-data-back-compat>
