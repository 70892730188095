import { Component } from '@angular/core';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
})
export class TicketListComponent {
  constructor() {
    console.log('[TicketListComponent] constructor');
  }
}
