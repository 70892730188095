import { Injectable } from '@angular/core';

import { MaintenanceClient } from '@core/api';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  public maintenanceDateMessage: string | null = null;

  constructor(private maintenanceClient: MaintenanceClient) {}

  public get() {
    return this.maintenanceClient
      .get()
      .pipe(
        tap(maintenanceDate => (this.maintenanceDateMessage = maintenanceDate))
      );
  }
}
