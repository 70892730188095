import { Component, Injector, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { MandantConfigService } from '@core/services/mandant-config-service/mandant-config.service';
import { filter } from 'rxjs/operators';
import {
  MyAccountInfo,
  ProfileBaseComponent,
} from '@shared/components/profile-base/profile.base';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';

@Component({
  selector: 'app-profile-default',
  templateUrl: './profile-default.component.html',
  styleUrls: ['./profile-default.component.scss'],
})
export class ProfileDefaultComponent
  extends ProfileBaseComponent
  implements OnInit
{
  loginDisplay = false;

  accountInfo: MyAccountInfo;

  showJson = false;

  showPasswordReset = false;

  cardMap: CardConfig = {
    header: {
      title: {
        label: 'Account Info',
      },
    },
    footer: {
      button1: {
        label: this.translateService.instant('profile.showPasswordResetButton'),
        onClick: () => {
          this.resetPassword();
        },
        disabled: this.showPasswordReset,
        hide: this.showPasswordReset,
      },
    },
  };

  constructor(
    injector: Injector,
    private broadcastService: MsalBroadcastService,
    private mandantConfigService: MandantConfigService
  ) {
    super(injector);

    this.activatedRoute.data.subscribe({
      next: data => {
        this.showJson = data.showJson || false;
      },
    });
  }

  ngOnInit(): void {
    this.setLoginDisplay();
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe({
        next: (result: EventMessage) => {
          console.log('LOGIN SUCCESS:', result);

          this.setLoginDisplay();
        },
      });

    this.broadcastService.inProgress$.subscribe({
      next: (status: InteractionStatus) => {
        console.log('loginstatus:', status);
        this.setLoginDisplay();
      },
    });

    this.showPasswordReset =
      this.mandantConfigService.mandant.profile?.showPasswordReset || false;
  }

  setLoginDisplay() {
    const account = this.msalService.instance.getActiveAccount();
    this.loginDisplay = !!account;
    this.accountInfo = <MyAccountInfo>account;
  }
}
