<div class="fact-container content-body padding-home grid-container-all">
  <div class="item-svg">
    <svg class="line" viewBox="0 0 100 100" preserveAspectRatio="MidYMid meet">
      <g id="line-group">
        <path
          d="M 30 10 Q 23 30 30 50 T 30 90"
          stroke="#fff"
          stroke-width="0.5"
          fill="none"
          transform="rotate(20, 50, 50)" />
      </g>
      <g id="circles-group">
        <circle id="circle-0" cx="40" cy="13" r="1.5" fill="#F79CCEFF" />
        <circle id="circle-1" cx="32.5" cy="32" r="1.5" fill="#F79CCEFF" />
        <circle id="circle-2" cx="31" cy="50.5" r="1.5" fill="#F79CCEFF" />
        <circle id="circle-3" cx="25" cy="69.5" r="1.5" fill="#F79CCEFF" />
      </g>
    </svg>
  </div>

  <div class="fact-column grid-conatiner">
    <ng-container *ngFor="let factItem of factItemData; let i = index">
      <div class="fact-item-wrapper item-facts" #factItem [attr.data-index]="i">
        <app-fact-item
          [index]="i"
          [factType]="factItem.factType"
          [subtitle]="factItem.subtitle"
          [iconUrl]="factItem.iconUrl"
          [factAmount]="factItem.factAmount">
        </app-fact-item>
      </div>
    </ng-container>
  </div>
</div>
