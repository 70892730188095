<div class="home-page">
  <app-header-content-area class="padding-home"></app-header-content-area>
  <app-thumbnail title="Wir geben Raum."></app-thumbnail>
  <div class="fact-image-container">
    <img
      src="../../../assets/images/Map Webseite White.png"
      alt="Fixed Image"
      class="fixed-image" />
    <app-fact-wrapper-home-page
      class="app-fact-wrapper"></app-fact-wrapper-home-page>
  </div>
</div>
