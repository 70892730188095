import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RegexpEmail } from '@shared/utils/regex';
import {
  phoneConstraints,
  phoneValidationMessages,
} from '../ticket-created-shared.field-configs';
import { EmploymentSearchResultDto } from '@core/api';

export function ordererContact(
  translateService: TranslateService,
  currentUser?: EmploymentSearchResultDto
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__contact_orderer'
            ),
          },
        },
        {
          key: 'ordererContact',
          type: 'flex-layout',
          fieldGroup: [
            {
              type: 'input',
              key: 'firstname',
              defaultValue: currentUser ? currentUser.firstName ?? '--' : '',
              props: {
                label: translateService.instant(
                  'ticket.create.label__firstName'
                ),
                visibleInConfirm: true,
                maxLength: 50,
                required: !currentUser ? true : false,
                disabled: currentUser ? true : false,
              },
            },
            {
              type: 'input',
              key: 'lastname',
              defaultValue: currentUser ? currentUser.lastName ?? '--' : '',
              props: {
                label: translateService.instant(
                  'ticket.create.label__lastName'
                ),
                maxLength: 50,
                visibleInConfirm: true,
                required: !currentUser ? true : false,
                disabled: currentUser ? true : false
              },
            },
            {
              type: 'input',
              key: 'phonenumber',
              defaultValue: currentUser ? currentUser.phone !== '' ? currentUser.phone : currentUser.mobilePhone !== '' ? currentUser.mobilePhone : '--' : '0049',
              wrappers: ['tooltip', 'form-field'],
              props: {
                label: translateService.instant(
                  'ticket.create.label__phone'
                ),
                visibleInConfirm: true,
                required: !currentUser ? true : false,
                disabled: currentUser ? true : false,
                ...phoneConstraints,
                tooltip: translateService.instant(
                  'ticket.create.tooltip__phone'
                ),
              },
              validation: phoneValidationMessages(translateService),
            },
            {  
              type: 'input',
              key: 'email',
              defaultValue: currentUser ? currentUser.email ?? '--' : '',
              props: {
                label: translateService.instant(
                  'ticket.create.label__email'
                ),
                pattern: RegexpEmail,
                visibleInConfirm: true,
                required: !currentUser ? true : false,
                disabled: currentUser ? true : false,
                maxLength: 100,
              },
              validation: {
                messages: {
                  pattern: () =>
                    translateService.instant('formErrors.email.pattern'),
                },
              },
            },
            {
              type: 'input',
              key: 'corporation',
              defaultValue: currentUser ? currentUser.company !== '' ? currentUser.company : '--' : '',
              props: {
                label: translateService.instant(
                  'ticket.create.label__corporation'
                ),
                visibleInConfirm: true,
                required: !currentUser ? true : false,
                disabled: currentUser ? true : false,
                maxLength: 100,
              },
            },
            {
              type: 'input',
              key: 'operation',
              defaultValue: currentUser ? currentUser.orgUnit !== '' ? currentUser.orgUnit : '--' : '',
              props: {
                label: translateService.instant(
                  'ticket.create.label__operation'
                ),
                visibleInConfirm: true,
                required: !currentUser ? true : false,
                disabled: currentUser ? true : false,
                maxLength: 100,
              },
            },
          ],
        },
      ],
    },
  ];
}
