import { inject } from '@angular/core';
import { PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { Authorization } from '@shared/constants/authorization.constants';
import { protectedResources } from '@core/auth-config';
import { MsalService } from '@azure/msal-angular';

export const SsoGuard = async () => {
  const msalAuthService = inject(MsalService);

  const tSso = window.location.search.includes(Authorization.tsso);
  if (tSso && !msalAuthService.instance.getActiveAccount()) {
    const msalInstance: PublicClientApplication =
      msalAuthService.instance as PublicClientApplication;
    msalInstance.clearCache();
    const loginRequest: RedirectRequest = {
      scopes: protectedResources.getIncident.scopes,
    };
    loginRequest.extraQueryParameters = {
      domain_hint: Authorization.telekomAad,
    };
    await msalInstance.initialize();
    await msalInstance
      .handleRedirectPromise()
      .then(async () => {
        await msalInstance.loginRedirect(loginRequest);
      })
      .catch(err => {
        console.log(err);
      });
  }
};
