import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseSelectViewModel } from '@core/api';
import { EditBuildService } from '@core/services/edit-building/edit-building.service';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-maintenance-building-add-new',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SmartusComponentsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
    FormlyModule,
    OverrideModule,
  ],
  templateUrl: './maintenance-building-add-new.component.html',
  styleUrl: './maintenance-building-add-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceBuildingAddNewComponent implements OnInit {
  private dialogRef = inject(MatDialogRef<MaintenanceBuildingAddNewComponent>);
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private editBuildingService = inject(EditBuildService);
  private toastr = inject(ToastrService);
  private cd = inject(ChangeDetectorRef);

  form: FormGroup;
  formFields: FormlyFieldConfig[];
  formModel: {
    validFrom: string;
    buildingClassId: string;
  } = {
    validFrom: '',
    buildingClassId: '',
  };
  minDate = new Date();
  buildingClasses: any[] = [];
  archObjId: string;
  changeReasons: BaseSelectViewModel[];
  isLoading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    const today = new Date();
    this.minDate = new Date(today.setDate(today.getDate() + 1));
    this.archObjId = data.buildingId;
  }

  ngOnInit(): void {
    this.changeReasons = this.data.changeReasons;
    this.buildingClasses = Object.values(this.data.buildingClasses);
    this.form = this.fb.group({
      validFrom: ['', Validators.required],
      buildingClassId: ['', Validators.required],
      changeReason: ['', Validators.required],
    });

    this.formFields = [
      {
        key: 'validFrom',
        type: 'matdatetimepicker',
        props: {
          type: 'date',
          label: this.translate('buildingMaintenance.table.validFrom'),
          required: true,
          minDate: this.minDate,
        },
      },
      {
        key: 'buildingClassId',
        type: 'select',
        props: {
          label: this.translate('buildingMaintenance.table.buildingUsage'),
          options: this.buildingClasses.map((usage: any) => ({
            value: usage.buildingClassId,
            label: usage.buildingClassId,
          })),
          required: true,
        },
      },
      {
        key: 'changeReason',
        type: 'select',
        props: {
          label: this.translate('buildingMaintenance.table.changeReason'),
          options: this.changeReasons.map((reason: BaseSelectViewModel) => ({
            value: reason.name,
            label: reason.id,
          })),
          required: true,
        },
      },
    ];
  }

  isSaveDisabled(): boolean {
    return this.form.invalid;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const formValue = this.form.value;
      const requestData = {
        ...formValue,
        ArchObjId: this.archObjId,
      };

      this.editBuildingService.addArchObjUtilHistory(requestData).subscribe({
        next: () => {
          this.isLoading = false;
          this.toastr.success(
            this.translate('buildingMaintenance.toastr.success_add')
          );
          this.dialogRef.close(requestData);
        },
        error: error => {
          this.isLoading = false;
          if (error.processError === 11800 || error.processError === 11900) {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(
              this.translate('buildingMaintenance.toastr.error_add')
            );
          }
          this.cd.detectChanges();
          this.dialogRef.close(requestData);
        },
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
