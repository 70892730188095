<su-headline h="h1" skin="primary">{{ headline }}</su-headline>

<div class="frozenZoneDescriptionContainer" *ngIf="frozenStatus">
  <div
    class="sub-headline contactDescription"
    class="frozenZoneDescription"
    [innerHtml]="description | safeHtml"></div>
</div>

<su-headline h="h2" skin="default"
  >{{ 'locationServices.currentRoom' | translate
  }}{{ currentRoom }}</su-headline
>

<su-headline h="h2" skin="default">
  {{ 'locationServices.numberOfWorkingPlaces' | translate }}
  <input
    matInput
    type="number"
    [(ngModel)]="numberOfWorkingPlaces"
    placeholder="Enter number of working places"
    [disabled]="frozenStatus"
    [min]="this.workingSpaceListSubject.value.length" />
</su-headline>

<su-button
  [label]="'locationServices.addWorkingSpaceButton' | translate"
  skin="primary"
  (onClick)="addWorkingSpace()"
  [disabled]="frozenStatus">
</su-button>

<su-slot-tab-content>
  <mtx-grid
    [columns]="columns"
    [data]="workingSpaceList$ | async"
    [loading]="isLoading"
    [columnSortable]="true"
    [columnResizable]="config.grid.columnResizable"
    [columnPinnable]="false"
    [pageOnFront]="false"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    (page)="getNextPage($event)"
    [pageSize]="config.grid.paging.pageSize"
    [pageIndex]="config.grid.paging.pageIndex"
    [showPaginator]="config.grid.paging.showPaginator"
    [rowStriped]="true"
    [cellSelectable]="false"
    class="table-width">
  </mtx-grid>
  <ng-template #checkboxInactiveTemplate let-row>
    <div class="checkbox-container">
      <mat-checkbox
        [(ngModel)]="row.isActive"
        [disabled]="frozenStatus"></mat-checkbox>
    </div>
  </ng-template>
  <ng-template #checkboxDummyTemplate let-row>
    <div class="checkbox-container">
      <mat-checkbox
        [disabled]="!row.isActive || frozenStatus"
        [(ngModel)]="row.isDummy"></mat-checkbox>
    </div>
  </ng-template>
  <ng-template #inputAPNoTemplate let-row>
    <div class="input-container">
      <mat-form-field>
        <input
          [disabled]="!row.isActiv || frozenStatus"
          matInput
          [(ngModel)]="row.apNo"
          type="number" />
      </mat-form-field>
    </div>
  </ng-template>
  <ng-template #inputNameTemplate let-row>
    <div class="input-container">
      <mat-form-field>
        <input
          [disabled]="!row.isActive || frozenStatus"
          matInput
          [(ngModel)]="row.name"
          type="text" />
      </mat-form-field>
    </div>
  </ng-template>
  <ng-template #inputDummyKindTemplate let-row>
    <div class="input-container">
      <mat-form-field>
        <input
          [disabled]="!row.isActive || frozenStatus || !row.isDummy"
          matInput
          [(ngModel)]="row.dummyKind"
          type="text" />
      </mat-form-field>
    </div>
  </ng-template>
  <ng-template #selectTemplate let-row>
    <div class="checkbox-container">
      <mat-select
        [disabled]="frozenStatus"
        class="select"
        [(value)]="row.magentaSpaceType"
        [placeholder]="'shared.select' | translate">
        <mat-option
          *ngFor="let officeAreaType of officeAreaTypes"
          [value]="$any(officeAreaType).id"
          [title]="$any(officeAreaType).name"
          [matTooltip]="$any(officeAreaType).name">
          {{ $any(officeAreaType).name }}
        </mat-option>
      </mat-select>
    </div>
  </ng-template>

  <div
    style="display: flex; gap: 20px; flex-direction: column; margin-top: 10px">
    <mat-form-field style="width: 50%">
      <mat-label>{{ 'locationServices.changeReason' | translate }}</mat-label>
      <mat-select [(value)]="selectedChangeReasonId">
        <mat-option
          *ngFor="let changeReason of changeReasons"
          [value]="changeReason.id"
          [disabled]="frozenStatus">
          {{ changeReason.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="handlingPanel">
      <su-button
        skin="primary"
        [label]="'shared.save' | translate"
        (onClick)="save()"
        [disabled]="
          this.selectedChangeReasonId === '' || frozenStatus
        "></su-button>
      <su-button
        skin="primary"
        [label]="'shared.cancel' | translate"
        (onClick)="onCancelClick()"
        [disabled]="frozenStatus"></su-button>
    </div>
  </div>
</su-slot-tab-content>
