export enum LocationInfoCategory {
  LocationHint = 'affinis_mylocationcategory-1',
  Contact = 'affinis_mylocationcategory-2',
  ParkingLot = 'affinis_mylocationcategory-3',
  ChargingStation = 'affinis_mylocationcategory-4',
  BicycleStands = 'affinis_mylocationcategory-5',
  Canteen = 'affinis_mylocationcategory-6',
  MailDistribution = 'affinis_mylocationcategory-7',
  ParentChildOffice = 'affinis_mylocationcategory-8',
  FirstAid = 'affinis_mylocationcategory-9',
  AdditionalServices = 'affinis_mylocationcategory-10',
  Reception = 'affinis_mylocationcategory-11',
}
