import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  isDevMode,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadFile } from '@core/api';
import { FileService } from '@core/services/file-service/file.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogComponent,
  IDialogFeedback,
} from '@shared/components/dialog/dialog.component';
import { rxSubscriptionContainerMixin } from '@shared/utils/rx-subscription-container.mixin';
import { Observable } from 'rxjs';
import { IFormConfig, IFormConfirmConfig } from '../form.types';

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.html',
  styleUrls: ['./form-base.scss'],
})
export class FormBaseComponent extends rxSubscriptionContainerMixin() {
  config: IFormConfig = <IFormConfig>{
    title: '',
    description: '',
    confirm: <IFormConfirmConfig>{},
  };

  loading = false;

  form = new FormGroup({});

  formModel: any = {};

  formFields: FormlyFieldConfig[];

  extraConfirmFormFields: FormlyFieldConfig[];

  filledForm: FormlyFieldConfig[] | null;

  dialogFeedback?: IDialogFeedback;

  options: FormlyFormOptions = {
    formState: {
      mainModel: this.formModel,
    },
  };

  isDevMode = isDevMode();

  protected prepareSubmit: (data: any) => any;

  protected clientEndpoint: (data: any) => Observable<any>;

  protected router: Router;

  protected activatedRoute: ActivatedRoute;

  protected cd: ChangeDetectorRef;

  private el: ElementRef;

  protected dialog: MatDialog;

  protected fileService: FileService;

  protected translateService: TranslateService;

  constructor(@Inject(Injector) injector: Injector) {
    super();

    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.cd = injector.get(ChangeDetectorRef);

    this.el = injector.get(ElementRef);
    this.dialog = injector.get(MatDialog);
    this.fileService = injector.get(FileService);

    this.translateService = injector.get(TranslateService);
  }

  validateForm() {
    if (this.form.valid) {
      this.showFormSummary();
    } else {
      this.scrollToFirstInvalidControl(true);
    }
  }

  private async scrollToFirstInvalidControl(repeatScroll: boolean) {
    this.form.markAllAsTouched();
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector(
        '.mat-error',
        '.mat-input-element.ng-invalid, .mat-select.ng-invalid, mat-checkbox.ng-invalid, mat-form-field.ng-invalid'
      );

    try {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
      firstInvalidControl.focus();
    } finally {
      if (repeatScroll) {
        setTimeout(() => {
          this.scrollToFirstInvalidControl(false);
        }, 10);
      }
    }
  }

  async showFormSummary() {
    this.filledForm = this.formFields;
  }

  fileRead(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as string);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  getFileList(element: any) {
    const elList: any = [];
    for (const control of element.formControl.controls) {
      elList.push({
        label: control.value.attachmentType,
        value: control.value.attachment.name,
        type: 'file',
        data: null,
        visibleInConfirm: true,
        origin: 'file',
      });
    }
    return elList;
  }

  discardSumUpForm() {
    this.filledForm = null;
  }

  async submit() {
    this.loading = true;

    const preparedData = this.prepareSubmit(this.form.value);
    const preparedFiles = await this.getPreparedFiles();
    this.pushSubscription(
      this.clientEndpoint({
        incidentDTO: preparedData,
        files: preparedFiles,
      }).subscribe({
        next: result => {
          this.form.reset();
          this.loading = false;
          const successDialog = this.dialog.open(DialogComponent, {
            width: '500px',
            data: {
              type: 'okButton',
              title:
                this.dialogFeedback?.success.title ||
                'ticket.create.success.title',
              message:
                this.dialogFeedback?.success.message ||
                'ticket.create.success.message',
              okButtonText: 'ticket.create.success.button__text',
            },
          });
          successDialog.afterClosed().subscribe({
            next: () => {
              this.submitRedirect(result);
            },
          });
        },
        error: error => {
          this.dialog.open(DialogComponent, {
            width: '300px',
            data: {
              type: 'noButtons',
              title:
                this.dialogFeedback?.error.title || 'ticket.create.error.title',
              message:
                this.dialogFeedback?.error.message ||
                'ticket.create.error.message',
            },
          });
          console.error(error);
          this.loading = false;
        },
      })
    );
  }

  protected configure(config: IFormConfig) {
    return { ...this.config, ...config };
  }

  protected async getPreparedFiles(): Promise<Array<UploadFile>> {
    const files = this.form.get('attachments') as unknown as FormArray;
    const packedFiles: Array<UploadFile> = [];
    if (files !== null) {
      for (const [i, f] of files.controls.entries()) {
        console.log(i, f);
        // eslint-disable-next-line no-await-in-loop
        const base64 = await this.fileService.fileRead(f.value.attachment);
        const cont = base64.split(',')[1];
        packedFiles.push({
          contentBase64Encoded: cont,
          metadata: {
            isInternal: false,
            documentId: '',
            name: f.value.attachment.name,
            guidExternalSystem: undefined,
            crmGuidOriginRecord: undefined,
            filename: f.value.attachment.name,
            dynamicsUri: undefined,
            publishDocument: undefined,
            statusCode: 'Aktiv',
            contactId: undefined,
            rentalContractId: undefined,
            functionalLocationId: undefined,
            accountId: undefined,
            changeDate: undefined,
          },
        });
      }
    }
    return packedFiles;
  }

  protected submitRedirect(result): void {
    this.router.navigate(['..', 'details', result], {
      relativeTo: this.activatedRoute,
      queryParams: { w: 'true' },
    });
  }
}
