import { Injectable } from '@angular/core';
import * as mapsRestService from 'azure-maps-rest';
import {
  Aborter,
  MapsURL,
  SearchURL,
  SubscriptionKeyCredential,
} from 'azure-maps-rest';

import { environment } from '@core/environments/environment';
import * as atlas from 'azure-maps-control';
import { AuthenticationType } from 'azure-maps-control';

@Injectable({
  providedIn: 'root',
})
export class LocationMapService {
  private readonly pipeline: mapsRestService.Pipeline;

  constructor() {
    const subscriptionKeyCredential = new SubscriptionKeyCredential(
      environment.mapAuthOption!.subscriptionKey!
    );

    this.pipeline = MapsURL.newPipeline(subscriptionKeyCredential, {
      retryOptions: { maxTries: 4 },
    });
  }

  public async getCoordinatesFromAddress(
    address: string
  ): Promise<[number, number]> {
    const searchURL = new SearchURL(this.pipeline);
    const response = await searchURL.searchAddress(
      Aborter.timeout(10000),
      address
    );
    if (
      (response?.summary?.numResults ?? 0) > 0 &&
      response.results &&
      response.results[0].position
    ) {
      const position = response.results[0].position;
      return [position.lon!, position.lat!];
    }
    return [0, 0];
  }

  public get DefaultOptions(): atlas.Options {
    return {
      zoom: 16,
      language: 'de-DE',
      showLogo: false,
      showFeedbackLink: false,
      dragRotateInteraction: false,
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: environment.mapAuthOption?.subscriptionKey,
      },
    };
  }
}
