import { NgModule } from '@angular/core';
import { TicketCreateTelekomComponent } from '@mandants/telekom/features/tickets/ticket-create/ticket-create.component';
import { TicketStatusItemTelekomComponent } from '@mandants/telekom/features/tickets/ticket-status-item/ticket-status-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from '@mandants/telekom/override/override.module';
import { SafeHtmlPipe } from '@shared/pipes';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    TicketCreateTelekomComponent,
    TicketStatusItemTelekomComponent,
  ],
  imports: [SharedModule, TranslateModule, OverrideModule, SafeHtmlPipe],
  exports: [TicketCreateTelekomComponent, TicketStatusItemTelekomComponent],
  providers: [],
})
export class TelekomSharedModule {}
