<div class="headline-container">  
  <div mat-dialog-title>
    <su-headline h="h3" skin="primary">{{ 'access_orderer.history.dialog.title' | translate }}</su-headline>
  </div>

  <su-button
      [skin]="'secondary'"
      [icon]="'xmark'"
      (onClick)="dialogRef.close()">
  </su-button>
</div>

<div mat-dialog-content>
  <mtx-grid
    #grid
    [columns]="columns"
    [data]="data.items"
    [loading]="isLoading"
    [rowStriped]="true"
    [cellSelectable]="false">
  </mtx-grid>
</div>