import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PermissionMode } from '../models/permissionMode';

export interface IDialogData {
  title?: string;
  message?: string;
  id?: string;
}

@Component({
  selector: 'app-denial-dialog',
  templateUrl: './denial-dialog.component.html',
})
export class DenialDialogComponent {
  reasonForDenial = '';
  constructor(
    public dialogRef: MatDialogRef<DenialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}

  public onOk() {
    this.closeDialog(PermissionMode.Denial);
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  public closeDialog(mode: PermissionMode) {
    const result = {
      mode: mode,
      reasonForDenial: this.reasonForDenial,
      id: this.data.id,
    };
    this.dialogRef.close(result);
  }
}
