<ng-container *ngIf="(isLandingModule | async) !== true">
  <su-slot-mobile-top-bar
    *ngIf="hasSideNav"
    [brandingLogoXs]="brandingLogoXs"
    [brandingTitle]="brandingTitle"
    [navItemsTree]="navItemsTree"
    [metaNavItemsTree]="metaNavItemsTree"
    [topBarBtns]="topBarBtns"
    [userInfo]="userService.accountDisplayName"
    [accountBtns]="userService.isGsusUserBehavior$ ? accountBtnsGsus : accountBtns"
    [isOpen]="isSideNavOpen"
    [activeNavItem]="activeNavItem"
    (onToggle)="handleSideNavToggle()"
    (onLogoClick)="handleHomeBtnClick()"></su-slot-mobile-top-bar>
  <su-slot-side-nav
    *ngIf="hasSideNav"
    [brandingLogoXl]="brandingLogoXl"
    [brandingLogoXs]="brandingLogoXs"
    [brandingTitle]="brandingTitle"
    [isOpen]="isSideNavOpen"
    [activeNavItem]="activeNavItem"
    [navItemsTree]="navItemsTree"
    [metaNavItemsTree]="metaNavItemsTree"
    (onToggle)="handleSideNavToggle()"
    (onLogoClick)="handleHomeBtnClick()"></su-slot-side-nav>
  <su-slot-main [hasSideNav]="hasSideNav" [isSideNavOpen]="isSideNavOpen">
    <su-slot-top-bar
      [breadcrumbs]="breadcrumbs"
      [topBarBtns]="topBarBtns"
      [userInfo]="userService.accountDisplayName"
      [accountBtns]="userService.isGsusUserBehavior$ ? accountBtnsGsus : accountBtns"
      (onHomeBtnClick)="handleHomeBtnClick()"
      (onBackBtnClick)="handleBackBtnClick()"
      (onLoginClick)="login()"></su-slot-top-bar>

    <router-outlet></router-outlet>
  </su-slot-main>
</ng-container>

<div style="width: 100%" *ngIf="(isLandingModule | async) === true">
  <router-outlet></router-outlet>
</div>
