import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  CreateFurnitureOrder,
  EmploymentClient,
  EmploymentSearchResultDto,
  IncidentClient,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { EmployeeSearchFormlyFieldService } from '@core/services/employee-search-formly-field-service/employee-search-formly-field.service';
import { FileService } from '@core/services/file-service/file.service';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { TicketCreateBaseComponent } from '@features/tickets/components/ticket-create/ticket-create.base';
import { fileAttachments } from '@mandants/telekom/features/tickets/ticket-create/field-configs';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { SafeHtmlPipe } from '@shared/pipes';
import { RegexpZipCodeDE } from '@shared/utils/regex';
import { Moment } from 'moment';
import { furnitureOptions } from './field-configs/furniture-options.field-config';

@Component({
  selector: 'app-furniture-order',
  standalone: true,
  imports: [
    SmartusComponentsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatDatepickerModule,
    OverrideModule,
    SafeHtmlPipe,
  ],
  templateUrl: './furniture-order.component.html',
  styleUrl: './furniture-order.component.scss',
})
export class FurnitureOrderComponent
  extends TicketCreateBaseComponent
  implements OnInit
{
  private employeeSearchFormlyFieldService = inject(
    EmployeeSearchFormlyFieldService
  );
  private locationFormlyFieldService = inject(LocationFormlyFieldService);
  private breadcrumbService = inject(BreadcrumbService);
  private incidentClient = inject(IncidentClient);
  private employmentClient = inject(EmploymentClient);
  private fileService = inject(FileService);

  override loading = false;

  override form = new FormGroup({});

  override formModel = {};

  override formFields: FormlyFieldConfig[] = [];

  override filledForm: FormlyFieldConfig[] | null;

  override title: string;

  minDesiredDate = new Date();

  cardConfirm: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  currentUserEmail = this.msalService.instance.getActiveAccount()?.idTokenClaims
    ?.email as string;

  currentUser: EmploymentSearchResultDto;

  ngOnInit() {
    this.title = this.translateService.instant(
      'ticket.create.furniture_order.title'
    );

    this.breadcrumbService.setBreadcrumbs([{ label: this.title }]);

    this.successTitle = this.translateService.instant(
      'ticket.create.order_access.successTitle'
    );
    this.successMessage = this.translateService.instant(
      'ticket.create.order_access.successMessage'
    );

    this.minDesiredDate.setDate(this.minDesiredDate.getDate() + 42);

    this.loading = true;
    this.loadingMessage = this.translateService.instant('form.loading');
    this.employmentClient.searchEmployee(this.currentUserEmail).subscribe({
      next: res => {
        this.currentUser = res.entites[0];
        this.formFields = this.getFormFieldsForTelekomLocation();
        this.loading = false;
        this.loadingMessage = this.translateService.instant(
          'form.loading_message'
        );
      },
    });

    this.card = {
      header: {
        title: {
          label: '',
        },
      },
      footer: {
        button1: {
          label: this.translateService.instant('forward'),
          disabled: false,
          onClick: () => {
            this.fileService.setFileAttachmentType(this.form.value, 'SO7');
            this.validateForm();
          },
        },
      },
    };

    this.description = this.translateService.instant(
      'ticket.create.furniture_order.p__info_important_notes'
    );
    this.descriptionStyle =
      'background-color: #E20074; color: white; border-radius: 5px; padding: 15px;';
    this.descriptionContainerStyle =
      'width: 100%; max-width: 840px; border-radius: 15px; padding: 0 15px;';
  }

  getFormFieldsForTelekomLocation() {
    return [
      {
        wrappers: [],
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'ticket.create.furniture_order.delivery_address'
              ),
            },
          },
          {
            wrappers: ['no-border'],
            type: 'radio',
            key: 'telekomCheck',
            defaultValue: true,
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.telekom_location'
              ),
              showLabel: true,
              required: true,
              options: [
                {
                  value: true,
                  label: this.translateService.instant('shared.yes'),
                },
                {
                  value: false,
                  label: this.translateService.instant('shared.no'),
                },
              ],
            },
            hooks: {
              onInit: field => {
                field?.formControl?.valueChanges.subscribe({
                  next: () => {
                    this.form.markAsUntouched();
                    this.form.get('searchResult').disable();
                    this.formFields = this.getFormFieldsForTeleheim();
                  },
                });
              },
            },
          },
          ...this.locationFormlyFieldService.autocompleteSearch(
            false,
            null,
            true
          ),
          {
            type: 'datepicker',
            key: 'desiredDate',
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.desired_date.title'
              ),
              description: this.translateService.instant(
                'ticket.create.furniture_order.desired_date.note'
              ),
              datepickerOptions: {
                min: this.minDesiredDate,
                filter: (d: Moment): boolean => {
                  const day = d?.day();
                  return day !== 0 && day !== 6;
                },
              },
              required: true,
            },
          },
          {
            wrappers: ['description', 'form-field'],
            type: 'textarea',
            key: 'description',
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.description_field.title'
              ),
              description: this.translateService.instant(
                'ticket.create.furniture_order.description_field.note'
              ),
              required: true,
              autosize: true,
              autosizeMinRows: 3,
            },
          },
          ...fileAttachments(this.translateService, true),
          ...furnitureOptions(this.translateService, true),
          {
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'ticket.create.headline__search_creator'
                  ),
                },
              },
              {
                key: 'editor',
                fieldGroup: [
                  ...this.employeeSearchFormlyFieldService.autocompleteSearch(
                    'ticket.create.placeholder__search_processor',
                    null,
                    false,
                    true,
                    this.currentUserEmail,
                    null,
                    null,
                    this.currentUser
                  ),
                ],
              },
            ],
          },
          {
            type: 'input',
            key: 'pspElement',
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.psp_element.title'
              ),
              description: this.translateService.instant(
                'ticket.create.furniture_order.psp_element.note'
              ),
              maxLength: 100,
            },
          },
        ],
      },
    ];
  }

  getFormFieldsForTeleheim() {
    return [
      {
        wrappers: [],
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'ticket.create.furniture_order.delivery_address'
              ),
            },
          },
          {
            wrappers: ['no-border'],
            type: 'radio',
            key: 'telekomCheck',
            defaultValue: true,
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.telekom_location'
              ),
              showLabel: true,
              required: true,
              options: [
                {
                  value: true,
                  label: this.translateService.instant('shared.yes'),
                },
                {
                  value: false,
                  label: this.translateService.instant('shared.no'),
                },
              ],
            },
            hooks: {
              onInit: field => {
                field?.formControl?.valueChanges.subscribe({
                  next: () => {
                    this.form.get('deliveryAddress').disable();
                    this.form
                      .get('furnitureOptions.additionalAgreement')
                      .disable();
                    this.formFields = this.getFormFieldsForTelekomLocation();
                  },
                });
              },
            },
          },
          {
            key: 'deliveryAddress',
            fieldGroup: [
              {
                type: 'input',
                key: 'firstName',
                props: {
                  label: this.translateService.instant(
                    'ticket.create.label__firstName'
                  ),
                  required: true,
                  maxLength: 100,
                },
              },
              {
                type: 'input',
                key: 'lastName',
                props: {
                  label: this.translateService.instant(
                    'ticket.create.label__lastName'
                  ),
                  required: true,
                  maxLength: 100,
                },
              },
              {
                type: 'input',
                key: 'street',
                props: {
                  label: this.translateService.instant(
                    'ticket.create.label__street_long'
                  ),
                  required: true,
                  maxLength: 200,
                },
              },
              {
                type: 'input',
                key: 'zipCode',
                props: {
                  label: this.translateService.instant(
                    'ticket.create.label__zipcode'
                  ),
                  pattern: RegexpZipCodeDE,
                },
                validation: {
                  messages: {
                    pattern: this.translateService.instant(
                      'formErrors.zipcode.pattern'
                    ),
                  },
                },
              },
              {
                type: 'input',
                key: 'city',
                props: {
                  label: this.translateService.instant(
                    'ticket.create.label__city'
                  ),
                  required: true,
                },
              },
            ],
          },
          {
            type: 'datepicker',
            key: 'desiredDate',
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.desired_date.title'
              ),
              description: this.translateService.instant(
                'ticket.create.furniture_order.desired_date.note'
              ),
              datepickerOptions: {
                min: this.minDesiredDate,
              },
              required: true,
            },
          },
          {
            wrappers: ['description', 'form-field'],
            type: 'textarea',
            key: 'description',
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.description_field.title'
              ),
              description: this.translateService.instant(
                'ticket.create.furniture_order.description_field.note'
              ),
              required: true,
              autosize: true,
              autosizeMinRows: 3,
            },
          },
          ...fileAttachments(this.translateService, true),
          ...furnitureOptions(this.translateService, false),
          {
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'ticket.create.headline__search_creator'
                  ),
                },
              },
              {
                key: 'editor',
                fieldGroup: [
                  ...this.employeeSearchFormlyFieldService.autocompleteSearch(
                    'ticket.create.placeholder__search_processor',
                    null,
                    false,
                    true,
                    this.currentUserEmail,
                    null,
                    null,
                    this.currentUser
                  ),
                ],
              },
            ],
          },
          {
            type: 'input',
            key: 'pspElement',
            props: {
              label: this.translateService.instant(
                'ticket.create.furniture_order.psp_element.title'
              ),
              description: this.translateService.instant(
                'ticket.create.furniture_order.psp_element.note'
              ),
              maxLength: 100,
            },
          },
        ],
      },
    ];
  }

  pressBackToEdit() {
    this.filledForm = null;
  }

  public override clientEndpoint = data => {
    return this.incidentClient.createFurnitureOrder(data);
  };

  protected override prepareSubmit = data => {
    const d = data;
    const furnitureOrder: CreateFurnitureOrder = {};
    furnitureOrder.archObjId = d.searchResult ? d.searchResult.id : null;
    furnitureOrder.deliveryAddressFirstName = d.deliveryAddress
      ? d.deliveryAddress.firstName
      : null;
    furnitureOrder.deliveryAddressLastName = d.deliveryAddress
      ? d.deliveryAddress.lastName
      : null;
    furnitureOrder.deliveryAddressStreet = d.deliveryAddress
      ? d.deliveryAddress.street
      : null;
    furnitureOrder.deliveryAddressPostalCode = d.deliveryAddress
      ? d.deliveryAddress.zipCode
      : null;
    furnitureOrder.deliveryAddressCity = d.deliveryAddress
      ? d.deliveryAddress.city
      : null;
    furnitureOrder.requestedDate = d.desiredDate;
    furnitureOrder.incidentDescription = d.description;
    furnitureOrder.flooringHard = d.furnitureOptions?.roll == 'hard';
    furnitureOrder.flooringSoft = d.furnitureOptions?.roll == 'soft';
    furnitureOrder.statementBad = d.furnitureOptions?.statement ?? false;
    furnitureOrder.electricDesk = d.furnitureOptions?.electricDesk ?? false;
    furnitureOrder.specialOrder = d.furnitureOptions?.specialOrder ?? false;
    furnitureOrder.severeDisability =
      d.furnitureOptions?.severeDisability ?? false;
    furnitureOrder.teleWork = d.furnitureOptions?.additionalAgreement ?? false;
    furnitureOrder.contactPerson = d.editor?.searchResult ?? null;
    furnitureOrder.pspElement = d.pspElement ?? null;
    return { furnitureOrder: furnitureOrder };
  };

  public override getStatusPath() {
    return '/dashboard';
  }
}
