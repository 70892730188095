import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-description-wrapper',
  template: `
    <ng-container #fieldComponent></ng-container>
    <div class="description" [innerHtml]="props.customDescription"></div>
  `,
  styles: [
    `
      .description {
        margin-bottom: 35px;
        padding: 0 16px;
        font-size: 12px;
      }
    `,
  ],
})
export class FormlyDescriptionWrapperComponent extends FieldWrapper {}
