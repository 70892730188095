import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-button-wrapper',
  templateUrl: './button-wrapper.component.html',
  styleUrls: ['./button-wrapper.component.scss'],
})
export class ButtonWrapperComponent {
  @Input() routerLink: string | undefined = '';

  @Input() buttonText = '';

  @Input() buttonVariant = 'primary';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  buttonClick(route: string | undefined) {
    if (route) {
      this.router.navigate([route], { relativeTo: this.activatedRoute });
    }
  }
}
