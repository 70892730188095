import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { EditRoomService } from '@core/services/edit-room/edit-room.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BuildingRoomViewModel } from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class EditRoomResolver {
  private roomDataService = inject(EditRoomService);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private translateService = inject(TranslateService);

  resolve(route: ActivatedRouteSnapshot): Observable<BuildingRoomViewModel> {
    const stageId = route.paramMap.get('stageId');
    const floorId = route.paramMap.get('floorId');
    const roomId = route.paramMap.get('roomId');

    const spaceId = stageId + '/' + floorId + '/' + roomId;

    if (!spaceId) {
      this.handleNavigationError();
      return of(null);
    }

    return this.roomDataService
      .getBuildingMaintenanceRoomInformation(spaceId)
      .pipe(
        catchError(error => {
          if (error.status === 404 || error.status === 500) {
            this.handleNavigationError();
            return of(null);
          } else {
            throw error;
          }
        })
      );
  }

  private handleNavigationError() {
    this.toastr.error(
      this.translateService.instant('roles_handle_resolver.not_found')
    );
    this.router.navigate(['/']);
  }
}
