import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { SmartusComponentsModule } from '@affinis/smartus-components';
import { TranslateModule } from '@ngx-translate/core';
import { DtDetailAreaType } from '@core/api';

@Component({
  selector: 'app-nested-select-room-dropdowns',
  standalone: true,
  imports: [
    SmartusComponentsModule,
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormlyModule,
    TranslateModule,
  ],
  template: `
    <div style="display: flex; flex-direction: column;">
      <mat-form-field class="fill">
        <mat-label>{{
          'buildingMaintenance.labels.selectCategory' | translate
        }}</mat-label>
        <mat-select
          [formControl]="levelControl"
          (selectionChange)="onCategoryChange($event.value)">
          <mat-option
            *ngFor="let item of topLevelOptions"
            [value]="item.categoryName">
            {{ item.categoryName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="fill" *ngIf="dtUsageTypes.length">
        <mat-label>{{
          'buildingMaintenance.labels.selectUsageType' | translate
        }}</mat-label>
        <mat-select
          [formControl]="usageTypeControl"
          (selectionChange)="onUsageTypeChange($event.value)">
          <mat-option
            *ngFor="let dtUsageType of dtUsageTypes"
            [value]="dtUsageType">
            {{ dtUsageType.usageTypeId }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="fill" *ngIf="dtDetailAreaTypes.length">
        <mat-label>{{
          'buildingMaintenance.labels.selectDetailArea' | translate
        }}</mat-label>
        <mat-select [formControl]="detailAreaControl">
          <mat-option
            *ngFor="let dtDetailAreaType of dtDetailAreaTypes"
            [value]="dtDetailAreaType">
            {{ dtDetailAreaType.detailAreaTypeId }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
})
export class NestedSelectRoomDropdownsComponent
  extends FieldType
  implements OnInit
{
  topLevelOptions: { categoryName: string }[] = [];
  dtUsageTypes: any[] = [];
  dtDetailAreaTypes: DtDetailAreaType[] = [];

  levelControl = new FormControl();
  usageTypeControl = new FormControl();
  detailAreaControl = new FormControl();

  ngOnInit() {
    if (this.props && this.props.datatypeOfUseDetailedId) {
      this.topLevelOptions = this.props.datatypeOfUseDetailedId.map(item => ({
        categoryName: item.categoryName,
      }));
    }

    if (this.formControl instanceof FormControl) {
      this.levelControl.setValue(this.formControl.value);
      this.formControl.valueChanges.subscribe(value =>
        this.onCategoryChange(value)
      );
    } else {
      console.error('Expected formControl to be a FormControl.');
    }

    this.levelControl.valueChanges.subscribe(value => {
      this.onCategoryChange(value);
      this.updateFormControl();
    });

    this.usageTypeControl.valueChanges.subscribe(() => {
      this.updateFormControl();
    });

    this.detailAreaControl.valueChanges.subscribe(() => {
      this.updateFormControl();
    });
  }

  onCategoryChange(value: string) {
    const selectedCategory = this.props.datatypeOfUseDetailedId.find(
      item => item.categoryName === value
    );
    if (selectedCategory) {
      this.dtUsageTypes = selectedCategory.dtUsageTypes;
      this.dtDetailAreaTypes = [];
      this.usageTypeControl.reset();
      this.detailAreaControl.reset();
    }
  }

  onUsageTypeChange(value: any) {
    const selectedDtUsageType = this.dtUsageTypes.find(
      item => item.usageTypeId === value.usageTypeId
    );
    if (selectedDtUsageType) {
      this.dtDetailAreaTypes = selectedDtUsageType.dtDetailAreaTypes;
      this.detailAreaControl.reset();
    }
  }

  private updateFormControl() {
    this.formControl.setValue({
      levelControl: this.levelControl.value,
      usageTypeControl: this.usageTypeControl.value,
      detailAreaControl: this.detailAreaControl.value,
    });
  }
}
