<su-headline h="h1" skin="primary">{{ headline }}</su-headline>

<su-card [card]="cardUpdateUser" *ngIf="user">
    <div card-content>
        <ng-container *ngIf="!user.isTelekomEmployee">
            <formly-form
                [form]="formB2CUser"
                [fields]="formFieldsB2CUser"
                [model]="formModelB2CUser">
            </formly-form>
            <hr>
        </ng-container>

        <div class="toggle-container">
            <mat-slide-toggle *ngIf="!user.isTelekomEmployee" color="primary"
                [(ngModel)]="isLinkContactChecked"
                (change)="toggleLinkContactCheck()"
                [disabled]="isLinkContactCheckedDisabled">
                {{ 'administration.update_user.label__link_new_contact' | translate }}
            </mat-slide-toggle>
        </div>
        
        <formly-form
            [form]="form"
            [fields]="formFields"
            [model]="formModel">
        </formly-form>
    </div>
</su-card>

<app-spinner-overlay [show]="loading" [label]="loadingMessage"></app-spinner-overlay>