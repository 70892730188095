import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartusComponentsModule } from '@affinis/smartus-components';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyModule } from '@ngx-formly/core';
import { MtxGridModule } from '@ng-matero/extensions/grid';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileDefaultComponent } from './components/profile-default.component';

const components = [ProfileDefaultComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    SmartusComponentsModule,
    TranslateModule,
    FormlyModule,
    MtxGridModule,
    AngularEditorModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [...components],
})
export class ProfileDefaultModule {}
