import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MtxGrid, MtxGridColumn } from '@ng-matero/extensions/grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceBuildingEditModalComponent } from './maintenance-building-edit-modal/maintenance-building-edit-modal.component';
import { MaintenanceBuildingAddNewComponent } from './maintenance-building-add-new/maintenance-building-add-new.component';
import { EditBuildService } from '@core/services/edit-building/edit-building.service';
import { ToastrService } from 'ngx-toastr';
import { DataMaintenance } from '@shared/constants/datamaintenance.constants';
import { BaseSelectViewModel, BuildingMaintenanceViewModel } from '@core/api';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OverrideModule } from '@override/override.module';

@Component({
  selector: 'app-maintenance-building',
  standalone: true,
  imports: [
    CommonModule,
    SmartusComponentsModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MtxGrid,
    MaintenanceBuildingEditModalComponent,
    FormsModule,
    MatButtonModule,
    OverrideModule,
  ],
  templateUrl: './maintenance-building.component.html',
  styleUrl: './maintenance-building.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceBuildingComponent implements OnInit {
  @ViewChild('deleteButtonTemplate', { static: true })
  deleteButtonTemplate: TemplateRef<any>;
  @ViewChild('editButtonTemplate', { static: true })
  editButtonTemplate: TemplateRef<any>;
  @Input() title: string;
  @Input() buildingClasses: any;
  @Input()
  dataTableBuildMaintenance$: BehaviorSubject<BuildingMaintenanceViewModel>;
  @Input() buildingId: string;
  @Input() changeReasons: BaseSelectViewModel[];

  private translateService = inject(TranslateService);
  private dialog = inject(MatDialog);
  private cd = inject(ChangeDetectorRef);
  private editBuildingService = inject(EditBuildService);
  private toastr = inject(ToastrService);
  public dialogRef: MatDialogRef<MaintenanceBuildingComponent>;
  public route = inject(ActivatedRoute);

  columns: MtxGridColumn[] = [];
  selectedItem: any;
  isModalOpen = false;
  isBuildingUsageEditable = true;
  parsedData: any;
  DataMaintenance = DataMaintenance;
  isLoading: boolean = false;

  ngOnInit(): void {
    this.initializeColumns();
    this.dataTableBuildMaintenance$.subscribe({
      next: () => {
        this.parseData();
      },
    });
  }

  private initializeColumns(): void {
    this.columns = [
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.validFrom'
        ),
        field: 'validFrom',
        sortable: true,
        class: '',
        resizable: true,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.validUntil'
        ),
        field: 'validUntil',
        sortable: true,
        class: 'truncated',
        resizable: true,
        width: '200dp',
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.buildingUsage'
        ),
        field: 'buildingClassId',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.createdBy'
        ),
        field: 'createdBy',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.changedBy'
        ),
        field: 'changedBy',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.createdOn'
        ),
        field: 'createDate',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.changedOn'
        ),
        field: 'changeDate',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.changeReason'
        ),
        field: 'changeReason',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translateService.instant(
          'buildingMaintenance.table.status'
        ),
        field: 'status',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: '',
        field: 'editIcon',
        width: '80px',
        resizable: true,
        cellTemplate: this.editButtonTemplate,
      },
      {
        header: '',
        field: 'iconRemove',
        width: '80px',
        resizable: true,
        cellTemplate: this.deleteButtonTemplate,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dataTableBuildMaintenance &&
      !changes.dataTableBuildMaintenance.isFirstChange()
    ) {
      this.parseData();
      this.cd.markForCheck();
    }
  }

  parseData(): void {
    const data = this.dataTableBuildMaintenance$.value;

    if (data?.archObjUtilizationHistories) {
      this.parsedData = data.archObjUtilizationHistories.map(
        (history: any) => ({
          ...history,
          buildingClassId: history.buildingClassId ?? '--',
          validFrom: this.formatDate(history.validFrom) || '--',
          validUntil: this.formatDate(history.validUntil) || '--',
          createDate: this.formatDate(history.createDate) || '--',
          changeDate: this.formatDate(history.changeDate) || '--',
          createdBy: history.createdBy || '--',
          changedBy: history.changedBy || '--',
          changeReason: history.changeReason ?? '--',
        })
      );
    } else {
      this.parsedData = [];
    }
    this.cd.markForCheck();
  }

  formatDate(dateString: string) {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  onRowClick(event: any) {
    this.selectedItem = event.rowData;
    this.isModalOpen = true;
  }

  openEditModal(item: any, buildingClasses: any): void {
    const dialogRef = this.dialog.open(MaintenanceBuildingEditModalComponent, {
      minWidth: '40vw',
      minHeight: '60vh',
      data: {
        ...item,
        buildingClasses,
        changeReasons: this.changeReasons,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshBuildingData();
        this.cd.detectChanges();
      }
    });
  }

  openAddModal(item: any): void {
    const dialogRef = this.dialog.open(MaintenanceBuildingAddNewComponent, {
      minWidth: '40vw',
      minHeight: '35vh',
      data: {
        buildingClasses: item,
        buildingId: this.buildingId,
        changeReasons: this.changeReasons,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshBuildingData();
        this.cd.detectChanges();
      }
    });
  }

  refreshBuildingData(): void {
    this.editBuildingService
      .getBuildingMaintenanceInformation(this.buildingId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.dataTableBuildMaintenance$.next(data);
            this.cd.markForCheck();
          }
        },
      });
  }

  editRow(event: Event, rowData: any): void {
    event.stopPropagation();
    this.openEditModal(rowData, this.buildingClasses);
  }

  deleteRow(event: Event, rowData: any) {
    event.stopPropagation();
    this.isLoading = true;
    this.editBuildingService.deleteArchObjUtilHistory(rowData.id).subscribe({
      next: () => {
        this.isLoading = false;
        this.refreshBuildingData();
        this.cd.detectChanges();
        this.toastr.success(
          this.translate('buildingMaintenance.toastr.success_delete')
        );
      },
      error: () => {
        this.isLoading = false;
        this.cd.detectChanges();
        this.toastr.error(
          this.translate('buildingMaintenance.toastr.error_delete')
        );
      },
    });
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
