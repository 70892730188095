import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { federalList } from './ticket-created-shared.field-configs';
import {
  RegexpLocationname,
  RegexpStreetname,
  RegexpZipCodeDE,
} from '@shared/utils/regex';

export function targetLocation(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__objectAdress'
            ),
            chapterText: translateService.instant(
              'ticket.create.p__info_objectadress'
            ),
            visibleInConfirm: true,
          },
        },
        {
          key: 'targetLocation',
          fieldGroup: [
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'street',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__street_property'
                    ),
                    visibleInConfirm: true,
                    maxLength: 300,
                    minLength: 3,
                    pattern: RegexpStreetname,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant(
                        'formErrors.street.pattern'
                      ),
                      minlength: err =>
                        translateService.instant(
                          'formErrors.street.minLength',
                          {
                            length: err.requiredLength,
                          }
                        ),
                    },
                  },
                  expressionProperties: {
                    'props.required': (_model, formstate) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
                {
                  type: 'input',
                  key: 'zipcode',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__zipcode_property'
                    ),
                    required: true,
                    visibleInConfirm: true,
                    minLength: 5,
                    maxLength: 300,
                    pattern: RegexpZipCodeDE,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant(
                        'formErrors.zipcode.pattern'
                      ),
                    },
                  },
                  expressionProperties: {
                    'props.required': (_model, formstate) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
              ],
            },
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'city',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__city_property'
                    ),
                    required: true,
                    visibleInConfirm: true,
                    minLength: 3,
                    maxLength: 300,
                    pattern: RegexpLocationname,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant(
                        'formErrors.city.pattern'
                      ),
                      minlength: err =>
                        translateService.instant('formErrors.city.minLength', {
                          length: err.requiredLength,
                        }),
                    },
                  },
                  expressionProperties: {
                    'props.required': (_model, formstate) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
                {
                  type: 'select',
                  key: 'state',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__state'
                    ),
                    required: true,
                    visibleInConfirm: true,
                    options: federalList(translateService.currentLang),
                  },
                  expressionProperties: {
                    'props.required': (_model, formstate) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
