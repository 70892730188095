/* eslint-disable @typescript-eslint/dot-notation */
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { AfterViewInit, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ContactClient,
  ContactViewModel,
  OrganisationViewModel,
  RoleViewModel,
  UserAdministrationClient,
  UserAdministrationIncidentRequest,
  UserClient,
  UserViewModel,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { ContactSearchFormlyFieldService } from '@core/services/contact-search-formly-field-service/contact-search-formly-field.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { RegexpEmail } from '@shared/utils/regex';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements AfterViewInit {
  headline = this.translateService.instant('administration.update_user.title');

  userId: string;

  user: UserViewModel;

  roles: RoleViewModel[];

  organisations: OrganisationViewModel[];

  cardUpdateUser: CardConfig;

  form = new FormGroup({});

  formModel: any = {};

  formFields: Array<FormlyFieldConfig>;

  formB2CUser = new FormGroup({});

  formModelB2CUser: any = {};

  formFieldsB2CUser: Array<FormlyFieldConfig>;

  isLinkContactChecked = false;

  isLinkContactCheckedDisabled = false;

  contact: ContactViewModel;

  loading = false;

  loadingMessage = '';

  isUserSelected = false;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private contactFormlyFieldService: ContactSearchFormlyFieldService,
    private dialog: MatDialog,
    private contactClient: ContactClient,
    private userClient: UserClient,
    private toastr: ToastrService,
    private userAdministrationClient: UserAdministrationClient
  ) {
    this.loading = true;
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      this.userId = navigation.extras.state.userId;
      this.roles = navigation.extras.state.roles;
      this.organisations = navigation.extras.state.organisations;
    }

    this.userClient.get(this.userId).subscribe({
      next: user => {
        this.user = user;

        if (this.user?.contactId && this.user?.contactId !== 'not-set') {
          this.contactClient.retrieve(this.user?.contactId).subscribe({
            next: contact => {
              this.contact = contact;
              this.isLinkContactChecked = false;
              this.formFields = this.getFormFields();
              this.formFieldsB2CUser = this.getFormFieldsB2CUser();
              this.loading = false;
            },
            error: () => {
              this.isLinkContactChecked = true;
              this.isLinkContactCheckedDisabled = true;
              this.formFields = this.getFormFields();
              this.formFieldsB2CUser = this.getFormFieldsB2CUser();
              this.loading = false;
            },
          });
        } else if (
          !this.user?.contactId ||
          this.user?.contactId === 'not-set'
        ) {
          this.isLinkContactChecked = true;
          this.isLinkContactCheckedDisabled = true;
          this.formFields = this.getFormFields();
          this.formFieldsB2CUser = this.getFormFieldsB2CUser();
          this.loading = false;
        }

        this.cardUpdateUser.footer.button3 = {
          label: this.user.accountEnabled
            ? this.translateService.instant(
                'administration.update_user.lock_user.title'
              )
            : this.translateService.instant(
                'administration.update_user.unlock_user.title'
              ),
          onClick: () => this.openDialogChangeUserState(),
          disabled: this.user.isTelekomEmployee,
        };
      },
      error: err => {
        let message = this.translateService.instant('generic.errorMessage');
        if (err.status === 404) {
          message = this.translateService.instant(
            'administration.users.error_message_user_not_found'
          );
        }
        this.toastr.error(
          message,
          this.translateService.instant('alert.error')
        );
        this.loading = false;
        this.router.navigateByUrl('user-administration');
      },
    });

    this.cardUpdateUser = {
      header: {
        title: {
          label: this.headline,
        },
      },
      footer: {
        button1: {
          label: this.translateService.instant(
            'administration.update_user.label__submit'
          ),
          onClick: () => this.validateForm(),
          disabled: false,
        },
        button2: {
          label: this.translateService.instant(
            'administration.update_user.label__cancel'
          ),
          onClick: () => this.resetUpdateUser(),
          disabled: false,
        },
        button3: {
          label: this.user?.accountEnabled
            ? this.translateService.instant(
                'administration.update_user.lock_user.title'
              )
            : this.translateService.instant(
                'administration.update_user.unlock_user.title'
              ),
          onClick: () => this.openDialogChangeUserState(),
          disabled: this.user?.isTelekomEmployee,
        },
      },
    };

    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.translateService.instant(
          'menu.admin.component.user_administration'
        ),
        onClick: () => {
          this.router.navigateByUrl('user-administration');
        },
      },
      {
        label: this.headline,
      },
    ]);
  }

  ngAfterViewInit(): void {
    this.formFieldsB2CUser = this.getFormFieldsB2CUser();
  }

  getFormFields() {
    this.formModel = {};

    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          !this.user?.isTelekomEmployee && this.isLinkContactChecked
            ? {
                wrappers: [],
                fieldGroup: [
                  {
                    type: 'chapter',
                    props: {
                      chapterTitle: this.translateService.instant(
                        'administration.create_user.label__searchContact'
                      ),
                      disabled: this.user?.isTelekomEmployee,
                    },
                  },
                  {
                    key: 'contact',
                    fieldGroup: [
                      ...this.contactFormlyFieldService.autocompleteSearch(
                        this.translateService.instant(
                          'administration.create_user.placeholder__search'
                        ),
                        this.onSelectUser.bind(this),
                        false,
                        true,
                        null,
                        true,
                        this.onDeleteUser.bind(this)
                      ),
                    ],
                  },
                ],
              }
            : null,
          {
            type: 'flex-layout',
            key: 'userData',
            fieldGroup: [
              {
                key: 'firstName',
                type: 'input',
                defaultValue: this.user?.isTelekomEmployee
                  ? this.user?.firstName
                  : this.isLinkContactChecked === false
                    ? this.user?.firstName
                    : null,
                props: {
                  label: this.translateService.instant(
                    'administration.create_user.label__firstName'
                  ),
                  required: true,
                  disabled:
                    this.user?.isTelekomEmployee ||
                    (this.user?.firstName
                      ? !this.isLinkContactChecked
                        ? true
                        : false
                      : false),
                },
              },
              {
                key: 'lastName',
                type: 'input',
                defaultValue: this.user?.isTelekomEmployee
                  ? this.user?.lastName
                  : this.isLinkContactChecked === false
                    ? this.user?.lastName
                    : null,
                props: {
                  label: this.translateService.instant(
                    'administration.create_user.label__lastName'
                  ),
                  required: true,
                  disabled:
                    this.user?.isTelekomEmployee ||
                    (this.user?.firstName
                      ? !this.isLinkContactChecked
                        ? true
                        : false
                      : false),
                },
              },
              {
                key: 'email',
                type: 'input',
                defaultValue: this.user?.isTelekomEmployee
                  ? this.user?.email
                  : this.isLinkContactChecked === false
                    ? this.user?.email
                    : null,
                props: {
                  label: this.translateService.instant(
                    'administration.create_user.label__email'
                  ),
                  required: true,
                  disabled:
                    this.user?.isTelekomEmployee ||
                    (this.user?.firstName
                      ? !this.isLinkContactChecked
                        ? true
                        : false
                      : false),
                  pattern: RegexpEmail,
                },
                validation: {
                  messages: {
                    pattern: (_err, _field: FormlyFieldConfig) =>
                      this.translateService.instant('formErrors.email.pattern'),
                  },
                },
              },
              {
                key: 'organisation',
                type: 'select',
                hide: this.user?.isTelekomEmployee,
                defaultValue: this.contact
                  ? this.contact.organisation?.name
                  : null,
                props: {
                  label: this.translateService.instant(
                    'administration.create_user.label__organisation'
                  ),
                  required: true,
                  options: this.organisations,
                  valueProp: 'id',
                  labelProp: 'name',
                },
              },
              {
                key: 'roles',
                type: 'autocomplete-chips-for-roles',
                defaultValue: this.user?.roles.filter(
                  role => role.description !== ''
                ),
                props: {
                  label: this.translateService.instant(
                    'administration.create_user.label__roles'
                  ),
                  required: false,
                  options: this.roles,
                  currentRoles: this.user.roles,
                },
              },
            ],
          },
          {
            key: 'userLocked',
            type: 'input',
            wrappers: ['readOnly', 'form-field'],
            defaultValue:
              this.user?.accountEnabled === true
                ? this.translateService.instant(
                    'administration.update_user.user_state.active'
                  )
                : this.translateService.instant(
                    'administration.update_user.user_state.deactive'
                  ),
            hide: this.user?.isTelekomEmployee,
            props: {
              label: this.translateService.instant(
                'administration.update_user.label__user_state'
              ),
            },
          },
        ],
      },
    ];
  }

  resetUpdateUser() {
    this.router.navigateByUrl('user-administration');
  }

  validateForm() {
    if (this.form.valid) {
      this.updateUser();
    } else {
      this.form.markAllAsTouched();
    }
  }

  updateUser() {
    this.loading = true;
    this.loadingMessage = this.translateService.instant('form.loading_message');
    let request: UserAdministrationIncidentRequest;

    if (this.isUserSelected) {
      request = {
        firstName:
          this.form.value['contact']?.searchResult?.firstName ??
          this.form.value['employee']?.searchResult?.firstName ??
          null,
        lastName:
          this.form.value['contact']?.searchResult?.lastName ??
          this.form.value['employee']?.searchResult?.lastName ??
          null,
        email:
          this.form.value['contact']?.searchResult?.email ??
          this.form.value['employee']?.searchResult.email ??
          null,
        companyId:
          this.form.value['contact']?.searchResult?.company?.id ??
          this.form.value['userData']?.organisation ??
          null,
        b2cUserId: this.user?.id ?? null,
        contactId: this.form.value['contact']?.searchResult?.contactId ?? null,
        roles:
          this.form.value['userData']?.roles?.map(
            (role: RoleViewModel) => role.id
          ) ?? null,
      };
    } else {
      request = {
        firstName:
          this.form.value['userData']?.firstName ??
          this.form.getRawValue()['userData']?.firstName ??
          null,
        lastName:
          this.form.value['userData']?.lastName ??
          this.form.getRawValue()['userData']?.lastName ??
          null,
        email:
          this.form.value['userData']?.email ??
          this.form.getRawValue()['userData']?.email ??
          null,
        companyId: this.form.value['userData']?.organisation ?? null,
        b2cUserId: this.user?.id ?? null,
        contactId: this.isLinkContactChecked ? null : this.user.contactId,
        roles:
          this.form.value['userData']?.roles?.map(
            (role: RoleViewModel) => role.id
          ) ?? null,
      };
    }

    this.userAdministrationClient.updateUser(request).subscribe({
      next: (incidentId: string) => {
        this.toastr.success(
          this.translateService.instant(
            'administration.update_user.' +
              (incidentId ? 'success_message' : 'success_instant_message')
          )
        );
        this.loading = false;
        this.router.navigateByUrl('user-administration');
      },
      error: err => {
        let message = this.translateService.instant('generic.errorMessage');
        if (err.status === 404) {
          message = this.translateService.instant(
            'administration.update_user.error_message_user_not_found'
          );
        }
        this.toastr.error(
          message,
          this.translateService.instant('alert.error')
        );
        this.loading = false;
      },
    });
  }

  onSelectUser(_id: any, _initialSearch, user: any) {
    this.formFields[0].form
      ?.get('userData.firstName')
      ?.setValue(user.firstName);
    this.formFields[0].form?.get('userData.lastName')?.setValue(user.lastName);
    this.formFields[0].form?.get('userData.email')?.setValue(user.email);
    this.formFields[0].form
      ?.get('userData.organisation')
      ?.setValue(user.company);

    this.formFields[0].form?.get('userData.firstName').disable();
    this.formFields[0].form?.get('userData.lastName').disable();
    this.formFields[0].form?.get('userData.email').disable();

    this.isUserSelected = true;
  }

  onDeleteUser() {
    this.form.reset();
    if (!this.user?.isTelekomEmployee) {
      this.formFields[0].form?.get('userData.firstName').enable();
      this.formFields[0].form?.get('userData.lastName').enable();
      this.formFields[0].form?.get('userData.email').enable();
    }
    this.isUserSelected = false;
  }

  openDialogChangeUserState() {
    const successDialog = this.dialog.open(DialogComponent, {
      panelClass: 'smartportal-dialog',
      width: '500px',
      data: {
        type: 'okDismissButton',
        title: this.user?.accountEnabled
          ? this.translateService.instant(
              'administration.update_user.lock_user.title'
            )
          : this.translateService.instant(
              'administration.update_user.unlock_user.title'
            ),
        message: this.user?.accountEnabled
          ? this.translateService.instant(
              'administration.update_user.lock_user.message'
            )
          : this.translateService.instant(
              'administration.update_user.unlock_user.message'
            ),
        okButtonText: this.translateService.instant('shared.confirm'),
        dismissButtonText: this.translateService.instant('shared.cancel'),
      },
    });

    successDialog.afterClosed().subscribe({
      next: confirmed => {
        if (confirmed) {
          this.loadingMessage = this.translateService.instant(
            'administration.update_user.loading_message'
          );
          this.loading = true;
          if (this.user?.accountEnabled === true) {
            this.deactivateUser();
          } else {
            this.activateUser();
          }
        }
      },
    });
  }

  activateUser() {
    this.userClient.activate(this.user?.id).subscribe({
      next: () => {
        this.formFields[0].form
          ?.get('userLocked')
          ?.setValue(
            this.translateService.instant(
              'administration.update_user.user_state.active'
            )
          );
        this.loading = false;
        this.updateUserStateButton();
        this.toastr.success(
          this.translateService.instant(
            'administration.update_user.user_state.success_message'
          )
        );
      },
      error: () => {
        this.toastr.error(
          this.translateService.instant(
            'administration.update_user.user_state.error_message'
          )
        );
        this.loading = false;
      },
    });
  }

  deactivateUser() {
    this.userClient.deactivate(this.user?.id).subscribe({
      next: () => {
        this.formFields[0].form
          ?.get('userLocked')
          ?.setValue(
            this.translateService.instant(
              'administration.update_user.user_state.deactive'
            )
          );
        this.loading = false;
        this.updateUserStateButton();
        this.toastr.success(
          this.translateService.instant(
            'administration.update_user.user_state.success_message'
          )
        );
      },
      error: () => {
        this.toastr.error(
          this.translateService.instant(
            'administration.update_user.user_state.error_message'
          )
        );
        this.loading = false;
      },
    });
  }

  toggleLinkContactCheck() {
    this.formFields = this.getFormFields();
  }

  updateUserStateButton() {
    this.user.accountEnabled = !this.user.accountEnabled;
    this.cardUpdateUser.footer.button3 = {
      label: this.user.accountEnabled
        ? this.translateService.instant(
            'administration.update_user.lock_user.title'
          )
        : this.translateService.instant(
            'administration.update_user.unlock_user.title'
          ),
      onClick: () => this.openDialogChangeUserState(),
      disabled: this.user.isTelekomEmployee,
      // hide: this.user.isTelekomEmployee,
    };
  }

  getFormFieldsB2CUser() {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'administration.update_user.label__user_data_b2c'
              ),
            },
          },
          {
            key: 'firstName',
            type: 'input',
            wrappers: ['readOnly', 'form-field'],
            defaultValue: this.user?.firstName,
            props: {
              label: this.translateService.instant(
                'administration.create_user.label__firstName'
              ),
            },
          },
          {
            key: 'lastName',
            type: 'input',
            wrappers: ['readOnly', 'form-field'],
            defaultValue: this.user?.lastName,
            props: {
              label: this.translateService.instant(
                'administration.create_user.label__lastName'
              ),
            },
          },
          {
            key: 'email',
            type: 'input',
            wrappers: ['readOnly', 'form-field'],
            defaultValue: this.user?.email,
            props: {
              label: this.translateService.instant(
                'administration.create_user.label__email'
              ),
            },
          },
        ],
      },
    ];
  }
}
