import { Component, OnInit } from '@angular/core';
import { RentalListingClient } from '@core/api';

@Component({
  selector: 'app-rental',
  templateUrl: './rental.component.html',
  styleUrls: ['./rental.component.scss'],
})
export class RentalComponent implements OnInit {
  videoUrl: string;
  constructor(private rentalListingClient: RentalListingClient) {}

  ngOnInit() {
    this.getVideo();
  }

  getVideo() {
    this.rentalListingClient.getVideoUrl().subscribe({
      next: response => {
        this.videoUrl = response;
      },
      error: error => {
        console.error('Fehler beim Abrufen der Video-URL', error);
      },
    });
  }
}
