import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';

export function closureLocation(
  translateService: TranslateService,
  locationFormlyFieldService: LocationFormlyFieldService,
  onSelectLocation: (id: string) => void,
  weNo?: string | null
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__closure_location'
            ),
          },
        },
        {
          key: 'searchLocation',
          fieldGroup: [
            ...locationFormlyFieldService.autocompleteSearch(
              false,
              { archObjId: weNo },
              true,
              onSelectLocation
            ),
          ],
        },
      ],
    },
  ];
}
