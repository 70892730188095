import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss'],
})
export class ModalFilterComponent implements OnInit {
  form = new FormGroup({});

  formModel: any = {};

  filterFields: any[];

  options: FormlyFormOptions = {
    formState: {
      mainModel: this.formModel,
    },
  };

  filterEvent = new EventEmitter<any>();

  searchEvent = new EventEmitter<any>();

  resetFilterEvent = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filterFields = data.filterFields[0].fieldGroup;
  }

  ngOnInit() {
    const ticketNumber = new URLSearchParams(window.location.search).get(
      'ticketNumber'
    );
    if (ticketNumber) {
      this.formModel = {
        ...this.data.filterValues,
        ticketNumber: ticketNumber,
      };
    } else {
      this.formModel = this.data.filterValues;
    }
  }

  dialogFooterConfig = {
    button1: {
      label: 'Filter anwenden',
      onClick: () => {
        this.data.actions.onSubmit(this.formModel);
        this.dialogRef.close();
      },
    },
    button2: {
      label: 'Filter zurücksetzen',
      onClick: () => {
        this.data.actions.onReset();
        this.resetFilter();
      },
    },
  };

  resetFilter() {
    this.form.reset();
    this.formModel = {};
    this.filterFields.forEach(item => {
      item.fieldGroup?.forEach((group: any) => {
        if (group.type === 'matdaterange') {
          if (group.key) {
            this.form.controls[group.key.toString()].setValue('delete');
          }
        }
      });
    });
    this.resetFilterEvent.emit();
  }
}
