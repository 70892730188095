/* eslint-disable @typescript-eslint/dot-notation */
import { MenuItem } from '@affinis/smartus-components';
import { ButtonProps } from '@affinis/smartus-components/lib/button/button.types';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Chip } from '@affinis/smartus-components/lib/chips/chips.component.types';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';
import {
  Component,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TokenClaims } from '@azure/msal-common';
import {
  OrganisationClient,
  OrganisationViewModel,
  RoleClient,
  RoleViewModel,
  UserAdministrationClient,
  UserAdministrationIncidentRequest,
  UserViewModel,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { ContactSearchFormlyFieldService } from '@core/services/contact-search-formly-field-service/contact-search-formly-field.service';
import { EmployeeSearchFormlyFieldService } from '@core/services/employee-search-formly-field-service/employee-search-formly-field.service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalFilterComponent } from '@shared/components/modal-filter/modal-filter.component';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import {
  ITableConfig,
  ITableFilterConfig,
} from '@shared/components/table-base/table-base.types';
import { RegexpEmail } from '@shared/utils/regex';
import { BehaviorSubject, map, of, shareReplay } from 'rxjs';

export interface IDialogData {
  columns: MtxGridColumn[];
  filterFields: FormlyFieldConfig[];
  filterValues: ITableFilterConfig;
  actions: {
    onSubmit: (formModel) => void;
    onReset: () => void;
  };
}

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent
  extends TableBaseComponent
  implements OnInit
{
  headline = this.translateService.instant(
    'menu.admin.component.user_administration'
  );

  loadingData = false;

  loading = false;

  override config = this.configure(<ITableConfig>{
    title: 'administration.users.title',
    translatePath: 'administration',
  });

  tabs: Tab[] = [
    {
      label: this.translateService.instant('administration.users.title'),
      id: 'user',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: this.translateService.instant('administration.create_user.title'),
      id: 'create-user',
      onClick: (id: string) => this.onTabClick(id),
    },
  ];

  activeTab = this.tabs[0].id;

  columnsUsers: MtxGridColumn[] = [];

  roles$ = this.roleClient.get().pipe(shareReplay(1));

  private userDataSubject = new BehaviorSubject<UserViewModel[]>([]);
  userData = this.userDataSubject.asObservable();

  @ViewChild('chipsTemplate', { static: true })
  chipsTemplateRef: TemplateRef<void>;

  chips: Chip[] = [];

  controlButtons: ButtonProps[] = [
    {
      label: this.translateService.instant('crud.refresh'),
      onClick: () => {
        this.refreshData();
      },
    },
  ];

  menuItems: MenuItem[] = [];

  dialogData: IDialogData;

  userDataInitial = [];

  modalComponent = ModalFilterComponent;

  cardUser: CardConfig = {
    header: {
      title: {
        label: this.translate('create_user.headline'),
      },
    },
    footer: {
      button1: {
        label: this.translate('create_user.label__submit'),
        onClick: () => this.validateForm(),
        disabled: false,
      },
      button2: {
        label: this.translate('create_user.label__reset'),
        onClick: () => this.resetCreateUser(),
        disabled: false,
      },
    },
  };

  form = new FormGroup({});

  formModel = {};

  formFields: Array<FormlyFieldConfig>;

  filledForm: Array<FormlyFieldConfig> | null;

  currentUserTokenClaims: TokenClaims & {
    [key: string]: unknown;
  };

  isUserSelected = false;

  private organisationsSubject = new BehaviorSubject<OrganisationViewModel[]>(
    []
  );
  organisations$ = this.organisationsSubject.asObservable();

  isTelekomChecked = false;

  isTelekomUser = false;

  constructor(
    injector: Injector,
    private breadcrumbService: BreadcrumbService,
    private roleClient: RoleClient,
    private employeeFormlyFieldService: EmployeeSearchFormlyFieldService,
    private contactFormlyFieldService: ContactSearchFormlyFieldService,
    private userAdministrationClient: UserAdministrationClient,
    private organisationClient: OrganisationClient
  ) {
    super(injector);
    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);

    this.userData.subscribe({
      next: res => {
        this.userDataInitial = res;
        this.filterUsers(this.config.grid.filter.filterOptions);
      },
    });
  }

  ngOnInit() {
    this.loadingData = true;

    this.loadUserData();

    this.organisationClient.get().subscribe({
      next: (organisations: OrganisationViewModel[]) => {
        this.organisationsSubject.next(organisations);
      },
    });

    this.currentUserTokenClaims = this.userService.getCurrentUserTokenClaims();

    this.isTelekomUser =
      this.currentUserTokenClaims.ciamId &&
      this.currentUserTokenClaims.ciamId !== '';

    if (this.isTelekomUser) {
      this.isTelekomChecked = true;
    }

    this.columnsUsers = [
      {
        header: this.translateService.instant('administration.users.firstname'),
        field: 'firstName',
        sortable: true,
        width: '15%',
      },
      {
        header: this.translateService.instant('administration.users.lastname'),
        field: 'lastName',
        sortable: true,
        width: '15%',
      },
      {
        header: this.translateService.instant('administration.users.email'),
        field: 'email',
        sortable: true,
        width: '20%',
      },
      {
        header: this.translateService.instant('administration.users.role'),
        field: 'roles',
        cellTemplate: this.chipsTemplateRef,
      },
    ];

    this.filterFields = [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'firstName',
            type: 'input',
            props: {
              label: this.translate('users.firstname'),
            },
          },
          {
            key: 'lastName',
            type: 'input',
            props: {
              label: this.translate('users.lastname'),
            },
          },
          {
            key: 'email',
            type: 'input',
            props: {
              label: this.translate('users.email'),
            },
          },
          {
            key: 'roles',
            type: 'autocomplete-chips-for-roles',
            props: {
              label: this.translate('users.role'),
              options: this.roles$,
            },
          },
        ],
      },
    ];

    this.dialogData = {
      columns: this.columns,
      filterFields: this.filterFields,
      filterValues: this.config.grid.filter.filterOptions,
      actions: {
        onSubmit: formModel => {
          this.loadingData = true;
          this.mapFormModelToChips(formModel);
          this.updateFilter(formModel);
          this.filterUsers(this.config.grid.filter.filterOptions);
          this.dialogData.filterValues = this.config.grid.filter.filterOptions;
        },
        onReset: () => {
          this.loadingData = true;
          this.mapFormModelToChips({});
          this.updateFilter({});
          this.filterUsers(this.config.grid.filter.filterOptions);
        },
      },
    };

    if (this.isTelekomChecked) {
      this.formFields = this.getFormFieldsForTelekom();
    } else {
      this.formFields = this.getFormFieldsForExternal();
    }
  }

  refreshData() {
    this.loadingData = true;
    this.loadUserData();
  }

  filterUsers(searchCriteria: Partial<UserViewModel>) {
    this.userData = of(this.userDataInitial);
    const filteringSearchCriteria = searchCriteria.roles?.map(item => item.id);
    this.userData = this.userData.pipe(
      map(users =>
        users.filter(user => {
          // Filter by input fields (firstName, lastName, email)
          const inputFiltersPassed = Object.keys(searchCriteria).every(key => {
            const value = searchCriteria[key as keyof UserViewModel];
            if (key === 'roles') return true; // Skip role here, we'll handle it separately
            if (Array.isArray(user[key as keyof UserViewModel])) {
              this.loadingData = false;
              return (user[key as keyof UserViewModel] as string[]).some(item =>
                item.includes(value as string)
              );
            }
            this.loadingData = false;
            return user[key as keyof UserViewModel]
              ?.toString()
              .toLowerCase()
              .includes(value?.toString().toLowerCase() || '');
          });
          // Filter by role criteria
          const roleFilterPassed =
            !filteringSearchCriteria ||
            filteringSearchCriteria?.length === 0 ||
            (user.roles &&
              filteringSearchCriteria.every(criteria =>
                user.roles.find(role => role.id === criteria)
              ));
          this.loadingData = false;
          return inputFiltersPassed && roleFilterPassed;
        })
      )
    );
  }

  override getTableData$ = () => {
    return this.userData;
  };

  onTabClick(value: string) {
    this.activeTab = value;
  }

  handleOnRemoveChip = eventObj => {
    // Find the index of the item to remove in this.config.grid.filter.filterOptions.roles
    const indexToRemove =
      this.config.grid.filter.filterOptions.roles?.findIndex(
        item => item.id === eventObj.id
      );
    // Remove the item from the roles array if found
    if (indexToRemove !== -1) {
      this.config.grid.filter.filterOptions.roles?.splice(indexToRemove, 1);
    }
    this.chips = this.chips.filter(chipItem => chipItem.id !== eventObj.id);

    delete this.config.grid.filter.filterOptions[eventObj.id];

    this.filterUsers(this.config.grid.filter.filterOptions);
  };

  mapFormModelToChips = formModel => {
    this.chips = [];
    this.chips.length = 0;
    Object.keys(formModel).forEach(formModelItemKey => {
      const field = this.filterFields[0].fieldGroup.find(fieldItem => {
        return fieldItem.key === formModelItemKey;
      });
      if (formModel[formModelItemKey]) {
        if (formModelItemKey === 'roles') {
          formModel[formModelItemKey].forEach(item => {
            this.chips.push({
              id: `${item.id}`,
              label: `${field.props.label}: ${item.description}`,
            });
          });
        } else {
          this.chips.push({
            id: formModelItemKey,
            label: `${field.props.label}: ${formModel[formModelItemKey]}`,
          });
        }
      }
    });
  };

  onSelectUser(_id: string, _initialSearch, user) {
    this.formFields[0].form
      ?.get('userData.firstName')
      ?.setValue(user.firstName);
    this.formFields[0].form?.get('userData.lastName')?.setValue(user.lastName);
    this.formFields[0].form?.get('userData.email')?.setValue(user.email);
    this.formFields[0].form
      ?.get('userData.organisation')
      ?.setValue(user.company);

    this.formFields[0].form?.get('userData.firstName').disable();
    this.formFields[0].form?.get('userData.lastName').disable();
    this.formFields[0].form?.get('userData.email').disable();

    this.isUserSelected = true;
  }

  onDelete() {
    this.formFields[0].form?.get('userData.firstName')?.setValue(null);
    this.formFields[0].form?.get('userData.lastName')?.setValue(null);
    this.formFields[0].form?.get('userData.email')?.setValue(null);
    this.formFields[0].form?.get('userData.organisation')?.setValue(null);

    if (!this.isTelekomChecked) {
      this.formFields[0].form?.get('userData.firstName').enable();
      this.formFields[0].form?.get('userData.lastName').enable();
      this.formFields[0].form?.get('userData.email').enable();
      this.formFields[0].form?.get('userData.roles').enable();
    }

    this.isUserSelected = false;
  }

  resetCreateUser() {
    this.formModel = {};
    this.form.markAsUntouched();
    this.formFields[0].form?.get('userData.roles').markAsUntouched();
  }

  validateForm() {
    if (this.form.valid) {
      this.createUser();
    } else {
      this.form.markAllAsTouched();
      this.formFields[0].form?.get('userData.roles').markAsTouched();
    }
  }

  createUser() {
    this.loading = true;
    let request: UserAdministrationIncidentRequest;

    if (this.isUserSelected) {
      request = {
        firstName:
          this.form.value['contact']?.searchResult?.firstName ??
          this.form.value['employee']?.searchResult?.firstName ??
          null,
        lastName:
          this.form.value['contact']?.searchResult?.lastName ??
          this.form.value['employee']?.searchResult?.lastName ??
          null,
        email:
          this.form.value['contact']?.searchResult?.email ??
          this.form.value['employee']?.searchResult?.email ??
          null,
        companyId:
          this.form.value['contact']?.searchResult?.company?.id ?? null,
        b2cUserId: this.form.value['employee']?.searchResult?.id ?? null,
        contactId: this.form.value['contact']?.searchResult?.contactId ?? null,
        roles:
          this.form.value['userData']?.roles?.map(
            (role: RoleViewModel) => role.id
          ) ?? null,
      };
    } else {
      request = {
        firstName: this.form.value['userData']?.firstName ?? null,
        lastName: this.form.value['userData']?.lastName ?? null,
        email: this.form.value['userData']?.email ?? null,
        companyId: this.form.value['userData']?.organisation ?? null,
        b2cUserId: null,
        contactId: null,
        roles:
          this.form.value['userData']?.roles?.map(
            (role: RoleViewModel) => role.id
          ) ?? null,
      };
    }

    this.userAdministrationClient.createUser(request).subscribe({
      next: () => {
        this.toastr.success(
          this.translateService.instant(
            'administration.create_user.success_message'
          )
        );
        this.loading = false;
        this.form.reset();
        this.isUserSelected = false;
        this.isTelekomChecked = false;
        this.formFields = this.getFormFieldsForExternal();
      },
      error: err => {
        let message = this.translateService.instant('generic.errorMessage');
        if (err.status === 409) {
          message = this.translateService.instant(
            'administration.create_user.error_message_user_exists'
          );
        }
        this.toastr.error(
          message,
          this.translateService.instant('alert.error')
        );
        this.loading = false;
      },
    });
  }

  getFormFieldsForExternal() {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'isTelekomChecked',
            type: 'select',
            defaultValue: this.isTelekomChecked,
            props: {
              options: [
                {
                  id: 1,
                  label: this.translateService.instant(
                    'administration.create_user.telekom_employee'
                  ),
                  value: true,
                },
                {
                  id: 2,
                  label: this.translateService.instant(
                    'administration.create_user.external_user'
                  ),
                  value: false,
                },
              ],
            },
            hooks: {
              onInit: field => {
                field.formControl.valueChanges.subscribe(value => {
                  this.isTelekomChecked = value;

                  field.formControl.disable({ emitEvent: false });
                  this.toggleTelekomCheck();
                  field.formControl.enable({ emitEvent: false });
                });
              },
            },
          },
          {
            wrappers: [],
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translate(
                    'create_user.label__searchContact'
                  ),
                },
              },
              {
                key: 'contact',
                fieldGroup: [
                  ...this.contactFormlyFieldService.autocompleteSearch(
                    this.translate('create_user.placeholder__search'),
                    this.onSelectUser.bind(this),
                    false,
                    true,
                    null,
                    true,
                    this.onDelete.bind(this)
                  ),
                ],
              },
            ],
          },
          {
            type: 'flex-layout',
            key: 'userData',
            fieldGroup: [
              {
                key: 'firstName',
                type: 'input',
                props: {
                  label: this.translate('create_user.label__firstName'),
                  required: true,
                },
              },
              {
                key: 'lastName',
                type: 'input',
                props: {
                  label: this.translate('create_user.label__lastName'),
                  required: true,
                },
              },
              {
                key: 'email',
                type: 'input',
                props: {
                  label: this.translate('create_user.label__email'),
                  required: true,
                  pattern: RegexpEmail,
                },
                validation: {
                  messages: {
                    pattern: (_err, _field: FormlyFieldConfig) =>
                      this.translateService.instant('formErrors.email.pattern'),
                  },
                },
              },
              {
                key: 'organisation',
                type: 'select',
                props: {
                  label: this.translate('create_user.label__organisation'),
                  required: true,
                  options: this.organisations$,
                  valueProp: 'id',
                  labelProp: 'name',
                },
              },
              {
                key: 'roles',
                type: 'autocomplete-chips-for-roles',
                props: {
                  label: this.translate('create_user.label__roles'),
                  required: true,
                  options: this.roles$.pipe(
                    map(roles =>
                      roles
                        .filter(role => role.name.startsWith('DL_'))
                        .sort((a, b) =>
                          a.description.localeCompare(b.description)
                        )
                    )
                  ),
                },
                validation: {
                  messages: {
                    required: this.translateService.instant(
                      'formErrors.required',
                      {
                        label: this.translate('create_user.label__roles'),
                      }
                    ),
                  },
                },
              },
            ],
          },
        ],
      },
    ];
  }

  getFormFieldsForTelekom() {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'isTelekomChecked',
            type: 'select',
            defaultValue: this.isTelekomChecked,
            props: {
              options: [
                {
                  id: 1,
                  label: this.translateService.instant(
                    'administration.create_user.telekom_employee'
                  ),
                  value: true,
                },
                {
                  id: 2,
                  label: this.translateService.instant(
                    'administration.create_user.external_user'
                  ),
                  value: false,
                },
              ],
            },
            hooks: {
              onInit: field => {
                field.formControl.valueChanges.subscribe(value => {
                  this.isTelekomChecked = value;

                  field.formControl.disable({ emitEvent: false });
                  this.toggleTelekomCheck();
                  field.formControl.enable({ emitEvent: false });
                });
              },
            },
          },
          {
            wrappers: [],
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translate(
                    'create_user.label__searchEmployee'
                  ),
                },
              },
              {
                key: 'employee',
                fieldGroup: [
                  ...this.employeeFormlyFieldService.autocompleteSearch(
                    this.translate('create_user.placeholder__search'),
                    this.onSelectUser.bind(this),
                    false,
                    true,
                    null,
                    true,
                    this.onDelete.bind(this)
                  ),
                ],
              },
            ],
          },
          {
            type: 'flex-layout',
            key: 'userData',
            fieldGroup: [
              {
                key: 'firstName',
                type: 'input',
                props: {
                  label: this.translate('create_user.label__firstName'),
                  required: true,
                  disabled: true,
                },
              },
              {
                key: 'lastName',
                type: 'input',
                props: {
                  label: this.translate('create_user.label__lastName'),
                  required: true,
                  disabled: true,
                },
              },
              {
                key: 'email',
                type: 'input',
                props: {
                  label: this.translate('create_user.label__email'),
                  required: true,
                  disabled: true,
                },
              },
              {
                key: 'roles',
                type: 'autocomplete-chips-for-roles',
                props: {
                  label: this.translate('create_user.label__roles'),
                  required: true,
                  options: this.roles$.pipe(
                    map(roles =>
                      roles
                        .filter(
                          role =>
                            role.name.startsWith('REM_') ||
                            role.name.startsWith('DTAG_')
                        )
                        .sort((a, b) =>
                          a.description.localeCompare(b.description)
                        )
                    )
                  ),
                },
                validation: {
                  messages: {
                    required: this.translateService.instant(
                      'formErrors.required',
                      {
                        label: this.translate('create_user.label__roles'),
                      }
                    ),
                  },
                },
              },
            ],
          },
        ],
      },
    ];
  }

  toggleTelekomCheck() {
    this.isUserSelected = false;
    if (this.isTelekomChecked) {
      this.formFields = this.getFormFieldsForTelekom();
    } else {
      this.formFields = this.getFormFieldsForExternal();
    }

    this.form.patchValue(
      {
        isTelekomChecked: this.isTelekomChecked,
      },
      { emitEvent: false }
    );
  }

  navigateToDetails(userData: UserViewModel) {
    userData.roles = userData.roles.filter(role => role.description);
    this.roles$.subscribe({
      next: roles => {
        this.router.navigateByUrl('user-administration/details', {
          state: {
            userId: userData.id,
            roles: userData.isTelekomEmployee
              ? roles
                  .filter(
                    role =>
                      role.name.startsWith('REM_') ||
                      role.name.startsWith('DTAG_')
                  )
                  .sort((a, b) => a.description.localeCompare(b.description))
              : roles
                  .filter(role => role.name.startsWith('DL_'))
                  .sort((a, b) => a.description.localeCompare(b.description)),
            organisations: this.organisationsSubject.getValue(),
          },
        });
      },
    });
  }

  loadUserData() {
    this.userClient.getAll().subscribe({
      next: res => {
        this.userDataSubject.next(res);
        this.loadingData = false;
      },
      error: () => {
        this.toastr.error(
          this.translateService.instant('administration.users.error_load_data'),
          this.translateService.instant('alert.error')
        );
        this.loadingData = false;
      },
    });
  }
}
