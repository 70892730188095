import { Component, Input, OnInit } from '@angular/core';
import {
  IncidentViewModel,
  ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping,
} from '@core/api';
import { IDetailFields } from '@shared/components/details/detail-fields/models/detail-fields.model';
import { DetailsBaseComponent } from '@shared/components/details/details-base';
import { IDetailsConfig } from '@shared/components/details/details-base.types';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';
import { of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent extends DetailsBaseComponent {
  @Input() incident: IncidentViewModel;

  @Input()
  task: ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping;

  private entity: any;

  public title = '';

  public ticketNumber = '';

  public showFileList = true;

  override config = this.configure(<IDetailsConfig>{
    title: '',
    showBackButton: false,
    showFileUpload: true,
  });

  override detailFields: IDetailFields[] = [
    {
      type: 'field',
      key: 'name',
      label: this.translate('label__ticketTitle'),
    },
    {
      type: 'field',
      key: 'ticketNumber',
      label: this.translate('label__ticketNumber'),
      css: 'font-family: monospace; font-size:15px',
    },
    {
      type: 'field',
      key: 'subject',
      label: this.translate('label__task'),
      hiddenIfEmtpy: true,
    },
    {
      type: 'buffered-files',
      key: 'documentMetadata',
    },
    {
      type: 'typed-files',
      key: 'fmDocument',
    },
  ];

  override ngOnInit(): void {
    const firstPart = window.location.pathname.split('/')[1];
    if(firstPart === 'docupload'){
      this.showFileList = false;
    }
  }

  override translate(key: string): string {
    return this.translateService.instant(`documents.${key}`);
  }

  override getItem = () => {
    this.ifTaskMapToIncident();
    this.ifIncidentMapToIncident();
    return of(this.entity);
  };

  private ifIncidentMapToIncident() {
    if (this.incident) {
      this.entity = this.incident;
      this.title = this.entity.name;
      this.ticketNumber = this.entity.ticketNumber;
    }
  }

  private ifTaskMapToIncident() {
    if (this.task?.entity?.incident) {
      this.entity = this.task.entity.incident;
      this.entity.taskId = this.task.entity.taskId;
      this.entity.fmDocument = this.task.entity.fmDocument;
      this.entity.documentMetadata = this.task.entity.documentMetadata;
      this.entity.configurationTask = this.task.entity.configurationTask;
      this.entity.subject = this.task.entity.subject;
    }
  }

  tabs: Tab[] = [
    {
      label: 'Dokumentenübersicht',
      id: 'documents_overview',
      onClick: (id: string) => this.onTabClick(id),
    },
    // {
    //   label: 'Dokument hochladen',
    //   id: 'document_upload',
    //   onClick: (id: string) => this.onTabClick(id),
    //   disabled: true
    // }
  ];

  activeTab = this.tabs[0].id;

  onTabClick(value: string) {
    this.activeTab = value;
  }
}
