import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TicketsModule } from '@features/tickets/tickets.module';
import { TelekomSharedModule } from '@mandants/telekom/telekom-shared.module';
import { FORMLY_CONFIG } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { registerTranslateExtension } from '@shared/utils/translate.extension';
import { defineCustomElements } from '@telekom/scale-components/loader';
import { AnimatedCounterComponent } from './components/animated-counter/animated-counter.component';
import { ButtonWrapperComponent } from './components/button-wrapper/button-wrapper.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { PodcastComponent } from './pages/podcast/podcast.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { StatusItemComponent } from './pages/status/status-item.component';
import { StatusComponent } from './pages/status/status.component';
import { WhoComponent } from './pages/who/who.component';
import { RentalComponent } from './pages/rental/rental.component';
import { SellComponent } from './pages/sell/sell.component';
import { RemComponent } from './rem.component';
import { BodyComponent } from './widgets/body/body.component';
import { ColumnContainerComponent } from './widgets/column-container/column-container.component';
import { ContactCardComponent } from './widgets/contact-card/contact-card.component';
import { FactItemComponent } from './widgets/fact/fact-item/fact-item.component';
import { FactWrapperComponent } from './widgets/fact/fact-wrapper/fact-wrapper.component';
import { FaqItemComponent } from './widgets/faq/faq-item/faq-item.component';
import { FaqWrapperComponent } from './widgets/faq/faq-wrapper/faq-wrapper.component';
import { FooterComponent } from './widgets/footer/footer.component';
import { HeaderComponent } from './widgets/header/header.component';
import { OurChallengesComponent } from './widgets/our-challenges/our-challenges.component';
import { OurTasksComponent } from './widgets/our-tasks/our-tasks.component';
import { QuickTextComponent } from './widgets/quick-text/quick-text.component';
import { ThumbnailComponent } from './widgets/thumbnail/thumbnail.component';
import { HeaderContentAreaComponent } from './widgets/header-content-area/header-content-area.component';
import { RentalSearchComponent } from './widgets/rental-search/rental-search.component';
import { FactWrapperHomePageComponent } from './widgets/fact/fact-wrapper-home-page/fact-wrapper-home-page.component';
import { RentalItemComponent } from './widgets/rental-search/rental-item/rental-item.component';
import { RentalItemDetailsComponent } from './widgets/rental-search/rental-item-details/rental-item-details.component';

defineCustomElements();

@NgModule({
  declarations: [
    RemComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PortfolioComponent,
    ContactComponent,
    BodyComponent,
    ThumbnailComponent,
    FaqWrapperComponent,
    FaqItemComponent,
    FactWrapperComponent,
    FactWrapperHomePageComponent,
    FactItemComponent,
    AnimatedCounterComponent,
    ButtonWrapperComponent,
    QuickTextComponent,
    ColumnContainerComponent,
    WhoComponent,
    RentalComponent,
    SellComponent,
    ContactCardComponent,
    ImpressumComponent,
    PodcastComponent,
    OurChallengesComponent,
    OurTasksComponent,
    StatusComponent,
    StatusItemComponent,
    MaintenanceComponent,
    HeaderContentAreaComponent,
    RentalSearchComponent,
    RentalItemComponent,
    RentalItemDetailsComponent,
  ],
  imports: [TelekomSharedModule, SharedModule, TranslateModule, TicketsModule],
  exports: [
    RemComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PortfolioComponent,
    ContactComponent,
    BodyComponent,
    ThumbnailComponent,
    FaqWrapperComponent,
    FaqItemComponent,
    FactWrapperComponent,
    FactWrapperHomePageComponent,
    FactItemComponent,
    AnimatedCounterComponent,
    ButtonWrapperComponent,
    QuickTextComponent,
    WhoComponent,
    RentalComponent,
    SellComponent,
    ContactCardComponent,
    ImpressumComponent,
    PodcastComponent,
    OurChallengesComponent,
    OurTasksComponent,
    HeaderContentAreaComponent,
    RentalSearchComponent,
    RentalItemComponent,
    RentalItemDetailsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [TranslateService],
    },
  ],
})
export class TelekomRemModule {}
