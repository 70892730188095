import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormlyFieldBaseService {
  translatePath = 'form';

  fileNotSelected$ = new BehaviorSubject<boolean>(false);
  public fileNotSelectedChange$ = this.fileNotSelected$.asObservable();

  constructor(protected translateService: TranslateService) {}

  createInput(
    key: string,
    labelKey: string,
    required = true,
    visibleInConfirm = true,
    maxLength = 200
  ): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            type: 'input',
            key,
            wrappers: ['readOnly', 'form-field'],
            props: {
              label: this.translate(labelKey),
              required,
              visibleInConfirm,
              maxLength,
            },
          },
        ],
      },
    ];
  }

  createTextarea(
    key: string,
    labelKey: string,
    required = true,
    visibleInConfirm = true,
    maxLength = 500,
    rows = 5,
    defaultValue: any = undefined
  ): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key,
            type: 'textarea',
            defaultValue,
            props: {
              label: this.translate(labelKey),
              required,
              visibleInConfirm,
              maxLength,
              rows,
            },
          },
        ],
      },
    ];
  }

  createHidden(key: string, value: any): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key,
            defaultValue: value,
          },
        ],
      },
    ];
  }

  createDateTimePicker(
    key: string,
    labelKey: string,
    required = true,
    visibleInConfirm = true,
    dateFilter = (_d: moment.Moment): boolean => true
  ): FormlyFieldConfig[] {
    return [
      {
        type: 'matdatetimepicker',
        key,
        props: {
          label: this.translate(labelKey),
          required,
          visibleInConfirm,
          startAt: moment().startOf('day'),
          startView: 'month',
          dateTimeFormat: this.translate('locale_moment__date_format'),
          dateTimeFormatConfirm: this.translate('locale_moment__date_format'),
          timezone: 'Europe/Berlin',
          dateFilter,
        },
        expressions: {
          'props.minDate': () => {
            return moment(new Date()).startOf('day');
          },
        },
      },
    ];
  }

  createFileUpload(accept = '*', maxFileSizeInMB = '10'): FormlyFieldConfig[] {
    return [
      {
        key: 'attachments',
        type: 'repeat',
        props: {
          addText: this.translateService.instant(
            'ticket.create.button__attach_new_file'
          ),
          tooltip: this.translateService.instant(
            'ticket.create.p__info_attachment'
          ),
        },

        fieldArray: {
          type: 'flex-layout',

          fieldGroup: [
            {
              type: 'file',
              key: 'attachment',
              props: {
                label: this.translateService.instant(
                  'ticket.create.label__attachment'
                ),
                visibleInConfirm: true,
                accept,
                maxFileSizeInMB,
              },
            },
          ],
        },
      },
    ];
  }

  private translate(key: string): string {
    return this.translateService.instant(`${this.translatePath}.${key}`);
  }

  public setNotSelectedFileError() {
    this.fileNotSelected$.next(true);
  }

  public unsetNotSelectedFileError() {
    this.fileNotSelected$.next(false);
  }
}
