<su-headline h="h1" skin="primary">{{
  'edit_room.labels.title' | translate
}}</su-headline>

<su-headline *ngIf="spaceId" h="h2" skin="tertiary" class="selectionHeadline">
  {{ formattedSpaceId }}
</su-headline>

<div [style]="descriptionContainerStyle" *ngIf="frozenStatus">
  <div
    class="sub-headline contactDescription"
    [style]="descriptionStyle"
    [innerHtml]="description | safeHtml"></div>
</div>

<su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>

<su-slot-tab-content *ngIf="activeTab === tabs[0].id">
  <su-card [card]="cardFields">
    <div card-content>
      <formly-form [form]="form" [fields]="formFields" [model]="formModel">
      </formly-form>
    </div>
  </su-card>
</su-slot-tab-content>

<su-slot-tab-content *ngIf="activeTab === tabs[1].id">
  <div card-content>
    <app-maintenance-room
      [typeOfUseDetailedId]="typeOfUseDetailedId"
      [typeOfUseDetailAreaData$]="typeOfUseDetailAreaData$"
      [changeReasons]="changeReasons"
      [spaceId]="spaceId"></app-maintenance-room>
  </div>
</su-slot-tab-content>

<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
