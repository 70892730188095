<div *ngIf="data.type === 'okDismissButton' || 'okButton' || 'noButtons'">
  <div mat-dialog-title *ngIf="data.title">
    <su-headline h="h3" skin="primary">{{ data.title }}</su-headline>
  </div>
  <div *ngIf="data.message" mat-dialog-content class="dialog-content">
    <p [innerHtml]="data.message"></p>
  </div>
  <div mat-dialog-actions class="dialog-button-container">
    <su-button
      skin="secondary"
      (onClick)="onDismiss()"
      *ngIf="data.type === 'okDismissButton'"
      [label]="data.dismissButtonText! || 'Cancel'"></su-button>

    <su-button
      skin="primary"
      (onClick)="onOk()"
      *ngIf="data.type === 'okDismissButton' || 'okButton'"
      [label]="data.okButtonText! || 'Ok'"></su-button>
  </div>
</div>
