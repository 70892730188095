import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { MsalService } from '@azure/msal-angular';
import {
  ContentTypeViewModel,
  DocumentTypeGroupViewModel,
  DocumentTypeViewModel,
  FmDocument,
  FmIncident,
  FmTask,
  SmartPortalProcessError,
  TargetSystem,
  UploadFileTempStorage,
  UploadFilesTempStorageRequest,
  UploadFilesWithEmailRequest,
} from '@core/api';
import { FileService } from '@core/services/file-service/file.service';
import { MandantConfigService } from '@core/services/mandant-config-service/mandant-config.service';
import { TranslateService } from '@ngx-translate/core';
import { DefaultValues } from '@shared/constants/default-values.constants';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { ToastrService } from 'ngx-toastr';

import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';

interface DocumentTypeFile extends File {
  documentTypeId?: string | null;
}

@Component({
  selector: 'app-detail-file-upload',
  templateUrl: './detail-file-upload.component.html',
  styleUrls: ['./detail-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailFileUploadComponent implements OnInit {
  @Input() hasTaskSelection = false;

  @Input() hasTargetSystemSelection = false;

  @Input() hasDocumentTypeSelection = false;

  @Input() hasDocumentVisibilitySelection = false;

  @Input() metadata: [{ attributeName: string; attributeValue: string }];

  @Input() incident: FmIncident;

  @Input() tasks = Array<FmTask>();

  @Input() statusData: { eMail: string; ticketNumber } = {
    eMail: '',
    ticketNumber: '',
  };

  @Output() uploadEvent = new EventEmitter<any>();

  // task selection
  defaultTaskValue = '';

  defaultTaskLabel = '';

  defaultTaskControl = new FormControl('');

  filteredTasks: Observable<string[]>;

  // documentType selection
  documentTypeGroups: DocumentTypeGroupViewModel[];

  documentTypes: DocumentTypeViewModel[];

  defaultDocumentTypeValue = '';

  defaultDocumentTypeLabel = '';

  defaultDocumentTypeControl = new FormControl('');

  filteredDocumentTypes: Observable<DocumentTypeGroupViewModel[]>;

  // targetSystem selection
  targetSystems = [
    {
      value: TargetSystem.Remdoc,
      label: this.translateService.instant('documents.targetSystems.0'),
    },
  ];
  defaultTargetSystemValue = this.targetSystems[0].value;

  defaultTargetSystemLabel = this.targetSystems[0].label;

  defaultTargetSystemControl = new FormControl(this.defaultTargetSystemLabel);

  filteredTargetSystems: Observable<string[]>;

  // documentVisibility selection
  documentVisibilities = this.fileService.documentVisibilities;

  documentVisibilitiesSelectable =
    this.fileService.documentVisibilitiesSelectable;

  defaultDocumentVisibilityValue =
    this.fileService.defaultDocumentVisibilityValue;

  defaultDocumentVisibilityLabel =
    this.fileService.defaultDocumentVisibilityLabel;

  defaultDocumentVisibilityControl =
    this.fileService.defaultDocumentVisibilityControl;

  filteredDocumentVisibilities: Observable<string[]>;

  busy = false;

  @ViewChild('fileUpload') fileUpload: ElementRef;

  form = this.fb.group({
    typedFiles: this.fb.array([]),
  });

  get typedFiles(): FormArray {
    return this.form.controls.typedFiles as FormArray;
  }

  filteredDocumentTypeOptions: Observable<any[]>[] = [];

  constructor(
    private fileService: FileService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private msalService: MsalService,
    private mandantConfigService: MandantConfigService
  ) {}

  ngOnInit() {
    this.initTargetSystemOptions();
    this.initFilteredDocumentTypes();

    if (this.hasDocumentTypeSelection) {
      this.fileService.getDocumentTypes(this.incident.incidentId).subscribe({
        next: (documentTypeGroups: any) => {
          this.documentTypeGroups = documentTypeGroups;

          this.defaultDocumentTypeLabel = null;

          this.defaultDocumentTypeValue =
            this.metadata.find(
              metadata => metadata.attributeName === 'defaultDocumentTypeId'
            )?.attributeValue ?? '';

          for (const documentTypeGroup of this.documentTypeGroups) {
            const foundDocumentType = documentTypeGroup.documentTypes.find(
              documentType =>
                documentType.documentTypeId === this.defaultDocumentTypeValue
            );
            if (foundDocumentType) {
              this.defaultDocumentTypeLabel = foundDocumentType.name;
              break;
            }
          }

          this.defaultDocumentTypeControl.setValue(
            this.defaultDocumentTypeLabel
          );

          this.cd.detectChanges();
        },
      });
    }

    this.initFilteredTasks();
    this.initFilteredTargetSystems();
    this.initDefaultVisibility();
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  checkForFileSizeError(typedFileForm: any): any {
    const documentTypeId = typedFileForm.get('documentTypeId')?.value;
    const maxMB = this.getSelectedDocumentTypeMaxMB(documentTypeId);
    const [isValid, message] = this.fileService.validateFile(
      typedFileForm.value.file,
      maxMB
    );
    if (!isValid) {
      return message;
    }

    return false;
  }

  getContentTypeList(documentTypeId: string) {
    if (documentTypeId) {
      const contentTypes =
        this.getSelectedDocumentTypeContentTypes(documentTypeId);
      if (contentTypes?.length) {
        return this.getContentTypesString(contentTypes);
      }
    }
    return false;
  }

  onTaskSelected(event: any) {
    const selectedTask = this.tasks.find(
      (task: any) => task.subject === event.option?.value
    );
    if (selectedTask) {
      this.defaultTaskValue = selectedTask.taskId ?? '';
      this.defaultTaskLabel = selectedTask.subject ?? '';
    } else if (selectedTask === undefined) {
      this.defaultTaskValue = '';
      this.defaultTaskLabel = '';
    }
  }

  onTargetSystemSelected(event: any) {
    const selectedTargetSystem = this.targetSystems.find(
      (targetSystem: any) => targetSystem.label === event.option?.value
    );
    if (selectedTargetSystem) {
      this.defaultTargetSystemValue = selectedTargetSystem.value;
      this.defaultTargetSystemLabel = selectedTargetSystem.label;
    }
  }

  onDocumentTypeSelected(event: any) {
    this.documentTypes = this.getListOfDocumentTypes();

    const selectedDocumentType = this.documentTypes.find(
      (documentType: DocumentTypeViewModel) =>
        documentType.name === event.option?.value
    );
    if (selectedDocumentType) {
      this.defaultDocumentTypeValue = selectedDocumentType.externalCode ?? '';
      this.defaultDocumentTypeLabel = selectedDocumentType.name ?? '';
    } else if (selectedDocumentType === undefined) {
      this.defaultDocumentTypeValue = '';
      this.defaultDocumentTypeLabel = '';
    }
  }

  onDocumentVisibilitySelected(event: any) {
    const selectedDocumentVisibility = this.documentVisibilities.find(
      (documentVisibility: any) =>
        documentVisibility.label === event.option?.value
    );
    if (selectedDocumentVisibility) {
      this.defaultDocumentVisibilityValue = selectedDocumentVisibility.value;
      this.defaultDocumentVisibilityLabel = selectedDocumentVisibility.label;
    } else if (selectedDocumentVisibility === undefined) {
      this.defaultDocumentVisibilityValue = undefined;
      this.defaultDocumentVisibilityLabel = undefined;
    }
  }

  onDocumentTypeOptionSelected(event: any, index: number) {
    this.documentTypes = this.getListOfDocumentTypes();

    const selectedDocumentType = this.documentTypes.find(
      (documentType: DocumentTypeViewModel) =>
        documentType.name === event.option?.value
    );
    const arrayControl = this.form.get('typedFiles') as FormArray;
    const arrayControlObject = arrayControl.at(index);
    if (arrayControlObject) {
      const arrayControlObjectField = arrayControlObject.get('documentTypeId');
      arrayControlObjectField?.setValue(selectedDocumentType?.externalCode);
    }
  }

  defaultTaskOnBlur(): void {
    setTimeout(() => {
      this.checkControlLabel(
        this.tasks,
        'subject',
        this.defaultTaskControl,
        () => {
          if (
            this.defaultTaskControl.getRawValue().replaceAll(' ', '') === ''
          ) {
            this.defaultTaskValue = '';
            this.defaultTaskLabel = '';
            this.defaultTaskControl.setValue('');
            this.filteredTasks = null;
          } else {
            this.defaultTaskControl.setValue(this.defaultTaskLabel);
          }
          this.cd.detectChanges();
        }
      );
      this.initFilteredTasks();
    }, 1000);
  }

  defaultTargetSystemOnBlur(): void {
    setTimeout(() => {
      this.checkControlLabel(
        this.targetSystems,
        'label',
        this.defaultTargetSystemControl,
        () => {
          this.defaultTargetSystemControl.setValue(
            this.defaultTargetSystemLabel
          );
          this.cd.detectChanges();
        }
      );
      this.initFilteredTargetSystems();
    }, 1000);
  }

  defaultDocumentTypeOnBlur(): void {
    this.documentTypes = this.getListOfDocumentTypes();

    setTimeout(() => {
      this.checkControlLabel(
        this.documentTypes,
        'name',
        this.defaultDocumentTypeControl,
        () => {
          this.defaultDocumentTypeControl.setValue(
            this.defaultDocumentTypeLabel
          );
          this.cd.detectChanges();
        }
      );
      this.initFilteredDocumentTypes();
    }, 1000);
  }

  initFilteredDocumentVisibilities(): void {
    this.documentTypes = this.getListOfDocumentTypes();

    this.checkControlLabel(
      this.documentTypes,
      'label',
      this.defaultDocumentVisibilityControl,
      () => {
        this.defaultDocumentVisibilityControl.setValue(
          this.defaultDocumentVisibilityLabel
        );
        this.cd.detectChanges();
      }
    );
    this.filteredDocumentVisibilities =
      this.fileService.initFilteredDocumentVisibilities(
        this.hasDocumentVisibilitySelection,
        this.documentVisibilities,
        this.defaultDocumentVisibilityControl
      );
    this.documentVisibilitiesSelectable =
      this.fileService.documentVisibilitiesSelectable;
    this.cd.detectChanges();
  }

  onDocumentTypeOptionOnBlur(index: number) {
    const arrayControl = this.form.get('typedFiles') as FormArray;
    const arrayControlObject = arrayControl.at(index);
    if (arrayControlObject) {
      const arrayControlObjectFieldLabel =
        arrayControlObject.get('documentTypeLabel');
      const arrayControlObjectFieldId =
        arrayControlObject.get('documentTypeId');

      setTimeout(() => {
        if (arrayControlObjectFieldLabel && arrayControlObjectFieldId) {
          this.documentTypes = this.getListOfDocumentTypes();

          this.checkControlLabel(
            this.documentTypes,
            'name',
            arrayControlObjectFieldLabel,
            () => {
              arrayControlObjectFieldLabel.setValue('');
              arrayControlObjectFieldId.setValue('');
              this.cd.detectChanges();
            }
          );
        }
        this.initDocumentTypesOptions(index);
      }, 1000);
    }
  }

  private initFilteredTasks() {
    if (!this.hasTaskSelection) {
      return;
    }
    this.filteredTasks = this.defaultTaskControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTasks(value || ''))
    );
    this.cd.detectChanges();
  }

  private filterTasks(taskSubject: string): any[] {
    const filterValue = taskSubject.toLowerCase();
    return this.tasks?.filter(option =>
      option.subject?.toLowerCase().includes(filterValue)
    );
  }

  private initFilteredTargetSystems() {
    if (!this.hasTargetSystemSelection) {
      return;
    }
    this.filteredTargetSystems =
      this.defaultTargetSystemControl.valueChanges.pipe(
        startWith(''),
        map(value => this.filterTargetSystems(value || ''))
      );
    this.cd.detectChanges();
  }

  private filterTargetSystems(targetSystemLabel: string): any[] {
    const filterValue = targetSystemLabel.toLowerCase();
    return this.targetSystems?.filter(option =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  private initFilteredDocumentTypes() {
    if (!this.hasDocumentTypeSelection) {
      return;
    }
    this.filteredDocumentTypes =
      this.defaultDocumentTypeControl.valueChanges.pipe(
        startWith(''),
        map(value => this.filterDocumentTypes(value || ''))
      );
    this.cd.detectChanges();
  }

  private filterDocumentTypes(documentTypeLabel: string): any[] {
    const filterValue = documentTypeLabel.toLowerCase();
    return this.documentTypeGroups
      .map(group => ({
        name: group.name,
        documentTypes: group.documentTypes.filter(option =>
          option?.name?.toLowerCase().includes(filterValue)
        ),
      }))
      .filter(group => group.documentTypes.length > 0);
  }

  async uploadFiles() {
    this.form.markAllAsTouched();
    if (this.typedFiles.value === null || !this.typedFiles.valid) {
      return;
    }
    const [isValid, message, title] = this.fileService.validateTotalFileSize(
      this.form.value.typedFiles,
      (accumulator, current) => accumulator + current.file.size,
      this.mandantConfigService.mandant.maxTotalUploadInMB ??
        DefaultValues.maxTotalUploadInMB
    );
    if (!isValid) {
      this.toastr.error(message, title);
      return;
    }
    this.busy = true;
    const errorFiles: FormGroup[] = [];
    const packedFiles: Array<UploadFileTempStorage> = [];
    for await (const formGroup of this.typedFiles.value) {
      const {
        file,
        documentTypeId,
        documentVisibilityId,
        targetSystem,
        taskId,
      } = formGroup;
      const contentBase64Encoded = (
        await this.fileService.fileRead(file)
      ).split(',')[1];

      const maxMB = this.getSelectedDocumentTypeMaxMB(documentTypeId);
      const [isFileValid] = this.fileService.validateFile(file, maxMB);

      if (isFileValid) {
        const uploadFile = <UploadFileTempStorage>{
          contentBase64Encoded,
          metadata: {
            ...(<FmDocument>{
              isInternal: false,
              documentId: '',
              statusCode: 'Aktiv',
            }),
            ...this.metadata,
          },
          targetSystem,
        };

        if (taskId) {
          uploadFile.metadata.taskId = taskId;
        }

        // populate metadata attributes
        this.metadata.forEach(m => {
          uploadFile.metadata[m.attributeName] = m.attributeValue;
        });

        uploadFile.metadata.documentTypeId = documentTypeId;
        uploadFile.metadata.visibilityId = documentVisibilityId;
        uploadFile.metadata.name = file.name;
        uploadFile.metadata.filename = file.name;

        packedFiles.push(uploadFile);
      } else {
        errorFiles.push(formGroup);
      }
    }

    if (!packedFiles.length) {
      // nothing to upload
      this.busy = false;
      this.cd.detectChanges();
      return;
    }

    let targetEntityId = 'entity-not-configured';
    const taskMetadata = this.metadata.find(m => m.attributeName === 'taskId');
    const incidentMetadata = this.metadata.find(
      m => m.attributeName === 'incidentId'
    );
    const contactMetadata = this.metadata.find(
      m => m.attributeName === 'contactId'
    );
    const rentalContractMetadata = this.metadata.find(
      m => m.attributeName === 'rentalContractId'
    );

    if (taskMetadata?.attributeValue) {
      targetEntityId = taskMetadata?.attributeValue;
    } else if (incidentMetadata?.attributeValue) {
      targetEntityId = incidentMetadata.attributeValue;
    } else if (contactMetadata?.attributeValue) {
      targetEntityId = contactMetadata?.attributeValue;
    } else if (rentalContractMetadata?.attributeValue) {
      targetEntityId = rentalContractMetadata.attributeValue;
    }
    const uploadFilesTempStorageRequest: UploadFilesTempStorageRequest = {
      targetEntityId,
      files: packedFiles,
    };

    if (this.statusData.eMail === '' && this.statusData.ticketNumber === '') {
      this.fileService
        .uploadFilesTempStorage(uploadFilesTempStorageRequest)
        .pipe(first())
        .subscribe({
          next: result => {
            this.typedFiles.clear();

            errorFiles.forEach(item => {
              this.typedFiles.push(item);
            });

            this.toastr.success(
              this.translateService.instant('crud.fileUpload.started')
            );
            this.busy = false;
            this.uploadEvent.emit(result);
            this.fileUpload.nativeElement.value = '';
            this.defaultDocumentTypeControl.setValue(null);
            this.cd.detectChanges();
          },
          error: error => {
            this.busy = false;
            console.log(error);

            if (error.errorCode === SmartPortalProcessError.FILE_CONTENT_TYPE) {
              this.toastr.error(
                this.translateService.instant(
                  'crud.fileUpload.error.contentType'
                )
              );
            } else if (
              error.errorCode ===
              SmartPortalProcessError.USER_IS_NOT_ALLOWED_TO_ACCESS_INCIDENT
            ) {
              this.toastr.error(
                this.translateService.instant(
                  'crud.fileUpload.error.closedTicket'
                )
              );
            } else {
              this.toastr.error(
                this.translateService.instant('crud.fileUpload.error.upload')
              );
            }

            this.fileUpload.nativeElement.value = '';
            this.cd.detectChanges();
          },
        });
    } else {
      const uploadFilesWithEmailRequest: UploadFilesWithEmailRequest = {
        files: packedFiles,
        primaryContactEmail: this.statusData.eMail,
        ticketNumber: this.statusData.ticketNumber,
        targetEntityId: targetEntityId,
      };

      this.fileService
        .uploadFilesWithEmail(uploadFilesWithEmailRequest)
        .pipe(first())
        .subscribe({
          next: result => {
            this.typedFiles.clear();

            errorFiles.forEach(item => {
              this.typedFiles.push(item);
            });

            this.toastr.success(
              this.translateService.instant('crud.fileUpload.started')
            );
            this.busy = false;
            this.uploadEvent.emit(result);
            this.fileUpload.nativeElement.value = '';
            this.defaultDocumentTypeControl.setValue(null);
            this.cd.detectChanges();
          },
          error: error => {
            this.busy = false;
            console.log(error);

            if (error.errorCode === SmartPortalProcessError.FILE_CONTENT_TYPE) {
              this.toastr.error(
                this.translateService.instant(
                  'crud.fileUpload.error.contentType'
                )
              );
            } else {
              this.toastr.error(
                this.translateService.instant('crud.fileUpload.error.upload')
              );
            }

            this.fileUpload.nativeElement.value = '';
            this.cd.detectChanges();
          },
        });
    }
  }

  castToFormGroup(formGroup: any) {
    return formGroup as FormGroup;
  }

  onFileSelected(event: any) {
    if (event) {
      const files: DocumentTypeFile[] = [...event.target.files];
      files.forEach(file => {
        this.addTypedFiles(file);
      });
    }
  }

  private checkControlLabel(
    list: any[],
    listItemAttribute: string,
    control: AbstractControl,
    callback: () => void
  ) {
    if (!list.find(item => item[listItemAttribute] === control.value)) {
      callback();
    }
  }

  private initDocumentTypesOptions(index: number) {
    const arrayControl = this.form.get('typedFiles') as FormArray;
    const arrayControlObject = arrayControl.at(index);
    if (arrayControlObject) {
      const arrayControlObjectField =
        arrayControlObject.get('documentTypeLabel');
      if (arrayControl && arrayControlObject && arrayControlObjectField) {
        this.filteredDocumentTypeOptions[index] =
          arrayControlObjectField.valueChanges.pipe(
            startWith(''),
            map(value => this.filterDocumentTypes(value || ''))
          );
      }
    }
  }

  private addTypedFiles(file: File) {
    const maxMB = this.getSelectedDocumentTypeMaxMB(
      this.defaultDocumentTypeValue
    );
    const errorTitle = this.translateService.instant(
      'fileUpload.error.title_error'
    );
    const [isValid, message] = this.fileService.validateFile(file, maxMB);
    if (!isValid) {
      this.toastr.error(message, errorTitle);
      this.fileUpload.nativeElement.value = '';
      return;
    }

    if (this.defaultDocumentTypeValue) {
      const contentType = this.fileService.getContentTypeByFileExtension(
        file.name
      );
      if (
        !this.validateContentType(contentType, this.defaultDocumentTypeValue)
      ) {
        const contentTypes = this.getSelectedDocumentTypeContentTypes(
          this.defaultDocumentTypeValue
        );
        const contentTypesString = this.getContentTypesString(contentTypes);
        const contentTypeErrorMessage = this.translateService.instant(
          'documents.allowed__contentTypes',
          { contentTypes: contentTypesString }
        );
        this.toastr.error(contentTypeErrorMessage, errorTitle);
        this.fileUpload.nativeElement.value = '';
        return;
      }
    }

    const fileForm = this.fb.group({
      taskId: [this.defaultTaskValue],
      targetSystem: [
        this.defaultTargetSystemValue,
        this.hasTargetSystemSelection ? Validators.required : '',
      ],
      documentTypeId: [
        this.defaultDocumentTypeValue,
        this.hasDocumentTypeSelection ? Validators.required : '',
      ],
      documentTypeLabel: [this.defaultDocumentTypeLabel],
      documentVisibilityId: [
        this.defaultDocumentVisibilityValue,
        this.hasDocumentVisibilitySelection ? Validators.required : '',
      ],
      file: [file],
    });

    this.typedFiles.push(fileForm);
    this.initDocumentTypesOptions(this.typedFiles.length - 1);
    this.cd.detectChanges();
  }

  detachFile(index: number) {
    this.typedFiles.removeAt(index);
    this.fileUpload.nativeElement.value = '';
    this.filteredDocumentTypeOptions.splice(index, 1);
    this.cd.detectChanges();
  }

  private validateContentType(fileContentType: string, documentTypeId: string) {
    let isValid = false;
    const contentTypes =
      this.getSelectedDocumentTypeContentTypes(documentTypeId);

    if (!contentTypes?.length) {
      return true;
    }

    contentTypes.every((item: ContentTypeViewModel) => {
      if (item?.contentType?.toLowerCase() === fileContentType.toLowerCase()) {
        isValid = true;
        return false;
      }
      return true;
    });
    return isValid;
  }

  private getContentTypesString(
    contentTypes: ContentTypeViewModel[] | undefined
  ) {
    let contentTypesString = '';
    contentTypes?.forEach((item: ContentTypeViewModel) => {
      if (item.contentType) {
        contentTypesString += `${this.translateService.instant(
          `documents.contentTypes.${item.contentType}`
        )} `;
      }
    });
    return contentTypesString;
  }

  private getSelectedDocumentTypeMaxMB(_documentTypeId) {
    const fallbackMaxMB =
      this.mandantConfigService.mandant.maxFileSizeInMB ??
      DefaultValues.maxFileSizeInMB;
    return fallbackMaxMB;
    /*
        TODO enable when GSUS has maintained document documentTypes.maxFileSizeInBytes
        this.documentTypes = this.getListOfDocumentTypes();
        const selectedDocumentType = this.documentTypes?.find((documentType: DocumentTypeViewModel) => documentType.externalCode === documentTypeId);
        return selectedDocumentType?.maxFileSizeInBytes
          ? selectedDocumentType.maxFileSizeInBytes / 1024 / 1024
          : fallbackMaxMB;
        */
  }

  private getSelectedDocumentTypeContentTypes(documentTypeId: string) {
    this.documentTypes = this.getListOfDocumentTypes();

    const selectedDocumentType = this.documentTypes.find(
      (documentType: DocumentTypeViewModel) =>
        documentType.externalCode === documentTypeId
    );
    return selectedDocumentType?.fmDocumentTypeContentType;
  }

  private initTargetSystemOptions() {
    const userRoles =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
    const hasImaUpload = !!userRoles?.find(role =>
      role.startsWith(UserRoles.SMARTPORTAL_DOCUMENT_IMAUPLOAD_PREFIX)
    );
    if (hasImaUpload) {
      this.targetSystems.push({
        value: TargetSystem.ImageMaster,
        label: this.translateService.instant('documents.targetSystems.1'),
      });
    }
  }

  private initDefaultVisibility() {
    [
      this.hasDocumentVisibilitySelection,
      this.defaultDocumentVisibilityValue,
      this.defaultDocumentVisibilityLabel,
      this.documentVisibilities,
      this.defaultDocumentVisibilityControl,
    ] = this.fileService.initDefaultVisibility(
      this.hasDocumentVisibilitySelection,
      this.defaultDocumentVisibilityControl
    );
  }

  private getListOfDocumentTypes(): DocumentTypeViewModel[] {
    const listOfDocumentTypes: DocumentTypeViewModel[] = [];
    this.documentTypeGroups.forEach(gr => {
      gr.documentTypes.forEach(docType => {
        listOfDocumentTypes.push(docType);
      });
    });
    return listOfDocumentTypes;
  }
}
