import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { OptionSetClient } from '@core/api';
import { ChangeDetectorRef } from '@angular/core';
import { IbpdiHelperService } from '@core/services/translate-service/ibpdi-helper.service';

export function searchLocation(
  translateService: TranslateService,
  optionSetClient: OptionSetClient,
  cd: ChangeDetectorRef,
  ibpdiHelperService: IbpdiHelperService,
  locationFormlyFieldService: LocationFormlyFieldService,
  onSelectLocation: (id: string) => void,
  weNo?: string | null
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.confirm.headline__objectAdress'
            ),
            visibleInConfirm: true,
          },
        },
        {
          key: 'searchLocation',
          fieldGroup: [
            ...locationFormlyFieldService.autocompleteSearch(
              false,
              { archObjId: weNo },
              true,
              onSelectLocation
            ),

            {
              wrappers: ['form-field'],
              type: 'input',
              key: 'roomNumber',
              className: 'description-margin',
              props: {
                label: translateService.instant(
                  'ticket.create.label__roomNumber'
                ),
                required: true,
                visibleInConfirm: true,
                maxLength: 50,
                description: translateService.instant(
                  'helpdesk.ticket.create.description__roomNumber'
                ),
              },
            },
            {
              type: 'input',
              key: 'floor',
              props: {
                label: translateService.instant(
                  'helpdesk.ticket.create.label__floor'
                ),
                visibleInConfirm: true,
                maxLength: 50,
              },
            },
            {
              type: 'input',
              key: 'section',
              props: {
                label: translateService.instant(
                  'helpdesk.ticket.create.label__section'
                ),
                visibleInConfirm: true,
                maxLength: 20,
              },
              validation: {
                messages: {
                  pattern: translateService.instant(
                    'formErrors.section.pattern'
                  ),
                },
              },
            },
            {
              type: 'textarea',
              key: 'auxaddress',
              props: {
                label: translateService.instant(
                  'helpdesk.ticket.create.label__differentAddress'
                ),
                visibleInConfirm: true,
                autosize: true,
                autosizeMinRows: 4,
                maxLength: 100,
              },
            },
          ],
        },
      ],
    },
  ];
}
