import { FormModes } from '@mandants/telekom/features/tickets/ticket-create/ticket-create.types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function incidentInformation(
  translateService: TranslateService,
  mode: FormModes
): Array<FormlyFieldConfig> {
  if (mode === 'helpdesk') {
    return [
      {
        fieldGroup: [
          {
            type: 'input',
            key: 'incidentTitle',
            props: {
              label: translateService.instant(
                'ticket.create.label__short_description'
              ),
              required: true,
              visibleInConfirm: true,
              maxLength: 40,
            },
          },
        ],
      },
      {
        wrappers: ['form-field', 'count-chars'],
        key: 'incidentDescription',
        type: 'textarea',
        props: {
          label: translateService.instant(
            'ticket.create.label__additional_information_malfunction'
          ),
          visibleInConfirm: true,
          autosize: true,
          autosizeMinRows: 4,
          maxLength: 700,
        },
      },
    ];
  }

  return [
    {
      fieldGroup: [
        {
          type: 'input',
          key: 'incidentTitle',
          props: {
            label: translateService.instant('ticket.create.label__title'),
            required: true,
            visibleInConfirm: true,
            maxLength: 200,
          },
        },
      ],
    },
    {
      fieldGroup: [
        {
          type: 'select-tooltip',
          key: 'incidentClass',
          props: {
            visibleInConfirm: true,
            label: translateService.instant(
              'ticket.create.label__incidentcategory'
            ),
            required: true,
            options: [
              {
                value: 'Baulast',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.constructionLoad.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.constructionLoad.tooltip'
                ),
              },
              {
                value: 'Dienstbarkeit',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.easement.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.easement.tooltip'
                ),
              },
              {
                value: 'Kaufanfrage',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.purchaseEnquiry.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.purchaseEnquiry.tooltip'
                ),
              },
              {
                value: 'Löschungsbewilligung',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.deletionPermit.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.deletionPermit.tooltip'
                ),
              },
              {
                value: 'Öffentlich rechtliches Verfahren',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.publicLawProcedure.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.publicLawProcedure.tooltip'
                ),
              },
              {
                value: 'Fläche extern vermieten',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.rentSpaceExternally.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.rentSpaceExternally.tooltip'
                ),
              },
              {
                value: 'Sonstiges',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.other.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.other.tooltip'
                ),
              },
            ],
          },
          expressions: {
            'props.visibleInConfirm': 'true',
          },
        },
        {
          type: 'chapter',
          props: {
            chapterTitleBold: `${translateService.instant('ticket.create.incidentClass.options.rentSpaceExternally.notice').replace(translateService.instant('ticket.create.incidentClass.options.rentSpaceExternally.linkText'), `<a href="/landing/rental" class="link">${translateService.instant('ticket.create.incidentClass.options.rentSpaceExternally.linkText')}</a>`)}`,
          },
          hideExpression: formstate => {
            return formstate.incidentClass !== 'Fläche extern vermieten';
          },
        },
      ],
    },
    {
      wrappers: ['form-field', 'count-chars'],
      key: 'incidentDescription',
      type: 'input',
      props: {
        label: translateService.instant('ticket.create.label__description'),
        visibleInConfirm: true,
        required: true,
        autosize: true,
        autosizeMinRows: 2,
        maxLength: 2000,
      },
    },
    {
      type: 'chapter',
      props: {
        chapterText: translateService.instant(
          'ticket.create.p__info_contactInformation'
        ),
        visibleInConfirm: false,
      },
    },
    {
      wrappers: ['form-field', 'count-chars'],
      key: 'contactInformation',
      type: 'input',
      props: {
        label: translateService.instant(
          'ticket.create.label__contactInformation'
        ),
        visibleInConfirm: true,
        autosize: true,
        autosizeMinRows: 3,
        maxLength: 4000,
      },
    },
  ];
}
