import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ContactClient, EmploymentSearchResultDto } from '@core/api';
import { FormlyFieldBaseService } from '@core/services/formly-service/formly-field.service';
import { FormlyPreviewAutocompleteProps } from '@core/services/formly-service/formly-service.types';

@Injectable({
  providedIn: 'root',
})
export class ContactSearchFormlyFieldService extends FormlyFieldBaseService {
  override translatePath = 'contact.autocomplete';

  constructor(
    protected override translateService: TranslateService,
    private contactClient: ContactClient
  ) {
    super(translateService);
  }

  autocompleteSearch(
    placeholder: string,
    onSelect: (id: string, onInitSearch: boolean, value: EmploymentSearchResultDto) => void,
    required?: boolean,
    visibleInConfirm?: boolean,
    currentUserEmail?: string,
    hideInPreview?: boolean,
    onDelete?: () => void,
  ): FormlyFieldConfig[] {
    const translatePath = 'contact.autocomplete';

    const columnDefinitions = [
      {
        fieldName: 'firstName',
        labelTranslationKey: translatePath + '.firstName',
        classes: 'col-3, text-overflow',
        hideInPreview: hideInPreview ?? false
      },
      {
        fieldName: 'lastName',
        labelTranslationKey: translatePath + '.lastName',
        classes: 'col-2, text-overflow',
        hideInPreview: hideInPreview ?? false
      },
      {
        fieldName: 'email',
        labelTranslationKey: translatePath + '.email',
        classes: 'col-2, text-overflow',
        hideInPreview: hideInPreview ?? false
      },
      {
        fieldName: 'telephone',
        labelTranslationKey: translatePath + '.phone',
        classes: 'col-2, text-overflow',
        hideInPreview: hideInPreview ?? false
      },
    ];
    const props: FormlyPreviewAutocompleteProps = {
      bindLabel: 'fullName',
      bindValue: 'id',
      required: required,
      visibleInConfirm: visibleInConfirm ?? false,
      translatePath,
      columnDefinitions,
      defaultValue: { id: currentUserEmail },
      onSearch: (searchText: string): Observable<any> => {
        return this.contactClient
          .search(searchText, 100);
      },
      onSelect,
      onDelete,
      label: this.translateService.instant(placeholder),
    };

    return [
      {
        type: 'preview-autocomplete',
        key: 'searchResult',
        props: { ...props },
        validation: {
          messages: {
            required: this.translateService.instant('formErrors.contact.empty'),
          },
        },
      },
    ];
  }
}
