import {
  Component,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AccessOrdererClient,
  AccessOrdererTableEntryViewModel,
  CreateAccessOrdererRequest,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { CostcenterEmployeeSearchFormlyFieldService } from '@core/services/costcenter-employee-search-formly-field-service/costcenter-employee-search-formly-field.service';
import { CostcenterSearchFormlyFieldService } from '@core/services/costcenter-search-formly-field-service/costcenter-search-formly-field.service';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import moment from 'moment';
import { AccessOrdererHistoryComponent } from './access-orderer-history/access-orderer-history.component';
import { EditAccessOrdererComponent } from './edit-access-orderer/edit-access-orderer.component';

@Component({
  selector: 'app-access-orderer',
  templateUrl: './access-orderer.component.html',
  styleUrls: ['./access-orderer.component.scss'],
})
export class AccessOrdererComponent
  extends TableBaseComponent
  implements OnInit
{
  @ViewChild('addExtendTemplate', { static: true })
  addExtendTemplateRef!: TemplateRef<void>;

  @ViewChild('endTemplate', { static: true })
  endTemplateRef!: TemplateRef<void>;

  form = new FormGroup({});

  formModel: any = {};

  selectedCostcenter: string;

  selectedOrderer: string;

  constructor(
    injector: Injector,
    private breadcrumbService: BreadcrumbService,
    private costcenterEmployeeFormlyFieldService: CostcenterEmployeeSearchFormlyFieldService,
    private costcenterFormlyFieldService: CostcenterSearchFormlyFieldService,
    private accessOrdererClient: AccessOrdererClient,
    private dialog: MatDialog
  ) {
    super(injector);
  }

  ngOnInit() {
    const translatedLabel = this.translateService.instant(
      'access_orderer.title'
    );
    this.breadcrumbService.setBreadcrumbs([{ label: translatedLabel }]);
    this.columns = [
      {
        header: this.translateService.instant('access_orderer.list.orderer'),
        field: 'employeeName',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.list.phone'),
        field: 'employeePhoneNumber',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.list.costcenter'),
        field: 'costCenterId',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant(
          'access_orderer.list.costCenterName'
        ),
        field: 'costCenterName',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.list.validUntil'),
        field: 'accessOrdererEnddate',
        sortable: true,
        class: 'truncated',
        formatter: (data: any) =>
          data.accessOrdererEnddate
            ? moment(data.accessOrdererEnddate)
                .locale(this.translateService.instant('locale.locale'))
                .format(
                  this.translateService.instant(
                    'locale.moment__date_format_only_digits'
                  )
                )
            : '--',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.list.add_extend'),
        field: 'addExtend',
        width: '185px',
        cellTemplate: this.addExtendTemplateRef,
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.list.end'),
        field: 'end',
        cellTemplate: this.endTemplateRef,
        resizable: true,
      },
    ];

    this.isLoading = true;

    this.getData();

    this.filterFields = [
      {
        key: 'costcenter',
        fieldGroup: [
          ...this.costcenterFormlyFieldService.autocompleteSearch(
            this.translateService.instant(
              'access_orderer.filter.costcenter.title'
            ),
            null,
            null,
            null,
            id => this.onSelectCostcenter(id),
            () => this.onDeleteCostcenter()
          ),
        ],
      },
      {
        key: 'orderer',
        fieldGroup: [
          ...this.costcenterEmployeeFormlyFieldService.autocompleteSearch(
            this.translateService.instant(
              'access_orderer.filter.orderer.title'
            ),
            null,
            null,
            null,
            id => this.onSelectOrderer(id),
            () => this.onDeleteOrderer()
          ),
        ],
      },
    ];
  }

  onSelectCostcenter(id: string) {
    this.selectedCostcenter = id;
    this.getData();
  }

  onSelectOrderer(id: string) {
    this.selectedOrderer = id;
    this.getData();
  }

  onDeleteCostcenter() {
    this.selectedCostcenter = null;
    this.getData();
  }

  onDeleteOrderer() {
    this.selectedOrderer = null;
    this.getData();
  }

  override getData() {
    const request = {
      pagingOptions: this.query.pagingOptions,
      orderOptions: this.query.orderOptions,
      costCenterId: this.selectedCostcenter,
      employeeId: this.selectedOrderer,
    };
    this.accessOrdererClient.getAccessOrderers(request).subscribe({
      next: res => {
        this.data.items = res.entites;
        this.data.totalCount = res.totalCount;
        this.isLoading = false;
      },
    });
  }

  openDialogAccessOrderer(
    row: AccessOrdererTableEntryViewModel,
    action: 'add' | 'extend' | 'end'
  ) {
    let title = '';
    let confirmText = this.translateService.instant('shared.save');
    switch (action) {
      case 'add':
        title = this.translateService.instant(
          'access_orderer.dialog.title.add'
        );
        confirmText = this.translateService.instant(
          'access_orderer.dialog.confirm.add'
        );
        break;
      case 'extend':
        title = this.translateService.instant(
          'access_orderer.dialog.title.extend'
        );
        confirmText = this.translateService.instant(
          'access_orderer.dialog.confirm.extend'
        );
        break;
      case 'end':
        title = this.translateService.instant(
          'access_orderer.dialog.title.end'
        );
        confirmText = this.translateService.instant(
          'access_orderer.dialog.confirm.end'
        );
        break;
    }
    const dialogRefAccessOrderer = this.dialog.open(
      EditAccessOrdererComponent,
      {
        panelClass: 'smartportal-dialog',
        width: '600px',
        data: { row, endAccessOrderer: action === 'end', title, confirmText },
      }
    );

    dialogRefAccessOrderer.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.isLoading = true;
          switch (action) {
            case 'add':
              const addRequest: CreateAccessOrdererRequest = {
                costCenterId: row.costCenterId,
                employeeId: row.employeeId,
              };
              this.accessOrdererClient
                .createAccessOrderer(addRequest)
                .subscribe({
                  next: () => {
                    this.getData();
                  },
                });
              break;
            case 'extend':
              this.accessOrdererClient
                .extendAccessOrderer(row.accessOrdererId)
                .subscribe({
                  next: () => {
                    this.getData();
                  },
                });
              break;
            case 'end':
              this.accessOrdererClient
                .deleteAccessOrderer(row.accessOrdererId)
                .subscribe({
                  next: () => {
                    this.getData();
                  },
                });
              break;
          }
        }
      },
    });
  }

  openHistory() {
    this.accessOrdererClient.getAccessOrderersHistory({}).subscribe({
      next: res => {
        console.log(res);
        this.dialog.open(AccessOrdererHistoryComponent, {
          panelClass: 'smartportal-dialog',
          width: '80vw',
          data: { items: res.entites },
        });
      },
    });
  }
}
