import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DataMaintenanceClient } from '@core/api';
import { BuildingResolver } from '@core/resolvers/edit-building.resolver';
import { EditRoomResolver } from '@core/resolvers/edit-room.resolver';
import { EditStageResolver } from '@core/resolvers/edit-stage-resolver';
import { EditWorkingSpaceResolver } from '@core/resolvers/edit-working-space.resolver';
import { defaultRoutes } from '@core/routing/routes';
import { RoutePath } from '@core/routing/routing.types';
import { MaintenanceGuard } from '@core/services/menu-service/maintenance.guard';
import { RolesGuard } from '@core/services/menu-service/roles.guard';
import { CallbackComponent } from '@features/authentication/callback/callback.component';
import { DashboardComponent } from '@features/dashboard/components/dashboard/dashboard.component';
import { DocumentsComponent } from '@features/documents/documents.component';
import { ProfileDefaultComponent } from '@features/profile-default/components/profile-default.component';
import { TaskDetailsViewComponent } from '@features/tasks/components/task-details-view/task-details-view.component';
import { TicketGetStatusDefaultComponent } from '@features/tickets/components/ticket-get-status/default/ticket-get-status-default';
import { TicketListHelpdeskComponent } from '@features/tickets/components/ticket-list-helpdesk/ticket-list-helpdesk.component';
import { TicketSummaryComponent } from '@features/tickets/components/ticket-summary/ticket-summary.component';
import { WebsiteEditComponent } from '@mandants/telekom//features/website-edit/website-edit.component';
import { AccessOrdererComponent } from '@mandants/telekom/features/access-orderer/access-orderer.component';
import { AdministrationComponent } from '@mandants/telekom/features/administration/administration.component';
import { UpdateUserComponent } from '@mandants/telekom/features/administration/update-user/update-user.component';
import { EditBuildingComponent } from '@mandants/telekom/features/edit-building/edit-building.component';
import { EditRoomComponent } from '@mandants/telekom/features/edit-room/edit-room.component';
import { EditStageComponent } from '@mandants/telekom/features/edit-stage/edit-stage.component';
import { FeedbackTelekomComponent } from '@mandants/telekom/features/feedback/feedback.component';
import { FurnitureOrderComponent } from '@mandants/telekom/features/furniture-order/furniture-order.component';
import { ImprintTelekomComponent } from '@mandants/telekom/features/imprint/imprint.component';
import { MyLocationComponent } from '@mandants/telekom/features/location/my-location/my-location.component';
import { WorkingSpaceComponent } from '@mandants/telekom/features/locationServices/working-space/working-space.component';
import { PrivacyTelekomComponent } from '@mandants/telekom/features/privacy/privacy.component';
import { RelocationComponent } from '@mandants/telekom/features/relocation/relocation.component';
import { ContactComponent } from '@mandants/telekom/features/rem/pages/contact/contact.component';
import { HomeComponent } from '@mandants/telekom/features/rem/pages/home/home.component';
import { ImpressumComponent } from '@mandants/telekom/features/rem/pages/impressum/impressum.component';
import { MaintenanceComponent } from '@mandants/telekom/features/rem/pages/maintenance/maintenance.component';
import { PortfolioComponent } from '@mandants/telekom/features/rem/pages/portfolio/portfolio.component';
import { PrivacyComponent } from '@mandants/telekom/features/rem/pages/privacy/privacy.component';
import { RentalComponent } from '@mandants/telekom/features/rem/pages/rental/rental.component';
import { SellComponent } from '@mandants/telekom/features/rem/pages/sell/sell.component';
import { StatusItemComponent } from '@mandants/telekom/features/rem/pages/status/status-item.component';
import { StatusComponent } from '@mandants/telekom/features/rem/pages/status/status.component';
import { WhoComponent } from '@mandants/telekom/features/rem/pages/who/who.component';
import { RemComponent } from '@mandants/telekom/features/rem/rem.component';
import { SpaceRequestComponent } from '@mandants/telekom/features/space-request/space-request.component';
import { SpaceReturnComponent } from '@mandants/telekom/features/space-return/space-return.component';
import { TaskDetailsTelekomComponent } from '@mandants/telekom/features/tasks/task-details/task-details.component';
import { TaskListTelekomComponent } from '@mandants/telekom/features/tasks/task-list/task-list.component';
import { ApprovalsComponent } from '@mandants/telekom/features/tickets/approvals/approvals.component';
import { TicketCreateOrderAccessTelekomComponent } from '@mandants/telekom/features/tickets/ticket-create/order-access/order-access.component';
import { TicketCreateTelekomComponent } from '@mandants/telekom/features/tickets/ticket-create/ticket-create.component';
import { TicketListTelekomComponent } from '@mandants/telekom/features/tickets/ticket-list/ticket-list.component';
import { TicketStatusItemTelekomComponent } from '@mandants/telekom/features/tickets/ticket-status-item/ticket-status-item.component';
import { UserCreateComponent } from '@mandants/telekom/features/user/create/user-create.component';
import { UserPermissionsComponent } from '@mandants/telekom/features/user/permissions/user-permissions.component';
import { TelekomModule } from '@mandants/telekom/telekom.module';
import { SsoGuard } from '@core/services/oauth-service/sso.guard';
import { CostcenterusageComponent } from '@mandants/telekom/features/data-maintenance/costcenterusage/costcenterusage.component';

// Telekom routes
const routes: Routes = [
  {
    path: RoutePath.ACCESS_ORDERER,
    component: AccessOrdererComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.APPROVALS,
    component: ApprovalsComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.AUTH_CALLBACK,
    component: CallbackComponent,
    canActivate: [MsalGuard],
  },
  {
    path: RoutePath.DASHBOARD,
    component: DashboardComponent,
    canActivate: [SsoGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.DATA_MAINTENANCE,
    loadChildren: () =>
      import('@mandants/telekom/features/data-maintenance/routes').then(
        m => m.default
      ),
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
    providers: [DataMaintenanceClient],
  },
  {
    path: RoutePath.DOCUMENTS,
    children: [
      {
        path: '',
        component: DocumentsComponent,
      },
      {
        path: 'ticket/:ticketNumber',
        component: DocumentsComponent,
      },
      {
        path: 'incident/:incidentId',
        component: DocumentsComponent,
      },
      {
        path: 'task/:taskId',
        component: DocumentsComponent,
      },
    ],
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.DOCUPLOAD,
    children: [
      {
        path: '',
        component: DocumentsComponent,
      },
      {
        path: 'ticket/:ticketNumber',
        component: DocumentsComponent,
      },
    ],
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.FEEDBACK,
    component: FeedbackTelekomComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.IMPRINT,
    component: ImprintTelekomComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.LOCATION,
    component: MyLocationComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.RELOCATION,
    component: RelocationComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.SPACERETURN,
    component: SpaceReturnComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.SPACEREQUEST,
    component: SpaceRequestComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.FURNITUREORDER,
    component: FurnitureOrderComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.PRIVACY,
    component: PrivacyTelekomComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.PROFILE_DEFAULT,
    component: ProfileDefaultComponent,
    canActivate: [MsalGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.PROFILE_JSON,
    component: ProfileDefaultComponent,
    data: { showJson: true },
    canActivate: [MaintenanceGuard],
  },
  {
    path: RoutePath.TASK,
    component: TaskDetailsTelekomComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TASK_VIEW,
    component: TaskDetailsViewComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TASKS,
    component: TaskListTelekomComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKET_CREATE,
    component: TicketCreateTelekomComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKET_CREATE_HELPDESK,
    component: TicketCreateTelekomComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.HELPDESK_MYTICKETS,
    component: TicketListHelpdeskComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.HELPDESK_MYTICKETS_DETAILS,
    component: TicketSummaryComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKET_CREATE_ORDER_ACCESS,
    component: TicketCreateOrderAccessTelekomComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKETS,
    component: TicketListTelekomComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKETSTATUS,
    component: TicketGetStatusDefaultComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.TICKETSTATUS_ITEM,
    component: TicketStatusItemTelekomComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.WEBSITE_EDIT_RENTAL,
    component: WebsiteEditComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.USER_ADMINISTRATION,
    children: [
      {
        path: '',
        component: AdministrationComponent,
      },
      {
        path: RoutePath.DETAILS,
        component: UpdateUserComponent,
      },
    ],
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.EDIT_BUILDING,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
    children: [
      {
        path: ':buildingId',
        component: EditBuildingComponent,
        resolve: {
          building: BuildingResolver,
        },
      },
    ],
  },
  {
    path: RoutePath.EDIT_STAGE,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
    children: [
      {
        path: ':stageId/:floorId',
        component: EditStageComponent,
        resolve: {
          stage: EditStageResolver,
        },
      },
    ],
  },
  {
    path: RoutePath.EDIT_ROOM,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
    children: [
      {
        path: ':stageId/:floorId/:roomId',
        component: EditRoomComponent,
        resolve: {
          roomData: EditRoomResolver,
        },
      },
    ],
  },
  {
    path: RoutePath.USER_CREATION,
    component: UserCreateComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.USER_PERMISSIONS,
    component: UserPermissionsComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.WORKING_SPACE,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
    children: [
      {
        path: ':buildingId/:stageId/:spaceId/:roomId',
        component: WorkingSpaceComponent,
        resolve: {
          roomData: EditWorkingSpaceResolver,
        },
      },
    ],
  },
  {
    path: RoutePath.DATA_MAINTENANCE_COSTCENTER_USAGE,
    component: CostcenterusageComponent,
    canActivate: [MsalGuard, RolesGuard, MaintenanceGuard],
  },
  {
    path: RoutePath.LANDING,
    component: RemComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'portfolio', component: PortfolioComponent },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [MaintenanceGuard],
      },
      { path: 'who', component: WhoComponent },
      { path: 'rental', component: RentalComponent },
      { path: 'sell', component: SellComponent },
      {
        path: 'status',
        component: StatusComponent,
        canActivate: [MaintenanceGuard],
      },
      {
        path: 'status/:id',
        component: StatusItemComponent,
        canActivate: [MaintenanceGuard],
      },
      { path: 'imprint', component: ImpressumComponent },
      {
        path: RoutePath.MAINTENANCE,
        component: MaintenanceComponent,
      },
      { path: '*', redirectTo: 'home' },
      { path: '**', redirectTo: 'home' },
    ],
  },
  {
    path: RoutePath.PRIVACY_GENERAL,
    component: RemComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            '@mandants/telekom/features/rem/pages/general-privacy/general-privacy.component'
          ).then(m => m.GeneralPrivacyComponent),
      },
    ],
  },
  {
    path: RoutePath.PRIVACY_REM,
    component: RemComponent,
    children: [{ path: '', component: PrivacyComponent }],
  },
  {
    path: RoutePath.LOGOUT,
    redirectTo: RoutePath.DASHBOARD,
  },
  {
    path: RoutePath.NONE,
    pathMatch: 'full',
    redirectTo: RoutePath.LANDING,
  },
  {
    path: '**',
    redirectTo: RoutePath.LANDING,
  },
  ...defaultRoutes,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { bindToComponentInputs: true }),
    TelekomModule,
  ],
  exports: [RouterModule],
})
export class RoutingModule {
  constructor() {
    if (!location.pathname.includes('/authentication/callback')) {
      localStorage.setItem('previousUrl', location.pathname);
    }
  }
}
