import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { SearchHelpDialogComponent } from '@shared/components/search-help-dialog/search-help-dialog.component';

@Component({
  selector: 'app-formly-button-ccemail-dialog',
  template: `
    <su-button
      data-debug="button-cc-email-dialog formly-button-ccemail-dialog"
      skin="secondary"
      [label]="field.props?.label | translate"
      (onClick)="showDialog(this.form.get('ccemail')?.value)"></su-button>
  `,
})
export class FormlyButtonCCEmailDialogComponent
  extends FieldType
  implements OnInit
{
  override field: FormlyFieldConfig;

  templateOptions: any;

  resultString: string; // todo: no usages?

  constructor(public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.templateOptions = this.field.props;
  }

  showDialog(currentValue: string) {
    const dialogRef = this.dialog.open(SearchHelpDialogComponent, {
      panelClass: 'smartportal-dialog',
      width: '600px',
      data: { currValue: currentValue },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.templateOptions?.change(result);
      },
    });
  }
}
