<su-headline h="h1" skin="primary">{{
  'taskmodule.task.update.title' | translate
}}</su-headline>

<su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>

<su-slot-tab-content *ngIf="activeTab === tabs[0].id">
  <su-card [card]="cardFields">
    <div card-content>
      <!-- <excel-export-telekom [formModel]="formModel" [isLoading]="isLoading" [formFields]="formFields"></excel-export-telekom> -->
      <formly-form
        [form]="form"
        [fields]="formFields"
        [model]="formModel"
        (modelChange)="modelOnChange()"></formly-form>
      <div class="button-container">
        <su-button
          *ngIf="formModel.subject === '12.1 Mietinteressent melden'"
          [label]="'taskmodule.task.prospective_tenants.record' | translate"
          [disabled]="false"
          (onClick)="recordProspectiveTenants()"
          skin="secondary"
          class="button-left"></su-button>
        <su-button
          [label]="'shared.save' | translate"
          [disabled]="!this.form.valid || this.isLoading || !this.form.touched || !this.isSaveButtonEnabled"
          (onClick)="submit()"
          class="button-right"></su-button>
      </div>
      <ng-container *ngIf="dataProspectiveTenantsList$ | async as dataProspectiveTenantsList">
        <h3 *ngIf="dataProspectiveTenantsList.length > 0">
          {{ 'taskmodule.task.prospective_tenants.label' | translate }}
        </h3>
        <br />
        <table
          *ngIf="dataProspectiveTenantsList.length > 0"
          mat-table
          [dataSource]="dataProspectiveTenantsList"
          class="mat-elevation-z8">
          <ng-container matColumnDef="ticketNumber">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'taskmodule.task.prospective_tenants.ticketnumber' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.ticketNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="requestedArea">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'taskmodule.task.prospective_tenants.requested_area' | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.requestedArea }}
            </td>
          </ng-container>

          <ng-container matColumnDef="requestedParkingSpace">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'taskmodule.task.prospective_tenants.requested_parking_spaces'
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.requestedParkingSpace }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ticketStatusReason">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'taskmodule.task.prospective_tenants.ticketStatusReason'
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.ticketStatusReason }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>
    </div>
  </su-card>
</su-slot-tab-content>

<su-slot-tab-content *ngIf="activeTab === tabs[1].id">
  <su-headline h="h1" skin="primary">{{ filesTitle }}</su-headline>

  <su-card [card]="cardDocumentupload">
    <div card-content>
      <app-detail-fields-base
        *ngIf="task"
        [data]="task"
        [detailFields]="detailFields"></app-detail-fields-base>
    </div>
  </su-card>
</su-slot-tab-content>

<su-slot-tab-content *ngIf="activeTab === tabs[2].id">
  <su-card [card]="cardContact">
    <div card-content>
        <app-task-contact *ngIf="task" [dataContactAddress]="task.incident"></app-task-contact>
    </div>
  </su-card>
</su-slot-tab-content>

<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
