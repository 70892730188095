import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CreateRelocation,
  EmploymentClient,
  EmploymentSearchResultDto,
  IncidentClient,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { EmployeeSearchFormlyFieldService } from '@core/services/employee-search-formly-field-service/employee-search-formly-field.service';
import { FileService } from '@core/services/file-service/file.service';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { TicketCreateBaseComponent } from '@features/tickets/components/ticket-create/ticket-create.base';
import { formLocationOfOrigin } from '@mandants/telekom/features/tickets/ticket-create/field-configs/formly-location-of-origin.field-config';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { SafeHtmlPipe } from '@shared/pipes';
import { Moment } from 'moment';
import { fileAttachments } from '../tickets/ticket-create/field-configs';

@Component({
  selector: 'app-relocation',
  templateUrl: './relocation.component.html',
  styleUrl: './relocation.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    SmartusComponentsModule,
    TranslateModule,
    ReactiveFormsModule,
    OverrideModule,
    MatDatepickerModule,
    FormsModule,
    MatFormFieldModule,
    SafeHtmlPipe,
  ],
})
export class RelocationComponent
  extends TicketCreateBaseComponent
  implements OnInit
{
  private employeeFormlyFieldService = inject(EmployeeSearchFormlyFieldService);
  private locationFormlyFieldService = inject(LocationFormlyFieldService);
  private breadcrumbService = inject(BreadcrumbService);
  private incidentClient = inject(IncidentClient);
  private employmentClient = inject(EmploymentClient);
  private fileService = inject(FileService);

  override filledForm: FormlyFieldConfig[] | null;
  override formFields: FormlyFieldConfig[] = [];
  isUserSelected: boolean;
  isTelekomChecked: boolean;
  override form = new FormGroup({});
  override formModel = {};
  override options: FormlyFormOptions = {
    formState: {
      mainModel: this.formModel,
    },
  };
  today = new Date();

  cardFields: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
    footer: {
      button1: {
        label: this.translateService.instant('forward'),
        disabled: false,
        onClick: () => {
          this.fileService.setFileAttachmentType(this.form.value, 'SO7');
          this.validateForm();
        },
      },
    },
  };

  cardConfirm: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  currentUserEmail = this.msalService.instance.getActiveAccount()?.idTokenClaims
    ?.email as string;

  currentUser: EmploymentSearchResultDto;

  isTelekomCheckedOrigin = true;

  isTelekomCheckedTarget = true;

  isProcessingValueChanges = false;

  ngOnInit(): void {
    this.title = this.translateService.instant('relocation.title');

    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.title,
      },
    ]);

    this.successTitle = this.translateService.instant(
      'ticket.create.order_access.successTitle'
    );
    this.successMessage = this.translateService.instant(
      'ticket.create.order_access.successMessage'
    );

    this.description = this.translateService.instant(
      'relocation.p__info_important_notes'
    );
    this.descriptionStyle =
      'background-color: #E20074; color: white; border-radius: 5px; padding: 15px;';
    this.descriptionContainerStyle =
      'width: 100%; max-width: 840px; border-radius: 15px; padding: 0 15px;';

    this.loading = true;
    this.loadingMessage = this.translateService.instant('form.loading');
    this.employmentClient.searchEmployee(this.currentUserEmail).subscribe({
      next: res => {
        this.currentUser = res.entites[0];
        this.formFields = this.getFormFields();
        this.loading = false;
        this.loadingMessage = this.translateService.instant(
          'form.loading_message'
        );
      },
    });
  }

  saveScrollPosition() {
    const scrollPosition = window.scrollY;
    localStorage.setItem('scrollPosition', scrollPosition.toString());
  }

  restoreScrollPosition() {
    const savedPosition = localStorage.getItem('scrollPosition');
    if (savedPosition) {
      setTimeout(() => {
        window.scrollTo(0, +savedPosition);
      }, 0);
    }
  }

  getFormFields() {
    return [
      {
        wrappers: ['form-field'],
        key: 'incidentDescription',
        type: 'textarea',
        props: {
          label: this.translateService.instant(
            'relocation.location_of_origin.incidentDescription'
          ),
          visibleInConfirm: true,
          required: true,
          autosize: true,
          autosizeMinRows: 5,
        },
      },
      {
        key: 'infoConsent',
        type: 'checkbox',
        wrappers: ['no-border'],
        defaultValue: false,
        props: {
          label: this.translateService.instant(
            'relocation.location_of_origin.firstCheckBox'
          ),
          margin: '0px',
          appearance: 'outline',
        },
      },
      {
        key: 'spaceRequestReturn',
        type: 'checkbox',
        wrappers: ['no-border'],
        defaultValue: false,
        props: {
          label: this.translateService.instant(
            'relocation.location_of_origin.secondCheckBox'
          ),
          appearance: 'outline',
        },
      },
      {
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'relocation.location_of_origin.worker'
              ),
            },
          },
          {
            key: 'editor',
            fieldGroup: [
              ...this.employeeFormlyFieldService.autocompleteSearch(
                'ticket.create.placeholder__search_processor',
                null,
                false,
                true,
                this.currentUserEmail,
                null,
                null,
                this.currentUser
              ),
            ],
          },
        ],
      },
      {
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'relocation.location_of_origin.contact_person'
              ),
            },
          },
          {
            key: 'contactPerson',
            fieldGroup: [
              ...this.employeeFormlyFieldService.autocompleteSearch(
                'ticket.create.placeholder__search_processor',
                null,
                false,
                true
              ),
            ],
          },
        ],
      },
      {
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'relocation.location_of_origin.location'
              ),
            },
          },
          {
            key: 'originLocation',
            fieldGroup: [
              {
                wrappers: ['no-border'],
                type: 'radio',
                key: 'telekomCheckOrigin',
                defaultValue: this.isTelekomCheckedOrigin,
                props: {
                  label: this.translateService.instant(
                    'ticket.create.furniture_order.telekom_location'
                  ),
                  showLabel: true,
                  required: true,
                  options: [
                    {
                      value: true,
                      label: this.translateService.instant('shared.yes'),
                    },
                    {
                      value: false,
                      label: this.translateService.instant('shared.no'),
                    },
                  ],
                },
                hooks: {
                  onInit: field => {
                    field?.formControl?.valueChanges.subscribe({
                      next: (value: boolean) => {
                        this.isTelekomCheckedOrigin = value;

                        if (value === true) {
                          this.form
                            .get('originLocation.addressManually')
                            ?.disable();
                          this.form
                            .get('originLocation.searchResult')
                            ?.enable();
                        } else {
                          this.form
                            .get('originLocation.addressManually')
                            ?.enable();
                          this.form
                            .get('originLocation.searchResult')
                            ?.disable();
                        }
                        this.saveScrollPosition();
                        this.formFields = this.getFormFields();
                        this.restoreScrollPosition();
                      },
                    });
                  },
                },
              },
              {
                fieldGroup: formLocationOfOrigin(
                  this.translateService,
                  this.isTelekomCheckedOrigin,
                  this.locationFormlyFieldService
                ),
              },
            ],
          },
        ],
      },
      {
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'relocation.location_of_origin.target'
              ),
            },
          },
          {
            key: 'targetLocation',
            fieldGroup: [
              {
                wrappers: ['no-border'],
                type: 'radio',
                key: 'telekomCheckTarget',
                defaultValue: this.isTelekomCheckedTarget,
                props: {
                  label: this.translateService.instant(
                    'ticket.create.furniture_order.telekom_location'
                  ),
                  showLabel: true,
                  required: true,
                  options: [
                    {
                      value: true,
                      label: this.translateService.instant('shared.yes'),
                    },
                    {
                      value: false,
                      label: this.translateService.instant('shared.no'),
                    },
                  ],
                },
                hooks: {
                  onInit: field => {
                    field?.formControl?.valueChanges.subscribe({
                      next: (value: boolean) => {
                        this.isTelekomCheckedTarget = value;

                        if (value === true) {
                          this.form
                            .get('targetLocation.addressManually')
                            ?.disable();
                          this.form
                            .get('targetLocation.searchResult')
                            ?.enable();
                        } else {
                          this.form
                            .get('targetLocation.addressManually')
                            ?.enable();
                          this.form
                            .get('targetLocation.searchResult')
                            ?.disable();
                        }
                        this.saveScrollPosition();
                        this.formFields = this.getFormFields();
                        this.restoreScrollPosition();
                      },
                    });
                  },
                },
              },
              {
                fieldGroup: formLocationOfOrigin(
                  this.translateService,
                  this.isTelekomCheckedTarget,
                  this.locationFormlyFieldService
                ),
              },
            ],
          },
        ],
      },
      {
        type: 'datepicker',
        key: 'requestedDate',
        props: {
          label: this.translateService.instant('relocation.desired_date.title'),
          description: this.translateService.instant(
            'relocation.desired_date.note'
          ),
          datepickerOptions: {
            min: this.addWorkdays(this.today, 17),
            filter: (d: Moment): boolean => {
              const day = d?.day();
              return day !== 0 && day !== 6;
            },
          },
          required: true,
        },
      },
      ...fileAttachments(this.translateService, true, true),
    ];
  }

  public onEmailChange(emails: string) {
    this.form.get('ccemail')?.setValue(emails as never);
  }

  pressBackToEdit() {
    this.filledForm = null;
  }

  override validateForm() {
    if (
      (this.form.value as any)?.searchLocation?.searchResult?.id === undefined
    ) {
      (this.form.get('searchLocation.searchResult') as any)?.setValue(
        undefined,
        { emitEvent: false }
      );
    }

    for (const control in this.form.controls) {
      if (this.form.controls[control].invalid) {
        console.log('invalid');
      }
    }

    if (this.form.valid) {
      this.showFormSummary();
    } else {
      this.scrollToFirstInvalidControl(true);
    }
  }

  override async showFormSummary() {
    this.filledForm = this.formFields;
    this.cd.detectChanges();
  }

  override async scrollToFirstInvalidControl(repeatScroll: boolean) {
    this.form.markAllAsTouched();
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    try {
      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
        firstInvalidControl.focus();
      }
    } catch (e) {
      console.error('[ticket-create] scrollToFirstInvalidControl:', e);
    } finally {
      if (repeatScroll) {
        setTimeout(() => {
          this.scrollToFirstInvalidControl(false);
        }, 10);
      }
    }
  }

  onSelectUser(_id: string, _initialSearch, user) {
    this.formFields[0].form
      ?.get('userData.firstName')
      ?.setValue(user.firstName);
    this.formFields[0].form?.get('userData.lastName')?.setValue(user.lastName);
    this.formFields[0].form?.get('userData.email')?.setValue(user.email);
    this.formFields[0].form
      ?.get('userData.organisation')
      ?.setValue(user.company);

    this.formFields[0].form?.get('userData.firstName').disable();
    this.formFields[0].form?.get('userData.lastName').disable();
    this.formFields[0].form?.get('userData.email').disable();

    this.isUserSelected = true;
  }

  onDelete() {
    this.formFields[0].form?.get('userData.firstName')?.setValue(null);
    this.formFields[0].form?.get('userData.lastName')?.setValue(null);
    this.formFields[0].form?.get('userData.email')?.setValue(null);
    this.formFields[0].form?.get('userData.organisation')?.setValue(null);

    if (!this.isTelekomChecked) {
      this.formFields[0].form?.get('userData.firstName').enable();
      this.formFields[0].form?.get('userData.lastName').enable();
      this.formFields[0].form?.get('userData.email').enable();
      this.formFields[0].form?.get('userData.roles').enable();
    }

    this.isUserSelected = false;
  }

  addWorkdays(startDate: Date, workdays: number): Date {
    let daysAdded = 0;
    const currentDate = new Date(startDate);

    while (daysAdded < workdays) {
      currentDate.setDate(currentDate.getDate() + 1);

      // Überprüfen, ob der aktuelle Tag ein Arbeitstag ist (Montag bis Freitag)
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++;
      }
    }

    return currentDate;
  }

  public override clientEndpoint = data => {
    return this.incidentClient.createRelocation(data);
  };

  protected override prepareSubmit = data => {
    const d = data;
    const relocation: CreateRelocation = {};
    relocation.incidentDescription = d.incidentDescription;
    relocation.workersCouncilApproved = d.infoConsent ?? false;
    relocation.areaChangeCreated = d.spaceRequestReturn ?? false;
    relocation.originAdressId = d.originLocation?.searchResult?.id ?? null;
    if (d.originLocation?.addressManually) {
      relocation.originFirstName = d.originLocation?.addressManually.firstName;
      relocation.originLastName = d.originLocation?.addressManually.lastName;
      relocation.originStreetNameHouseNumber =
        d.originLocation?.addressManually.streetName;
      relocation.originPostalCode =
        d.originLocation?.addressManually.postalCode;
      relocation.originCity = d.originLocation?.addressManually.city;
    }
    relocation.targetAdressId = d.targetLocation?.searchResult?.id ?? null;

    if (d.targetLocation?.addressManually) {
      relocation.targetFirstName = d.targetLocation?.addressManually.firstName;
      relocation.targetLastName = d.targetLocation?.addressManually.lastName;
      relocation.targetStreetNameHouseNumber =
        d.targetLocation?.addressManually.streetName;
      relocation.targetPostalCode =
        d.targetLocation?.addressManually.postalCode;
      relocation.targetCity = d.targetLocation?.addressManually.city;
    }
    relocation.requestedDate = d.requestedDate;
    relocation.creator = d.editor?.searchResult ?? null;
    relocation.contactPerson = d.contactPerson?.searchResult ?? null;

    return { relocation: relocation };
  };

  public override getStatusPath() {
    return '/dashboard';
  }
}
