<div class="fact-container" #factItem>
  <div class="fact-vertical">
    <app-animated-counter
      [counterNum]="getNumberFromString(factAmount)"
      [duration]="1300">
    </app-animated-counter>
    <span *ngIf="factType !== ''">&nbsp;Mio. m²</span>
    <span>&nbsp;{{ subtitle }}</span>
  </div>
</div>
