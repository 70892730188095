/* eslint-disable @typescript-eslint/no-explicit-any */
import { SmartusComponentsModule } from '@affinis/smartus-components';
import {
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  BaseSelectViewModel,
  CostCenterClient,
  FmChangeReason,
  LocationClient,
} from '@core/api';
import { CostcenterSearchFormlyFieldService } from '@core/services/costcenter-search-formly-field-service/costcenter-search-formly-field.service';
import { OverrideModule } from '@mandants/telekom/override/override.module';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-costcenter-usage',
  standalone: true,
  imports: [
    SmartusComponentsModule,
    TranslateModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    OverrideModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  templateUrl: './edit-costcenterusage.component.html',
  styleUrls: ['./edit-costcenterusage.component.scss'],
})
export class EditCostcenterusageComponent implements OnInit {
  form = new FormGroup({});
  private cd = inject(ChangeDetectorRef);

  formFields: Array<FormlyFieldConfig>;
  changeReasons: BaseSelectViewModel[] = [];

  formModel = {};

  isLoading = false;

  selectedCostcenter: string;

  constructor(
    private translateService: TranslateService,
    private costCenterClient: CostCenterClient,
    private locationClient: LocationClient,
    public dialogRef: MatDialogRef<EditCostcenterusageComponent>,
    private costcenterFormlyFieldService: CostcenterSearchFormlyFieldService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      room: string;
      costcenter: string;
      start: Date;
      end: Date;
      note: string;
      recentChange: string;
      changeReason: BaseSelectViewModel;
      areaInSquareMeters?: number;
      isRoom: boolean;
    }
  ) {}

  ngOnInit() {
    forkJoin({
      changeReasons: this.locationClient.getChangeReasons(),
    }).subscribe({
      next: results => {
        this.changeReasons = results.changeReasons;
        this.initializeFormFields();
      },
    });
  }

  initializeFormFields() {
    this.formFields = [
      {
        type: 'input',
        key: 'room',
        defaultValue: this.data.room,
        props: {
          label: this.data.isRoom
            ? this.translateService.instant(
                'costcenter.costcenter_usage.edit_dialog.room'
              )
            : this.translateService.instant(
                'costcenter.costcenter_usage.working_space'
              ),
          required: false,
        },
      },
      {
        key: 'costcenter',
        fieldGroup: [
          ...this.costcenterFormlyFieldService.autocompleteSearch(
            this.translateService.instant(
              'costcenter.costcenter_usage.edit_dialog.costcenter'
            ),
            null,
            null,
            this.data.costcenter,
            id => this.onSelectCostcenter(id),
            () => this.onDeleteCostcenter(),
            true
          ),
        ],
      },
      {
        type: 'chapter',
        props: {
          chapterTitle: this.translateService.instant(
            'costcenter.costcenter_usage.edit_dialog.usage_period'
          ),
        },
      },
      {
        type: 'flex-layout-col',
        fieldGroup: [
          {
            type: 'datepicker',
            key: 'usageFrom',
            defaultValue: new Date(this.data.start),
            props: {
              label: this.translateService.instant(
                'costcenter.costcenter_usage.edit_dialog.usage_from'
              ),
            },
            validators: {
              dateValidation: {
                expression: c => {
                  const usageToControl = c.parent.get('usageTo');

                  if (
                    usageToControl &&
                    usageToControl.valid === false &&
                    usageToControl.value > c.value
                  ) {
                    // Trigger validation on usageFrom
                    usageToControl.updateValueAndValidity();
                  }
                  if (usageToControl.value && c.value > usageToControl.value) {
                    return false;
                  }
                  return true;
                },
                message: () =>
                  `Kein valides Datum. Das Startdatum muss vor dem Enddatum liegen`,
              },
            },
          },
          {
            type: 'datepicker',
            key: 'usageTo',
            defaultValue:
              new Date(this.data.end.valueOf()) || new Date('01.01.2099'),
            props: {
              label: this.translateService.instant(
                'costcenter.costcenter_usage.edit_dialog.usage_to'
              ),
            },
            validators: {
              dateValidation: {
                expression: c => {
                  const usageFromControl = c.parent.get('usageFrom');

                  if (
                    usageFromControl &&
                    usageFromControl.valid === false &&
                    usageFromControl.value < c.value
                  ) {
                    // Trigger validation on usageFrom
                    usageFromControl.updateValueAndValidity();
                  }

                  if (
                    usageFromControl.value &&
                    c.value < usageFromControl.value
                  ) {
                    return false;
                  }
                  return true;
                },
                message: () =>
                  `Kein valides Datum. Das Enddatum muss nach dem Startdatum liegen`,
              },
            },
          },
          {
            type: 'button',
            key: 'today',
            className: 'form-button',
            props: {
              label: this.translateService.instant(
                'costcenter.costcenter_usage.edit_dialog.today'
              ),
              onClick: () => {
                (this.form.get('usageTo') as any).setValue(new Date());
              },
            },
          },
        ],
      },
      {
        type: 'chapter',
        props: {
          chapterTitle: this.translateService.instant(
            'costcenter.costcenter_usage.edit_dialog.usage_share'
          ),
        },
      },
      {
        type: 'input-with-text',
        key: 'usageArea',
        hide: this.data.areaInSquareMeters ? false : true,
        props: {
          label: this.translateService.instant(
            'costcenter.costcenter_usage.edit_dialog.usage_area'
          ),
          placeholder: this.data.areaInSquareMeters?.toString(),
          required: false,
        },
        hooks: {
          onInit: field => {
            field.props.text = `von ${field.props.placeholder} m²`;
          },
        },
      },
      {
        type: 'input',
        key: 'note',
        defaultValue: this.data.note,
        props: {
          label: this.translateService.instant(
            'costcenter.costcenter_usage.edit_dialog.note'
          ),
          required: false,
        },
      },
      {
        type: 'input',
        key: 'recentChange',
        wrappers: ['readOnly'],
        defaultValue: this.data.recentChange,
        props: {
          label: this.translateService.instant(
            'costcenter.costcenter_usage.edit_dialog.recent_change'
          ),
          required: false,
        },
      },
      {
        type: 'select',
        key: 'changeReason',
        defaultValue: this.data.costcenter,
        props: {
          label: this.translateService.instant(
            'costcenter.costcenter_usage.edit_dialog.change_reason'
          ),
          options: this.changeReasons.map((reason: BaseSelectViewModel) => ({
            value: reason.id,
            label: reason.name,
          })),
          required: false,
        },
      },
    ];
  }

  onSelectCostcenter(id: string) {
    this.selectedCostcenter = id;
  }

  onDeleteCostcenter() {
    this.selectedCostcenter = null;
  }

  onSave() {
    console.log(this.form.value);
    this.dialogRef.close(true);
  }
}
