import { SmartusComponentsModule } from '@affinis/smartus-components';
import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-button',
  standalone: true,
  imports: [SmartusComponentsModule, FormlyModule],
  template: `
    <div>
      <su-button
        [skin]="props.color"
        [type]="'link'"
        (click)="onClick($event)"
        [label]="props.label">
      </su-button>
    </div>
  `,
})
export class FormlyButtonComponent extends FieldType {
  onClick($event: Event) {
    if (this.props.onClick) {
      this.props.onClick($event);
    }
  }
}
