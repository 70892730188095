import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { PageEvent } from '@angular/material/paginator';
import {
  MatOption,
  MatSelect,
  MatSelectModule,
} from '@angular/material/select';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BaseSearchOptions,
  BaseSelectViewModel,
  WorkingSpaceViewModel,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { EditWorkingSpaceService } from '@core/services/edit-working-space/edit-working-space.service';
import { UserService } from '@core/services/oauth-service/user.service';
import { MtxGridColumn, MtxGridModule } from '@ng-matero/extensions/grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ITableConfig,
  ITableGridConfig,
  ITablePagingConfig,
} from '@shared/components/table-base/table-base.types';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { rxSubscriptionContainerMixin } from '@shared/utils/rx-subscription-container.mixin';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, forkJoin, of } from 'rxjs';
import { SafeHtmlPipe } from '../../../../../app/shared/pipes/safe-html.pipe';

export interface IWorkingSpaceViewModel {
  isInactive: boolean;
  APNo: number;
  name: string;
  dummy: boolean;
  dummyKind: string;
  areaType: number;
}

@Component({
  selector: 'app-working-space',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SmartusComponentsModule,
    MtxGridModule,
    TranslateModule,
    MatInputModule,
    MatSelect,
    MatOption,
    MatTooltip,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    SmartusComponentsModule,
    SafeHtmlPipe,
  ],
  templateUrl: './working-space.component.html',
  styleUrl: './working-space.component.scss',
})
export class WorkingSpaceComponent extends rxSubscriptionContainerMixin() {
  headline = this.translateService.instant('locationServices.title');

  private editWorkingSpaceService = inject(EditWorkingSpaceService);
  private cd = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);

  buildingId = this.route.snapshot.paramMap.get('buildingId');
  stageId = this.route.snapshot.paramMap.get('stageId');
  spaceId = this.route.snapshot.paramMap.get('spaceId');
  roomId = this.route.snapshot.paramMap.get('roomId');
  subSpaceId =
    this.buildingId +
    '/' +
    this.stageId +
    '/' +
    this.spaceId +
    '/' +
    this.roomId;

  changeReasons: BaseSelectViewModel[] = [];
  loadedInformation = false;
  selectedWorkingSpace = null;
  isLoading = false;
  currentRoom = '';
  numberOfWorkingPlaces = 0;
  isDataManager = false;
  frozenZoneStartDate = '';
  frozenZoneEndDate = '';
  frozenStatus: boolean = false;
  description = '';
  selectedChangeReasonId = '';
  spaceNumber: any = '';

  workingSpaceListSubject: BehaviorSubject<WorkingSpaceViewModel[]> =
    new BehaviorSubject<WorkingSpaceViewModel[]>([]);
  workingSpacesToAdd: WorkingSpaceViewModel[] = [];
  workingSpaceList$ = this.workingSpaceListSubject.asObservable();
  originalWorkingSpaces: WorkingSpaceViewModel[] = [];

  @ViewChild('checkboxInactiveTemplate', { static: true })
  checkboxInactiveTemplateRef!: TemplateRef<void>;

  @ViewChild('checkboxDummyTemplate', { static: true })
  checkboxDummyTemplateRef!: TemplateRef<void>;

  @ViewChild('inputAPNoTemplate', { static: true })
  inputAPNoTemplateRef!: TemplateRef<void>;

  @ViewChild('inputNameTemplate', { static: true })
  inputNameTemplateRef!: TemplateRef<void>;

  @ViewChild('inputDummyKindTemplate', { static: true })
  inputDummyKindTemplateRef!: TemplateRef<void>;

  @ViewChild('selectTemplate', { static: true })
  selectTemplateRef!: TemplateRef<void>;

  config: ITableConfig = <ITableConfig>{
    grid: <ITableGridConfig>{
      showToolbar: true,
      columnResizable: false,
      paging: <ITablePagingConfig>{
        pageIndex: 0,
        pageSize: 50,
        showPaginator: true,
      },
    },
  };

  query: BaseSearchOptions = {
    pagingOptions: {
      skip:
        this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
      take: this.config.grid.paging.pageSize,
    },
  };

  columns: MtxGridColumn[] = [];
  officeAreaTypes: BaseSelectViewModel[] = [];
  activeToast: ActiveToast<object>;

  constructor(
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    super();
    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);
    this.pushSubscription(
      this.userService
        .checkIfUserHasAnyRole([
          UserRoles.SMARTPORTAL_MODULE_HELPDESK,
          UserRoles.SMARTPORTAL_MODULE_LOCATION,
          UserRoles.SMARTPORTAL_LOCATION_DATA_MANAGER,
          UserRoles.SMARTPORTAL_OCCUPANCY_CREATE,
          UserRoles.SMARTPORTAL_AREAREQUEST_CREATE,
          UserRoles.SMARTPORTAL_AREARETURN_CREATE,
          UserRoles.SMARTPORTAL_RELOCATION_CREATE,
          UserRoles.SMARTPORTAL_DATAMANAGEMENT_GLOBAL,
        ])
        .subscribe(hasRole => (this.isDataManager = hasRole))
    );
  }

  ngOnInit(): void {
    forkJoin({
      frozenZoneStatus: this.editWorkingSpaceService
        .checkFrozenZone()
        .pipe(catchError(() => of({ startDate: '', endDate: '' }))),
      changeReasons: this.editWorkingSpaceService
        .getChangeReasons()
        .pipe(catchError(() => of([]))),
      officeAreaTypes: this.editWorkingSpaceService
        .getOfficeAreaTypes()
        .pipe(catchError(() => of([]))),
      spaceNumber: this.editWorkingSpaceService
        .getSpaceNumberBySpaceId(
          this.subSpaceId.split('/').slice(0, 3).join('/')
        )
        .pipe(catchError(() => of([]))),
    }).subscribe(results => {
      this.changeReasons = results.changeReasons;
      this.officeAreaTypes = results.officeAreaTypes;
      this.spaceNumber = results.spaceNumber.result;

      this.currentRoom =
        this.spaceNumber !== undefined
          ? this.route.snapshot.paramMap.get('spaceId') +
            ' / ' +
            this.spaceNumber
          : this.route.snapshot.paramMap.get('spaceId');

      const frozenZoneData = results.frozenZoneStatus;
      if (
        frozenZoneData.startDate.length > 0 &&
        frozenZoneData.endDate.length > 0
      ) {
        this.frozenZoneStartDate = frozenZoneData.startDate;
        this.frozenZoneEndDate = frozenZoneData.endDate;
        this.frozenStatus = true;
        this.description = this.translateService.instant(
          'edit_room.labels.frozenZone',
          {
            frozenZoneStartDate: this.frozenZoneStartDate,
            frozenZoneEndDate: this.frozenZoneEndDate,
          }
        );
      } else {
        this.frozenStatus = false;
      }

      this.cd.detectChanges();
    });

    this.route.data.subscribe(
      (data: { roomData: IWorkingSpaceViewModel[] }) => {
        if (data && data.roomData) {
          const paginatedList = this.paginateList(data.roomData);
          this.workingSpaceListSubject.next(paginatedList);
          this.originalWorkingSpaces = JSON.parse(
            JSON.stringify(paginatedList)
          );
          this.numberOfWorkingPlaces = data.roomData.length;
        }
        this.cd.markForCheck();
      }
    );

    this.columns = [
      {
        header: this.translateService.instant(
          'locationServices.workingSpaceTable.active'
        ),
        field: 'isActive',
        sortable: true,
        cellTemplate: this.checkboxInactiveTemplateRef,
      },
      {
        header: this.translateService.instant(
          'locationServices.workingSpaceTable.dummy'
        ),
        field: 'dummy',
        sortable: true,
        cellTemplate: this.checkboxDummyTemplateRef,
      },
      {
        header: this.translateService.instant(
          'locationServices.workingSpaceTable.aPNo'
        ),
        field: 'apNo',
        sortable: true,
        cellTemplate: this.inputAPNoTemplateRef,
      },
      {
        header: this.translateService.instant(
          'locationServices.workingSpaceTable.name'
        ),
        field: 'name',
        cellTemplate: this.inputNameTemplateRef,
      },
      {
        header: this.translateService.instant(
          'locationServices.workingSpaceTable.dummyKind'
        ),
        field: 'dummyKind',
        sortable: true,
        cellTemplate: this.inputDummyKindTemplateRef,
      },
      {
        header: this.translateService.instant('Magenta Office Flächenart'),
        field: 'areaType',
        sortable: true,
        cellTemplate: this.selectTemplateRef,
      },
    ];

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadedInformation = false;
        if (this.selectedWorkingSpace) {
          this.onSelect(this.selectedWorkingSpace);
        }
      }
    });
  }

  getNextPage(e: PageEvent) {
    this.config.grid.paging.pageIndex = e.pageIndex;
    this.config.grid.paging.pageSize = e.pageSize;

    this.query = {
      ...this.query,
      pagingOptions: {
        skip:
          this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
        take: this.config.grid.paging.pageSize,
      },
    };
  }

  public addWorkingSpace() {
    this.toastr.remove(this.activeToast?.toastId);

    const hasNonNullValue = Object.values(
      this.workingSpaceListSubject.value[
        this.workingSpaceListSubject.value.length - 1
      ]
    ).some(value => value !== null);

    if (hasNonNullValue) {
      if (
        this.numberOfWorkingPlaces < this.workingSpaceListSubject.value.length
      ) {
        return;
      }

      var workingPlacesToAdd =
        this.numberOfWorkingPlaces -
          this.workingSpaceListSubject.value.length ===
        0
          ? 1
          : this.numberOfWorkingPlaces -
            this.workingSpaceListSubject.value.length;

      if (
        workingPlacesToAdd + this.workingSpaceListSubject.value.length >=
        100
      ) {
        return;
      }

      for (var i = 0; i < workingPlacesToAdd; i++) {
        const maxApNo = this.workingSpaceListSubject.value.reduce(
          (max, item) => {
            const currentApNo =
              item.apNo !== null ? parseInt(item.apNo, 10) : 0;
            return currentApNo > max ? currentApNo : max;
          },
          0
        );
        const maxName = this.workingSpaceListSubject.value.reduce(
          (maxName, item) => {
            const namePart = item.name || '';
            const match = namePart.match(/(\d+)$/);
            const currentNumber = match ? parseInt(match[1], 10) : 0;
            const maxNumber = maxName.match(/(\d+)$/)
              ? parseInt(maxName.match(/(\d+)$/)[1], 10)
              : 0;

            return currentNumber > maxNumber ? namePart : maxName;
          },
          ''
        );

        const currentNumber = maxName.match(/(\d+)$/)
          ? parseInt(maxName.match(/(\d+)$/)[1], 10)
          : 0;
        const newNumber = (currentNumber + 1).toString();

        const numberLength = maxName.match(/(\d+)$/)
          ? maxName.match(/(\d+)$/)[1].length
          : 1;
        const formattedNewNumber = newNumber.padStart(numberLength, '0');

        const newName = `${maxName.replace(/(\d+)$/, '')}${formattedNewNumber}`;

        const newWorkingSpace = {
          isActive: null,
          apNo: (maxApNo + 1).toString(),
          name: newName,
          isDummy: null,
          dummyKind: null,
          magentaSpaceType: null,
          subSpaceId: this.subSpaceId,
        };

        this.workingSpaceListSubject.next([
          ...this.workingSpaceListSubject.value,
          newWorkingSpace,
        ]);

        this.workingSpacesToAdd = [...this.workingSpacesToAdd, newWorkingSpace];
      }
      this.numberOfWorkingPlaces = this.workingSpaceListSubject.value.length;
    }
  }

  private paginateList<T>(list: T[]): T[] {
    const pageSize = this.config.grid.paging.pageSize;
    const pageIndex = this.config.grid.paging.pageIndex;

    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;

    return list.slice(startIndex, endIndex);
  }

  public onSelect(_archObjId: string, _onInitSearch?: boolean): void {
    this.isLoading = true;
    // this.locationClient.getArchobjById(archObjId).subscribe({
    //   next: (response: ArchObjHierarchyViewModel) => {
    //     console.log('[MyLocationComponent] onSelect:', response);
    //     this.selectedLocation = response;
    //     this.tabs[2].disabled = false;
    //     this.tabs[3].disabled = false;
    //     if (this.tabs[4]) {
    //       this.tabs[4].disabled = false;
    //     }
    //     this.isBuildingFaultButtonDisabled = false;
    //     if (!onInitSearch) {
    //       this.activeTab = this.tabs[2].id;
    //     }
    //   },
    //   error: (error: any) => this.handleError(error),
    //   complete: () => {
    //     this.isLoading = false;
    //     if (
    //       window.location.hash === '#information' &&
    //       !this.loadedInformation
    //     ) {
    //       this.activeTab = this.tabs[2].id;
    //       this.loadedInformation = true;
    //     }
    //   },
    //});
    //this.archObjId = archObjId;

    //this.getLocationList();
  }

  public IsSpaceTypeNull(workingSpacesToUpdate, workingSpacesToAdd): boolean {
    const hasInvalidMagentaSpaceType = workingSpacesToUpdate.some(
      space =>
        space.magentaSpaceType === null || space.magentaSpaceType === undefined
    );

    const hasInvalidMagentaSpaceTypeInAdd = workingSpacesToAdd.some(
      space =>
        space.magentaSpaceType === null || space.magentaSpaceType === undefined
    );

    return hasInvalidMagentaSpaceType || hasInvalidMagentaSpaceTypeInAdd;
  }

  public IsDummyTypeNotSet(workingSpacesToUpdate, workingSpacesToAdd): boolean {
    const hasNotSetDummyTypeForUpdates = workingSpacesToUpdate.some(
      space =>
        space.isDummy !== null &&
        space.isDummy !== undefined &&
        space.isDummy === true &&
        (space.dummyKind === null ||
          space.dummyKind === undefined ||
          space.dummyKind === '')
    );

    const hasNotSetDummyTypeForCreates = workingSpacesToAdd.some(
      space =>
        space.isDummy !== null &&
        space.isDummy !== undefined &&
        space.isDummy === true &&
        (space.dummyKind === null ||
          space.dummyKind === undefined ||
          space.dummyKind === '')
    );

    return hasNotSetDummyTypeForUpdates || hasNotSetDummyTypeForCreates;
  }

  public save() {
    const updatedWorkingSpaces = this.workingSpaceListSubject.value
      .filter((space, index) => {
        const original = this.originalWorkingSpaces[index];
        const isInWorkingSpacesToAdd = this.workingSpacesToAdd.some(
          ws => ws.apNo === space.apNo && ws.name === space.name
        );
        return (
          !isInWorkingSpacesToAdd &&
          (space.isActive !== original.isActive ||
            space.apNo !== original.apNo ||
            space.name !== original.name ||
            space.isDummy !== original.isDummy ||
            space.dummyKind !== original.dummyKind ||
            space.magentaSpaceType !== original.magentaSpaceType)
        );
      })
      .map(space => ({
        ...space,
        changeReason: this.selectedChangeReasonId,
        roomId: this.roomId,
      }));

    if (
      this.IsSpaceTypeNull(updatedWorkingSpaces, this.workingSpacesToAdd) ===
      true
    ) {
      this.toastr.error(
        this.translateService.instant('locationServices.fillAllSpaceTypes')
      );
      return;
    }

    if (
      this.IsDummyTypeNotSet(updatedWorkingSpaces, this.workingSpacesToAdd) ===
      true
    ) {
      this.toastr.error(
        this.translateService.instant('locationServices.fillAllDummyTypes')
      );
      return;
    }

    const create$ =
      this.workingSpacesToAdd.length > 0
        ? this.editWorkingSpaceService.createWorkingSpaces(
            this.workingSpacesToAdd.map(space => ({
              ...space,
              changeReason: this.selectedChangeReasonId,
              roomId: this.roomId,
            }))
          )
        : of(null);

    const update$ =
      updatedWorkingSpaces.length > 0
        ? this.editWorkingSpaceService.updateWorkingSpaces(updatedWorkingSpaces)
        : of(null);

    forkJoin([create$, update$]).subscribe({
      next: () => {
        this.toastr.success(
          this.translateService.instant('locationServices.successfullySaved')
        );
      },
      error: err => {
        this.toastr.error(
          this.translateService.instant('locationServices.errorOccurred')
        );
      },
    });
  }

  public onCancelClick() {
    this.router.navigate(['/dashboard']);
  }
}
