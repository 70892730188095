<su-headline h="h1" skin="primary">{{headline}}</su-headline>
<div
  class="sub-headline"
  [innerHtml]="'ticket.status.p__info' | translate"></div>

<su-card [card]="card">
  <div card-content>
    <form (ngSubmit)="submit()" [formGroup]="form">
      <formly-form
        [form]="form"
        [fields]="formFields"
        [model]="formModel"></formly-form>
    </form>
  </div>
</su-card>

<app-spinner-overlay [show]="loading"></app-spinner-overlay>
