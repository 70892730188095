import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TicketGetStatusBaseComponent } from '../base/ticket-get-status';
import { RegexpEmail } from '@shared/utils/regex';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-get-status-default',
  templateUrl: '../base/ticket-get-status.html',
  styleUrls: [],
})
export class TicketGetStatusDefaultComponent extends TicketGetStatusBaseComponent {
  constructor(
    router: Router,
    route: ActivatedRoute,
    translateService: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    super(router, route, translateService);
    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);
  }

  override headline = this.translateService.instant('ticket.status.title');

  override card = {
    header: {
      title: {
        label: '',
      },
    },
    footer: {
      button1: {
        label: 'Weiter',
        onClick: () => this.submit(),
        disabled: false,
      },
    },
  };
  override formFields: Array<FormlyFieldConfig> = [
    {
      fieldGroup: [
        {
          type: 'input',
          key: 'id',
          props: {
            label: this.translateService.instant(
              'ticket.status.label__incident_guid'
            ),
            required: true,
            attributes: {
              style: 'font-family: monospace; font-size:1.5em',
            },
          },
        },
      ],
    },
    {
      fieldGroup: [
        {
          type: 'input',
          key: 'email',
          props: {
            label: this.translateService.instant('ticket.status.label__email'),
            required: true,
            pattern: RegexpEmail,
          },
          validation: {
            messages: {
              pattern: (_err, _field: FormlyFieldConfig) =>
                this.translateService.instant('formErrors.email.pattern'),
            },
          },
        },
      ],
    },
  ];
}
