<div class="content-body">
  <app-quick-text
    [title]="'Unsere Aufgaben'"
    [imgUrl]="'assets/images/undraw_who1.svg'"
    class="our-tasks-quick-text">
    <p>
      Telekom RealEstate ist für die laufende Verwaltung von schwerpunktmäßig
      Büro- und Technikimmobilien verantwortlich. Die wichtigste Aufgabe ist
      dabei, die Flächenbedarfe der Deutschen Telekom AG und ihrer
      Tochtergesellschaften zu bestimmen und zu bedienen.
    </p>
  </app-quick-text>
  <scale-tab-nav style="padding: 10px">
    <scale-tab-header slot="tab">Portfolio Management</scale-tab-header>
    <scale-tab-panel slot="panel">
      <div class="panel">
        <div class="panel-row">
          <div class="panel-column">
            <p>
              Bedingt durch den kontinuierlichen Wandel des
              Telekommunikationsumfeldes sorgen das Berichtswesen und die
              laufende Kontrolle zentraler Portfoliokennzahlen für eine stetige
              Anpassung des Immobilienportfolios.
            </p>
          </div>
        </div>
      </div>
    </scale-tab-panel>
    <scale-tab-header slot="tab">Interner Dienstleister</scale-tab-header>
    <scale-tab-panel slot="panel">
      <div class="panel">
        <div class="panel-row">
          <div class="panel-column">
            <p>
              Telekom RealEstate schafft für den Konzern die Rahmenbedingungen
              zur Errichtung der besten Festnetz- und Mobilfunk-Netze. Die
              Mitarbeitenden bekommen in unseren Immobilien den Raum für die
              Kreativität, die zu neuen Ideen und Innovationen führt, um
              Kundenwünsche in Zukunft bestmöglich zu erfüllen. Telekom
              RealEstate handelt partnerschaftlich mit allen Dienstleistern im
              Bau- und Facility-Management.
            </p>
          </div>
        </div>
      </div>
    </scale-tab-panel>
  </scale-tab-nav>
</div>
