import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaultAppSetings } from './settings-service.types';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private options =
    JSON.parse(localStorage.getItem('appsettings') || 'null') ||
    defaultAppSetings;

  private notify$ = new BehaviorSubject<any>({});

  get notify(): Observable<any> {
    return this.notify$.asObservable();
  }

  setLayout(options?: AppSettings): AppSettings {
    this.options = Object.assign(defaultAppSetings, options);
    return this.options;
  }

  setNavState(type: string, value: boolean) {
    this.notify$.next({ type, value } as any);
  }

  getOptions(): AppSettings {
    return this.options;
  }

  get language(): string {
    return this.options.language || 'de-DE';
  }

  /**
   * Stores selected lanuage in local storage
   * @param lang long language string like 'de-DE' or 'en-US'
   */
  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
    this.saveAppSettings();
  }

  saveAppSettings() {
    localStorage.setItem('appsettings', JSON.stringify(this.options));
  }
}
