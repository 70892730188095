import { Component, OnInit } from '@angular/core';
import { MenuService } from '@core/services/menu-service/menu.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { UserService } from '@core/services/oauth-service/user.service';

@Component({
  selector: 'app-dummy-dashboard',
  templateUrl: '../shared/dashboard.component.html',
  styleUrls: ['../shared/dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  menu = null;
  userInfo = '';
  viewTitle = 'Herzlich Willkommen im Portal';
  linksTitle = '';
  links: Array<any> = [];
  linksAnonymous: Array<any> = [
    {
      label: 'Anmeldung',
      items: [
        {
          id: 'login',
          label: 'Login',
          icon: '',
          onClick: () => {
            this.userService.login();
          },
        },
      ],
    },
    {
      label: 'Feedback',
      items: [
        {
          id: 'feedback',
          label: 'Support',
          icon: '',
          onClick: () => {
            this.router.navigateByUrl('/feedback');
          },
        },
      ],
    },
  ];
  cardsTitle = 'Ihre Meldungen';
  cards: Array<any> = [];

  constructor(
    private menuService: MenuService,
    private router: Router,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private breadcrumbService: BreadcrumbService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.menuService.menuChange$.subscribe({
      next: data => {
        this.menu = this.mapMenu(data);
      },
    });
    this.breadcrumbService.resetBreadcrumbs();

    this.userInfo = this.userService.accountDisplayName;
  }

  mapMenu(menu: any) {
    if (menu['side-nav'] && menu['side-nav'].length > 0) {
      const sideNav = [];
      menu['side-nav'].forEach(item => {
        const subItems = [];
        if (item.items && item.items.length > 0) {
          item.items.forEach(subItem => {
            subItems.push({
              id: subItem.id,
              label: subItem.label,
              icon: subItem.icon && subItem.icon !== '' ? subItem.icon : 'box',
              onClick: () => {
                this.router.navigateByUrl(subItem.link, {
                  state: { menuId: subItem.id },
                });
              },
            });
          });
        }
        sideNav.push({
          label: item.label,
          items: subItems,
        });
      });
      this.links = sideNav;
    }
  }
}
