<div class="dialog">
  <form [formGroup]="form" (ngSubmit)="onOk()" class="form-container">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <div mat-dialog-actions class="button-container">
      <su-button
        skin="secondary"
        [label]="'form.cancel' | translate"
        (onClick)="onDismiss()"></su-button>
      <su-button
        skin="primary"
        [label]="'form.save' | translate"
        type="submit"></su-button>
      <app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
    </div>
  </form>
</div>
