// https://stackblitz.com/edit/angular-mfsxfi?file=src%2Fapp%2Faddons.wrapper.ts

import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-wrapper-addons',
  template: `
    <div class="info-card_field" data-debug="formly-read-only-wrapper">
      <div class="label">{{ props.label }}:</div>
      <div [innerHtml]="value || '--'"></div>
    </div>
  `,
})
export class FormlyReadOnlyWrapperComponent
  extends FieldWrapper
  implements OnInit
{
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  override fieldComponent!: ViewContainerRef;

  override field: FormlyFieldConfig;

  tmpl = '';

  private valueFormatter: (value: any) => any;

  constructor(private santitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    if (this.props.valueFormatter) {
      this.valueFormatter = this.props.valueFormatter;
    }
    // TODO
    // if (this.props.readOnly && this.props.readOnlyTemplate && this.props.readOnlyTemplate !== '') {
    //   this.tmpl = this.props.readOnlyTemplate(this.field);
    // }
  }

  get value(): string {
    return this.valueFormatter
      ? this.santitizer.bypassSecurityTrustHtml(
          this.valueFormatter(this.formControl.value)
        )
      : this.formControl.value;
  }
}
