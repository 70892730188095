import { NgModule, Optional, SkipSelf } from '@angular/core';
// import { throwIfAlreadyLoaded } from '@core/module-import-guard';
// import { environment } from '@env/environment';
import { environment } from '@core/environments/environment';
import {
  API_BASE_URL,
  AccessOrdererClient,
  ApprovalClient,
  AuthClient,
  ContactClient,
  CostCenterClient,
  EmploymentClient,
  FileClient,
  IncidentClient,
  LocationClient,
  MaintenanceClient,
  OptionSetClient,
  OrganisationClient,
  RoleClient,
  StorageClient,
  TaskClient,
  RentalListingClient,
  UserAdministrationClient,
  UserClient,
  WorkingSpaceClient
} from './smart-portal.api';

const PROVIDERS = [
  { provide: API_BASE_URL, useFactory: () => environment.baseUrl },
  IncidentClient,
  AuthClient,
  FileClient,
  UserClient,
  TaskClient,
  OptionSetClient,
  LocationClient,
  EmploymentClient,
  StorageClient,
  MaintenanceClient,
  AccessOrdererClient,
  RentalListingClient,
  RoleClient,
  ContactClient,
  UserAdministrationClient,
  OrganisationClient,
  ApprovalClient,
  CostCenterClient,
  WorkingSpaceClient
];

@NgModule({
  declarations: [],
  imports: [],
  providers: [...PROVIDERS],
})
export class SmartPortalApiModule {
  constructor(@Optional() @SkipSelf() _parentModule: SmartPortalApiModule) {
    // throwIfAlreadyLoaded(parentModule, 'SmartPortalApiModule');
  }
}
