import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb-service/BreadcrumbService';
import { BehaviorSubject, catchError, forkJoin, Observable, of } from 'rxjs';
import { SmartusComponentsModule } from '@affinis/smartus-components';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import {
  BaseSelectViewModel,
  BuildingMaintenanceViewModel,
  UpdateBuildingRequest,
} from '@core/api';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';
import { OverrideModule } from '../../../../app/override/override.module';
import { SafeHtml } from '@angular/platform-browser';
import { MaintenanceBuildingComponent } from './maintenance-building/maintenance-building.component';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceBuildingAddNewComponent } from './maintenance-building/maintenance-building-add-new/maintenance-building-add-new.component';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { UserService } from '@core/services/oauth-service/user.service';
import { EditBuildService } from '@core/services/edit-building/edit-building.service';
import { ToastrService } from 'ngx-toastr';
import { SafeHtmlPipe } from '../../../../app/shared/pipes/safe-html.pipe';

@Component({
  selector: 'app-edit-building',
  standalone: true,
  imports: [
    CommonModule,
    SmartusComponentsModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    FormlyModule,
    OverrideModule,
    MaintenanceBuildingComponent,
    SafeHtmlPipe,
  ],
  templateUrl: './edit-building.component.html',
  styleUrl: './edit-building.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBuildingComponent implements AfterViewInit, OnInit {
  private route = inject(ActivatedRoute);
  private translateService = inject(TranslateService);
  private breadcrumbsService = inject(BreadcrumbService);
  private cd = inject(ChangeDetectorRef);
  private fb = inject(FormBuilder);
  public dialogRef: MatDialogRef<EditBuildingComponent>;
  private dialog = inject(MatDialog);
  private userService = inject(UserService);
  private editBuildingService = inject(EditBuildService);
  private toastr = inject(ToastrService);

  formModel: {
    aoid: string;
    name: string;
    buildingClass: string;
    wmCluster: string;
    changeReason: BaseSelectViewModel[];
  } = {
    aoid: '',
    name: '',
    buildingClass: '',
    wmCluster: '',
    changeReason: [],
  };
  form: FormGroup;
  formFields: FormlyFieldConfig[];

  private initialBuildingUse: string = '';
  addressString = '';
  buildingUseOptions$: Observable<{ value: string; label: string }[]> = of([]);

  frozenZoneStartDate = '';
  frozenZoneEndDate = '';
  frozenStatus: boolean = false;
  description = '';
  descriptionStyle =
    'background-color: #E20074; color: white; border-radius: 5px; padding: 15px;';
  descriptionContainerStyle =
    'width: 100%; max-width: 840px; border-radius: 15px; padding: 0 15px;';

  buildData: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
    footer: {
      button2: {
        label: this.translateService.instant('edit_stage.labels.cancel'),
        onClick: () => {
          window.close();
        },
        disabled: false,
        type: 'button',
      },
      button1: {
        label: this.translateService.instant('edit_stage.labels.save'),
        onClick: () => {
          this.onSubmit();
        },
        disabled: true,
        type: 'submit',
      },
    },
  };

  buildMaintenance: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  tabs: Tab[] = [
    {
      label: this.translateService.instant(
        'buildingMaintenance.labels.buildingData'
      ),
      id: 'buildingData',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: this.translateService.instant(
        'buildingMaintenance.labels.buildingMaintenance'
      ),
      id: 'buildingMaintenance',
      onClick: (id: string) => this.onTabClick(id),
    },
  ];

  activeTab = this.tabs[0].id;
  isLoading: boolean = false;
  clickableLink: SafeHtml;
  title: string;
  buildingClasses: any;
  dataTableBuildMaintenance$: BehaviorSubject<BuildingMaintenanceViewModel> =
    new BehaviorSubject<BuildingMaintenanceViewModel>(null);
  buildingId: string;
  changeReasons: BaseSelectViewModel[] = [];
  wmvClusters: BaseSelectViewModel[] = [];

  initialFormModel: {
    warmRentCluster: string;
    changeReason: string;
  };

  ngOnInit(): void {
    this.activeTab = this.tabs[0].id;
    this.breadcrumbsService.setBreadcrumbs([
      {
        label: this.translateService.instant(
          'buildingMaintenance.labels.title'
        ),
      },
    ]);

    forkJoin({
      frozenZoneStatus: this.editBuildingService
        .checkFrozenZone()
        .pipe(catchError(() => of({ startDate: '', endDate: '' }))),
      wmvClusters: this.editBuildingService.getWmvClusters(),
      changeReasons: this.editBuildingService.getChangeReasons(),
    }).subscribe({
      next: results => {
        this.wmvClusters = results.wmvClusters;
        this.changeReasons = results.changeReasons;

        const frozenZoneData = results.frozenZoneStatus;
        if (
          frozenZoneData.startDate.length > 0 &&
          frozenZoneData.endDate.length > 0
        ) {
          this.frozenZoneStartDate = frozenZoneData.startDate;
          this.frozenZoneEndDate = frozenZoneData.endDate;
          this.frozenStatus = true;
          this.description = this.translateService.instant(
            'buildingMaintenance.labels.frozenZone',
            {
              frozenZoneStartDate: this.frozenZoneStartDate,
              frozenZoneEndDate: this.frozenZoneEndDate,
            }
          );
        } else {
          this.formFields = this.getFormFields();
          this.frozenStatus = false;
        }

        if (this.form && this.frozenStatus) {
          this.disableFormFields();
        }

        this.cd.detectChanges();
      },
    });

    this.initializeFormData();

    this.route.paramMap.subscribe(params => {
      this.buildingId = params.get('buildingId')!;
    });

    this.formFields = this.getFormFields();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cd.detectChanges();
    }, 0);
  }

  initializeFormData(): void {
    this.route.data.subscribe(
      (data: {
        building: {
          buildingId: string;
          //UPDATE THE TYPE FOR "BUILDING"
          building: any;
        };
      }) => {
        if (data && data.building) {
          const building = data.building.building;
          this.addressString = `${building.street || '--'}, ${building.postalCode || '--'} ${building.city || '--'}`;
          this.buildingClasses = building.buildingClasses;
          this.dataTableBuildMaintenance$.next(building);
          this.formModel = {
            aoid: building.aoid || '--',
            name: building.name || '--',
            buildingClass: building.buildingClass || '--',
            wmCluster: building.wmCluster || '--',
            changeReason: [],
          };

          this.title = this.formModel.buildingClass;
          this.initializeForm();

          if (this.frozenStatus) {
            this.disableFormFields();
          }

          this.cd.detectChanges();
        }
      }
    );
  }

  disableFormFields() {
    if (this.frozenStatus && this.form) {
      this.form.disable();
      this.cd.markForCheck();
    }
  }

  initializeForm(): void {
    this.form = this.fb.group({
      aoid: [this.formModel?.aoid || '--'],
      name: [this.formModel?.name || '--'],
      buildingUse: [this.initialBuildingUse || '--'],
      wmCluster: [this.formModel?.wmCluster || '--'],
      changeReason: [this.formModel?.changeReason || ''],
    });

    if (this.frozenStatus) {
      this.disableFormFields();
    }

    this.initialFormModel = {
      warmRentCluster: this.formModel?.wmCluster || '--',
      changeReason: this.formModel?.changeReason?.[0]?.id || '',
    };

    this.form.valueChanges.subscribe(() => {
      this.updateButtonState();
    });
  }

  onTabClick(value: string) {
    this.isLoading = true;
    this.activeTab = value;
    this.cd.detectChanges();
    setTimeout(() => {
      this.isLoading = false;
      this.cd.detectChanges();
    }, 500);
  }

  updateButtonState(): void {
    const currentWarmRentCluster = this.form.get('wmCluster')?.value;
    const currentChangeReason = this.form.get('changeReason')?.value;

    const isWarmRentClusterNotEmpty =
      typeof currentWarmRentCluster === 'string' &&
      currentWarmRentCluster.trim() !== '';
    const isChangeReasonNotEmpty =
      typeof currentChangeReason === 'string' &&
      currentChangeReason.trim() !== '';

    const isWarmRentClusterModified =
      currentWarmRentCluster !== this.initialFormModel.warmRentCluster;
    const isChangeReasonModified =
      currentChangeReason !== this.initialFormModel.changeReason;

    const isFormValid =
      isWarmRentClusterNotEmpty &&
      isChangeReasonNotEmpty &&
      isWarmRentClusterModified &&
      isChangeReasonModified;
    this.buildData.footer.button1.disabled = !isFormValid;
    this.cd.detectChanges();
  }

  getFormFields(): FormlyFieldConfig[] {
    const loggedUserRoles = this.userService.getCurrentUserRoles();
    const isEditable = loggedUserRoles.includes(
      UserRoles.SMARTPORTAL_REM_DATENPFLEGE
    );

    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'aoid',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.labels.aoid'
              ),
            },
          },
          {
            key: 'name',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.labels.building'
              ),
            },
          },
          {
            key: 'buildingClass',
            type: 'link-edit-tab',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.labels.buildingUsages'
              ),
              value: this.formModel.buildingClass,
              onClick: () => this.openAddModal(this.buildingClasses),
            },
          },
          {
            key: 'wmCluster',
            type: 'select',
            props: {
              label: this.translate(
                'buildingMaintenance.labels.warmRentCluster'
              ),
              options: this.wmvClusters.map((cluster: BaseSelectViewModel) => ({
                value: cluster.name,
                label: cluster.id,
              })),
              required: true,
              disabled: !isEditable,
            },
          },
          {
            key: 'changeReason',
            type: 'select',
            props: {
              label: this.translate('buildingMaintenance.table.changeReason'),
              options: this.changeReasons.map(
                (reason: BaseSelectViewModel) => ({
                  value: reason.id,
                  label: reason.name,
                })
              ),
              required: true,
            },
          },
        ],
      },
    ];
  }

  openAddModal(item: any): void {
    const dialogRef = this.dialog.open(MaintenanceBuildingAddNewComponent, {
      minWidth: '40vw',
      minHeight: '35vh',
      data: {
        buildingClasses: item,
        buildingId: this.buildingId,
        changeReasons: this.changeReasons,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshBuildingData();
      }
    });
  }

  refreshBuildingData(): void {
    this.editBuildingService
      .getBuildingMaintenanceInformation(this.buildingId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.updateBuildingData(data);
            this.dataTableBuildMaintenance$.next(data);
          }
        },
      });
  }

  updateBuildingData(building: any): void {
    this.addressString = `${building.street || '--'}, ${building.postalCode || '--'}, ${building.city || '--'}`;
    this.buildingClasses = building.buildingClasses;

    if (this.dataTableBuildMaintenance$) {
      this.dataTableBuildMaintenance$.next(building);
    }

    this.formModel = {
      aoid: building.aoid || '--',
      name: building.name || '--',
      buildingClass: building.buildingClass || '--',
      wmCluster: building.wmCluster || '--',
      changeReason: [],
    };

    this.title = this.formModel.buildingClass;
    this.initializeForm();

    this.cd.detectChanges();
  }

  onSubmit(): void {
    this.isLoading = true;
    const request: UpdateBuildingRequest = {
      id: this.buildingId,
      wmCluster: this.form.value.wmCluster,
      changeReason: this.form.value.changeReason,
    };

    this.editBuildingService.updateBuilding(request).subscribe({
      next: () => {
        this.isLoading = false;
        this.refreshBuildingData();
        this.toastr.success(
          this.translateService.instant('buildingMaintenance.toastr.success')
        );
        this.buildData.footer.button1.disabled = true;

        this.form.reset(
          {
            changeReason: '',
          },
          { emitEvent: false }
        );
      },
      error: () => {
        this.isLoading = false;
        this.toastr.error(
          this.translateService.instant('buildingMaintenance.toastr.error')
        );
      },
    });
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
