// https://regexr.com/2rhq7 RFC28822 standard Email validation
export const RegexpPhoneInternational =
  /^\s*(?:\+?(\d{1,3}))?([- /\\(]*(\d{3})[- /\\)]*)?((\d{3})[- /\\]*(\d{2,4})(?:[-x /\\]*(\d+))?)\s*$/;
export const RegexpCountryCode = /^(\+|00)\d{1,4}$/;
export const RegexpEmail = /^[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const RegexpMultipleEmail =
  // eslint-disable-next-line
  /^([\w\-\.]+@[a-zA-Z0-9\-]+(\.[a-zA-Z]{2,4})+(\;[\w\-\.]+@[a-zA-Z0-9\-]+(\.[a-zA-Z]{2,4})+)*)/;
export const RegexpMultipleEmailComma =
  // eslint-disable-next-line
  /^([\w\-\.]+@[a-zA-Z0-9\-]+(\.[a-zA-Z]{2,4})+(\,[\w\-\.]+@[a-zA-Z0-9\-]+(\.[a-zA-Z]{2,4})+)*)$/;
export const RegexpPhoneDE = /^(\+49|0|0049)+([\s/|-]?\d+)*$/;
export const RegexpPhoneInt = /^(?:\+49|00\d{2})\s?\d{1,21}$/;
export const RegexpZipCodeDE = /^\d{5}$/;
export const RegexpStreetname = /^[a-zA-Z0-9äöüÄÖÜ!.\-_ß\s&]+$/;
export const RegexpLocationname = /^[ A-Za-z0-9_.,/ß&\-äÄöÖüÜ'"]*$/;
export const RegexpPlotnumber = /^[A-Za-z0-9]*$/;
export const RegexpSingleDigitInteger = /^\d$/;
