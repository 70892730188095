<div mat-dialog-title>
  <su-headline h="h3" skin="primary">{{ data.title }}</su-headline>
</div>

<div mat-dialog-content>
  <form [formGroup]="form">
    <formly-form
    [form]="form"
    [fields]="formFields"
    [model]="formModel"></formly-form>
  </form>
  <div *ngIf="data.endAccessOrderer"><p>Hinweis: Wenn Sie die Zutrittsbestellerzuweisung beenden, wird diese sofort, zum heutigen Datum beendet. Die bestehende Gülltigkeit wird somit überschrieben.</p></div>
</div>
<div mat-dialog-actions>
  <su-button
    skin="secondary"
    [label]="'shared.cancel' | translate"
    mat-dialog-close></su-button>

  <su-button
    skin="primary"
    [label]="data.confirmText"
    (onClick)="dialogRef.close(true)"></su-button>
</div>
