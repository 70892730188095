import { Injectable, inject } from '@angular/core';
import {
  BaseSelectViewModel,
  BuildingMaintenanceViewModel,
  FrozenZoneViewModel,
  LocationClient,
  UpdateFloorRequest,
} from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditStageService {
  private locationClient = inject(LocationClient);

  getBuildingMaintenanceInformation(
    stageId: string | undefined
  ): Observable<BuildingMaintenanceViewModel> {
    return this.locationClient.getBuildingMaintenanceInformation(stageId);
  }

  updateFloor(request: UpdateFloorRequest): Observable<void> {
    return this.locationClient.updateFloor(request);
  }

  getChangeReasons(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getChangeReasons();
  }

  checkFrozenZone(): Observable<FrozenZoneViewModel> {
    return this.locationClient.checkFrozenZone();
  }
}
