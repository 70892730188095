export enum RoutePath {
  ACCESS_ORDERER = 'access-orderer',
  APPROVALS = 'approvals',
  AREA_VALUE = 'area-value',
  AUTH_CALLBACK = 'authentication/callback',
  CLEARING_INDICATOR = 'clearing-indicator',
  DASHBOARD = 'dashboard',
  DATA_MAINTENANCE = 'datamaintenance',
  DATA_MAINTENANCE_COSTCENTER_USAGE = 'datamaintenance/costcenterusage',
  DELETION_REQUEST = 'deletion-request',
  DETAILS = 'details',
  DOCUMENTS = 'documents',
  DOCUPLOAD = 'docupload',
  EDIT_BUILDING = 'edit-building',
  EDIT_STAGE = 'edit-stage',
  EDIT_ROOM = 'edit-room',
  FEEDBACK = 'feedback',
  FURNITUREORDER = 'furniture-order',
  HELPDESK_MYTICKETS = 'helpdesk/my-tickets',
  HELPDESK_MYTICKETS_DETAILS = 'helpdesk/my-tickets/:incidentId',
  IMPRINT = 'imprint',
  LANDING = 'landing',
  LOCATION = 'location/my-location',
  LOGOUT = 'logout',
  MAINTENANCE = 'maintenance',
  NONE = '',
  OVERBOOKED_ROOMS = 'overbooked-rooms',
  PRIVACY = 'privacy',
  PRIVACY_GENERAL = 'datenschutz/allgemeine-datenschutzhinweise-der-telekom-ag-group-supply-services-real-estate',
  PRIVACY_REM = 'datenschutz',
  PROFILE_DEFAULT = 'profile',
  PROFILE_JSON = 'profile/json',
  RELOCATION = 'relocation',
  SPACEREQUEST = 'space-request',
  SPACERETURN = 'space-return',
  TASK = 'task/details/:id',
  TASK_VIEW = 'task/viewDetails/:id',
  TASKS = 'tasks',
  TICKET_CREATE = 'create',
  TICKET_CREATE_HELPDESK = 'create/helpdesk',
  TICKET_CREATE_ORDER_ACCESS = 'create/order-access',
  TICKETS = 'tickets',
  TICKETSTATUS = 'ticket/status',
  TICKETSTATUS_ITEM = 'ticket/status/:id',
  USER_ADMINISTRATION = 'user-administration',
  USER_CREATION = 'user-create',
  USER_PERMISSIONS = 'user-permissions',
  WORKING_SPACE = 'working-space',
  WEBSITE_EDIT_RENTAL = 'website-edit/rental',
}
