import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { EditStageService } from '@core/services/edit-stage/edit-stage.service';
import {
  BuildingFloorViewModel,
  BuildingMaintenanceViewModel,
} from '@core/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class EditStageResolver {
  private editStageService = inject(EditStageService);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private translateService = inject(TranslateService);

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<BuildingFloorViewModel | null> {
    const stageId = route.paramMap.get('stageId');
    const floorId = route.paramMap.get('floorId');
    const floor = stageId + '/' + floorId;

    if (!stageId || !floorId) {
      this.handleNavigationError();
      return of(null);
    }

    return this.editStageService.getBuildingMaintenanceInformation(floor).pipe(
      switchMap((stageData: BuildingMaintenanceViewModel) => {
        const stageDataArray = Array.isArray(stageData)
          ? stageData
          : [stageData];
        const validStageData = stageDataArray.find(data => data.buildingFloor);

        if (!validStageData) {
          this.handleNavigationError();
          return of(null);
        }

        return of(validStageData);
      }),
      catchError(() => {
        this.handleNavigationError();
        return of(null);
      })
    );
  }

  private handleNavigationError() {
    this.toastr.error(
      this.translateService.instant('roles_handle_resolver.not_found')
    );
    this.router.navigate(['/']);
  }
}
