import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, catchError, filter, map, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { WorkingSpaceViewModel } from '@core/api';
import { EditWorkingSpaceService } from '@core/services/edit-working-space/edit-working-space.service';

@Injectable({
  providedIn: 'root',
})
export class EditWorkingSpaceResolver {
  private roomDataService = inject(EditWorkingSpaceService);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private translateService = inject(TranslateService);

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<WorkingSpaceViewModel[]> {
    const buildingId = route.paramMap.get('buildingId');
    const stageId = route.paramMap.get('stageId');
    const spaceId = route.paramMap.get('spaceId');
    const roomId = route.paramMap.get('roomId');

    const workingSpaceId =
      buildingId + '/' + stageId + '/' + spaceId + '/' + roomId;

    if (!buildingId || !stageId || !spaceId || !roomId) {
      this.handleNavigationError();
      return of(null);
    }

    return this.roomDataService.getWorkingSpacesByAreaCode(workingSpaceId).pipe(
      catchError(error => {
        if (error.status === 404 || error.status === 500) {
          this.handleNavigationError(error);
          return of(null);
        } else {
          throw error;
        }
      })
    );
  }

  private handleNavigationError(error?: any) {
    this.toastr.error(
      this.translateService.instant('roles_handle_resolver.not_found')
    );
    this.router.navigate(['/']);
  }
}
