import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateDataService {
  constructor(private translateService: TranslateService) {}

  translate(deString?: string, enString?: string): string {
    const currlang = this.translateService.currentLang;
    if (!deString && !enString) {
      return '';
    }
    if (currlang === 'de-DE' && deString) {
      return deString;
    }
    if (currlang === 'en-US' && enString) {
      return enString;
    }
    return '';
  }
}
