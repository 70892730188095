import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { deepmerge } from 'deepmerge-ts';
import { Observable, forkJoin, map } from 'rxjs';

export class TranslateLangService implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(): Observable<any> {
    const base = this.http.get('/assets/i18n/base-de-DE.json');
    const custom = this.http.get('/assets/i18n/custom/de-DE.json');

    // return forkJoin([base, custom  ]).pipe( mergeMap( resp => {
    //   return resp
    // }));

    // return combineLatest([base, custom  ]).pipe(
    //   map(([a, b]) => ({ ...a, ...b }))
    // );

    return forkJoin([base, custom]).pipe(
      map(response => deepmerge(...response))
    );
  }
}
