import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { TaskListComponent } from '@features/tasks/components/task-list/task-list.component';
import { TaskDetailsComponent } from '@features/tasks/components/task-details/task-details.component';
import { TaskDetailsViewComponent } from './components/task-details-view/task-details-view.component';
import { OverrideModule } from '@override/override.module';

@NgModule({
  declarations: [
    TaskListComponent,
    TaskDetailsComponent,
    TaskDetailsViewComponent,
  ],
  imports: [TranslateModule, SharedModule, OverrideModule],
})
export class TasksModule {}
