/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  RentalListingClient,
  RentalListingDto,
  CreateOrUpdateRentalListingRequest,
} from '@core/api';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { MatDialog } from '@angular/material/dialog';
import { RentalListingEditComponent } from './components';
import moment from 'moment';

@Component({
  selector: 'app-website-edit',
  templateUrl: './website-edit.component.html',
  styleUrls: ['./website-edit.component.scss'],
})
export class WebsiteEditComponent implements OnInit {
  form = new FormGroup({});
  formFields: Array<FormlyFieldConfig>;
  isLoading = false;
  formModel = {};
  searchform = new FormGroup({
    searchTerm: new FormControl(''),
  });

  public rentalListingObjects: RentalListingDto[];
  public createOrUpdateRentalListingRequest: CreateOrUpdateRentalListingRequest;
  public filteredRentalListingObjects: RentalListingDto[];
  searchTerm = '';
  private readonly maxFileSizeInMB = 10;

  columnsRentalListings: MtxGridColumn[] = [];

  headline = this.translateService.instant('website_edit.rental.headline');
  isLoadingObjects = false;

  constructor(
    private translateService: TranslateService,
    private fieldService: LocationFormlyFieldService,
    private breadcrumbService: BreadcrumbService,
    private rentalListingClient: RentalListingClient,
    private dialog: MatDialog
  ) {
    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);
  }

  ngOnInit(): void {
    this.isLoadingObjects = true;

    this.columnsRentalListings = [
      {
        header: this.translateService.instant(
          'website_edit.rental.table_header.label__archobjectId'
        ),
        field: 'archObjId',
        sortable: true,
        width: '5%',
        minWidth: 100,
      },
      {
        header: this.translateService.instant(
          'website_edit.rental.table_header.label__address'
        ),
        field: 'address',
        sortable: true,
        formatter: data =>
          `${data.address.streetName} ${data.address.houseNumber}, ${data.address.postalCode}, ${data.address.city}`,
        width: '15%',
        minWidth: 200,
      },
      {
        header: this.translateService.instant(
          'website_edit.rental.table_header.label__title'
        ),
        field: 'title',
        sortable: true,
        formatter: data => WebsiteEditComponent.shortenText(data.title, 50),
        width: '15%',
        minWidth: 200,
      },
      {
        header: this.translateService.instant(
          'website_edit.rental.table_header.label__description'
        ),
        field: 'description',
        sortable: true,
        width: '30%',
        minWidth: 300,
        formatter: data =>
          WebsiteEditComponent.shortenText(data.description, 50),
      },
      {
        header: this.translateService.instant(
          'website_edit.rental.table_header.label__link'
        ),
        field: 'link',
        sortable: true,
        width: '10%',
        minWidth: 150,
        formatter: data => WebsiteEditComponent.shortenText(data.link, 20),
      },
      {
        header: this.translateService.instant(
          'website_edit.rental.table_header.label__isPublished'
        ),
        field: 'isPublished',
        type: 'boolean',
        sortable: true,
        width: '5%',
        minWidth: 100,
        formatter: data =>
          data.isPublished
            ? this.translateService.instant(
                'website_edit.rental.table_header.label__true'
              )
            : this.translateService.instant(
                'website_edit.rental.table_header.label__false'
              ),
      },
      {
        header: this.translateService.instant(
          'website_edit.rental.table_header.label__createdAt'
        ),
        field: 'createdAt',
        sortable: true,
        width: '10%',
        minWidth: 150,
        formatter: data =>
          data.createdAt
            ? moment
                .utc(data.createdAt)
                .tz('Europe/Berlin')
                .locale(this.translateService.instant('locale.locale'))
                .format(
                  this.translateService.instant(
                    'locale.moment__date_time_format'
                  )
                ) +
              ' ' +
              this.translateService.instant(
                'website_edit.rental.extended_time_str'
              )
            : 'Nicht verfügbar',
      },
    ];

    this.getRentalListingObjects();
  }

  /**
   * Applies the search filter to the rental marketing objects.
   */
  applySearch() {
    this.searchTerm = this.searchform.get('searchTerm')?.value ?? '';
    if (this.searchTerm.trim().length > 2) {
      this.filteredRentalListingObjects = this.rentalListingObjects.filter(
        rentalListingObject =>
          rentalListingObject.address?.city?.includes(this.searchTerm) ||
          rentalListingObject.address?.postalCode?.includes(this.searchTerm) ||
          rentalListingObject.address?.streetName?.includes(this.searchTerm) ||
          rentalListingObject.address?.houseNumber?.includes(this.searchTerm) ||
          rentalListingObject.title?.includes(this.searchTerm) ||
          rentalListingObject.description?.includes(this.searchTerm) ||
          rentalListingObject.archObjId?.includes(this.searchTerm) ||
          rentalListingObject.link?.includes(this.searchTerm)
      );
    } else {
      this.filteredRentalListingObjects = [...this.rentalListingObjects];
    }
  }

  /**
   * Handles errors by logging them and setting the loading state to false.
   * @param error The error to handle.
   */
  private handleError(error: Error) {
    this.isLoading = false;
    console.error('[WebsiteEditComponent] handleError:', error);
  }

  /**
   * Retrieves the rental marketing objects and updates the filtered list.
   */
  public getRentalListingObjects() {
    this.rentalListingClient.getAllRentalListingObjectsForEdit().subscribe({
      next: (rentalMarketingObjects: RentalListingDto[]) => {
        this.rentalListingObjects = rentalMarketingObjects.sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return dateB - dateA;
        });
        this.filteredRentalListingObjects = [...this.rentalListingObjects];
        this.fieldService.getDefaultList();
      },
      error: (error: Error) => {
        this.handleError(error);
        this.isLoadingObjects = false;
      },
      complete: () => (this.isLoadingObjects = false),
    });
  }

  /**
   * Handles the selection of a rental listing by ID.
   * @param id The ID of the rental listing.
   */
  onSelectRentalListing(id: number): void {
    this.isLoading = true;

    this.rentalListingClient.getRentalListingObjectForEdit(id).subscribe({
      next: (rentalListingObject: RentalListingDto) => {
        this.openRentalListingEditDialog(rentalListingObject);
        this.isLoading = false;
      },
      error: (error: Error) => {
        this.handleError(error);
        this.isLoading = false;
      },
    });
  }

  /**
   * Opens the rental listing edit dialog.
   * @param rentalListingObject The rental listing object to edit.
   */
  openRentalListingEditDialog(rentalListingObject?: RentalListingDto): void {
    const dialogRef = this.dialog.open(RentalListingEditComponent, {
      maxHeight: '800px',
      data: rentalListingObject,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.cancelled) {
        console.log('Dialog wurde abgebrochen');
      } else {
        this.getRentalListingObjects();
      }
    });
  }

  /**
   * Shortens the given text to the specified maximum length.
   * @param text The text to shorten.
   * @param maxLength The maximum length of the shortened text.
   * @returns The shortened text with ellipsis if it exceeds the maximum length.
   */
  private static shortenText(text: string, maxLength: number): string {
    if (!text || text.length <= maxLength) {
      return text;
    }

    let shortened = text.substring(0, maxLength);

    const lastSpaceIndex = shortened.lastIndexOf(' ');
    if (lastSpaceIndex > 0) {
      shortened = shortened.substring(0, lastSpaceIndex);
    }

    return shortened + '...';
  }
}
