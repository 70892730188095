<div class="content-body">
  <h2>Unsere Herausforderungen</h2>
  <p id="p">
    Die ganzheitliche Digitalisierung und die nachhaltige Bewirtschaftung des
    Immobilienportfolios sind für Telekom RealEstate zentrale Herausforderungen.
  </p>

  <scale-tab-nav>
    <scale-tab-header slot="tab">Digitalisierung</scale-tab-header>
    <scale-tab-panel slot="panel">
      <div class="panel">
        <div class="panel-row">
          <div class="panel-column">
            <p>
              Mit dem gezielten Einsatz von Digitalisierung verfolgen wir drei
              Ziele:
            </p>

            <ul>
              <!-- <div style="padding-left: 2rem"> -->
              <li>
                Einfache Nutzung von Gebäudeservices für unsere Mitarbeitenden
              </li>
              <li>
                Förderung der Zusammenarbeit (u. a. mit unseren Dienstleistern)
              </li>
              <li>
                Optimierung der Büro- und Technikflächen durch gezielte Analysen
                zur Gebäudenutzung
              </li>
              <!-- </div> -->
            </ul>
            <br /><br />
            <div class="expand-right">
              <p id="p">
                Folgende Beispiele zeigen, wie wir unserer Ambition des
                gezielten Einsatzes von Digitalisierung bei Telekom RealEstate
                gerecht werden:
              </p>
              <p id="p">
                Mitarbeitende können Störungen im Gebäude, wie beispielsweise
                eine defekte Jalousie, schnell und unkompliziert via App
                aufnehmen und melden. Dies erspart unter anderem das Auffinden
                und die Kontaktaufnahme mit der zuständigen Person. Die
                aufgenommenen Störungen im Gebäude werden über eine digitalen
                Schnittstelle an unseren Gebäudedienstleister übermittelt. Damit
                schaffen wir gute Voraussetzungen für die optimale
                Zusammenarbeit mit unseren Dienstleistern.
              </p>
              <p id="p">
                Eine App ermöglicht unseren Mitarbeitenden das Buchen von
                Flächen (Arbeitsplätze, Meetingräume) in unseren Büroimmobilien.
                Mithilfe dieser Informationen gewinnen wir Erkenntnisse, welche
                Flächen unsere Kunden im „New Normal“ benötigen und besonders
                gerne nutzen. Dieses Wissen nutzen wir für die Gestaltung neuer
                Flächenkonzepte.
              </p>
              <p id="p">
                Mehr zu den einzelnen Bereichen der Digitalisierung im Real
                Estate Management finden Sie zukünftig u. a. in unseren
                <a
                  href="https://open.spotify.com/show/5nSfhF0DRF4rMeOQFBocPA"
                  target="_blank"
                  style="white-space: nowrap"
                  >Podcasts
                  <mat-icon style="font-size: 20px">launch</mat-icon></a
                >.
              </p>
            </div>
          </div>
          <div class="panel-column image-container">
            <img [src]="'assets/images/undraw_what3.svg'" />
          </div>
        </div>
      </div>
    </scale-tab-panel>
    <scale-tab-header slot="tab">Nachhaltigkeit</scale-tab-header>
    <scale-tab-panel slot="panel">
      <div class="panel">
        <div class="panel-row">
          <div class="panel-column">
            <p id="p">
              Das Thema Nachhaltigkeit und die Reduzierung unseres ökologischen
              Fußabdrucks steht bei uns im Fokus. Telekom RealEstate leistet
              damit einen wichtigen Beitrag zur Strategie der Deutschen Telekom
              AG, den CO2-Verbrauch signifikant zu senken.
            </p>
            <p id="p">
              Seit Jahren steuern wir aktiv den Energie-Verbrauch unserer
              Gebäude: Durch innovative Standort- und Bürokonzepte verbessern
              wir die Flächenauslastung unserer Gebäude und reduzieren damit den
              Flächenbedarf. Nicht mehr benötigte Flächen vermieten wir weiter,
              vermeiden so Leerstand und sparen Energie. Wir identifizieren
              Auffälligkeiten im Energie-Verbrauch, analysieren den Lastgang und
              optimieren den operativen Betrieb der Gebäude. Außerdem setzen wir
              auf digitale Unterstützung, zum Beispiel durch wetteroptimierte
              Gebäudesteuerung. Zusätzlich überprüfen wir, welche Möglichkeiten
              es gibt (bspw. über Photovoltaik) den benötigten Strom für unsere
              Gebäude selbst zu produzieren.
            </p>
            <p id="p">
              Der Strom, den wir in unseren Gebäuden einsetzen, ist 100%
              klimaneutral.
            </p>
          </div>
          <div class="panel-column image-container">
            <img [src]="'assets/images/undraw_what2.svg'" />
          </div>
        </div>
      </div>
    </scale-tab-panel>
  </scale-tab-nav>
</div>
