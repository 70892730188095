import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PermissionMode } from '../models/permissionMode';

export interface IDialogData {
  title?: string;
  message?: string;
  id?: string;
}

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
})
export class ApprovalDialogComponent {
  reasonForApproval = '';
  constructor(
    public dialogRef: MatDialogRef<ApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}

  public onOk() {
    this.closeDialog(PermissionMode.Approval);
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  public closeDialog(mode: PermissionMode) {
    const result = {
      mode: mode,
      reasonForApproval: this.reasonForApproval,
      id: this.data.id,
    };
    this.dialogRef.close(result);
  }
}
