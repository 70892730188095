import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay-telekom.component.html',
  styleUrls: ['./spinner-overlay-telekom.component.scss'],
})
export class SpinnerOverlayTelekomComponent {
  @Input() show: boolean | null = false;
  @Input() label = this.translateService.instant('form.loading_message');

  constructor(private translateService: TranslateService) {}
}
