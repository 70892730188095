import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-fact-item',
  templateUrl: './fact-item.component.html',
  styleUrls: ['./fact-item.component.scss'],
})
export class FactItemComponent {
  @Input() iconUrl = 'assets/icons/dummy-icon.svg';
  @Input() factAmount = '0';
  @Input() factType = '';
  @Input() subtitle = 'subtitle';
  @Input() index!: number;
  @ViewChild('factItem') factItemElement!: ElementRef;

  constructor(private renderer: Renderer2) {}

  getNumberFromString(str: string) {
    str = str.replace(',', '.');
    if (!isNaN(parseFloat(str))) {
      return parseFloat(str);
    } else {
      console.log('not a number!');
      return 0;
    }
  }

  highlight() {
    this.renderer.addClass(this.factItemElement.nativeElement, 'highlight');
    const circle = document.getElementById(`circle-${this.index}`);
    if (circle) {
      this.renderer.addClass(circle, 'highlight-circle');
      const cx = circle.getAttribute('cx');
      const cy = circle.getAttribute('cy');
      const newCircle = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
      );
      newCircle.setAttribute('cx', cx);
      newCircle.setAttribute('cy', cy);
      newCircle.setAttribute('r', '1'); // Radius des inneren Kreises
      newCircle.setAttribute('fill', '#e20074'); // Farbe des inneren Kreises
      newCircle.setAttribute('class', 'inner-highlight-circle');
      circle.parentElement?.appendChild(newCircle);
    }
  }

  removeHighlight() {
    this.renderer.removeClass(this.factItemElement.nativeElement, 'highlight');
    const circle = document.getElementById(`circle-${this.index}`);
    if (circle) {
      this.renderer.removeClass(circle, 'highlight-circle');
      const innerCircle = circle.parentElement?.querySelector(
        '.inner-highlight-circle'
      );
      if (innerCircle) {
        innerCircle.remove();
      }
    }
  }
}
