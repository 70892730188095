import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
})
export class TableFilterComponent implements OnInit, OnDestroy {
  form = new FormGroup({});

  formModel = {};

  @Input() filterFields: Array<FormlyFieldConfig>;

  @Input() filterVisible = false;

  @Input() triggerViaChange = false;

  @Output() filterEvent = new EventEmitter<any>();

  @Output() searchEvent = new EventEmitter<any>();

  @Output() resetFilterEvent = new EventEmitter<any>();

  private subForm: Subscription;

  ngOnInit() {
    this.subForm = this.form.valueChanges.subscribe({
      next: () => {
        this.filterEvent.emit(this.form.value);
      },
    });
  }

  public patchValue(newVal) {
    this.formModel = { ...this.formModel, ...newVal };
    this.filterVisible = true;
  }

  ngOnDestroy() {
    this.subForm.unsubscribe();
  }

  search() {
    this.searchEvent.emit();
  }

  resetFilter() {
    this.form.reset();
    this.filterFields.forEach(item => {
      item.fieldGroup?.forEach(group => {
        if (group.type === 'matdaterange') {
          if (group.key) {
            this.form.controls[group.key.toString()].setValue('delete');
          }
        }
      });
    });
    this.resetFilterEvent.emit();
  }
}
