import { SmartusComponentsModule } from '@affinis/smartus-components';
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-formly-workingplaces-planning',
  standalone: true,
  imports: [
    SmartusComponentsModule,
    NgIf,
    FormlyModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  template: `
    <div
      class="info-card_field"
      data-debug="formly-custom-flache-nutzer"
      style="display: flex; align-items: center;">
      <div class="label">{{ props.label }}:</div>

      <div class="read-only-section">
        <span
          >{{ 'edit_room.labels.number' | translate }}: {{ props.number }}
        </span>
        /
        <span
          >{{ 'edit_room.labels.occupied' | translate }}:
          {{ props.occupied }}
        </span>
      </div>

      <div class="editable-section" style="margin-left: 8px;">
        <label for="flacheNutzer" style="margin-right: 4px;"
          >{{ 'edit_room.labels.planning' | translate }}:</label
        >
        <input
          id="flacheNutzer"
          type="number"
          [formControl]="control"
          [formlyAttributes]="field"
          style="width: 80px;" />
      </div>
    </div>
  `,
})
export class FormlyWorkingPlacesPlanningComponent extends FieldType {
  formattedValue: string = '';

  ngOnInit() {
    if (this.props.planned !== null && this.props.planned !== undefined) {
      this.control.setValue(this.props.planned);
    } else {
      this.control.setValue(0);
    }
  }

  get control(): FormControl {
    return this.formControl as FormControl;
  }
}
