import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MtxGridModule } from '@ng-matero/extensions/grid';
import { TranslateModule } from '@ngx-translate/core';
import { MyLocationComponent } from '@mandants/telekom/features/location/my-location/my-location.component';
import { SmartusComponentsModule } from '@affinis/smartus-components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  LocationMapComponent,
  LocationNotificationComponent,
  TelekomEmailTemplateComponent,
  LocationInformationEditComponent,
} from './components';
import { OverrideModule } from '@mandants/telekom/override/override.module';
import { SafeHtmlPipe } from '@shared/pipes';
import { HtmlEditorComponent } from '@shared/components/html-editor/html-editor.component';
import { MatIconModule } from '@angular/material/icon';

const components = [
  LocationMapComponent,
  MyLocationComponent,
  LocationNotificationComponent,
  TelekomEmailTemplateComponent,
  LocationInformationEditComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    SmartusComponentsModule,
    TranslateModule,
    FormlyModule,
    MtxGridModule,
    AngularEditorModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    OverrideModule,
    SafeHtmlPipe,
    HtmlEditorComponent,
    MatIconModule,
  ],
  exports: [...components],
})
export class LocationModule {}
