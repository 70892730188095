import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { ENTER, SEMICOLON } from '@angular/cdk/keycodes';

interface IChip {
  value: string;
  valid: boolean;
}

@Component({
  selector: 'app-formly-chips-input',
  standalone: true,
  imports: [
    FormlyModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgFor,
    NgIf,
  ],
  template: ` <form data-debug="formly-chips-input">
    <mat-form-field class="wrapper-full">
      <mat-label *ngIf="props?.label">{{ props.label }}</mat-label>
      <mat-chip-grid
        #chipGrid
        aria-label="Enter keywords"
        [formControl]="formControl"
        [pattern]="props?.pattern">
        <mat-chip-row
          *ngFor="let chip of chips"
          [color]="chip.valid ? 'primary' : 'warn'"
          highlighted
          (removed)="removeChip(chip)">
          {{ chip.value }}
          <button matChipRemove aria-label="remove chip">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        [placeholder]="props?.placeholder"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputFor]="chipGrid"
           (matChipInputTokenEnd)="add($event)" 
           (paste)="paste($event)" />
      <mat-hint *ngIf="props?.description">{{ props.description }}</mat-hint>
      <mat-error *ngIf="formControl?.hasError('pattern')">
        <span [innerText]="field.validation?.messages?.pattern"></span>
      </mat-error>
    </mat-form-field>
  </form>`,
  styles: [
    `
      .wrapper-full {
        width: 100%;
        margin-bottom: 12px;
      }
    `,
  ],
})
export class FormlyChipsInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  chips: IChip[] = [];
  private regex: RegExp;
  separatorKeysCodes = [ENTER, SEMICOLON];

  ngOnInit() {
    if (!this.props.pattern) {
      return;
    }
    this.regex = new RegExp(this.props.pattern);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.chips.push({ value: value, valid: this.regex.test(value) });
      event.chipInput.clear();
    }
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
    .getData('Text')
    .split(/;|,|\n/)
    .forEach(value => {
      if(value.trim()){
        this.chips.push({ value: value, valid: this.regex.test(value) });
      }
    })
  }

  removeChip(chip: IChip) {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
    }
  }
}
