import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RoleViewModel } from '@core/api';
import { FieldType } from '@ngx-formly/core';
import { isObservable } from 'rxjs';

interface ExtendedRoleViewModel extends RoleViewModel {
  disabled?: boolean;
}

@Component({
  selector: 'app-formly-autocomplete-chips',
  template: `
    <ng-container *ngIf="!loading">
      <mat-form-field class="wrapper-full">
        <mat-label *ngIf="props?.label">{{ props.label }}</mat-label>
        
        <mtx-select
          [formControl]="formControl"
          [items]="allRoles"
          bindLabel="description"
          [multiple]="true"
          [required]="props.required"
          [disabled]="props.disabled"
          [notFoundText]="'user.roles_not_found' | translate">
        </mtx-select>

        <mat-hint *ngIf="props?.description">{{ props.description }}</mat-hint>
        <mat-error *ngIf="formControl?.hasError('required')">
          <span [innerText]="field.validation?.messages?.required"></span>
        </mat-error>

        <mat-error *ngIf="formControl?.hasError('pattern')">
          <span [innerText]="field.validation?.messages?.pattern"></span>
        </mat-error>
      </mat-form-field>
    </ng-container>
  `,
  styles: [
    `
      .wrapper-full {
        width: 100%;
        margin-bottom: 12px;
      }

      :host::ng-deep
        .ng-select.ng-select-multiple
        .ng-select-container
        .ng-value-container
        .ng-value {
        background-color: #e20074;
        color: white;
        padding: 2px;
      }
    `,
  ],
})
export class FormlyAutocompleteChipsForRolesComponent
  extends FieldType
  implements OnInit
{
  // @ts-ignore
  formControl: FormControl;

  searchTerm = '';
  allRoles: ExtendedRoleViewModel[];

  preselectedRoles: ExtendedRoleViewModel[] = [];

  loading = true;

  constructor(
    private cd: ChangeDetectorRef
  ){
    super();
  }

  ngOnInit(): void {
    this.formControl.setValue(this.props.currentRoles)
    if (isObservable(this.props.options)) {
      this.props.options.subscribe({
        next: res => {
          this.allRoles = res;
          this.allRoles.map(roles => roles.disabled = false)
          this.handlePreselectedRoles();
        },
      });
    } else {
      this.allRoles = this.props.options;
      this.allRoles.map(roles => roles.disabled = false)
      this.handlePreselectedRoles();
    }
  }

  handlePreselectedRoles() {
    if (this.props.disablePreselectedRoles === true) {
      this.preselectRoles();
      this.updateDisabledStatus();
    } else {
      this.loading = false;
    }
  }

  preselectRoles(): void {
    this.preselectedRoles = this.formControl.value || [];
  }

  updateDisabledStatus(): void {
    if (this.formControl.value) {
      this.formControl.value.forEach(item => {
        const role = this.allRoles.find(r => r.id === item.id);
        if (role && this.isPreselected(role)) {
          role.disabled = true;
        } else if (role) {
          role.disabled = false;
        }
      });
    }

    this.formControl.setValue([...this.formControl.value]);
    this.loading = false;
    this.cd.detectChanges();
  }

  isPreselected(role: ExtendedRoleViewModel): boolean {
    return this.preselectedRoles.some(preRole => preRole.id === role.id);
  }
}
