<div class="content-body content-body-padding">
  <div class="text-col">
    <scale-card>
      <ng-content select="[card1]"></ng-content>
    </scale-card>
  </div>
  <div class="text-col">
    <scale-card>
      <ng-content select="[card2]"></ng-content>
    </scale-card>
  </div>

  <div class="text-col">
    <scale-card>
      <ng-content select="[card3]"></ng-content>
    </scale-card>
  </div>
</div>
