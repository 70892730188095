import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormSubmitService } from '@core/services/form-submit-service/form-submit.service';
import { SettingsService } from '@core/services/settings-service/settings.service';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

export interface IConfirmFields {
  data?: any;
  label: string;
  origin?: string;
  type:
    | 'textarea'
    | 'formly-template'
    | 'chapter'
    | 'matdatetimepicker'
    | 'select'
    | 'field';
  value: any;
  visibleInConfirm?: boolean;
}

@Component({
  selector: 'app-form-confirm',
  templateUrl: './form-confirm.component.html',
  styleUrls: ['./form-confirm.component.scss'],
})
export class FormConfirmComponent implements OnInit {
  @Input() translateKeyTitle?: string;

  @Input() translateKeyDescription?: string;

  public filledForm?;

  /**
   * Filled form fields
   */
  @Input() filledFields?;

  /**
   * show or hide return button.
   */
  @Input() backButton: boolean;

  @Input() showTicketCreateData: boolean;

  /**
   *  Additional fields for e.g. accept dsgvo
   */
  @Input() extraConfirmFormFields;

  @Input() additionalFields;

  @Input() showExtraConfirmFormFields;

  @Output() confirmed = new EventEmitter<void>();

  @Output() backToEdit = new EventEmitter<void>();

  extraConfirmFormModel: any = {};

  extraConfirmForm = new FormGroup({});

  public isSubmitted: boolean;

  card: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  constructor(
    private settingService: SettingsService,
    private formSubmitService: FormSubmitService
  ) {
    this.formSubmitService.isSubmitted.subscribe(res => {
      this.isSubmitted = res;
    });
  }

  ngOnInit() {
    if (this.translateKeyTitle === undefined) {
      if (this.showTicketCreateData) {
        this.translateKeyTitle =
          'ticket.create.confirm.label__employeeMessage_summary';
      } else {
        this.translateKeyTitle =
          'ticket.create.confirm.label__contactTitle_summary';
      }
    }

    if (this.translateKeyDescription === undefined) {
      if (this.showTicketCreateData) {
        this.translateKeyDescription =
          'ticket.create.confirm.p__infoEmployeeMessage_summary';
      } else {
        this.translateKeyDescription = 'ticket.create.confirm.p__info_summary';
      }
    }

    if (this.filledFields) {
      this.filledForm = this.flatten(this.filledFields);
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  pressBackToEdit() {
    this.backToEdit.emit();
  }

  validateConfirmedForm() {
    this.extraConfirmForm.markAllAsTouched();

    if (this.extraConfirmForm.valid) {
      this.formSubmitService.isSubmitted.emit(true);
      this.confirmed.emit();
    }
  }

  private flatten(filledFields: any) {
    let flatArray: IConfirmFields[] = [];

    for (const field of filledFields) {
      if (field.fieldGroup) {
        flatArray = flatArray.concat(this.flattenFieldGroup(field));
      } else {
        flatArray.push({
          label: field.templateOptions.label,
          value: field.formControl.value,
          data: field,
          type: field.type,
          visibleInConfirm: field.templateOptions.visibleInConfirm,
          origin: 'a',
        });
      }
    }
    flatArray = [...flatArray, ...(this.additionalFields || [])];
    return flatArray;
  }

  private getFileList(field: any) {
    const elList: any = [];
    for (const control of field.formControl.controls) {
      elList.push({
        label: control.value.attachmentType,
        value: control.value.attachment.name,
        type: 'file',
        data: null,
        visibleInConfirm: true,
        origin: 'file',
      });
    }
    return elList;
  }

  private flattenFieldGroup(fg: any) {
    let flatArray: any = [];

    if (fg.fieldGroup) {
      flatArray = flatArray.concat(this.flattenFieldGroup(fg.fieldGroup));
    } else {
      for (const field of fg) {
        if (field.fieldGroup) {
          if (!field._hide) {
            flatArray = flatArray.concat(
              this.flattenFieldGroup(field.fieldGroup)
            );
          }
        } else if (field.type === 'chapter') {
          flatArray.push({
            label: field.templateOptions.chapterTitle,
            value: field.templateOptions.chapterText,
            type: field.type,
            data: field,
            visibleInConfirm: field.templateOptions.visibleInConfirm,
            origin: 'chapter',
          });
        } else if (field.type === 'repeat') {
          flatArray = flatArray.concat(this.getFileList(field));
        } else if (!field._hide) {
          if (field.type === 'file' && field.formControl.value) {
            flatArray.push({
              label: field.templateOptions.label,
              value: field.formControl.value.name || 'null',
              data: field,
              type: field.type,
              visibleInConfirm: field.templateOptions.visibleInConfirm,
              origin: 'file',
            });
          } else if (field.type === 'select') {
            const theValue = field.templateOptions.options.find(
              o => o.value === field.formControl.value
            )?.label;
            flatArray.push({
              label: field.templateOptions.label,
              value: theValue || '',
              data: field,
              type: field.type,
              visibleInConfirm: field.templateOptions.visibleInConfirm,
              origin: 'select',
            });
          } else if (field.type === 'tree-select') {
            const theValue = field.formControl.value.name;
            flatArray.push({
              label: field.templateOptions.label,
              value: theValue || '',
              data: field,
              type: field.type,
              visibleInConfirm: field.templateOptions.visibleInConfirm,
              origin: 'tree-select',
            });
          } else if (field.type === 'matdatetimepicker') {
            flatArray.push({
              label: field.templateOptions.label,
              value: this.formatDateTime(field.formControl.value, {
                format: field.templateOptions.dateTimeFormat,
                timezone: field.templateOptions.timezone,
              }),
              data: field,
              type: field.type,
              dateTimeFormat: field.templateOptions.dateTimeFormat,
              visibleInConfirm: field.templateOptions.visibleInConfirm,
              origin: 'matdatetimepicker',
            });
          } else {
            flatArray.push({
              label: field.templateOptions.label,
              value: field.formControl.value,
              data: field,
              type: field.type,
              visibleInConfirm: field.templateOptions.visibleInConfirm,
              origin: 'other',
            });
          }
        }
      }
    }
    return flatArray;
  }

  private formatDateTime(
    t: moment.Moment,
    config: { format?: string; timezone?: string }
  ) {
    if (!t) {
      return '--';
    }
    console.log(t);

    const tformatted = config.timezone
      ? momentTimezone(t).tz(config.timezone || '', true)
      : momentTimezone(t);

    return tformatted
      .lang(this.settingService.language.substring(0, 2))
      .format(config.format || 'DD MMM yyyy HH:mm z');
  }

  public showChapter(label: string): boolean {
    if (label === 'Anhang hinzufügen') {
      let showAttachment = false;
      this.filledForm.forEach(item => {
        if (item.type == 'file') {
          showAttachment = true;
        }
      });
      return showAttachment;
    }
    return true;
  }
}
