import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CostcenterSearchFormlyFieldService } from '@core/services/costcenter-search-formly-field-service/costcenter-search-formly-field.service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-formly-space-request',
  template: `
    <div>
      <div style="display: flex; gap: 10px">
        <mat-form-field>
          <mat-label>{{
            'ticket.create.space_request.requested_area.quantity' | translate
          }}</mat-label>
          <input type="number" matInput [formControl]="quantity" min="0" />
        </mat-form-field>
        <mat-form-field style=" flex-grow: 1;">
          <mat-label>{{
            'ticket.create.space_request.requested_area.type' | translate
          }}</mat-label>
          <mat-select [formControl]="spaceType">
            <mat-option
              *ngFor="
                let docType of [
                  'Büroarbeitsplätze',
                  'Technikfläche',
                  'Lagerfläche',
                  'Verrechnungsbereich'
                ]
              "
              [value]="docType"
              [title]="docType">
              {{ docType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="display: flex; gap: 10px">
        <formly-form
          [fields]="filterFields"
          [model]="formModel"
          style=" flex-grow: 1;"></formly-form>
        <su-button
          skin="accent"
          icon="plus"
          [label]="'ticket.create.space_request.requested_area.add' | translate"
          (onClick)="addItem()"
          style="margin-top: 10px">
        </su-button>
      </div>
      <div>
        <mtx-grid
          #grid
          [columns]="columns"
          [data]="data"
          [rowStriped]="true"
          [showPaginator]="false"
          [noResultText]="
            'ticket.create.space_request.requested_area.no_results' | translate
          "
          [cellSelectable]="false">
        </mtx-grid>
      </div>
      <ng-container #fieldComponent></ng-container>
    </div>
    <ng-template #itemDeleteTemplate let-row let-index="index" let-col="colDef">
      <su-button
        skin="secondary"
        icon="trash"
        (onClick)="deleteItem(row)"
        [title]="'crud.delete' | translate">
      </su-button>
    </ng-template>
  `,
  styles: [
    `
      :host::ng-deep.mtx-grid-no-result {
        min-height: 50px;
      }

      ::ng-deep td {
        align-content: center;
      }
    `,
  ],
})
export class FormlySpaceRequestComponent extends FieldType implements OnInit {
  quantity = new FormControl();
  spaceType = new FormControl();
  costcenter = new FormControl();

  activeToast: ActiveToast<object>;

  @ViewChild('itemDeleteTemplate', { static: true })
  itemDeleteTemplateRef!: TemplateRef<void>;

  data = [];
  columns: MtxGridColumn[] = [];

  filterFields?: FormlyFieldConfig[];

  formModel: any = {};

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private costcenterFormlyFieldService: CostcenterSearchFormlyFieldService
  ) {
    super();
  }

  ngOnInit(): void {
    this.data = this.formControl.value || [];

    this.columns = [
      {
        header: this.translateService.instant(
          'ticket.create.space_request.requested_area.quantity'
        ),
        field: 'quantity',
      },
      {
        header: this.translateService.instant(
          'ticket.create.space_request.requested_area.type'
        ),
        field: 'spaceType',
      },
      {
        header: this.translateService.instant(
          'ticket.create.space_request.requested_area.costcenter'
        ),
        field: 'costcenter',
      },
      {
        header: '',
        width: '120px',
        field: 'delete',
        cellTemplate: this.itemDeleteTemplateRef,
      },
    ];

    this.filterFields = this.getFilterFields();
  }

  getFilterFields() {
    return [
      {
        key: 'costcenter',
        fieldGroup: [
          ...this.costcenterFormlyFieldService.autocompleteSearch(
            this.translateService.instant(
              'access_orderer.filter.costcenter.title'
            ),
            null,
            null,
            null,
            id => this.onSelectCostcenter(id),
            this.onDeleteCostcenter.bind(this)
          ),
        ],
      },
    ];
  }

  onSelectCostcenter(id) {
    this.costcenter.setValue(id);
  }

  onDeleteCostcenter() {
    this.costcenter.reset();
    this.formModel = {};
    this.filterFields = this.getFilterFields();
  }

  addItem() {
    if (this.activeToast) {
      this.toastr.remove(this.activeToast.toastId);
    }
    if (
      this.quantity?.value &&
      this.spaceType?.value &&
      this.costcenter?.value
    ) {
      this.data = [
        ...this.data,
        {
          quantity: this.quantity.value,
          spaceType: this.spaceType.value,
          costcenter: this.costcenter.value,
        },
      ];

      const formArray = this.formControl as FormArray;

      formArray.push(
        new FormGroup({
          quantity: new FormControl(this.quantity.value ?? null),
          spaceType: new FormControl(this.spaceType.value ?? null),
          costcenter: new FormControl(this.costcenter.value ?? null),
        })
      );

      this.quantity.reset();
      this.spaceType.reset();
      this.onDeleteCostcenter();
    } else {
      this.activeToast = this.toastr.error(
        this.translateService.instant(
          'ticket.create.space_request.requested_area.invalid_data'
        )
      );
    }
  }

  deleteItem(row) {
    this.data = this.data.filter(d => d !== row);

    const formArray = this.formControl as FormArray;

    formArray.clear();

    this.data.forEach(item => {
      formArray.push(
        new FormGroup({
          quantity: new FormControl(item.quantity),
          spaceType: new FormControl(item.spaceType),
          costcenter: new FormControl(item.costcenter),
        })
      );
    });
  }
}
