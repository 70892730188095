import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ticket-status',
  templateUrl: './ticket-get-status.html',
  styleUrls: [],
})
export class TicketGetStatusBaseComponent implements OnDestroy {
  loading = false;

  form = new FormGroup({});

  formModel: any = {};

  formFields: Array<FormlyFieldConfig>;

  headline: string;

  card: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  private routeSub: Subscription;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected translateService: TranslateService
  ) {
    this.routeSub = this.route.queryParams.subscribe({
      next: params => {
        setTimeout(() => {
          this.form.patchValue({
            id: params.id,
            email: params.email,
          });
        }, 1);
      },
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const id = (this.form.value as { id?: number }).id;
      this.router.navigate([`${id}`], {
        relativeTo: this.route,
        queryParams: { email: (this.form.value as { email?: string }).email },
      });
    } else {
      console.log('Ticket nicht gefunden');
    }
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }
}
