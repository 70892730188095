<su-headline h="h1" skin="primary">{{headline}}</su-headline>

<su-card [card]="cardStatus" *ngIf="data">
  <div card-content>
    <ng-container>
      <app-detail-fields-base
        [detailFields]="detailFields"
        [data]="data"
        [statusData]="statusData"
        [labelMinWidth]="220"></app-detail-fields-base>
    </ng-container>
  </div>
</su-card>

<su-card [card]="cardNotFound" *ngIf="notFound">
  <div card-content>
    <div class="alert warning">
      {{'ticket.details.error.ticketNotFound.message' | translate}}
    </div>
  </div>
</su-card>

<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
