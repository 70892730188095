import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  RegexpLocationname,
  RegexpStreetname,
  RegexpZipCodeDE,
} from '@shared/utils/regex';

export function formLocationOfOrigin(
  translateService: TranslateService,
  isTelekomChecked?: boolean,
  locationFormlyFieldService?
): Array<FormlyFieldConfig> {
  if (isTelekomChecked) {
    return [
      ...locationFormlyFieldService.autocompleteSearch(false, null, true),
    ];
  }
  return [
    {
      key: 'addressManually',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: translateService.instant(
              'relocation.location_of_origin.first_name'
            ),
            placeholder: translateService.instant(
              'relocation.location_of_origin.first_name'
            ),
            required: true,
            maxLength: 100,
          },
        },
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: translateService.instant(
              'relocation.location_of_origin.last_name'
            ),
            placeholder: translateService.instant(
              'relocation.location_of_origin.last_name'
            ),
            required: true,
            maxLength: 100,
          },
        },
        {
          key: 'streetName',
          type: 'input',
          props: {
            label: translateService.instant(
              'relocation.location_of_origin.address'
            ),
            placeholder: translateService.instant(
              'relocation.location_of_origin.address'
            ),
            required: true,
            maxLength: 200,
            minLength: 3,
            pattern: RegexpStreetname,
          },
          validation: {
            messages: {
              pattern: translateService.instant('formErrors.street.pattern'),
              minlength: err =>
                translateService.instant('formErrors.street.minLength', {
                  length: err.requiredLength,
                }),
            },
          },
        },
        {
          key: 'postalCode',
          type: 'input',
          props: {
            label: translateService.instant(
              'relocation.location_of_origin.zip_code'
            ),
            placeholder: translateService.instant(
              'relocation.location_of_origin.zip_code'
            ),
            required: true,
            pattern: RegexpZipCodeDE,
          },
          validation: {
            messages: {
              pattern: translateService.instant('formErrors.zipcode.pattern'),
            },
          },
        },
        {
          key: 'city',
          type: 'input',
          props: {
            label: translateService.instant(
              'relocation.location_of_origin.city'
            ),
            placeholder: translateService.instant(
              'relocation.location_of_origin.city'
            ),
            required: true,
            minLength: 3,
            maxLength: 100,
            pattern: RegexpLocationname,
          },
          validation: {
            messages: {
              pattern: translateService.instant('formErrors.city.pattern'),
              minlength: err =>
                translateService.instant('formErrors.city.minLength', {
                  length: err.requiredLength,
                }),
            },
          },
        },
      ],
    },
  ];
}
