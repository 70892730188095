/* eslint-disable no-param-reassign */
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DocumentTypeViewModel,
  FmDocument,
  FmDocumentType,
  FmTask,
  IncidentViewModel,
  OmContact,
  StorageClient,
  TaskClient,
  TaskViewModel,
} from '@core/api';
import {
  KeyValuePairOfStringAndString,
  SmartPortalProcessError,
} from '@core/api/smart-portal.nemo.api';
import { FileService } from '@core/services/file-service/file.service';
import { MandantConfigService } from '@core/services/mandant-config-service/mandant-config.service';
import { MtxGrid } from '@ng-matero/extensions/grid';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import { ITableConfig } from '@shared/components/table-base/table-base.types';
import { EntityStates } from '@shared/constants/entity-states.constants';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { DocumentVisibility } from '@shared/types/document.types';
import * as moment from 'moment';
import { EMPTY, catchError, first, of } from 'rxjs';

interface DocumentRow extends FmDocument {
  downloadLoading: boolean;
  archiveToImageMasterLoading: boolean;
  actionsDisabled: boolean;
}

interface DocumentDeleteRow extends FmDocument {
  canDelete?: boolean;
}

@Component({
  selector: 'app-detail-file-list',
  templateUrl: '../../table-base/table-base.html',
  styleUrls: ['../../table-base/table-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailFileListComponent
  extends TableBaseComponent
  implements OnInit, OnChanges
{
  @Input() documents: FmDocument[];

  @Input() tasks: FmTask[];

  @Input() incident: IncidentViewModel;

  @Input() task: TaskViewModel;

  @Input() canDeleteDocument = false;

  activeAccount;

  deleteErrorMessage = this.translateService.instant(
    'documents.list.delete_error_msg.default'
  );

  documentTypes: DocumentTypeViewModel[];

  taskSubjects: KeyValuePairOfStringAndString[] = [];

  override config = this.configure(<ITableConfig>{
    title: 'documents.list.title',
    translatePath: 'documents',
    showSmartCard: false,
    showBackButton: false,
    showRefreshButton: false,
  });

  @ViewChild('documentActionsTemplate', { static: true })
  documentActionsTemplateRef!: TemplateRef<void>;

  @ViewChild('documentDeleteTemplate', { static: true })
  documentDeleteTemplateRef!: TemplateRef<void>;

  @ViewChild('documentActionsBRSTTemplate', { static: true })
  documentActionsTemplateBRSTRef!: TemplateRef<void>;

  @ViewChild('visibilityTemplate', { static: true })
  visibilityTemplateRef!: TemplateRef<void>;

  @ViewChild('grid') grid!: MtxGrid;

  override showDocumentDeleteButton = false;

  override hasMultiDownload =
    this.mandantConfigService.mandant.hasMultiDownload ?? false;

  override downloadingDocuments = false;

  override documentVisibilities = [
    {
      value: DocumentVisibility.Intern,
      label: this.translateService.instant('documents.documentVisibilities.0'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.0'
      ),
    },
    {
      value: DocumentVisibility.InternAndContractors,
      label: this.translateService.instant('documents.documentVisibilities.1'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.1'
      ),
    },
    {
      value: DocumentVisibility.Public,
      label: this.translateService.instant('documents.documentVisibilities.2'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.2'
      ),
    },
  ];

  defaultDocumentVisibilityControl =
    this.fileService.defaultDocumentVisibilityControl;

  hasDocumentVisibilitySelection = true;

  defaultDocumentVisibilityValue = '';

  defaultDocumentVisibilityLabel = '';

  documentsDelete: DocumentDeleteRow[];

  user: OmContact;

  constructor(
    injector: Injector,
    private fileService: FileService,
    private storageClient: StorageClient,
    private mandantConfigService: MandantConfigService,
    private taskClient: TaskClient
  ) {
    super(injector);

    this.config.grid.showToolbar = false;
    this.config.grid.columnResizable = false;
    this.config.grid.paging.showPaginator = false;
    this.config.grid.filter.filterOnFront = false;
    this.config.grid.filter.sortOnFront = true;

    this.config.grid.select.rowSelectable = this.hasMultiDownload;
    this.config.grid.select.multiSelectable = this.hasMultiDownload;

    this.activeAccount = this.msalService.instance.getActiveAccount();
  }

  ngOnInit() {
    const documentTypeSubscription = this.fileService
      .getDocumentTypes(this.incident?.incidentId || this.task?.incidentId)
      .subscribe({
        next: (documentTypes: any) => {
          this.documentTypes = documentTypes[0]?.documentTypes;
          if (documentTypes[1]?.documentTypes.length > 0) {
            this.documentTypes = this.documentTypes.concat(
              documentTypes[1]?.documentTypes
            );
          }
          this.initColumns();
          this.handleChanges();
        },
      });

    this.pushSubscription(documentTypeSubscription);

    if (this.incident) {
      this.taskClient
        .getRelatedTaskSubjects(this.incident.incidentId)
        .subscribe({
          next: data => {
            this.taskSubjects = data;
          },
        });

      this.authClient
        .checkSecurityRoleByIncidentId(this.incident.incidentId)
        .subscribe({
          next: res => {
            this.canEditVisibility = res;
          },
          error: err => {
            console.log(err);
          },
        });
    } else if (this.task.taskId) {
      this.authClient.checkSecurityRoleByTaskId(this.task.taskId).subscribe({
        next: res => {
          this.canEditVisibility = res;
        },
        error: err => {
          console.log(err);
        },
      });
    }

    [
      this.hasDocumentVisibilitySelection,
      this.defaultDocumentVisibilityValue,
      this.defaultDocumentVisibilityLabel,
      this.documentVisibilities,
      this.defaultDocumentVisibilityControl,
    ] = this.fileService.initDefaultVisibility(
      this.hasDocumentVisibilitySelection,
      this.defaultDocumentVisibilityControl
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.incident) {
      this.taskClient
        .getRelatedTaskSubjects(this.incident.incidentId)
        .subscribe({
          next: data => {
            this.taskSubjects = data;
          },
        });
    }

    this.documents = changes?.documents?.currentValue;
    this.documentsDelete = [...this.documents];
    this.getUserProfile();
    this.handleChanges();
    this.getData();
    this.initColumns();
  }

  getUserProfile() {
    this.userClient
      .getUserProfile()
      .pipe(
        first(),
        catchError(err => {
          console.log(err);
          this.documentsDelete.forEach(item => {
            item.canDelete = this.canDelete(item, null);
          });
          this.handleChanges();
          return EMPTY;
        })
      )
      .subscribe({
        next: user => {
          this.documentsDelete.forEach(item => {
            item.canDelete = this.canDelete(item, user);
            this.handleChanges();
          });
        },
        complete: () => {
          this.handleChanges();
        },
      });
  }

  handleChanges() {
    this.cd.detectChanges();
    this.grid.detectChanges();
  }

  initColumns() {
    if (!this.documentTypes?.length) {
      // not yet ready to show document type labels
      return;
    }

    this.columns = [
      {
        header: this.translate('label__title'),
        field: 'filename',
        sortable: true,
      },
      {
        header: this.translate('label__documentType'),
        field: 'documentTypeId',
        width: '15%',
        sortable: true,
        formatter: (data: any) => this.getDocumentTypeName(data.documentTypeId),
      },
      {
        header: this.translate('label__task'),
        field: 'taskId',
        width: '15%',
        sortable: true,
        formatter: (data: any) => this.getTaskSubject(data.taskId),
        hide: !this.tasks?.length,
      },
      {
        header: this.translate('label__documentVisibility'),
        field: 'visibilityId',
        width: '15%',
        sortable: true,
        cellTemplate: this.visibilityTemplateRef,
        formatter: (data: any) => {
          this.getVisibilityLabel(data.visibilityId);
        },
      },
      {
        header: this.translate('label__created'),
        field: 'changeDate',
        width: '15%',
        sortable: true,
        formatter: (data: FmDocument) =>
          data.changeDate
            ? moment.utc(data.changeDate).local().format('DD.MM.YYYY, HH:mm:ss')
            : '--',
      },
      {
        header: this.hasMultiDownload ? '' : this.translate('label__action'),
        width: '120px',
        field: 'action',
        cellTemplate: this.documentActionsTemplateRef,
      },
      {
        header: '',
        width: '120px',
        field: 'delete',
        cellTemplate: this.documentDeleteTemplateRef,
        hide: !this.canDeleteDocument,
      },
    ];
  }

  override getTableData$ = () => {
    return of({ entites: this.documents, totalCount: this.documents.length });
  };

  override clickRow = (_data: any, _router: Router, _route: ActivatedRoute) => {
    // do nothing
  };

  override async downloadDocuments() {
    if (this.selectedRows.length) {
      const ids: string[] = [];
      this.selectedRows.forEach((selecedRow: DocumentRow) => {
        if (selecedRow.documentId) {
          ids.push(selecedRow.documentId);
        }
      });

      this.downloadingDocuments = true;
      this.pushSubscription(
        this.fileService.downloadZip(ids).subscribe({
          next: res => {
            const fileResponse = res;
            this.downloadingDocuments = false;
            this.cd.detectChanges();

            if (fileResponse !== null) {
              this.fileService.saveFile(
                fileResponse,
                fileResponse.fileName ?? 'download.zip'
              );
            }
          },
          error: error => {
            if (
              error.errorCode ===
              SmartPortalProcessError.FILESIZE_DOWNLOAD_LIMIT
            ) {
              this.toastr.error(
                this.translateService.instant(
                  'detailFields.fileDownload.filesize_download_limit'
                )
              );
            } else {
              this.toastr.error(
                this.translateService.instant('detailFields.fileDownload.warn')
              );
            }
            this.downloadingDocuments = false;
            this.cd.detectChanges();
          },
        })
      );
    }
  }

  override async downloadDocument(event: Event, row: DocumentRow) {
    if (!row.blobFilename) {
      this.toastr.error(
        this.translateService.instant('detailFields.fileDownload.warn')
      );
      return;
    }

    event.stopPropagation();
    row.actionsDisabled = true;
    row.downloadLoading = true;
    this.cd.detectChanges();

    this.pushSubscription(
      this.fileService
        .downloadFile(row.blobFilename, row.contentType)
        .subscribe({
          next: res => {
            const fileResponse = res;
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();

            if (fileResponse !== null) {
              this.fileService.saveFile(fileResponse, row.name);
            }
          },
          error: error => {
            if (
              error.errorCode ===
              SmartPortalProcessError.FILESIZE_DOWNLOAD_LIMIT
            ) {
              this.toastr.error(
                this.translateService.instant(
                  'detailFields.fileDownload.filesize_download_limit'
                )
              );
            } else {
              this.toastr.error(
                this.translateService.instant('detailFields.fileDownload.warn')
              );
            }
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();
          },
        })
    );
  }

  override async deleteDocument(row: DocumentRow, grid: MtxGrid) {
    this.fileClient.setFileDeleted(row.documentId).subscribe({
      next: () => {
        this.toastr.success(
          this.translateService.instant('documents.list.delete_success_msg')
        );
        row.deleted = true;
        this.data.items = this.data.items.filter(item => item.deleted !== true);
        grid.detectChanges();
      },
      error: err => {
        if (
          err.errorCode ===
          SmartPortalProcessError.FILE_DELETION_MISSING_AUTHORIZATION
        ) {
          this.deleteErrorMessage = this.translateService.instant(
            'documents.list.delete_error_msg.no_rights'
          );
        }
        this.toastr.error(
          this.deleteErrorMessage,
          this.translateService.instant('alert.error')
        );
      },
    });
  }

  async archiveFilesToImageMaster(row: DocumentRow) {
    if (this.selectedRows.length) {
      const ids: string[] = [];
      this.selectedRows.forEach((selecedRow: DocumentRow) => {
        if (selecedRow.blobFilename) {
          ids.push(selecedRow.blobFilename);
        }
      });

      this.pushSubscription(
        this.storageClient.archiveFilesToImageMaster(ids).subscribe({
          next: () => {
            row.actionsDisabled = false;
            row.archiveToImageMasterLoading = false;
            this.cd.detectChanges();
          },
          error: () => {
            this.toastr.error(
              this.translateService.instant('detailFields.fileDownload.warn')
            );
            row.actionsDisabled = false;
            row.archiveToImageMasterLoading = false;
            this.cd.detectChanges();
          },
        })
      );
    }
  }
  override async archiveFileToImageMaster(
    event: Event,
    row: DocumentRow,
    grid: MtxGrid
  ) {
    if (!row.blobFilename) {
      this.toastr.error(
        this.translateService.instant('detailFields.fileDownload.warn')
      );
      return;
    }

    event.stopPropagation();
    row.actionsDisabled = true;
    row.archiveToImageMasterLoading = true;
    this.cd.detectChanges();

    this.pushSubscription(
      this.storageClient.archiveFileToImageMaster(row.blobFilename).subscribe({
        next: () => {
          row.actionsDisabled = false;
          row.archiveToImageMasterLoading = false;
          row.imageMaster = true;
          this.cd.detectChanges();
          grid.detectChanges();
        },
        error: () => {
          this.toastr.error(
            this.translateService.instant('detailFields.fileDownload.warn')
          );
          row.actionsDisabled = false;
          row.archiveToImageMasterLoading = false;
          this.cd.detectChanges();
        },
      })
    );
  }

  override canDelete(row, user): boolean {
    const taskId = row.taskId;
    let canDelete = false;
    const roleGlobal = this.activeAccount.idTokenClaims.roles.includes(
      UserRoles.SMARTPORTAL_DOCUMENT_DELETE_GLOBAL
    );
    const roleBasic = this.activeAccount.idTokenClaims.roles.includes(
      UserRoles.SMARTPORTAL_DOCUMENT_DELETE_BASIC
    );
    const roleLocal = this.activeAccount.idTokenClaims.roles.includes(
      UserRoles.SMARTPORTAL_DOCUMENT_DELETE_LOCAL
    );
    const incidentCompletedOrCancelled = [
      EntityStates.Erledigt,
      EntityStates.Storniert,
    ].includes(this.incident?.statusReason as EntityStates);

    let sysUser;

    // incident closed or cancelled: no deletion allowed
    if (incidentCompletedOrCancelled) {
      this.deleteErrorMessage = this.translateService.instant(
        'documents.list.delete_error_msg.ticket_closed'
      );
      canDelete = false;
      row.canDelete = false;

      return canDelete;
    }
    // if document is attached to task and task is closed or cancelled: no deletion allowed
    if (taskId) {
      const foundTask = this.tasks?.find(task => task.taskId === taskId);
      if (
        foundTask &&
        [EntityStates.Abgeschlossen, EntityStates.Storniert].includes(
          foundTask.status as EntityStates
        )
      ) {
        this.deleteErrorMessage = this.translateService.instant(
          'documents.list.delete_error_msg.task_closed'
        );
        canDelete = false;
        row.canDelete = false;

        return canDelete;
      }
    }

    // document in image master: no deletion allowed
    if (row.imageMaster) {
      canDelete = false;
      row.canDelete = false;

      return canDelete;
    }

    // delete global role: all deletions allowed
    if (roleGlobal) {
      canDelete = true;
      row.canDelete = true;
    } else {
      const extension_ReferenceUserID = this.msalService.instance
        .getActiveAccount()
        .idTokenClaims.extension_ReferenceUserID.toString();
      const email = this.msalService.instance
        .getActiveAccount()
        .idTokenClaims.email.toString();
      if (
        extension_ReferenceUserID == 'not-set' ||
        !extension_ReferenceUserID
      ) {
        this.userClient.getSystemUser(email).subscribe(systemUser => {
          sysUser = systemUser;
          if (
            (roleBasic || roleLocal) &&
            row.systemUserId === sysUser?.systemUserId
          ) {
            canDelete = true;
            row.canDelete = true;
            this.handleChanges();
          }
          // delete local role and matching businessUnitId: deletion allowed
          // TODO
          // if (roleLocal) {
          //   if (row.systemUser?.businessUnitId === sysUser?.businessUnitId) {
          //     canDelete = true;
          //     row.canDelete = true;

          //     console.log(
          //       '# canDelete local system user business unit',
          //       canDelete
          //     );
          //     this.handleChanges();
          //   }
          // }
        });
      } else {
        // delete basic or local role and matching contactId: deletion allowed
        if ((roleBasic || roleLocal) && row.contactId === user?.contactId) {
          canDelete = true;
          row.canDelete = true;
        }
        // delete local role and matching accountId and orgId: deletion allowed
        // TODO
        // if (roleLocal) {
        //   if (user === undefined || user.organisationId === undefined) {
        //     canDelete = false;
        //     row.canDelete = false;

        //     console.log(
        //       '# canDelete local portal user no user or org id',
        //       canDelete
        //     );
        //   } else if (row.accountId === user?.organisationId) {
        //     canDelete = true;
        //     row.canDelete = true;

        //     console.log('# canDelete local portal user org id', canDelete);
        //   }
        // }
      }
    }

    return canDelete;
  }

  private getTaskSubject(taskId: string) {
    if (this.taskSubjects.length > 0) {
      const found = this.taskSubjects?.find(
        (kvp: KeyValuePairOfStringAndString) => kvp.key === taskId
      );
      return found?.value ? found.value : '--';
    }

    const found = this.tasks?.find((task: FmTask) => task.taskId === taskId);
    return found?.subject ? found.subject : '--';
  }

  override getVisibilityLabel(visibilityId: string) {
    const found = this.documentVisibilities?.find(
      (option: any) => option.value === visibilityId
    );
    return found?.label ? found.label : '--';
  }

  private getDocumentTypeName(documentTypeId: string) {
    const found = this.documentTypes?.find(
      (item: FmDocumentType) => item.documentTypeId === documentTypeId
    );
    return found?.name ? found.name : '--';
  }
}
