import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-our-challenges',
  templateUrl: './our-challenges.component.html',
  styleUrls: ['./our-challenges.component.scss'],
})
export class OurChallengesComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    // Workaround: after page load the first scale-tab-header is autofocussed. The problems cause lies in the
    // telekom scale design system and can be fixed with this workaround:
    setTimeout(
      () =>
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        }),
      100
    );
  }
}
