<su-card [card]="card">
  <div card-content>
    <h2 [innerText]="config.title"></h2>
    <app-detail-fields-base
      *ngIf="!isLoading && data"
      [allowFileUpload]="config.showFileUpload"
      [detailFields]="detailFields"
      [data]="data"></app-detail-fields-base>
    <ng-template appPlaceholder></ng-template>
  </div>
</su-card>
<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
