import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  AngularEditorConfig,
  AngularEditorModule,
} from '@kolkov/angular-editor';
@Component({
  selector: 'app-html-editor',
  standalone: true,
  imports: [FormsModule, AngularEditorModule],
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlEditorComponent {
  @Input() id = 'html-editor';
  @Input() config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    minHeight: '25rem',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    showToolbar: true,
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
      ],
      [
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };
  @Input() content: string;
  @Output() contentChange = new EventEmitter<string>();

  onContentChange(content: string): void {
    this.content = content;
    this.contentChange.emit(content);
  }
}
