import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @Input() companyIntro =
    'Telekom RealEstate erbringt Dienstleistungen im Bereich Asset Management und ' +
    'Portfoliomanagement für alle angemieteten und im Eigentum befindlichen Objekte in Deutschland für die ' +
    'Deutsche Telekom AG.';
}

export interface HomeTextData {
  title: string;
  imageUrl: string;
  buttonText?: string;
  buttonLink?: string;
}
