import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
})
export class ThumbnailComponent {
  @Input() img?: string;
  @Input() headertitle?: string;
  @Input() title?: string;
  @Input() introtext?: string;
}
