<h3 mat-dialog-title>
  {{ title }}
</h3>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field style="width: 80%">
    <mat-label>{{
      'location.myLocation.location_information.category.title' | translate
    }}</mat-label>
    <mat-select formControlName="category">
      <mat-option
        [value]="item"
        *ngFor="let item of categories"
        (click)="onCategoryOptionSelect(item)">
        <su-icon [icon]="item.icon"></su-icon> {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    *ngIf="form.get('category').value?.name === 'Standorthinweise'">
    <mat-label>{{
      'location.myLocation.location_information.validFrom' | translate
    }}</mat-label>
    <input
      matInput
      [max]="maxDate"
      [matDatepicker]="validFromPicker"
      formControlName="validFrom"
      (dateChange)="onValidFromDateChange($event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="validFromPicker"></mat-datepicker-toggle>
    <mat-datepicker #validFromPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field
    *ngIf="form.get('category').value?.name === 'Standorthinweise'">
    <mat-label>{{
      'location.myLocation.location_information.validTo' | translate
    }}</mat-label>
    <input
      matInput
      [min]="minDate"
      [matDatepicker]="validToPicker"
      formControlName="validTo"
      (dateChange)="onValidToDateChange($event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="validToPicker"></mat-datepicker-toggle>
    <mat-datepicker #validToPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>{{
      'location.myLocation.location_information.information' | translate
    }}</mat-label>
    <textarea matInput formControlName="text" rows="5"></textarea>
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>{{ linkLabel }}</mat-label>
    <input matInput formControlName="link" />
    <mat-error *ngIf="form.controls.link.errors?.email">{{
      'formErrors.email.pattern' | translate
    }}</mat-error>
    <mat-error *ngIf="form.controls.link.errors?.pattern">{{
      'location.myLocation.location_information.link_error' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>{{ linkNameLabel }}</mat-label>
    <input matInput formControlName="linkName" />
  </mat-form-field>

  <mat-checkbox formControlName="isPublished" style="margin-bottom: 40px">
    <span style="font-size: 16px">
      {{ 'location.myLocation.location_information.publish' | translate }}
    </span>
  </mat-checkbox>

  <div class="button-container">
    <su-button
      skin="secondary"
      (onClick)="closeDialog()"
      [label]="'shared.cancel' | translate">
    </su-button>
    <su-button
      skin="primary"
      [label]="'shared.save' | translate"
      (onClick)="onSubmit()"></su-button>
  </div>
</form>

<app-spinner-overlay [show]="isLoading" [label]="''"></app-spinner-overlay>
