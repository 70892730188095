<su-headline h="h1" skin="primary">{{ title | translate }}</su-headline>

<su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>
<ng-container *ngIf="incidentClassName === incidentClassEnum.BuildingFault">
  <su-slot-tab-content *ngIf="activeTab === tabs[0].id">
    <su-card [card]="cardSummary">
      <div
        card-content
        data-debug="form-confirm"
        *ngIf="ticketDataBuildingFault$ | async as ticketData">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'taskmodule.shared.label__ticketNumber',
              value1: ticketData.ticketNumber,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'taskmodule.shared.label__incidentType',
              value1: ticketData.requestType,
            }
          "></ng-container>

        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.confirm.label__incidentTitle',
              value1: ticketData.name,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.confirm.label__incidentDescription',
              value1: ticketData.description,
            }
          "></ng-container>
        <br />
        <su-headline skin="secondary" h="h2">{{
          'ticket.create.confirm.headline__objectAdress' | translate
        }}</su-headline>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.confirm.label__placeholder',
              value1: ticketData.archObjId,
              value2: ticketData.city,
              value3: ticketData.street,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.confirm.label__roomNumber',
              value1: ticketData.room,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.confirm.label__floor',
              value1: ticketData.floor,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.confirm.label__section',
              value1: ticketData.structuralPart,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.confirm.label__differentAddress',
              value1: ticketData.deviatingAddress,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.headline__search_creator',
              value1: ticketData.creatorName,
              value2: ticketData.creatorEmail,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.headline__search_processor',
              value1: ticketData.reporterName,
              value2: ticketData.reporterEmail,
            }
          "></ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>

  <su-slot-tab-content *ngIf="activeTab === tabs[1].id">
    <su-card
      [card]="cardDocuments"
      *ngIf="ticketDataBuildingFault$ | async as ticketData">
      <div card-content>
        <ng-container>
          <mtx-grid
            #grid
            [columns]="columns"
            [data]="ticketData.documents"
            [rowStriped]="true"
            [noResultText]="'grid.no_results' | translate"
            [cellSelectable]="false">
          </mtx-grid>
        </ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>
</ng-container>

<ng-container *ngIf="incidentClassName === incidentClassEnum.OptionalServices">
  <su-slot-tab-content *ngIf="activeTab === tabs[0].id">
    <su-card [card]="cardSummary">
      <div
        card-content
        data-debug="form-confirm"
        *ngIf="ticketAccessOrderData$ | async as ticketAccessOrderData">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.summary_access_order.label__short_title',
              value1: ticketAccessOrderData.name,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label:
                'ticket.create.summary_access_order.label__contact_orderer',
              value1: ticketAccessOrderData.creatorName,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label:
                'ticket.create.summary_access_order.label__closure_location',
              value1: ticketAccessOrderData.addressString,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label:
                'ticket.create.summary_access_order.label__delivery_address',
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.summary_access_order.label__description',
              value1: ticketAccessOrderData.description,
            }
          "></ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>

  <su-slot-tab-content *ngIf="activeTab === tabs[1].id">
    <su-card
      [card]="cardDocuments"
      *ngIf="ticketAccessOrderData$ | async as ticketAccessOrderData">
      <div card-content>
        <ng-container>
          <mtx-grid
            #grid
            [columns]="columns"
            [data]="ticketAccessOrderData.documents"
            [rowStriped]="true"
            [noResultText]="'grid.no_results' | translate"
            [cellSelectable]="false">
          </mtx-grid>
        </ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>
</ng-container>

<ng-container *ngIf="incidentClassName === incidentClassEnum.FurnitureOrder">
  <su-slot-tab-content *ngIf="activeTab === tabs[0].id">
    <su-card [card]="cardSummary">
      <div
        card-content
        data-debug="form-confirm"
        *ngIf="ticketDataFurnitureOrder$ | async as ticketDataFurnitureOrder">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.delivery_address',
              value1: ticketDataFurnitureOrder.addressString
                ? ticketDataFurnitureOrder.addressString
                : ticketDataFurnitureOrder.deliveryAddressFirstName
                  ? ticketDataFurnitureOrder.deliveryAddressFirstName +
                    ' ' +
                    ticketDataFurnitureOrder.deliveryAddressLastName +
                    ', ' +
                    ticketDataFurnitureOrder.deliveryAddressStreet +
                    ', ' +
                    ticketDataFurnitureOrder.deliveryAddressPostalCode +
                    ' ' +
                    ticketDataFurnitureOrder.deliveryAddressCity
                  : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.desired_date.title',
              value1: ticketDataFurnitureOrder.requestedDate
                ? (ticketDataFurnitureOrder.requestedDate | date: 'dd.MM.yyyy')
                : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.description_field.title',
              value1: ticketDataFurnitureOrder.description,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.flooring.title',
              value1:
                ticketDataFurnitureOrder.flooringHard === true
                  ? ('ticket.create.furniture_order.flooring.options.hard'
                    | translate)
                  : ticketDataFurnitureOrder.flooringSoft === true
                    ? ('ticket.create.furniture_order.flooring.options.soft'
                      | translate)
                    : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.statement_BAD.title',
              value1:
                ticketDataFurnitureOrder.statementBad === true
                  ? ('ticket.create.furniture_order.statement_BAD.options.statement_added'
                    | translate)
                  : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.electric_desk.title',
              value1:
                ticketDataFurnitureOrder.electricDesk === true
                  ? ('ticket.create.furniture_order.electric_desk.options.statement_added'
                    | translate)
                  : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.special_order.title',
              value1:
                ticketDataFurnitureOrder.specialOrder === true
                  ? ('ticket.create.furniture_order.special_order.options.statement_added'
                    | translate)
                  : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.severe_disability.title',
              value1:
                ticketDataFurnitureOrder.severeDisability === true
                  ? ('ticket.create.furniture_order.severe_disability.options.consultation_rejection'
                    | translate)
                  : null,
            }
          "></ng-container>
        <ng-container *ngIf="!ticketDataFurnitureOrder.addressString">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                label:
                  'ticket.create.furniture_order.additional_agreement.title',
                value1:
                  ticketDataFurnitureOrder.teleWork === true
                    ? ('ticket.create.furniture_order.additional_agreement.options.agreement_added'
                      | translate)
                    : null,
              }
            "></ng-container>
        </ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.contact_person',
              value1: ticketDataFurnitureOrder.contactPerson
                ? ticketDataFurnitureOrder.contactPerson.firstName +
                  ' ' +
                  ticketDataFurnitureOrder.contactPerson.lastName +
                  ', ' +
                  ticketDataFurnitureOrder.contactPerson.email
                : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.psp_element.title',
              value1: ticketDataFurnitureOrder.pspElement,
            }
          "></ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>

  <su-slot-tab-content *ngIf="activeTab === tabs[1].id">
    <su-card
      [card]="cardDocuments"
      *ngIf="ticketDataFurnitureOrder$ | async as ticketDataFurnitureOrder">
      <div card-content>
        <ng-container>
          <mtx-grid
            #grid
            [columns]="columns"
            [data]="ticketDataFurnitureOrder.documents"
            [rowStriped]="true"
            [noResultText]="'grid.no_results' | translate"
            [cellSelectable]="false">
          </mtx-grid>
        </ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>
</ng-container>

<ng-container *ngIf="incidentClassName === incidentClassEnum.Relocation">
  <su-slot-tab-content *ngIf="activeTab === tabs[0].id">
    <su-card [card]="cardSummary">
      <div
        card-content
        data-debug="form-confirm"
        *ngIf="ticketDataRelocation$ | async as ticketDataRelocation">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'relocation.location_of_origin.incidentDescription',
              value1: ticketDataRelocation.description,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'relocation.location_of_origin.firstCheckBox',
              value1:
                ticketDataRelocation.infoConsent === true
                  ? ('shared.yes' | translate)
                  : ('shared.no' | translate),
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'relocation.location_of_origin.secondCheckBox',
              value1:
                ticketDataRelocation.spaceRequestReturn === true
                  ? ('shared.yes' | translate)
                  : ('shared.no' | translate),
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'relocation.location_of_origin.worker',
              value1: ticketDataRelocation.creator.firstName
                ? ticketDataRelocation.creator.firstName +
                  ' ' +
                  ticketDataRelocation.creator.lastName +
                  ', ' +
                  ticketDataRelocation.creator.email
                : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'relocation.location_of_origin.contact_person',
              value1: ticketDataRelocation.contactPerson.firstName
                ? ticketDataRelocation.contactPerson.firstName +
                  ' ' +
                  ticketDataRelocation.contactPerson.lastName +
                  ', ' +
                  ticketDataRelocation.contactPerson.email
                : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'relocation.location_of_origin.location',
              value1: ticketDataRelocation.addressStringOrigin
                ? ticketDataRelocation.addressStringOrigin
                : ticketDataRelocation.originAddressFirstName
                  ? ticketDataRelocation.originAddressFirstName +
                    ' ' +
                    ticketDataRelocation.originAddressLastName +
                    ', ' +
                    ticketDataRelocation.originAddressStreet +
                    ', ' +
                    ticketDataRelocation.originAddressPostalCode +
                    ' ' +
                    ticketDataRelocation.originAddressCity
                  : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'relocation.location_of_origin.target',
              value1: ticketDataRelocation.addressStringTarget
                ? ticketDataRelocation.addressStringTarget
                : ticketDataRelocation.targetAddressFirstName
                  ? ticketDataRelocation.targetAddressFirstName +
                    ' ' +
                    ticketDataRelocation.targetAddressLastName +
                    ', ' +
                    ticketDataRelocation.targetAddressStreet +
                    ', ' +
                    ticketDataRelocation.targetAddressPostalCode +
                    ' ' +
                    ticketDataRelocation.targetAddressCity
                  : null,
            }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              label: 'ticket.create.furniture_order.desired_date.title',
              value1: ticketDataRelocation.requestedDate
                ? (ticketDataRelocation.requestedDate | date: 'dd.MM.yyyy')
                : null,
            }
          "></ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>

  <su-slot-tab-content *ngIf="activeTab === tabs[1].id">
    <su-card
      [card]="cardDocuments"
      *ngIf="ticketDataRelocation$ | async as ticketDataRelocation">
      <div card-content>
        <ng-container>
          <mtx-grid
            #grid
            [columns]="columns"
            [data]="ticketDataRelocation.documents"
            [rowStriped]="true"
            [noResultText]="'grid.no_results' | translate"
            [cellSelectable]="false">
          </mtx-grid>
        </ng-container>
      </div>
    </su-card>
  </su-slot-tab-content>
</ng-container>

<ng-template
  #itemTemplate
  let-label="label"
  let-value1="value1"
  let-value2="value2"
  let-value3="value3">
  <div class="item-container">
    <div class="confirm-label">
      <span>{{ label | translate }}</span
      >:
    </div>
    <span class="confirm-value" *ngIf="value1">
      <ng-container>{{ value1 }}</ng-container>
      <ng-container *ngIf="value2">, {{ value2 }}</ng-container>
      <ng-container *ngIf="value3">, {{ value3 }}</ng-container>
    </span>
    <span class="confirm-value" *ngIf="!value1">--</span>
  </div>
</ng-template>

<ng-template
  #documentActionsTemplate
  let-row
  let-index="index"
  let-col="colDef">
  <div class="button-container">
    <su-button
      skin="secondary"
      icon="download"
      [title]="'Download'"
      (onClick)="downloadDocument($event, row)">
    </su-button>
  </div>
</ng-template>
