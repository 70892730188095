<scale-telekom-footer>
  <scale-telekom-footer-content>
    <span slot="notice"> © Deutsche Telekom AG </span>
    <ul slot="navigation">
      <ng-container *ngFor="let item of footerNavContent">
        <li>
          <a (click)="item.onClick()" style="cursor: pointer">
            {{ item.name }}</a
          >
        </li>
      </ng-container>
    </ul>
  </scale-telekom-footer-content>
</scale-telekom-footer>
