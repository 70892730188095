import { inject, Injectable } from '@angular/core';
import {
  BaseSelectViewModel,
  WorkingSpaceViewModel,
  LocationClient,
  WorkingSpaceClient,
  FrozenZoneViewModel,
} from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditWorkingSpaceService {
  private locationClient = inject(LocationClient);
  private workingSpaceClient = inject(WorkingSpaceClient);

  getWorkingSpacesByAreaCode(
    areaCode: string | undefined
  ): Observable<WorkingSpaceViewModel[]> {
    return this.workingSpaceClient.getWorkingSpacesByArea(areaCode);
  }

  updateWorkingSpaces(workingSpaces: WorkingSpaceViewModel[]): Observable<any> {
    return this.workingSpaceClient.updateWorkingSpaces(workingSpaces);
  }

  createWorkingSpaces(workingSpaces: WorkingSpaceViewModel[]): Observable<any> {
    return this.workingSpaceClient.createWorkingSpaces(workingSpaces);
  }

  getChangeReasons(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getChangeReasons();
  }

  getOfficeAreaTypes(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getOfficeAreaTypes();
  }

  checkFrozenZone(): Observable<FrozenZoneViewModel> {
    return this.locationClient.checkFrozenZone();
  }

  getSpaceNumberBySpaceId(spaceId: string): Observable<any> {
    return this.locationClient.getSpaceNumberBySpaceId(spaceId);
  }
}
