import { Injectable } from '@angular/core';
import { BreadcrumbSlotItem } from '@affinis/smartus-components/lib/breadcrumbs/breadcrumbs.types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbs: Array<BreadcrumbSlotItem> = [];

  private bc$ = new BehaviorSubject<any>(this.breadcrumbs);
  bcChange$ = this.bc$.asObservable();

  setBreadcrumbs(listSegments: any[]) {
    this.breadcrumbs = listSegments;
    this.bc$.next(listSegments);
  }

  resetBreadcrumbs() {
    this.breadcrumbs = [];
    this.bc$.next([]);
  }
}
