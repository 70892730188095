<div [style.background-color]="backgroundColor" [style.color]="fontColor">
  <div class="content-body" [class.row-container-reversed]="isReversed(index)">
    <div class="row-container">
      <div class="text-col">
        <h1 *ngIf="hasLargeHeader">{{ title }}</h1>
        <h2 *ngIf="!hasLargeHeader">{{ title }}</h2>
        <ng-content></ng-content>
      </div>
      <div class="optional-col" *ngIf="showButton">
        <app-button-wrapper
          [routerLink]="buttonLink"
          [buttonVariant]="buttonVariant || 'secondary'"
          [buttonText]="buttonText || ''">
        </app-button-wrapper>
      </div>
    </div>
    <div *ngIf="imgUrl" class="image-container">
      <img [src]="imgUrl" />
    </div>
  </div>
</div>
