import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs';
import { UserService } from '../oauth-service/user.service';
import { MaintenanceService } from './maintenance.service';

export const MaintenanceGuard = () => {
  const router = inject(Router);
  const userService = inject(UserService);
  const isInMaintenance$ = inject(MaintenanceService).get();
  const route = window.location.pathname;
  const parts = route.split('/');
  const base = parts[1];

  return isInMaintenance$.pipe(
    tap(isInMaintenance => {
      if (isInMaintenance) {
        if (base !== 'landing' && userService.loggedinB2C) {
          userService.OnLogoutClick();
        }
        router.navigate(['landing/maintenance']);
      }
    }),
    map(isInMaintenance => !isInMaintenance)
  );
};
