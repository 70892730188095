import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetIncidentDTO, IncidentClient } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

import { MsalService } from '@azure/msal-angular';
import { RoutePath } from '@core/routing/routing.types';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { IbpdiHelperService } from '@core/services/translate-service/ibpdi-helper.service';
import { TranslateDataService } from '@core/services/translate-service/translate-data.service';
import { IDetailFields } from '@shared/components/details/detail-fields/models/detail-fields.model';
import { DocumentVisibility } from '@shared/types/document.types';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Subscription, iif } from 'rxjs';

@Component({
  selector: 'app-ticket-status-base',
  templateUrl: './ticket-status-item.html',
  styleUrls: [],
})
export class TicketStatusItemBaseComponent {
  public data: any;

  public title?: string;

  public headline?: string = this.translateService.instant(
    'ticket.status.headline'
  );

  public detailFields: IDetailFields[];

  public isLoading = false;

  public notFound = false;

  public statusData: { eMail: string; ticketNumber } = {
    eMail: '',
    ticketNumber: '',
  };

  private queryParamsSubscription: Subscription;

  private getTicketStatusSubscription: Subscription;

  cardStatus: CardConfig;

  cardNotFound: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'ticket.details.error.ticketNotFound.title'
        ),
      },
    },
    footer: {
      button1: {
        label: 'Zurück',
        disabled: false,
        onClick: () => {
          const route = this.router.url;
          const parts = route.split('/');
          this.router.navigateByUrl(`${parts[1]}/status`);
        },
      },
    },
  };

  prepareOutput?: (data) => any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private incidentClient: IncidentClient,
    protected ibpdiHelperService: IbpdiHelperService,
    protected translateService: TranslateService,
    protected translateDataService: TranslateDataService,
    private breadcrumbService: BreadcrumbService,
    private msalService: MsalService
  ) {
    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.translateService.instant('ticket.status.title'),
        onClick: () => {
          this.router.navigateByUrl(RoutePath.TICKETSTATUS);
        },
      },
      {
        label: this.headline,
      },
    ]);

    this.getTicketData();
  }

  getTicketData() {
    this.isLoading = true;

    this.queryParamsSubscription = this.route.queryParams.subscribe({
      next: async queryParams => {
        const { email } = queryParams;
        const ticketNumber = this.route.snapshot.paramMap.get('id');
        if (ticketNumber) {
          const getIncidentDto: GetIncidentDTO = {
            ticketNumber,
            primaryContactEmail: email,
          };

          const request = iif(
            () => !!this.msalService.instance.getActiveAccount(),
            this.incidentClient.getIncidentWithEmailAuthenticated(
              getIncidentDto
            ),
            this.incidentClient.getIncidentWithEmail(getIncidentDto)
          );

          this.getTicketStatusSubscription = request.subscribe({
            next: data => {
              this.data = data;
              if (this.prepareOutput) {
                this.data = this.prepareOutput(data);
              }
              const fmDocumentField = this.detailFields.find(
                d => d.key != null && d.key === 'fmDocument'
              );
              if (fmDocumentField) {
                fmDocumentField.fileMetadata = [
                  {
                    attributeName: 'incidentId',
                    attributeValue: data.incidentId || 'error no incidentId',
                  },
                  {
                    attributeName: 'visibilityId',
                    attributeValue: DocumentVisibility.Public,
                  },
                  {
                    attributeName: 'ticketEmail',
                    attributeValue:
                      getIncidentDto.primaryContactEmail ||
                      'error no primaryContactEmail',
                  },
                  {
                    attributeName: 'ticketNumber',
                    attributeValue:
                      getIncidentDto.ticketNumber || 'error no ticketNumber',
                  },
                ];
              }
              this.statusData.eMail = getIncidentDto.primaryContactEmail;
              this.statusData.ticketNumber = getIncidentDto.ticketNumber;
              this.isLoading = false;
              this.notFound = !this.data;
            },
            error: () => {
              this.notFound = true;
              this.isLoading = false;
            },
          });
        } else {
          this.notFound = true;
          this.isLoading = false;
        }
      },
    });
  }

  ngOnDestory() {
    this.queryParamsSubscription.unsubscribe();
    this.getTicketStatusSubscription.unsubscribe();
  }
}
