import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function furnitureOptions(
  translateService: TranslateService,
  isTelekomLocation: boolean
): Array<FormlyFieldConfig> {
  const commonFields = [
    {
      type: 'chapter',
      props: {
        chapterTitle: translateService.instant(
          'ticket.create.furniture_order.flooring.title'
        ),
        visibleInConfirm: true,
      },
    },
    {
      wrappers: ['no-border'],
      key: 'roll',
      type: 'checkbox-multiple-options',
      props: {
        options: [
          {
            value: 'hard',
            label: translateService.instant(
              'ticket.create.furniture_order.flooring.options.hard'
            ),
          },
          {
            value: 'soft',
            label: translateService.instant(
              'ticket.create.furniture_order.flooring.options.soft'
            ),
          },
        ],
      },
    },
    {
      type: 'chapter',
      props: {
        chapterTitle: translateService.instant(
          'ticket.create.furniture_order.statement_BAD.title'
        ),
        visibleInConfirm: true,
      },
    },
    {
      wrappers: ['no-border'],
      key: 'statement',
      type: 'checkbox',
      defaultValue: false,
      props: {
        label: translateService.instant(
          'ticket.create.furniture_order.statement_BAD.options.statement_added'
        ),
      },
    },
    {
      type: 'chapter',
      props: {
        chapterTitle: translateService.instant(
          'ticket.create.furniture_order.electric_desk.title'
        ),
        visibleInConfirm: true,
      },
    },
    {
      wrappers: ['no-border'],
      key: 'electricDesk',
      type: 'checkbox',
      defaultValue: false,
      props: {
        label: translateService.instant(
          'ticket.create.furniture_order.electric_desk.options.statement_added'
        ),
      },
    },
    {
      type: 'chapter',
      props: {
        chapterTitle: translateService.instant(
          'ticket.create.furniture_order.special_order.title'
        ),
        visibleInConfirm: true,
      },
    },
    {
      wrappers: ['no-border'],
      key: 'specialOrder',
      type: 'checkbox',
      defaultValue: false,
      props: {
        label: translateService.instant(
          'ticket.create.furniture_order.special_order.options.statement_added'
        ),
      },
    },
    {
      type: 'chapter',
      props: {
        chapterTitle: translateService.instant(
          'ticket.create.furniture_order.severe_disability.title'
        ),
        visibleInConfirm: true,
      },
    },
    {
      wrappers: ['no-border'],
      key: 'severeDisability',
      type: 'checkbox',
      defaultValue: false,
      props: {
        label: translateService.instant(
          'ticket.create.furniture_order.severe_disability.options.consultation_rejection'
        ),
      },
    },
  ];

  if (isTelekomLocation) {
    return [
      {
        key: 'furnitureOptions',
        fieldGroup: commonFields,
      },
    ];
  } else {
    return [
      {
        key: 'furnitureOptions',
        fieldGroup: [
          ...commonFields,
          {
            type: 'chapter',
            props: {
              chapterTitle: translateService.instant(
                'ticket.create.furniture_order.additional_agreement.title'
              ),
              visibleInConfirm: true,
            },
          },
          {
            wrappers: ['no-border'],
            key: 'additionalAgreement',
            type: 'checkbox',
            defaultValue: false,
            props: {
              label: translateService.instant(
                'ticket.create.furniture_order.additional_agreement.options.agreement_added'
              ),
            },
          },
        ],
      },
    ];
  }
}
