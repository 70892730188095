<su-headline h="h1" skin="primary">{{ viewTitle }}</su-headline>

<ng-container *ngIf="showIncident">
  <div *ngIf="incident$ | async as incident; else loadingOrError">
    <app-document-upload [incident]="incident"></app-document-upload>
  </div>
</ng-container>

<ng-container *ngIf="showTask">
  <div *ngIf="task$ | async as task; else loadingOrError">
    <app-document-upload [task]="task"></app-document-upload>
  </div>
</ng-container>

<ng-template #loadingOrError>
  <div *ngIf="loadingError$ | async; else loading">
    <h4>{{ 'documents.loadingError' | translate }}</h4>
  </div>
  <ng-template #loading>
    <app-spinner-overlay [show]="true"></app-spinner-overlay>
  </ng-template>
</ng-template>
