import { Component } from '@angular/core';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintTelekomComponent {
  constructor(private bradcrubService: BreadcrumbService) {
    this.bradcrubService.setBreadcrumbs([
      {
        label: 'Impressum',
      },
    ]);
  }
}
