import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoutePath } from '@core/routing/routing.types';
import { CallbackComponent } from '@features/authentication/callback/callback.component';
import { DashboardComponent } from '@features/dashboard/components/dashboard/dashboard.component';
import { DocumentsComponent } from '@features/documents/documents.component';
import { ImprintComponent } from '@features/imprint/imprint.component';
import { PrivacyComponent } from '@features/privacy/privacy.component';
import { ProfileDefaultComponent } from '@features/profile-default/components/profile-default.component';
import { TaskDetailsComponent } from '@features/tasks/components/task-details/task-details.component';
import { TaskListComponent } from '@features/tasks/components/task-list/task-list.component';
import { TicketGetStatusDefaultComponent } from '@features/tickets/components/ticket-get-status/default/ticket-get-status-default';
import { TicketListComponent } from '@features/tickets/components/ticket-list/ticket-list.component';
import { TicketStatusItemBaseComponent } from '@features/tickets/components/ticket-status-item/base/ticket-status-item';
import { EditBuildingComponent } from '@mandants/telekom/features/edit-building/edit-building.component';
import { EditRoomComponent } from '@mandants/telekom/features/edit-room/edit-room.component';
import { EditStageComponent } from '@mandants/telekom/features/edit-stage/edit-stage.component';

export const defaultRoutes: Routes = [
  {
    path: RoutePath.AUTH_CALLBACK,
    component: CallbackComponent,
    canActivate: [MsalGuard],
  },
  {
    path: RoutePath.DOCUMENTS,
    children: [
      {
        path: '',
        component: DocumentsComponent,
      },
      {
        path: 'ticket/:ticketNumber',
        component: DocumentsComponent,
      },
      {
        path: 'incident/:incidentId',
        component: DocumentsComponent,
      },
      {
        path: 'task/:taskId',
        component: DocumentsComponent,
      },
    ],
  },
  {
    path: RoutePath.IMPRINT,
    component: ImprintComponent,
  },
  {
    path: RoutePath.DASHBOARD,
    component: DashboardComponent,
  },
  {
    path: RoutePath.NONE,
    component: DashboardComponent,
  },
  {
    path: RoutePath.PRIVACY,
    component: PrivacyComponent,
  },
  {
    path: RoutePath.PROFILE_DEFAULT,
    component: ProfileDefaultComponent,
  },
  {
    path: RoutePath.TASK,
    component: TaskDetailsComponent,
  },
  {
    path: RoutePath.TASKS,
    component: TaskListComponent,
  },
  {
    path: RoutePath.TICKETS,
    component: TicketListComponent,
  },
  {
    path: RoutePath.TICKETSTATUS,
    component: TicketGetStatusDefaultComponent,
  },
  {
    path: RoutePath.TICKETSTATUS_ITEM,
    component: TicketStatusItemBaseComponent,
  },
  {
    path: RoutePath.EDIT_BUILDING,
    component: EditBuildingComponent,
  },
  {
    path: RoutePath.EDIT_STAGE,
    component: EditStageComponent,
  },
  {
    path: RoutePath.EDIT_ROOM,
    component: EditRoomComponent,
  },
  {
    path: RoutePath.LOGOUT,
    redirectTo: RoutePath.DASHBOARD,
  },
];
