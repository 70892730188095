import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  EmploymentClient,
  EmploymentSearchResultDto,
  IncidentClient,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { EmployeeSearchFormlyFieldService } from '@core/services/employee-search-formly-field-service/employee-search-formly-field.service';
import { FileService } from '@core/services/file-service/file.service';
import { LocationFormlyFieldService } from '@core/services/formly-service/location-formly-field.service';
import { TicketCreateBaseComponent } from '@features/tickets/components/ticket-create/ticket-create.base';
import { MtxGridColumn, MtxGridModule } from '@ng-matero/extensions/grid';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { SafeHtmlPipe } from '@shared/pipes';
import {
  emailcc,
  fileAttachments,
} from '../tickets/ticket-create/field-configs';

@Component({
  selector: 'app-space-request',
  standalone: true,
  imports: [
    SmartusComponentsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatDatepickerModule,
    OverrideModule,
    SafeHtmlPipe,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MtxGridModule,
  ],
  templateUrl: './space-request.component.html',
  styleUrl: './space-request.component.scss',
})
export class SpaceRequestComponent
  extends TicketCreateBaseComponent
  implements OnInit
{
  private employeeSearchFormlyFieldService = inject(
    EmployeeSearchFormlyFieldService
  );
  private locationFormlyFieldService = inject(LocationFormlyFieldService);
  private breadcrumbService = inject(BreadcrumbService);
  private incidentClient = inject(IncidentClient);
  private fileService = inject(FileService);
  private employmentClient = inject(EmploymentClient);

  minDesiredDate = new Date();

  cardConfirm: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  currentUserEmail = this.msalService.instance.getActiveAccount()?.idTokenClaims
    ?.email as string;

  currentUser: EmploymentSearchResultDto;

  override form: FormGroup;

  spaceRequestColumns: MtxGridColumn[] = [
    {
      header: this.translateService.instant(
        'ticket.create.space_request.requested_area.quantity'
      ),
      field: 'quantity',
    },
    {
      header: this.translateService.instant(
        'ticket.create.space_request.requested_area.type'
      ),
      field: 'spaceType',
    },
    {
      header: this.translateService.instant(
        'ticket.create.space_request.requested_area.costcenter'
      ),
      field: 'costcenter',
    },
  ];

  spaceRequestData = [];

  ngOnInit() {
    this.form = new FormGroup({
      spaceRequest: new FormArray([]),
    });

    this.title = this.translateService.instant('Flächenanfrage');

    this.breadcrumbService.setBreadcrumbs([{ label: this.title }]);

    this.successTitle = this.translateService.instant(
      'ticket.create.order_access.successTitle'
    );
    this.successMessage = this.translateService.instant(
      'ticket.create.order_access.successMessage'
    );

    this.minDesiredDate.setDate(this.minDesiredDate.getDate() + 1);
    this.loading = true;
    this.loadingMessage = this.translateService.instant('form.loading');
    this.employmentClient.searchEmployee(this.currentUserEmail).subscribe({
      next: res => {
        this.currentUser = res.entites[0];
        this.formFields = this.getFormFields();
        this.loading = false;
      },
    });

    this.card = {
      header: {
        title: {
          label: '',
        },
      },
      footer: {
        button1: {
          label: this.translateService.instant('forward'),
          disabled: false,
          onClick: () => {
            this.fileService.setFileAttachmentType(this.form.value, 'SO7');
            this.validateForm();
          },
        },
      },
    };
  }

  getFormFields() {
    return [
      {
        wrappers: ['form-field'],
        key: 'shortTitle',
        type: 'input',
        props: {
          label: this.translateService.instant(
            'ticket.create.space_request.short_title'
          ),
          visibleInConfirm: true,
          required: true,
        },
      },
      {
        wrappers: ['form-field'],
        key: 'incidentDescription',
        type: 'textarea',
        props: {
          label: this.translateService.instant(
            'ticket.create.space_request.description'
          ),
          visibleInConfirm: true,
          required: true,
          autosize: true,
          autosizeMinRows: 5,
        },
      },
      {
        wrappers: [],
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant(
                'ticket.create.space_request.requested_location'
              ),
            },
          },
          ...this.locationFormlyFieldService.autocompleteSearch(
            false,
            null,
            true
          ),
          {
            type: 'datepicker',
            key: 'desiredDate',
            props: {
              label: this.translateService.instant(
                'ticket.create.space_request.requested_date'
              ),
              datepickerOptions: {
                min: this.minDesiredDate,
              },
              required: true,
            },
          },
          {
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'ticket.create.space_request.requested_area.title'
                  ),
                },
              },
              {
                type: 'space-request',
                key: 'spaceRequest',
                hooks: {
                  onInit: field => {
                    field?.formControl?.valueChanges.subscribe({
                      next: () => {
                        this.spaceRequestData = this.formModel['spaceRequest'];
                      },
                    });
                  },
                },
              },
            ],
          },
          ...fileAttachments(this.translateService, true),
          {
            wrappers: [],
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'ticket.create.headline__search_creator'
                  ),
                },
              },
              {
                key: 'searchContact',
                fieldGroup: [
                  ...this.employeeSearchFormlyFieldService.autocompleteSearch(
                    'ticket.create.placeholder__search_contact',
                    null,
                    true,
                    true,
                    this.currentUserEmail,
                    null,
                    null,
                    this.currentUser
                  ),
                ],
              },
            ],
          },
          {
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'ticket.create.label__processor'
                  ),
                },
              },
              {
                key: 'contactPerson',
                fieldGroup: [
                  ...this.employeeSearchFormlyFieldService.autocompleteSearch(
                    'ticket.create.placeholder__search_processor',
                    null,
                    false,
                    true
                  ),
                ],
              },
            ],
          },
          ...emailcc(this.translateService, this.onEmailChange.bind(this)),
        ],
      },
    ];
  }

  public onEmailChange(emails: string) {
    this.form.get('ccemail')?.setValue(emails as never);
  }

  pressBackToEdit() {
    this.filledForm = null;
  }

  public override clientEndpoint = data => {
    this.loadingMessage = this.translateService.instant('form.loading_message');

    // return this.incidentClient.createSpaceRequest(data);
    return null;
  };

  protected override prepareSubmit = data => {
    const d = data;
    console.log(d);
    // const spaceRequest: CreateSpaceRequest = {};
    // spaceRequest.shortTitle = d.shortTitle;
    // spaceRequest.incidentDescription = d.incidentDescription;
    // spaceRequest.archObjId = d.searchResult ? d.searchResult.id : null;
    // spaceRequest.requestedDate = d.desiredDate;
    // spaceRequest.spaceRequest = d.spaceRequest;
    // spaceRequest.editor = d.editor?.searchResult ?? null;
    // spaceRequest.contactPerson = d.contactPerson?.searchResult ?? null;
    // return { spaceRequest: spaceRequest };
    return null;
  };

  public override getStatusPath() {
    return '/dashboard';
  }
}
