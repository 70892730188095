<body>
  <div class="card-container">
    <su-card [card]="cardSupport">
      <div card-content>
        <p>{{ 'feedback.content' | translate }}</p>
        <img src="./../../assets/images/icons8-envelope-100.png" />
        <a [href]="'mailto:' + mailAddress">{{ mailAddress }}</a>
      </div>
    </su-card>
  </div>
</body>
