<div class="who-page">
  <app-quick-text
    [title]="'Unser Portfolio'"
    [imgUrl]="'assets/images/undraw_what1.svg'"
    [backgroundColor]="'#E20074'"
    [fontColor]="'white'"
    [hasLargeHeader]="true"
    [index]="1">
    <p>
      Wir verwalten über 9000 Immobilien auf einer Gesamtfläche von über acht
      Millionen qm. Erfahren Sie mehr über unser Standortportfolio.
    </p>
  </app-quick-text>

  <div class="overview-areas-title">
    <h2>Übersicht unserer betreuten Flächen</h2>
  </div>

  <app-column-container>
    <div card1>
      <img
        src="assets/images/telekom-real-estate-startseite-header-1500px.jpg"
        alt="placeholder" />
      <div class="card-content-body">
        <h4>Telekom Gebäude</h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor.
        </p>
      </div>
    </div>
    <div card2>
      <img
        src="assets/images/telekom-real-estate-startseite-header-1500px.jpg"
        alt="placeholder" />
      <div class="card-content-body">
        <h4>Büro- und Gewerbeflächen</h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor.
        </p>
      </div>
    </div>
    <div card3>
      <img
        src="assets/images/telekom-real-estate-startseite-header-1500px.jpg"
        alt="placeholder" />
      <div class="card-content-body">
        <h4>Wohnflächen</h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor.
        </p>
      </div>
    </div>
  </app-column-container>

  <app-fact-wrapper></app-fact-wrapper>

  <app-about></app-about>
</div>
