export enum UserRoles {
  SMARTPORTAL_DOCUMENT_READ_PREFIX = 'smartportal.document.read',
  SMARTPORTAL_DOCUMENT_WRITE_PREFIX = 'smartportal.document.write',
  SMARTPORTAL_DOCUMENT_IMAUPLOAD_PREFIX = 'smartportal.document.imaupload',
  SMARTPORTAL_DOCUMENT_VISIBILITY_GSUS = 'smartportal.document.visibility.gsus',
  SMARTPORTAL_DOCUMENT_VISIBILITY_SERVICEPROVIDER = 'smartportal.document.visibility.serviceprovider',
  SMARTPORTAL_DOCUMENT_SETVISIBILITY_GLOBAL = 'smartportal.document.setvisibilitylabel.global',
  SMARTPORTAL_DOCUMENT_DELETE_BASIC = 'smartportal.document.delete.basic',
  SMARTPORTAL_DOCUMENT_DELETE_LOCAL = 'smartportal.document.delete.local',
  SMARTPORTAL_DOCUMENT_DELETE_GLOBAL = 'smartportal.document.delete.global',
  SMARTPORTAL_LOCATION_DATA_MANAGER = 'smartportal.location.data_manager',
  SMARTPORTAL_LOCATION_MAIL = 'smartportal.location.mail',
  SMARTPORTAL_REM_DATENPFLEGE = 'smartportal.datamanagement.global',
  SMARTPORTAL_REM_FLM_DATENPFLEGE = 'smartportal.datamanagement.flm',
  SMARTPORTAL_MODULE_HELPDESK = 'smartportal.module.helpdesk',
  SMARTPORTAL_MODULE_LOCATION = 'smartportal.module.location',
  SMARTPORTAL_OCCUPANCY_CREATE = 'smartportal.occupancy.create',
  SMARTPORTAL_AREAREQUEST_CREATE = 'smartportal.arearequest.create',
  SMARTPORTAL_AREARETURN_CREATE = 'smartportal.areareturn.create',
  SMARTPORTAL_RELOCATION_CREATE = 'smartportal.relocation.create',
  SMARTPORTAL_DATAMANAGEMENT_GLOBAL = 'smartportal.datamanagement.global',
}
