<div class="rental-search">
  <div class="grid-container">
    <div class="header-item">
      <h1 [attr.data-text]="'Nice to MIET you!'">Nice to MIET you!</h1>
    </div>
    <div class="picture-item">
      <img
        src="../../../assets/images/Map Webseite White.png"
        alt="Fixed Image"
        class="fixed-image" />
    </div>
    <div class="rental-objects">
      <div class="card-content" *ngIf="!selectedItem">
        <scale-card
          class="card-style"
          target="_blank"
          rel="noopener noreferrer"
          label="Filter">
          <div class="row row-style">
            <scale-text-field
              class="text-field"
              id="text-field"
              label="Suche"
              [value]="inputValue"
              (scaleInput)="setInputValue($event)"
              placeholder="Ort, PLZ"
              inputModeType="search">
            </scale-text-field>
            <scale-slider
              id="slider"
              class="slider"
              label="Umkreis in km"
              [value]="sliderValue"
              (scaleInput)="setSliderValue($event)"
              step="5"
              min="5"
              max="50">
            </scale-slider>
          </div>
        </scale-card>
      </div>
      <div
        class="app-rental-items-container"
        [ngClass]="{ loading: isLoading }">
        <div *ngIf="isLoading" class="loading-spinner">
          <scale-loading-spinner
            size="large"
            variant="white"
            alignment="vertical"
            text="Daten werden geladen..."
            class="rental-search-spinner"></scale-loading-spinner>
        </div>
        <div *ngIf="!isLoading">
          <div *ngIf="!selectedItem">
            <app-rental-item
              [items]="tiles"
              (selectedItem)="onItemSelect($event)"></app-rental-item>
          </div>
          <div *ngIf="selectedItem">
            <app-rental-item-details
              [location]="selectedItem"
              [similarLocations]="tiles"
              (goBack)="onGoBack()"></app-rental-item-details>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
