<div class="contact-page">
  <app-quick-text
    [title]="'Wartungsarbeiten'"
    [imgUrl]="'assets/images/telekom_maintenance.png'"
    [index]="1"
    [backgroundColor]="'#E20074'"
    [fontColor]="'white'"
    [hasLargeHeader]="true">
    <p style="padding-bottom: 50vh">
      {{maintenanceMessage}}
      <br /><br />
      {{'maintenance.info' | translate}}
    </p>
  </app-quick-text>
</div>
