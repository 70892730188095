import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseSelectViewModel } from '@core/api';
import { EditBuildService } from '@core/services/edit-building/edit-building.service';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-maintenance-building-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SmartusComponentsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule,
    FormlyModule,
    OverrideModule,
  ],
  templateUrl: './maintenance-building-edit-modal.component.html',
  styleUrl: './maintenance-building-edit-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceBuildingEditModalComponent implements OnInit {
  private translateService = inject(TranslateService);
  private editBuildingService = inject(EditBuildService);
  private toastr = inject(ToastrService);
  private cd = inject(ChangeDetectorRef);

  form: FormGroup;
  formFields: FormlyFieldConfig[];
  formModel: {
    validFrom: string;
    validUntil: string;
    buildingClassId: string;
    changeReason: string;
    createdOn: string;
    changedOn: string;
  } = {
    validFrom: '',
    validUntil: '',
    buildingClassId: '',
    changeReason: '',
    createdOn: '',
    changedOn: '',
  };
  buildingUsageOptions: Array<{ name: string; selected: boolean }> = [];
  initialBuildingUsage: string;
  initialChangeReason: string;
  buildingClasses: any[] = [];
  archObjUtilHistoryId: string;
  changeReasons: BaseSelectViewModel[];
  saveDisabled: boolean = true;
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MaintenanceBuildingEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      validFrom: [
        { value: data.validFrom, disabled: true },
        Validators.required,
      ],
      validUntil: [
        { value: data.validUntil, disabled: true },
        Validators.required,
      ],
      buildingClassId: [data.buildingClassId, Validators.required],
      createdOn: [{ value: data.createDate, disabled: true }],
      changedOn: [{ value: data.changeDate, disabled: true }],
    });
    this.archObjUtilHistoryId = data.id;
  }

  ngOnInit(): void {
    this.changeReasons = this.data.changeReasons;
    this.buildingClasses = Object.values(this.data.buildingClasses);
    this.initialBuildingUsage = this.data.buildingClassId || '';
    this.initialChangeReason = this.data.changeReason || '';
    this.initializeFormData();

    this.form.valueChanges.subscribe(() => {
      this.isSaveDisabled();
    });

    this.isSaveDisabled();
  }

  initializeFormData(): void {
    this.formModel = {
      validFrom: this.data.validFrom || '',
      validUntil: this.data.validUntil || '',
      buildingClassId: this.data.buildingClassId || '',
      changeReason: this.data.changeReason || '',
      createdOn: this.data.createDate || '',
      changedOn: this.data.changeDate || '',
    };

    this.formFields = this.getFormFields();
    this.form = this.fb.group({
      validFrom: [this.formModel.validFrom],
      validUntil: [this.formModel.validUntil],
      buildingClassId: [this.formModel.buildingClassId, Validators.required],
      changeReason: [this.formModel.changeReason, Validators.required],
      createdOn: [this.formModel.createdOn],
      changedOn: [this.formModel.changedOn],
    });
  }

  getFormFields(): FormlyFieldConfig[] {
    return [
      {
        type: 'formly-group',
        fieldGroup: [
          {
            key: 'validFrom',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.validFrom'
              ),
              disabled: true,
            },
          },
          {
            key: 'validUntil',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.validUntil'
              ),
              disabled: true,
            },
          },
          {
            key: 'buildingClassId',
            type: 'select',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.buildingUsage'
              ),
              options: this.buildingClasses.map(usage => ({
                label: usage.buildingClassId,
                value: usage.buildingClassId,
              })),
              required: true,
            },
          },
          {
            key: 'changeReason',
            type: 'select',
            props: {
              label: this.translate('buildingMaintenance.table.changeReason'),
              options: this.changeReasons.map(
                (reason: BaseSelectViewModel) => ({
                  value: reason.name,
                  label: reason.id,
                })
              ),
              required: true,
            },
          },
          {
            key: 'createdOn',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.createdOn'
              ),
              disabled: true,
            },
          },
          {
            key: 'changedOn',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.changedOn'
              ),
              disabled: true,
            },
          },
        ],
      },
    ];
  }

  isSaveDisabled(): void {
    const currentBuildingClass = this.form.get('buildingClassId')?.value;
    const currentChangeReason = this.form.get('changeReason')?.value;

    const isBuildingClassChanged =
      currentBuildingClass !== this.initialBuildingUsage;
    const isChangeReasonChanged =
      currentChangeReason !== this.initialChangeReason;

    this.saveDisabled = !(isBuildingClassChanged && isChangeReasonChanged);
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const formValue = this.form.value;

      const requestData = {
        ...formValue,
        archObjUtilHistoryId: this.archObjUtilHistoryId,
      };

      this.editBuildingService.updateArchObjUtilHistory(requestData).subscribe({
        next: () => {
          this.isLoading = false;
          this.toastr.success(
            this.translate('buildingMaintenance.toastr.success_edit')
          );
          this.dialogRef.close(requestData);
        },
        error: () => {
          this.isLoading = false;
          this.cd.detectChanges();
          this.toastr.error(
            this.translate('buildingMaintenance.toastr.error_edit')
          );
          this.dialogRef.close(requestData);
        },
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
