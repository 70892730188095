import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RegexpPhoneInt } from '@shared/utils/regex';
import { federalStatesDe } from '@shared/utils/federalStatesDe';

export const phoneConstraints = {
  pattern: RegexpPhoneInt,
  minLength: 8,
  maxLength: 24,
};

export function federalList(currentLang: string) {
  const langKey = currentLang === 'de-DE' ? 'name_de' : 'name_en';

  const states = [{ label: '--', value: '' }];

  states.push(
    ...federalStatesDe
      .map(c => {
        return {
          label: c[langKey],
          value: c.value,
        };
      })
      .sort((a, b) => {
        if (a.label === b.label) {
          return 0;
        }
        return a.label < b.label ? -1 : 1;
      })
  );
  return states;
}

export function phoneValidationMessages(translateService: TranslateService) {
  return {
    messages: {
      pattern: () => translateService.instant('formErrors.phone.pattern'),

      minlength: err => {
        return `${translateService.instant('formErrors.phone.minLength', {
          length: err.requiredLength,
        })}`;
      },
      maxlength: err => {
        return `${translateService.instant('formErrors.phone.maxLength', {
          length: err.maxLength,
        })}`;
      },
    },
  };
}

export function newSalutation(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      type: 'flex-layout',

      fieldGroup: [
        {
          type: 'select',
          key: 'salutation',
          props: {
            label: translateService.instant('ticket.create.label__salution'),
            visibleInConfirm: true,
            options: [
              {
                value: '',
                label: translateService.instant('ticket.create.salution.none'),
              },
              {
                value: 'Herr',
                label: translateService.instant('ticket.create.salution.mr'),
              },
              {
                value: 'Frau',
                label: translateService.instant('ticket.create.salution.ms'),
              },
            ],
          },
        },
        {
          type: 'input',
          key: 'jobTitle',
          props: {
            visibleInConfirm: true,
            label: translateService.instant('ticket.create.label__jobtitel'),
            maxLength: 100
          },
        },
      ],
    },
  ];
}
