<su-headline h="h1" skin="primary">{{ headline }}</su-headline>

<su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>

<su-slot-tab-content *ngIf="activeTab === tabs[0].id">
  <div class="row-chips">
    <su-chip [items]="chips" (onRemove)="handleOnRemoveChip($event)"></su-chip>
  </div>
  <mtx-grid
    id="users"
    [columns]="columnsUsers"
    [data]="userData | async"
    [loading]="loadingData"
    [rowStriped]="true"
    [showPaginator]="true"
    [cellSelectable]="false"
    [showToolbar]="true"
    [showColumnMenuButton]="true"
    columnMenuButtonType="flat"
    [columnMenuButtonText]="'grid.columns_shown' | translate"
    [toolbarTemplate]="toolbarTpl"
    (rowClick)="navigateToDetails($event.rowData)">
  </mtx-grid>
</su-slot-tab-content>

<ng-template #chipsTemplate let-row let-index="index" let-col="colDef">
  <div class="chips-group">
    <ng-container *ngIf="roles$ | async as roles">
      <ng-container *ngFor="let item of row[col.field]">
        <span *ngIf="(roles | filterPipe: item.id : 'id')[0]" class="chip">
          {{ (roles | filterPipe: item.id : 'id')[0]?.description }}
        </span>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #toolbarTpl>
  <su-slot-controls
    [mainTLabel]="'crud.filter' | translate"
    [buttons]="controlButtons"
    [chips]="chips"
    [menuItems]="menuItems"
    [dialogData]="dialogData"
    [modal]="modalComponent"></su-slot-controls>
</ng-template>

<su-slot-tab-content *ngIf="activeTab === tabs[1].id">
  <su-card [card]="cardUser">
    <div card-content>
      <formly-form [form]="form" [fields]="formFields" [model]="formModel">
      </formly-form>
    </div>
  </su-card>
</su-slot-tab-content>

<app-spinner-overlay [show]="loading"></app-spinner-overlay>
