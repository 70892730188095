import { ButtonProps } from '@affinis/smartus-components/lib/button/button.types';
import { Chip } from '@affinis/smartus-components/lib/chips/chips.component.types';
import { MenuItem } from '@affinis/smartus-components/lib/menu-extra/menu-extra.component';
import {
  Component,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BaseSearchOptions,
  IncidentClient,
  IncidentFilterOptions,
  IncidentSearchOptions,
  IncidentTableEntryTaskViewModel,
  IncidentTableEntryViewModel,
  IncidentViews,
  OmContact,
  OptionSetClient,
  TaskClient,
  TaskSearchOptions,
} from '@core/api';
import { RoutePath } from '@core/routing/routing.types';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { IbpdiHelperService } from '@core/services/translate-service/ibpdi-helper.service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalFilterComponent } from '@shared/components/modal-filter/modal-filter.component';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import { ITableConfig } from '@shared/components/table-base/table-base.types';
import * as moment from 'moment';
import { Subscription, first } from 'rxjs';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})
export class TicketListTelekomComponent
  extends TableBaseComponent
  implements OnInit
{
  override config = this.configure(<ITableConfig>{
    title: 'taskmodule.tickets.list.title',
    translatePath: 'taskmodule.shared',
  });

  form = new FormGroup({});
  user: OmContact;

  @ViewChild('ticketActionsTemplate', { static: true })
  ticketActionsTemplateRef!: TemplateRef<void>;

  @ViewChild('contactButtonTemplate', { static: true })
  contactButtonTemplate!: TemplateRef<void>;

  controlButtons: ButtonProps[] = [
    {
      label: this.translateService.instant('crud.refresh'),
      onClick: () => this.refresh(),
    },
  ];

  private optionSetSubscription: Subscription;

  organisationName: string;

  organisations: string[] = [
    'ISS Vermietung',
    'ISS Property Management',
    'ISS An-/Abmietung',
    'ISS Capital Projects',
    'ISS Vertragsmanagement',
  ];

  constructor(
    injector: Injector,
    private ibpdiHelperService: IbpdiHelperService,
    public incidentClient: IncidentClient,
    private taskClient: TaskClient,
    private optionSetClient: OptionSetClient,
    private breadcrumbsService: BreadcrumbService
  ) {
    super(injector);
  }

  public ngOnInit() {
    this.breadcrumbsService.setBreadcrumbs([
      {
        label: this.translateService.instant('taskmodule.tickets.list.title'),
      },
    ]);

    this.columns = [
      {
        field: 'showExpand',
        showExpand: true,
        sortable: false,
        resizable: false,
        width: '90px',
      },
      {
        header: this.translate('label__ticketNumber'),
        field: 'ticketNumber',
        sortable: true,
        class: '',
        resizable: true,
        minWidth: 180,
      },
      {
        header: this.translate('label__incidentTitle'),
        field: 'title',
        sortable: true,
        class: 'truncated',
        resizable: true,
        width: '200dp',
        minWidth: 200,
      },
      {
        header: this.translate('label__incidentDescription'),
        field: 'description',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
        hide: true,
      },
      {
        header: this.translate('label__incidentClass'),
        field: 'ticketClass',
        sortable: true,
        class: '',
        resizable: true,
      },
      {
        header: this.translate('label__economicUnit'),
        field: 'economicUnit',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translate('label__postalCode'),
        field: 'postalCode',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translate('label__incidentCity'),
        field: 'city',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translate('label__incidentStreet'),
        field: 'street',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translate('label__createdOn'),
        field: 'createdOn',
        sortable: true,
        class: 'truncated',
        formatter: (data: any) =>
          data.createdOn
            ? moment(data.createdOn)
                .locale(this.translateService.instant('locale.locale'))
                .format(
                  this.translateService.instant(
                    'locale.moment__date_time_format'
                  )
                ) +
              ' ' +
              this.translate('extended_time_str')
            : '--',
        resizable: true,
        width: '140px',
        minWidth: 134,
      },
      {
        header: this.translate('label__statusReason'),
        field: 'status',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translate('label__documents'),
        width: '120px',
        field: 'action',
        cellTemplate: this.ticketActionsTemplateRef,
        resizable: true,
      },
    ];
    this.userClient.getUserProfile().subscribe({
      next: profile => {
        this.user = profile;
      },
      error: err => {
        if (err.errorCode === 5002) {
          let count = 0;
          this.userService.isGsusUserBehavior$.subscribe({
            next: isGsus => {
              this.sysUser = isGsus;
              if (this.sysUser) {
                this.chips = [
                  {
                    id: 'ticketTaskSelection',
                    label:
                      'Ticket-/Aufgabenstatus: Tickets mit aktiven Aufgaben',
                  },
                ];
                this.filterFields[0].fieldGroup[
                  this.filterFields[0].fieldGroup.findIndex(
                    i => i.key === 'ticketTaskSelection'
                  )
                ].props.options = [
                  {
                    label: 'Tickets mit aktiven Aufgaben',
                    value: 'TicketsWithActiveTasks',
                  },
                  {
                    label: 'Alle anzeigen',
                    value: 'All',
                  },
                ];
                if (
                  !this.filterFields.some(
                    field => field.key === 'organisation'
                  ) &&
                  count === 0
                ) {
                  count = 1;
                  this.filterFields[0].fieldGroup.push({
                    type: 'select',
                    key: 'organisation',
                    props: {
                      label: 'Firma',
                      options: this.organisations.map(organisation => ({
                        label: organisation,
                        value: organisation,
                      })),
                    },
                  });
                }
              }
            },
          });
        }
      },
    });
  }

  chips: Chip[] = [];

  menuItems: MenuItem[] = [];

  override columns: MtxGridColumn[] = [];

  override filterFields: Array<FormlyFieldConfig> = [
    {
      type: 'flex-layout',
      fieldGroup: [
        {
          key: 'ticketNumber',
          type: 'input',
          props: {
            label: this.translate('label__ticketNumber'),
          },
        },
        {
          key: 'name',
          type: 'input',
          props: {
            label: this.translate('label__title'),
          },
        },
        {
          key: 'ticketDescription',
          type: 'input',
          props: {
            label: this.translate('label__incidentDescription'),
          },
        },
        {
          key: 'ticketClass',
          type: 'input',
          props: {
            label: this.translate('label__incidentClass'),
          },
        },
        {
          key: 'economicUnit',
          type: 'input',
          props: {
            label: this.translate('label__economicUnit'),
          },
        },
        {
          key: 'postalcode',
          type: 'input',
          props: {
            label: this.translate('label__postalCode'),
          },
        },
        {
          key: 'city',
          type: 'input',
          props: {
            label: this.translate('label__incidentCity'),
          },
        },
        {
          key: 'streetName',
          type: 'input',
          props: {
            label: this.translate('label__incidentStreet'),
          },
        },
        {
          type: 'flex-layout-col',
          fieldGroup: [
            {
              type: 'datepicker',
              key: 'createDateFrom',
              props: {
                label: this.translate('label__createDateFrom'),
              },
            },
            {
              type: 'datepicker',
              key: 'createDateTo',
              props: {
                label: this.translate('label__createDateTo'),
              },
            },
          ],
        },
        {
          key: 'statusreasonList',
          type: 'select',
          props: {
            label: this.translate('label__statusReason'),
            options: [],
            multiple: true,
            loading: true,
            readonly: true,
          },
          hooks: {
            onInit: field => {
              if (field?.props != null) {
                const f = field.props;

                if (f.options && (f.options as Array<any>).length > 0) {
                  return;
                }
                f.loading = true;
                this.optionSetSubscription = this.optionSetClient
                  .getOptionSetByString('Incident_StatusCode')
                  .pipe(first())
                  .subscribe({
                    next: res => {
                      f.options = res.map(option => {
                        return {
                          label:
                            this.ibpdiHelperService.getTranslatedEnumLable(
                              option
                            ),
                          value: option.defaultName,
                        };
                      });
                      f.readonly = false;
                      if (field?.props != null) {
                        f.loading = false;
                      }
                      this.cd.detectChanges();
                    },
                  });
              }
            },
            onDestroy: _field => {
              if (!this.optionSetSubscription.closed) {
                this.optionSetSubscription.unsubscribe();
              }
            },
          },
        },
        {
          type: 'select',
          key: 'ticketTaskSelection',
          props: {
            label: 'Ticket-/Aufgabenstatus',
            options: [
              {
                label: 'Meine Tickets mit aktiven Aufgaben',
                value: 'MyTicketsWithActiveTasks',
              },
              {
                label: 'Tickets mit aktiven Aufgaben',
                value: 'TicketsWithActiveTasks',
              },
              {
                label: 'Alle anzeigen',
                value: 'All',
              },
            ],
          },
        },
      ],
    },
  ];

  modalComponent = ModalFilterComponent;

  override getTableData$ = (
    query?: BaseSearchOptions,
    filterOptions?: IncidentFilterOptions
  ) => {
    //console.log('[Ticket List] getTableData$')

    (this.form.value as any).ticketTaskSelection =
      filterOptions?.ticketTaskSelection == null
        ? 'TicketsWithActiveTasks'
        : filterOptions?.ticketTaskSelection;
    let view: IncidentViews = IncidentViews.VisibleByTaskAssociation;

    if ((this.form.value as any).ticketTaskSelection === 'All') {
      view = IncidentViews.VisibleByOneTaskAssociation;
    } else if (
      (this.form.value as any).ticketTaskSelection ===
      'MyTicketsWithActiveTasks'
    ) {
      view = IncidentViews.VisibleByMyTaskAssociation;
    }
    const searchOptions: IncidentSearchOptions = {
      ...query,
      filterOptions,
      viewOptions: { selectedView: view },
    };
    return this.incidentClient.getIncidentsAuthenticated(searchOptions);
  };

  getTasks(incident: IncidentTableEntryViewModel) {
    if (
      this.config.grid.filter.filterOptions.ticketTaskSelection ===
      'TicketsWithActiveTasks'
    ) {
      if (this.config.grid.filter.filterOptions.organisation) {
        incident.tasks = incident.tasks.filter(
          x => x.organisationName === this.organisationName
        );
      } else if (this.sysUser) {
        incident.tasks = incident.tasks.filter(x => this.checkOrganisation(x));
      }
    }
    const searchOptions: TaskSearchOptions = {
      filterOptions: { ticketNumber: incident.ticketNumber },
    };
    this.taskClient.getTasks(searchOptions);
  }

  override clickRow = (data, router: Router, route: ActivatedRoute) => {
    router.navigate(['..', 'tasks'], {
      relativeTo: route,
      queryParams: {
        ticketNumber: data.rowData.ticketNumber,
      },
    });
  };

  override clickAdd = (router: Router, route: ActivatedRoute) => {
    router.navigate(['create'], { relativeTo: route });
  };

  dialogData = {
    columns: this.columns,
    filterFields: this.filterFields,
    filterValues: this.config.grid.filter.filterOptions,
    actions: {
      onSubmit: (formModel: any) => {
        this.mapFormModelToChips(formModel);
        this.updateFilter(formModel);
        this.refreshFromFilter();
        this.dialogData.filterValues = this.config.grid.filter.filterOptions;
        this.organisationName =
          this.config.grid.filter.filterOptions.organisation;
      },
      onReset: () => {
        this.mapFormModelToChips({});
        this.updateFilter({});
        this.refreshFromFilter();
      },
    },
  };

  clickExpandedRow(row: IncidentTableEntryTaskViewModel) {
    if (!row.isActive && row.organisationId === this.user.organisationId) {
      this.router.navigate(['/task/viewDetails', row.taskId]);
      return;
    }

    if (!row.isActive && row.organisationId !== this.user.organisationId)
      return;

    if (
      row.status === 'Abgeschlossen' ||
      (row.status === 'Storniert' &&
        row.organisationId === this.user.organisationId)
    ) {
      this.router.navigate([RoutePath.TASK_VIEW.replace(':id', row.taskId)]);
    } else {
      this.router.navigate([RoutePath.TASK.replace(':id', row.taskId)]);
    }
  }

  clickExpandedRowGSUS(row: IncidentTableEntryTaskViewModel) {
    this.router.navigate(['/task/viewDetails', row.taskId]);
  }

  openDocuments(event: MouseEvent, ticket: IncidentTableEntryViewModel) {
    event.stopPropagation();
    this.router.navigate([
      `${RoutePath.DOCUMENTS}/ticket/${ticket.ticketNumber}`,
    ]);
    return false;
  }

  openContact(event: MouseEvent, row: IncidentTableEntryTaskViewModel): void {
    event.stopPropagation();
    const taskId = row.taskId;

    const url = `/task/details/${taskId}`;
    this.router
      .navigate([url], { queryParams: { tab: 'contact' } })
      .catch(err => {
        console.error('Navigation error:', err);
      });
  }

  openContactDetails(
    event: MouseEvent,
    row: IncidentTableEntryTaskViewModel
  ): void {
    event.stopPropagation();
    const taskId = row.taskId;

    const url = `/task/viewDetails/${taskId}`;
    this.router
      .navigate([url], { queryParams: { tab: 'contact' } })
      .catch(err => {
        console.error('Navigation error:', err);
      });
  }

  checkOrganisation(task: IncidentTableEntryTaskViewModel) {
    if (this.config.grid.filter.filterOptions.organisation) {
      return (
        this.config.grid.filter.filterOptions.organisation ===
        task.organisationName
      );
    }
    return this.organisations.includes(task.organisationName);
  }

  onCloseDialog = (e: any) => {
    console.log('[Ticket list] onCloseDialog', e);
  };

  handleOnRemoveChip = (eventObj: any) => {
    delete this.config.grid.filter.filterOptions[eventObj.item.id];
    this.chips = this.chips.filter(
      chipItem => chipItem.id !== eventObj.item.id
    );
    this.refreshFromFilter();
  };

  mapFormModelToChips = formModel => {
    if (this.sysUser) {
      this.chips = [
        {
          id: 'ticketTaskSelection',
          label: 'Ticket-/Aufgabenstatus: Tickets mit aktiven Aufgaben',
        },
      ];
      this.chips.length = 1;
    } else {
      this.chips = [];
      this.chips.length = 0;
    }

    Object.keys(formModel).forEach(formModelItemKey => {
      const field = this.filterFields[0].fieldGroup?.find(fieldItem => {
        return fieldItem.key === formModelItemKey;
      });
      if (field) {
        if (formModelItemKey === 'ticketTaskSelection') {
          const option = (field.props.options as any[]).find(
            opt => opt.value === formModel[formModelItemKey]
          );
          if (option) {
            if (this.sysUser) {
              const existingChip = this.chips.find(
                chip => chip.id === formModelItemKey
              );
              if (existingChip) {
                this.chips.splice(this.chips.indexOf(existingChip), 1, {
                  id: formModelItemKey,
                  label: `${field.props.label}: ${option.label}`,
                });
              } else {
                this.chips.push({
                  id: formModelItemKey,
                  label: `${field.props.label}: ${option.label}`,
                });
              }
            } else {
              this.chips.push({
                id: formModelItemKey,
                label: `${field.props.label}: ${option.label}`,
              });
            }
          }
        } else if (
          formModelItemKey === 'createDateTo' ||
          formModelItemKey === 'createDateFrom'
        ) {
          const date = new Date(formModel[formModelItemKey]).toLocaleDateString(
            'de-DE'
          );
          this.chips.push({
            id: formModelItemKey,
            label: `${field.props.label}: ${date}`,
          });
        } else if (formModel[formModelItemKey]) {
          this.chips.push({
            id: formModelItemKey,
            label: `${field.props.label}: ${formModel[formModelItemKey]}`,
          });
        }
      }
    });
  };
}
