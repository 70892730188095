import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-chapter',
  styles: [
    `
      .chapter-headline {
        margin-top: 20px;
      }
      .bold {
        font-weight: bold;
      }
      .link {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
      }
      .chapter-description {
        padding-left: 15px;
        font-size: 12px;
      }
    `,
  ],
  template: `
    <hr *ngIf="field.props?.chapterHr" />
    <h3
      class="bold"
      style="font-size: 1rem;"
      [innerHTML]="field.props?.chapterTitleBold || ''"
      #tooltip="matTooltip"
      [matTooltip]="field.props?.tooltip"></h3>
    <h3
      class="chapter-headline"
      [innerText]="field.props?.chapterTitle || ''"
      #tooltip="matTooltip"
      [matTooltip]="field.props?.tooltip"></h3>
    <span
      *ngIf="field.props?.description"
      class="chapter-description"
      [innerText]="field.props?.description || ''"></span>
    <su-button
      *ngIf="field.props?.tooltip"
      [skin]="'accent'"
      [icon]="'question-circle'"
      (onClick)="tooltip.toggle()"></su-button>
    <p [innerText]="field.props?.chapterText || ''"></p>
  `,
})
export class FormlyChapterComponent extends FieldType {}
