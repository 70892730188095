import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  CreateIncidentAuthenticatedFromPortalDTO,
  IncidentClient,
  IncidentType,
} from '@core/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CiamService } from '@core/services/ciam-service/ciam-service';

@Component({
  selector: 'app-prospective-tenants-telekom',
  templateUrl: './prospective-tenants.component.html',
  styleUrls: ['./prospective-tenants.component.scss'],
})
export class ProspectiveTenantsTelekomComponent {
  form = new FormGroup({});

  model = {};

  public isLoading = false;

  fields: FormlyFieldConfig[] = [
    {
      key: 'nameOfProspectiveTenantsId',
      type: 'input',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.name'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.name'
        ),
        required: true,
      },
    },
    {
      key: 'requestedArea',
      type: 'input',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.requested_area'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.requested_area'
        ),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'requestedParkingSpaces',
      type: 'input',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.requested_parking_spaces'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.requested_parking_spaces'
        ),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'pricePerSquareMeter',
      type: 'input',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.price_per_square_meter'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.price_per_square_meter'
        ),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'rentalPricePerParkingSpace',
      type: 'input',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.rental_price_per_parking_space'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.rental_price_per_parking_space'
        ),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'durationMonths',
      type: 'input',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.duration_months'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.duration_months'
        ),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'setupRenterDecisionId',
      type: 'radio',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.preparation_necessary'
        ),
        required: true,
        options: [
          {
            label: this.translateService.instant(
              'taskmodule.shared.label__choice_yes'
            ),
            value: 'yes',
          },
          {
            label: this.translateService.instant(
              'taskmodule.shared.label__choice_no'
            ),
            value: 'no',
          },
        ],
      },
    },
    {
      key: 'descriptionTenantSpecificPreparation',
      type: 'input',
      hide: true,
      props: {
        label: 'Beschreibung mieterspezifische Herrichtungsmaßnahmen',
        placeholder: 'Beschreibung mieterspezifische Herrichtungsmaßnahmen',
        required: true,
      },
      expressionProperties: {
        hide: model => {
          return model.setupRenterDecisionId !== 'yes';
        },
      },
    },
    {
      key: 'maintenanceMeasuresId',
      type: 'radio',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.maintenance_measures'
        ),
        required: true,
        options: [
          {
            label: this.translateService.instant(
              'taskmodule.shared.label__choice_yes'
            ),
            value: 'yes',
          },
          {
            label: this.translateService.instant(
              'taskmodule.shared.label__choice_no'
            ),
            value: 'no',
          },
        ],
      },
    },
    {
      key: 'incentives',
      type: 'input',
      props: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.incentives'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.incentives'
        ),
        required: true,
      },
    },
  ];

  public preparation = false;

  public isPreparationNecessary;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProspectiveTenantsTelekomComponent>,
    public incidentClient: IncidentClient,
    public translateService: TranslateService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private ciamService: CiamService
  ) {}

  public onOk() {
    this.isLoading = true;
    const data: CreateIncidentAuthenticatedFromPortalDTO = {
      incidentDTO: {
        incidentTitle: `Mietinteressent melden WE${this.dataDialog.siteCode} ${this.dataDialog.city} - ${this.dataDialog.street}`,
        incidentDescription: 'Abwicklung Mietinteressent',
        incidentClassId: 'Abwicklung Mietinteressent',
        incidentType: IncidentType.Mietmanagement,
        createdById: 'systemuser-1464',
        alternateAddress: {
          city: this.dataDialog.city,
          street: this.dataDialog.street,
        },
        parentIncidentId: this.dataDialog.incidentId,
        externalLetting: this.form.value,
        ciamId: this.ciamService.getCiamId(),
      },
    };
    if (this.form.valid) {
      this.incidentClient.createIncidentAuthenticated(data).subscribe({
        next: res => {
          this.dataDialog.ticketId = res;
          this.isLoading = false;
          this.dialogRef.close(this.model);
          this.toastr.success(
            this.translateService.instant(
              'taskmodule.task.prospective_tenants.success_message'
            ),
            ''
          );
        },
        error: error => {
          console.log(error);
          this.isLoading = false;
          this.toastr.error(
            this.translateService.instant('ticket.create.error.message'),
            this.translateService.instant('ticket.create.error.title')
          );
        },
      });
    } else {
      this.isLoading = false;
    }
  }

  public onDismiss() {
    console.log('dismiss');
    this.dialogRef.close();
  }
}
