import { NgModule } from '@angular/core';
import { SpinnerOverlayTelekomComponent } from '@mandants/telekom/override/spinner-overlay/spinner-overlay-telekom.component';
import { MaterialModule } from '@shared/material/material.module';
import { NgIf } from '@angular/common';

const components = [SpinnerOverlayTelekomComponent];

@NgModule({
  declarations: [...components],
  imports: [MaterialModule, NgIf],
  exports: [...components],
})
export class OverrideModule {}
