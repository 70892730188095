import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { SysEnum } from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class IbpdiHelperService {
  constructor(private translateService: TranslateService) {}

  /**
   * @param sysEnum<SysEnum> that includes sysEnumTranslations
   * @returns translated label
   */
  public getTranslatedEnumLable(sysEnum: SysEnum) {
    return (
      sysEnum?.sysEnumTranslation?.find(
        s => s.language === this.translateService.currentLang
      )?.name ||
      sysEnum?.defaultName ||
      '--'
    );
  }
}
