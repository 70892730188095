<su-headline h="h1" skin="primary">{{
  'edit_stage.labels.title' | translate
}}</su-headline>

<su-headline
  *ngIf="stageAddress"
  h="h2"
  skin="tertiary"
  class="selectionHeadline">
  {{ stageAddress }}
</su-headline>

<div [style]="descriptionContainerStyle" *ngIf="frozenStatus">
  <div
    class="sub-headline contactDescription"
    [style]="descriptionStyle"
    [innerHtml]="description | safeHtml"></div>
</div>

<su-card [card]="cardFields">
  <div card-content>
    <formly-form [form]="form" [fields]="formFields" [model]="formModel">
    </formly-form>
  </div>
</su-card>

<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
