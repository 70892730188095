import {
  AfterViewInit,
  Component,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FactItemComponent } from '../fact-item/fact-item.component';

@Component({
  selector: 'app-fact-wrapper-home-page',
  templateUrl: './fact-wrapper-home-page.component.html',
  styleUrl: './fact-wrapper-home-page.component.scss',
})
export class FactWrapperHomePageComponent implements AfterViewInit, OnDestroy {
  @ViewChildren(FactItemComponent) factItems!: QueryList<FactItemComponent>;
  currentIndex = 0;
  highlightInterval: NodeJS.Timeout;

  ngAfterViewInit() {
    this.startHighlighting();
  }

  startHighlighting() {
    const items = this.factItems.toArray();
    if (items.length > 0) {
      this.highlightInterval = setInterval(() => {
        this.highlightCurrentItem();
      }, 3000);
    }
  }

  highlightCurrentItem() {
    const items = this.factItems.toArray();

    if (this.currentIndex > 0) {
      items[(this.currentIndex - 1) % items.length].removeHighlight();
    } else {
      items[items.length - 1].removeHighlight();
    }

    items[this.currentIndex % items.length].highlight();

    this.currentIndex++;
  }

  ngOnDestroy() {
    if (this.highlightInterval) {
      clearInterval(this.highlightInterval);
    }
  }

  factItemData: FactItemData[] = [
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '10600',
      subtitle: 'Standorte',
      factType: '',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '4800',
      subtitle: 'Bürostandorte',
      factType: '',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '7,3',
      subtitle: 'Gesamtfläche',
      factType: 'm2',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '1,4',
      subtitle: 'Bürofläche',
      factType: 'm2',
    },
  ];
}

export interface FactItemData {
  iconUrl: string;
  factAmount: string;
  subtitle: string;
  factType: string;
}
