import { Component, Injectable, Input, ViewEncapsulation } from '@angular/core';
import { AddressViewModel } from '@core/api';

@Component({
  selector: 'app-telekom-email-template',
  templateUrl: './telekom-email-template.component.html',
  styleUrls: ['./telekom-email-template.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
@Injectable({
  providedIn: 'root',
})
export class TelekomEmailTemplateComponent {
  @Input() address: AddressViewModel;
  @Input() emailContent = '';
  @Input() subject = '';

  date = new Date();
}
