import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml', standalone: true })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(htmlContent: string): SafeHtml {
    console.log(htmlContent);
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }
}
