<div class="form-style">
  <h3>
    {{ 'buildingMaintenance.labels.editBuildTitle' | translate }}
  </h3>
  <div>
    <formly-form [form]="form" [fields]="formFields" [model]="formModel">
    </formly-form>
  </div>
  <div class="button-container">
    <su-button
      type="button"
      [skin]="'secondary'"
      (click)="closeDialog()"
      [label]="'buildingMaintenance.labels.buttonCancel' | translate">
    </su-button>
    <su-button
      type="submit"
      [skin]="'primary'"
      [disabled]="form.invalid || saveDisabled"
      (click)="onSubmit()"
      [label]="'buildingMaintenance.labels.buttonSave' | translate">
    </su-button>
  </div>
</div>

<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
