/* eslint-disable @typescript-eslint/dot-notation */
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenClaims } from '@azure/msal-common';
import {
  OrganisationClient,
  OrganisationViewModel,
  RoleClient,
  RoleViewModel,
  UserAdministrationClient,
  UserAdministrationIncidentRequest,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { ContactSearchFormlyFieldService } from '@core/services/contact-search-formly-field-service/contact-search-formly-field.service';
import { EmployeeSearchFormlyFieldService } from '@core/services/employee-search-formly-field-service/employee-search-formly-field.service';
import { UserService } from '@core/services/oauth-service/user.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RegexpEmail } from '@shared/utils/regex';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, shareReplay } from 'rxjs';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit {
  headline = this.translateService.instant(
    'menu.ticket.component.request_user_creation'
  );

  cardUser: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant('user.request.create.title'),
      },
    },
    footer: {
      button1: {
        label: this.translateService.instant(
          'user.request.create.label__submit'
        ),
        onClick: () => this.validateForm(),
        disabled: false,
      },
      button2: {
        label: this.translateService.instant(
          'user.request.create.label__reset'
        ),
        onClick: () => this.reset(),
        disabled: false,
      },
    },
  };

  form = new FormGroup({});

  formModel = {};

  formFields: Array<FormlyFieldConfig>;

  loading = false;

  isTelekomChecked = false;

  isUserSelected = false;

  roles$ = this.roleClient.get().pipe(shareReplay(1));

  private organisationsSubject = new BehaviorSubject<OrganisationViewModel[]>(
    []
  );
  organisations$ = this.organisationsSubject.asObservable();

  isTelekomUser = false;

  currentUserTokenClaims: TokenClaims & {
    [key: string]: unknown;
  };

  constructor(
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private employeeFormlyFieldService: EmployeeSearchFormlyFieldService,
    private contactFormlyFieldService: ContactSearchFormlyFieldService,
    private roleClient: RoleClient,
    private userService: UserService,
    private organisationClient: OrganisationClient,
    private userAdministrationClient: UserAdministrationClient,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);

    this.currentUserTokenClaims = this.userService.getCurrentUserTokenClaims();

    this.isTelekomUser =
      this.currentUserTokenClaims.ciamId &&
      this.currentUserTokenClaims.ciamId !== '';

    this.organisationClient.get().subscribe({
      next: (organisations: OrganisationViewModel[]) => {
        this.organisationsSubject.next(organisations);
      },
    });

    if (this.isTelekomUser == true) {
      this.isTelekomChecked = true;
      this.formFields = this.getFormFieldsForTelekom();
    } else {
      this.formFields = this.getFormFieldsForExternal();
    }
  }

  validateForm() {
    if (this.form.valid) {
      this.createUserRequest();
    } else {
      if (this.isTelekomChecked === true) {
        const requiredKeys = [];
        Object.keys(this.form.controls).forEach(key => {
          const controlErrors = this.form.get(key)?.errors;
          if (controlErrors != null) {
            requiredKeys.push(key);
          }
        });
        if (requiredKeys.length === 1 && requiredKeys[0] === 'acceptTerms') {
          this.createUserRequest();
        } else {
          this.form.markAllAsTouched();
        }
      } else {
        this.form.markAllAsTouched();
      }
    }
  }

  reset() {
    this.router.navigateByUrl('dashboard');
  }

  toggleTelekomCheck() {
    if (this.isTelekomChecked) {
      this.formFields = this.getFormFieldsForTelekom();
    } else {
      this.formFields = this.getFormFieldsForExternal();
    }

    this.form.patchValue(
      {
        isTelekomChecked: this.isTelekomChecked,
      },
      { emitEvent: false }
    );
  }

  getFormFieldsForTelekom() {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'isTelekomChecked',
            type: 'select',
            defaultValue: this.isTelekomChecked,
            props: {
              options: [
                {
                  id: 1,
                  label: this.translateService.instant(
                    'administration.create_user.telekom_employee'
                  ),
                  value: true,
                },
                {
                  id: 2,
                  label: this.translateService.instant(
                    'administration.create_user.external_user'
                  ),
                  value: false,
                },
              ],
            },
            hooks: {
              onInit: field => {
                field.formControl.valueChanges.subscribe(value => {
                  this.isTelekomChecked = value;

                  field.formControl.disable({ emitEvent: false });
                  this.toggleTelekomCheck();
                  field.formControl.enable({ emitEvent: false });
                });
              },
            },
          },
          {
            wrappers: [],
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'user.request.create.label__searchEmployee'
                  ),
                },
              },
              {
                key: 'employee',
                fieldGroup: [
                  ...this.employeeFormlyFieldService.autocompleteSearch(
                    this.translateService.instant(
                      'user.request.create.placeholder__search'
                    ),
                    this.onSelectUser.bind(this),
                    false,
                    true,
                    null,
                    true,
                    this.onDelete.bind(this)
                  ),
                ],
              },
            ],
          },
          {
            type: 'flex-layout',
            key: 'userData',
            fieldGroup: [
              {
                key: 'firstName',
                type: 'input',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__firstName'
                  ),
                  required: true,
                  disabled: true,
                },
              },
              {
                key: 'lastName',
                type: 'input',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__lastName'
                  ),
                  required: true,
                  disabled: true,
                },
              },
              {
                key: 'email',
                type: 'input',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__email'
                  ),
                  required: true,
                  disabled: true,
                },
              },
              {
                key: 'roles',
                type: 'autocomplete-chips-for-roles',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__roles'
                  ),
                  required: true,
                  options: this.roles$.pipe(
                    map(roles =>
                      roles
                        .filter(
                          role =>
                            role.name.startsWith('REM_') ||
                            role.name.startsWith('DTAG_')
                        )
                        .sort((a, b) =>
                          a.description.localeCompare(b.description)
                        )
                    )
                  ),
                },
                validation: {
                  messages: {
                    required: this.translateService.instant(
                      'formErrors.required',
                      {
                        label: this.translateService.instant(
                          'user.request.create.label__roles'
                        ),
                      }
                    ),
                  },
                },
              },
            ],
          },
        ],
      },
    ];
  }

  getFormFieldsForExternal() {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'isTelekomChecked',
            type: 'select',
            defaultValue: this.isTelekomChecked,
            props: {
              disabled: !this.isTelekomUser,
              options: [
                {
                  id: 1,
                  label: this.translateService.instant(
                    'administration.create_user.telekom_employee'
                  ),
                  value: true,
                },
                {
                  id: 2,
                  label: this.translateService.instant(
                    'administration.create_user.external_user'
                  ),
                  value: false,
                },
              ],
            },
            hooks: {
              onInit: field => {
                field.formControl.valueChanges.subscribe(value => {
                  this.isTelekomChecked = value;

                  field.formControl.disable({ emitEvent: false });
                  this.toggleTelekomCheck();
                  field.formControl.enable({ emitEvent: false });
                });
              },
            },
          },
          {
            wrappers: [],
            fieldGroup: [
              {
                type: 'chapter',
                props: {
                  chapterTitle: this.translateService.instant(
                    'user.request.create.label__searchContact'
                  ),
                },
              },
              {
                key: 'contact',
                fieldGroup: [
                  ...this.contactFormlyFieldService.autocompleteSearch(
                    this.translateService.instant(
                      'user.request.create.placeholder__search'
                    ),
                    this.onSelectUser.bind(this),
                    false,
                    true,
                    null,
                    true,
                    this.onDelete.bind(this)
                  ),
                ],
              },
            ],
          },
          {
            type: 'flex-layout',
            key: 'userData',
            fieldGroup: [
              {
                key: 'firstName',
                type: 'input',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__firstName'
                  ),
                  required: true,
                },
              },
              {
                key: 'lastName',
                type: 'input',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__lastName'
                  ),
                  required: true,
                },
              },
              {
                key: 'email',
                type: 'input',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__email'
                  ),
                  required: true,
                  pattern: RegexpEmail,
                },
                validation: {
                  messages: {
                    pattern: (_err, _field: FormlyFieldConfig) =>
                      this.translateService.instant('formErrors.email.pattern'),
                  },
                },
              },
              {
                key: 'organisation',
                type: 'select',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__organisation'
                  ),
                  required: true,
                  options: this.organisations$,
                  valueProp: 'id',
                  labelProp: 'name',
                },
              },
              {
                key: 'roles',
                type: 'autocomplete-chips-for-roles',
                props: {
                  label: this.translateService.instant(
                    'user.request.create.label__roles'
                  ),
                  required: true,
                  options: this.roles$.pipe(
                    map(roles =>
                      roles
                        .filter(role => role.name.startsWith('DL_'))
                        .sort((a, b) =>
                          a.description.localeCompare(b.description)
                        )
                    )
                  ),
                },
                validation: {
                  messages: {
                    required: this.translateService.instant(
                      'formErrors.required',
                      {
                        label: this.translateService.instant(
                          'user.request.create.label__roles'
                        ),
                      }
                    ),
                  },
                },
              },
            ],
          },
          {
            key: 'acceptTerms',
            type: 'checkbox',
            wrappers: ['no-border'],
            defaultValue: false,
            props: {
              label: this.translateService.instant(
                'register.data_privacy_accept'
              ),
              appearance: 'outline',
            },
            validators: {
              validation: ['requiredTrue'],
            },
            validation: {
              messages: {
                required: this.translateService.instant(
                  'register.data_privacy_error_message'
                ),
              },
            },
          },
          {
            key: 'link',
            type: 'link',
            className: 'form-link',
            props: {
              label: this.translateService.instant(
                'register.data_privacy_link'
              ),
              href: '/privacy',
              icon: 'file-lines',
            },
          },
        ],
      },
    ];
  }

  onSelectUser(_id: string, _initialSearch, user) {
    this.formFields[0].form
      ?.get('userData.firstName')
      ?.setValue(user.firstName);
    this.formFields[0].form?.get('userData.lastName')?.setValue(user.lastName);
    this.formFields[0].form?.get('userData.email')?.setValue(user.email);
    this.formFields[0].form
      ?.get('userData.organisation')
      ?.setValue(user.company);

    this.formFields[0].form?.get('userData.firstName').disable();
    this.formFields[0].form?.get('userData.lastName').disable();
    this.formFields[0].form?.get('userData.email').disable();

    this.isUserSelected = true;
  }

  onDelete() {
    this.formFields[0].form?.get('userData.firstName')?.setValue(null);
    this.formFields[0].form?.get('userData.lastName')?.setValue(null);
    this.formFields[0].form?.get('userData.email')?.setValue(null);
    this.formFields[0].form?.get('userData.organisation')?.setValue(null);

    if (!this.isTelekomChecked) {
      this.formFields[0].form?.get('userData.firstName').enable();
      this.formFields[0].form?.get('userData.lastName').enable();
      this.formFields[0].form?.get('userData.email').enable();
      this.formFields[0].form?.get('userData.roles').enable();
    }

    this.isUserSelected = false;
  }

  createUserRequest() {
    this.loading = true;
    let request: UserAdministrationIncidentRequest;

    if (this.isUserSelected) {
      request = {
        firstName:
          this.form.value['contact']?.searchResult?.firstName ??
          this.form.value['employee']?.searchResult?.firstName ??
          null,
        lastName:
          this.form.value['contact']?.searchResult?.lastName ??
          this.form.value['employee']?.searchResult?.lastName ??
          null,
        email:
          this.form.value['contact']?.searchResult?.email ??
          this.form.value['employee']?.searchResult.email ??
          null,
        companyId:
          this.form.value['contact']?.searchResult?.company?.id ?? null,
        b2cUserId: this.form.value['employee']?.searchResult?.id ?? null,
        contactId: this.form.value['contact']?.searchResult?.contactId ?? null,
        roles:
          this.form.value['userData']?.roles?.map(
            (role: RoleViewModel) => role.id
          ) ?? null,
      };
    } else {
      request = {
        firstName: this.form.value['userData']?.firstName ?? null,
        lastName: this.form.value['userData']?.lastName ?? null,
        email: this.form.value['userData']?.email ?? null,
        companyId: this.form.value['userData']?.organisation ?? null,
        b2cUserId: null,
        contactId: null,
        roles:
          this.form.value['userData']?.roles?.map(
            (role: RoleViewModel) => role.id
          ) ?? null,
      };
    }

    this.userAdministrationClient.createUser(request).subscribe({
      next: () => {
        this.toastr.success(
          this.translateService.instant(
            'administration.create_user.success_message'
          )
        );
        this.loading = false;
        this.router.navigateByUrl('dashboard');
      },
      error: err => {
        let message = this.translateService.instant('generic.errorMessage');
        if (err.status === 409) {
          message = this.translateService.instant(
            'administration.create_user.error_message_user_exists'
          );
        }
        this.toastr.error(
          message,
          this.translateService.instant('alert.error')
        );
        this.loading = false;
      },
    });
  }
}
