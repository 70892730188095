import { Component } from '@angular/core';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
})
export class TaskListComponent {
  constructor() {
    console.log('[TaskListComponent] constructor');
  }
}
