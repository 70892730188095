<div mat-dialog-title>
  <su-headline h="h3" skin="primary">{{ data.title }}</su-headline>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" style="margin-top: 20px">
    <formly-form
      [form]="form"
      [fields]="formFields"
      [model]="formModel"></formly-form>
  </form>
</div>

<div mat-dialog-actions>
  <su-button
    skin="secondary"
    [label]="'shared.cancel' | translate"
    mat-dialog-close></su-button>

  <su-button
    skin="primary"
    [label]="'shared.save' | translate"
    (onClick)="onSave()"></su-button>
</div>
