/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormlyFieldBaseService } from '@core/services/formly-service/formly-field.service';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-formly-field-file-pictures',
  template: `
    <div
      [ngClass]="
        isMobile
          ? 'field-file-container-mobile'
          : 'field-file-container-desktop'
      ">
      <su-button
        [ngClass]="isMobile ? 'file-select-button-mobile' : ''"
        (onClick)="fileInput.click()"
        [skin]="'accent'"
        [icon]="'paperclip'"></su-button>

      <div class="form-file-upload-label-error">
        <p
          class="p-flex"
          [ngClass]="
            isMobile ? 'filename-label-mobile' : 'filename-label-desktop'
          ">
          {{ fileName || 'ticket.create.label__select_file' | translate }}
        </p>
        <p
          class="p-flex alert error"
          *ngIf="
            (formlyFieldService.fileNotSelectedChange$ | async) && !fileName
          ">
          {{ 'formErrors.file.select' | translate }}
        </p>
        <img
          *ngIf="previewUrl"
          [src]="previewUrl"
          alt="Bildvorschau"
          class="image-preview" />
      </div>

      <input
        #fileInput
        type="file"
        [formlyAttributes]="field"
        [accept]="to.accept"
        hidden
        required
        (change)="onFileSelected($event)" />
    </div>
  `,
  styles: [
    `
      .field-file-container-mobile {
        margin-top: 10px;
        align-items: baseline;
      }

      .field-file-container-desktop {
        display: flex;
        margin-top: 10px;
        align-items: baseline;
      }

      .file-select-button-mobile {
        display: flex;
        margin: 0px 0px 0px 10px;
      }

      .p-flex {
        flex: 0 0 auto;
      }

      .error {
        color: red;
      }
      .form-file-upload-label-error {
        display: flex;
        flex-direction: column;
      }

      .file-select-mobile {
        margin: 0px 0px 0px 0px;
      }

      .filename-label-mobile {
        text-align: center;
        padding-right: 10px;
      }

      .filename-label-desktop {
        word-break: break-all;
        padding-left: 10px;
      }
      .image-preview {
        max-width: 100px;
        max-height: 100px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    `,
  ],
})
export class FormlyFieldFilePicturesComponent
  extends FieldType
  implements OnInit
{
  formControlFile: FormControl;

  override field: FormlyFieldConfig;

  selectedFile: File;

  fileName: string;

  fileNameErrors: string;

  formatError = false;

  sizeError = false;

  fileNameLengthError = false;

  fileSizeTooSmallError = false;

  fileNameEmptyError = false;

  isMobile = false;

  previewUrl: string | ArrayBuffer | null = null;

  @HostListener('window:resize', ['$event'])
  onResize(_event: any): void {
    this.isMobile = window.innerWidth < 768;
  }

  constructor(
    public formlyFieldService: FormlyFieldBaseService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.formatError = false;
    this.sizeError = false;
    this.fileName = '';
    this.fileNameErrors = '';
    this.fileNameLengthError = false;
    this.fileSizeTooSmallError = false;
    this.fileNameEmptyError = false;
    this.isMobile = window.innerWidth < 768;
    if (this.formControl.value && this.formControl.value.url) {
      this.fileName = this.formControl.value.name;
      this.previewUrl = this.formControl.value.url;
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] as File;

    if (this.selectedFile === undefined) {
      return;
    }

    this.sizeError = false;
    this.formatError = false;
    this.fileName = '';
    this.fileNameErrors = '';
    this.fileNameLengthError = false;
    this.fileSizeTooSmallError = false;
    this.fileNameEmptyError = false;
    this.previewUrl = null;

    const selectedFileExtensionArray = this.selectedFile.name.split('.');
    const selectedFileExtensionArrayLength = selectedFileExtensionArray.length;
    this.fileNameErrors = `${this.selectedFile.name} (${(
      this.selectedFile.size / 1e6
    ).toFixed(2)} MB)`;

    if (
      this.field.props?.accept &&
      !this.field.props?.accept
        ?.toLowerCase()
        .includes(
          selectedFileExtensionArray[
            selectedFileExtensionArrayLength - 1
          ].toLowerCase()
        )
    ) {
      this.formatError = true;
      this.toastr.error(
        this.translateService.instant('formErrors.file.format', {
          accept: this.to.accept,
        })
      );
      return;
    }
    if (
      this.field.props?.maxFileSizeInMB &&
      this.selectedFile.size > this.field.props?.maxFileSizeInMB * 1e6
    ) {
      this.sizeError = true;
      this.field.formControl.patchValue(null);
      this.toastr.error(
        this.translateService.instant('formErrors.file.size', {
          maxSize: this.to.maxFileSizeInMB,
          file: this.fileNameErrors,
        })
      );
      return;
    }
    if (this.selectedFile.name.length > 150) {
      this.fileNameLengthError = true;
      this.toastr.error(
        this.translateService.instant(
          'fileUpload.error.message__filename_length_too_large'
        )
      );
      return;
    }

    if (this.selectedFile.size <= 0) {
      this.fileSizeTooSmallError = true;
      this.toastr.error(
        this.translateService.instant('formErrors.file.size_small', {
          file: this.fileNameErrors,
        })
      );
      return;
    }
    if (
      !selectedFileExtensionArray[0] &&
      !selectedFileExtensionArray[0].trim()
    ) {
      this.fileNameEmptyError = true;
      this.toastr.error(
        this.translateService.instant(
          'fileUpload.error.message__filename_empty',
          { file: this.fileNameErrors }
        )
      );
      return;
    }

    this.previewUrl = window.URL.createObjectURL(this.selectedFile);

    this.fileName = `${this.selectedFile.name} (${(
      this.selectedFile.size / 1e6
    ).toFixed(2)} MB)`;
    this.formControl.patchValue(this.selectedFile);
  }
}
