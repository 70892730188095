<div data-debug="detail-fields-base">
  <ng-container *ngFor="let field of detailFields">
    <ng-container [ngSwitch]="field.type" *ngIf="visible(field)">
      <!-- FIELD -->
      <ng-container
        *ngSwitchCase="['field', 'allignedField', 'textarea'].includes(field.type) ? field.type : ''">
        <div class="info-card_field">
          <div
            [innerText]="getLabel(field)"
            class="label"
            [style.min-width.px]="labelMinWidth"></div>
          <div [innerText]="getData(field)"></div>
        </div>
      </ng-container>

      <!-- LINK -->
      <ng-container *ngSwitchCase="'link'">
        <div class="info-card_field">
          <div [innerText]="getLabel(field)" class="label"></div>
          <div>
            <a [href]="getLink(field)" target="_blank">
              <span [innerText]="getData(field)"></span>
            </a>
          </div>
        </div>
      </ng-container>

      <!-- HTML -->
      <ng-container *ngSwitchCase="'html'">
        <div class="info-card_field">
          <div [innerText]="getLabel(field)" class="label"></div>
          <div [innerHTML]="getData(field)"></div>
        </div>
      </ng-container>

      <!-- CHAPTER -->
      <ng-container *ngSwitchCase="'chapter'">
        <div>
          <div><hr /></div>
          <div [style.color]="'var(--theme-accent-500)'">
            <h3 [innerHtml]="getLabel(field)"></h3>
          </div>
          <div [style.padding-top.px]="22"><hr /></div>
        </div>
      </ng-container>

      <!-- FILES -->
      <ng-container *ngSwitchCase="'files'">
        <su-headline *ngIf="field.label" h="h3" skin="secondary"
          >{{field.label}}</su-headline
        >
        <ng-container *ngIf="mandant !== 'brst'">
          <div>
            <div>
              <app-detail-file-upload
                *ngIf="isFileUploadAllowed()"
                [hasTaskSelection]="true"
                [hasTargetSystemSelection]="false"
                [hasDocumentTypeSelection]="true"
                [metadata]="getMetadata(field)"
                [incident]="data"
                [tasks]="data?.fmTask"
                [statusData]="statusData"
                (uploadEvent)="documentsBufferedChanged($event)"></app-detail-file-upload>
            </div>
          </div>
          <div>
            <div *ngIf="showFileList">
              <app-detail-file-list-buffered
                *ngIf="documentsBuffered.length > 0"
                [documents]="documentsBuffered"
                (removeEvent)="documentsBufferedChanged($event)">
              </app-detail-file-list-buffered>
              <br />
              <br />
            </div>
          </div>
          <div *ngIf="isAuthenticated() && documents.length > 0">
            <div *ngIf="showFileList">
              <app-detail-file-list
                *ngIf="documents.length > 0"
                [documents]="documents"
                [task]="data"
                [incident]="incident">
              </app-detail-file-list>
            </div>
          </div>
          <div
            class="file-download-list"
            *ngIf="!isAuthenticated() && documents.length > 0">
            <div
              class="file-container"
              *ngFor="let document of documents; let i = index">
              <su-button
                class="fileButton"
                (onClick)="downloadFile(document, i)"
                skin="secondary"
                icon="download"
                [label]="document.name"
                [disabled]="fileDownloadState[i] === true"></su-button>
              <mat-spinner
                [diameter]="25"
                *ngIf="fileDownloadState[i] === true"></mat-spinner>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- TYPED-FILES -->
      <ng-container *ngSwitchCase="'typed-files'">
        <h4 *ngIf="field.label" [innerText]="field.label"></h4>

        <ng-container *ngIf="mandant === 'telekom' || mandant === 'brst'">
          <div>
            <div>
              <app-detail-file-upload
                *ngIf="isFileUploadAllowed()"
                [hasTaskSelection]="true"
                [hasTargetSystemSelection]="false"
                [hasDocumentTypeSelection]="true"
                [metadata]="getMetadata(field)"
                [incident]="data"
                [tasks]="data?.fmTask"
                [statusData]="statusData"
                (uploadEvent)="documentsBufferedChanged($event)"></app-detail-file-upload>
            </div>
          </div>
          <div *ngIf="showFileList">
            <div>
              <app-detail-file-list-buffered
                *ngIf="documentsBuffered.length > 0"
                [documents]="documentsBuffered"
                (removeEvent)="documentsBufferedChanged($event)">
              </app-detail-file-list-buffered>
              <br />
              <br />
            </div>
          </div>
          <div *ngIf="showFileList">
            <div>
              <app-detail-file-list
                *ngIf="documents.length > 0"
                [documents]="documents"
                [tasks]="data?.fmTask"
                [task]="data"
                [incident]="incident"
                [canDeleteDocument]="true">
              </app-detail-file-list>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
