import { SmartusComponentsModule } from '@affinis/smartus-components';
import { NgIf } from '@angular/common';
import { Component, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-formly-area-room-extended',
  standalone: true,
  imports: [
    SmartusComponentsModule,
    NgIf,
    FormlyModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  template: `
    <div
      class="info-card_field"
      data-debug="formly-read-only-wrapper"
      style="display: flex; align-items: center;">
      <div class="label">{{ props.label }}:</div>

      <div class="read-only-section">
        <span
          >{{ 'edit_room.labels.architecture' | translate }}:
          {{ props.architecture }} m²</span
        >
        /
        <span
          >{{ 'edit_room.labels.rentalRem' | translate }}:
          {{ props.rentalArea_REM }} m²</span
        >
      </div>

      <div class="editable-section" style="margin-left: 8px;">
        <label for="flacheNutzer" style="margin-right: 4px;"
          >{{ 'edit_room.labels.userArea' | translate }}:</label
        >
        <input
          id="flacheNutzer"
          type="text"
          [formControl]="control"
          [formlyAttributes]="field"
          style="width: 80px;"
          [value]="props.userArea"
          (keypress)="validateNumericInput($event)" />
        m²
      </div>
    </div>
  `,
})
export class FormlyAreaRoomExtendedComponent extends FieldType {
  ngOnInit() {
    this.updateControlValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.props) {
      this.updateControlValues();
    }
  }

  updateControlValues() {
    if (this.control && this.props.userArea) {
      this.control.setValue(this.props.userArea);
    }

    this.props.userArea =
      this.props.userArea !== undefined ? this.props.userArea : '--';
    this.props.architecture =
      this.props.architecture !== undefined ? this.props.architecture : '--';
    this.props.rentalArea_REM =
      this.props.rentalArea_REM !== undefined
        ? this.props.rentalArea_REM
        : '--';
  }

  validateNumericInput(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const inputChar = event.key;

    if (!allowedKeys.includes(inputChar) && !this.isControlKey(event)) {
      event.preventDefault();
    }
  }

  isControlKey(event: KeyboardEvent): boolean {
    const controlKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
      'Tab',
    ];
    return controlKeys.includes(event.key) || event.ctrlKey || event.metaKey;
  }

  get control(): FormControl {
    return this.formControl as FormControl;
  }
}
