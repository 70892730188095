<su-headline h="h1" skin="primary">{{ headline }}</su-headline>

<div class="location-width">
  <formly-form
    [form]="form"
    [fields]="formFields"
    [model]="formModel"></formly-form>
</div>

<div class="card-container">
  <su-card [card]="cardArchitecture" class="content-card-override">
    <div card-content>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- Tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-icon-button disabled></button>
          <ng-container *ngIf="node.level > 2 && node.level !== 5">
            <mat-checkbox
              [ngModel]="GetCheckedState(node)"
              (change)="onCheckboxClick($event, node)"></mat-checkbox>
            {{ node.name }}
          </ng-container>
          <span *ngIf="node.level <= 2 || node.level === 5">
            {{ node.name }}
          </span>
        </mat-tree-node>
        <!-- Tree node template for expandable nodes -->
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding>
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
              }}
            </mat-icon>
          </button>
          <ng-container *ngIf="node.level > 2 && node.level !== 5">
            <mat-checkbox
              [ngModel]="GetCheckedState(node)"
              (change)="onCheckboxClick($event, node)"></mat-checkbox
            >{{ node.name }}
          </ng-container>
          <span *ngIf="node.level <= 2 || node.level === 5">
            {{ node.name }}
          </span>
        </mat-tree-node>
      </mat-tree>
      <span *ngIf="dataSource.data.length === 0"
        >Bitte einen Standort auswählen</span
      >
    </div>
  </su-card>

  <su-card
    [card]="cardView"
    class="content-card-override"
    [ngClass]="{ 'custom-style': isCustomStyleActive }">
    <div card-content>
      <div style="height: 500px">
        <div class="views-button-container">
          <button
            type="button"
            mat-raised-button
            color="secondary"
            *ngFor="let view of views"
            [class.active]="view.value === selectedViewType"
            (click)="selectView(view.value)">
            {{ view.name }}
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            mat-raised-button
            disabled="true"
            color="accent"
            (click)="scrollToToday()">
            Heute
          </button>
        </div>

        <app-gantt-flat
          #gantt
          [items]="items"
          [viewOptions]="viewOptions"
          [groups]="groups"
          [viewType]="options.viewType"
          [draggable]="options.draggable"
          [styles]="options.styles"
          (barClick)="barClick($event)">
          <ng-template #group let-group="group"></ng-template>
          <ng-template #bar let-item="item">
            <div
              class="bar-content"
              matTooltipClass="my-tooltip"
              [matTooltip]="getTooltip(item)">
              <span style="color: #000; font-weight: bold"
                >&nbsp;&nbsp;{{ item.title }}
              </span>
            </div>
          </ng-template>
        </app-gantt-flat>
      </div>
      <div class="button-container">
        <su-button
          skin="secondary"
          icon="arrows-left-right"
          [title]="buttonMaxWidthTitle"
          (onClick)="toggleStyle()"></su-button>
      </div>
    </div>
  </su-card>
</div>

<app-spinner-overlay [show]="isLoading" [label]="''"></app-spinner-overlay>
