import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class CiamService
{
  constructor(
    private msalService: MsalService
  ) {}

  public getCiamId(): string | undefined {
    const ciamId = this.msalService.instance.getActiveAccount()?.idTokenClaims?.ciamId;
    if (ciamId) {
      return this.removePrefix(ciamId as string);
    }

    return undefined;
  }

  removePrefix(ciamId: string): string {
    if (ciamId.length > 2) {
      const prefix = ciamId.substring(0, 2);
      if (prefix === 'C-' || prefix === 'c-') {
        return ciamId.substring(2);
      }
    }

    return ciamId;
  }
}
