<su-button
  type="button"
  (click)="openAddModal(this.typeOfUseDetailedId)"
  [skin]="'primary'"
  [label]="'buildingMaintenance.labels.addNewBuild' | translate">
</su-button>

<mtx-grid
  [columnResizable]="true"
  [columns]="columns"
  [data]="processedData"
  [noResultText]="'grid.no_results' | translate"
  [cellSelectable]="false"
  [pageSizeOptions]="[5, 10, 25, 50, 100]"
  [columnMenuButtonText]="'grid.columns_shown' | translate"
  [rowHover]="false"
  [columnHideable]="true"
  [columnHideableChecked]="'show' | translate"
  columnMenuButtonClass="col-button"
  [showToolbar]="true"
  [columnSortable]="true"
  [showColumnMenuButton]="true"
  columnMenuButtonType="flat">
</mtx-grid>

<ng-template #editButtonTemplate let-rowData>
  <div class="buttons-container">
    <su-button
      *ngIf="rowData.status === DataMaintenance.planned"
      icon="edit"
      skin="secondary"
      (click)="editRow($event, rowData)"
      class="button-content-center">
    </su-button>
  </div>
</ng-template>

<ng-template #deleteButtonTemplate let-rowData>
  <div class="buttons-container">
    <su-button
      *ngIf="rowData.status === DataMaintenance.planned"
      icon="trash"
      skin="secondary"
      (click)="deleteRow($event, rowData)"
      class="button-content-center">
    </su-button>
  </div>
</ng-template>

<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
