import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ArchObjHierarchyViewModel,
  AttachmentRequest,
  LocationClient,
  LocationNotificationRequest,
} from '@core/api';
import { FileService } from '@core/services/file-service/file.service';
import { FormlyFieldBaseService } from '@core/services/formly-service/formly-field.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { RegexpMultipleEmailComma } from '@shared/utils/regex';
import { ToastrService } from 'ngx-toastr';
import { finalize, from, switchMap } from 'rxjs';

interface ILocationNotificationForm {
  archObjId: string;
  recipient: string;
  hint: string;
  additionalRecipients: string[];
  subject: string;
  attachments: Array<{ attachment: File }>;
}

@Component({
  selector: 'app-location-notification',
  templateUrl: './location-notification.component.html',
  styleUrls: ['./location-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationNotificationComponent implements OnInit {
  @Input() location: ArchObjHierarchyViewModel;

  form = new FormGroup({});
  formFields: Array<FormlyFieldConfig> = [];
  formModel = {} as ILocationNotificationForm;
  htmlContent = '';
  subject = '';
  isLoading = false;

  private readonly maxFileSizeInMB = 10;

  angularEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    minHeight: '25rem',
    placeholder: this.translateService.instant(
      'location.myLocation.location_notification.enter_text'
    ),
    translate: 'no',
    defaultParagraphSeparator: 'p',
    fonts: [
      { class: 'teleneo-office', name: 'TeleNeo Office' },
      { class: 'extra-bold', name: 'ExtraBold' },
    ],
    defaultFontName: 'Teleneo',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
      ],
      [
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

  cardNotification: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'location.myLocation.location_notification.preview'
        ),
      },
    },
    footer: {
      button1: {
        label: this.translateService.instant('form.submit'),
        disabled: false,
        onClick: () => {
          this.validateForm();
        },
      },
    },
  };

  cardConfigurationWithoutFooter: CardConfig = {
    header: {
      title: {
        label: this.translateService.instant(
          'location.myLocation.location_notification.email_employees'
        ),
      },
    },
  };

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private formlyFieldService: FormlyFieldBaseService,
    private fileService: FileService,
    private locationClient: LocationClient,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.formlyFieldService.unsetNotSelectedFileError();
    this.initalizeFormFields();
  }

  private initalizeFormFields(): void {
    this.formFields = [
      {
        fieldGroup: [
          {
            key: 'archObjId',
            type: 'textarea',
            defaultValue: this.location?.archObjId,
            props: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__location'
              ),
              readonly: true,
            },
          },
          {
            key: 'recipient',
            type: 'textarea',
            defaultValue: this.translateService.instant(
              'location.myLocation.location_notification.email_to'
            ),
            props: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__email_to'
              ),
              rows: 2,
              readonly: true,
            },
          },
          {
            key: 'hint',
            type: 'textarea',
            defaultValue: this.translateService.instant(
              'location.myLocation.location_notification.email_hint'
            ),
            props: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__hint'
              ),
              rows: 2,
              readonly: true,
            },
          },
          {
            key: 'additionalRecipients',
            type: 'chips-input',
            props: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__additional_recipients'
              ),
              placeholder: this.translateService.instant(
                'location.myLocation.location_notification.email'
              ),
              pattern: RegexpMultipleEmailComma,
              description: this.translateService.instant(
                'location.myLocation.location_notification.email_recipient_description'
              ),
            },
            validation: {
              messages: {
                pattern: this.translateService.instant(
                  'formErrors.email.multiple_pattern'
                ),
              },
            },
          },
          {
            key: 'subject',
            type: 'input',
            props: {
              label: `${this.translateService.instant(
                'location.myLocation.location_notification.label__subject'
              )}:`,
              required: true,
            },
            hooks: {
              onInit: field => {
                field.formControl.valueChanges.subscribe(
                  value => (this.subject = value)
                );
              },
            },
            validation: {
              messages: {
                required: this.translateService.instant('formErrors.required', {
                  label: this.translateService.instant(
                    'location.myLocation.location_notification.label__subject'
                  ),
                }),
              },
            },
          },
        ],
      },
      {
        fieldGroup: [
          {
            type: 'chapter',
            props: {
              chapterTitle: this.translateService.instant('Anhänge'),
              chapterText: this.translateService.instant(
                this.translateService.instant(
                  'location.myLocation.location_notification.file_attachments',
                  {
                    maxMB: this.maxFileSizeInMB,
                  }
                )
              ),
            },
          },
          {
            key: 'attachments',
            type: 'repeat',
            props: {
              maxItems: 5,
              addText: this.translateService.instant(
                'ticket.create.button__attach_new_file'
              ),
            },
            fieldArray: {
              type: 'flex-layout',
              fieldGroup: [
                {
                  type: 'file',
                  key: 'attachment',
                  props: {
                    label: this.translateService.instant(
                      'ticket.create.label__attachment'
                    ),
                    visibleInConfirm: true,
                    accept:
                      '.jpg, jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx',
                    maxFileSizeInMB: this.maxFileSizeInMB,
                  },
                },
              ],
            },
          },
        ],
      },
    ];
  }

  private resetForm() {
    //TODO: Find better solution to reset, attachements were not resetted otherwise
    this.form = new FormGroup({});
    this.formModel = {} as ILocationNotificationForm;
    this.htmlContent = '';
    this.subject = '';
    this.initalizeFormFields();
  }

  submitLocationNotification() {
    this.isLoading = true;
    this.cd.detectChanges();
    from(this.mapFormToRequest())
      .pipe(
        switchMap(request =>
          this.locationClient.postSendLocationNotification(request).pipe(
            finalize(() => {
              this.isLoading = false;
              this.cd.detectChanges();
            })
          )
        )
      )
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translateService.instant(
              'location.myLocation.location_notification.success_message'
            )
          );
          this.resetForm();
        },
        error: () => {
          this.toastr.error(
            this.translateService.instant(
              'location.myLocation.location_notification.error_message'
            )
          );
        },
      });
  }

  public canSubmit(): boolean {
    const maxMB = 10;

    const formAttachment = (this.form.value as any).attachments;

    if (formAttachment.length === 0) {
      return true;
    }

    let isValidFiles = true;
    formAttachment.forEach(element => {
      if (element.attachment === undefined || element.attachment === null) {
        isValidFiles = false;
      }
    });

    if (isValidFiles) {
      this.formlyFieldService.unsetNotSelectedFileError();
      const [isValid, message, title] = this.fileService.validateTotalFileSize(
        formAttachment,
        (accumulator, current) => accumulator + current.attachment.size,
        maxMB
      );
      if (!isValid) {
        this.toastr.error(message, title);
      }
      return isValid;
    } else {
      this.formlyFieldService.setNotSelectedFileError();
      const element = document.querySelector('.form-file-upload-label-error');
      element?.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
  }

  validateForm() {
    if (this.form.valid && this.canSubmit()) {
      const dialogRef = this.dialog.open(DialogComponent, {
        panelClass: 'smartportal-dialog',
        width: '500px',
        data: {
          type: 'okDismissButton',
          title: this.translateService.instant(
            'location.myLocation.location_notification.confirm_dialog.title'
          ),
          message: this.translateService.instant(
            'location.myLocation.location_notification.confirm_dialog.message'
          ),
          okButtonText: this.translateService.instant('shared.confirm'),
          dismissButtonText: this.translateService.instant('shared.cancel'),
        },
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result) {
            this.submitLocationNotification();
          }
        },
      });
    } else {
      this.scrollToFirstInvalidControl(true);
    }
  }

  private async mapFormToRequest(): Promise<LocationNotificationRequest> {
    const request: LocationNotificationRequest = {
      archObjId: this.formModel.archObjId,
      address: this.location?.dtArchObjAddress[0]?.address,
      additionalRecipients: this.formModel.additionalRecipients,
      subject: this.formModel.subject,
      mailContent: this.htmlContent,
    };

    if (this.formModel.attachments?.length > 0) {
      request.attachements = await Promise.all(
        this.formModel.attachments.map(async attachment => {
          const base64 = await this.fileService.fileRead(attachment.attachment);
          return {
            fileName: attachment.attachment.name,
            content: base64.split(',')[1],
          } as AttachmentRequest;
        })
      );
    }

    return request;
  }

  private async scrollToFirstInvalidControl(repeatScroll: boolean) {
    this.form.markAllAsTouched();
    const firstInvalidControl: HTMLElement = document.querySelector(
      '.mat-mdc-input-element.ng-invalid, .mat-mdc-select.ng-invalid, .mat-mdc-checkbox.ng-invalid, mat-mdc-form-field.ng-invalid'
    );

    try {
      firstInvalidControl?.scrollIntoView({ behavior: 'smooth' });
      firstInvalidControl?.focus();
    } finally {
      if (repeatScroll) {
        setTimeout(() => {
          this.scrollToFirstInvalidControl(false);
        }, 10);
      }
    }
  }
}
