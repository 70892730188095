import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RentalListingOverviewViewModel } from '@core/api';
import { getGermanPaginatorIntl } from '../rental-item-details/rental-item-details.component';

@Component({
  selector: 'app-rental-item',
  templateUrl: './rental-item.component.html',
  styleUrl: './rental-item.component.scss',
  providers: [
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
  ],
})
export class RentalItemComponent implements OnInit, OnChanges {
  @Input() items: RentalListingOverviewViewModel[] = [];
  @Input() showPaginator: boolean = true;
  @Output() selectedItem = new EventEmitter<RentalListingOverviewViewModel>();

  pageSize: number;
  currentPage = 0;
  totalSize = 0;

  paginatedItems: RentalListingOverviewViewModel[] = [];

  ngOnInit() {
    this.updatePageSize();
    this.totalSize = this.items.length;
    this.updatePaginatedItems();
  }

  ngOnChanges() {
    this.updatePageSize();
    this.totalSize = this.items.length;
    this.updatePaginatedItems();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updatePageSize();
    this.updatePaginatedItems();
  }

  /**
   * Updates the page size based on the current window width.
   * Resets the current page to 0.
   */
  updatePageSize() {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1920) {
      this.pageSize = 14;
    } else if (windowWidth >= 1700) {
      this.pageSize = 12;
    } else if (windowWidth >= 1480) {
      this.pageSize = 10;
    } else if (windowWidth >= 1260) {
      this.pageSize = 8;
    } else if (windowWidth >= 1040) {
      this.pageSize = 6;
    } else if (windowWidth >= 640) {
      this.pageSize = 4;
    } else {
      this.pageSize = 6;
    }

    this.currentPage = 0;
  }

  /**
   * Updates the paginated items based on the current page and page size.
   * Slices the items array to get the items for the current page.
   */
  updatePaginatedItems() {
    const startIndex = this.currentPage * this.pageSize;
    this.paginatedItems = this.items.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }

  /**
   * Handles the page change event from the paginator.
   * Updates the page size and current page, then updates the paginated items.
   * @param event The page event containing the page index and page size.
   */
  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedItems();
  }

  /**
   * Emits the selected item.
   * @param item The selected rental listing overview item.
   */
  selectItem(item: RentalListingOverviewViewModel) {
    this.selectedItem.emit(item);
  }
}
