<su-slot-tabs [tabs]="tabs" [activeTab]="activeTab"></su-slot-tabs>

<su-slot-tab-content *ngIf="activeTab === tabs[0].id">
  <!-- TODO Layout Document upload -->

  <app-detail-fields-base
    *ngIf="!isLoading && data && (incident || task)"
    [allowFileUpload]="config.showFileUpload"
    [detailFields]="detailFields"
    [data]="data"
    [incident]="incident"
    [showFileList]="showFileList"></app-detail-fields-base>
  <ng-template appPlaceholder></ng-template>
</su-slot-tab-content>

<!-- <details *ngIf="isDevMode">
    <pre>{{ data | json }}</pre>
</details> -->

<app-spinner-overlay [show]="isLoading"></app-spinner-overlay>
