import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export class TranslateExtension {
  constructor(private translate: TranslateService) {}

  prePopulate(field: FormlyFieldConfig) {
    const to = field.props || {};
    if (!to.translate || to._translated) {
      return;
    }

    to._translated = true;
    field.expressions = {
      ...(field.expressions || {}),
      'templateOptions.label': this.translate.stream(to.label || ''),
    };
  }
}

export function registerTranslateExtension(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message(_err, field: FormlyFieldConfig) {
          return translate.stream('formErrors.required', {
            label: field.props?.label,
          });
        },
      },
    ],
    extensions: [
      {
        name: 'translate',
        extension: new TranslateExtension(translate),
      },
    ],
  };
}
