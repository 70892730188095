import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  NgZone,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { OverrideModule } from '@override/override.module';
import {
  GANTT_GLOBAL_CONFIG,
  GANTT_UPPER_TOKEN,
  GanttGlobalConfig,
  GanttGroupInternal,
  GanttItemInternal,
  GanttUpper,
  NgxGanttModule,
} from '@worktile/gantt';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gantt-flat',
  templateUrl: './flat.component.html',
  styleUrls: ['./flat.component.scss'],
  standalone: true,
  imports: [CommonModule, OverrideModule, NgxGanttModule],
  providers: [
    {
      provide: GANTT_UPPER_TOKEN,
      useExisting: AppGanttFlatComponent,
    },
  ],
})
export class AppGanttFlatComponent extends GanttUpper implements OnInit {
  mergeIntervalDays = 0;

  override groups: GanttGroupInternal[] = [];

  loading = true;

  @HostBinding('class.gantt-flat') ganttFlatClass = true;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    cdr: ChangeDetectorRef,
    ngZone: NgZone,
    @Inject(GANTT_GLOBAL_CONFIG) config: GanttGlobalConfig
  ) {
    super(elementRef, cdr, ngZone, config);
  }

  private buildGroupMergedItems(items: GanttItemInternal[]) {
    const mergedItems: GanttItemInternal[][] = [];
    items = items
      .filter(item => item.start && item.end)
      .sort((a, b) => a.start.getUnixTime() - b.start.getUnixTime());
    items.forEach(item => {
      let indexOfMergedItems = -1;
      for (let i = 0; i < mergedItems.length; i++) {
        const subItems = mergedItems[i];
        if (
          item.start.value >
          subItems[subItems.length - 1].end.addDays(this.mergeIntervalDays)
            .value
        ) {
          subItems.push(item);
          indexOfMergedItems = i;
          break;
        }
      }
      if (indexOfMergedItems === -1) {
        mergedItems.push([item]);
        indexOfMergedItems = mergedItems.length - 1;
      }
    });
    return mergedItems;
  }

  override ngOnInit() {
    super.ngOnInit();
    this.dragEnded
      .pipe(startWith<null, null>(null), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.buildGroupItems();
      });
  }

  override ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    super.ngOnChanges(changes);
    this.buildGroupItems();
    this.loading = false;
  }

  private buildGroupItems() {
    console.log(this.groups);
    this.groups.forEach(group => {
      group.mergedItems = this.buildGroupMergedItems(group.items);
      group.mergedItems =
        group.mergedItems.length === 0 ? [[]] : group.mergedItems;
    });
  }
}
