import { EnvironmentBase } from '@core/environments/environment.types';
import { LogLevel } from '@core/services/logger-service/logger.service';
import { AuthenticationType } from 'azure-maps-control';

/**
 * Deployment Config when deploying into the NEMO tenant.
 */
export const environment: EnvironmentBase = {
  MultiMandantConfigs: [
    {
      host: 'smartportal-prod.powerspaces.gsusrem.telekom-dienste.de',
      pathToAssets:
        'https://smartportal-prod.powerspaces.gsusrem.telekom-dienste.de/assets',
    },
  ],
  baseUrl:
    'https://smartportal-api-prod.powerspaces.gsusrem.telekom-dienste.de',
  production: true,
  logLevel: LogLevel.All,
  hmr: false,
  AadClientId: 'c271d81b-b715-48d0-bfdf-3804933cb752',
  AadScope:
    'https://powerspacesnemo.onmicrosoft.com/smart-data-hub/SmartPortal',
  AadAuthorityDomain: 'powerspacesnemo.b2clogin.com',
  AadSignupSigninUrl:
    'https://powerspacesnemo.b2clogin.com/powerspacesnemo.onmicrosoft.com/B2C_1A_social_signin_only',
  AadEditprofileUrl:
    'https://powerspacesnemo.b2clogin.com/powerspacesnemo.onmicrosoft.com/B2C_1_PasswordReset',
  buildNumber: '',
  appInsightsConnectionString:
    'InstrumentationKey=ab9f7815-f35a-4acc-a8c6-14c77f4ddc30;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=66d45071-3b87-4e1b-b22d-3b302555c9fa',
  mapAuthOption: {
    authType: AuthenticationType.subscriptionKey,
    subscriptionKey: 'JYrSadGpT2frvp31epPj8zE_qOSYFYEWdq9cQLg2G3A',
  },
};
