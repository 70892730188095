import { MenuItem } from '@affinis/smartus-components';
import { ButtonProps } from '@affinis/smartus-components/lib/button/button.types';
import { Chip } from '@affinis/smartus-components/lib/chips/chips.component.types';
import { Component, inject, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseSearchOptions,
  IncidentClient,
  MyIncidentsRequest,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { TicketService } from '@core/services/ticket-list-desk-service/ticket.service';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalFilterComponent } from '@shared/components/modal-filter/modal-filter.component';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import {
  ITableConfig,
  ITableFilterConfig,
} from '@shared/components/table-base/table-base.types';
import { RequestTypes } from '@shared/constants/request-types.constants';
import * as moment from 'moment';
import { ActiveToast } from 'ngx-toastr';

export interface IDialogData {
  columns: MtxGridColumn[];
  filterFields: FormlyFieldConfig[];
  filterValues: ITableFilterConfig;
  actions: {
    onSubmit: (formModel) => void;
    onReset: () => void;
  };
}

@Component({
  selector: 'app-ticket-list-helpdesk',
  templateUrl: './ticket-list-helpdesk.component.html',
  styleUrls: ['./ticket-list-helpdesk.component.scss'],
})
export class TicketListHelpdeskComponent
  extends TableBaseComponent
  implements OnInit
{
  override config = this.configure(<ITableConfig>{
    title: 'taskmodule.tickets.list.title',
    translatePath: 'helpdesk.ticket.list',
  });

  private ticketService = inject(TicketService);

  controlButtons: ButtonProps[] = [
    {
      label: this.translateService.instant('crud.refresh'),
      onClick: () => {
        this.refresh();
      },
    },
  ];

  chips: Chip[] = [];

  menuItems: MenuItem[] = [];

  dialogData: IDialogData;

  toast: ActiveToast<object>;

  constructor(
    injector: Injector,
    public incidentClient: IncidentClient,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  public ngOnInit() {
    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.translateService.instant(
          'ticket.create.helpdesk.my_tickets'
        ),
      },
    ]);

    this.columns = [
      {
        header: this.translate('ticketNumber'),
        field: 'ticketNumber',
        sortable: true,
        class: '',
        resizable: true,
      },
      {
        header: this.translate('title'),
        field: 'name',
        sortable: true,
        class: 'truncated',
        resizable: true,
        width: '200dp',
        minWidth: 200,
      },
      {
        header: this.translate('description'),
        field: 'description',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
        hide: true,
      },
      {
        header: this.translate('tickettype'),
        field: 'requestType',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translate('ticketclass'),
        field: 'incidentClassName',
        sortable: true,
        class: 'truncated',
        resizable: true,
        minWidth: 195,
      },
      {
        header: this.translate('address'),
        field: 'addressString',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translate('room'),
        field: 'room',
        sortable: true,
        class: '',
        resizable: true,
      },
      {
        header: this.translate('floor'),
        field: 'floor',
        sortable: true,
        class: 'truncated',
        resizable: true,
        hide: true,
      },
      {
        header: this.translate('structuralPart'),
        field: 'structuralPart',
        sortable: true,
        class: 'truncated',
        resizable: true,
        hide: true,
      },
      {
        header: this.translate('deviatingAddress'),
        field: 'deviatingAddress',
        sortable: true,
        class: 'truncated',
        resizable: true,
        hide: true,
      },
      {
        header: this.translate('createdOn'),
        field: 'createdOn',
        sortable: true,
        class: 'truncated',
        formatter: data =>
          data.createdOn
            ? moment(data.createdOn)
                .locale(this.translateService.instant('locale.locale'))
                .format(
                  this.translateService.instant(
                    'locale.moment__date_time_format'
                  )
                ) +
              ' ' +
              this.translate('extended_time_str')
            : '--',
        resizable: true,
        width: '140px',
        minWidth: 134,
      },
    ];

    this.filterFields = [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'ticketNumber',
            type: 'input',
            props: {
              label: this.translate('ticketNumber'),
            },
          },
          {
            key: 'name',
            type: 'input',
            props: {
              label: this.translate('title'),
            },
          },
          {
            key: 'description',
            type: 'input',
            props: {
              label: this.translate('description'),
            },
          },
          {
            key: 'tickettype',
            type: 'input',
            props: {
              label: this.translate('tickettype'),
            },
          },
          {
            key: 'ticketclass',
            type: 'input',
            props: {
              label: this.translate('ticketclass'),
            },
          },
          {
            key: 'address',
            type: 'input',
            props: {
              label: this.translate('address'),
            },
          },
          {
            key: 'room',
            type: 'input',
            props: {
              label: this.translate('room'),
            },
          },
          {
            key: 'floor',
            type: 'input',
            props: {
              label: this.translate('floor'),
            },
          },
          {
            key: 'structuralPart',
            type: 'input',
            props: {
              label: this.translate('structuralPart'),
            },
          },
          {
            key: 'deviatingAddress',
            type: 'input',
            props: {
              label: this.translate('deviatingAddress'),
            },
          },
          {
            type: 'flex-layout-col',
            fieldGroup: [
              {
                type: 'datepicker',
                key: 'createDateFrom',
                props: {
                  label: this.translate('createDateFrom'),
                },
              },
              {
                type: 'datepicker',
                key: 'createDateTo',
                props: {
                  label: this.translate('createDateTo'),
                },
              },
            ],
          },
        ],
      },
    ];

    this.dialogData = {
      columns: this.columns,
      filterFields: this.filterFields,
      filterValues: this.config.grid.filter.filterOptions,
      actions: {
        onSubmit: formModel => {
          this.mapFormModelToChips(formModel);
          this.updateFilter(formModel);
          this.refreshFromFilter();
          this.dialogData.filterValues = this.config.grid.filter.filterOptions;
        },
        onReset: () => {
          this.mapFormModelToChips({});
          this.updateFilter({});
          this.refreshFromFilter();
        },
      },
    };
  }

  modalComponent = ModalFilterComponent;

  override columns: MtxGridColumn[] = [];

  override getTableData$ = (query?: BaseSearchOptions) => {
    const request: MyIncidentsRequest = {
      isUserFiltered: true,
      pagingOptions: query.pagingOptions,
      filterOptions: {
        incidentTicketNumber:
          this.config.grid.filter.filterOptions.ticketNumber,
        incidentName: this.config.grid.filter.filterOptions.name,
        incidentDescription: this.config.grid.filter.filterOptions.description,
        incidentAddress: this.config.grid.filter.filterOptions.address,
        incidentType: this.config.grid.filter.filterOptions.tickettype,
        incidentClass: this.config.grid.filter.filterOptions.ticketclass,
        generalRequestRoom: this.config.grid.filter.filterOptions.room,
        generalRequestFloor: this.config.grid.filter.filterOptions.floor,
        generalRequestStructuralPart:
          this.config.grid.filter.filterOptions.structuralPart,
        generalRequestDeviatingAddress:
          this.config.grid.filter.filterOptions.deviatingAddress,
        dateRangeStart: this.config.grid.filter.filterOptions.createDateFrom,
        dateRangeEnd: this.config.grid.filter.filterOptions.createDateTo,
      },
      orderOptions: query.orderOptions,
    };
    return this.ticketService.getMyIncidents(request);
  };

  override clickRow = (data, router: Router) => {
    this.toastr.remove(this.toast?.toastId);
    if (data.rowData.requestType === RequestTypes.Benutzeradministration) {
      this.toast = this.toastr.info(
        this.translateService.instant('ticket.create.helpdesk.no_info')
      );
    } else {
      router.navigateByUrl('/helpdesk/my-tickets/' + data.rowData.incidentId, {
        state: {
          incidentClassName: data.rowData.incidentClassName,
        },
      });
    }
  };

  onCloseDialog = e => {
    console.log('[Ticket list] onCloseDialog', e);
  };

  handleOnRemoveChip = eventObj => {
    delete this.config.grid.filter.filterOptions[eventObj.id];
    this.chips = this.chips.filter(chipItem => chipItem.id !== eventObj.id);
    this.refreshFromFilter();
  };

  mapFormModelToChips = formModel => {
    this.chips = [];
    this.chips.length = 0;

    Object.keys(formModel).forEach(formModelItemKey => {
      const field = this.filterFields[0].fieldGroup.find(fieldItem => {
        return fieldItem.key === formModelItemKey;
      });
      if (field) {
        if (formModelItemKey === 'ticketTaskSelection') {
          const option = (field.props.options as any[]).find(
            opt => opt.value === formModel[formModelItemKey]
          );
          if (option) {
            if (this.sysUser) {
              const existingChip = this.chips.find(
                chip => chip.id === formModelItemKey
              );
              if (existingChip) {
                this.chips.splice(this.chips.indexOf(existingChip), 1, {
                  id: formModelItemKey,
                  label: `${field.props.label}: ${option.label}`,
                });
              } else {
                this.chips.push({
                  id: formModelItemKey,
                  label: `${field.props.label}: ${option.label}`,
                });
              }
            } else {
              this.chips.push({
                id: formModelItemKey,
                label: `${field.props.label}: ${option.label}`,
              });
            }
          }
        } else if (
          formModelItemKey === 'createDateTo' ||
          formModelItemKey === 'createDateFrom'
        ) {
          const date = new Date(formModel[formModelItemKey]).toLocaleDateString(
            'de-DE'
          );
          this.chips.push({
            id: formModelItemKey,
            label: `${field.props.label}: ${date}`,
          });
        } else {
          if (formModel[formModelItemKey]) {
            this.chips.push({
              id: formModelItemKey,
              label: `${field.props.label}: ${formModel[formModelItemKey]}`,
            });
          }
        }
      }
    });
  };
}
