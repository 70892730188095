<su-headline h="h1" skin="primary">{{
    'access_orderer.title' | translate
  }}</su-headline>

  <su-button
    class="fa-regular"
    skin="secondary"
    icon="book-open"
    [label]="'access_orderer.history.title' | translate"
    (click)="openHistory()"></su-button><br><br>

    <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; margin-bottom: 25px">
        <form [formGroup]="form" style="width: 80%; margin-bottom: 10px;">
            <formly-form
            [fields]="filterFields"
            [form]="form"
            [model]="formModel"></formly-form>
        </form>
    </div>
  
  <mtx-grid
    #grid
    [columns]="columns"
    [data]="data.items"
    [loading]="isLoading"
    [length]="data.totalCount"
    [sortOnFront]="config.grid.filter.sortOnFront"
    (sortChange)="sortChange($event)"
    [pageOnFront]="false"
    [pageSize]="config.grid.paging.pageSize"
    [pageIndex]="config.grid.paging.pageIndex"
    (page)="getNextPage($event)"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [showPaginator]="config.grid.paging.showPaginator"
    [rowStriped]="true"
    [rowHover]="true"
    [cellSelectable]="false"
    [showSidebar]="true"
    [hideRowSelectionCheckbox]="false"
    [showColumnMenuButton]="false">
</mtx-grid>

<ng-template #addExtendTemplate let-row let-index="index" let-col="colDef">
    <div class="button-container">
        <su-button
            *ngIf="!row.accessOrdererEnddate"
            skin="primary"
            icon="check"
            (click)="openDialogAccessOrderer(row, 'add')">
        </su-button>
        <su-button
            *ngIf="row.isExtendable"
            skin="primary"
            icon="check"
            (click)="openDialogAccessOrderer(row, 'extend')">
        </su-button>
    </div>
</ng-template>

<ng-template #endTemplate let-row let-index="index" let-col="colDef">
    <su-button
        *ngIf="row.accessOrdererEnddate"
        skin="secondary"
        icon="x"
        (click)="openDialogAccessOrderer(row, 'end')">
    </su-button>
</ng-template>