import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MtxGridModule } from '@ng-matero/extensions/grid';
import { TranslateModule } from '@ngx-translate/core';
import { SmartusComponentsModule } from '@affinis/smartus-components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { OverrideModule } from '@mandants/telekom/override/override.module';
import { SafeHtmlPipe } from '@shared/pipes';
import { HtmlEditorComponent } from '@shared/components/html-editor/html-editor.component';
import { MatIconModule } from '@angular/material/icon';
import { WebsiteEditComponent } from './website-edit.component';
import { RentalListingEditComponent } from './components/rental-listing-edit/rental-listing-edit.component';
import { ConfirmationDialogComponent } from './components/rental-listing-edit-dialog/confirmation-dialog.component';

const components = [
  WebsiteEditComponent,
  RentalListingEditComponent,
  ConfirmationDialogComponent,
];
@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    SmartusComponentsModule,
    TranslateModule,
    FormlyModule,
    MtxGridModule,
    AngularEditorModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    OverrideModule,
    SafeHtmlPipe,
    HtmlEditorComponent,
    MatIconModule,
  ],
  exports: [...components],
})
export class WebsiteEditModule {}
