<!-- <app-background-image [imageUrl]="'../../../assets/images/polygon.svg'"></app-background-image> -->

<su-card [card]="cardMap">
  <div card-content>
    <div>
      <h3
        *ngIf="!loginDisplay"
        [innerText]="'profile.headline__pleaseLogin' | translate"></h3>
      <ng-container *ngIf="loginDisplay">
        <ng-container *ngIf="accountInfo">
          <p>
            <span [innerText]="'profile.family_name' | translate"></span>:
            <strong
              [innerText]="accountInfo.idTokenClaims?.family_name"></strong>
          </p>
          <p>
            <span [innerText]="'profile.given_name' | translate"></span>:
            <strong
              [innerText]="accountInfo.idTokenClaims?.given_name"></strong>
          </p>
        </ng-container>
        <div *ngIf="showJson">
          <pre *ngIf="accountInfo">{{ accountInfo | json }}</pre>
        </div>
      </ng-container>
    </div>
  </div>
</su-card>
