import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-tooltip-wrapper',
  template: `
    <div
      [matTooltip]="props.tooltip"
      [matTooltipPosition]="props.tooltipPosition || 'above'">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
})
export class FormlyTooltipWrapperComponent extends FieldWrapper {}
