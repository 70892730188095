<su-headline h="h1" skin="primary">{{ headline }}</su-headline>

<div>
  <ng-container>
    <mtx-grid
      #grid
      [columns]="columns"
      [data]="approvalData$ | async"
      [loading]="isLoadingData"
      [rowStriped]="true"
      [noResultText]="'grid.no_results' | translate"
      [rowHover]="false"
      [cellSelectable]="false"
      [hideRowSelectionCheckbox]="false"
      [showColumnMenuButton]="false">
    </mtx-grid
  ></ng-container>
</div>

<ng-template #actionsTemplate let-row let-index="index" let-col="colDef">
  <div class="button-container">
    <su-button
      skin="primary"
      icon="check"
      (onClick)="onApprove(row)"
      [title]="'taskmodule.tickets.list.permission.approve' | translate">
    </su-button>
    <su-button
      skin="secondary"
      icon="xmark"
      (onClick)="onDeny(row)"
      [title]="'taskmodule.tickets.list.permission.deny' | translate">
    </su-button>
  </div>
</ng-template>

<app-spinner-overlay
  [show]="loading"
  [label]="loadingMessage"></app-spinner-overlay>
