<su-headline [hidden]="filledForm" h="h1" skin="primary">{{
  title
}}</su-headline>

<div [style]="descriptionContainerStyle">
  <div
    *ngIf="!filledForm"
    class="sub-headline contactDescription"
    [style]="descriptionStyle"
    [innerHtml]="description | safeHtml"></div>
</div>

<su-card [card]="cardFields" [hidden]="filledForm">
  <div card-content>
    <form (ngSubmit)="validateForm()" [formGroup]="form">
      <div [hidden]="filledForm">
        <formly-form
          [fields]="formFields"
          [form]="form"
          [model]="formModel"
          [options]="options"></formly-form>
      </div>
    </form>
  </div>
</su-card>

<su-headline *ngIf="filledForm" h="h1" skin="primary">{{
  'relocation.summary' | translate
}}</su-headline>
<div class="description" *ngIf="filledForm">
  <p
    [innerText]="'relocation.p__info_summary' | translate"
    class="confirm-value"></p>
</div>
<br />
<su-card [card]="cardConfirm" *ngIf="filledForm">
  <div card-content data-debug="form-confirm">
    <ng-container>
      <div class="confirm-container">
        <div>
          <div class="confirm-label">
            <span>
              {{
                'relocation.location_of_origin.incidentDescription' | translate
              }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formModel['incidentDescription']">
              {{ formModel['incidentDescription'] }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formModel['incidentDescription']">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'relocation.location_of_origin.firstCheckBox' | translate }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formModel['infoConsent']">
              {{
                formModel['infoConsent'] === true
                  ? ('shared.yes' | translate)
                  : ('shared.no' | translate)
              }}
            </span>
            <span class="confirm-value" *ngIf="!formModel['infoConsent']">
              {{ 'shared.no' | translate }}
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'relocation.location_of_origin.secondCheckBox' | translate }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formModel['spaceRequestReturn']">
              {{
                formModel['spaceRequestReturn'] === true
                  ? ('shared.yes' | translate)
                  : ('shared.no' | translate)
              }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formModel['spaceRequestReturn']">
              {{ 'shared.no' | translate }}
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'relocation.location_of_origin.worker' | translate }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formModel['editor']?.searchResult?.id">
              <span>{{ formModel['editor']?.searchResult?.fullName }}</span>
              <span>{{
                formModel['editor']?.searchResult?.email
                  ? ', ' + formModel['editor']?.searchResult?.email
                  : ''
              }}</span>
              <span>{{
                formModel['editor']?.searchResult?.phone
                  ? ', ' + formModel['editor']?.searchResult?.phone
                  : ''
              }}</span>
              <span>{{
                formModel['editor']?.searchResult?.company
                  ? ', ' + formModel['editor']?.searchResult?.company
                  : ''
              }}</span>
              <span>{{
                formModel['editor']?.searchResult?.orgUnit
                  ? ', ' + formModel['editor']?.searchResult?.orgUnit
                  : ''
              }}</span>
            </span>
            <span
              class="confirm-value"
              *ngIf="!formModel['editor']?.searchResult?.id">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'relocation.location_of_origin.contact_person' | translate }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formModel['contactPerson']?.searchResult?.id">
              <span>{{
                formModel['contactPerson']?.searchResult?.fullName
              }}</span>
              <span>{{
                formModel['contactPerson']?.searchResult?.email
                  ? ', ' + formModel['contactPerson']?.searchResult?.email
                  : ''
              }}</span>
              <span>{{
                formModel['contactPerson']?.searchResult?.phone
                  ? ', ' + formModel['contactPerson']?.searchResult?.phone
                  : ''
              }}</span>
              <span>{{
                formModel['contactPerson']?.searchResult?.company
                  ? ', ' + formModel['contactPerson']?.searchResult?.company
                  : ''
              }}</span>
              <span>{{
                formModel['contactPerson']?.searchResult?.orgUnit
                  ? ', ' + formModel['contactPerson']?.searchResult?.orgUnit
                  : ''
              }}</span>
            </span>
            <span
              class="confirm-value"
              *ngIf="!formModel['contactPerson']?.searchResult?.id">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'relocation.location_of_origin.location' | translate }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="
                formModel['originLocation'].searchResult ||
                formModel['originLocation'].addressManually
              ">
              <span
                *ngIf="formModel['originLocation'].telekomCheckOrigin === true">
                <span>{{ formModel['originLocation'].searchResult?.id }}</span>
                <span>{{
                  formModel['originLocation'].searchResult?.streetName
                    ? ', ' +
                      formModel['originLocation'].searchResult?.streetName
                    : ''
                }}</span>
                <span>{{
                  formModel['originLocation'].searchResult?.postalCode
                    ? ', ' +
                      formModel['originLocation'].searchResult?.postalCode
                    : ''
                }}</span>
                <span>{{
                  formModel['originLocation'].searchResult?.city
                    ? ', ' + formModel['originLocation'].searchResult?.city
                    : ''
                }}</span>
              </span>
              <span
                *ngIf="
                  formModel['originLocation'].telekomCheckOrigin === false
                ">
                <span>{{
                  formModel['originLocation'].addressManually?.firstName
                }}</span>
                <span>{{
                  formModel['originLocation'].addressManually?.lastName
                    ? ' ' +
                      formModel['originLocation'].addressManually?.lastName
                    : ''
                }}</span>
                <span>{{
                  formModel['originLocation'].addressManually?.address
                    ? ', ' +
                      formModel['originLocation'].addressManually?.address
                    : ''
                }}</span>
                <span>{{
                  formModel['originLocation'].addressManually?.zipCode
                    ? ', ' +
                      formModel['originLocation'].addressManually?.zipCode
                    : ''
                }}</span>
                <span>{{
                  formModel['originLocation'].addressManually?.city
                    ? ', ' + formModel['originLocation'].addressManually?.city
                    : ''
                }}</span>
              </span>
            </span>
            <span
              class="confirm-value"
              *ngIf="
                !formModel['originLocation'].searchResult?.id &&
                !formModel['originLocation'].addressManually?.firstName
              ">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span>
              {{ 'relocation.location_of_origin.target' | translate }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="
                formModel['targetLocation'].searchResult ||
                formModel['targetLocation'].addressManually
              ">
              <span
                *ngIf="formModel['targetLocation'].telekomCheckTarget === true">
                <span>{{ formModel['targetLocation'].searchResult?.id }}</span>
                <span>{{
                  formModel['targetLocation'].searchResult?.streetName
                    ? ', ' +
                      formModel['targetLocation'].searchResult?.streetName
                    : ''
                }}</span>
                <span>{{
                  formModel['targetLocation'].searchResult?.postalCode
                    ? ', ' +
                      formModel['targetLocation'].searchResult?.postalCode
                    : ''
                }}</span>
                <span>{{
                  formModel['targetLocation'].searchResult?.city
                    ? ', ' + formModel['targetLocation'].searchResult?.city
                    : ''
                }}</span>
              </span>
              <span
                *ngIf="
                  formModel['targetLocation'].telekomCheckTarget === false
                ">
                <span>{{
                  formModel['targetLocation'].addressManually?.firstName
                }}</span>
                <span>{{
                  formModel['targetLocation'].addressManually?.lastName
                    ? ' ' +
                      formModel['targetLocation'].addressManually?.lastName
                    : ''
                }}</span>
                <span>{{
                  formModel['targetLocation'].addressManually?.address
                    ? ', ' +
                      formModel['targetLocation'].addressManually?.address
                    : ''
                }}</span>
                <span>{{
                  formModel['targetLocation'].addressManually?.zipCode
                    ? ', ' +
                      formModel['targetLocation'].addressManually?.zipCode
                    : ''
                }}</span>
                <span>{{
                  formModel['targetLocation'].addressManually?.city
                    ? ', ' + formModel['targetLocation'].addressManually?.city
                    : ''
                }}</span>
              </span>
            </span>
            <span
              class="confirm-value"
              *ngIf="
                !formModel['targetLocation'].searchResult?.id &&
                !formModel['targetLocation'].addressManually?.firstName
              ">
              --
            </span>
          </div>
        </div>

        <div>
          <div class="confirm-label">
            <span> {{ 'relocation.desired_date.title' | translate }}: </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formModel['requestedDate']">
              {{ formModel['requestedDate'] | date: 'dd.MM.yyyy' }}
            </span>
            <span class="confirm-value" *ngIf="!formModel['requestedDate']">
              --
            </span>
            <span *ngIf="formModel['attachments']?.length === 0">
              <br />
              <br />
            </span>
          </div>
        </div>

        <div *ngIf="formModel['attachments']?.length > 0">
          <su-headline skin="secondary" h="h2">
            {{ 'ticket.create.confirm.headline__attachment' | translate }}
          </su-headline>
          <div *ngFor="let file of formModel['attachments']">
            <div class="confirm-label">
              <span>{{
                'ticket.create.confirm.label__attachmentType' | translate
              }}</span
              >:
            </div>
            <span class="confirm-value">{{
              'ticket.create.incidentClass.options.other.label' | translate
            }}</span>
            <br /><br />
            <div class="confirm-label">
              <span>{{
                'ticket.create.confirm.label__attachment' | translate
              }}</span
              >:
            </div>
            <span class="confirm-value">{{ file.attachment?.name }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <form (ngSubmit)="submit()">
      <div class="confirm-buttons">
        <su-button
          [skin]="'primary'"
          [icon]="'arrow-left'"
          [label]="'ticket.create.confirm.button__back_to_edit' | translate"
          (onClick)="pressBackToEdit()">
        </su-button>
        <su-button
          id="buttonSendInConfirmForm"
          type="submit"
          [skin]="'accent'"
          [icon]="'paper-plane'"
          [label]="'ticket.create.confirm.button__send' | translate"
          [disabled]="isSubmitted">
        </su-button>
      </div>
    </form>
  </div>
</su-card>

<app-spinner-overlay
  [show]="loading"
  [label]="loadingMessage"></app-spinner-overlay>
