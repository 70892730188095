<div class="sell-page">
  <img src="../../../assets/images/Verkaufsteaser.png" alt="Fixed Image" />
</div>

<div class="sell-search">
  <div class="grid-container">
    <div class="header-item">
      <h1 data-text="Bye Renting.">Bye Renting.</h1>
      <h1 data-text="Buy Real Estate.">Buy Real Estate.</h1>
    </div>
    <div class="picture-item">
      <img
        src="../../../assets/images/Map Webseite White.png"
        alt="Fixed Image"
        class="fixed-image" />
    </div>
    <div class="sell-objects">
      <div class="card-content"></div>
      <div class="app-sell-items-container">
        <div></div>
      </div>
    </div>
  </div>
</div>
