import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-wrapper-panel',
  template: `
    <div
      data-debug="formly-no-border-wrapper"
      [ngStyle]="{ margin: props.margin ?? '15px 0' }">
      <ng-container *ngIf="props.showLabel">
        {{ props.label }}
      </ng-container>
      <ng-container #fieldComponent></ng-container>
      <mat-error
        *ngIf="
          formControl.errors && (formControl.touched || formControl.dirty)
        ">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </div>
  `,
})
export class FormlyNoBorderWrapperComponent extends FieldWrapper {}
