import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { AnswerApproval, ApprovalClient, ApprovalViewModel } from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { OverrideModule } from '@mandants/telekom/override/override.module';
import { MtxGridColumn, MtxGridModule } from '@ng-matero/extensions/grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, first } from 'rxjs';
import { ApprovalDialogComponent } from '../approval-dialog/approval-dialog.component';
import { DenialDialogComponent } from '../denial-dialog/denial-dialog.component';
import { PermissionMode } from '../models/permissionMode';

@Component({
  selector: 'app-approvals',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SmartusComponentsModule,
    MtxGridModule,
    MatDialogModule,
    OverrideModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  templateUrl: './approvals.component.html',
  styleUrl: './approvals.component.scss',
})
export class ApprovalsComponent implements OnInit {
  headline = this.translateService.instant('Freigaben verwalten');

  loadingMessage = '';
  isLoadingData = true;
  loading = false;

  columns: Array<MtxGridColumn> = [];

  private approvalDataSubject$ = new BehaviorSubject<ApprovalViewModel[]>([]);
  approvalData$ = this.approvalDataSubject$.asObservable();

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplateRef!: TemplateRef<void>;

  translatePath = 'taskmodule.tickets.list.permission';

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private approvalClient: ApprovalClient,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.getData();

    this.breadcrumbService.setBreadcrumbs([{ label: this.headline }]);

    this.loadingMessage = this.translateService.instant('form.loading_message');
  }

  ngOnInit(): void {
    this.columns = [
      {
        header: this.translateService.instant('Name'),
        field: 'name',
        class: 'truncated',
        resizable: true,
        width: '200dp',
        minWidth: 200,
      },
      {
        header: this.translateService.instant('Ticketnummer'),
        field: 'ticketNumber',
        sortable: true,
        resizable: true,
        minWidth: 180,
      },
      {
        header: this.translateService.instant('Funktionaler Standort (Ticket)'),
        field: 'archObjId',
        resizable: true,
        minWidth: 180,
      },
      {
        header: this.translateService.instant('Reihenfolge'),
        field: 'sequence',
        resizable: true,
        minWidth: 180,
      },
      {
        header: this.translateService.instant('Freigabestatus'),
        field: 'approvalStatus',
        resizable: true,
        minWidth: 180,
      },
      {
        field: 'actions',
        cellTemplate: this.actionsTemplateRef,
      },
    ];
  }

  public onApprove(row) {
    this.checkDialog(
      this.translateService.instant(`${this.translatePath}.onApprove.title`),
      this.translateService.instant(`${this.translatePath}.onApprove.message`, {
        ticketNumber: row.ticketNumber,
      }),
      PermissionMode.Approval,
      this.translateService.instant(
        `${this.translatePath}.onApprove.error_message`
      ),
      this.translateService.instant(
        `${this.translatePath}.onApprove.success_message`
      ),
      row.ticketNumber,
      row.id
    );
  }

  public onDeny(row) {
    this.checkDialog(
      this.translateService.instant(`${this.translatePath}.onDeny.title`),
      this.translateService.instant(`${this.translatePath}.onDeny.message`, {
        ticketNumber: row.ticketNumber,
      }),
      PermissionMode.Denial,
      this.translateService.instant(
        `${this.translatePath}.onDeny.error_message`
      ),
      this.translateService.instant(
        `${this.translatePath}.onDeny.success_message`
      ),
      row.ticketNumber,
      row.id
    );
  }

  public checkDialog(
    title: string,
    message: string,
    mode: PermissionMode,
    errorMessage: string,
    successMessage: string,
    ticketNumber,
    id: string
  ) {
    let checkDialog: MatDialogRef<
      ApprovalDialogComponent | DenialDialogComponent
    >;
    if (mode === PermissionMode.Approval) {
      checkDialog = this.dialog.open(ApprovalDialogComponent, {
        panelClass: 'smartportal-dialog',
        width: '500px',
        data: {
          type: 'okDismissButton',
          title: title,
          message: message,
          ticketNumber,
          id,
        },
      });
    } else if (mode === PermissionMode.Denial) {
      checkDialog = this.dialog.open(DenialDialogComponent, {
        panelClass: 'smartportal-dialog',
        width: '500px',
        data: {
          title: title,
          message: message,
          ticketNumber,
          id,
        },
      });
    }

    checkDialog
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: res => {
          this.loading = true;
          if (res === false || res === undefined) {
            this.loading = false;
            return;
          }

          this.saveEntry(
            res?.id,
            res?.mode ?? mode,
            successMessage,
            errorMessage,
            (res?.reasonForDenial || res?.reasonForApproval) ?? null
          );
        },
        error: () => {
          this.toastr.error(
            errorMessage,
            this.translateService.instant('alert.error')
          );
        },
      });
  }

  saveEntry(
    id: string,
    mode: PermissionMode,
    successMessage: string,
    errorMessage: string,
    reason?: string
  ) {
    this.loading = true;
    const approve = mode === PermissionMode.Approval ?? false;

    const request: AnswerApproval = {
      id,
      approve,
      returnMessage: reason,
    };

    this.approvalClient.answerApproval(request).subscribe({
      next: () => {
        this.toastr.success(successMessage);
        this.loading = false;
        this.getData();
      },
      error: () => {
        this.toastr.error(
          errorMessage,
          this.translateService.instant('alert.error')
        );
        this.loading = false;
        this.getData();
      },
    });
  }

  getData() {
    this.isLoadingData = true;
    this.approvalClient.getApprovals({}).subscribe({
      next: res => {
        this.approvalDataSubject$.next(res.entites);
        this.isLoadingData = false;
      },
      error: () => {
        this.toastr.error(
          this.translateService.instant('generic.errorMessage')
        );
        this.isLoadingData = false;
      },
    });
  }
}
