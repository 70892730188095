<su-headline [hidden]="filledForm" h="h1" skin="primary">{{
  title
}}</su-headline>

<su-card [card]="card" [hidden]="filledForm">
  <div card-content>
    <form (ngSubmit)="validateForm()" [formGroup]="form">
      <div [hidden]="filledForm">
        <formly-form
          [fields]="formFields"
          [form]="form"
          [model]="formModel"
          [options]="options"></formly-form>
      </div>
    </form>
  </div>
</su-card>

<su-headline *ngIf="filledForm" h="h1" skin="primary">{{
  'ticket.create.space_request.summary' | translate
}}</su-headline>
<div class="description" *ngIf="filledForm">
  <p
    [innerText]="'ticket.create.space_request.p__info_summary' | translate"
    class="confirm-value"></p>
</div>
<br />
<su-card [card]="cardConfirm" *ngIf="filledForm">
  <div card-content data-debug="form-confirm">
    <ng-container>
      <div class="confirm-container">
        <div>
          <div class="confirm-label">
            <span>
              {{ 'ticket.create.space_request.short_title' | translate }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formModel['shortTitle']">
              {{ formModel['shortTitle'] }}
            </span>

            <span class="confirm-value" *ngIf="!formModel['shortTitle']">
              --
            </span>
          </div>
        </div>
        <br />
        <div>
          <div class="confirm-label">
            <span>
              {{ 'ticket.create.space_request.description' | translate }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formModel['incidentDescription']">
              {{ formModel['incidentDescription'] }}
            </span>

            <span
              class="confirm-value"
              *ngIf="!formModel['incidentDescription']">
              --
            </span>
          </div>
        </div>

        <br />
        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.space_request.requested_location' | translate
              }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formModel['searchResult']">
              {{ formModel['searchResult'].id }},
              {{ formModel['searchResult'].streetName }},
              {{ formModel['searchResult'].postalCode }}
              {{ formModel['searchResult'].city }}
            </span>
            <span class="confirm-value" *ngIf="!formModel['searchResult']">
              --
            </span>
          </div>
        </div>

        <br />
        <div>
          <div class="confirm-label">
            <span>
              {{ 'ticket.create.space_request.requested_date' | translate }}:
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formModel['desiredDate']">
              {{ formModel['desiredDate'] | date: 'dd.MM.yyyy' }}
            </span>
            <span class="confirm-value" *ngIf="!formModel['desiredDate']">
              --
            </span>
          </div>
        </div>

        <br />
        <div>
          <div class="confirm-label">
            <span>
              {{
                'ticket.create.space_request.requested_area.title' | translate
              }}: <br /><br />
            </span>
          </div>
          <div>
            <span class="confirm-value" *ngIf="formModel['spaceRequest']">
              <mtx-grid
                [columns]="spaceRequestColumns"
                [data]="spaceRequestData"
                [rowStriped]="true"
                [showPaginator]="false"
                [noResultText]="''"
                [cellSelectable]="false">
              </mtx-grid>
            </span>

            <span class="confirm-value" *ngIf="!formModel['spaceRequest']">
              --
            </span>
          </div>
        </div>

        <div *ngIf="formModel['attachments']?.length > 0">
          <br />
          <su-headline skin="secondary" h="h2">
            {{ 'ticket.create.confirm.headline__attachment' | translate }}
          </su-headline>
          <div *ngFor="let file of formModel['attachments']">
            <div class="confirm-label">
              <span>{{
                'ticket.create.confirm.label__attachmentType' | translate
              }}</span
              >:
            </div>
            <span class="confirm-value">{{
              'ticket.create.incidentClass.options.other.label' | translate
            }}</span>
            <br /><br />
            <div class="confirm-label">
              <span>{{
                'ticket.create.confirm.label__attachment' | translate
              }}</span
              >:
            </div>
            <span class="confirm-value">{{ file.attachment?.name }}</span>
            <span
              *ngIf="
                file !==
                formModel['attachments'][formModel['attachments']?.length - 1]
              ">
              <br /><br />
            </span>
          </div>
        </div>

        <br />
        <div>
          <div class="confirm-label">
            <span>
              {{ 'ticket.create.headline__search_creator' | translate }}:
            </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formModel['searchContact'].searchResult.id">
              {{ formModel['searchContact'].searchResult.fullName }},
              {{ formModel['searchContact'].searchResult.phone }},
              {{ formModel['searchContact'].searchResult.email }},
              {{ formModel['searchContact'].searchResult.company }},
              {{ formModel['searchContact'].searchResult.orgUnit }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formModel['searchContact'].searchResult.id">
              --
            </span>
          </div>
        </div>

        <br />
        <div>
          <div class="confirm-label">
            <span> {{ 'ticket.create.label__processor' | translate }}: </span>
          </div>
          <div>
            <span
              class="confirm-value"
              *ngIf="formModel['contactPerson'].searchResult.id">
              {{ formModel['contactPerson'].searchResult.fullName }},
              {{ formModel['contactPerson'].searchResult.phone }},
              {{ formModel['contactPerson'].searchResult.email }},
              {{ formModel['contactPerson'].searchResult.company }},
              {{ formModel['contactPerson'].searchResult.orgUnit }}
            </span>
            <span
              class="confirm-value"
              *ngIf="!formModel['contactPerson'].searchResult.id">
              --
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <form (ngSubmit)="submit()">
      <div class="confirm-buttons">
        <su-button
          [skin]="'primary'"
          [icon]="'arrow-left'"
          [label]="'ticket.create.confirm.button__back_to_edit' | translate"
          (onClick)="pressBackToEdit()">
        </su-button>
        <su-button
          id="buttonSendInConfirmForm"
          type="submit"
          [skin]="'accent'"
          [icon]="'paper-plane'"
          [label]="'ticket.create.confirm.button__send' | translate"
          [disabled]="isSubmitted">
        </su-button>
      </div>
    </form>
  </div>
</su-card>

<app-spinner-overlay
  [show]="loading"
  [label]="loadingMessage"></app-spinner-overlay>
