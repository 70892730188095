<div class="container">
  <input
    type="file"
    multiple
    (change)="onFileSelected($event)"
    title=""
    #fileUpload />
  <div class="headline-document-upload-browse">
    <h3>{{ 'crud.fileUpload.dragAndDrop.title' | translate }}</h3>
    <h3>{{ 'crud.fileUpload.dragAndDrop.titleOr' | translate }}</h3>
  </div>

  <div class="document-upload-browse">
    <su-button
      skin="primary"
      [label]="'crud.fileUpload.dragAndDrop.browse' | translate"></su-button>

    <div class="row-actions">
      <mat-form-field
        *ngIf="hasTargetSystemSelection && targetSystems"
        appearance="outline"
        class="drag-and-drop-form-field">
        <mat-label>{{ 'documents.label__target' | translate }}</mat-label>
        <input
          type="text"
          matInput
          [formControl]="defaultTargetSystemControl"
          [matAutocomplete]="auto"
          (blur)="defaultTargetSystemOnBlur()" />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onTargetSystemSelected($event)">
          <mat-option
            *ngFor="let option of filteredTargetSystems | async"
            [value]="$any(option).label">
            {{ $any(option).label }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>
          <span
            [innerHtml]="'crud.fileUpload.error.required' | translate"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="hasTaskSelection && tasks?.length"
        appearance="outline"
        class="drag-and-drop-form-field">
        <mat-label>{{ 'documents.label__task' | translate }}</mat-label>
        <input
          type="text"
          matInput
          [formControl]="defaultTaskControl"
          [matAutocomplete]="auto"
          (blur)="defaultTaskOnBlur()" />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onTaskSelected($event)">
          <mat-option>{{ 'documents.label__noTask' | translate }}</mat-option>
          <mat-option
            *ngFor="let option of filteredTasks | async"
            [value]="$any(option).subject">
            {{ $any(option).subject }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>
          <span
            [innerHtml]="'crud.fileUpload.error.required' | translate"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="hasDocumentTypeSelection && documentTypeGroups"
        appearance="outline"
        class="drag-and-drop-form-field">
        <mat-label>{{ 'documents.label__documentType' | translate }}</mat-label>
        <input
          type="text"
          matInput
          [formControl]="defaultDocumentTypeControl"
          [matAutocomplete]="auto"
          (blur)="defaultDocumentTypeOnBlur()" />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onDocumentTypeSelected($event)">
          <ng-container *ngIf="documentTypeGroups.length > 1">
            <mat-optgroup
            *ngFor="let group of filteredDocumentTypes | async" [label]="group.name">
            <mat-option
            *ngFor="let documentType of group.documentTypes"
            [value]="$any(documentType).name"
            [matTooltip]="$any(documentType).tooltip">
              {{ $any(documentType).name }}
            </mat-option>
          </mat-optgroup>
        </ng-container>
        <ng-container *ngIf="documentTypeGroups.length === 1">
          <mat-option
            *ngFor="let documentType of (filteredDocumentTypes | async)[0].documentTypes"
            [value]="$any(documentType).name"
            [matTooltip]="$any(documentType).tooltip">
              {{ $any(documentType).name }}
            </mat-option>
        </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        *ngIf="hasDocumentVisibilitySelection && documentVisibilities"
        appearance="outline"
        class="drag-and-drop-form-field">
        <mat-label>{{
          'documents.label__documentVisibility' | translate
        }}</mat-label>
        <input
          type="text"
          matInput
          [formControl]="defaultDocumentVisibilityControl"
          [matAutocomplete]="auto"
          (click)="initFilteredDocumentVisibilities()" />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onDocumentVisibilitySelected($event)">
          <mat-option
            *ngFor="let option of documentVisibilitiesSelectable"
            [value]="$any(option).label"
            [matTooltip]="$any(option).tooltip">
            {{ $any(option).label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <br />
  <br />
</div>
<div class="files-list" data-debug="detail-file-upload_uploaded-files">
  <div
    *ngFor="let typedFileForm of typedFiles.controls; let i = index"
    class="single-file-container">
    <div class="single-file" [formGroup]="castToFormGroup(typedFileForm)">
      <div class="row-name">
        <mat-icon>attach_file</mat-icon>
        <div class="info">
          <h4 class="name">
            {{ typedFileForm.value.file.name }}
          </h4>
          <p class="size">
            {{ formatBytes(typedFileForm.value.file.size) }}
          </p>
          <!-- <app-progress [progress]="$any(typedFileForm).progress"></app-progress> -->
          <mat-error
            *ngIf="checkForFileSizeError(typedFileForm) as errorMessage">
            <span [innerHtml]="errorMessage"></span>
          </mat-error>
        </div>
      </div>
      <div class="row-actions">
        <div class="form-field-container">
          <mat-form-field *ngIf="hasTargetSystemSelection && targetSystems">
            <mat-label>{{ 'documents.label__target' | translate }}</mat-label>
            <mat-select formControlName="targetSystem">
              <mat-option
                *ngFor="let target of targetSystems"
                [value]="target.value"
                [title]="target.label">
                {{ target.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <mat-form-field *ngIf="hasTaskSelection && tasks?.length">
            <mat-label>{{ 'documents.label__task' | translate }}</mat-label>
            <mat-select formControlName="taskId">
              <mat-option>{{
                'documents.label__noTask' | translate
              }}</mat-option>
              <mat-option
                *ngFor="let task of tasks"
                [value]="task.taskId"
                [title]="task.subject">
                {{ task.subject }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <mat-form-field *ngIf="hasDocumentTypeSelection && documentTypeGroups">
            <mat-label>{{
              'documents.label__documentType' | translate
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="documentTypeLabel"
              [matAutocomplete]="auto"
              (blur)="onDocumentTypeOptionOnBlur(i)" />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onDocumentTypeOptionSelected($event, i)">
              <mat-optgroup
                *ngFor="let group of filteredDocumentTypeOptions[i] | async" [label]="group.name">
                <mat-option
                  *ngFor="let documentType of group.documentTypes"
                  [value]="documentType.name">
                  {{ documentType.name }}
                </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error
            *ngIf="typedFileForm.get('documentTypeId').hasError('required')">
            <span
              [innerHtml]="'crud.fileUpload.error.required' | translate"></span>
          </mat-error>
        </div>

        <div class="form-field-container">
          <mat-form-field
            *ngIf="hasDocumentVisibilitySelection && documentVisibilities">
            <mat-label>{{
              'documents.label__documentVisibility' | translate
            }}</mat-label>
            <mat-select formControlName="documentVisibilityId">
              <mat-option
                *ngFor="let documentVisibility of documentVisibilities"
                [value]="documentVisibility.value"
                [matTooltip]="documentVisibility.tooltip">
                {{ documentVisibility.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row-button-container">
          <su-button
            [skin]="'accent'"
            [icon]="'xmark'"
            (onClick)="detachFile(i)"
            [disabled]="busy"></su-button>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        getContentTypeList(
          typedFileForm.get('documentTypeId').value
        ) as contentTypes
      "
      class="content-types-hint">
      {{ 'documents.allowed__contentTypes' | translate : { contentTypes } }}
    </div>
  </div>
</div>

<div class="button-upload">
  <su-button
    skin="primary"
    icon="upload"
    [label]="'crud.file.upload' | translate"
    (onClick)="uploadFiles()"
    [disabled]="busy || !typedFiles.value.length"></su-button>
</div>
<mat-error *ngIf="!form.valid && form.touched">
  <div class="error-message">
    <span
      [innerHtml]="
        'crud.fileUpload.error.missingRequiredFields' | translate
      "></span>
  </div>
</mat-error>

<app-spinner-overlay [show]="busy"></app-spinner-overlay>
