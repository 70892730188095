import { ProtectedResourceScopes } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';
import { environment } from '@core/environments/environment';
import { RoutePath } from '@core/routing/routing.types';
import { Authorization } from '@shared/constants/authorization.constants';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNIN_ONLY_ROLES',
    editProfile: 'B2C_1_PasswordReset',
  },
  authorities: {
    signUpSignIn: {
      authority: environment.AadSignupSigninUrl,
    },
    editProfile: {
      authority: environment.AadEditprofileUrl,
    },
  },
  authorityDomain: environment.AadAuthorityDomain,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.AadClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `${window.location.origin}/${RoutePath.AUTH_CALLBACK}`,
    postLogoutRedirectUri: `${window.location.origin}/${RoutePath.LOGOUT}`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (_logLevel, _message, _containsPii) => {},
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  getIncident: {
    endpoint: '/api/Incident/GetIncidents',
    scopes: [environment.AadScope],
  },
  getIncidentsAuthenticated: {
    endpoint: '/api/Incident/GetIncidentsAuthenticated',
    scopes: [environment.AadScope],
  },
  getIncidentAuthenticated: {
    endpoint: '/api/Incident/GetIncidentAuthenticated',
    scopes: [environment.AadScope],
  },
  getBuildingFaultIncident: {
    endpoint: '/api/Incident/GetBuildingFaultIncident',
    scopes: [environment.AadScope],
  },
  getBuildingFaultIncidents: {
    endpoint: '/api/Incident/GetBuildingFaultIncidents',
    scopes: [environment.AadScope],
  },
  getIncidentByTicketIdAuthenticated: {
    endpoint: '/api/Incident/GetIncidentByTicketIdAuthenticated',
    scopes: [environment.AadScope],
  },
  getWorkorders: {
    endpoint: '/api/Workorder/GetWorkorders',
    scopes: [environment.AadScope],
  },
  getWorkorder: {
    endpoint: '/api/Workorder/GetWorkorder',
    scopes: [environment.AadScope],
  },
  getQuotes: {
    endpoint: '/api/Quote/GetQuotes',
    scopes: [environment.AadScope],
  },
  getQuote: {
    endpoint: '/api/Quote/GetQuote',
    scopes: [environment.AadScope],
  },
  changeQuoteState: {
    endpoint: '/api/Quote/ChangeQuoteState',
    scopes: [environment.AadScope],
  },
  getLocationFilterOptionSet: {
    endpoint: '/api/OptionSet/GetLocationFilter',
    scopes: [environment.AadScope],
  },
  getOptionSetByString: {
    endpoint: '/api/OptionSet/GetOptionSetByString',
    scopes: [environment.AadScope],
  },
  searchArchobjForAutocomplete: {
    endpoint: '/api/Location/SearchArchobjForAutocomplete',
    scopes: [environment.AadScope],
  },
  searchArchobjForAutocompleteInexact: {
    endpoint: '/api/Location/SearchArchobjForAutoCompleteInexact',
    scopes: [environment.AadScope],
  },
  searchEmployee: {
    endpoint: '/api/Employment/SearchEmployee',
    scopes: [environment.AadScope],
  },
  postUpdateLocationInformation: {
    endpoint: '/api/Location/PostUpdateLocationInformation',
    scopes: [environment.AadScope],
  },
  getArchobjById: {
    endpoint: '/api/Location/GetArchobjById',
    scopes: [environment.AadScope],
  },
  getArchobjOfCurrentUser: {
    endpoint: '/api/Location/GetArchobjOfCurrentUser',
    scopes: [environment.AadScope],
  },
  getLocationInfo: {
    endpoint: '/api/Location/GetLocationInfo',
    scopes: [environment.AadScope],
  },
  getLocationInfoCategories: {
    endpoint: '/api/Location/GetLocationInfoCategories',
    scopes: [environment.AadScope],
  },
  postAddLocationInformation: {
    endpoint: '/api/Location/PostAddLocationInformation',
    scopes: [environment.AadScope],
  },
  deleteLocationInformation: {
    endpoint: '/api/Location/DeleteLocationInformation',
    scopes: [environment.AadScope],
  },
  postSendLocationNotification: {
    endpoint: '/api/Location/PostSendLocationNotification',
    scopes: [environment.AadScope],
  },
  createFavorite: {
    endpoint: '/api/Location/CreateFavorite',
    scopes: [environment.AadScope],
  },
  deleteFavorite: {
    endpoint: '/api/Location/DeleteFavorite',
    scopes: [environment.AadScope],
  },
  getFavorites: {
    endpoint: '/api/Location/GetFavorites',
    scopes: [environment.AadScope],
  },
  getHolidayList: {
    endpoint: '/api/OptionSet/GetHolidayList',
    scopes: [environment.AadScope],
  },
  createIncidentAuthenticated: {
    endpoint: '/api/Incident/CreateIncidentAuthenticated',
    scopes: [environment.AadScope],
  },
  createEmployeeNotification: {
    endpoint: '/api/Incident/CreateEmployeeNotification',
    scopes: [environment.AadScope],
  },
  createPasmIncidentAuthenticated: {
    endpoint: '/api/Incident/CreatePasmIncidentAuthenticated',
    scopes: [environment.AadScope],
  },
  createKeyServiceRequest: {
    endpoint: '/api/Incident/CreateKeyServiceRequest',
    scopes: [environment.AadScope],
  },
  createRelocation: {
    endpoint: '/api/Incident/CreateRelocation',
    scopes: [environment.AadScope],
  },
  getTasks: {
    endpoint: '/api/Task/GetTasks',
    scopes: [environment.AadScope],
  },
  getTask: {
    endpoint: '/api/Task/GetTask',
    scopes: [environment.AadScope],
  },
  updateTask: {
    endpoint: '/api/Task/UpdateTask',
    scopes: [environment.AadScope],
  },
  createExportExcelFromTask: {
    endpoint: 'api/Task/CreateExportExcelFromTask',
    scopes: [environment.AadScope],
  },
  getUserProfile: {
    endpoint: '/api/User/GetUserProfile',
    scopes: [environment.AadScope],
  },
  updateUserProfile: {
    endpoint: '/api/User/UpdateUserProfile',
    scopes: [environment.AadScope],
  },
  getRentalContractForUser: {
    endpoint: '/api/User/GetRentalContractForUser',
    scopes: [environment.AadScope],
  },
  readUser: {
    endpoint: '/api/User/ReadUser',
    scopes: [environment.AadScope],
  },
  updateUser: {
    endpoint: '/api/User/UpdateUser',
    scopes: [environment.AadScope],
  },
  deleteUser: {
    endpoint: '/api/User/DeleteUser',
    scopes: [environment.AadScope],
  },
  readUsers: {
    endpoint: '/api/User/ReadUsers',
    scopes: [environment.AadScope],
  },
  readUserGroups: {
    endpoint: '/api/User/ReadUserGroups',
    scopes: [environment.AadScope],
  },
  readGroups: {
    endpoint: '/api/User/ReadGroups',
    scopes: [environment.AadScope],
  },
  addUserToGroups: {
    endpoint: '/api/User/AddUserToGroups',
    scopes: [environment.AadScope],
  },
  removeUserFromGroups: {
    endpoint: '/api/User/RemoveUserFromGroups',
    scopes: [environment.AadScope],
  },
  getSystemUser: {
    endpoint: '/api/User/GetSystemUser',
    scopes: [environment.AadScope],
  },
  checkIfGsusUser: {
    endpoint: '/api/User/CheckIfGsusUser',
    scopes: [environment.AadScope],
  },
  requestRentalcontractTermination: {
    endpoint: '/api/Incident/RequestRentalcontractTermination',
    scopes: [environment.AadScope],
  },
  requestRoles: {
    endpoint: '/api/User/RequestRoles',
    scopes: [environment.AadScope],
  },
  getUser: {
    endpoint: '/api/User',
    scopes: [environment.AadScope],
  },
  activateUser: {
    endpoint: '/api/User/Activate',
    scopes: [environment.AadScope],
  },
  deactivateUser: {
    endpoint: '/api/User/Deactivate',
    scopes: [environment.AadScope],
  },
  archiveFileToImageMaster: {
    endpoint: '/api/Storage/ArchiveFileToImageMaster',
    scopes: [environment.AadScope],
  },
  archiveFilesToImageMaster: {
    endpoint: '/api/Storage/ArchiveFilesToImageMaster',
    scopes: [environment.AadScope],
  },
  deleteDocumentMetadata: {
    endpoint: '/api/Storage/DeleteDocumentMetadata',
    scopes: [environment.AadScope],
  },
  getDocumentMetadata: {
    endpoint: '/api/Storage/GetDocumentMetadata',
    scopes: [environment.AadScope],
  },
  getDocumentTypes: {
    endpoint: '/api/Storage/GetDocumentTypes',
    scopes: [environment.AadScope],
  },
  uploadFiles: {
    endpoint: '/api/Storage/UploadFiles',
    scopes: [environment.AadScope],
  },
  getIncidentDocuments: {
    endpoint: '/api/File/GetIncidentDocuments',
    scopes: [environment.AadScope],
  },
  getTaskDocuments: {
    endpoint: '/api/File/GetTaskDocuments',
    scopes: [environment.AadScope],
  },
  getContactDocuments: {
    endpoint: '/api/File/GetContactDocuments',
    scopes: [environment.AadScope],
  },
  getRentalContractDocuments: {
    endpoint: '/api/File/GetRentalContractDocuments',
    scopes: [environment.AadScope],
  },
  downloadFile: {
    endpoint: '/api/File/DownloadFile',
    scopes: [environment.AadScope],
  },
  downloadZip: {
    endpoint: '/api/File/DownloadZip',
    scopes: [environment.AadScope],
  },
  SetFileVisibility: {
    endpoint: '/api/File/SetFileVisibility',
    scopes: [environment.AadScope],
  },
  getIncidentWithEmailAuthenticated: {
    endpoint: '/api/Incident/GetIncidentWithEmailAuthenticated',
    scopes: [environment.AadScope],
  },
  setFileDeleted: {
    endpoint: 'api/File/SetFileDeleted',
    scopes: [environment.AadScope],
  },
  isTaskUpdated: {
    endpoint: 'api/Task/IsTaskUpdated',
    scopes: [environment.AadScope],
  },
  getRelatedTaskSubjects: {
    endpoint: 'api/Task/GetRelatedTaskSubjects',
    scopes: [environment.AadScope],
  },
  isInMaintenance: {
    endpoint: 'api/Maintenance',
    scopes: [],
  },
  CheckSecurityRoleByIncidentId: {
    endpoint: '/api/Auth/CheckSecurityRoleByIncidentId',
    scopes: [environment.AadScope],
  },
  checkSecurityRoleByTaskId: {
    endpoint: '/api/Auth/CheckSecurityRoleByTaskId',
    scopes: [environment.AadScope],
  },
  getAccessOrderers: {
    endpoint: '/api/AccessOrderer/GetAccessOrderers',
    scopes: [environment.AadScope],
  },
  searchCostCenterEmployees: {
    endpoint: '/api/AccessOrderer/SearchCostCenterEmployees',
    scopes: [environment.AadScope],
  },
  searchManagerCostCenters: {
    endpoint: '/api/AccessOrderer/SearchManagerCostCenters',
    scopes: [environment.AadScope],
  },
  createAccessOrderer: {
    endpoint: '/api/AccessOrderer/CreateAccessOrderer',
    scopes: [environment.AadScope],
  },
  deleteAccessOrderer: {
    endpoint: '/api/AccessOrderer/DeleteAccessOrderer',
    scopes: [environment.AadScope],
  },
  extendAccessOrderer: {
    endpoint: '/api/AccessOrderer/ExtendAccessOrderer',
    scopes: [environment.AadScope],
  },
  getAccessOrderersHistory: {
    endpoint: '/api/AccessOrderer/GetAccessOrderersHistory',
    scopes: [environment.AadScope],
  },
  getRoles: {
    endpoint: '/api/Role',
    scopes: [environment.AadScope],
  },
  searchContact: {
    endpoint: '/api/Contact',
    scopes: [environment.AadScope],
  },
  createUser: {
    endpoint: '/api/UserAdministration/CreateUser',
    scopes: [environment.AadScope],
  },
  updateUserAdministration: {
    endpoint: '/api/UserAdministration/UpdateUser',
    scopes: [environment.AadScope],
  },
  requestRolesUserAdministration: {
    endpoint: '/api/UserAdministration/RequestRoles',
    scopes: [environment.AadScope],
  },
  getOrganisations: {
    endpoint: '/api/Organisation',
    scopes: [environment.AadScope],
  },
  getMyIncidents: {
    endpoint: '/api/Incident/GetMyIncidents',
    scopes: [environment.AadScope],
  },
  getOrderAccessIncident: {
    endpoint: '/api/Incident/GetOrderAccessIncident?',
    scopes: [environment.AadScope],
  },
  getFurnitureOrderIncident: {
    endpoint: '/api/Incident/GetFurnitureOrderIncident',
    scopes: [environment.AadScope],
  },
  getRelocationIncident: {
    endpoint: '/api/Incident/GetRelocationIncident',
    scopes: [environment.AadScope],
  },
  getBuildingMaintenanceInformation: {
    endpoint: '/api/Location/GetBuildingMaintenanceInformation',
    scopes: [environment.AadScope],
  },
  setBuildingFloorDescription: {
    endpoint: '/api/Location/SetBuildingFloorDescription',
    scopes: [environment.AadScope],
  },
  getBuildingMaintenanceRoomInformation: {
    endpoint: '/api/Location/GetBuildingMaintenanceRoomInformation',
    scopes: [environment.AadScope],
  },
  SetBuildingMaintenanceRoomInformation: {
    endpoint: '/api/Location/SetBuildingMaintenanceRoomInformation',
    scopes: [environment.AadScope],
  },
  GetSpaceNumberBySpaceId: {
    endpoint: '/api/Location/GetSpaceNumberBySpaceId',
    scopes: [environment.AadScope],
  },
  addArchObjUtilHistory: {
    endpoint: '/api/Location/AddArchObjUtilHistory',
    scopes: [environment.AadScope],
  },
  updateArchObjUtilHistory: {
    endpoint: '/api/Location/UpdateArchObjUtilHistory',
    scopes: [environment.AadScope],
  },
  deleteArchObjUtilHistory: {
    endpoint: '/api/Location/DeleteArchObjUtilHistory?',
    scopes: [environment.AadScope],
  },
  getChangeReasons: {
    endpoint: '/api/Location/GetChangeReasons',
    scopes: [environment.AadScope],
  },
  getWorkingSpacesByArea: {
    endpoint: '/api/WorkingSpace/GetWorkingSpacesByArea',
    scopes: [environment.AadScope],
  },
  createWorkingSpaces: {
    endpoint: '/api/WorkingSpace/CreateWorkingSpaces',
    scopes: [environment.AadScope],
  },
  updateWorkingSpaces: {
    endpoint: '/api/WorkingSpace/UpdateWorkingSpaces',
    scopes: [environment.AadScope],
  },
  getWmvClusters: {
    endpoint: '/api/Location/GetWmvClusters',
    scopes: [environment.AadScope],
  },
  getWmVClusterInfos: {
    endpoint: '/api/Location/GetWmvClusterInfos',
    scopes: [environment.AadScope],
  },
  getWmvProducts: {
    endpoint: '/api/Location/GetWmvProducts',
    scopes: [environment.AadScope],
  },
  getCalculationTypes: {
    endpoint: '/api/Location/GetCalculationTypes',
    scopes: [environment.AadScope],
  },
  getCentralInfos: {
    endpoint: '/api/Location/GetCentralInfos',
    scopes: [environment.AadScope],
  },
  getOfficeAreaTypes: {
    endpoint: '/api/Location/GetOfficeAreaTypes',
    scopes: [environment.AadScope],
  },
  getAllocationAreas: {
    endpoint: '/api/Location/GetAllocationAreas',
    scopes: [environment.AadScope],
  },
  updateRoom: {
    endpoint: '/api/Location/UpdateRoom',
    scopes: [environment.AadScope],
  },
  updateBuilding: {
    endpoint: '/api/Location/UpdateBuilding',
    scopes: [environment.AadScope],
  },
  updateFloor: {
    endpoint: '/api/Location/UpdateFloor',
    scopes: [environment.AadScope],
  },
  getApprovals: {
    endpoint: '/api/Approval/GetApprovals',
    scopes: [environment.AadScope],
  },
  answerApproval: {
    endpoint: '/api/Approval/AnswerApproval',
    scopes: [environment.AadScope],
  },
  getAllRentalListingObjectsForEditAsync: {
    endpoint: 'api/RentalListing/edit',
    scopes: [environment.AadScope],
  },
  getRentalListingObjectForEditAsync: {
    endpoint: 'api/RentalListing/*/edit',
    scopes: [environment.AadScope],
  },
  createRentalListingAsync: {
    endpoint: 'api/RentalListing/CreateRentalListing',
    scopes: [environment.AadScope],
  },
  updateRentalListingAsync: {
    endpoint: 'api/RentalListing/UpdateRentalListing/*',
    scopes: [environment.AadScope],
  },
  deleteRentalListingAsync: {
    endpoint: 'api/RentalListing/DeleteRentalListing/*',
    scopes: [environment.AadScope],
  },
  uploadFileAsync: {
    endpoint: 'api/RentalListing/UploadFileAsync',
    scopes: [environment.AadScope],
  },
  checkFrozenZone: {
    endpoint: '/api/Location/CheckFrozenZone',
    scopes: [environment.AadScope],
  },
  createFurnitureOrder: {
    endpoint: '/api/Incident/CreateFurnitureOrder',
    scopes: [environment.AadScope],
  },
  getCostCenters: {
    endpoint: '/api/CostCenter/GetCostCenters',
    scopes: [environment.AadScope],
  },
  getCostCenterUsageTable: {
    endpoint: '/api/CostCenter/GetCostCenterUsageTable',
    scopes: [environment.AadScope],
  },
  getCostCenterUsageDetails: {
    endpoint: '/api/CostCenter/GetCostCenterUsageDetails',
    scopes: [environment.AadScope],
  },
  searchCostCentersForAutoComplete: {
    endpoint: '/api/CostCenter/SearchCostCentersForAutoComplete',
    scopes: [environment.AadScope],
  },
  getArchObjTree: {
    endpoint: '/api/Location/GetArchObjTree',
    scopes: [environment.AadScope],
  },
  getAreaValues: {
    endpoint: '/api/DataMaintenance/areaValues',
    scopes: [environment.AadScope],
  },
  correctAreaValues: {
    endpoint: '/api/DataMaintenance/correctAreaValues',
    scopes: [environment.AadScope],
  },
  getClearingIndicators: {
    endpoint: '/api/DataMaintenance/clearingIndicators',
    scopes: [environment.AadScope],
  },
};

export function GetProtectedResourcesMap(): Map<
  string,
  Array<string | ProtectedResourceScopes>
> {
  const map: Map<string, Array<string | ProtectedResourceScopes>> = new Map([]);
  Object.keys(protectedResources).forEach(element => {
    const ressource: {
      endpoint: string;
      scopes: string[];
      httpMethod?: string;
    } = protectedResources[element];
    map.set(
      ressource.endpoint,
      ressource.httpMethod
        ? [
            {
              httpMethod: ressource.httpMethod,
              scopes: ressource.scopes,
            } as ProtectedResourceScopes,
          ]
        : ressource.scopes
    );
  });
  return map;
}

export function GetDomainHint(): string {
  return location.href.includes(Authorization.tsso)
    ? Authorization.telekomAad
    : '';
}
