import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  DocumentMetadata,
  DocumentMetadataRequest,
  DocumentMetadataWithEmailRequest,
  DownloadFileExternalRequest,
  FileClient,
  FmDocument,
  IncidentViewModel,
  StorageClient,
} from '@core/api';
import { SmartPortalProcessError } from '@core/api/smart-portal.nemo.api';
import { FileService } from '@core/services/file-service/file.service';
import { MandantConfigService } from '@core/services/mandant-config-service/mandant-config.service';
import { TranslateService } from '@ngx-translate/core';
import { EntityStates } from '@shared/constants/entity-states.constants';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { rxSubscriptionContainerMixin } from '@shared/utils/rx-subscription-container.mixin';
import { ToastrService } from 'ngx-toastr';
import { iif } from 'rxjs';
import { take } from 'rxjs/operators';
import { IDetailFields } from './models/detail-fields.model';

@Component({
  selector: 'app-detail-fields-base',
  templateUrl: './detail-fields-base.html',
  styleUrls: ['./detail-fields-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailFieldsBaseComponent
  extends rxSubscriptionContainerMixin()
  implements OnInit, OnChanges, OnDestroy
{
  @Input() allowFileUpload = true;

  @Input() detailFields: Array<IDetailFields> | undefined;

  @Input() data: any;

  @Input() readonly = false;

  @Input() statusData: { eMail: string; ticketNumber } = {
    eMail: '',
    ticketNumber: '',
  };

  @Input() incident: IncidentViewModel;

  @Input() labelMinWidth: number;

  @Input() showFileList: boolean = true;

  @Output() uploadEvent = new EventEmitter<void>();

  temp1: string;

  temp2: number;

  fileIsLoading = '';

  fileDownloadState: boolean[] = [];

  documents: any[] = [];

  documentsBuffered: any[] = [];

  mandant: string;

  documentField: IDetailFields | undefined;

  interval: any;

  readonly UpdateDocumentsTimeout = 1 * 1000;

  readonly TemStorageReloadTimeout = 1 * 1000;

  readonly TemStorageReloadInterval = 5 * 1000;

  getDocumentsRetryTimout: any;

  getMetadata(field) {
    return field.fileMetadata;
  }

  constructor(
    private fileService: FileService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private mandantConfigService: MandantConfigService,
    private storageClient: StorageClient,
    private fileClient: FileClient,
    private msalService: MsalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.documentField = this.getDocumentField();
    const documentBufferedField = this.detailFields?.find(
      f => f.type === 'buffered-files'
    );
    this.documents = this.getFiles(this.documentField?.key);
    this.documentsBuffered = this.getFiles(documentBufferedField?.key);

    if (this.documentsBuffered.length) {
      this.startProgressCheck();
    }

    if (this.detailFields) {
      const hasAllignedFields: boolean =
        this.detailFields?.filter(f => f.type === 'allignedField')?.length > 0;
      if (hasAllignedFields === true) {
        this.temp1 = 'column';
        this.temp2 = 0;
      } else {
        this.temp1 = 'row wrap';
        this.temp2 = 1;
      }
    }
    this.mandant = this.mandantConfigService.mandant.name;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.detailFields) {
      this.detailFields = changes.detailFields.currentValue;
      this.documentField = this.getDocumentField();
      const documentBufferedField = this.detailFields?.find(
        f => f.type === 'buffered-files'
      );
      this.documents = this.getFiles(this.documentField?.key);
      this.documentsBuffered = this.getFiles(documentBufferedField?.key);
    }
  }

  override ngOnDestroy(): void {
    this.stopProgressCheck();
  }

  private getDocumentField() {
    return this.detailFields?.find(
      f => f.type === 'files' || f.type === 'typed-files'
    );
  }

  getLabel(field: IDetailFields) {
    try {
      let { label } = field;

      if (field.transformLabelFn) {
        const value = field?.key?.split('.').reduce((o, i) => o[i], this.data);
        label = field.transformLabelFn(value);
      }

      return !(label === null || label === undefined) ? label : '--';
    } catch (_err) {
      return '--';
    }
  }

  getData(field: IDetailFields) {
    try {
      if (field.key === undefined || field.key === null || !this.data) {
        return '--';
      }
      let val = field.key.split('.').reduce((o, i) => o[i], this.data);

      if (field.transformValueFn) {
        val = field.transformValueFn(val);
      }

      return !(val === null || val === undefined) ? val : '--';
    } catch (_err) {
      return '--';
    }
  }

  getLink(field: IDetailFields) {
    if (this.data && field.hrefParamKey) {
      return `${field.href}${this.data[field.hrefParamKey]}` || '#';
    }
    return '';
  }

  getTableData(key: string | undefined) {
    if (key && this.data && this.data[key]) {
      return this.data[key];
    }
    return [];
  }

  private getFiles(key: string | undefined) {
    if (!key || !this.data) {
      return [];
    }
    const fileArray = key.split('.').reduce((o, i) => o[i], this.data) || [];
    this.fileDownloadState = new Array(fileArray.length).fill(false);
    return fileArray;
  }

  visible(field: IDetailFields) {
    if (field.hiddenIfEmtpy) {
      return this.getData(field) !== '--';
    }
    return true;
  }

  async downloadFile(file: FmDocument, index: number) {
    if (!file.blobFilename) {
      console.warn('cannot load file without a blobFilename');
      this.toastr.error(
        this.translateService.instant('detailFields.fileDownload.warn')
      );
      return;
    }
    this.fileDownloadState[index] = true;

    this.cd.detectChanges();

    const metadata = this.documentField?.fileMetadata;
    const ticketEmailAttribute = metadata?.find(
      (m: any) => m.attributeName === 'ticketEmail'
    );
    const ticketNumberAttribute = metadata?.find(
      (m: any) => m.attributeName === 'ticketNumber'
    );

    if (ticketEmailAttribute && ticketNumberAttribute) {
      const requestDto = <DownloadFileExternalRequest>{
        documentId: file.blobFilename,
        contentType: file.contentType,
        email: ticketEmailAttribute?.attributeValue,
        ticketId: ticketNumberAttribute?.attributeValue,
      };
      this.fileService.downloadFileExternal(requestDto).subscribe({
        next: res => this.downloadSuccess(res, index, file),
        error: err => this.downloadError(err, index),
      });
    } else {
      this.pushSubscription(
        this.fileService
          .downloadFile(file.blobFilename, file.contentType)
          .subscribe({
            next: res => this.downloadSuccess(res, index, file),
            error: err => this.downloadError(err, index),
          })
      );
    }
  }

  private downloadSuccess(res, index: number, file: FmDocument) {
    const fileResponse = res;
    this.fileDownloadState[index] = false;
    this.cd.detectChanges();

    if (fileResponse !== null) {
      this.fileService.saveFile(fileResponse, file.name);
    }
  }

  private downloadError(err, index: number) {
    console.warn(err);
    if (err.errorCode === SmartPortalProcessError.FILESIZE_DOWNLOAD_LIMIT) {
      this.toastr.error(
        this.translateService.instant(
          'detailFields.fileDownload.filesize_download_limit'
        )
      );
    } else {
      this.toastr.error(
        this.translateService.instant('detailFields.fileDownload.warn')
      );
    }
    this.fileDownloadState[index] = false;
    this.cd.detectChanges();
  }

  fileRead(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as string);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  private startProgressCheck() {
    this.stopProgressCheck();
    setTimeout(() => {
      this.checkProgress();
    }, this.TemStorageReloadTimeout);
    this.interval = setInterval(() => {
      this.checkProgress();
    }, this.TemStorageReloadInterval);
  }

  private stopProgressCheck() {
    clearInterval(this.interval);
  }

  private checkProgress() {
    if (!this.documentsBuffered?.length) {
      this.stopProgressCheck();
      return;
    }

    const request = <DocumentMetadataRequest>{};
    request.entityId = this.documentsBuffered[0].entityId;

    if (this.statusData.eMail === '' && this.statusData.ticketNumber === '') {
      this.storageClient
        .getDocumentMetadata(request)
        .pipe(take(1))
        .subscribe({
          next: (tempDocuments: DocumentMetadata[]) => {
            if (this.documentsBuffered.length !== tempDocuments.length) {
              setTimeout(() => {
                this.stopProgressCheck();
                this.updateDocuments(tempDocuments);
              }, this.UpdateDocumentsTimeout);
            } else {
              this.documentsBufferedChanged(tempDocuments);
            }
          },
        });
    } else {
      const requestWithEmail: DocumentMetadataWithEmailRequest = {
        primaryContactEmail: this.statusData.eMail,
        ticketNumber: this.statusData.ticketNumber,
        ...request,
      };

      this.storageClient
        .externalGetDocumentMetadata(requestWithEmail)
        .pipe(take(1))
        .subscribe({
          next: (tempDocuments: DocumentMetadata[]) => {
            if (this.documentsBuffered.length !== tempDocuments.length) {
              setTimeout(() => {
                this.stopProgressCheck();
                this.updateDocuments(tempDocuments);
              }, this.UpdateDocumentsTimeout);
            } else {
              this.documentsBufferedChanged(tempDocuments);
            }
          },
        });
    }
  }

  documentsChanged(documents: any) {
    if (this.documents.length !== documents.length) {
      this.documents = [];
      this.cd.detectChanges();
      this.documents = documents;
      this.cd.detectChanges();
    }
  }

  documentsBufferedChanged(documentsBuffered: any) {
    this.documentsBuffered = [];
    this.cd.detectChanges();
    this.documentsBuffered = documentsBuffered;
    this.startProgressCheck();
    this.cd.detectChanges();
  }

  isFileUploadAllowed(): boolean {
    if (
      this.isInState(EntityStates.Erledigt) ||
      this.isInState(EntityStates.Archiviert) ||
      this.isInState(EntityStates.Bereitgestellte_Informationen) ||
      this.isInState(EntityStates.Abgeschlossen) ||
      this.isInState(EntityStates.Storniert) ||
      this.readonly === true
    ) {
      return false;
    }

    const userRoles =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
    const hasRoleImageUpload = !!userRoles?.find(role =>
      role.startsWith(UserRoles.SMARTPORTAL_DOCUMENT_WRITE_PREFIX)
    );

    return (
      this.allowFileUpload && (hasRoleImageUpload || !this.isAuthenticated())
    );
  }

  isAuthenticated(): boolean {
    return !!this.msalService.instance.getActiveAccount();
  }

  private isInState(stateName: string) {
    if (
      this.data?.status === stateName ||
      this.data?.statusReason === stateName
    ) {
      return true;
    }
    return false;
  }

  private updateDocuments(tempDocuments: DocumentMetadata[]) {
    clearTimeout(this.getDocumentsRetryTimout);

    const metadata = this.documentField?.fileMetadata;

    const taskAttribute = metadata?.find(
      (m: any) => m.attributeName === 'taskId'
    );
    const incidentAttribute = metadata?.find(
      (m: any) => m.attributeName === 'incidentId'
    );
    const contactAttribute = metadata?.find(
      m => m.attributeName === 'contactId'
    );
    const rentalContractAttribute = metadata?.find(
      m => m.attributeName === 'rentalContractId'
    );

    if (taskAttribute?.attributeValue) {
      this.getTaskDocuments(taskAttribute.attributeValue, tempDocuments);
    } else if (incidentAttribute?.attributeValue) {
      this.getIncidentDocuments(
        incidentAttribute.attributeValue,
        tempDocuments
      );
    } else if (contactAttribute?.attributeValue) {
      this.getContactDocuments(contactAttribute.attributeValue, tempDocuments);
    } else if (rentalContractAttribute?.attributeValue) {
      this.getRentalContractDocuments(
        rentalContractAttribute.attributeValue,
        tempDocuments
      );
    }
  }

  private getTaskDocuments(id: string, tempDocuments: DocumentMetadata[]) {
    this.fileClient.getTaskDocuments(id).subscribe({
      next: (documents: FmDocument[]) => {
        this.documentsBufferedChanged(tempDocuments);
        this.documentsChanged(documents);
        this.startProgressCheck();
      },
    });
  }

  private getIncidentDocuments(id: string, tempDocuments: DocumentMetadata[]) {
    if (this.statusData.eMail === '' && this.statusData.ticketNumber === '') {
      this.fileClient.getIncidentDocuments(id).subscribe({
        next: (documents: FmDocument[]) => {
          this.documentsBufferedChanged(tempDocuments);
          this.documentsChanged(documents);
          this.startProgressCheck();
        },
      });
    } else {
      const request = iif(
        () => !!this.msalService.instance.getActiveAccount(),
        this.fileClient.getIncidentDocuments(id),
        this.fileClient.externalGetIncidentDocuments(
          id,
          this.statusData.ticketNumber,
          this.statusData.eMail
        )
      );

      request.subscribe({
        next: (documents: FmDocument[]) => {
          this.documentsBufferedChanged(tempDocuments);
          this.documentsChanged(documents);
          this.startProgressCheck();
        },
      });
    }
  }

  private getContactDocuments(id: string, tempDocuments: DocumentMetadata[]) {
    this.fileClient.getContactDocuments(id).subscribe({
      next: (documents: FmDocument[]) => {
        this.documentsBufferedChanged(tempDocuments);
        this.documentsChanged(documents);
        this.startProgressCheck();
      },
    });
  }

  private getRentalContractDocuments(
    id: string,
    tempDocuments: DocumentMetadata[]
  ) {
    this.fileClient.getRentalContractDocuments(id).subscribe({
      next: (documents: FmDocument[]) => {
        this.documentsBufferedChanged(tempDocuments);
        this.documentsChanged(documents);
        this.startProgressCheck();
      },
    });
  }
}
