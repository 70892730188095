import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  RegexpEmail,
  RegexpLocationname,
  RegexpStreetname,
  RegexpZipCodeDE,
} from '@shared/utils/regex';

export function shippingAddress(
  translateService: TranslateService,
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__shipping_address'
            ),
          },
        },
        {
          key: 'shippingAddress',
          fieldGroup: [
            {
              type: 'flex-layout',
              key: 'contactInformation',
              fieldGroup: [
                {
                  type: 'input',
                  key: 'firstname',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__firstName'
                    ),
                    visibleInConfirm: true,
                    maxLength: 50,
                    required: true,
                  },
                },
                {
                  type: 'input',
                  key: 'lastname',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__lastName'
                    ),
                    maxLength: 50,
                    visibleInConfirm: true,
                    required: true,
                  },
                },
                {
                  type: 'input',
                  key: 'email',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__email'
                    ),
                    pattern: RegexpEmail,
                    visibleInConfirm: true,
                    required: true,
                    maxLength: 100,
                  },
                  validation: {
                    messages: {
                      pattern: () =>
                        translateService.instant('formErrors.email.pattern'),
                    },
                  },
                },
              ],
            },
            {
              key: 'address',
              fieldGroup: [
                {
                  type: 'flex-layout',

                  fieldGroup: [
                    {
                      type: 'input',
                      key: 'street',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__street'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        maxLength: 250,
                        minLength: 3,
                        pattern: RegexpStreetname,
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant(
                            'formErrors.street.pattern'
                          ),
                          minlength: err =>
                            translateService.instant(
                              'formErrors.street.minLength',
                              {
                                length: err.requiredLength,
                              }
                            ),
                        },
                      },
                      expressions: {
                        'props.visibleInConfirm': 'true',
                      },
                    },
                    {
                      type: 'input',
                      key: 'zipcode',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__zipcode'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        pattern: RegexpZipCodeDE,
                        maxLength: 5,
                        minLength: 5,
                      },
                      expressions: {
                        'props.visibleInConfirm': 'true',
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant(
                            'formErrors.zipcode.pattern'
                          ),
                        },
                      },
                    },
                    {
                      type: 'input',
                      key: 'city',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__city'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        minLength: 3,
                        maxLength: 80,
                        pattern: RegexpLocationname,
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant(
                            'formErrors.city.pattern'
                          ),
                          minlength: err =>
                            translateService.instant(
                              'formErrors.city.minLength',
                              {
                                length: err.requiredLength,
                              }
                            ),
                        },
                      },
                      expressions: {
                        'props.visibleInConfirm': 'true',
                      },
                    },
                    {
                      type: 'input',
                      key: 'corporation',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__corporation'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        maxLength: 100,
                      },
                    },
                    {
                      type: 'input',
                      key: 'operation',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__operation'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        maxLength: 100,
                      },
                    },
                  ],
                },
              ],
            },
          ]
        },
      ],
    },
  ];
}
