import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  LocationClient,
  BuildingMaintenanceViewModel,
  CreateArchObjUtilHistoryRequest,
  UpdateArchObjUtilHistoryRequest,
  BaseSelectViewModel,
  UpdateBuildingRequest,
  FrozenZoneViewModel,
} from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class EditBuildService {
  private locationClient = inject(LocationClient);

  getBuildingMaintenanceInformation(
    buildingId: string | undefined
  ): Observable<BuildingMaintenanceViewModel> {
    return this.locationClient.getBuildingMaintenanceInformation(buildingId);
  }

  updateBuilding(request: UpdateBuildingRequest) {
    return this.locationClient.updateBuilding(request);
  }

  addArchObjUtilHistory(
    data: CreateArchObjUtilHistoryRequest
  ): Observable<void> {
    return this.locationClient.addArchObjUtilHistory(data);
  }

  updateArchObjUtilHistory(
    data: UpdateArchObjUtilHistoryRequest
  ): Observable<void> {
    return this.locationClient.updateArchObjUtilHistory(data);
  }

  deleteArchObjUtilHistory(archObjUtilHistoryId: string): Observable<void> {
    return this.locationClient.deleteArchObjUtilHistory(archObjUtilHistoryId);
  }

  getChangeReasons(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getChangeReasons();
  }

  getWmvClusters(): Observable<BaseSelectViewModel[]> {
    return this.locationClient.getWmvClusters();
  }

  checkFrozenZone(): Observable<FrozenZoneViewModel> {
    return this.locationClient.checkFrozenZone();
  }
}
