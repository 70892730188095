<div #rentalItemDetails class="details">
  <div class="details-container">
    <div style="margin-bottom: 2rem">
      <scale-button
        icon-position="start"
        (click)="goBack.emit()"
        size="small"
        variant="secondary-white">
        <scale-icon-navigation-left></scale-icon-navigation-left>
        {{ 'rem.rental.label__back' | translate }}
      </scale-button>
    </div>

    <div class="file-previews" *ngIf="isDesktopView">
      <div
        class="file-preview"
        *ngFor="let item of items; let i = index"
        [lightbox]="i"
        [gallery]="galleryId">
        <img
          [src]="item.data.thumb"
          onerror="this.onerror=null;this.src='../../../assets/icons/scan_delete.svg'" />
      </div>
    </div>

    <div class="file-preview-mobile" *ngIf="!isDesktopView">
      <gallery
        #gallery
        [counter]="false"
        [dots]="true"
        [thumb]="false"
        [imageSize]="'cover'"
        [items]="items"></gallery>
    </div>

    <h2>
      {{ location.title }}
    </h2>
    <p>{{ location.description }}</p>
    <p>
      <a style="color: white" target="_blank" [href]="location.link"
        >{{ location.displayNameLink
        }}<mat-icon style="font-size: 20px">launch</mat-icon></a
      >
    </p>

    <div class="similar-locations">
      <h2 style="font-size: 1.5rem">
        {{
          'rem.rental.label__additional_informations.label__additional_rentalobjects'
            | translate
        }}
        {{ location.city }}:
      </h2>
      <div *ngIf="isLoading" class="loading-spinner">
        <scale-loading-spinner
          size="large"
          variant="white"
          alignment="vertical"
          text="Daten werden geladen..."
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
            height: 100px;
          "></scale-loading-spinner>
      </div>
      <div *ngIf="!isLoading">
        <div class="similarLocationsItems">
          <app-rental-item
            [items]="paginatedLocations"
            [showPaginator]="false"
            (selectedItem)="onItemSelect($event)"></app-rental-item>
        </div>
      </div>
      <mat-paginator
        *ngIf="isDesktopView"
        [length]="similarLocations.length"
        hidePageSize
        showFirstLastButtons
        [pageSize]="pageSize"
        (page)="onPageChange($event)"></mat-paginator>
      <mat-paginator
        class="paginator-mobile"
        *ngIf="!isDesktopView"
        [length]="similarLocations.length"
        hidePageSize
        showFirstLastButtons
        [pageSize]="2"
        (page)="onPageChange($event)"></mat-paginator>
    </div>
  </div>
</div>
