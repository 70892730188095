<div class="contact-page">
  <app-quick-text
    [title]="'Kontakt'"
    [imgUrl]="'assets/images/undraw_personal_text_re_vqj3_grau.svg'"
    [index]="1"
    [backgroundColor]="'#E20074'"
    [fontColor]="'white'"
    [hasLargeHeader]="true">
    <p>
      {{ 'contact.message' | translate }}
      <br />
      {{ 'maintenance.info' | translate }}
    </p>
    <div class="button-container">
      <scale-button [variant]="'primary'" (click)="scrollToForm()">
        <scale-icon-communication-email></scale-icon-communication-email>
        Anfrage stellen
      </scale-button>
      <scale-button
        [variant]="'primary'"
        (click)="navigateTo('/landing/status')">
        <scale-icon-alert-help></scale-icon-alert-help>
        Status abfragen
      </scale-button>
    </div>
  </app-quick-text>

  <div class="contact-form-scroll-div" #createTicketForm></div>

  <div class="contact-form-container">
    <app-ticket-create> </app-ticket-create>
  </div>

  <div style="background-color: #e20074">
    <app-quick-text
      [title]="'Sie haben uns bereits kontaktiert?'"
      [index]="1"
      [backgroundColor]="'#E20074'"
      [fontColor]="'white'"
      class="text-col-contact">
      <p>
        {{ 'contact.message' | translate }}
      </p>
      <div class="button-container">
        <scale-button
          [variant]="'primary'"
          (click)="navigateTo('/landing/status')">
          <scale-icon-alert-help></scale-icon-alert-help>
          Status abfragen
        </scale-button>
      </div>
    </app-quick-text>
  </div>

  <div class="more-information-title">
    <h2>Weitere Informationen</h2>
  </div>

  <app-column-container>
    <div class="info-card" card1>
      <div>
        <img
          src="assets/images/undraw_detailed_information_re_qmuc.svg"
          alt="placeholder" />
      </div>
      <div>
        <h2>Impressum</h2>
        <p>
          Hier finden Sie alle verpflichtenden Angaben zu unserem Webauftritt.
        </p>
      </div>
      <div class="button-wrapper-container">
        <scale-button
          [variant]="'primary'"
          [routerLink]="'/landing/imprint'"
          target="_blank">
          Mehr erfahren
        </scale-button>
      </div>
    </div>
    <div class="info-card" card2>
      <div>
        <img
          src="assets/images/undraw_private_data_re_4eab.svg"
          alt="placeholder" />
      </div>
      <div>
        <h2>Datenschutz</h2>
        <p>
          Erhalten Sie einen Überblick über die Datenverarbeitung im Rahmen
          unserer Website.
        </p>
      </div>
      <div class="button-wrapper-container">
        <scale-button
          [variant]="'primary'"
          [routerLink]="'/datenschutz'"
          target="_blank">
          Mehr erfahren
        </scale-button>
      </div>
    </div>
    <div class="info-card" card3>
      <div>
        <img src="assets/images/undraw_what1.svg" alt="placeholder" />
      </div>
      <div>
        <h2>Mehr über uns</h2>
        <p>Wir verwalten 9000 Immobilien.</p>
      </div>
      <div class="button-wrapper-container">
        <scale-button
          [variant]="'primary'"
          [routerLink]="'/landing/who'"
          target="_blank">
          Über uns
        </scale-button>
      </div>
    </div>
  </app-column-container>
</div>
