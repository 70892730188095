import { Injectable } from '@angular/core';
import * as mapsRestService from 'azure-maps-rest';
import {
  Aborter,
  MapsURL,
  SearchURL,
  SubscriptionKeyCredential,
} from 'azure-maps-rest';
import { environment } from '@core/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RentalSearchService {
  private readonly pipeline: mapsRestService.Pipeline;

  constructor() {
    const subscriptionKeyCredential = new SubscriptionKeyCredential(
      environment.mapAuthOption!.subscriptionKey!
    );

    this.pipeline = MapsURL.newPipeline(subscriptionKeyCredential, {
      retryOptions: { maxTries: 4 },
    });
  }

  /**
   * Retrieves the coordinates (latitude and longitude) for a given query string.
   * @param query The address or location query string.
   * @returns A promise that resolves to a tuple containing the latitude and longitude.
   */
  public async getCoordinatesFromQuerry(
    querry: string
  ): Promise<[number, number]> {
    const searchURL = new SearchURL(this.pipeline);
    const response = await searchURL.searchAddress(
      Aborter.timeout(10000),
      querry,
      { language: 'de' }
    );
    if (
      (response?.summary?.numResults ?? 0) > 0 &&
      response.results &&
      response.results[0].position
    ) {
      const position = response.results[0].position;
      return [position.lat!, position.lon!];
    }
    return [0, 0];
  }

  /**
   * Retrieves the city for a given set of coordinates (latitude and longitude).
   * @param lat The latitude.
   * @param lon The longitude.
   * @returns A promise that resolves to the city of the given coordinates.
   */
  public async getCityFromCoordinates(
    lat: number,
    lon: number
  ): Promise<string> {
    const searchURL = new SearchURL(this.pipeline);
    const response = await searchURL.searchAddressReverse(
      Aborter.timeout(10000),
      [lon, lat],
      { language: 'de' }
    );
    if (
      (response?.summary?.numResults ?? 0) > 0 &&
      response.addresses &&
      response.addresses[0].address &&
      response.addresses[0].address.municipality
    ) {
      return response.addresses[0].address.municipality!;
    }
    return '';
  }
}
