import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-telekom-root',
  templateUrl: './rem.component.html',
  styleUrls: ['./rem.component.scss'],
})
export class RemComponent implements OnInit {
  title = 'real-estate-dummy';

  authenticated = false;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.router.events.subscribe({
      next: event => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      },
    });

    this.changeFavicon();
  }

  updateAuthState(state: boolean) {
    this.authenticated = state;
  }

  changeFavicon() {
    const dataUrl = this.sanitizer.bypassSecurityTrustHtml(
      '/assets/icons/telekom-favicon-rem.svg'
    ) as string;
    const breakSanatizer = JSON.parse(
      JSON.stringify(dataUrl)
    ).changingThisBreaksApplicationSecurity;
    this.document
      .getElementById('appIcon')
      ?.setAttribute('href', breakSanatizer);
  }
}
