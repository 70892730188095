import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseSelectViewModel, UpdateFloorRequest } from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { EditStageService } from '@core/services/edit-stage/edit-stage.service';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { ToastrService } from 'ngx-toastr';
import { catchError, forkJoin, of } from 'rxjs';
import { SafeHtmlPipe } from '../../../../app/shared/pipes/safe-html.pipe';

@Component({
  selector: 'app-edit-stage',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SmartusComponentsModule,
    FormlyModule,
    OverrideModule,
    SafeHtmlPipe,
  ],
  templateUrl: './edit-stage.component.html',
  styleUrl: './edit-stage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStageComponent {
  private route = inject(ActivatedRoute);
  private breadcrumbsService = inject(BreadcrumbService);
  private translateService = inject(TranslateService);
  private editStageService = inject(EditStageService);
  private cd = inject(ChangeDetectorRef);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);

  formModel: any = {
    floorId: '',
    floorDescription: '',
    floorName: '',
    changeReason: '',
  };
  form: FormGroup;
  formFields: FormlyFieldConfig[];
  changeReasons: BaseSelectViewModel[] = [];

  private initialFloorDescription: string;
  isLoading: boolean = false;

  stageAddress = '';

  frozenZoneStartDate = '';
  frozenZoneEndDate = '';
  frozenStatus: boolean = false;
  description = '';
  descriptionStyle =
    'background-color: #E20074; color: white; border-radius: 5px; padding: 15px;';
  descriptionContainerStyle =
    'width: 100%; max-width: 840px; border-radius: 15px; padding: 0 15px;';

  cardFields: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
    footer: {
      button2: {
        label: this.translateService.instant('edit_stage.labels.cancel'),
        onClick: () => {
          window.close();
        },
        disabled: false,
        type: 'button',
      },
      button1: {
        label: this.translateService.instant('edit_stage.labels.save'),
        onClick: () => {
          this.updateDesignationSave();
        },
        disabled: true,
        type: 'submit',
      },
    },
  };

  public ngOnInit(): void {
    this.breadcrumbsService.setBreadcrumbs([
      {
        label: this.translateService.instant('edit_stage.labels.title'),
      },
    ]);

    forkJoin({
      frozenZoneStatus: this.editStageService
        .checkFrozenZone()
        .pipe(catchError(() => of({ startDate: '', endDate: '' }))),
      changeReasons: this.editStageService.getChangeReasons(),
    }).subscribe(results => {
      this.changeReasons = results.changeReasons;

      const frozenZoneData = results.frozenZoneStatus;
      if (
        frozenZoneData.startDate.length > 0 &&
        frozenZoneData.endDate.length > 0
      ) {
        this.frozenZoneStartDate = frozenZoneData.startDate;
        this.frozenZoneEndDate = frozenZoneData.endDate;
        this.frozenStatus = true;
        this.description = this.translateService.instant(
          'edit_room.labels.frozenZone',
          {
            frozenZoneStartDate: this.frozenZoneStartDate,
            frozenZoneEndDate: this.frozenZoneEndDate,
          }
        );
      } else {
        this.formFields = this.getFormFields();
        this.frozenStatus = false;
      }

      if (this.form && this.frozenStatus) {
        this.disableFormFields();
      }
      this.cd.detectChanges();
    });

    this.route.data.subscribe((data: { stage: any }) => {
      if (data && data.stage) {
        this.stageAddress = data.stage.floorName;
        this.formModel = data.stage.buildingFloor;
        this.formModel.changeReason = '';
        this.initializeForm();
        this.initialFloorDescription = this.formModel?.floorDescription || '';
        this.cd.markForCheck();
      }
    });

    this.formFields = this.getFormFields();
  }

  disableFormFields() {
    if (this.frozenStatus && this.form) {
      this.form.disable();
      this.cd.markForCheck();
    }
  }

  initializeForm(): void {
    this.form = this.fb.group({
      floorId: [this.formModel?.floorId || '--'],
      floorDescription: [this.formModel?.floorDescription || '--'],
      floorName: [this.formModel?.floorName || '--'],
      changeReason: [''],
    });

    this.form.get('floorDescription')?.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });

    this.form.get('changeReason')?.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });
  }

  getFormFields(): FormlyFieldConfig[] {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'floorId',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant('edit_stage.labels.floorId'),
            },
          },
          {
            key: 'floorDescription',
            type: 'input',
            props: {
              label: this.translateService.instant(
                'edit_stage.labels.floorDescription'
              ),
              required: true,
            },
            className: 'margin-to-top',
          },
          {
            key: 'changeReason',
            type: 'select',
            props: {
              label: this.translate('buildingMaintenance.table.changeReason'),
              required: true,
              options: this.changeReasons.map(
                (changeReason: BaseSelectViewModel) => ({
                  value: changeReason.id,
                  label: changeReason.name,
                })
              ),
            },
          },
        ],
      },
    ];
  }

  checkFormValidity(): void {
    const floorDescriptionChanged =
      this.form.get('floorDescription')?.value !== this.initialFloorDescription;
    const changeReasonSelected = !!this.form.get('changeReason')?.value;

    this.updateButtonDisabledState(
      !(floorDescriptionChanged && changeReasonSelected)
    );
  }

  updateButtonDisabledState(isDisabled: boolean) {
    this.cardFields.footer.button1.disabled = isDisabled;
  }

  updateDesignationSave(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const updatedModel: UpdateFloorRequest = {
        id: this.formModel.floorId,
        name2: this.form.value.floorDescription,
        changeReason: this.form.value.changeReason,
      };

      this.editStageService.updateFloor(updatedModel).subscribe({
        next: () => {
          this.isLoading = false;
          this.toastr.success(
            this.translateService.instant('edit_stage.toastr.success')
          );
          this.formModel.changeReason = '';
          const changeReasonControl = this.form.get('changeReason');

          if (changeReasonControl) {
            changeReasonControl.setValue('', { emitEvent: false });
            changeReasonControl.markAsPristine();
            changeReasonControl.markAsUntouched();
          }
          this.updateButtonDisabledState(true);
          this.cd.markForCheck();
        },
        error: () => {
          this.isLoading = false;
          this.cd.detectChanges();
          this.toastr.error(
            this.translateService.instant('edit_stage.toastr.error')
          );
        },
      });
    }
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
