import { SmartusComponentsModule } from '@affinis/smartus-components';
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-link',
  standalone: true,
  imports: [SmartusComponentsModule, NgIf, FormlyModule],
  template: `
    <su-icon
      *ngIf="field.props?.icon"
      class="m-x-8"
      [icon]="field.props?.icon"></su-icon>
    &nbsp;
    <a [href]="field.props?.href" target="_blank">{{ field.props?.label }}</a>
  `,
})
export class FormlyLinkComponent extends FieldType {}
