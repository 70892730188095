import { Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FmIncident } from '@core/api/smart-portal.api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-contact',
  templateUrl: './task-contact.component.html',
  styleUrls: ['./task-contact.component.scss'],
})
export class TaskContactComponent {
  @Input() dataContactAddress: FmIncident;
  formModel: any = {};
  form = new FormGroup({});
  formFields: FormlyFieldConfig[];
  translateService = inject(TranslateService);

  ngOnInit() {
    this.formFields = this.getFormFields();
    this.formModel = this.getFormModel();
  }

  translate(key: string): string {
    return this.translateService.instant(`kontakt.task_contact.${key}`);
  }

  getFormFields(): FormlyFieldConfig[] {
    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'fullName',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('reporting_contact'),
              readonly: true,
            }
          },
          {
            key: 'contactUs',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('contact_us'),
              readonly: true,
            }
          },
          {
            key: 'salutation',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('salutation'),
              readonly: true,
            }
          },
          {
            key: 'title',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('title'),
              readonly: true,
            }
          },
          {
            key: 'email',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('email'),
              readonly: true,
            }
          },
          {
            key: 'phone',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('phone'),
              readonly: true,
            }
          },
          {
            key: 'street',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('street'),
              readonly: true,
            }
          },
          {
            key: 'city',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('location'),
              readonly: true,
            }
          },
          {
            key: 'postalCode',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('postal_code'),
              readonly: true,
            }
          },
          {
            key: 'federalState',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translate('federal_state'),
              readonly: true,
            }
          }
        ]
      }
    ];
  }

  getFormModel() {
    return this.formatContact(this.dataContactAddress);
  }

  formatContact(contact: any) {
    const notifyingContact = contact?.notifyingContact;
    return {
      fullName: `${notifyingContact.firstName} ${notifyingContact.lastName}` || '--',
      contactUs: contact.contactPreferences || '--',
      salutation: notifyingContact.salutation || '--',
      title: notifyingContact.jobTitle || '--',
      email: notifyingContact.email || '--',
      phone: notifyingContact.mobilePhone || '--',
      street: notifyingContact.contactFormStreet || '--',
      city: notifyingContact.contactFormCity || '--',
      postalCode: notifyingContact.contactFormPostalcode || '--',
      federalState: notifyingContact.contactFormState || '--',
    };
  }
}
