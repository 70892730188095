import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
// TODO remove
@Component({
  selector: 'app-formly-card-wrapper',
  template: `
    <div data-debug="formly-card-wrapper">
      {{ to.visible }}
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
})
export class FormlyCardWrapperComponent extends FieldWrapper {}
