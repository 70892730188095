import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-form-flex-col',
  template: `
    <div data-debug="flex-layout-col" class="content formly-form-flex">
      <formly-field
        *ngFor="let f of field.fieldGroup"
        [field]="f"></formly-field>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .mat-form-field-infix {
        width: auto;
      }

      .formly-form-flex {
        flex: 1 1 0;
        display: flex;
        gap: 20px;
      }
    `,
  ],
})
export class FlexLayoutColComponent extends FieldType {}
