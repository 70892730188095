import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CallbackComponent } from './callback/callback.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CallbackComponent],
  imports: [CommonModule, SharedModule, TranslateModule],
})
export class AuthenticationModule {}
