<div class="fact-container content-body padding-home">
  <div class="fact-row">
    <app-fact-item
      *ngFor="let factItem of factItemData"
      [factType]="factItem.factType"
      [subtitle]="factItem.subtitle"
      [iconUrl]="factItem.iconUrl"
      [factAmount]="factItem.factAmount"></app-fact-item>
  </div>
  <!-- <app-fact-item [factAmount]="factItemData[0].number" [factType]="'m2'" [subtitle]="'I am a subtitle'"></app-fact-item> -->
</div>
