import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IncidentClient,
  IncidentViewModel,
  ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping,
  TaskClient,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { SessionStorageService } from '@core/services/session-storage-service/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const documentsEntitiesKey = 'documentsEntities';
const ticketNumberKey = 'ticketNumber';
const incidentIdKey = 'incidentId';
const taskIdKey = 'taskId';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DocumentsComponent implements OnInit {
  incident$: Observable<IncidentViewModel | undefined>;

  task$: Observable<
    | ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping
    | undefined
  >;

  loadingError$ = new Subject<boolean>();

  showIncident: boolean;

  showTask: boolean;

  showBackButton: boolean;

  incidentIdFromState: string;

  viewTitle = this.translateService.instant('documents.title');

  constructor(
    private location: Location,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private incidentClient: IncidentClient,
    private taskClient: TaskClient,
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService
  ) {
    this.breadcrumbService.setBreadcrumbs([
      {
        label: translateService.instant('taskmodule.tickets.list.title'),
        onClick: () => {
          this.router.navigateByUrl('/tickets');
        },
      },
      {
        label: this.viewTitle,
      },
    ]);

    this.incidentIdFromState =
      this.router.getCurrentNavigation()?.extras?.state?.incidentId;
    if (this.incidentIdFromState) {
      this.showBackButton = true;
    }
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const ticketNumber =
      this.activatedRoute.snapshot.paramMap.get(ticketNumberKey);
    if (ticketNumber) {
      this.initViaTicketNumber(ticketNumber);
    }

    const incidentId =
      this.activatedRoute.snapshot.paramMap.get(incidentIdKey) ||
      this.incidentIdFromState;
    if (incidentId) {
      this.initViaIncidentId(incidentId);
    }

    const taskId = this.activatedRoute.snapshot.paramMap.get(taskIdKey);
    if (taskId) {
      this.initViaTaskId(taskId);
    }

    if (!ticketNumber && !incidentId && !taskId) {
      const documentsEntities =
        this.sessionStorageService.get(documentsEntitiesKey);
      const ticketNumberFallback = documentsEntities?.ticketNumber;
      if (ticketNumberFallback) {
        this.initViaTicketNumber(ticketNumberFallback);
      }

      const incidentIdFallback = documentsEntities?.incidentId;
      if (incidentIdFallback) {
        this.initViaIncidentId(incidentIdFallback);
      }

      const taskIdFallback = documentsEntities?.taskId;
      if (taskIdFallback) {
        this.initViaTaskId(taskIdFallback);
      }
    }
  }

  initViaTicketNumber(ticketNumber: string) {
    this.showIncident = true;
    this.incident$ = this.getByTicketNumber(ticketNumber);
    this.clearLocalStorageIds();
    this.sessionStorageService.set(documentsEntitiesKey, { ticketNumber });
  }

  initViaIncidentId(incidentId: string) {
    this.showIncident = true;
    this.incident$ = this.getByIncidentId(incidentId);
    this.clearLocalStorageIds();
    this.sessionStorageService.set(documentsEntitiesKey, { incidentId });
  }

  initViaTaskId(taskId: string) {
    this.showTask = true;
    this.task$ = this.getByTaskId(taskId);
    this.clearLocalStorageIds();
    this.sessionStorageService.set(documentsEntitiesKey, { taskId });
  }

  clearLocalStorageIds() {
    this.sessionStorageService.remove(documentsEntitiesKey);
  }

  getByTicketNumber(ticketNumber: string) {
    return this.incidentClient
      .getIncidentByTicketIdAuthenticated(ticketNumber)
      .pipe(
        map((result: IncidentViewModel) => {
          return result;
        }),
        catchError(() => {
          this.loadingError$.next(true);
          return of(undefined);
        })
      );
  }

  getByIncidentId(id: string) {
    return this.incidentClient.getIncidentAuthenticated(id).pipe(
      map((result: IncidentViewModel) => {
        return result;
      }),
      catchError(() => {
        this.loadingError$.next(true);
        return of(undefined);
      })
    );
  }

  getByTaskId(id: string) {
    return this.taskClient.getTask(id, false).pipe(
      map(
        (
          result: ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping
        ) => {
          return result;
        }
      ),
      catchError(() => {
        this.loadingError$.next(true);
        return of(undefined);
      })
    );
  }
}
