import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import {
  DocumentMetadata,
  DocumentTypeGroupViewModel,
  DocumentTypeViewModel,
  DownloadFile,
  DownloadFileExternalRequest,
  DownloadFileResult,
  FileClient,
  FileResponse,
  StorageClient,
  UploadFile,
  UploadFileResult,
  UploadFilesTempStorageRequest,
  UploadFilesWithEmailRequest,
} from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { DocumentVisibility } from '@shared/types/document.types';
import { rxSubscriptionContainerMixin } from '@shared/utils/rx-subscription-container.mixin';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { MandantConfigService } from '../mandant-config-service/mandant-config.service';

@Injectable({
  providedIn: 'root',
})
export class FileService
  extends rxSubscriptionContainerMixin()
  implements OnDestroy
{
  documentTypes$: BehaviorSubject<DocumentTypeViewModel[]>;

  filteredDocumentVisibilities: Observable<string[]>;

  documentVisibilities = [
    {
      value: DocumentVisibility.Public,
      label: this.translateService.instant('documents.documentVisibilities.2'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.2'
      ),
    },
  ];

  documentVisibilitiesSelectable = [
    {
      value: DocumentVisibility.Public,
      label: this.translateService.instant('documents.documentVisibilities.2'),
      tooltip: this.translateService.instant(
        'documents.documentVisibilities.tooltip.2'
      ),
    },
  ];

  defaultDocumentVisibilityValue = this.documentVisibilities[0].value;

  defaultDocumentVisibilityLabel = this.documentVisibilities[0].label;

  defaultDocumentVisibilityControl = new FormControl(
    this.defaultDocumentVisibilityLabel
  );

  constructor(
    private fileClient: FileClient,
    private storageClient: StorageClient,
    private httpCient: HttpClient,
    private translateService: TranslateService,
    private mandant: MandantConfigService,
    private msalService: MsalService
  ) {
    super();
  }

  public downloadFile(id: string, contentType: string | undefined) {
    const fileReq = { id } as DownloadFile;
    return this.fileClient.downloadFile(contentType, fileReq);
  }

  public downloadFileExternal(request: DownloadFileExternalRequest) {
    return this.fileClient.externalFileDownload(request);
  }

  public downloadZip(ids: string[]) {
    return this.fileClient.downloadZip(ids);
  }

  public getFileLink(id: string) {
    const fileReq = { id } as DownloadFile;

    return this.fileClient.getFileLink(fileReq);
  }

  public saveFile(fileResponse: FileResponse, fileName: string) {
    saveAs(fileResponse.data, fileName);
  }

  public saveFileLink(
    fileResult: DownloadFileResult,
    fileName: string | undefined
  ) {
    if (fileResult.link !== undefined) {
      this.httpCient.get(fileResult.link, { responseType: 'blob' }).subscribe({
        next: response => {
          saveAs(response, fileName);
        },
      });
    }
  }

  public uploadFile(files: UploadFile[]): Observable<UploadFileResult[]> {
    return this.fileClient.uploadFile(files);
  }

  public uploadFilesWithEmail(request: UploadFilesWithEmailRequest) {
    return this.storageClient.externalUploadFilesWithEmail(request);
  }

  public uploadFilesTempStorage(
    uploadRequest: UploadFilesTempStorageRequest
  ): Observable<DocumentMetadata[]> {
    return this.storageClient.uploadFiles(uploadRequest);
  }

  public fileRead(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as string);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  public validateFile(file?: File, maxMB = 10): [boolean, string, string] {
    let isValid = true;
    let message = '';
    const title = this.translateService.instant('fileUpload.error.title_error');
    if (file) {
      const filenameWithoutExtension = file.name.slice(
        0,
        file.name.lastIndexOf('.')
      );
      if (filenameWithoutExtension === '') {
        message = this.translateService.instant(
          'fileUpload.error.message__filename_empty'
        );
        isValid = false;
      }

      if (file.name.length > 150) {
        message = this.translateService.instant(
          'fileUpload.error.message__filename_length_too_large'
        );
        isValid = false;
      }

      if (file.size <= 0) {
        message = this.translateService.instant(
          'fileUpload.error.message__file_size_too_small'
        );
        isValid = false;
      }

      if (file.size > 1024 * 1024 * maxMB) {
        message = this.translateService.instant(
          'fileUpload.error.message__file_size_larger10',
          {
            maxMB,
          }
        );
        isValid = false;
      }
    }
    return [isValid, message, title];
  }

  public validateTotalFileSize(
    files: any[],
    fileSizeAccessor: (accumulator, current: any) => number,
    maxMB: number
  ) {
    let isValid = true;
    let message = '';
    const title = this.translateService.instant('fileUpload.error.title_error');
    if (this.mandant.mandant.name === 'telekom') {
      const totalSize = files.reduce(fileSizeAccessor, 0);
      if (totalSize > 1024 * 1024 * maxMB) {
        message = this.translateService.instant(
          'fileUpload.error.message__file_size_larger20',
          {
            maxMB,
          }
        );
        isValid = false;
      }
      if (totalSize <= 0) {
        message = this.translateService.instant(
          'fileUpload.error.message__file_size_too_small'
        );
        isValid = false;
      }
    }
    return [isValid, message, title];
  }

  getDocumentTypes(
    incidentId: string = null
  ): BehaviorSubject<DocumentTypeGroupViewModel[]> {
    if (!this.isAuthenticated()) {
      const unauthenticatedDocumentTypes =
        new Array<DocumentTypeGroupViewModel>({
          documentTypes: [
            { name: 'Flurstückskarte', externalCode: 'AUG' },
            { name: 'Grundbuchauszug', externalCode: 'AUG' },
            { name: 'Pläne', externalCode: 'PL7' },
            { name: 'Schriftverkehr', externalCode: 'SVK' },
            { name: 'Sonstiges', externalCode: 'SO7' },
          ],
        });
      return new BehaviorSubject(unauthenticatedDocumentTypes);
    }
    if (!this.documentTypes$) {
      this.documentTypes$ = new BehaviorSubject(
        new Array<DocumentTypeViewModel>()
      );
    }
    this.initDocumentTypes(incidentId);
    return this.documentTypes$;
  }

  private initDocumentTypes(incidentId: string): void {
    this.pushSubscription(
      this.storageClient.getDocumentTypes(incidentId).subscribe({
        next: (documentTypes: any) => {
          this.documentTypes$.next(documentTypes);
        },
      })
    );
  }

  isAuthenticated(): boolean {
    return !!this.msalService.instance.getActiveAccount();
  }

  override ngOnDestroy(): void {
    this.clearSubscriptions();
    this.documentTypes$.complete();
  }

  public getContentTypeByFileExtension(filenameOriginal: string): string {
    const filename = filenameOriginal.toLocaleLowerCase();

    if (filename.endsWith('.jpg') || filename.endsWith('.jpeg')) {
      return 'image/jpg';
    }

    if (filename.endsWith('.png')) {
      return 'image/png';
    }

    if (filename.endsWith('.pdf')) {
      return 'application/pdf';
    }

    if (filename.endsWith('.txt')) {
      return 'text/plain';
    }

    if (filename.endsWith('.doc') || filename.endsWith('.docx')) {
      return 'application/msword';
    }

    if (filename.endsWith('.xls') || filename.endsWith('.xlsx')) {
      return 'application/vnd.ms-excel';
    }

    if (filename.endsWith('.ppt') || filename.endsWith('.pptx')) {
      return 'application/vnd.ms-powerpoint';
    }

    if (filename.endsWith('.vsd')) {
      return 'application/vnd.visio';
    }

    if (filename.endsWith('.tif') || filename.endsWith('.tiff')) {
      return 'application/tiff';
    }

    if (filename.endsWith('.msg')) {
      return 'application/vnd.ms-outlook';
    }

    if (filename.endsWith('.zip')) {
      return 'application/zip';
    }

    return '';
  }

  public initDefaultVisibilityOptions(defaultDocumentVisibilityValue) {
    let documentVisibilities;
    if (defaultDocumentVisibilityValue === DocumentVisibility.Intern) {
      documentVisibilities = [
        {
          value: DocumentVisibility.Intern,
          label: this.translateService.instant(
            'documents.documentVisibilities.0'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.0'
          ),
        },
        {
          value: DocumentVisibility.InternAndContractors,
          label: this.translateService.instant(
            'documents.documentVisibilities.1'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.1'
          ),
        },
        {
          value: DocumentVisibility.Public,
          label: this.translateService.instant(
            'documents.documentVisibilities.2'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.2'
          ),
        },
      ];
      this.documentVisibilitiesSelectable = [
        {
          value: DocumentVisibility.Intern,
          label: this.translateService.instant(
            'documents.documentVisibilities.0'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.0'
          ),
        },
        {
          value: DocumentVisibility.InternAndContractors,
          label: this.translateService.instant(
            'documents.documentVisibilities.1'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.1'
          ),
        },
        {
          value: DocumentVisibility.Public,
          label: this.translateService.instant(
            'documents.documentVisibilities.2'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.2'
          ),
        },
      ];
    } else if (
      defaultDocumentVisibilityValue === DocumentVisibility.InternAndContractors
    ) {
      documentVisibilities = [
        {
          value: DocumentVisibility.InternAndContractors,
          label: this.translateService.instant(
            'documents.documentVisibilities.1'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.1'
          ),
        },
        {
          value: DocumentVisibility.Public,
          label: this.translateService.instant(
            'documents.documentVisibilities.2'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.2'
          ),
          hidden: true,
        },
      ];
      this.documentVisibilitiesSelectable = [
        {
          value: DocumentVisibility.InternAndContractors,
          label: this.translateService.instant(
            'documents.documentVisibilities.1'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.1'
          ),
        },
      ];
    } else {
      documentVisibilities = [
        {
          value: DocumentVisibility.Public,
          label: this.translateService.instant(
            'documents.documentVisibilities.2'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.2'
          ),
        },
      ];
      this.documentVisibilitiesSelectable = [
        {
          value: DocumentVisibility.Public,
          label: this.translateService.instant(
            'documents.documentVisibilities.2'
          ),
          tooltip: this.translateService.instant(
            'documents.documentVisibilities.tooltip.2'
          ),
        },
      ];
    }
    return documentVisibilities;
  }

  public initDefaultVisibility(
    hasDocumentVisibilitySelection,
    defaultDocumentVisibilityControl
  ) {
    const userRoles =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
    const hasVisibilityGsus = !!userRoles?.includes(
      UserRoles.SMARTPORTAL_DOCUMENT_VISIBILITY_GSUS
    );
    const hasVisibilityServiceProvider = !!userRoles?.includes(
      UserRoles.SMARTPORTAL_DOCUMENT_VISIBILITY_SERVICEPROVIDER
    );

    let defaultDocumentVisibilityValue = '';
    let defaultDocumentVisibilityLabel = '';

    if (hasVisibilityGsus) {
      defaultDocumentVisibilityValue = DocumentVisibility.Intern;
      defaultDocumentVisibilityLabel = this.translateService.instant(
        'documents.documentVisibilities.0'
      );
      hasDocumentVisibilitySelection = true;
    } else if (hasVisibilityServiceProvider) {
      defaultDocumentVisibilityValue = DocumentVisibility.InternAndContractors;
      defaultDocumentVisibilityLabel = this.translateService.instant(
        'documents.documentVisibilities.1'
      );
      hasDocumentVisibilitySelection = true;
    } else {
      defaultDocumentVisibilityValue = DocumentVisibility.Public;
      defaultDocumentVisibilityLabel = this.translateService.instant(
        'documents.documentVisibilities.2'
      );
    }

    const documentVisibilities = this.initDefaultVisibilityOptions(
      defaultDocumentVisibilityValue
    );
    defaultDocumentVisibilityControl.setValue(defaultDocumentVisibilityLabel);

    this.initFilteredDocumentVisibilities(
      hasDocumentVisibilitySelection,
      documentVisibilities,
      defaultDocumentVisibilityControl
    );

    return [
      hasDocumentVisibilitySelection,
      defaultDocumentVisibilityValue,
      defaultDocumentVisibilityLabel,
      documentVisibilities,
      defaultDocumentVisibilityControl,
    ];
  }

  public initFilteredDocumentVisibilities(
    hasDocumentVisibilitySelection,
    documentVisibilities,
    defaultDocumentVisibilityControl
  ) {
    if (!hasDocumentVisibilitySelection) {
      return null;
    }
    this.filteredDocumentVisibilities =
      defaultDocumentVisibilityControl.valueChanges.pipe(
        startWith(''),
        map(value =>
          this.filterDocumentVisibilities(
            value.toString() || '',
            documentVisibilities
          )
        )
      );
    return this.filteredDocumentVisibilities;
  }

  public filterDocumentVisibilities(
    documentVisibility: string,
    documentVisibilities
  ): any[] {
    const filterValue = documentVisibility.toLowerCase();
    return documentVisibilities?.filter(option =>
      option.label?.toLowerCase().includes(filterValue)
    );
  }

  public setFileAttachmentType(formValue, type: string) {
    const at = formValue.attachments;

    at.forEach(element => {
      element.attachmentType = type;
    });
  }
}
