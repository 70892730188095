import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-formly-input-search',
  template: `
    <form data-debug="formly-input-search">
      <mat-form-field class="wrapper-full">
        <input
          matInput
          id="emailResult"
          type="text"
          aria-label="Options"
          [formControl]="myControl"
          [matAutocomplete]="auto"
          [placeholder]="this.templateOptions.placeholder" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  `,
  styles: [
    `
      .wrapper-full {
        width: 100%;
      }
    `,
  ],
})
export class FormlyInputSearchComponent extends FieldType implements OnInit {
  override field: FormlyFieldConfig;

  templateOptions: any;

  myControl = new FormControl('');

  optionsList: string[] = [];

  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.templateOptions = this.field.props;

    this.optionsList = this.templateOptions.options;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      // eslint-disable-next-line no-underscore-dangle
      map(value => this._filter(value || ''))
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsList.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
