import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseSelectViewModel, UpdateRoomRequest } from '@core/api';
import { Billing } from '@core/services/edit-room/billing';
import { EditRoomService } from '@core/services/edit-room/edit-room.service';
import { UserService } from '@core/services/oauth-service/user.service';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, forkJoin, of } from 'rxjs';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb-service/BreadcrumbService';
import { SafeHtmlPipe } from '../../../../app/shared/pipes/safe-html.pipe';
import { MaintenanceRoomAddNewModalComponent } from './maintenance-room/maintenance-room-add-new-modal/maintenance-room-add-new-modal/maintenance-room-add-new-modal.component';
import { MaintenanceRoomComponent } from './maintenance-room/maintenance-room.component';

@Component({
  selector: 'app-room-data',
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    SmartusComponentsModule,
    TranslateModule,
    MaintenanceRoomComponent,
    OverrideModule,
    SafeHtmlPipe,
  ],
  templateUrl: './edit-room.component.html',
  styleUrl: './edit-room.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRoomComponent implements OnInit, AfterViewInit {
  private translateService = inject(TranslateService);
  private breadcrumbsService = inject(BreadcrumbService);
  private route = inject(ActivatedRoute);
  private cd = inject(ChangeDetectorRef);
  private fb = inject(FormBuilder);
  public dialogRef: MatDialogRef<EditRoomComponent>;
  private dialog = inject(MatDialog);
  private userService = inject(UserService);
  private editRoomService = inject(EditRoomService);
  private toastr = inject(ToastrService);

  formModel: {
    primaryRoomNumber: string;
    aoid: string;
    magOffice: string;
    wmProd: string;
    designType: string;
    warmRentCluster: string;
    usersAreaArchitectural: string;
    rentalArea_REM: string;
    userArea: string;
    workingPlacesOrPlanning: string;
    workingPlacesOrPlanningAvailable: string;
    workingPlacesOrPlanningOccupied: string;
    workingPlacesOrPlanningPlanned: string;
    roomDescription: string;
    warmClusterIndividual: string;
    wmClusterInfo: string;
    warmRentCalculation: boolean;
    hasWmvContract: boolean;
    isRoutineCleaning: boolean;
    magOfficeDetailAreaType: string;
    allocationArea: string;
    wmProdIndi: string;
    calculationType: string;
    tiFlag: boolean;
    centralInformation: string;
    typeOfUseOrDetailedArea: string;
    changeReason: string;
  } = {
    primaryRoomNumber: '',
    aoid: '',
    magOffice: '',
    wmProd: '',
    designType: '',
    warmRentCluster: '',
    roomDescription: '',
    usersAreaArchitectural: '',
    rentalArea_REM: '',
    userArea: '',
    workingPlacesOrPlanning: '',
    workingPlacesOrPlanningAvailable: '',
    workingPlacesOrPlanningOccupied: '',
    workingPlacesOrPlanningPlanned: '',
    warmClusterIndividual: '',
    wmClusterInfo: '',
    warmRentCalculation: false,
    hasWmvContract: false,
    isRoutineCleaning: false,
    tiFlag: false,
    magOfficeDetailAreaType: '',
    allocationArea: '',
    wmProdIndi: '',
    calculationType: '',
    centralInformation: '',
    typeOfUseOrDetailedArea: '',
    changeReason: '',
  };
  form: FormGroup;
  formFields: FormlyFieldConfig[];

  cardFields: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
    footer: {
      button2: {
        label: this.translateService.instant('edit_room.labels.cancel'),
        onClick: () => {
          window.close();
        },
        disabled: false,
        type: 'button',
      },
      button1: {
        label: this.translateService.instant('edit_room.labels.save'),
        onClick: () => {
          this.onSubmit();
        },
        disabled: true,
        type: 'submit',
      },
    },
  };

  tabs: Tab[] = [
    {
      label: this.translateService.instant('edit_room.labels.roomDateTab'),
      id: 'roomData',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: this.translateService.instant('edit_room.labels.userAreaTab'),
      id: 'typeOfUseOrDetailedArea',
      onClick: (id: string) => this.onTabClick(id),
    },
  ];

  activeTab = this.tabs[0].id;
  isLoading: boolean = false;
  roomTitle: string;
  typeOfUseDetailedId: any[];
  typeOfUseDetailAreaData$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  spaceId: string;
  formattedSpaceId: string;
  changeReasons: BaseSelectViewModel[] = [];
  wmvClusters: BaseSelectViewModel[] = [];
  wmvClusterInfos: BaseSelectViewModel[] = [];
  wmvProducts: BaseSelectViewModel[] = [];
  calculationTypes: BaseSelectViewModel[] = [];
  allocationAreas: BaseSelectViewModel[] = [];
  centralInfos: BaseSelectViewModel[] = [];
  officeAreaTypes: BaseSelectViewModel[] = [];
  initialFormState: any;
  initialValues: Partial<UpdateRoomRequest> = {};
  initialValueChangeReason: string;
  initialAllocationArea: string;
  frozenZoneStartDate = '';
  frozenZoneEndDate = '';
  frozenStatus: boolean = false;
  description = '';

  descriptionStyle =
    'background-color: #E20074; color: white; border-radius: 5px; padding: 15px;';
  descriptionContainerStyle =
    'width: 100%; max-width: 840px; border-radius: 15px; padding: 0 15px;';

  ngOnInit(): void {
    this.activeTab = this.tabs[0].id;

    forkJoin({
      frozenZoneStatus: this.editRoomService
        .checkFrozenZone()
        .pipe(catchError(() => of({ startDate: '', endDate: '' }))),
      changeReasons: this.editRoomService
        .getChangeReasons()
        .pipe(catchError(() => of([]))),
      wmvClusters: this.editRoomService
        .getWmvClusters()
        .pipe(catchError(() => of([]))),
      wmvClusterInfos: this.editRoomService
        .getWmVClusterInfos()
        .pipe(catchError(() => of([]))),
      wmvProducts: this.editRoomService
        .getWmvProducts()
        .pipe(catchError(() => of([]))),
      calculationTypes: this.editRoomService
        .getCalculationTypes()
        .pipe(catchError(() => of([]))),
      allocationAreas: this.editRoomService
        .getAllocationAreas()
        .pipe(catchError(() => of([]))),
      centralInfos: this.editRoomService
        .getCentralInfos()
        .pipe(catchError(() => of([]))),
      officeAreaTypes: this.editRoomService
        .getOfficeAreaTypes()
        .pipe(catchError(() => of([]))),
    }).subscribe(results => {
      this.changeReasons = results.changeReasons;
      this.wmvClusters = results.wmvClusters;
      this.wmvClusterInfos = results.wmvClusterInfos;
      this.wmvProducts = results.wmvProducts;
      this.calculationTypes = results.calculationTypes;
      this.allocationAreas = results.allocationAreas;
      this.centralInfos = results.centralInfos;
      this.officeAreaTypes = results.officeAreaTypes;

      const frozenZoneData = results.frozenZoneStatus;
      if (
        frozenZoneData.startDate.length > 0 &&
        frozenZoneData.endDate.length > 0
      ) {
        this.frozenZoneStartDate = frozenZoneData.startDate;
        this.frozenZoneEndDate = frozenZoneData.endDate;
        this.frozenStatus = true;
        this.description = this.translateService.instant(
          'edit_room.labels.frozenZone',
          {
            frozenZoneStartDate: this.frozenZoneStartDate,
            frozenZoneEndDate: this.frozenZoneEndDate,
          }
        );
      } else {
        this.formFields = this.getFormFields();
        this.frozenStatus = false;
      }

      if (this.form && this.frozenStatus) {
        this.disableFormFields();
      }
      this.cd.detectChanges();
    });

    //Get the building data from the resolver
    //TODO: change roomData type to BuildingRoomViewModel
    this.route.data.subscribe((data: { roomData: any }) => {
      if (data && data.roomData) {
        this.typeOfUseDetailAreaData$.next(data.roomData);
        this.spaceId = data.roomData.spaceId;
        this.formattedSpaceId =
          this.translateService.instant('edit_room.labels.room') +
          ': ' +
          this.spaceId.split('/').pop() +
          ' / ' +
          data.roomData.roomDescription;
        this.initialValueChangeReason = data.roomData.changeReason;
        this.initialAllocationArea =
          data.roomData.allocationArea === Billing.allocationArea
            ? data.roomData.allocationArea
            : '';

        this.formModel = {
          primaryRoomNumber: data.roomData.primaryRoomNumber || '--',
          aoid: data.roomData.aoid || '--',
          magOffice: data.roomData?.magOfficeString || '--',
          wmProd: data.roomData?.wmProd || '--',
          designType: data.roomData?.measurement || '--',
          warmRentCluster: data.roomData?.WmClusterInfo || '--',
          typeOfUseOrDetailedArea:
            data.roomData.usageTypeDetailAreaType || '--',
          usersAreaArchitectural:
            data.roomData?.architecturalArea !== undefined
              ? data.roomData?.architecturalArea
              : '--',
          rentalArea_REM:
            data.roomData?.rentalArea_REM !== undefined
              ? data.roomData?.rentalArea_REM
              : '--',
          userArea:
            data.roomData?.userArea !== undefined
              ? data.roomData?.userArea
              : '--',
          workingPlacesOrPlanning:
            data.roomData?.workingPlacesOrPlanning || '--',
          workingPlacesOrPlanningAvailable:
            data.roomData?.cntWorkspacesAvailable !== undefined
              ? data.roomData?.cntWorkspacesAvailable
              : '--',
          workingPlacesOrPlanningOccupied:
            data.roomData?.cntWorkspacesOccupied !== undefined
              ? data.roomData?.cntWorkspacesOccupied
              : '--',
          workingPlacesOrPlanningPlanned:
            data.roomData?.cntWorkspacesPlanned !== undefined
              ? data.roomData?.cntWorkspacesPlanned
              : '--',
          warmRentCalculation: data.roomData.isWmv ?? false,
          hasWmvContract: data.roomData.isWarmRentalContractValid ?? false,
          isRoutineCleaning: data.roomData.isRoutineCleaning ?? false,
          tiFlag: data.roomData.tiFlag ?? false,
          roomDescription: data.roomData?.roomDescription,
          wmProdIndi: data.roomData?.wmProdIndi,
          warmClusterIndividual: data.roomData?.wmClusterIndi,
          wmClusterInfo: data.roomData?.wmClusterInfo,
          magOfficeDetailAreaType: data.roomData?.magOfficeDetailAreaType,
          calculationType: data.roomData?.calculationType,
          allocationArea: data.roomData?.allocationArea,
          centralInformation: data.roomData?.centralInfo,
          changeReason: '',
        };

        this.typeOfUseDetailedId = data.roomData.usageCategories;
        this.initializeForm();
        this.cd.markForCheck();
      }
    });

    this.breadcrumbsService.setBreadcrumbs([{ label: this.spaceId }]);

    this.formFields = this.getFormFields();

    setTimeout(() => {
      this.initialFormState = { ...this.form.value };

      this.form.valueChanges.subscribe(() => {
        this.getButtonVisibility();
      });
    }, 0);

    this.route.paramMap.subscribe(params => {
      const stageId = params.get('stageId')!;
      const floorId = params.get('floorId')!;
      const roomId = params.get('roomId')!;

      this.spaceId = `${stageId}/${floorId}/${roomId}`;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cd.detectChanges();
    }, 0);
  }

  initializeForm(): void {
    this.form = this.fb.group({
      primaryRoomNumber: [this.formModel.primaryRoomNumber || '--'],
      aoid: [this.formModel.aoid || '--'],
      magOffice: [this.formModel.magOffice || '--'],
      wmProd: [this.formModel.wmProd || '--'],
      designType: [this.formModel.designType || '--'],
      warmRentCluster: [this.formModel.warmRentCluster || '--'],
      typeOfUseOrDetailedArea: [this.formModel.typeOfUseOrDetailedArea || '--'],
      rentalArea_REM: [this.formModel.rentalArea_REM || '--'],
      usersAreaArchitectural: [this.formModel.usersAreaArchitectural || '--'],
      userArea: [this.formModel.userArea || '--'],
      workingPlacesOrPlanning: [this.formModel.workingPlacesOrPlanning || '--'],
      workingPlacesOrPlanningAvailable: [
        this.formModel.workingPlacesOrPlanningAvailable || '--',
      ],
      workingPlacesOrPlanningOccupied: [
        this.formModel.workingPlacesOrPlanningOccupied || '--',
      ],
      workingPlacesOrPlanningPlanned: [
        this.formModel.workingPlacesOrPlanningPlanned || '--',
      ],
      warmRentCalculation: [this.formModel.warmRentCalculation ?? false],
      isRoutineCleaning: [this.formModel.isRoutineCleaning ?? false],
      tiFlag: [this.formModel.tiFlag ?? false],
      roomDescription: [this.formModel.roomDescription || '--'],
      wmProdIndi: [this.formModel.wmProdIndi || ''],
      warmClusterIndividual: [this.formModel.warmClusterIndividual || ''],
      wmClusterInfo: [this.formModel.wmClusterInfo || ''],
      magOfficeDetailAreaType: [this.formModel.magOfficeDetailAreaType || ''],
      calculationType: [this.formModel.calculationType || ''],
      allocationArea: [
        { value: this.formModel.allocationArea, disabled: true },
      ],
      centralInformation: [this.formModel.centralInformation || ''],
      changeReason: [this.formModel.changeReason || ''],
    });

    this.formFields = this.getFormFields();
  }

  disableFormFields() {
    if (this.frozenStatus && this.form) {
      this.form.disable();
      this.cd.markForCheck();
    }
  }

  onTabClick(value: string) {
    this.isLoading = true;
    this.activeTab = value;
    this.cd.detectChanges();
    setTimeout(() => {
      this.isLoading = false;
      this.cd.detectChanges();
    }, 500);
  }

  getFormFields(): FormlyFieldConfig[] {
    const loggedUserRoles = this.userService.getCurrentUserRoles();
    const isEditable = loggedUserRoles.includes(
      UserRoles.SMARTPORTAL_REM_DATENPFLEGE
    );
    const isEditableBothRoles =
      loggedUserRoles.includes(UserRoles.SMARTPORTAL_REM_FLM_DATENPFLEGE) ||
      loggedUserRoles.includes(UserRoles.SMARTPORTAL_REM_DATENPFLEGE);

    return [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'primaryRoomNumber',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'edit_room.labels.primaryRoomNumber'
              ),
            },
          },
          {
            key: 'aoid',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant('edit_room.labels.aoid'),
            },
          },
          {
            key: 'magOffice',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'edit_room.labels.magOffice'
              ),
            },
          },
          {
            key: 'wmProd',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant('edit_room.labels.wmProd'),
            },
          },
          {
            key: 'designType',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'edit_room.labels.designType'
              ),
            },
          },
          {
            key: 'warmRentCluster',
            type: 'input',
            wrappers: ['readOnly'],
            props: {
              label: this.translateService.instant(
                'edit_room.labels.warmRentCluster'
              ),
            },
          },
          {
            key: 'typeOfUseOrDetailedArea',
            type: 'link-edit-tab',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.typeOfUseOrDetailedArea'
              ),
              disabled: !isEditableBothRoles,
              value: this.formModel.typeOfUseOrDetailedArea,
              onClick: () => this.openAddModal(this.typeOfUseDetailedId),
            },
          },
          {
            key: 'usersArea',
            type: 'user-area-inputs',
            props: {
              disabled: !isEditable,
              label: this.translateService.instant(
                'edit_room.labels.usersAreas'
              ),
              architecture: this.formModel.usersAreaArchitectural,
              rentalArea_REM: this.formModel.rentalArea_REM,
              userArea: this.formModel.userArea,
            },
          },
          {
            key: 'workingPlacesOrPlanning',
            type: 'workingplaces-planning-input',
            props: {
              disabled: !isEditableBothRoles,
              label: this.translateService.instant(
                'edit_room.labels.workingPlacesOrPlanning'
              ),
              number: this.formModel.workingPlacesOrPlanningAvailable,
              planned: this.formModel.workingPlacesOrPlanningPlanned,
              occupied: this.formModel.workingPlacesOrPlanningOccupied,
            },
          },
          {
            type: 'flex-layout-col',
            fieldGroup: [
              {
                key: 'warmRentCalculation',
                type: 'checkbox',
                wrappers: ['no-border'],
                className: 'checkBox-margin-top',
                templateOptions: {
                  disabled: !isEditable || !this.formModel.hasWmvContract,
                  label: this.formModel.hasWmvContract
                    ? this.translateService.instant(
                        'edit_room.labels.rentalContractExistNo'
                      )
                    : this.translateService.instant(
                        'edit_room.labels.rentalContractExistYes'
                      ),
                  value: this.formModel.warmRentCalculation,
                },
              },
            ],
          },
          {
            type: 'flex-layout-col',
            fieldGroup: [
              {
                key: 'isRoutineCleaning',
                type: 'checkbox',
                wrappers: ['no-border'],
                className: 'margin-to-top me-3',
                props: {
                  disabled:
                    !isEditableBothRoles || !this.formModel.warmRentCalculation,
                  label: this.translateService.instant(
                    'edit_room.labels.isRoutineCleaning'
                  ),
                  value: this.formModel.isRoutineCleaning,
                },
              },
              {
                key: 'tiFlag',
                type: 'checkbox',
                wrappers: ['no-border'],
                className: 'margin-to-top',
                props: {
                  disabled: !isEditableBothRoles,
                  label: this.translateService.instant(
                    'edit_room.labels.tiFlag'
                  ),
                  value: this.formModel.tiFlag ?? false,
                },
              },
            ],
          },
          {
            key: 'roomDescription',
            type: 'input',
            props: {
              disabled: !isEditableBothRoles,
              label: this.translateService.instant(
                'edit_room.labels.roomDescription'
              ),
            },
          },
          {
            key: 'wmProdIndi',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.wmProdIndi'
              ),
              disabled: !isEditable,
              options: this.wmvProducts.map((reason: BaseSelectViewModel) => ({
                value: reason.id,
                label: reason.name,
              })),
            },
          },
          {
            key: 'warmClusterIndividual',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.wmProdClusterIndividual'
              ),
              disabled: !isEditable,
              options: this.wmvClusters.map((reason: BaseSelectViewModel) => ({
                value: reason.id,
                label: reason.name,
              })),
            },
          },
          {
            key: 'wmClusterInfo',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.wmClusterInfo'
              ),
              disabled: !isEditable,
              options: this.wmvClusterInfos.map(
                (reason: BaseSelectViewModel) => ({
                  value: reason.id,
                  label: reason.name,
                })
              ),
            },
          },
          {
            key: 'magOfficeDetailAreaType',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.magOfficeAreaType'
              ),
              disabled: !isEditableBothRoles,
              options: this.officeAreaTypes.map(
                (officeAreaTypes: BaseSelectViewModel) => ({
                  value: officeAreaTypes.id,
                  label: officeAreaTypes.name,
                })
              ),
            },
          },
          {
            key: 'calculationType',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.settlementOn'
              ),
              disabled: !isEditableBothRoles,
              options: this.calculationTypes.map(
                (type: BaseSelectViewModel) => ({
                  value: type.id,
                  label: type.name,
                })
              ),
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                if (field.formControl?.value !== Billing.billingArea) {
                  this.form.get('allocationArea')?.disable();
                }
                field.formControl?.valueChanges.subscribe(() => {
                  if (Billing.billingArea !== field.formControl?.value) {
                    this.form.get('allocationArea')?.disable();
                    this.form.get('allocationArea')?.setValue('');
                  } else {
                    this.form.get('allocationArea')?.enable();
                    this.form
                      .get('allocationArea')
                      ?.setValue(this.initialAllocationArea);
                  }
                });
              },
            },
          },
          {
            key: 'allocationArea',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.billingArea'
              ),
              options: this.allocationAreas.map((type: any) => ({
                value: type.id,
                label: type.name,
              })),
            },
          },
          {
            key: 'centralInformation',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'edit_room.labels.centralInformation'
              ),
              disabled: !isEditableBothRoles,
              options: this.centralInfos.map(
                (centralInfo: BaseSelectViewModel) => ({
                  value: centralInfo.id,
                  label: centralInfo.name,
                })
              ),
            },
          },
          {
            key: 'changeReason',
            type: 'select',
            className: 'margin-to-top',
            props: {
              label: this.translateService.instant(
                'buildingMaintenance.table.changeReason'
              ),
              disabled: !isEditableBothRoles,
              options: this.changeReasons.map(
                (changeReason: BaseSelectViewModel) => ({
                  value: changeReason.id,
                  label: changeReason.name,
                })
              ),
            },
          },
        ],
      },
    ];
  }

  openAddModal(item: any): void {
    const dialogRef = this.dialog.open(MaintenanceRoomAddNewModalComponent, {
      minWidth: '40vw',
      minHeight: '35vh',
      data: {
        item: item,
        changeReasons: this.changeReasons,
        spaceId: this.spaceId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshRoomData();
      }
    });
  }

  refreshRoomData(): void {
    if (this.frozenStatus) {
      return;
    }
    this.editRoomService
      .getBuildingMaintenanceRoomInformation(this.spaceId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.updateBuildingData(data);
            this.typeOfUseDetailAreaData$.next(data);

            this.initialAllocationArea = data.allocationArea;
          }
        },
      });
  }

  updateBuildingData(building: any): void {
    if (this.typeOfUseDetailAreaData$) {
      this.typeOfUseDetailAreaData$.next(building);
    }

    this.formModel = {
      primaryRoomNumber: building.primaryRoomNumber || '--',
      aoid: building.aoid || '--',
      magOffice: building?.magOfficeString || '--',
      wmProd: building?.wmProd || '--',
      designType: building?.measurement || '',
      warmRentCluster: building?.WmClusterInfo || '--',
      typeOfUseOrDetailedArea: building.usageTypeDetailAreaType || '--',
      usersAreaArchitectural:
        building?.architecturalArea !== undefined
          ? building?.architecturalArea
          : '--',
      rentalArea_REM:
        building?.rentalArea_REM !== undefined
          ? building?.rentalArea_REM
          : '--',
      userArea: building?.userArea !== undefined ? building?.userArea : '--',
      workingPlacesOrPlanning:
        building?.workingPlacesOrPlanning !== undefined
          ? building?.workingPlacesOrPlanning
          : '--',
      workingPlacesOrPlanningAvailable:
        building?.cntWorkspacesAvailable !== undefined
          ? building?.cntWorkspacesAvailable
          : '--',
      workingPlacesOrPlanningOccupied:
        building?.cntWorkspacesOccupied !== undefined
          ? building?.cntWorkspacesOccupied
          : '--',
      workingPlacesOrPlanningPlanned:
        building?.cntWorkspacesPlanned !== undefined
          ? building?.cntWorkspacesPlanned
          : '--',
      warmRentCalculation: building.isWmv ?? false,
      isRoutineCleaning: building.isRoutineCleaning ?? false,
      tiFlag: building.tiFlag ?? false,
      roomDescription: building?.roomDescription,
      wmProdIndi: building?.wmProdIndi,
      warmClusterIndividual: building?.wmClusterIndi,
      wmClusterInfo: building?.wmClusterInfo,
      magOfficeDetailAreaType: building?.magOfficeDetailAreaType,
      calculationType: building?.calculationType,
      allocationArea: building?.allocationArea,
      hasWmvContract: building.isWarmRentalContractValid ?? false,
      centralInformation: building?.centralInfo,
      changeReason: '',
    };

    this.formFields = this.getFormFields();

    this.cd.detectChanges();
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }

  getButtonVisibility() {
    const formChanged =
      JSON.stringify(this.form.value) !== JSON.stringify(this.initialFormState);

    const selectedChangeReason = (
      this.form.controls.changeReason.value || ''
    ).trim();

    const isValidChangeReason =
      selectedChangeReason !== '' && selectedChangeReason !== null;

    const shouldEnableButton = formChanged && isValidChangeReason;

    this.cardFields.footer.button1.disabled = !shouldEnableButton;

    this.cd.detectChanges();
  }

  onSubmit() {
    this.isLoading = true;

    const isBilling = Billing.billingArea === this.form.value.calculationType;

    const allocationAreaValue = isBilling ? this.form.value.allocationArea : '';

    const requestBody: Partial<UpdateRoomRequest> = {
      id: this.spaceId,
      name2: this.form.value.roomDescription,
      userArea: this.form.value.usersArea,
      workspacesPlanned: this.form.value.workingPlacesOrPlanning,
      wmIndividualProduct: this.form.value.wmProdIndi,
      wmIndividualCluster: this.form.value.warmClusterIndividual,
      wmClusterInformation: this.form.value.wmClusterInfo,
      isWMVerrechnung: this.form.value.warmRentCalculation,
      areaType: this.form.value.magOfficeDetailAreaType,
      routineCleaning: this.form.value.isRoutineCleaning,
      calculationType: this.form.value.calculationType,
      allocationArea: allocationAreaValue,
      tiFlag: this.form.value.tiFlag,
      centralInfo: this.form.value.centralInformation,
      changeReason: this.form.value.changeReason,
    };

    if (Object.keys(requestBody).length > 0) {
      this.editRoomService
        .updateRoom(requestBody as UpdateRoomRequest)
        .subscribe({
          next: () => {
            this.toastr.success(
              this.translateService.instant('edit_room.toastr.success')
            );
            this.cardFields.footer.button1.disabled = true;
            this.refreshRoomData();
            this.isLoading = false;
            this.cd.markForCheck();
          },
          error: () => {
            this.isLoading = false;
            this.cd.detectChanges();
            this.toastr.error(
              this.translateService.instant('edit_room.toastr.error')
            );
          },
        });
    }
  }
}
