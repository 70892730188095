<su-slot-title
  *ngIf="this.userService.loggedinB2C"
  [title]="
    viewTitle + ', ' + this.userService.accountDisplayName
  "></su-slot-title>

<su-slot-shortcuts
  *ngIf="cards && cards.length > 0"
  [heading]="cardsTitle"
  [cards]="cards"></su-slot-shortcuts>
<su-slot-shortcuts
  *ngIf="links && links.length > 0"
  [heading]="linksTitle"
  [links]="links"></su-slot-shortcuts>

<div *ngIf="!userService.loggedinB2C">
  <su-slot-title [title]="viewTitle"></su-slot-title>
  <su-slot-shortcuts
    *ngIf="linksAnonymous && linksAnonymous.length > 0"
    [heading]="linksTitle"
    [links]="linksAnonymous"></su-slot-shortcuts>
</div>
