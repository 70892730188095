import { OnDestroy, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CustomMatPaginatorIntl
  extends MatPaginatorIntl
  implements OnDestroy
{
  private onDestroy$: Subject<boolean> = new Subject();

  public constructor(private readonly translate: TranslateService) {
    super();

    this.translate
      .stream([
        'table.paginator.itemsPerPage',
        'table.paginator.nextPage',
        'table.paginator.previousPage',
        'table.paginator.firstPage',
        'table.paginator.lastPage',
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(translations => {
        this.itemsPerPageLabel = translations['table.paginator.itemsPerPage'];
        this.nextPageLabel = translations['table.paginator.nextPage'];
        this.previousPageLabel = translations['table.paginator.previousPage'];
        this.firstPageLabel = translations['table.paginator.firstPage'];
        this.lastPageLabel = translations['table.paginator.lastPage'];
        this.getRangeLabel = this.getRangeLabel.bind(this);

        this.changes.next();
      });
  }

  public override getRangeLabel = (
    page: number,
    pageSize: number,
    pageLength: number
  ): string => {
    if (pageLength === 0 || pageSize === 0) {
      return this.translate.instant('table.paginator.noItems', { pageLength });
    }

    const length = Math.max(pageLength, 0);

    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return this.translate.instant('table.paginator.rangeLabel', {
      startIndex: startIndex + 1,
      endIndex,
      length,
    });
  };

  public ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
