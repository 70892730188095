import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DynamicThemeService } from '@core/services/dynamic-theme-service/dynamic-theme.service';
import { MandantConfigService } from '@core/services/mandant-config-service/mandant-config.service';
import { MenuService } from '@core/services/menu-service/menu.service';
import { UserService } from '@core/services/oauth-service/user.service';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  public defaultRoute: string;

  public defaultRouteAuthenticated?: string;

  constructor(
    private translateService: TranslateService,

    private menuService: MenuService,
    private router: Router,
    private dynamicTheme: DynamicThemeService,
    private mandantConfigService: MandantConfigService,

    private titleService: Title,
    private userService: UserService
  ) {}

  public async load(): Promise<void> {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        this.translateService.use('de-DE');

        const respConfig$ = await this.mandantConfigService.loadMandantConfig();
        const mandantConfig = await lastValueFrom(respConfig$);

        this.dynamicTheme.loadTheme(mandantConfig);

        const respMenu$ = await this.menuService.fetchMenu();
        await lastValueFrom(respMenu$);

        await this.userService.init();

        resolve();
      } catch (error) {
        console.error(error);
        reject(error);
        // this.preloader.showError(error.message);
        throw new Error('Failed to load startup data.');
      }
    });
  }
}
