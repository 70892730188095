import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentMetadata, StorageClient } from '@core/api';
import { MandantConfigService } from '@core/services/mandant-config-service/mandant-config.service';
import { Mandant } from '@core/services/mandant-config-service/mandant-config.service.types';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import { ITableConfig } from '@shared/components/table-base/table-base.types';
import * as moment from 'moment';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-detail-file-list-buffered',
  templateUrl: '../../table-base/table-base.html',
  styleUrls: ['../../table-base/table-base.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DetailFileListBufferedComponent
  extends TableBaseComponent
  implements OnInit, OnChanges
{
  @Input() documents: DocumentMetadata[];

  @Output() removeEvent = new EventEmitter<any>();

  override config = this.configure(<ITableConfig>{
    title: 'documents.list.titleInProgress',
    translatePath: 'documents',
    showSmartCard: false,
    showBackButton: false,
    showRefreshButton: false,
  });

  @ViewChild('bufferedActionsTemplate', { static: true })
  bufferedActionsTemplateRef!: TemplateRef<void>;

  constructor(
    injector: Injector,
    private storageClient: StorageClient,
    private mandantService: MandantConfigService
  ) {
    super(injector);

    this.config.grid.showToolbar = false;
    this.config.grid.columnResizable = false;
    this.config.grid.paging.showPaginator = false;
    this.config.grid.filter.filterOnFront = false;
    this.config.grid.filter.sortOnFront = true;
  }

  ngOnInit() {
    switch (this.mandantService.mandant.name) {
      case Mandant.brst:
        this.columns = [
          {
            header: this.translate('label__title'),
            field: 'name',
            sortable: true,
          },
          {
            header: this.translate('label__status'),
            field: 'status',
            width: '20%',
            sortable: true,
            formatter: (data: any) => this.translate(`status.${data.status}`),
          },
          {
            header: this.translate('label__created'),
            field: 'created',
            width: '20%',
            sortable: true,
            formatter: (data: any) =>
              data.created
                ? moment(data.created).format('DD.MM.YYYY, HH:mm:ss')
                : '--',
          },
          {
            header: this.translate('label__action'),
            width: '120px',
            field: 'action',
            cellTemplate: this.bufferedActionsTemplateRef,
          },
        ];
        break;
      default:
        this.columns = [
          {
            header: this.translate('label__title'),
            field: 'name',
            sortable: true,
          },
          {
            header: this.translate('label__target'),
            field: 'targetSystem',
            width: '10%',
            sortable: true,
            formatter: (data: any) =>
              this.translate(`targetSystems.${data.targetSystem}`),
          },
          {
            header: this.translate('label__status'),
            field: 'status',
            width: '20%',
            sortable: true,
            formatter: (data: any) => this.translate(`status.${data.status}`),
          },
          {
            header: this.translate('label__created'),
            field: 'created',
            width: '20%',
            sortable: true,
            formatter: (data: any) =>
              data.created
                ? moment
                    .utc(data.created)
                    .local()
                    .format('DD.MM.YYYY, HH:mm:ss')
                : '--',
          },
          {
            header: this.translate('label__action'),
            width: '120px',
            field: 'action',
            cellTemplate: this.bufferedActionsTemplateRef,
          },
        ];
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.documents = changes?.documents?.currentValue;
    this.getData();
  }

  override removeBufferredDocument(row: any) {
    if (row.rowKey && !row.loading) {
      // eslint-disable-next-line no-param-reassign
      row.loading = true;
      this.cd.detectChanges();
      this.storageClient
        .deleteDocumentMetadata(row.rowKey)
        .pipe(take(1))
        .subscribe({
          next: () => {
            const index = this.documents.indexOf(row);
            this.documents.splice(index, 1);
            const message = this.translateService.instant(
              'generic.successMessage'
            );
            this.toastr.success(message);
            this.removeEvent.emit(this.documents);
            // eslint-disable-next-line no-param-reassign
            row.loading = false;
            this.cd.detectChanges();
          },
          error: () => {
            // eslint-disable-next-line no-param-reassign
            row.loading = false;
            this.showError();
          },
        });
    }
  }

  showError() {
    const message = this.translateService.instant('generic.errorMessage');
    this.toastr.error(message);
    this.cd.detectChanges();
  }

  override getTableData$ = () => {
    return of({ entites: this.documents, totalCount: this.documents.length });
  };

  override clickRow = (_data: any, _router: Router, _route: ActivatedRoute) => {
    // do nothing
  };
}
