<div class="content-body">
  <div class="row-container">
    <div class="text-col">
      <h2>Impressum</h2>
      <br />
      <p>
        <strong>Adresse:</strong><br />
        Deutsche Telekom AG<br />
        Group Supply Services - Real Estate Management <br />
        Sternengasse 14-16<br />
        50676 Köln
      </p>
      <br />
      <p>
        <strong>Handelsregister:</strong><br />
        Amtsgericht Bonn HRB 6794 <br />
        Sitz der Gesellschaft Bonn <br />
        USt-IdNr. DE 123475223<br />
        E-Mail: <a href="mailto:real_estate@telekom.de">real_estate&#64;telekom.de</a><br />
        Telefon: 0228 / 181-0
      </p>
      <br />
      <p>
        <strong>Kontakt:</strong><br />
        Bei Fragen zu unseren Immobilien nutzen
        Sie bitte unsere
        <a href="/landing/contact" target="_blank" title="Kontaktseite"
          ><span>Kontaktseite</span></a
        >.
      </p>
      <br />
      <p>
        <strong>Vertretungsberechtigt:</strong><br />
        Rainer Hoff<br />
        Dagmar Ecken<br />
        Michael Schlombs<br />
      </p>
      <br />
      <p>
        <strong>Verantwortlich:</strong><br />
        Deutsche Telekom AG<br />
        Group Supply Services - Real Estate Management<br />
        Michael Schildger<br />
        Strategie, Prozesse und Qualität<br />
        Sternengasse 14-16 <br />
        50676 Köln
      </p>
    </div>
    <div class="image-container">
      <img src="../../../assets/images/undraw_impressum.svg" />
    </div>
  </div>
</div>
