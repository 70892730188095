<div class="content-body">
  <div class="row-container">
    <div class="text-col">
      <h3>Datenschutzhinweise</h3>
      <br />
      <p>
        Der Schutz Ihrer persönlichen Daten hat für die GMG
        Generalmietgesellschaft mbH einen hohen Stellenwert. Es ist uns wichtig,
        Sie darüber zu informieren, welche persönlichen Daten erfasst werden,
        wie diese verwendet werden und welche Gestaltungsmöglichkeiten Sie dabei
        haben.
      </p>
      <br />
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >1. Welche Daten werden erfasst, wie werden sie verwendet und wie
          lange werden sie gespeichert?
        </strong>
      </p>
      <br />
      <p>
        <strong>a) Technische Merkmale:</strong>
        Wenn Sie unsere Webseiten besuchen, verzeichnet der Web-Server
        vorübergehend den Domain-Namen oder die IP- Adresse Ihres Computers, die
        Dateianfrage des Clients (Dateiname und URL), den http-Antwort-Code und
        die Webseite, von der aus Sie uns besuchen.
      </p>
      <br />
      <p>
        Die protokollierten Daten werden ausschließlich für Zwecke der
        Datensicherheit, insbesondere zur Abwehr von Angriffsversuchen auf
        unseren Webserver verwendet (Art. 6 Abs. 1f DSGVO). Sie werden weder für
        die Erstellung von individuellen Anwenderprofilen verwendet noch an
        Dritte weitergegeben und werden nach spätestens 7 Tagen gelöscht. Die
        statistische Auswertung anonymisierter Datensätze behalten wir uns vor.
      </p>
      <br />
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >2. Datenkontrolle bei den eingesetzten Social Media Plug-ins bzw.
          Links zu Social Media Plattformen
        </strong>
      </p>
      <br />
      <p>
        Einige Webseiten enthalten Buttons von Social Media Netzwerken (wie z.
        B. Facebook, Google, Instagram, Twitter, Pinterest, Xing oder LinkedIn),
        mit denen Sie Ihren Freunden und Bekannten die Angebote der GMG
        Generalmietgesellschaft mbH empfehlen können.
      </p>
      <br />
      <p>
        Auf unseren Webseiten verwenden wir nur die Piktogramme des jeweiligen
        Social Media Netzwerkes. Erst mit einem Klick auf das Piktogramm werden
        Sie auf die Unternehmens-Seite auf der jeweiligen Social Media Plattform
        geleitet. Die Social Media Plattformen sowie die Fremdinhalteanbieter,
        die über die Piktogramme erreicht werden können, erbringen diese Dienste
        und die Verarbeitung ihrer Daten in eigener Verantwortung.
      </p>
      <br />
      <p>
        Durch das Aktivieren des Social Media Plug-ins bzw. Links über das
        Piktogramm, auch zum Teilen von Inhalten, (Art. 6 Abs. 1a DSGVO) können
        folgende Daten an die Social Media Anbieter übermittelt werden:
        IP-Adresse, Browserinformationen, Betriebssystem, Bildschirmauflösung,
        installierte Browser-Plug-ins wie z. B. Adobe Flash Player, vorherige
        Webseite, wenn Sie einem Link gefolgt sind (Referrer), die URL der
        aktuellen Webseite, etc..
      </p>
      <br />
      <p>
        Beim nächsten Webseitenaufruf werden die Social Media Plug-ins erneut im
        voreingestellten inaktiven Modus bereitgestellt, so dass bei einem
        erneuten Besuch der Webseite sichergestellt ist, dass keine Daten
        übermittelt werden.
        <br />
        Weitere Informationen zu Social Media Plug-ins zum Umfang und zu den
        Zwecken der jeweiligen Datenverarbeitung sowie weitere
        datenschutzrelevante Informationen finden Sie in den
        Datenschutzerklärungen des jeweiligen Verantwortlichen.
      </p>
      <br />
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >3. Wird mein Nutzungsverhalten ausgewertet, z. B. für Werbung oder
          Tracking?
        </strong>
      </p>
      <br />
      <p>
        <strong>Erläuterungen und Definitionen</strong>
      </p>
      <br />
      <p>
        Wir möchten, dass sie unsere Webseiten gerne nutzen und unsere Produkte
        und Dienste in Anspruch nehmen. Daran haben wir ein wirtschaftliches
        Interesse. Damit Sie die Produkte finden, die Sie interessieren und wir
        unsere Webseiten nutzerfreundlich ausgestalten können, analysieren wir
        anonymisiert oder pseudonymisiert Ihr Nutzungsverhalten. Im Rahmen der
        gesetzlichen Regelungen legen wir, oder von uns im Rahmen einer
        Auftragsverarbeitung beauftragte Unternehmen, Nutzungsprofile an. Ein
        unmittelbarer Rückschluss auf Sie ist dabei nicht möglich. Nachfolgend
        informieren wir Sie allgemein über die verschiedenen Zwecke. Über die
        Cookie-Abfrage, die beim Besuch unserer Webseiten erscheint, haben Sie
        die Möglichkeit, der Cookie-Nutzung zuzustimmen oder diese abzulehnen.
        Cookies, die zur Erbringung des Webdienstes erforderlich sind (siehe
        Erläuterung oben unter 1.), können nicht abgelehnt werden.
      </p>
      <br />
      <p>
        <strong>a) Erforderliche Cookies</strong><br />
        Diese Cookies sind notwendig, damit Sie durch die Seiten navigieren und
        wesentliche Funktionen nutzen können. Sie ermöglichen Grundfunktionen,
        wie die Bestellabwicklung im Online-Shop und den Zugriff auf gesicherte
        Bereiche der Webseite. Zudem dienen sie der anonymen Auswertung des
        Nutzerverhaltens, die von uns verwendet werden, um unseren Webauftritt
        stetig für Sie weiterzuentwickeln. Rechtsgrundlage für diese Cookies ist
        Art. 6 Abs. 1b DSGVO bzw. bei Drittstaaten Art. 49 Abs. 1b DSGVO.
        <br /><u>Es werden keine erforderlichen Cookies verwendet.</u>
      </p>
      <br />
      <p>
        <strong>b) Cookies, bei optionaler Nutzung von Tools</strong><br />
        Diese Cookies werden dann verwendet, wenn Sie zusätzlichen Funktionen,
        wie z. B. Chat, nutzen. Die möglichen Funktionen werden im Abschnitt
        Eins dieses Datenschutzhinweises erläutert. Rechtsgrundlage für diese
        Cookies ist Art. 6 Abs. 1a DSGVO bzw. bei Drittstaaten Art. 49 Abs. 1a
        DSGVO.
        <br /><u>Es werden keine Cookies für optionale Nutzungen verwendet.</u>
      </p>
      <br />
      <p>
        <strong>c) Analytische Cookies</strong><br />
        Diese Cookies helfen uns, das Nutzungsverhalten besser zu verstehen.
        Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und
        Erkennungsmöglichkeiten durch Erst- oder Drittanbieter, in so genannten
        pseudonymen Nutzungsprofilen. Wir benutzen beispielsweise
        Analyse-Cookies, um die Zahl der individuellen Besucher einer Webseite
        oder eines Dienstes zu ermitteln oder um andere Statistiken im Hinblick
        auf den Betrieb unserer Produkte zu erheben, als auch das
        Nutzerverhalten auf Basis anonymer und pseudonymer Informationen zu
        analysieren, wie Besucher mit der Webseite interagieren. Ein
        unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich.
        Rechtsgrundlage für diese Cookies ist Art. 6 Abs. 1a DSGVO bzw. bei
        Drittstaaten Art. 49 Abs. 1a DSGVO.
        <br /><u
          >Es werden keine Cookies für analytische Nutzungen verwendet.</u
        >
      </p>
      <br />
      <p>
        <strong>d) Marketing-Cookies/Retargeting</strong><br />
        Diese Cookies und ähnliche Technologien werden eingesetzt, um Ihnen
        personalisierte und dadurch relevante werbliche Inhalte anzeigen zu
        können. Marketing-Cookies werden eingesetzt, um interessante
        Werbeinhalte anzuzeigen und die Wirksamkeit unserer Kampagnen zu messen.
        Dies geschieht nicht nur auf Webseiten der Telekom, sondern auch auf
        anderen Werbepartner-Seiten (Drittanbieter). Dies wird auch als
        Retargeting bezeichnet. Es dient zur Erstellung pseudonymer Inhalts-
        oder Anzeigenprofile, der Schaltung relevanter Werbung auf anderen
        Webseiten und um Erkenntnisse über Zielgruppen, die die Anzeigen und
        Inhalte betrachtet haben, abzuleiten. Ein unmittelbarer Rückschluss auf
        eine Person ist dabei nicht möglich. Marketing- und Retargeting-Cookies
        helfen uns, mögliche relevante Werbeinhalte für Sie anzuzeigen. Durch
        das Unterdrücken von Marketing-Cookies sehen Sie auch weiterhin die
        gleiche Anzahl an Werbung, die aber möglicherweise weniger relevant für
        Sie ist. Rechtsgrundlage für diese Cookies ist Art. 6 Abs. 1a DSGVO bzw.
        bei Drittstaaten Art. 49 Abs. 1a DSGVO.
        <br /><u
          >Es werden keine Cookies für marketingtechnische Nutzungen
          verwendet.</u
        >
      </p>
      <br />
      <p>
        <strong
          >e) Dienste von anderen Unternehmen (eigenverantwortliche
          Drittanbieter)</strong
        ><br />
        Auf unseren Webseiten haben wir Drittanbieter Dienste eingebunden, die
        ihre Services eigenverantwortlich erbringen. Dabei werden beim Besuch
        unserer Seiten Daten mittels Cookies oder ähnlicher Technologien erfasst
        und an den jeweiligen Dritten übermittelt. Zum Teil für Telekomeigene
        Zwecke. Rechtsgrundlage für diese Cookies ist Art. 6 Abs. 1a DSGVO. In
        welchem Umfang, zu welchen Zwecken und auf Basis welcher Rechtsgrundlage
        eine Weiterverarbeitung zu eigenen Zwecken des Drittanbieters erfolgt,
        entnehmen Sie bitte den Datenschutzhinweisen des Drittanbieters. Die
        Informationen zu den eigenverantwortlichen Drittanbietern finden Sie
        nachfolgend.

        <br /><br /><strong>Spotify</strong><br />
        Auf der „Über uns“ Seite der Telekom Real Estate Webseite ist im unteren
        Bereich die Rubrik „Jetzt reinhören“ enthalten. Durch Anklicken des
        dortigen Buttons werden Sie direkt an Spotify weitergeleitet. Sie können
        sich jederzeit über die Datenverarbeitung von Spotify AB, Regeringsgatan
        19, 111 53 Stockholm, Schweden unter
        <a
          class="link-styling"
          href="https://www.spotify.com/de/legal/privacy-policy/"
          >https://​www​.​spo​ti​fy​.​com​/​de​/​le​gal​/​pri​va​cy​-​po​li​cy​/</a
        >
        informieren und dieser widersprechen.
      </p>
      <br />
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >4. Wo finde ich die Informationen, die für mich wichtig sind?
        </strong>
      </p>
      <br />
      <p>
        Dieser <strong>Datenschutzhinweis</strong> gibt einen Überblick über die
        Punkte, die für die Verarbeitung Ihrer Daten in diesem Webportal durch
        die Telekom gelten.
      </p>
      <br />
      <p>
        Weitere Informationen, auch zum Datenschutz im Allgemeinen und in
        speziellen Produkten, erhalten Sie auf
        <a
          class="link-styling"
          href="https://www.telekom.com/de/verantwortung/datenschutz-und-datensicherheit/datenschutz">
          https://www​.​te​le​kom​.​com​/​de​/​ver​ant​wor​tung​/
          ​da​ten​schutz​-​und​-​da​ten​si​cher​heit​/​da​ten​schutz
        </a>
        und unter
        <a
          class="link-styling"
          href="http://www.telekom.de/datenschutzhinweise">
          http://www​.​te​le​kom​.​de​/​da​ten​schutz​hin​wei​se
        </a>
        .
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >5. Wer ist verantwortlich für die Datenverarbeitung? Wer ist mein
          Ansprechpartner, wenn ich Fragen zum Datenschutz bei der Telekom habe?
        </strong>
      </p>
      <br />
      <p>
        Datenverantwortliche ist die GMG Generalmietgesellschaft mbH. Bei Fragen
        können Sie sich an unseren
        <a class="link-styling" href="http://www.telekom.de/kontakt"
          >Kundenservice</a
        >
        wenden oder an unseren Datenschutzbeauftragten, Herrn Dr. Claus D.
        Ulmer, Friedrich-Ebert-Allee 140, 53113 Bonn,
        <a class="link-styling" href="mailto:datenschutz@telekom.de"
          >da​ten​schutz​&#64;​te​le​kom​.​de</a
        >.
      </p>
      <br />
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong>6. Welche Rechte habe ich?</strong>
      </p>
      <br />
      <p>Sie haben das Recht,</p>
      <p>
        a) <strong>Auskunft</strong> zu verlangen zu Kategorien der
        verarbeiteten Daten, Verarbeitungszwecken, etwaigen Empfängern der
        Daten, der geplanten Speicherdauer (Art. 15 DSGVO);
      </p>
      <br />
      <p>
        b) die <strong>Berichtigung</strong> bzw. Ergänzung unrichtiger bzw.
        unvollständiger Daten zu verlangen (Art. 16 DSGVO);
      </p>
      <br />
      <p>
        c) eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu
        <strong>widerrufen </strong>(Art. 7 Abs. 3 DSGVO);
      </p>
      <br />
      <p>
        d) einer Datenverarbeitung, die aufgrund eines berechtigten Interesses
        erfolgen soll, aus Gründen zu <strong>widersprechen, </strong>die sich
        aus Ihrer besonderen Situation ergeben (Art 21 Abs. 1 DSGVO);
      </p>
      <br />
      <p>
        e) in bestimmten Fällen im Rahmen des Art. 17 DSGVO die
        <strong>Löschung</strong> von Daten zu verlangen - insbesondere soweit
        die Daten für den vorgesehenen Zweck nicht mehr erforderlich sind bzw.
        unrechtmäßig verarbeitet werden, oder Sie Ihre Einwilligung gemäß oben
        (c) widerrufen oder einen Widerspruch gemäß oben (d) erklärt haben;
      </p>
      <br />
      <p>
        f) unter bestimmten Voraussetzungen die
        <strong>Einschränkung</strong> von Daten zu verlangen, soweit eine
        Löschung nicht möglich bzw. die Löschpflicht streitig ist (Art. 18
        DSGVO);
      </p>
      <br />
      <p>
        g) auf <strong>Datenübertragbarkeit, </strong>d.h. Sie können Ihre
        Daten, die Sie uns bereitgestellt haben, in einem gängigen
        maschinenlesbaren Format, wie z.B. CSV, erhalten und ggf. an andere
        übermitteln (Art. 20 DSGVO;)
      </p>
      <br />
      <p>
        h) sich bei der zuständigen <strong>Aufsichtsbehörde</strong> über die
        Datenverarbeitung zu <strong>beschweren</strong> (für
        Telekommunikationsverträge: Bundesbeauftragter für den Datenschutz und
        die Informationsfreiheit; im Übrigen: Landesbeauftragte für den
        Datenschutz und die Informationsfreiheit Nordrhein-Westfalen).
      </p>
      <br />
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong>7. An wen gibt die Telekom meine Daten weiter?</strong>
      </p>
      <br />
      <p>
        <strong>An Auftragsverarbeiter, </strong>
        das sind Unternehmen, die wir im gesetzlich vorgesehenen Rahmen mit der
        Verarbeitung von Daten beauftragen, Art. 28 DSGVO (Dienstleister,
        Erfüllungsgehilfen). Die Telekom bleibt auch in dem Fall weiterhin für
        den Schutz Ihrer Daten verantwortlich. Wir beauftragen Unternehmen
        insbesondere in folgenden Bereichen: IT, Vertrieb, Marketing, Finanzen,
        Beratung, Kundenservice, Personalwesen, Logistik, Druck.
      </p>
      <br />
      <p>
        <strong>An Kooperationspartner, </strong>
        die in eigener Verantwortung Leistungen für Sie bzw. im Zusammenhang mit
        Ihrem Telekom-Vertrag erbringen. Dies ist der Fall, wenn Sie Leistungen
        solcher Partner bei uns beauftragen oder wenn Sie in die Einbindung des
        Partners einwilligen oder wenn wir den Partner aufgrund einer
        gesetzlichen Erlaubnis einbinden.
      </p>
      <br />
      <p>
        <strong>Aufgrund gesetzlicher Verpflichtung:</strong>
        In bestimmten Fällen sind wir gesetzlich verpflichtet, bestimmte Daten
        an die anfragende staatliche Stelle zu übermitteln.
      </p>
      <br />
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong>8. Wo werden meine Daten verarbeitet?</strong>
      </p>
      <br />
      <p>
        Ihre Daten werden in Deutschland und der Europäischen Union verarbeitet.
      </p>
      <p>Stand der Datenschutzhinweise 15.11.2022</p>
    </div>
    <div class="image-container">
      <img src="../../assets/images/undraw_dataprotection.svg" />
    </div>
  </div>
</div>
