<su-headline h="h1" skin="primary">{{
  'taskmodule.task.list.title' | translate
}}</su-headline>

<div class="row-chips">
  <su-chip [items]="chips" (onRemove)="handleOnRemoveChip($event)"></su-chip>
</div>

<ng-template #toolbarTpl>
  <su-slot-controls
    [mainTLabel]="'crud.filter' | translate"
    [buttons]="controlButtons"
    [chips]="[]"
    [menuItems]="[]"
    [dialogData]="dialogData"
    (onCloseDialog)="onCloseDialog($event)"
    [modal]="modalComponent"></su-slot-controls>
</ng-template>

<mtx-grid
  [columnResizable]="true"
  [columns]="columns"
  [data]="data.items"
  [length]="data.totalCount"
  [pageOnFront]="false"
  [sortOnFront]="config.grid.filter.sortOnFront"
  [loading]="isLoading"
  [rowStriped]="true"
  [noResultText]="'grid.no_results' | translate"
  (rowClick)="clickRowWrapper($event)"
  [rowHover]="false"
  [cellSelectable]="false"
  [pageSizeOptions]="[5, 10, 25, 50, 100]"
  (sortChange)="sortChange($event)"
  (page)="getNextPage($event)"
  [pageSize]="config.grid.paging.pageSize"
  [pageIndex]="config.grid.paging.pageIndex"
  [showPaginator]="config.grid.paging.showPaginator"
  [columnMenuButtonText]="'grid.columns_shown' | translate"
  [columnHideable]="true"
  [columnHideableChecked]="'show' | translate"
  columnMenuButtonClass="col-button"
  [showSidebar]="true"
  [expandable]="fieldsInExpansionTemplate.length > 0"
  [expansionTemplate]="expansionTemplate"
  [showToolbar]="true"
  [columnSortable]="true"
  [showColumnMenuButton]="true"
  columnMenuButtonType="flat"
  [columnPinnable]="false"
  [toolbarTemplate]="toolbarTpl"
  (columnChange)="saveColumnVisibility()">
</mtx-grid>

<ng-template #expansionTemplate let-row>
  <div>
    <p *ngFor="let field of fieldsInExpansionTemplate">{{ row[field] ? row[field] : '--'}}</p>
  </div>
</ng-template>
