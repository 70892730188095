import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from '@core/routing/routing.types';
import { UserService } from '../oauth-service/user.service';
import { MenuService } from './menu.service';

export const RolesGuard = () => {
  const router = inject(Router);
  const activeRoute = window.location.pathname;
  const menuService = inject(MenuService);
  const userService = inject(UserService);
  const menuRoutesRoles = menuService.routesRoles;
  const currentUserRoles = userService.getCurrentUserRoles();
  const foundRoute = menuRoutesRoles.find(x => activeRoute.includes(x.route));

  // Case 1: no route is found -> allowed to see all
  if (!foundRoute) {
    return true;
  }

  // Case 2: not loggedIn User -> navigate to NONE
  if (!currentUserRoles) {
    return router.parseUrl(RoutePath.NONE);
  }

  // Case 3: loggedIn User with Roles -> compare user roles with route roles
  let isAllowed = false;
  foundRoute.roles.forEach(routeRole => {
    currentUserRoles.forEach(userRole => {
      if (routeRole === userRole) {
        isAllowed = true;
      }
    });
  });

  if (isAllowed) {
    return true;
  }

  return router.parseUrl(RoutePath.NONE);
};
