import { Injectable } from '@angular/core';

import { BehaviorSubject, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../oauth-service/user.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menu$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  menuChange$ = this.menu$.asObservable();

  private originalMenu = null;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.userService.activeAccountChange$.subscribe({
      next: userState => {
        if (
          !this.originalMenu ||
          !this.originalMenu['side-nav'] ||
          this.originalMenu['side-nav'].length === 0
        ) {
          this.menu$.next({
            'meta-nav': [
              {
                label: 'Impressum',
                link: '/imprint',
              },
              {
                label: 'Datenschutz',
                link: '/privacy',
              },
            ],
          });
          return;
        }

        const menuRoutesRoles = this.originalMenu.routesRoles;

        const filteredMenu = this.originalMenu['side-nav'].map(sideNavItem => {
          return {
            ...sideNavItem,
            items: sideNavItem.items.filter(item => {
              let isVisible = false;

              const foundItemRoutesRoles = menuRoutesRoles.find(
                x => x.route === item.link
              );
              if (foundItemRoutesRoles) {
                // check roles
                foundItemRoutesRoles.roles.forEach(menuItemRole => {
                  if (
                    userState &&
                    userState.idTokenClaims?.roles.includes(menuItemRole)
                  ) {
                    isVisible = true;
                  }
                });
              } else {
                isVisible = true;
              }
              return isVisible;
            }),
          };
        });
        const temp2 = filteredMenu.filter(
          sideNavItem => sideNavItem.items.length > 0
        );

        let metaNav = this.originalMenu['meta-nav'];

        if(userState.idTokenClaims.ciamId){
          metaNav =  [
              {
                label: 'Impressum',
                link: '/imprint',
              },
              {
                label: 'Datenschutz',
                link: 'https://datenschutzhinweise.telekom.de/default.aspx?q=cf47a4d2-9831-46bd-9097-a2ea35014a31&preview=yes&lang=de',
              },
            ]
        }
        
        const nav = {
          ...this.originalMenu,
          'side-nav': temp2,
          'meta-nav': metaNav
        };
        this.menu$.next(nav);
      },
    });
  }

  get routesRoles() {
    if (!this.originalMenu) {
      return [];
    }
    return this.originalMenu.routesRoles;
  }

  get menu() {
    return this.menu$.value;
  }

  async fetchMenu() {
    return this.http.get(`/assets/menu.json?t=${Date.now()}`).pipe(
      map(response => {
        const menuTranslated = this.translateLabels(response);
        this.originalMenu = { ...menuTranslated };
        return menuTranslated;
      })
    );
  }

  translateLabels(menu: any, menuKey = null) {
    if (menu.constructor === Object) {
      Object.keys(menu).forEach(menuItemKey => {
        if (menuItemKey === 'label') {
          menu[menuItemKey] = this.translateService.instant(menu[menuItemKey]);
        }
        menu[menuItemKey] = this.translateLabels(
          menu[menuItemKey],
          menuItemKey
        );
      });
    } else if (menu.constructor === Array) {
      const menuArray = [];
      menu.forEach(item => {
        menuArray.push(this.translateLabels(item));
      });

      menu[menuKey] = menuArray;
    }
    return menu;
  }
}
