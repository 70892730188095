import { Component } from '@angular/core';

@Component({
  selector: 'app-fact-wrapper',
  templateUrl: './fact-wrapper.component.html',
  styleUrls: ['./fact-wrapper.component.scss'],
})
export class FactWrapperComponent {
  factItemData: FactItemData[] = [
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '10600',
      subtitle: 'Standorte',
      factType: '',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '4800',
      subtitle: 'Bürostandorte',
      factType: '',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '7,3',
      subtitle: 'Gesamtfläche',
      factType: 'm2',
    },
    {
      iconUrl: 'assets/icons/dummy-icon.svg',
      factAmount: '1,4',
      subtitle: 'Bürofläche',
      factType: 'm2',
    },
  ];
}

export interface FactItemData {
  iconUrl: string;
  factAmount: string;
  subtitle: string;
  factType: string;
}
