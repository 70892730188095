<div mat-dialog-title *ngIf="data.title">
  <su-headline h="h3" skin="primary">{{ data.title }}</su-headline>
</div>
<div *ngIf="data.message" mat-dialog-content class="dialog-content">
  <p [innerHtml]="data.message"></p>
</div>
<div mat-dialog-content class="dialog-content">
  <mat-form-field style="width: 100%">
    <mat-label>{{
      'taskmodule.tickets.list.permission.onApprove.reasonOptional' | translate
    }}</mat-label>
    <textarea
      matInput
      [(ngModel)]="reasonForApproval"
      name="reasonForApproval"
      #reasonInput="ngModel"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions class="dialog-button-container">
  <su-button
    skin="secondary"
    (onClick)="onDismiss()"
    [label]="
      'taskmodule.tickets.list.permission.cancel' | translate
    "></su-button>

  <su-button
    skin="primary"
    (onClick)="onOk()"
    [label]="'taskmodule.tickets.list.permission.ok' | translate"></su-button>
</div>
