<su-headline h="h1" skin="primary">{{ headline }}</su-headline>

<su-card [card]="cardUser">
  <div card-content>
    <br />
    <formly-form [form]="form" [fields]="formFields" [model]="formModel">
    </formly-form>
  </div>
</su-card>

<app-spinner-overlay [show]="loading"></app-spinner-overlay>
