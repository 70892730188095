import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { OptionSetClient, SmartPortalApiModule, UserClient } from '@core/api';
import {
  GetDomainHint,
  GetProtectedResourcesMap,
  msalConfig,
  protectedResources,
} from '@core/auth-config';
import { environment } from '@core/environments/environment';
import { RoutingModule } from '@core/routing/routing.module';
import { StartupServiceInitializer } from '@core/services/startup-service/startup.initializer';
import { TranslateLangService } from '@core/services/translate-service/translate-lang.service';
import { AuthenticationModule } from '@features/authentication/authentication.module';
import { DashboardModule } from '@features/dashboard/dashboard.module';
import { DocumentsModule } from '@features/documents/documents.module';
import { ProfileDefaultModule } from '@features/profile-default/profile-default.module';
import { TasksModule } from '@features/tasks/tasks.module';
import { TicketsModule } from '@features/tickets/tickets.module';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TableBaseComponent } from '@shared/components/table-base/table-base';
import { SharedModule } from '@shared/shared.module';
import { GANTT_GLOBAL_CONFIG } from '@worktile/gantt';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent, TableBaseComponent],
  imports: [
    TicketsModule,
    TasksModule,
    DocumentsModule,
    ProfileDefaultModule,
    SharedModule,
    AuthenticationModule,
    DashboardModule,
    HttpClientModule,
    BrowserModule,
    RoutingModule,
    BrowserAnimationsModule,
    SmartPortalApiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLangService,
        deps: [HttpClient],
      },
    }),
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: protectedResources.getIncident.scopes,
          domainHint: GetDomainHint(),
        },
        // important for the cancelation of "reset password"
        loginFailedRoute: '/',
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: GetProtectedResourcesMap(),
      }
    ),
    FormlyModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: () =>
        environment.appInsightsConnectionString
          ? new ApplicationinsightsAngularpluginErrorService()
          : new ErrorHandler(),
    },
    UserClient,
    StartupServiceInitializer,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    OptionSetClient,
    {
      provide: GANTT_GLOBAL_CONFIG,
      useValue: {
        dateFormat: {
          hour: 'HH:mm',
          day: 'dd. MMM yyyy',
          week: 'w',
          month: 'LLL',
          quarter: 'QQQ',
          year: 'yyyy',
          yearMonth: 'LLL yyyy',
          yearQuarter: `QQQ yyyy`,
        },
      },
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
