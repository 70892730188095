import { NgModule } from '@angular/core';
import { DocumentUploadComponent } from '@features/documents/components/document-upload/document-upload.component';
import { SharedModule } from '@shared/shared.module';
import { DocumentsComponent } from './documents.component';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from 'src/app/override/override.module';

const COMPONENTS = [DocumentUploadComponent, DocumentsComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [SharedModule, TranslateModule, OverrideModule],
  providers: [],
})
export class DocumentsModule {}
