import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterPipe', standalone: true })
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchTerm: any, property: string): any[] {
    if (!items || !searchTerm || !property) {
      return items;
    }
    return items.filter(item => item[property] === searchTerm);
  }
}
