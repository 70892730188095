import { ChangeDetectorRef, Component, isDevMode, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FmConfigurationRow,
  FmIncident,
  FmTask,
  IncidentFieldMapping,
  OptionSetClient,
  ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping,
  SysEnum,
  TaskClient,
  TaskStatus,
  UpdateIncidentDTO,
  UpdateTaskDTO,
} from '@core/api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { IbpdiHelperService } from '@core/services/translate-service/ibpdi-helper.service';
import { TranslateDataService } from '@core/services/translate-service/translate-data.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IDetailFields } from '@shared/components/details/detail-fields/models/detail-fields.model';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { DocumentVisibility } from '@shared/types/document.types';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { first, map, take } from 'rxjs/operators';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';

@Component({
  selector: 'app-task-details-view',
  templateUrl: 'task-details-view.component.html',
})
export class TaskDetailsViewComponent implements OnInit {
  public title = this.translateService.instant('taskmodule.task.view.title');

  public isLoading = false;

  public masterRequired = true;

  form = new FormGroup({});

  isDevMode = isDevMode();

  formModel: any = {};

  formFields: FormlyFieldConfig[];

  entity: ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping;

  task?: FmTask;

  dataContactAddress: FmIncident;

  extension?: IncidentFieldMapping[];

  saveButtonLabel: string;

  private updateIncidentFieldGroupName = 'updateIncidentFieldsFormlyGroup';

  cardFields: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  cardContact: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  tabs: Tab[] = [
    {
      label: this.translateService.instant('taskmodule.task.update.update_incident_fields'),
      id: 'editIncidentFields',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: this.translateService.instant('selfservice.ticket.shared.label__contact'),
      id: 'contact',
      onClick: (id: string) => this.onTabClick(id),
    },
  ];

  activeTab = this.tabs[0].id;

  onTabClick(value: string) {
    this.activeTab = value;
  }

  incidentInfomations: FormlyFieldConfig[] = [
    {
      wrappers: ['card'],
      fieldGroup: [
        {
          type: 'flex-layout',
          fieldGroup: [
            {
              key: 'incident.ticketNumber',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__ticketNumber'),
                readOnly: true,
              },
            },
            {
              key: 'incident.requestType',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentType'),
                readOnly: true,
              },
            },
          ],
        },
        {
          type: 'flex-layout',
          fieldGroup: [
            {
              key: 'incident.name',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentTitle'),
                readOnly: true,
              },
            },
            {
              key: 'incident.incidentClass.incidentClass',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentClass'),
                readOnly: true,
              },
            },
          ],
        },
        {
          type: 'flex-layout',
          fieldGroup: [
            {
              key: 'incident.archObjId',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentFunctionalLocation'),
                readOnly: true,
              },
            },
            {
              key: 'incident.dtagResponsibility',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentDtagResponsibility'),
                readOnly: true,
              },
            },
          ],
        },
      ],
    },
  ];

  taskInformations: FormlyFieldConfig[] = [
    {
      key: 'subject',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__subject'),
        readOnly: true,
      },
    },
    {
      key: 'description',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__taskDescription'),
        readOnly: true,
      },
    },
    {
      key: 'dueDate',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__dueDate'),
        readOnly: true,
        valueFormatter: dueDate => {
          if (!dueDate) {
            return '--';
          }
          const now = moment(new Date());
          let r = moment(dueDate)
            .locale(this.translateService.instant('locale.locale'))
            .format(
              this.translateService.instant('locale.moment__date_format')
            );
          r +=
            moment(dueDate).diff(now, 'days') < 0
              ? ` <span style="color:red"> ${this.translate(
                  'info__overdue'
                )} <span class="material-icons" style="vertical-align: bottom">warning_amber</span> </span>`
              : '';
          return r;
        },
      },
    },
    {
      wrappers: ['form-field', 'count-chars'],
      key: 'result',
      type: 'textarea',
      props: {
        label: this.translate('label__result'),
        visibleInConfirm: true,
        required: false,
        disabled: true,
        autosize: true,
        autosizeMinRows: 4,
      },
    },
    {
      key: 'status',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__actualSatus'),
        readOnly: true,
      },
      hooks: {
        onInit: field => {
          field?.formControl?.valueChanges.subscribe({
            next: value => {
              if (value === 'In Bearbeitung') {
                const index = this.formFields.findIndex(
                  x => x.key === 'newStatus'
                );
                this.formFields[index].defaultValue = TaskStatus.InBearbeitung;
              }
              if (this.task?.configurationTask?.fmConfigurationRow) {
                const formGroup =
                  this.formFields[
                    this.formFields.findIndex(
                      x => x.key === this.updateIncidentFieldGroupName
                    )
                  ];
                for (const row of this.task.configurationTask
                  .fmConfigurationRow) {
                  if (row.name) {
                    const key = this.firstLowercase(row.name);
                    if (formGroup.fieldGroup) {
                      const formField =
                        formGroup.fieldGroup[
                          formGroup?.fieldGroup?.findIndex(x => x.key === key)
                        ];
                      if (formField.props?.required !== undefined) {
                        formField.props.required =
                          value === 'Abgeschlossen' ||
                          value === 'Nicht gestartet';
                      }
                    }
                  }
                }
              }
            },
          });
        },
      },
    },
  ];

  detailFields: IDetailFields[] = [
    {
      type: 'buffered-files',
      key: 'documentMetadata',
    },
    {
      type: 'typed-files',
      key: 'fmDocument',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private optionSetClient: OptionSetClient,
    private cd: ChangeDetectorRef,
    private translateDataService: TranslateDataService,
    private translateService: TranslateService,
    private taskClient: TaskClient,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private ibpdiHelperService: IbpdiHelperService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.translateService.instant('taskmodule.tickets.list.title'),
        onClick: () => {
          this.router.navigateByUrl('/tickets');
        },
      },
      {
        label: this.title,
      },
    ]);

    this.formFields = [...this.incidentInfomations, ...this.taskInformations];
    this.getItem(this.route.snapshot.paramMap.get('id'));

    this.route.queryParams.subscribe(params => {
      const tab = params['tab'];
      if (tab) {
        this.setActiveTab(tab);
      }
    });
  }

  setActiveTab(tabId: string) {
    const tab = this.tabs.find(t => t.id === tabId);
    if (tab) {
      this.activeTab = tab.id;
    }
  }

  getItem(id: string | null) {
    if (!id) {
      return;
    }
    this.isLoading = true;
    this.taskClient
      .getTask(id, true)
      .pipe(first())
      .subscribe({
        next: (
          data: ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping
        ) => {
          this.isLoading = false;
          this.cd.detectChanges();

          if (!data) {
            this.showErrorToastLoadTask();
            this.cd.detectChanges();
            return;
          }

          this.entity = data;
          this.task = data.entity;
          this.dataContactAddress = (data.entity.incident);
          this.extension = data.extension;

          this.fillIncidentAttributeForm();

          this.detailFields[0].fileMetadata = [
            {
              attributeName: 'incidentId',
              attributeValue: data.entity?.incidentId || 'error no incidentId',
            },
            {
              attributeName: 'taskId',
              attributeValue: data.entity?.taskId || 'error no taskId',
            },
            {
              attributeName: 'visibilityId',
              attributeValue: DocumentVisibility.InternAndContractors,
            },
          ];

          this.formModel = { ...this.formModel, ...data.entity };
          this.cd.detectChanges();
        },
        error: error => {
          this.isLoading = false;
          this.showErrorToastLoadTask();
          this.cd.detectChanges();
          console.log(error);
        },
      });
  }

  private showErrorToastLoadTask() {
    this.toastr.error(
      this.translateService.instant('crud.details.error.failed_to_load_msg'),
      this.translateService.instant('crud.details.error.failed_to_load_title'),
      { timeOut: 0 }
    );
  }

  private showErrorToastUpdateTask() {
    this.toastr.error(
      this.translateService.instant('crud.update.error.failed_to_update_msg'),
      this.translateService.instant('crud.update.error.failed_to_update_title'),
      { timeOut: 0 }
    );
  }

  private showSuccessToastUpdateTask() {
    this.toastr.success(
      this.translateService.instant('crud.update.success.sucess_update_msg'),
      this.translateService.instant('crud.update.success.sucess_update_title'),
      { timeOut: 0 }
    );
  }

  submit() {
    if (!this.task || !this.task.incidentId) {
      return;
    }

    this.isLoading = true;
    this.cd.detectChanges();

    if ((this.form.value as any).newStatus === TaskStatus.Abgeschlossen) {
      const taskClosedDialog: MatDialogRef<DialogComponent> = this.dialog.open(
        DialogComponent,
        {
          width: '500px',
          data: {
            type: 'okDismissButton',
            title: 'Aufgabe wird abgeschlossen',
            message:
              'Abgeschlossene oder stornierte Aufgaben können nicht mehr bearbeitet werden und werden in der Übersicht nicht mehr angezeigt.',
            okButtonText: 'Jetzt senden',
            dismissButtonText: 'Abbrechen',
          },
        }
      );

      taskClosedDialog
        .afterClosed()
        .pipe(first())
        .subscribe({
          next: res => {
            if (!res) {
              this.isLoading = false;
              this.cd.detectChanges();
              return;
            }
            this.send();
          },
        });
    } else {
      this.send();
    }
  }

  private send() {
    if (this.task && this.task.taskId) {
      const updateTaskDto: UpdateTaskDTO = {
        result: (this.form.value as any).result,
        status: (this.form.value as any).newStatus,
        taskId: this.task.taskId,
        userId: '<setByBackend>',
        updateIncidentDTO: this.generateUpdateIncidentDTO(),
      };
      this.taskClient
        .updateTask(updateTaskDto)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.showSuccessToastUpdateTask();
            this.cd.detectChanges();
          },
          error: err => {
            console.error(err);
            this.isLoading = false;
            this.showErrorToastUpdateTask();
            this.cd.detectChanges();
          },
        });
    }
  }

  private generateUpdateIncidentDTO(): UpdateIncidentDTO {
    const incidentDto: FmIncident = {
      name: this.task?.incident?.name,
      incidentId: this.task?.incident?.incidentId,
      pmIncidentExternalLetting: this.task?.incident?.pmIncidentExternalLetting,
      pmIncidentRealEstateDecision: this.task?.incident?.pmIncidentRealEstateDecision,
      pmIncidentRentalContractMgmtIdNavigation: this.task?.incident?.pmIncidentRentalContractMgmtIdNavigation,
      ...this.form.value[this.updateIncidentFieldGroupName],
    };
    return {
      incident: incidentDto,
      fieldMapping: this.extension || [],
    };
  }

  private fillIncidentAttributeForm() {
    if (
      !this.task ||
      !this.task.configurationTask ||
      !this.task.configurationTask.fmConfigurationRow ||
      !this.task.incident ||
      this.task.configurationTask.fmConfigurationRow.length <= 0
    ) {
      return;
    }

    // add chapter title
    this.formFields.push({
      type: 'chapter',
      props: {
        chapterTitle: this.translateService.instant(
          'taskmodule.task.view.view_incident_fields'
        ),
      },
    });

    // generate emtpy FormGroup for the incident fields
    const updateIncidentFormGroup: {
      key: string;
      fieldGroup: Array<any>;
    } & FormlyFieldConfig = {
      key: this.updateIncidentFieldGroupName,
      fieldGroup: [],
    };

    // populate FormGroup with proper typed input fields
    for (const row of this.task.configurationTask.fmConfigurationRow) {
      // push generaded field in formGroup
      updateIncidentFormGroup.fieldGroup.push(
        this.generateConfigurationRowField(row)
      );
    }

    // push the formGroup in the parent formFields
    this.formFields.push(updateIncidentFormGroup);
  }

  private firstLowercase(theString: string): string {
    return theString.charAt(0).toLowerCase() + theString.slice(1);
  }

  private generateConfigurationRowField(
    row: FmConfigurationRow
  ): FormlyFieldConfig {
    if (!this.extension || !row.name || !this.task || !this.task.incident) {
      throw new Error('An Error occured in generateField');
    }

    // get mapping for fieldType of the configuration row
    const incidentFieldMapping: IncidentFieldMapping | null =
      this.extension.find(e => e.ibpdiAttributeName === row.name) || null;

    if (!incidentFieldMapping) {
      throw new Error('missing incidentFieldMapping');
    }

    const label: string = this.translateDataService.translate(
      row.titleDe,
      row.titleEn
    );

    const key: string = this.firstLowercase(row.name);

    const fieldType: string = incidentFieldMapping
      ? incidentFieldMapping.ibpdiAttributeType
      : '';

    // prefill field value with value in incident
    var defaultValue = null;

    if (incidentFieldMapping.targetEntity == null) {
      defaultValue = this.task.incident[key] || null;
    }
    else if(incidentFieldMapping.targetEntity == 'affinis_incidentrealestatedecision')
    {
      defaultValue = this.task.incident.pmIncidentRealEstateDecision[key] || null;
    }
    else if(incidentFieldMapping.targetEntity == 'affinis_incidentexternalletting')
    {
      defaultValue = this.task.incident.pmIncidentExternalLetting[key] || null;
    }
    else if(incidentFieldMapping.targetEntity == 'affinis_incidentrentalcontractmanagement')
    {
      defaultValue = this.task.incident.pmIncidentRentalContractMgmtIdNavigation[key] || null;
    }

    if (fieldType.includes('String')) {
      return this.generateTextField(key, label, defaultValue);
    }
    if (fieldType.includes('DateTime')) {
      return this.generateDateField(key, label, defaultValue);
    }
    if (fieldType.includes('Int')) {
      return this.generateIntField(key, label, defaultValue);
    }
    if (fieldType.includes('Decimal')) {
      return this.generateDecimalField(key, label, defaultValue);
    }
    if (fieldType.includes('SysEnum')) {
      if (!incidentFieldMapping.optionSetType) {
        throw new Error('Missing optionSetType');
      }
      return this.generateOptionSetField(
        incidentFieldMapping.optionSetType,
        defaultValue,
        key,
        label
      );
    }
    if (fieldType.includes('Boolean')) {
      return this.generateBooleanField(key, label, defaultValue);
    }
    throw new Error(`Unknown fieldType: ${fieldType}`);
  }

  public generateTextField(
    key = '--',
    label = '--',
    defaultValue = '',
    maxLength = 100
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue,
      props: {
        label,
        maxLength,
        readonly: true,
        disabled: true,
      },
    };
  }

  private generateDateField(
    key = '--',
    label = '--',
    defaultValue = ''
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue: this.formatDateString(new Date(defaultValue)),
      props: {
        label,
        readonly: true,
        disabled: true,
      },
    };
  }

  private generateIntField(
    key = '--',
    label = '--',
    defaultValue = ''
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue,
      props: {
        type: 'number',
        label,
        readonly: true,
        disabled: true,
      },
    };
  }

  private generateDecimalField(
    key = '--',
    label = '--',
    defaultValue = ''
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue,
      props: {
        type: 'number',
        label,
        step: 0.01,
        readonly: true,
        disabled: true,
      },
    };
  }

  private generateOptionSetField(
    optionSetType: string,
    defaultValue: SysEnum,
    key = '--',
    label = '--'
  ): FormlyFieldConfig {
    // fixes requiredness of sysEnumTranslation.name
    if (defaultValue) {
      delete defaultValue.sysEnumTranslation;
    }

    return {
      key,
      type: 'select',
      defaultValue,
      wrappers: ['form-field'], // todo check this wrapper : , 'loading-suffix'],
      props: {
        loading: false,
        readonly: true,
        disabled: true,
        compareWith(o1: any, o2: any) {
          return o1.id === o2.id;
        },
        label,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig | undefined) => {
          if (!field || !field.props) {
            return;
          }
          const f = field.props;
          f.loading = true;
          f.readonly = true;
          f.disabled = true;
          this.cd.detectChanges();

          this.optionSetClient
            .getOptionSetByString(optionSetType)
            .pipe(
              first(),
              map(sysEnums => {
                return sysEnums.map((sysEnum: SysEnum) => {
                  delete sysEnum.sysEnumTranslation;
                  const s = { ...sysEnum };
                  return {
                    label:
                      this.ibpdiHelperService.getTranslatedEnumLable(sysEnum),
                    value: s,
                  };
                });
              })
            )
            .subscribe({
              next: options => {
                // sysEnumTranslation:null fixes requiredness of sysEnumTranslation.name
                if (defaultValue) {
                  delete defaultValue.sysEnumTranslation;
                }

                f.options = options;
                f.readonly = true;
                f.disabled = true;
                this.form.patchValue({
                  [this.updateIncidentFieldGroupName]: { [key]: defaultValue },
                });
                f.loading = false;
                f.readonly = true;
                f.disabled = true;
                this.cd.detectChanges();
              },
              error: error => {
                this.showErrorToastLoadTask();
                console.error(error);
              },
            });
        },
      },
    };
  }

  private generateBooleanField(
    key = '--',
    label = '--',
    defaultValue: boolean | null | string = null
  ): FormlyFieldConfig {
    if (defaultValue === '') {
      defaultValue = null;
    }

    const options: { id: number; label: string; value: boolean }[] = [
      { id: 1, label: this.translate('label__choice_yes'), value: true },
      { id: 2, label: this.translate('label__choice_no'), value: false },
    ];

    return {
      key,
      type: 'select',
      defaultValue,
      props: {
        options,
        label,
        readonly: true,
        disabled: true,
      },
    };
  }

  public translate(key: string) {
    return this.translateService.instant(`taskmodule.shared.${key}`);
  }

  private formatDateString(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
}
