import { inject, Injectable } from '@angular/core';
import { IncidentClient, MyIncidentsRequest } from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private incidentClient = inject(IncidentClient);

  getMyIncidents(request: MyIncidentsRequest) {
    return this.incidentClient.getMyIncidents(request);
  }

  getOrderAccessIncident(id: string) {
    return this.incidentClient.getOrderAccessIncident(id);
  }

  getBuildingFaultIncident(id: string) {
    return this.incidentClient.getBuildingFaultIncident(id);
  }

  getFurnitureOrderIncident(id: string) {
    return this.incidentClient.getFurnitureOrderIncident(id);
  }

  geRelocationIncident(id: string) {
    return this.incidentClient.getRelocationIncident(id);
  }
}
