import { Component } from '@angular/core';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
})
export class TaskDetailsComponent {
  constructor() {
    console.log('[TaskDetailsComponent] constructor');
  }
}
