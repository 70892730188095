import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MandantConfig } from '@core/services/mandant-config-service/mandant-config.service.types';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MandantConfigService {
  private mandantConfig$: BehaviorSubject<any> = new BehaviorSubject({});

  get mandant(): MandantConfig {
    return this.mandantConfig$.value;
  }

  constructor(private http: HttpClient) {}

  public async loadMandantConfig() {
    const pathToAssets = `assets`;
    const url = '/assets/config.json';
    return this.http.get(url).pipe(
      map((response: MandantConfig) => {
        const mandantConfig = this.replaceRootUrl(response, pathToAssets || '');
        this.mandantConfig$.next(mandantConfig);
        return mandantConfig;
      })
    );
  }

  private replaceRootUrl(
    config: MandantConfig,
    absolutPath: string
  ): MandantConfig {
    Object.keys(config).forEach(key => {
      config[key] =
        typeof config[key] === 'string'
          ? config[key].replace('%storageRoot%', absolutPath)
          : config[key];
    });
    return config;
  }
}
