import { APP_INITIALIZER } from '@angular/core';
import { StartupService } from '@core/services/startup-service/startup.service';

function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}

export const StartupServiceInitializer = {
  provide: APP_INITIALIZER,
  useFactory: StartupServiceFactory,
  deps: [StartupService],
  multi: true,
};
