import { Component } from '@angular/core';
import { TicketStatusItemBaseComponent } from '@features/tickets/components/ticket-status-item/base/ticket-status-item';
import { IDetailFields } from '@shared/components/details/detail-fields/models/detail-fields.model';

@Component({
  selector: 'app-ticket-status-item-telekom',
  templateUrl:
    '../../../../../app/features/tickets/components/ticket-status-item/base/ticket-status-item.html',
  styleUrls: [],
})
export class TicketStatusItemTelekomComponent extends TicketStatusItemBaseComponent {
  override cardStatus = {
    header: {
      title: {
        label: this.title,
      },
    },
  };

  override detailFields: Array<IDetailFields> = [
    {
      type: 'field',
      key: 'name',
      label: 'Kurzbeschreibung des Anliegens',
      break: false,
    },
    {
      type: 'field',
      key: 'incidentClass.incidentClass',
      label: 'Art des Anliegens',
      break: false,
    },
    {
      type: 'textarea',
      key: 'description',
      label: 'Weitere Angaben zu Ihrem Anliegen',
      break: false,
    },
    {
      type: 'field',
      key: 'statusReasonNavigation',
      label: 'Status',
      break: false,
      transformValueFn: (field: any) => {
        return this.ibpdiHelperService.getTranslatedEnumLable(field);
      },
    },
    {
      type: 'field',
      key: 'ticketNumber',
      label: 'Ticketnummer',
      css: 'font-family: monospace; font-size:15px',
      break: false,
    },
    {
      type: 'buffered-files',
      key: 'documentMetadata',
    },
    {
      type: 'files',
      key: 'fmDocument',
      label: 'Anhänge',
      break: false,
      fileMetadata: [
        {
          attributeName: 'incidentId',
          attributeValue: 'missing incidentId',
        },
      ],
    },
  ];
}
