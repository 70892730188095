<form [formGroup]="form" [hidden]="filterVisible === false">
  <div>
    <div>
      <formly-form
        [form]="form"
        [fields]="filterFields"
        [model]="formModel"></formly-form>
    </div>
    <div
      [style.padding-top.px]="5"
      *ngIf="!triggerViaChange"
      class="button-container">
      <su-button
        [label]="'crud.applyFilter' | translate"
        (onClick)="search()"
        class="space-button"
        [skin]="'accent'"></su-button>
      <su-button
        [label]="'crud.resetFilter' | translate"
        (onClick)="resetFilter()"
        class="space-button"
        [skin]="'tertiary'"></su-button>
    </div>
  </div>
</form>
