import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { checkIfEmailIdentical } from '@shared/utils/email-validator';
import {
  RegexpEmail,
  RegexpLocationname,
  RegexpStreetname,
  RegexpZipCodeDE,
} from '@shared/utils/regex';
import {
  federalList,
  newSalutation,
  phoneConstraints,
  phoneValidationMessages,
} from './ticket-created-shared.field-configs';

export function concerningContact(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      // Besitzerkontakt
      expressions: {
        hide: (field: any) => {
          return (
            field.model?.concerningLocation?.powerOfDisposition === 'Andere' ||
            !field.model?.concerningLocation?.powerOfDisposition ||
            !field.model?.auxAddress
          );
        },
      },
      wrappers: [],
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__contact_owner'
            ),
            chapterText: translateService.instant(
              'ticket.create.p__info_contact_owner'
            ),
          },
          expressionProperties: {
            'props.visibleInConfirm':
              'field.model?.concerningLocation?.powerOfDisposition!=="Andere" && field.model?.auxAddress',
          },
        },
        {
          key: 'concerningContact',
          fieldGroup: [
            ...newSalutation(translateService),
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'firstname',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__firstName_owner'
                    ),
                    visibleInConfirm: true,
                    maxLength: 50,
                    required: true,
                  },
                },
                {
                  type: 'input',
                  key: 'lastname',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__lastName_owner'
                    ),
                    maxLength: 50,
                    visibleInConfirm: true,
                    required: true,
                  },
                },
              ],
            },
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'phonenumber',
                  defaultValue: '0049',
                  wrappers: ['tooltip', 'form-field'],
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__phone_owner'
                    ),
                    visibleInConfirm: true,
                    required: true,
                    ...phoneConstraints,
                    tooltip: translateService.instant(
                      'ticket.create.tooltip__phone'
                    ),
                  },
                  validation: phoneValidationMessages(translateService),
                },
              ],
            },
            {
              type: 'flex-layout',

              validators: {
                fieldMatch: {
                  expression: checkIfEmailIdentical,
                  message: translateService.instant(
                    'formErrors.email.not_identical'
                  ),
                  errorPath: 'confirmEmail',
                },
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'email',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__email_owner'
                    ),
                    pattern: RegexpEmail,
                    visibleInConfirm: true,
                    required: true,
                    maxLength: 100,
                  },
                  validation: {
                    messages: {
                      pattern: () =>
                        translateService.instant('formErrors.email.pattern'),
                    },
                  },
                },
                {
                  type: 'input',
                  key: 'confirmEmail',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__confirm_email_owner'
                    ),
                    required: true,
                  },
                },
              ],
            },
            {
              key: 'address',
              fieldGroup: [
                {
                  type: 'flex-layout',

                  fieldGroup: [
                    {
                      type: 'input',
                      key: 'street',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__street_owner'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        maxLength: 250,
                        minLength: 3,
                        pattern: RegexpStreetname,
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant(
                            'formErrors.street.pattern'
                          ),
                          minlength: err =>
                            translateService.instant(
                              'formErrors.street.minLength',
                              {
                                length: err.requiredLength,
                              }
                            ),
                        },
                      },
                      expressions: {
                        'props.visibleInConfirm': 'true',
                      },
                    },
                    {
                      type: 'input',
                      key: 'zipcode',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__zipcode_owner'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        pattern: RegexpZipCodeDE,
                        minLength: 5,
                        maxLength: 20,
                      },
                      expressions: {
                        'props.visibleInConfirm': 'true',
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant(
                            'formErrors.zipcode.pattern'
                          ),
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'flex-layout',

                  fieldGroup: [
                    {
                      type: 'input',
                      key: 'city',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__city_owner'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        minLength: 3,
                        maxLength: 80,
                        pattern: RegexpLocationname,
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant(
                            'formErrors.city.pattern'
                          ),
                          minlength: err =>
                            translateService.instant(
                              'formErrors.city.minLength',
                              {
                                length: err.requiredLength,
                              }
                            ),
                        },
                      },
                      expressions: {
                        'props.visibleInConfirm': 'true',
                      },
                    },
                    {
                      type: 'select',
                      key: 'state',
                      props: {
                        label: translateService.instant(
                          'ticket.create.label__state_owner'
                        ),
                        visibleInConfirm: true,
                        required: true,
                        options: federalList(translateService.currentLang),
                      },
                      expressions: {
                        'props.visibleInConfirm': 'true',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
