<div class="content-body">
  <div class="row-container">
    <div class="text-col">
      <h2>Datenschutzhinweise</h2>
      <br />
      <p>
        Der Schutz Ihrer persönlichen Daten hat für die Deutsche Telekom AG
        (Group Supply Services - Real Estate Management) einen hohen
        Stellenwert. Es ist uns wichtig, Sie darüber zu informieren, welche
        persönlichen Daten erfasst werden, wie diese verwendet werden und welche
        Gestaltungsmöglichkeiten Sie dabei haben.
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >1) Welche Daten werden erfasst, wie werden sie verwendet und wie
          lange werden sie gespeichert?
        </strong>
      </p>
      <p>
        a) Erforderliche Verarbeitungen bei der Erbringung des digitalen
        Dienstes (Art. 6 Abs. 1b DSGVO, §25 Abs. 2 Nr. 2 TDDDG)
      </p>
      <span style="display: block; padding-left: 20px">
        ● Bei der Absendung des Kontaktformulars werden, vorbehaltlich Ihrer
        Einwilligung, folgende Daten gespeichert:<br />
        <p style="display: block; padding-left: 40px">
          ∘ Vor- und Nachname <br />
          ∘ Kontaktdaten (Ggf. Postadresse, Telefonnummer, E-Mail-Adresse)
          <br />
          ∘ Standortdaten der Liegenschaft <br />
          ∘ Ihre Bestätigung die Daten zu speichern und dass Sie die
          Datenschutz- und Einwilligungserklärung gelesen haben und akzeptieren
          <br />
        </p>
        <span style="display: block; padding-left: 20px">
          Zweck der Verarbeitung Ihrer Daten ist die Kontaktaufnahme im Rahmen
          Ihres immobilienspezifischen Anliegens (u. a. Baulast, Dienstbarkeit,
          Löschbewilligung, Miet-/Kaufanfrage, Öffentlich-rechtliches Verfahren,
          Sonstiges) und dessen Bearbeitung. Ihre Daten werden von Personen und
          Organisationen sowie Dienstleistern der Telekom verarbeitet, um mit
          Ihnen in Kontakt zu treten und Ihnen Angebote zu Ihrer Anfrage zu
          unterbreiten.
        </span>
      </span>
      <br />
      <p>
        b) Verarbeitungen bei der Erbringung des digitalen Dienstes, die auf
        Basis des berechtigten Interesses erfolgen (Art. 6 Abs. 1 f DSGVO, §25
        Abs. 2 Nr. 2 TDDDG)
      </p>
      <span style="display: block; padding-left: 20px">
        Wenn Sie unseren digitalen Dienst nutzen, verzeichnen unsere Server den
        Domain-Namen oder die IP- Adresse Ihres Endgerätes sowie weitere Daten,
        wie z. B. die angefragten Inhalte, den Antwort-Code, Generische User-ID,
        welche nicht rückverfolgbar ist, aufgerufene Unterseiten. Sämtliche
        Daten sind nicht personenbezogene Daten. Die protokollierten Daten
        werden für Zwecke der Datensicherheit, insbesondere zur Abwehr von
        Angriffsversuchen auf unseren Server verwendet. Die Daten werden zudem
        zum Monitoring des Webseite Traffics genutzt. Dieses wird durch
        statistische Auswertungen anonymisierter Datensätze durchgeführt.
      </span>
      <br />
      <p>
        <strong
          >2) Wird mein Nutzungsverhalten ausgewertet, z. B. für Werbung oder
          Tracking?
        </strong>
      </p>
      <p>
        Wir möchten, dass Sie unsere digitalen Dienste gerne nutzen und unsere
        Produkte und Dienste in Anspruch nehmen. Damit Sie die Produkte finden,
        die Sie interessieren und wir unseren digitalen Dienst nutzerfreundlich
        ausgestalten können, analysieren wir anonymisiert Ihr Nutzungsverhalten.
        Nachfolgend informieren wir Sie allgemein über die verschiedenen Zwecke
        der Verarbeitungen.
      </p>
      <br />
      <p>a) Basis-Funktionalität des digitalen Dienstes</p>
      <p>
        Diese Verarbeitungen sind immer aktiv und notwendig, damit der digitale
        Dienst richtig funktioniert.
      </p>
      <p>
        <strong><u>Funktional:</u></strong>
      </p>
      <p>
        Diese Verarbeitungen sind notwendig, damit Sie durch den digitalen
        Dienst navigieren und wesentliche Funktionen nutzen können. Sie
        ermöglichen Grundfunktionen, wie die Bestellabwicklung im Online-Shop
        und den Zugriff auf gesicherte Bereiche des digitalen Dienstes. Zudem
        dienen sie der anonymen Auswertung des Nutzerverhaltens, die von uns
        verwendet werden, um unseren digitalen Dienst stetig für Sie
        weiterzuentwickeln. Rechtsgrundlage für diese Verarbeitungen ist §25
        Abs. 2 Nr. 2 TDDDG, Art. 6 Abs. 1b DSGVO bzw. bei Drittstaaten Art. 44
        ff. DSGVO.
      </p>
      <br />
      <div style="overflow-x:auto;">
        <table>
          <thead>
            <tr>
              <th>Verarbeitungs-zweck nach Consent Kategorie</th>
              <th>
                Verarbeitendes Unternehmen mit Firmenadresse / Datenempfänger
              </th>
              <th>Genutzte Produkte/kurze Beschreibung des genutzten Services</th>
              <th>Beschreibung des konkreten Verarbeitungs-zwecks</th>
              <th>Verantwortlichkeiten</th>
              <th>Verarbeitete Daten</th>
              <th>Speicherdauer</th>
              <th>Rechtsgrundlage (Verarbeitung)</th>
              <th>Verarbeitung in Drittländern</th>
              <th>Rechtsgrundlage (Drittländer)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Funktional</td>
              <td>Telekom</td>
              <td>Ai_session</td>
              <td>Anonyme statistische Nutzung</td>
              <td>Telekom</td>
              <td>Keine personenbezogenen Daten werden verarbeitet</td>
              <td>Session</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>Funktional</td>
              <td>Telekom</td>
              <td>AI-User</td>
              <td>Anonyme statistische Nutzung für Nutzeranzahl</td>
              <td>Telekom</td>
              <td>Keine personenbezogenen Daten werden verarbeitet</td>
              <td>1 Jahr</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <p>b) Spotify</p>
      <p>
        Auf der „Über uns“ Seite der Telekom Real Estate Webseite ist im unteren
        Bereich die Rubrik „Jetzt reinhören“ enthalten. Durch Anklicken des
        dortigen Buttons werden Sie direkt an Spotify weitergeleitet. Sie können
        sich jederzeit über die Datenverarbeitung von Spotify AB, Regeringsgatan
        19, 111 53 Stockholm, Schweden unter https:
        https://www.spotify.com/de/legal/privacy-policy/ informieren und dieser
        widersprechen.
      </p>
      <br />
      <p>c) Drittanbieter</p>
      <p>
        In den jeweiligen Immobilienangeboten können Links enthalten sein, die
        auf Internetseiten Dritter verweisen. Mit dem Anklicken dieser Links
        werden Sie direkt auf die Dritt-Seiten weitergeleitet. Die jeweiligen
        Drittanbieter sind gemäß Art. 4 Nr. 7 DSGVO jeweils für die
        Datenverarbeitung verantwortlich sind. Vorbehaltlich Ihrer Einwilligung
        baut Ihr Browser eine direkte Verbindung zu dem Server des jeweiligen
        Drittanbieters auf. Hierbei wird von dem Drittanbieter Ihre IP-Adresse
        verarbeitet, die für den Aufbau der Verbindung zum Server des
        Drittanbieters und dem Ausspielen der Inhalte erforderlich ist.
        Informationen zur Datenverarbeitung durch den jeweiligen Drittanbieter
        können Sie der Datenschutzerklärung des jeweiligen Drittanbieters
        entnehmen.
      </p>
      <br />
      <p>d) Haftungsausschluss</p>
      <p>
        Die von der Deutschen Telekom AG veröffentlichten Beiträge sind mit
        größter Sorgfalt recherchiert. Dennoch können weder die Deutsche Telekom
        AG noch deren Lieferanten oder Anbieter für die Richtigkeit eine Gewähr
        übernehmen. Die Deutsche Telekom AG weist ausdrücklich darauf hin, dass
        die veröffentlichten Artikel, Daten und Informationen keine Aufforderung
        zum Kauf, Verkauf oder zur Miete von Objekten oder Rechten darstellen.
        Sie ersetzen auch nicht eine fachliche Beratung.
        <br /><br />
        Die Deutsche Telekom AG ist für Inhalte externer Seiten, auf die im
        Rahmen dieses Onlineangebotes verwiesen wird, nicht verantwortlich und
        lehnt jegliche Haftung für deren Inhalte ab.
      </p>
      <br />
      <p>
        <strong
          >3) Wo finde ich weitere Informationen zum Datenschutz bei der
          Telekom?
        </strong>
      </p>
      <p>
        Weitere Informationen, auch zum Datenschutz in speziellen Produkten,
        erhalten Sie unter
        <a href="https://www.telekom.de/datenschutzhinweise"
          >www.telekom.de/datenschutzhinweise</a
        >
        und unter
        <a href="https://www.telekom.com/datenschutz"
          >www.telekom.com/datenschutz</a
        >.
      </p>
      <br />
      <p>
        <strong>4) Welche Rechte habe ich?</strong><br />
        Sie haben das Recht,
      </p>
      <p>
        a) <strong>Auskunft</strong> zu verlangen zu Kategorien der
        verarbeiteten Daten, Verarbeitungszwecken, etwaigen Empfängern der
        Daten, der geplanten Speicherdauer (Art. 15 DSGVO);
      </p>
      <p>
        b) die <strong>Berichtigung</strong> bzw. Ergänzung unrichtiger bzw.
        unvollständiger Daten zu verlangen (Art. 16 DSGVO);
      </p>
      <p>
        c) eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu
        <strong>widerrufen </strong>(Art. 7 Abs. 3 DSGVO);
      </p>
      <p>
        d) einer Datenverarbeitung, die aufgrund eines berechtigten Interesses
        erfolgen soll, aus Gründen zu <strong>widersprechen, </strong>die sich
        aus Ihrer besonderen Situation ergeben (Art 21 Abs. 1 DSGVO), unter
        Angabe dieser Gründe jederzeit für die Zukunft zu widersprechen. Einer
        Datenverarbeitung für Zwecke der Direktwerbung können Sie jederzeit ohne
        Angabe dieser Gründe widersprechen (Art. 21 Abs. 2, 3 DSGVO);
      </p>
      <p>
        e) in bestimmten Fällen im Rahmen des Art. 17 DSGVO die
        <strong>Löschung</strong> von Daten zu verlangen - insbesondere soweit
        die Daten für den vorgesehenen Zweck nicht mehr erforderlich sind bzw.
        unrechtmäßig verarbeitet werden, oder Sie Ihre Einwilligung gemäß oben
        (c) widerrufen oder einen Widerspruch gemäß oben (d) erklärt haben;
      </p>
      <p>
        f) unter bestimmten Voraussetzungen die
        <strong>Einschränkung</strong> von Daten zu verlangen, soweit eine
        Löschung nicht möglich bzw. die Löschpflicht streitig ist (Art. 18
        DSGVO);
      </p>
      <p>
        g) auf <strong>Datenübertragbarkeit</strong>, d.h. Sie können Ihre
        Daten, die Sie uns bereitgestellt haben, in einem gängigen
        maschinenlesbaren Format, wie z. B. CSV, erhalten und ggf. an andere
        übermitteln (Art. 20 DSGVO;)
      </p>
      <p>
        h) sich bei der zuständigen <strong>Aufsichtsbehörde</strong> über die
        Datenverarbeitung zu beschweren (für Telekommunikationsverträge:
        Bundesbeauftragter für den Datenschutz und die Informationsfreiheit; im
        Übrigen: Landesbeauftragte für den Datenschutz und die
        Informationsfreiheit Nordrhein-Westfalen).
      </p>
      <br />
      <p>
        <strong>5) An wen gibt die Telekom meine Daten weiter?</strong>
      </p>
      <p>
        An <strong>Auftragsverarbeiter</strong>
        , das sind Unternehmen, die wir im gesetzlich vorgesehenen Rahmen mit
        der Verarbeitung von Daten beauftragen, Art. 28 DSGVO (Dienstleister,
        Erfüllungsgehilfen). Die Telekom bleibt auch in dem Fall weiterhin für
        den Schutz Ihrer Daten verantwortlich. Wir beauftragen Unternehmen
        insbesondere in folgenden Bereichen: IT, Vertrieb, Marketing, Finanzen,
        Beratung, Kundenservice, Personalwesen, Logistik, Druck.
      </p>
      <p>
        An <strong>Kooperationspartner</strong>
        , die in eigener Verantwortung Leistungen für Sie bzw. im Zusammenhang
        mit Ihrem Telekom-Vertrag erbringen. Dies ist der Fall, wenn Sie
        Leistungen solcher Partner bei uns beauftragen oder wenn Sie in die
        Einbindung des Partners einwilligen oder wenn wir den Partner aufgrund
        einer gesetzlichen Erlaubnis einbinden.
      </p>
      <p>
        <strong>Aufgrund gesetzlicher Verpflichtung:</strong>
        In bestimmten Fällen sind wir gesetzlich verpflichtet, bestimmte Daten
        an die anfragende staatliche Stelle zu übermitteln.
      </p>
      <br />
      <p>
        <strong>6) Wo werden meine Daten verarbeitet?</strong>
      </p>
      <p>
        Ihre Daten werden in Deutschland und im europäischen Ausland
        verarbeitet. Im Übrigen gilt: Findet eine Datenverarbeitung in
        Drittstaaten statt, geschieht dies, soweit Sie hierin ausdrücklich
        eingewilligt haben oder es für unsere Leistungserbringung Ihnen
        gegenüber erforderlich ist oder es gesetzlich vorgesehen ist (Art.49
        DSGVO). ).
      </p>
      <p>
        Eine Verarbeitung Ihrer Daten in Drittstaaten erfolgt nur, soweit durch
        bestimmte Maßnahmen sichergestellt ist, dass hierfür ein angemessenes
        Datenschutzniveau besteht (z. B. Angemessenheitsbeschluss der
        EU-Kommission oder sog. geeignete Garantien, Art. 44ff. DSGVO, (Vgl.
        <a
          [href]="'https://www.telekom.de/datenschutzhinweise/download/060.pdf'"
          target="_blank"
          >https://www.telekom.de/datenschutzhinweise/download/060.pdf</a
        >).
      </p>
      <br />
      <p>
        <strong
          >7) Wer ist verantwortlich für die Datenverarbeitung? Wer ist mein
          Ansprechpartner, wenn ich Fragen zum Datenschutz bei der Telekom habe?
        </strong>
      </p>
      <p>
        Datenverantwortliche ist die Deutsche Telekom AG (Group Supply Services
        - Real Estate Management) Sternengasse 14-16 50676 Köln. Bei Fragen
        können Sie sich an unseren Kundenservice wenden oder an unseren
        Datenschutzbeauftragten, Herrn Dr. Claus D. Ulmer, Friedrich-Ebert-Allee
        140, 53113 Bonn,
        <a href="mailto:datenschutz@telekom.de"
          >da​ten​schutz​&#64;​te​le​kom​.​de</a
        >.
      </p>
      <br />
      <p>Stand der Datenschutzhinweise 09.10.2024</p>
    </div>
    <div class="image-container">
      <img src="assets/images/undraw_dataprotection.svg" />
    </div>
  </div>
</div>
