import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
import { BuildingMaintenanceViewModel } from '@core/api';
import { EditBuildService } from '@core/services/edit-building/edit-building.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class BuildingResolver {
  private editBuildService = inject(EditBuildService);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private translateService = inject(TranslateService);

  resolve(route: ActivatedRouteSnapshot): Observable<{
    buildingId: string;
    building: BuildingMaintenanceViewModel;
  }> {
    const buildingId = route.paramMap.get('buildingId');
    if (!buildingId) {
      this.handleNavigationError();
      return of(null);
    }

    return this.editBuildService
      .getBuildingMaintenanceInformation(buildingId)
      .pipe(
        filter(buildings => !!buildings),
        map(building => ({ buildingId, building })),
        catchError(error => {
          if (error.status === 404 || error.status === 500) {
            this.handleNavigationError();
            return of(null);
          } else {
            throw error;
          }
        })
      );
  }

  private handleNavigationError() {
    this.toastr.error(
      this.translateService.instant('roles_handle_resolver.not_found')
    );
    this.router.navigate(['/']);
  }
}
