export interface MandantConfig {
  name: string;
  id: string;
  brandingTitle: string;
  browserTitle?: string;
  primary: string;
  accent: string;
  backgroundColor?: string;
  backgroundUrl: string;
  logo: string;
  favicon: string;
  translationUrl: string;
  menuUrl: string;
  faviconUrl: string;
  customCssUrl: string;
  impressumUrl: string;
  dataPrivacyUrl?: string;
  defaultRoute: string;
  defaultRouteAuthenticated?: string;
  showLogin: boolean;
  hideExposeFilterForOccupation?: boolean;
  profile?: {
    showPasswordReset?: boolean;
  };
  cookieConsent?: {
    hrefDe: string;
    hrefEn?: string;
  };
  hideFooter?: boolean;
  topMenuLengthMax?: number;
  showPermanentSideMenu?: boolean;
  mobileSideMenuPosition?: string;
  hasMultiDownload?: boolean;
  maxFileSizeInMB?: number;
  maxTotalUploadInMB?: number;
}

export enum Mandant {
  telekom = 'telekom',
  brst = 'brst',
  affinis = 'affinis',
  bluebird = 'bluebird',
  tesla = 'tesla',
  molewo = 'molewo',
  pasm = 'pasm',
}
