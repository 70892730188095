<su-headline h="h1" skin="primary">{{
  'menu.taskmodule.component.tickets' | translate
}}</su-headline>

<div class="row-chips">
  <su-chip [items]="chips" (onRemove)="handleOnRemoveChip($event)"></su-chip>
</div>

<ng-template #toolbarTpl>
  <su-slot-controls
    [mainTLabel]="'crud.filter' | translate"
    [buttons]="controlButtons"
    [chips]="chips"
    [menuItems]="menuItems"
    [dialogData]="dialogData"
    (onCloseDialog)="onCloseDialog($event)"
    [modal]="modalComponent"></su-slot-controls>
</ng-template>

<mtx-grid
  [columnResizable]="true"
  [columns]="columns"
  [data]="data.items"
  [length]="data.totalCount"
  [pageOnFront]="false"
  [sortOnFront]="config.grid.filter.sortOnFront"
  [loading]="isLoading"
  [rowStriped]="true"
  [noResultText]="'grid.no_results' | translate"
  (rowClick)="clickRowWrapper($event)"
  [rowHover]="false"
  [cellSelectable]="false"
  [pageSizeOptions]="[5, 10, 25, 50, 100]"
  (sortChange)="sortChange($event)"
  (page)="getNextPage($event)"
  [pageSize]="config.grid.paging.pageSize"
  [pageIndex]="config.grid.paging.pageIndex"
  [showPaginator]="config.grid.paging.showPaginator"
  [columnMenuButtonText]="'grid.columns_shown' | translate"
  [columnHideable]="true"
  [columnHideableChecked]="'show' | translate"
  columnMenuButtonClass="col-button"
  [showSidebar]="true"
  [expandable]="true"
  (expansionChange)="getTasks($event)"
  [expansionTemplate]="expansionTemplate"
  [showToolbar]="true"
  [columnSortable]="true"
  [showColumnMenuButton]="true"
  columnMenuButtonType="flat"
  [columnPinnable]="false"
  [toolbarTemplate]="toolbarTpl"
  (columnChange)="saveColumnVisibility()">
</mtx-grid>

<ng-template #expansionTemplate let-row>
  <div *ngIf="!sysUser">
    <div *ngFor="let task of row.tasks">
      <!------ Meine Tickets mit aktiven Aufgaben----------------------------------------------------------------------------------->
      <div
        class="expansion-item"
        *ngIf="
          $any(this.form.value).ticketTaskSelection ===
          'MyTicketsWithActiveTasks'
        ">
        <div
          *ngIf="
            task.isActive &&
            task.status !== 'Abgeschlossen' &&
            task.status !== 'Storniert' &&
            task.processorId === user.contactId
          ">
          <ng-container>
            <p
              *ngIf="task.organisationId !== user?.organisationId; else first"
              class="disabled space-row">
              {{ task.subject }} ({{ task.status }})
              <span *ngIf="task.processorName">
                - {{ 'taskmodule.shared.label__editor' | translate }}:
                {{ task.processorName }}
              </span>
            </p>
          </ng-container>
          <ng-template #first>
            <p
              *ngIf="
                task.status === 'Abgeschlossen' || task.status === 'Storniert';
                else second
              "
              class="disabled-completed">
              {{ task.subject }} ({{ task.status }})
              <span *ngIf="task.processorName">
                - {{ 'taskmodule.shared.label__editor' | translate }}:
                {{ task.processorName }}
              </span>
            </p>
          </ng-template>
          <ng-template #second>
            <p
              *ngIf="
                task.status !== 'Abgeschlossen' && task.status !== 'Storniert'
              "
              class="enabled space-row"
              (click)="clickExpandedRow(task)">
              {{ task.subject }} ({{ task.status }})
              <span *ngIf="task.processorName">
                - {{ 'taskmodule.shared.label__editor' | translate }}:
                {{ task.processorName }}
              </span>
            </p>
          </ng-template>
        </div>
      </div>
      <!------Tickets mit aktiven Aufgaben----------------------------------------------------------------------------------->
      <div
        class="expansion-item"
        *ngIf="
          $any(this.form.value).ticketTaskSelection === 'TicketsWithActiveTasks'
        ">
        <div
          *ngIf="
            task.isActive &&
            task.status !== 'Abgeschlossen' &&
            task.status !== 'Storniert'
          ">
          <ng-container>
            <p
              *ngIf="task.organisationId !== user?.organisationId; else first"
              class="disabled space-row">
              {{ task.subject }} ({{ task.status }})
              <span *ngIf="task.processorName">
                - {{ 'taskmodule.shared.label__editor' | translate }}:
                {{ task.processorName }}
              </span>
              <span *ngIf="task.organisationName">
                - Firma:
                {{ task.organisationName }}
              </span>
            </p>
          </ng-container>
          <ng-template #first>
            <p
              *ngIf="
                task.status === 'Abgeschlossen' || task.status === 'Storniert';
                else second
              "
              class="disabled-completed">
              {{ task.subject }} ({{ task.status }})
              <span *ngIf="task.processorName">
                - {{ 'taskmodule.shared.label__editor' | translate }}:
                {{ task.processorName }}
              </span>
              <span *ngIf="task.organisationName">
                - Firma:
                {{ task.organisationName }}
              </span>
            </p>
          </ng-template>
          <ng-template #second>
            <div class="expandendDivs">
              <div>
                <su-button
                  skin="primary"
                  icon="user"
                  [ngClass]="'contactButton special-button'"
                  (onClick)="openContact($event, task)">
                </su-button>
              </div>
              <div>
                <p
                  *ngIf="
                    task.status !== 'Abgeschlossen' &&
                    task.status !== 'Storniert'
                  "
                  class="enabled space-row"
                  (click)="clickExpandedRow(task)">
                  {{ task.subject }} ({{ task.status }})
                  <span *ngIf="task.processorName">
                    - {{ 'taskmodule.shared.label__editor' | translate }}:
                    {{ task.processorName }}
                  </span>
                  <span *ngIf="task.organisationName">
                    - Firma:
                    {{ task.organisationName }}
                  </span>
                </p>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <!------Alle Tickets--------------------------------------------------------------------------------------------------->
      <div
        class="expansion-item"
        *ngIf="$any(this.form.value).ticketTaskSelection === 'All'">
        <ng-container>
          <p
            *ngIf="task.organisationId !== user?.organisationId; else first"
            class="disabled space-row">
            {{ task.subject }} ({{ task.status }})
            <span *ngIf="task.processorName">
              - {{ 'taskmodule.shared.label__editor' | translate }}:
              {{ task.processorName }}
            </span>
            <span *ngIf="task.organisationName">
              - Firma:
              {{ task.organisationName }}
            </span>
          </p>
        </ng-container>
        <ng-template #first>
          <p
            *ngIf="
              task.status === 'Abgeschlossen' ||
                (task.status === 'Storniert' &&
                  task.organisationId === user?.organisationId);
              else second
            "
            class="enabled-completed-cancelled space-row"
            (click)="clickExpandedRow(task)">
            {{ task.subject }} ({{ task.status }})
            <span *ngIf="task.processorName">
              - {{ 'taskmodule.shared.label__editor' | translate }}:
              {{ task.processorName }}
            </span>
            <span *ngIf="task.organisationName">
              - Firma:
              {{ task.organisationName }}
            </span>
          </p>
        </ng-template>
        <ng-template #second>
          <p
            *ngIf="task.status === 'Abgeschlossen'; else third"
            class="disabled-completed space-row">
            {{ task.subject }} ({{ task.status }})
            <span *ngIf="task.processorName">
              - {{ 'taskmodule.shared.label__editor' | translate }}:
              {{ task.processorName }}
            </span>
            <span *ngIf="task.organisationName">
              - Firma:
              {{ task.organisationName }}
            </span>
          </p>
        </ng-template>
        <ng-template #third>
          <p
            *ngIf="task.status !== 'Abgeschlossen'"
            class="enabled space-row"
            (click)="clickExpandedRow(task)">
            {{ task.subject }} ({{ task.status }})
            <span *ngIf="task.processorName">
              - {{ 'taskmodule.shared.label__editor' | translate }}:
              {{ task.processorName }}
            </span>
            <span *ngIf="task.organisationName">
              - Firma:
              {{ task.organisationName }}
            </span>
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div *ngIf="sysUser">
    <div *ngFor="let task of row.tasks">
      <!------GSUS: Tickets mit aktiven Aufgaben----------------------------------------------------------------------------------->
      <div
        class="expansion-item"
        *ngIf="
          $any(this.form.value).ticketTaskSelection ===
            'TicketsWithActiveTasks' && checkOrganisation(task)
        ">
        <div
          *ngIf="
            task.isActive &&
            task.status !== 'Abgeschlossen' &&
            task.status !== 'Storniert'
          ">
          <ng-container>
            <div class="expandendDivs">
              <div>
                <su-button
                  skin="primary"
                  icon="user"
                  [ngClass]="'contactButton special-button'"
                  (onClick)="openContactDetails($event, task)">
                </su-button>
              </div>
              <p class="enabled space-row">
                {{ task.subject }} ({{ task.status }})
                <span *ngIf="task.processorName">
                  - {{ 'taskmodule.shared.label__editor' | translate }}:
                  {{ task.processorName }}
                </span>
                <span *ngIf="task.organisationName">
                  - Firma:
                  {{ task.organisationName }}
                </span>
              </p>
            </div>
          </ng-container>
        </div>
      </div>
      <!------GSUS: Alle Tickets--------------------------------------------------------------------------------------------------->
      <div
        class="expansion-item"
        *ngIf="$any(this.form.value).ticketTaskSelection === 'All'">
        <ng-container>
          <p
            *ngIf="!checkOrganisation(task); else first"
            class="disabled space-row">
            {{ task.subject }} ({{ task.status }})
            <span *ngIf="task.processorName">
              - {{ 'taskmodule.shared.label__editor' | translate }}:
              {{ task.processorName }}
            </span>
            <span *ngIf="task.organisationName">
              - Firma:
              {{ task.organisationName }}
            </span>
          </p>
        </ng-container>
        <ng-template #first>
          <p
            *ngIf="
              task.status === 'Abgeschlossen' ||
                (task.status === 'Storniert' && checkOrganisation(task));
              else second
            "
            class="enabled-completed-cancelled space-row"
            (click)="clickExpandedRowGSUS(task)">
            {{ task.subject }} ({{ task.status }})
            <span *ngIf="task.processorName">
              - {{ 'taskmodule.shared.label__editor' | translate }}:
              {{ task.processorName }}
            </span>
            <span *ngIf="task.organisationName">
              - Firma:
              {{ task.organisationName }}
            </span>
          </p>
        </ng-template>
        <ng-template #second>
          <p
            *ngIf="task.status !== 'Abgeschlossen'"
            class="enabled space-row"
            (click)="clickExpandedRowGSUS(task)">
            {{ task.subject }} ({{ task.status }})
            <span *ngIf="task.processorName">
              - {{ 'taskmodule.shared.label__editor' | translate }}:
              {{ task.processorName }}
            </span>
            <span *ngIf="task.organisationName">
              - Firma:
              {{ task.organisationName }}
            </span>
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ticketActionsTemplate let-row let-index="index" let-col="colDef">
  <su-button
    [skin]="row.hasDocuments ? 'primary' : 'secondary'"
    icon="paperclip"
    (onClick)="openDocuments($event, row)">
  </su-button>
</ng-template>
