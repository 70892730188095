import { ChangeDetectorRef, Component, OnInit, isDevMode } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FmConfigurationRow,
  FmIncident,
  FmTask,
  IncidentClient,
  IncidentFieldMapping,
  LocationClient,
  OptionSetClient,
  PmIncidentExternalLetting,
  PmIncidentRealEstateDecision,
  PmIncidentRentalContractMgmt,
  ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping,
  SubIncidentViewModel,
  SysEnum,
  TaskClient,
  TaskStatus,
  UpdateIncidentDTO,
  UpdateTaskDTO,
} from '@core/api';
import { CheckForTaskUpdateRequest } from '@core/api/smart-portal.nemo.api';
import { BreadcrumbService } from '@core/services/breadcrumb-service/BreadcrumbService';
import { IbpdiHelperService } from '@core/services/translate-service/ibpdi-helper.service';
import { TranslateDataService } from '@core/services/translate-service/translate-data.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IDetailFields } from '@shared/components/details/detail-fields/models/detail-fields.model';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { DocumentVisibility } from '@shared/types/document.types';
import { enumToOptionSet } from '@shared/utils/enumToOptionSet';
import { CardConfig } from '@affinis/smartus-components/lib/card/card.component.types';
import { Tab } from '@affinis/smartus-components/lib/tabs/tabs.components.types';
import { cloneDeep, isEqual } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, first, map, shareReplay, switchMap, take } from 'rxjs';
import { ProspectiveTenantsTelekomComponent } from '../prospective-tenants/prospective-tenants.component';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
})
export class TaskDetailsTelekomComponent implements OnInit {
  public title = this.translateService.instant('taskmodule.task.update.title');

  public filesTitle = this.translateService.instant(
    'taskmodule.task.update.filesTitle'
  );

  cardFields: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  cardDocumentupload: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  cardContact: CardConfig = {
    header: {
      title: {
        label: '',
      },
    },
  };

  tabs: Tab[] = [
    {
      label: 'Ticketfelder bearbeiten',
      id: 'editIncidentFields',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: 'Dokumente',
      id: 'documents',
      onClick: (id: string) => this.onTabClick(id),
    },
    {
      label: 'Kontakt',
      id: 'contact',
      onClick: (id: string) => this.onTabClick(id),
    },
  ];

  activeTab = this.tabs[0].id;

  onTabClick(value: string) {
    this.activeTab = value;
  }

  public isLoading = false;

  public masterRequired = true;

  form = new FormGroup({});

  isDevMode = isDevMode();

  formModel: any = {};

  formFields: FormlyFieldConfig[];

  entity: ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping;

  task?: FmTask;

  extension?: IncidentFieldMapping[];

  saveButtonLabel: string;

  private updateIncidentFieldGroupName = 'updateIncidentFieldsFormlyGroup';
  private updateIncidentExternalLetting =
    'updateIncidentExternalLettingFieldsFormlyGroup';
  private updateIncidentRealEstateDecision =
    'updateIncidentRealEstateDecisionFieldsFormlyGroup';
  private updateIncidentRentalContractMngmnt =
    'updateIncidentRentalContractMngmntFieldsFormlyGroup';

  isSaveButtonEnabled = false;

  incidentInformations: FormlyFieldConfig[] = [
    {
      fieldGroup: [
        {
          type: 'flex-layout',
          fieldGroup: [
            {
              key: 'incident.ticketNumber',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__ticketNumber'),
                readOnly: true,
              },
            },
            {
              key: 'incident.requestType',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentType'),
                readOnly: true,
              },
            },
          ],
        },
        {
          type: 'flex-layout',
          fieldGroup: [
            {
              key: 'incident.name',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentTitle'),
                readOnly: true,
              },
            },
            {
              key: 'externalProcessorFullName',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__editor'),
                readOnly: true,
              },
            },
            {
              key: 'incident.description',
              type: 'input',
              wrappers: ['readOnly'], //, 'form-field'],
              props: {
                label: this.translate('label__incidentDescription'),
                readOnly: true,
              },
            },
            {
              key: 'incident.incidentClass.incidentClass',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentClass'),
                readOnly: true,
              },
            },
          ],
        },
        {
          type: 'flex-layout',
          fieldGroup: [
            {
              key: 'incident.archObjId',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentFunctionalLocation'),
                readOnly: true,
              },
            },
            {
              key: 'incident.dtagResponsibility',
              type: 'input',
              wrappers: ['readOnly'],
              props: {
                label: this.translate('label__incidentDtagResponsibility'),
                readOnly: true,
              },
            },
            // TODO: (KB): AUSGEBLENDET, Datengrundlage ist wahrscheinlich nicht korrekt.
            // {
            //   key: 'incident.city',
            //   type: 'input',
            //   wrappers: ['readOnly'],
            //   templateOptions: {
            //     label: this.translate('label__incidentCity'),
            //     readOnly: true,
            //   },
            // },
          ],
        },
        // {
        //   type: 'flex-layout',
        //   fieldGroup: [
        //     {
        //       key: 'incident.street',
        //       type: 'input',
        //       wrappers: ['readOnly'],
        //       templateOptions: {
        //         label: this.translate('label__incidentStreet'),
        //         readOnly: true,
        //       },
        //     },
        //   ],
        // },
      ],
    },
  ];

  taskInformations: FormlyFieldConfig[] = [
    {
      key: 'subject',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__subject'),
        readOnly: true,
      },
    },
    {
      key: 'description',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__taskDescription'),
        readOnly: true,
      },
    },
    {
      key: 'dueDate',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__dueDate'),
        readOnly: true,
        valueFormatter: _dueDate => {
          return '--';
          // TODO Reimplement this if use of dueDate is determined https://dev.azure.com/pts-vsts/Magenta%20Squad/_workitems/edit/19247
          // if (!dueDate) {
          //   return '--';
          // }
          // const now = moment(new Date());
          // let r = moment(dueDate)
          //   .locale(this.translateService.instant('locale.locale'))
          //   .format(
          //     this.translateService.instant('locale.moment__date_format')
          //   );
          // r +=
          //   moment(dueDate).diff(now, 'days') < 0
          //     ? ` &nbsp;<span class="warning-tag"> ${this.translate(
          //         'info__overdue'
          //       )}
          //     <i class="material-icons">warning_amber</i> </span>
          //     `
          //     : '';
          // return r;
        },
      },
    },
    {
      wrappers: ['form-field', 'count-chars'],
      key: 'result',
      type: 'textarea',
      props: {
        label: this.translate('label__result'),
        visibleInConfirm: true,
        required: this.masterRequired,
        autosize: true,
        autosizeMinRows: 4,
        maxLength: 2000,
      },
    },
    {
      key: 'status',
      type: 'input',
      wrappers: ['readOnly'],
      props: {
        label: this.translate('label__actualSatus'),
        readOnly: true,
      },
      hooks: {
        onInit: field => {
          field?.formControl?.valueChanges.subscribe({
            next: value => {
              if (value === 'In Bearbeitung') {
                const index = this.formFields.findIndex(
                  x => x.key === 'newStatus'
                );
                this.formFields[index].defaultValue = TaskStatus.InBearbeitung;
              }
              if (this.task?.configurationTask?.fmConfigurationRow) {
                const formGroup =
                  this.formFields[
                    this.formFields.findIndex(
                      x => x.key === this.updateIncidentFieldGroupName
                    )
                  ];
                for (const row of this.task.configurationTask
                  .fmConfigurationRow) {
                  if (row.name) {
                    const key = this.firstLowercase(row.name);
                    if (formGroup?.fieldGroup) {
                      const formField =
                        formGroup.fieldGroup[
                          formGroup?.fieldGroup?.findIndex(x => x.key === key)
                        ];
                      if (formField?.props?.required !== undefined) {
                        formField.props.required =
                          value === 'Abgeschlossen' ||
                          value === 'Nicht gestartet';
                      }
                    }
                  }
                }
              }
            },
          });
        },
      },
    },
    {
      type: 'select',
      key: 'newStatus',
      props: {
        label: this.translate('label__newStatus'),
        required: this.masterRequired,
        options: enumToOptionSet(
          TaskStatus,
          this.translateService,
          'taskmodule.shared.status'
        ),
        compareWith(o1: any, o2: any) {
          return o1.id === o2.id;
        },
      },
      hooks: {
        onInit: field => {
          field?.formControl?.valueChanges.subscribe({
            next: value => {
              if (this.task?.configurationTask?.fmConfigurationRow) {
                const formGroup =
                  this.formFields[
                    this.formFields.findIndex(
                      x => x.key === this.updateIncidentFieldGroupName
                    )
                  ];
                for (const row of this.task.configurationTask
                  .fmConfigurationRow) {
                  if (row.name) {
                    const key = this.firstLowercase(row.name);
                    if (formGroup?.fieldGroup) {
                      const formField =
                        formGroup.fieldGroup[
                          formGroup?.fieldGroup?.findIndex(x => x.key === key)
                        ];
                      if (formField?.props?.required !== undefined) {
                        formField.props.required =
                          value === TaskStatus.Abgeschlossen;
                      }
                    }
                  }
                }
              }
            },
          });
        },
      },
    },
  ];

  detailFields: IDetailFields[] = [
    {
      type: 'field',
      key: 'incident.name',
      label: this.translate('label__title'),
    },
    {
      type: 'field',
      key: 'incident.ticketNumber',
      label: this.translate('label__ticketNumber'),
    },
    {
      type: 'field',
      key: 'subject',
      label: this.translate('label__subject'),
    },
    {
      type: 'buffered-files',
      key: 'documentMetadata',
    },
    {
      type: 'typed-files',
      key: 'fmDocument',
      options: [
        { label: 'Flurstückskarte', value: 'AUG' },
        { label: 'Grundbuchauszug', value: 'AUG' },
        { label: 'Pläne', value: 'PL7' },
        { label: 'Schriftverkehr', value: 'SVK' },
        { label: 'Sonstiges', value: 'SO7' },
      ],
    },
  ];

  dataProspectiveTenantsList$: Observable<SubIncidentViewModel[]>;


  displayedColumns: string[] = [
    'ticketNumber',
    'requestedArea',
    'requestedParkingSpace',
    'ticketStatusReason',
  ];

  private formModelInit;

  constructor(
    private route: ActivatedRoute,
    private optionSetClient: OptionSetClient,
    private cd: ChangeDetectorRef,
    private translateDataService: TranslateDataService,
    private translateService: TranslateService,
    public taskClient: TaskClient,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private ibpdiHelperService: IbpdiHelperService,
    private locationClient: LocationClient,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private incidentClient: IncidentClient
  ) {}

  ngOnInit() {
    this.formFields = [...this.incidentInformations, ...this.taskInformations];
    this.getItem(this.route.snapshot.paramMap.get('id'));
    this.breadcrumbService.setBreadcrumbs([
      {
        label: this.translateService.instant('taskmodule.task.list.title'),
        onClick: () => {
          this.router.navigateByUrl('/tasks');
        },
      },
      {
        label: this.title,
      },
    ]);

    this.route.queryParams.subscribe(params => {
      const tabIndex = params['tab'];

      if (tabIndex === 'contact') {
        this.activeTab = this.tabs[2].id;
      } else {
        this.activeTab = this.tabs[0].id;
      }
    });
  }

  modelOnChange() {
    if (!isEqual(this.formModel, this.formModelInit)) {
      this.isSaveButtonEnabled = true;
    } else {
      this.isSaveButtonEnabled = false;
    }
  }

  async getItem(id: string | null) {
    if (!id) {
      return;
    }
    this.isLoading = true;
    this.taskClient
      .getTask(id, false)
      .pipe(first())
      .subscribe({
        next: (
          data: ResultExtensionWrapperOfTaskViewModelAndIEnumerableOfIncidentFieldMapping
        ) => {
          this.isLoading = false;
          this.cd.detectChanges();

          if (!data) {
            this.showErrorToastLoadTask();
            this.cd.detectChanges();
            return;
          }

          this.entity = data;
          this.task = data.entity;
          this.extension = data.extension;

          this.fillIncidentAttributeForm();

          const fileMetadata = [
            {
              attributeName: 'incidentId',
              attributeValue: data.entity?.incidentId || 'error no incidentId',
            },
            {
              attributeName: 'taskId',
              attributeValue: data.entity?.taskId || 'error no taskId',
            },
            {
              attributeName: 'visibilityId',
              attributeValue: DocumentVisibility.InternAndContractors,
            },
            {
              attributeName: 'defaultDocumentTypeId',
              attributeValue: data.entity?.configurationTask
                ? // eslint-disable-next-line @typescript-eslint/dot-notation
                  data.entity?.configurationTask['defaultDocumentTypeId'] ?? ''
                : '',
            },
          ];

          this.detailFields.forEach((detailField: IDetailFields) => {
            detailField.fileMetadata = fileMetadata;
          });

          this.formModel = { ...this.formModel, ...data.entity };
          this.cd.detectChanges();

          // if (this.formModel.subject === '12.1 Mietinteressent melden') {
          //   this.cardFields = {
          //     header: {
          //       ...this.cardFields.header,
          //     },
          //     footer: {
          //       ...this.cardFields.footer,
          //       button3: {
          //         label: this.translateService.instant(
          //           'taskmodule.task.prospective_tenants.record'
          //         ),
          //         disabled: false,
          //         onClick: () => {
          //           this.recordProspectiveTenants();
          //         },
          //       },
          //     },
          //   };
          // }
          this.getSubIncidents();

          this.formModelInit = cloneDeep(this.formModel);
        },
        error: error => {
          this.isLoading = false;
          this.showErrorToastLoadTask();
          this.cd.detectChanges();
          console.log(error);
        },
      });
  }

  private showErrorToastLoadTask() {
    this.toastr.error(
      this.translateService.instant('crud.details.error.failed_to_load_msg'),
      this.translateService.instant('crud.details.error.failed_to_load_title')
    );
  }

  private showErrorToastUpdateTask() {
    this.toastr.error(
      this.translateService.instant('crud.update.error.failed_to_update_msg'),
      this.translateService.instant('crud.update.error.failed_to_update_title')
    );
  }

  private showSuccessToastUpdateTask() {
    this.toastr.success(
      this.translateService.instant('crud.update.success.sucess_update_msg'),
      this.translateService.instant('crud.update.success.sucess_update_title')
    );
  }

  viewDetailsTask = false;

  submit() {
    if (!this.task || !this.task.incidentId) {
      return;
    }

    this.cd.detectChanges();

    const newStatus = (this.form.value as any).newStatus;

    if (newStatus === TaskStatus.Abgeschlossen) {
      const taskClosedDialog: MatDialogRef<DialogComponent> = this.dialog.open(
        DialogComponent,
        {
          panelClass: 'smartportal-dialog',
          width: '500px',
          data: {
            type: 'okDismissButton',
            title: 'Aufgabe wird abgeschlossen',
            message:
              'Abgeschlossene oder stornierte Aufgaben können nicht mehr bearbeitet werden und werden in der Übersicht nicht mehr angezeigt.',
            okButtonText: 'Jetzt senden',
            dismissButtonText: 'Abbrechen',
          },
        }
      );

      taskClosedDialog
        .afterClosed()
        .pipe(first())
        .subscribe({
          next: res => {
            if (!res) {
              this.isLoading = false;
              this.cd.detectChanges();
              return;
            }
            this.viewDetailsTask = true;
            this.send();
          },
        });
    } else {
      this.send();
    }
  }

  private send() {
    this.isLoading = true;

    if (this.task && this.task.taskId) {
      const { result, newStatus } = this.form.value as any;

      const updateTaskDto: UpdateTaskDTO = {
        result: result,
        status: newStatus,
        taskId: this.task.taskId,
        userId: '<setByBackend>',
        updateIncidentDTO: this.generateUpdateIncidentDTO(),
      };
      this.taskClient
        .updateTask(updateTaskDto)
        .pipe(take(1))
        .subscribe({
          next: () => {
            // check for update every second
            this.checkIsTaskUpdated();
          },
          error: err => {
            console.error(err);
            this.isLoading = false;
            this.showErrorToastUpdateTask();
            this.cd.detectChanges();
            window.location.reload();
          },
        });
    }
  }

  private checkIsTaskUpdated() {
    const request: CheckForTaskUpdateRequest = {
      id: this.task.taskId,
      modifiedOn: this.task.modifiedOn,
    };

    setTimeout(() => {
      this.taskClient.isTaskUpdated(request).subscribe({
        next: response => {
          if (!response) {
            this.checkIsTaskUpdated();
          }

          setTimeout(() => {
            this.isLoading = false;
            if (this.viewDetailsTask) {
              this.router.navigateByUrl(
                `/task/viewDetails/${this.task.taskId}`
              );
            } else {
              window.location.reload();
            }
            this.showSuccessToastUpdateTask();
            this.cd.detectChanges();
          }, 7000);
        },
        error: err => {
          console.error(err);
          this.checkIsTaskUpdated();
        },
      });
    }, 1000);
  }

  private generateUpdateIncidentDTO(): UpdateIncidentDTO {
    const areasReturnDateLessorDateString =
      this.form.value[this.updateIncidentRentalContractMngmnt]?.areasReturnDateLessor;

    let areasReturnDateLessorDateObject = null;

    let dateArray = areasReturnDateLessorDateString?.split('.');
    if (dateArray) {
      let year = Number(dateArray[2]);
      let month = Number(dateArray[1]) - 1;
      let day = Number(dateArray[0]);

      areasReturnDateLessorDateObject = new Date(year, month, day);
    }

    const el: PmIncidentExternalLetting = {
      incidentId: this.task?.incident?.incidentId,
      ...this.form.value[this.updateIncidentExternalLetting],
    };
    const red: PmIncidentRealEstateDecision = {
      incidentId: this.task?.incident?.incidentId,
      ...this.form.value[this.updateIncidentRealEstateDecision],
    };
    const rcm: PmIncidentRentalContractMgmt = {
      incidentId: this.task?.incident?.incidentId,
      ...this.form.value[this.updateIncidentRentalContractMngmnt],
      areasReturnDateLessor: areasReturnDateLessorDateObject,
    };

    const incidentDto: FmIncident = {
      name: this.task?.incident?.name,
      incidentId: this.task?.incident?.incidentId,
      pmIncidentExternalLetting: this.form.value[
        this.updateIncidentExternalLetting
      ]
        ? el
        : null,
      pmIncidentRealEstateDecision: this.form.value[
        this.updateIncidentRealEstateDecision
      ]
        ? red
        : null,
      pmIncidentRentalContractMgmtIdNavigation: this.form.value[
        this.updateIncidentRentalContractMngmnt
      ]
        ? rcm
        : null,
      ...this.form.value[this.updateIncidentFieldGroupName],
    };
    return {
      incident: incidentDto,
      fieldMapping: this.extension || [],
    };
  }

  private fillIncidentAttributeForm() {
    if (
      !this.task ||
      !this.task.configurationTask ||
      !this.task.configurationTask.fmConfigurationRow ||
      !this.task.incident ||
      this.task.configurationTask.fmConfigurationRow.length <= 0
    ) {
      return;
    }

    // add chapter title
    this.formFields.push({
      type: 'chapter',
      props: {
        chapterTitle: this.translateService.instant(
          'taskmodule.task.update.update_incident_fields'
        ),
      },
    });

    // generate emtpy FormGroup for the incident fields
    const updateIncidentFormGroup: {
      key: string;
      fieldGroup: Array<any>;
    } & FormlyFieldConfig = {
      key: this.updateIncidentFieldGroupName,
      fieldGroup: [],
    };

    const updateIncidentExternalLettingFormGroup: {
      key: string;
      fieldGroup: Array<any>;
    } & FormlyFieldConfig = {
      key: this.updateIncidentExternalLetting,
      fieldGroup: [],
    };

    const updateIncidentRealEstateDecisionFormGroup: {
      key: string;
      fieldGroup: Array<any>;
    } & FormlyFieldConfig = {
      key: this.updateIncidentRealEstateDecision,
      fieldGroup: [],
    };

    const updateIncidentRentalContractMngmntFormGroup: {
      key: string;
      fieldGroup: Array<any>;
    } & FormlyFieldConfig = {
      key: this.updateIncidentRentalContractMngmnt,
      fieldGroup: [],
    };

    // populate FormGroup with proper typed input fields
    for (const row of this.task.configurationTask.fmConfigurationRow) {
      // push config row form field
      if (row.targetEntity == null) {
        updateIncidentFormGroup.fieldGroup.push(
          this.generateConfigurationRowField(row)
        );
      } else if (row.targetEntity == 'affinis_incidentrealestatedecision') {
        updateIncidentRealEstateDecisionFormGroup.fieldGroup.push(
          this.generateConfigurationRowField(row)
        );
      } else if (row.targetEntity == 'affinis_incidentexternalletting') {
        updateIncidentExternalLettingFormGroup.fieldGroup.push(
          this.generateConfigurationRowField(row)
        );
      } else if (
        row.targetEntity == 'affinis_incidentrentalcontractmanagement'
      ) {
        updateIncidentRentalContractMngmntFormGroup.fieldGroup.push(
          this.generateConfigurationRowField(row)
        );
      }
    }

    // push the formGroup in the parent formFields
    if (updateIncidentFormGroup.fieldGroup.length > 0) {
      this.formFields.push(updateIncidentFormGroup);
    }
    if (updateIncidentRealEstateDecisionFormGroup.fieldGroup.length > 0) {
      this.formFields.push(updateIncidentRealEstateDecisionFormGroup);
    }
    if (updateIncidentExternalLettingFormGroup.fieldGroup.length > 0) {
      this.formFields.push(updateIncidentExternalLettingFormGroup);
    }
    if (updateIncidentRentalContractMngmntFormGroup.fieldGroup.length > 0) {
      this.formFields.push(updateIncidentRentalContractMngmntFormGroup);
    }
  }

  private firstLowercase(theString: string): string {
    return theString.charAt(0).toLowerCase() + theString.slice(1);
  }

  private generateConfigurationRowField(
    row: FmConfigurationRow
  ): FormlyFieldConfig {
    if (!this.extension || !row.name || !this.task || !this.task.incident) {
      throw new Error('An Error occured in generateField');
    }

    // get mapping for fieldType of the configuration row
    const incidentFieldMapping: IncidentFieldMapping | null =
      this.extension.find(e => e.ibpdiAttributeName === row.name) || null;

    if (!incidentFieldMapping) {
      throw new Error('missing incidentFieldMapping');
    }

    const label: string = this.translateDataService.translate(
      row.titleDe,
      row.titleEn
    );

    const key: string = this.firstLowercase(row.name);

    const fieldType: string = incidentFieldMapping
      ? incidentFieldMapping.ibpdiAttributeType
      : '';

    // prefill field value with value in incident
    let defaultValue = null;

    if (incidentFieldMapping.targetEntity == null) {
      defaultValue = this.task.incident[key] || null;
    } else if (
      incidentFieldMapping.targetEntity == 'affinis_incidentrealestatedecision'
    ) {
      defaultValue =
        this.task.incident.pmIncidentRealEstateDecision[key] || null;
    } else if (
      incidentFieldMapping.targetEntity == 'affinis_incidentexternalletting'
    ) {
      defaultValue = this.task.incident.pmIncidentExternalLetting[key] || null;
    } else if (
      incidentFieldMapping.targetEntity ==
      'affinis_incidentrentalcontractmanagement'
    ) {
      defaultValue =
        this.task.incident.pmIncidentRentalContractMgmtIdNavigation[key] ||
        null;
    }

    if (fieldType.includes('String')) {
      return this.generateTextField(key, label, defaultValue);
    }
    if (fieldType.includes('DateTime')) {
      return this.generateDateField(key, label, defaultValue);
    }
    if (fieldType.includes('Int')) {
      return this.generateIntField(key, label, defaultValue);
    }
    if (fieldType.includes('Decimal')) {
      return this.generateDecimalField(key, label, defaultValue);
    }
    if (fieldType.includes('SysEnum')) {
      if (!incidentFieldMapping.optionSetType) {
        throw new Error('Missing optionSetType');
      }
      return this.generateOptionSetField(
        incidentFieldMapping.optionSetType,
        defaultValue,
        key,
        label
      );
    }
    if (fieldType.includes('Boolean')) {
      return this.generateBooleanField(key, label, defaultValue);
    }
    throw new Error(`Unknown fieldType: ${fieldType}`);
  }

  public generateTextField(
    key = '--',
    label = '--',
    defaultValue = '',
    maxLength = 100
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue,
      props: {
        required: this.masterRequired,
        label,
        maxLength,
      },
    };
  }

  private generateDateField(
    key = '--',
    label = '--',
    defaultValue = ''
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue: defaultValue ? this.formatDateString(new Date(defaultValue)) : '',
      validation: {
        messages: {
          invalidDate: 'Ungültiges Datum / Datumsformat (TT.MM.JJJJ)',
        },
      },
      props: {
        required: this.masterRequired,
        label,
        placeholder: 'TT.MM.JJJJ',
        focus: res => {
          res.formControl.setErrors(
            this.handleDateInput(res.formControl.value)
          );
        },
        blur: res => {
          res.formControl.setErrors(
            this.handleDateInput(res.formControl.value)
          );
        },
      },
    };
  }

  private generateIntField(
    key = '--',
    label = '--',
    defaultValue = ''
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue,
      props: {
        type: 'number',
        label,
        required: this.masterRequired,
      },
    };
  }

  private generateDecimalField(
    key = '--',
    label = '--',
    defaultValue = ''
  ): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      defaultValue,
      props: {
        type: 'number',
        label,
        required: this.masterRequired,
        step: 0.01,
      },
    };
  }

  private generateOptionSetField(
    optionSetType: string,
    defaultValue: SysEnum,
    key = '--',
    label = '--'
  ): FormlyFieldConfig {
    // fixes requiredness of sysEnumTranslation.name
    if (defaultValue) {
      delete defaultValue.sysEnumTranslation;
    }

    return {
      key,
      type: 'select',
      defaultValue,
      //wrappers: ['loading-suffix'],
      props: {
        loading: false,
        readonly: false,
        compareWith(o1: any, o2: any) {
          return o1.id === o2.id;
        },
        label,
        required: this.masterRequired,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig | undefined) => {
          if (!field || !field.props) {
            return;
          }
          const f = field.props;
          f.loading = true;
          f.readonly = true;
          this.cd.detectChanges();

          this.optionSetClient
            .getOptionSetByString(optionSetType)
            .pipe(
              first(),
              map(sysEnums => {
                return sysEnums.map((sysEnum: SysEnum) => {
                  delete sysEnum.sysEnumTranslation;
                  const s = { ...sysEnum };
                  return {
                    label:
                      this.ibpdiHelperService.getTranslatedEnumLable(sysEnum),
                    value: s,
                  };
                });
              })
            )
            .subscribe({
              next: options => {
                // sysEnumTranslation:null fixes requiredness of sysEnumTranslation.name
                if (defaultValue) {
                  delete defaultValue.sysEnumTranslation;
                }

                f.options = options;
                this.form.patchValue({
                  [this.updateIncidentFieldGroupName]: { [key]: defaultValue },
                });
                f.loading = false;
                f.readonly = false;
                this.cd.detectChanges();
              },
              error: error => {
                this.showErrorToastLoadTask();
                console.error(error);
              },
            });
        },
      },
    };
  }

  private generateBooleanField(
    key = '--',
    label = '--',
    defaultValue: boolean | null | string = null
  ): FormlyFieldConfig {
    if (defaultValue === '') {
      defaultValue = null;
    }

    const options: { id: number; label: string; value: boolean }[] = [
      { id: 1, label: this.translate('label__choice_yes'), value: true },
      { id: 2, label: this.translate('label__choice_no'), value: false },
    ];

    return {
      key,
      type: 'select',
      defaultValue,
      props: {
        options,
        label,
        required: this.masterRequired,
      },
    };
  }

  public translate(key: string) {
    return this.translateService.instant(`taskmodule.shared.${key}`);
  }

  public recordProspectiveTenants() {
    let locationSiteCode;
    let locationWeNo;
    let addressCity;
    let addressStreet;

    this.locationClient
      .getArchobjById(this.task?.incident?.archObjId)
      .subscribe({
        next: res => {
          locationSiteCode = res.dtSite?.siteCode;
          locationWeNo = res.archObjId;
          addressCity = res.dtArchObjAddress
            ? res.dtArchObjAddress[0].address?.city
            : null;
          addressStreet = res.dtArchObjAddress
            ? res.dtArchObjAddress[0].address?.streetName
            : null;
          this.dialog.open(
            ProspectiveTenantsTelekomComponent,
            {
              width: '50vw',
              data: {
                siteCode: locationSiteCode,
                weNo: locationWeNo,
                city: addressCity,
                street: addressStreet,
                incidentId: this.task?.incidentId,
              },
            }
          );
        },
      });
  }

  //getSubIncidents() {
    //this.dataProspectiveTenantsList$ = this.incidentClient.getSubIncidents(this.task?.incidentId);
  //}
  getSubIncidents() {
    this.dataProspectiveTenantsList$ = this.incidentClient.getSubIncidents(this.task?.incidentId)
      .pipe(
        shareReplay(1)
      );
  }


  private isValidDate(dateString: string): boolean {
    // Zeichenkette im Format "DD.MM.YYYY"
    let dateArray = dateString?.split('.');
    if (dateArray) {
      let year = Number(dateArray[2]);
      let month = Number(dateArray[1]) - 1;
      let day = Number(dateArray[0]);

      let dateObject = new Date(year, month, day);

      return (
        dateObject.getFullYear() === year &&
        dateObject.getMonth() === month &&
        dateObject.getDate() === day &&
        dateObject.getFullYear() <= 9999
      );
    }
    return false;
  }

  private handleDateInput(dateString: string) {
    if (!this.isValidDate(dateString)) {
      return { invalidDate: true };
    } else {
      return null;
    }
  }

  private formatDateString(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
}
