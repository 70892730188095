import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { MtxGridModule } from '@ng-matero/extensions/grid';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { TaskContactComponent } from '@shared/components/contact/task-contact/task-contact.component';
import { DetailFieldsBaseComponent } from '@shared/components/details/detail-fields/detail-fields-base';
import { DetailFileListBufferedComponent } from '@shared/components/details/detail-file-list/detail-file-list-buffered.component';
import { DetailFileListComponent } from '@shared/components/details/detail-file-list/detail-file-list.component';
import { DetailFileUploadLegacyComponent } from '@shared/components/details/detail-file-upload-legacy/detail-file-upload-legacy.component';
import { DetailFileUploadComponent } from '@shared/components/details/detail-file-upload/detail-file-upload.component';
import { DetailsBaseComponent } from '@shared/components/details/details-base';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { FlexLayoutColComponent } from '@shared/components/forms/custom-formly-components/flex-layout-col.component';
import { FlexLayoutComponent } from '@shared/components/forms/custom-formly-components/flex-layout.component';
import { FormlyCardWrapperComponent } from '@shared/components/forms/custom-formly-components/formly-card-wrapper.component';
import { FormlyChapterComponent } from '@shared/components/forms/custom-formly-components/formly-chapter.component';
import { FormlyCountCharsWrapperComponent } from '@shared/components/forms/custom-formly-components/formly-count-chars-wrapper.component';
import { FormlyDatePickerComponent } from '@shared/components/forms/custom-formly-components/formly-datepicker.component';
import { FormlyFieldFileComponent } from '@shared/components/forms/custom-formly-components/formly-field-file.component';
import { FormlyNoBorderWrapperComponent } from '@shared/components/forms/custom-formly-components/formly-no-border-wrapper.component';
import { FormlyPreviewAutocompleteTypeComponent } from '@shared/components/forms/custom-formly-components/formly-preview-autocomplete-type/formly-preview-autocomplete-type.component';
import { FormlyReadOnlyWrapperComponent } from '@shared/components/forms/custom-formly-components/formly-read-only-wrapper.component';
import { FormlySelectTooltipComponent } from '@shared/components/forms/custom-formly-components/formly-select-tooltip.component';
import { FormlyTooltipWrapperComponent } from '@shared/components/forms/custom-formly-components/formly-tooltip-wrapper.component';
import { RepeatTypeComponent } from '@shared/components/forms/custom-formly-components/repeat-section.component';
import { FormBaseComponent } from '@shared/components/forms/form-base/form-base';
import { ModalFilterComponent } from '@shared/components/modal-filter/modal-filter.component';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { MaterialModule } from '@shared/material/material.module';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { ToastrModule } from 'ngx-toastr';
import { FormlyChipsInputComponent } from './components/forms/custom-formly-components';
import { FormlyAutocompleteChipsForRolesComponent } from './components/forms/custom-formly-components/formly-autocomplete-chips-for-roles.component';
import { FormlyButtonCCEmailDialogComponent } from './components/forms/custom-formly-components/formly-button-ccemail-dialog.component';
import { FormlyButtonComponent } from './components/forms/custom-formly-components/formly-button.component';
import { FormlyCheckboxMultipleOptionsComponent } from './components/forms/custom-formly-components/formly-checkbox-multiple-options.component';
import { FormlyDescriptionWrapperComponent } from './components/forms/custom-formly-components/formly-description-wrapper.component';
import { FormlyAreaRoomExtendedComponent } from './components/forms/custom-formly-components/formly-extended-input.component';
import { FormlyFieldFilePicturesComponent } from './components/forms/custom-formly-components/formly-field-file-pictures.component';
import { FormlyFieldInputWithTextComponent } from './components/forms/custom-formly-components/formly-field-input-with-text.component';
import { FormlyInputSearchComponent } from './components/forms/custom-formly-components/formly-input-search.component';
import { FormlyLinkEditTabComponent } from './components/forms/custom-formly-components/formly-link-edit-tab.component';
import { FormlyLinkComponent } from './components/forms/custom-formly-components/formly-link.component';
import { NestedSelectRoomDropdownsComponent } from './components/forms/custom-formly-components/formly-nested-selected.component';
import { FormlySpaceRequestComponent } from './components/forms/custom-formly-components/formly-space-request.component';
import { FormlyWorkingPlacesPlanningComponent } from './components/forms/custom-formly-components/formly-workingplaces-planning.component';
import { FormlyCheckboxComponent } from './components/forms/custom-formly-components/formly.checkbox.component';
import { RepeatMultipleFieldsTypeComponent } from './components/forms/custom-formly-components/repeat-section-multiple-fields.component';
import { FormConfirmComponent } from './components/forms/form-confirm/form-confirm.component';
import { SearchHelpDialogComponent } from './components/search-help-dialog/search-help-dialog.component';
import { FormlySelectOptionsTooltipPipe } from './pipes/forms/select-options-tooltip.pipe';

const pipes = [FormlySelectOptionsTooltipPipe];

const modules = [
  FormlyMaterialModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  SmartusComponentsModule,
  MtxGridModule,
  MtxSelectModule,
  FormlyMatDatepickerModule,
  GalleryModule,
  LightboxModule,
];

const components = [
  ModalFilterComponent,
  DialogComponent,
  FormlyChapterComponent,
  FormlySelectTooltipComponent,
  FormlyCountCharsWrapperComponent,
  FormlyFieldFileComponent,
  RepeatTypeComponent,
  RepeatMultipleFieldsTypeComponent,
  FormlyCardWrapperComponent,
  FlexLayoutComponent,
  FlexLayoutColComponent,
  FormBaseComponent,
  FormlyPreviewAutocompleteTypeComponent,
  TableFilterComponent,
  FormlyDatePickerComponent,
  DetailFileUploadComponent,
  DetailFileUploadLegacyComponent,
  DetailsBaseComponent,
  DetailFieldsBaseComponent,
  TaskContactComponent,
  DetailFileListBufferedComponent,
  DetailFileListComponent,
  DialogComponent,
  FormConfirmComponent,
  FormlyTooltipWrapperComponent,
  FormlyNoBorderWrapperComponent,
  FormlyInputSearchComponent,
  SearchHelpDialogComponent,
  FormlyButtonCCEmailDialogComponent,
  FormlyDescriptionWrapperComponent,
  FormlyAutocompleteChipsForRolesComponent,
  FormlyFieldFilePicturesComponent,
  FormlyFieldInputWithTextComponent,
  FormlySpaceRequestComponent,
];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: true,
      easing: 'ease-in',
      onActivateTick: true,
      autoDismiss: true,
    }),
    FormlyModule.forChild({
      types: [
        { name: 'file-pictures', component: FormlyFieldFilePicturesComponent },
        { name: 'file', component: FormlyFieldFileComponent },
        { name: 'select-tooltip', component: FormlySelectTooltipComponent },
        { name: 'chapter', component: FormlyChapterComponent },
        { name: 'chips-input', component: FormlyChipsInputComponent },
        { name: 'repeat', component: RepeatTypeComponent },
        {
          name: 'repeat-multiple-fields',
          component: RepeatMultipleFieldsTypeComponent,
        },
        { name: 'flex-layout', component: FlexLayoutComponent },
        { name: 'flex-layout-col', component: FlexLayoutColComponent },
        {
          name: 'preview-autocomplete',
          component: FormlyPreviewAutocompleteTypeComponent,
        },
        { name: 'matdatetimepicker', component: FormlyDatePickerComponent },
        { name: 'input-search', component: FormlyInputSearchComponent },
        {
          name: 'button-cc-email-dialog',
          component: FormlyButtonCCEmailDialogComponent,
        },
        {
          name: 'link-edit-tab',
          component: FormlyLinkEditTabComponent,
        },
        {
          name: 'link',
          component: FormlyLinkComponent,
        },
        {
          name: 'formly-checkbox',
          component: FormlyCheckboxComponent,
        },
        {
          name: 'autocomplete-chips-for-roles',
          component: FormlyAutocompleteChipsForRolesComponent,
        },
        {
          name: 'nested-select-room-dropdowns',
          component: NestedSelectRoomDropdownsComponent,
        },
        {
          name: 'user-area-inputs',
          component: FormlyAreaRoomExtendedComponent,
        },
        {
          name: 'workingplaces-planning-input',
          component: FormlyWorkingPlacesPlanningComponent,
        },
        {
          name: 'autocomplete-chips-for-roles',
          component: FormlyAutocompleteChipsForRolesComponent,
        },
        { name: 'link', component: FormlyLinkComponent },
        {
          name: 'checkbox-multiple-options',
          component: FormlyCheckboxMultipleOptionsComponent,
        },
        { name: 'space-request', component: FormlySpaceRequestComponent },
        {
          name: 'button',
          component: FormlyButtonComponent,
          defaultOptions: {
            props: {
              color: 'secondary',
              type: 'button',
            },
          },
        },
        {
          name: 'input-with-text',
          component: FormlyFieldInputWithTextComponent,
        },
      ],
      wrappers: [
        { name: 'count-chars', component: FormlyCountCharsWrapperComponent },
        { name: 'card', component: FormlyCardWrapperComponent },
        { name: 'readOnly', component: FormlyReadOnlyWrapperComponent },
        { name: 'tooltip', component: FormlyTooltipWrapperComponent },
        { name: 'no-border', component: FormlyNoBorderWrapperComponent },
        { name: 'description', component: FormlyDescriptionWrapperComponent },
      ],
      validators: [
        { name: 'requiredTrue', validation: Validators.requiredTrue },
      ],
    }),
    ...modules,
    OverrideModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormlyModule,
    ...components,
    ...modules,
    ...pipes,
  ],
})
export class SharedModule {}
