import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MtxGridColumn } from "@ng-matero/extensions/grid";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

@Component({
  selector: 'app-access-orderer-history',
  templateUrl: './access-orderer-history.component.html',
  styleUrls: ['./access-orderer-history.component.scss'],
})

export class AccessOrdererHistoryComponent implements OnInit {
  columns: Array<MtxGridColumn> = [];

  isLoading = false;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<AccessOrdererHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.columns = [
      {
        header: this.translateService.instant('access_orderer.history.dialog.costcenter'),
        field: 'costCenterId',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.lastname'),
        field: 'employeeLastName',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.firstname'),
        field: 'employeeFirstName',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.validUntil'),
        field: 'accessOrdererEndDate',
        sortable: true,
        class: 'truncated',
        formatter: (data: any) =>
          data.accessOrdererEndDate
            ? moment(data.accessOrdererEndDate)
                .locale(this.translateService.instant('locale.locale'))
                .format(
                  this.translateService.instant(
                    'locale.moment__date_format_only_digits'
                  )
                )
            : '--',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.valid'),
        field: 'isValid',
        sortable: true,
        class: 'truncated',
        formatter: data =>
          data.isValid === true 
            ? this.translateService.instant('access_orderer.history.dialog.yes') 
            : this.translateService.instant('access_orderer.history.dialog.no'),
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.end_on'),
        field: 'accessOrdererDeleteDate',
        sortable: true,
        class: 'truncated',
        formatter: (data: any) =>
          data.accessOrdererDeleteDate
            ? moment(data.accessOrdererDeleteDate)
                .locale(this.translateService.instant('locale.locale'))
                .format(
                  this.translateService.instant(
                    'locale.moment__date_format_only_digits'
                  )
                )
            : '--',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.changed_on'),
        field: 'accessOrdererCreateDate',
        sortable: true,
        class: 'truncated',
        formatter: (data: any) => 
          data.accessOrdererDeleteDate 
            ? data.accessOrdererDeleteDate
              ? moment(data.accessOrdererDeleteDate)
                  .locale(this.translateService.instant('locale.locale'))
                  .format(
                    this.translateService.instant(
                      'locale.moment__date_format_only_digits'
                    )
                  ) 
              : '--'
            : data.accessOrdererCreateDate
              ? moment(data.accessOrdererCreateDate)
              .locale(this.translateService.instant('locale.locale'))
              .format(
                this.translateService.instant(
                  'locale.moment__date_format_only_digits'
                  )
                  )
              : '--'
        ,
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.lastname_kostv'),
        field: 'kostvLastName',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
      {
        header: this.translateService.instant('access_orderer.history.dialog.firstname_kostv'),
        field: 'kostvFirstName',
        sortable: true,
        class: 'truncated',
        resizable: true,
      },
    ];
  }
}
