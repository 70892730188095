import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  RegexpLocationname,
  RegexpPlotnumber,
  RegexpStreetname,
  RegexpZipCodeDE,
} from '@shared/utils/regex';
import { federalList } from './ticket-created-shared.field-configs';

export function auxAddress(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      key: 'auxAddress',
      type: 'checkbox',
      defaultValue: false,
      props: {
        label: translateService.instant('ticket.create.checkbox__auxAddress'),
      },
    },
    {
      wrappers: [],
      expressions: {
        hide: '!field.model?.auxAddress',
      },
      fieldGroup: [
        {
          type: 'chapter',
          props: {
            chapterTitle: translateService.instant(
              'ticket.create.headline__auxAddress'
            ),
            chapterText: translateService.instant(
              'ticket.create.p__info_auxAddress'
            ),
          },
          expressions: {
            'props.visibleInConfirm': 'model?.auxAddress',
          },
        },
        {
          key: 'concerningLocation',
          fieldGroup: [
            {
              type: 'flex-layout',
              fieldGroup: [
                {
                  type: 'input',
                  key: 'street',

                  props: {
                    required: true,
                    label: translateService.instant(
                      'ticket.create.label__street_aux'
                    ),
                    minLength: 3,
                    maxLength: 400,
                    pattern: RegexpStreetname,
                    appearance: 'outline',
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant(
                        'formErrors.street.pattern'
                      ),
                      minlength: err =>
                        translateService.instant(
                          'formErrors.street.minLength',
                          {
                            length: err.requiredLength,
                          }
                        ),
                    },
                  },
                  expressions: {
                    'props.visibleInConfirm': 'true',
                  },
                },
                {
                  type: 'input',
                  key: 'zipcode',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__zipcode_aux'
                    ),
                    required: true,
                    minLength: 5,
                    maxLength: 400,
                    pattern: RegexpZipCodeDE,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant(
                        'formErrors.zipcode.pattern'
                      ),
                    },
                  },
                  expressions: {
                    'props.visibleInConfirm': 'true',
                  },
                },
              ],
            },
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'city',

                  props: {
                    label: translateService.instant(
                      'ticket.create.label__city_aux'
                    ),
                    required: true,
                    minLength: 3,
                    maxLength: 400,
                    pattern: RegexpLocationname,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant(
                        'formErrors.city.pattern'
                      ),
                      minlength: err =>
                        translateService.instant('formErrors.city.minLength', {
                          length: err.requiredLength,
                        }),
                    },
                  },
                  expressions: {
                    'props.visibleInConfirm': 'true',
                  },
                },
                {
                  type: 'select',
                  key: 'state',
                  props: {
                    label: translateService.instant(
                      'ticket.create.label__state_aux'
                    ),
                    required: true,
                    options: federalList(translateService.currentLang),
                  },
                  expressions: {
                    'props.visibleInConfirm': 'true',
                  },
                },
              ],
            },
            {
              type: 'flex-layout',

              fieldGroup: [
                {
                  type: 'input',
                  key: 'plotNumber',
                  props: {
                    maxLength: 400,
                    pattern: RegexpPlotnumber,
                    label: translateService.instant(
                      'ticket.create.label__plotNumber_aux'
                    ),
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant(
                        'formErrors.plotnumber.pattern'
                      ),
                    },
                  },
                  expressions: {
                    'props.visibleInConfirm': 'true',
                  },
                },
              ],
            },
            {
              type: 'select',
              key: 'powerOfDisposition',
              // expressions: {
              //   hide: '!field.model?.auxAddress'
              // },
              props: {
                label: translateService.instant(
                  'ticket.create.label__powerOfDisposition'
                ),
                visibleInConfirm: true,
                required: true,
                options: [
                  { label: 'Eigentümer', value: 'Eigentümer' },
                  {
                    label: 'Verfügungsberechtigter',
                    value: 'Verfügungsberechtigter',
                  },
                  { label: 'Andere', value: 'Andere' },
                ],
              },
            },
          ],
        },
      ],
    },
  ];
}
