import { SmartusComponentsModule } from '@affinis/smartus-components';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  BaseSelectViewModel,
  CreateArchObjUtilHistoryRequest,
  DtUsageCategoryViewModel,
  DtUsageType,
} from '@core/api';
import { EditRoomService } from '@core/services/edit-room/edit-room.service';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OverrideModule } from '@override/override.module';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-maintenance-room-add-new-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SmartusComponentsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
    FormlyModule,
    OverrideModule,
  ],
  templateUrl: './maintenance-room-add-new-modal.component.html',
  styleUrl: './maintenance-room-add-new-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceRoomAddNewModalComponent {
  private dialogRef = inject(MatDialogRef<MaintenanceRoomAddNewModalComponent>);
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private editRoomService = inject(EditRoomService);
  private toastr = inject(ToastrService);

  form: FormGroup;
  formFields: FormlyFieldConfig[];
  formModel: {
    validFrom: string;
    nestedDropdowns: string;
    changeReason: string;
  } = {
    validFrom: '',
    nestedDropdowns: '',
    changeReason: '',
  };
  minDate = new Date();
  typeOfUseDetailedId: any[] = [];
  changeReasons: BaseSelectViewModel[];
  spaceId: string;
  isLoading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    const today = new Date();
    this.minDate = new Date(today.setDate(today.getDate() + 1));
    this.spaceId = data.spaceId;
  }

  ngOnInit(): void {
    this.changeReasons = this.data.changeReasons;
    this.initializeForm();
  }

  initializeForm(): void {
    this.typeOfUseDetailedId = Object.entries(this.data.item).map(
      ([key, value]: [string, DtUsageCategoryViewModel]) => {
        const dtUsageTypes = {
          categoryName: value.name,
          dtUsageTypes: value.usageTypes.map((usage: DtUsageType) => ({
            usageTypeId: usage.usageTypeId,
            dtDetailAreaTypes: usage.dtUsageDetailArea || [],
          })),
        };

        return dtUsageTypes;
      }
    );

    this.form = this.fb.group({
      validFrom: ['', Validators.required],
      nestedDropdowns: ['', Validators.required],
      changeReason: ['', Validators.required],
    });

    this.formFields = [
      {
        type: 'flex-layout',
        fieldGroup: [
          {
            key: 'validFrom',
            type: 'matdatetimepicker',
            props: {
              type: 'date',
              label: this.translate('buildingMaintenance.table.validFrom'),
              required: true,
              minDate: this.minDate,
            },
          },
          {
            key: 'nestedDropdowns',
            type: 'nested-select-room-dropdowns',
            props: {
              label: this.translate('buildingMaintenance.table.buildingUsage'),
              datatypeOfUseDetailedId: this.typeOfUseDetailedId,
              required: true,
            },
          },
          {
            key: 'changeReason',
            type: 'select',
            props: {
              label: this.translate('buildingMaintenance.table.changeReason'),
              options: this.changeReasons.map(
                (reason: BaseSelectViewModel) => ({
                  value: reason.name,
                  label: reason.id,
                })
              ),
              required: true,
            },
          },
        ],
      },
    ];
  }

  isSaveDisabled(): boolean {
    const buildingUsageControl = this.form.get('nestedDropdowns');
    const buildingUsageValue = buildingUsageControl?.value;

    if (!buildingUsageValue || this.form.invalid) {
      return true;
    }

    const levelSelected = buildingUsageValue?.levelControl || null;
    const usageTypeSelected = buildingUsageValue?.usageTypeControl || null;
    const detailAreaSelected = buildingUsageValue?.detailAreaControl || null;

    const hasUsageTypeSecondDropdown =
      !!this.typeOfUseDetailedId.length &&
      !!this.typeOfUseDetailedId[0].dtUsageTypes.length;

    const hasDetailAreaThirdDropdown =
      hasUsageTypeSecondDropdown &&
      !!this.typeOfUseDetailedId[0].dtUsageTypes.find(
        type => type.dtDetailAreaTypes.length > 0
      );

    if (!hasUsageTypeSecondDropdown) {
      return !levelSelected;
    }

    if (hasUsageTypeSecondDropdown && !hasDetailAreaThirdDropdown) {
      return !(levelSelected && usageTypeSelected);
    }

    if (hasDetailAreaThirdDropdown) {
      if (
        usageTypeSelected &&
        usageTypeSelected.dtDetailAreaTypes.length === 0
      ) {
        return !(levelSelected && usageTypeSelected);
      } else {
        return !(levelSelected && usageTypeSelected && detailAreaSelected);
      }
    }

    return true;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const formValue = this.form.value;

      const nestedDropdowns = formValue.nestedDropdowns || {};
      const usageTypeControl = nestedDropdowns.usageTypeControl || {};
      const detailAreaControl = nestedDropdowns.detailAreaControl || {};

      const requestData: CreateArchObjUtilHistoryRequest = {
        validFrom: formValue.validFrom,
        archObjId: this.spaceId,
        usageTypeId: usageTypeControl.usageTypeId,
        detailAreaType: detailAreaControl.detailAreaTypeId,
        changeReason: formValue.changeReason,
      };

      this.editRoomService.addArchObjUtilHistory(requestData).subscribe({
        next: () => {
          this.isLoading = false;
          this.toastr.success(this.translate('edit_room.toastr.success_add'));
          this.dialogRef.close(requestData);
        },
        error: error => {
          this.isLoading = false;
          if (error.processError === 11800 || error.processError === 11900) {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(this.translate('edit_room.toastr.error_add'));
          }
          this.dialogRef.close(requestData);
        },
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
