import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { RentalSearchService } from './rental-search.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  from,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import {
  RentalListingClient,
  RentalListingDetailViewModel,
  RentalListingOverviewViewModel,
} from '@core/api';

@Component({
  selector: 'app-rental-search',
  templateUrl: './rental-search.component.html',
  styleUrl: './rental-search.component.scss',
})
export class RentalSearchComponent implements OnInit, OnDestroy {
  private searchSubject = new Subject<string>();
  private sliderSubject = new Subject<number>();
  coordinates = [0, 0];
  details: RentalListingDetailViewModel[] = [];
  tiles: RentalListingOverviewViewModel[] = [];
  selectedItem: RentalListingDetailViewModel | null = null;
  sliderValue: number = 15;
  inputValue: string = '';
  isLoading: boolean = false;
  rentalSearchElement: ElementRef;

  constructor(
    private rentalSearchService: RentalSearchService,
    private rentalListingClient: RentalListingClient
  ) {}

  ngOnInit(): void {
    this.loadRentalListings();
    this.setupSearchSubject();
    this.setupSliderSubject();
  }

  /**
   * Sets up the search subject to handle changes in the search query.
   * The subject is debounced to prevent excessive calls and only distinct values are processed.
   * When the search query changes, it triggers the retrieval of coordinates and the loading of rental listings.
   * If the query is empty or an error occurs, the coordinates are set to [0, 0].
   */
  private setupSearchSubject(): void {
    this.searchSubject
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(query => {
          if (!query) {
            return of([0, 0]); // Set coordinates to [0, 0] if the query is empty
          } else {
            return from(
              this.rentalSearchService.getCoordinatesFromQuerry(query)
            ).pipe(
              catchError(error => {
                console.error(error);
                return of([0, 0]); // Set coordinates to [0, 0] in case of error
              })
            );
          }
        })
      )
      .subscribe(coordinates => {
        this.coordinates = [coordinates[0], coordinates[1]];
        this.loadRentalListings();
      });
  }

  /****
   * Sets up the slider subject to handle changes in the slider value.
   * The subject is debounced to prevent excessive calls and only distinct values are processed.
   * When the slider value changes and the coordinates are not [0, 0], it triggers the loading of rental listings.
   */
  private setupSliderSubject(): void {
    this.sliderSubject
      .pipe(
        debounceTime(500), // Adjust the debounce time as needed
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (this.coordinates[0] !== 0 || this.coordinates[1] !== 0) {
          this.loadRentalListings();
        }
      });
  }

  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
    this.sliderSubject.unsubscribe();
  }

  /**
   * Handles the selection of a rental listing item.
   * @param item The selected rental listing overview item.
   */
  onItemSelect(item: RentalListingOverviewViewModel) {
    this.isLoading = true;
    this.rentalListingClient
      .getRentalListingObject(parseInt(item.id))
      .subscribe({
        next: data => {
          this.selectedItem = data;
          this.isLoading = false;
        },
        error: error => {
          console.error('Error loading rental listing details:', error);
          this.isLoading = false;
        },
      });
  }

  /**
   * Loads the rental listings based on the current coordinates and slider value.
   */
  loadRentalListings(): void {
    this.isLoading = true;
    this.rentalListingClient
      .getAllRentalListingObjects(
        this.coordinates ? this.coordinates[0] : 0,
        this.coordinates ? this.coordinates[1] : 0,
        this.sliderValue
      )
      .subscribe({
        next: data => {
          this.tiles = data;
          this.isLoading = false;
        },
        error: error => {
          console.error('Error loading rental listings:', error);
          this.isLoading = false;
        },
      });
  }

  /**
   * Handles the go back action, setting the selected item to null.
   */
  onGoBack() {
    this.selectedItem = null;
  }

  /**
   * Sets the input value and triggers the search subject with the new value.
   * @param event The input event containing the new value.
   */
  setInputValue(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.inputValue = inputElement.value;
    this.searchSubject.next(inputElement.value);
  }

  /**
   * Sets the slider value based on the input event.
   * @param event The input event containing the new slider value.
   */
  setSliderValue(event: Event) {
    const target = event.target as HTMLInputElement;
    this.sliderValue = Number(target.value);
    this.sliderSubject.next(this.sliderValue);
  }
}
